/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {StepperComponent} from '../../../../_rebusiness/assets/ts/components'
import {KTIcon} from '../../../../_rebusiness/helpers'
import {RegistrationStep1} from './stepsRegistration/Step1'
import {RegistrationStep2} from './stepsRegistration/Step2'
import {RegistrationStep3} from './stepsRegistration/Step3'
import {RegistrationStep4} from './stepsRegistration/Step4'
import {Form, Formik, useFormik} from "formik";
import * as Yup from "yup";
import {AuthLayout, useAuth} from "../../auth";
import {unstable_batchedUpdates} from "react-dom";
import {createUser} from "../../../core/_requests";
import { Link } from 'react-router-dom';



const registrationSchema = Yup.object().shape({
    companyName: Yup.string().required('Le nom de l\'entreprise est requis'),
    companyWebsite: Yup.string().required('Le site internet de l\'entreprise est requis'),
    role: Yup.string().required('Votre type de compte est requis'),
    siret: Yup.string().required('Le SIRET est requis')
        .matches(/^\d{14}$/, 'Le SIRET doit contenir exactement 14 chiffres'),
    siren: Yup.string().required('Le SIREN est requis')
        .matches(/^\d{9}$/, 'Le SIREN doit contenir exactement 9 chiffres'),
    companyAddress: Yup.string().required('L\'adresse de l\'entreprise est requise'),
    companyZipCode: Yup.string()
        .required('Le code postal de l\'entreprise est requis')
        .matches(/^\d{5}$/, 'Le code postal doit contenir exactement 5 chiffres'),
    companyCity: Yup.string().required('La ville de l\'entreprise est requise'),
    companyPhoneNumber: Yup.string()
        .required('Le numéro de téléphone de l\'entreprise est requis')
        .matches(/^\d{10}$/, 'Le numéro de téléphone doit contenir exactement 10 chiffres'),
    email: Yup.string().email('Adresse e-mail invalide').required('L\'adresse e-mail est requise'),
    password: Yup.string()
        .min(8, 'Au moins 8 caractères requis')
        .max(50, 'Maximum 50 caractères autorisés')
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, 'Le mot de passe doit contenir au moins 8 caractères, dont des lettres , des chiffres et des symboles')
        .required('Le mot de passe est requis'),
    passwordConfirmation: Yup.string()
        .min(8, 'Au moins 8 caractères requis')
        .max(50, 'Maximum 50 caractères autorisés')
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, 'Le mot de passe doit contenir au moins 8 caractères, dont des lettres, des chiffres et des symboles')
        .required('Le mot de passe est requis')
        .oneOf([Yup.ref('password')], 'Les mots de passe doivent correspondre'),
    contactFirstName: Yup.string().required('Le prénom du contact est requis'),
    contactLastName: Yup.string().required('Le nom de famille du contact est requis'),
    contactPhoneNumber: Yup.string()
        .required('Le numéro de téléphone du contact est requis')
        .matches(/^\d{10}$/, 'Le numéro de téléphone doit contenir exactement 10 chiffres'),
    contactPosition: Yup.string().required('Le poste du contact est requis'),
    uai: Yup.string()
        .required('Le code UAI est obligatoire')
        .matches(/^[0-9]{7}[A-Za-z]$/, 'Le code UAI doit être composé de 7 chiffres et d\'une lettre'),
    acceptTerms: Yup.bool().required('Vous devez accepter les règles générales'),
});
const Registration = () => {
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [step, setStep] = useState(0);
    const {  logout, setCurrentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            companyName: '',
            siret: '',
            siren: '',
            companyWebsite: '',
            companyAddress: '',
            companyZipCode: '',
            companyCity: '',
            companyPhoneNumber: '',
            email: '',
            password: '',
            passwordConfirmation:'',
            contactFirstName: '',
            contactLastName: '',
            contactPhoneNumber: '',
            contactPosition: '',
            uai: '',
            role: '',
            acceptTerms: false,
        },
        validationSchema: registrationSchema,
        onSubmit: async (values) => {


        },
    });


    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const checkStep1 = (): boolean => {
        if (formik.errors.role == undefined && formik.values.role !=="") {

            return true
        }else {
            formik.setStatus('Un type de compte est obligatoire');
                return false; // Validation failed
            }
        return false
    }
    const checkStep2 = (): boolean => {
        if (formik.values.role=='cfa'){
            if (formik.errors.companyName == undefined && formik.errors.uai == undefined
                && formik.errors.companyAddress == undefined && formik.errors.companyZipCode == undefined
                && formik.errors.companyCity == undefined && formik.errors.companyWebsite == undefined && formik.errors.companyPhoneNumber == undefined
                && formik.errors.email == undefined && formik.errors.password == undefined
                && formik.errors.passwordConfirmation == undefined) {
                return true
            }else {
                const hasErrors =
                    formik.errors.companyName !== undefined ||
                    formik.errors.companyWebsite !== undefined ||
                    formik.errors.uai !== undefined ||
                    formik.errors.companyAddress !== undefined ||
                    formik.errors.companyZipCode !== undefined ||
                    formik.errors.companyCity !== undefined ||
                    formik.errors.companyPhoneNumber !== undefined ||
                    formik.errors.email !== undefined ||
                    formik.errors.password !== undefined ||
                    formik.errors.passwordConfirmation !== undefined;

                if (hasErrors) {
                    // Concatenate error messages from each field
                    const errorMessages = [
                        formik.errors.companyName,
                        formik.errors.companyWebsite,
                        formik.errors.uai,
                        formik.errors.companyAddress,
                        formik.errors.companyZipCode,
                        formik.errors.companyCity,
                        formik.errors.companyPhoneNumber,
                        formik.errors.email,
                        formik.errors.password,
                        formik.errors.passwordConfirmation,
                    ].filter((errorMessage) => errorMessage !== undefined)
                        .join(' ');

                    formik.setStatus(errorMessages);
                    return false; // Validation failed
                }
            }
            return false
        }else{
            if (formik.errors.companyName == undefined
                && formik.errors.siret == undefined && formik.errors.siren == undefined
                && formik.errors.companyAddress == undefined && formik.errors.companyZipCode == undefined
                && formik.errors.companyCity == undefined && formik.errors.companyPhoneNumber == undefined
                && formik.errors.email == undefined && formik.errors.password == undefined
                && formik.errors.passwordConfirmation == undefined) {
                return true
            }else {
                const hasErrors =
                    formik.errors.companyName !== undefined ||
                    formik.errors.siret !== undefined ||
                    formik.errors.siren !== undefined ||
                    formik.errors.companyAddress !== undefined ||
                    formik.errors.companyZipCode !== undefined ||
                    formik.errors.companyCity !== undefined ||
                    formik.errors.companyPhoneNumber !== undefined ||
                    formik.errors.email !== undefined ||
                    formik.errors.password !== undefined ||
                    formik.errors.passwordConfirmation !== undefined;

                if (hasErrors) {
                    // Concatenate error messages from each field
                    const errorMessages = [
                        formik.errors.companyName,
                        formik.errors.siret,
                        formik.errors.siren,
                        formik.errors.companyAddress,
                        formik.errors.companyZipCode,
                        formik.errors.companyCity,
                        formik.errors.companyPhoneNumber,
                        formik.errors.email,
                        formik.errors.password,
                        formik.errors.passwordConfirmation,
                    ]
                        .filter((errorMessage) => errorMessage !== undefined)
                        .join(' ');

                    formik.setStatus(errorMessages);
                    return false; // Validation failed
                }
            }
            return false
        }

    }
    const checkStep3 = (): boolean => {
        console.log(formik.errors)
        if (formik.errors.contactFirstName == undefined && formik.errors.contactLastName == undefined
            && formik.errors.contactPhoneNumber == undefined && formik.errors.contactPosition == undefined) {
            return true
        }else{
            const hasErrors =
                formik.errors.contactFirstName !== undefined ||
                formik.errors.contactLastName !== undefined ||
                formik.errors.contactPhoneNumber !== undefined ||
                formik.errors.contactPosition !== undefined ;

            if (hasErrors) {
                // Concatenate error messages from each field
                const errorMessages = [
                    formik.errors.contactFirstName,
                    formik.errors.contactLastName,
                    formik.errors.contactPhoneNumber,
                    formik.errors.contactPosition,
                ]
                    .filter((errorMessage) => errorMessage !== undefined)
                    .join(' ');

                formik.setStatus(errorMessages);
                return false; // Validation failed

            }
        }
        return false;

    }
    const prevStep = () => {
        formik.setStatus(undefined);
        if (!stepper.current) {
            return
        }

        stepper.current.goPrev()
        setStep(stepper.current?.currentStepIndex)
    }

    const nextStep = () => {
        formik.setStatus(undefined);

        if (!stepper.current) {

            return
        }

        if (stepper.current.currentStepIndex=== 1) {
         if (!checkStep1()) {
                formik.errors.role=""

                return
            }
        }
        if (stepper.current.currentStepIndex === 2) {

            if (!checkStep2()) {
                return
            }
        }

        stepper.current.goNext()
        setStep(stepper.current?.currentStepIndex)
    }

    const submit = async () => {
        setLoading(true);
        try {
            // Prepare the data
            const data = {
                companyName: formik.values.companyName,
                companyWebsite: formik.values.companyWebsite,
                siret: formik.values.siret,
                siren: formik.values.siren,
                companyAddress: formik.values.companyAddress,
                companyZipCode: formik.values.companyZipCode,
                companyCity: formik.values.companyCity,
                companyPhoneNumber: formik.values.companyPhoneNumber,
                email: formik.values.email,
                password: formik.values.password,
                contactFirstName: formik.values.contactFirstName,
                contactLastName: formik.values.contactLastName,
                contactPhoneNumber: formik.values.contactPhoneNumber,
                contactPosition: formik.values.contactPosition,
                uai: formik.values.uai,
                role: formik.values.role,
            };

            // Send the data to the server for processing
            // Replace `editProfile` with the appropriate function to save project details
            const response = await createUser(data, logout);
            if(response.message === 'Success'){
                stepper.current?.goNext();
                formik.resetForm({
                    values: formik.initialValues,
                });
                console.log('success')
                setLoading(false);
            }
            if(response.error ==='User with the same email already exists'){
                formik.setStatus('Un compte avec cet email est déjà existant')
                setLoading(false);
            }

        } catch (error) {
            console.error(error);
            setLoading(false);
        }
        //window.location.reload()
    }

    useEffect(() => {

        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef,formik])


    return (<AuthLayout>
    <div className='card'>
        <div className='card-body'>
            <div
                ref={stepperRef}
                className='stepper stepper-links d-flex flex-column pt-15'
                id='create_account_stepper'
            >
                <div className='stepper-nav mb-5'>
                    <div className='stepper-item current' data-stepper-element='nav'>
                        <h3 className='stepper-title'>Type de compte</h3>
                    </div>
                    <div className='stepper-item' data-stepper-element='nav'>
                        <h3 className='stepper-title'>Information sur l'entreprise</h3>
                    </div>
                    <div className='stepper-item ' data-stepper-element='nav'>
                        <h3 className='stepper-title'>Information du contact</h3>
                    </div>
                    <div className='stepper-item' data-stepper-element='nav'>
                        <h3 className='stepper-title'>Activer</h3>
                    </div>
                </div>

                <form onSubmit={formik.handleSubmit} className='mx-auto mw-600px w-100 pt-15 pb-10' id='create_account_form'>
                            <div className='current' data-stepper-element='content'>
                                <RegistrationStep1 formik={formik} />
                            </div>

                            <div data-stepper-element='content'>
                                <RegistrationStep2 formik={formik} />
                            </div>

                            <div data-stepper-element='content'>
                                <RegistrationStep3  formik={formik}/>
                            </div>
                            <div data-stepper-element='content'>
                                <RegistrationStep4 formik={formik}/>
                            </div>
                    {formik.status && (
                        <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{formik.status}</div>
                        </div>
                    )}
                            <div className='d-flex flex-stack pt-10'>
                               <div className='me-2'>
                                   {( stepper.current?.currentStepIndex == 2|| stepper.current?.currentStepIndex == 3) &&<button
                                        type='button'
                                        className='main-btn-3 me-3 main-btn-small'
                                        style={{display: 'flex', alignItems: 'center'}}
                                        onClick={prevStep}
                                    >
                                        <KTIcon iconName='arrow-left' className='fs-3 me-1' /> Précédent
                                    </button>}
                                   {( !stepper.current || stepper.current?.currentStepIndex == 1) &&<Link to='/connexion'><button
                                       type='button'
                                       className=' main-btn-3 me-3 main-btn-small'
                                       style={{display: 'flex', alignItems: 'center'}}
                                   >
                                       <KTIcon iconName='arrow-left' className='fs-3 me-1' /> Annuler
                                   </button></Link>}
                                </div>
                                <div >
                                    {stepper.current?.currentStepIndex == 3 &&<button
                                        type='button'
                                        className=' main-btn-small'

                                        onClick={()=>submit()}
                                        disabled={loading||!formik.values.acceptTerms}
                                        style={{display: 'flex', alignItems: 'center'}}
                                    >
                                        {!loading ? 'Valider' : 'Patientez...'}
                                        {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>} <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                                    </button>}

                                    {(!stepper.current?.currentStepIndex ||  stepper.current?.currentStepIndex == 1 || stepper.current?.currentStepIndex == 2) && <button
                                        type='button'
                                        className=' main-btn-small'
                                        onClick={nextStep}
                                        style={{display: 'flex', alignItems: 'center'}}
                                    >
                                        Suivant <KTIcon iconName='arrow-right' className='fs-3 ms-1 me-0' />
                                    </button>}
                                </div>
                            </div>
                        </form>
            </div>
        </div>
    </div>
        </AuthLayout>
    )
}

export {Registration}
