import {FC} from 'react'

type Props = {
  address?: string
  city?: string
  zipcode?: string
}

const UserAddress: FC<Props> = ({address,city,zipcode}) => (
    <div className='d-flex align-items-center'><div className='d-flex flex-column'>
    <span>
      {address}
    </span>
    <span>{zipcode} {city}</span>
    </div></div>
)

export {UserAddress}
