import ContactFormArea from "../components/ContactArea/ContactFormArea";
import ContactInfoArea from "../components/ContactArea/ContactInfoArea";
import ContactMap from "../components/ContactArea/ContactMap";
import Header from "../components/Header/Header";
import Layout from "../components/Layout/Layout";
import PageTitle from "../components/Reuseable/PageTitle";
import React from "react";
import HeaderFront2 from "../components/Header/HeaderFront2";

const Contact : React.FC = () => {
  return (
    <Layout>
        <HeaderFront2/>
        <PageTitle title="Contact" />
        <ContactFormArea />
      {/*<Header />
      <PageTitle title="Contact" />
      <ContactFormArea />
      <ContactInfoArea />
      <ContactMap />*/}
    </Layout>
  );
};

export default Contact;
