/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useAuth} from '../../pages/auth'
import {KTIcon, toAbsoluteUrl} from '../../../_rebusiness/helpers'
import {UserMenu} from '../user-menu/UserMenu'

const AsideUserMenu: FC = () => {
  const {currentUser} = useAuth()

  return (
    <>
      <div className='d-flex flex-stack'>
        {/* begin::Wrapper */}
        <div className='d-flex align-items-center'>
          {/* begin::Avatar */}
          <div className='symbol symbol-circle symbol-40px'>
            {currentUser?.avatar ? <img src={toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL+'/'+currentUser?.avatar)} alt='avatar' />:<div className="symbol symbol-40px">
              <div className="symbol-label fs-1 fw-bold bg-info text-inverse-info">{currentUser?.contactFirstName.slice(0,1)}</div></div>}
          </div>
          {/* end::Avatar */}
          {/* begin::User info */}
          <div className='ms-2'>
            <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder lh-1'>
              {currentUser?.contactLastName.slice(0,1)}. {currentUser?.contactFirstName}
            </a>
            <span className='text-muted fw-bold d-block fs-7 lh-1'>{currentUser?.role}</span>
          </div>
          {/* end::User info */}
        </div>
        {/* end::Wrapper */}

        {/* begin::User menu */}
       {/* <div className='ms-1'>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary position-relative me-n2'
            data-menu-trigger='click'
            data-menu-overflow='false'
            data-menu-placement='top-end'
          >

            <KTIcon iconName='exit-right' className='fs-1' />
          </div>
          <UserMenu />
        </div>*/}
        {/* end::User menu */}
      </div>
    </>
  )
}

export {AsideUserMenu}
