/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../_rebusiness/helpers'
import {AsideMenuItemWithSub} from '../../../_rebusiness/layout/components/aside/AsideMenuItemWithSub'
import {AsideMenuItem} from '../../../_rebusiness/layout/components/aside/AsideMenuItem'
import {useAuth} from "../auth";
import {Navigate, useNavigate} from "react-router-dom";

export function AsideMenuAdmin() {
    const {currentUser, logout} = useAuth()
    const intl = useIntl()


  return (
    <>
      <AsideMenuItem
        to='/admin'
        icon='black-right'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Cfa</span>
        </div>
      </div>
        <AsideMenuItem
            to='/admin/gestion/cfa'
            icon='black-right'
            title='Gestion des CFA'
            fontIcon='bi-layers'
        />
        <AsideMenuItem
            to='/admin/gestion/projets'
            icon='black-right'
            title='Gestion des Projets'
            fontIcon='bi-layers'
        />
        <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Entreprises</span>
            </div>
        </div>
        <AsideMenuItem
            to='/admin/gestion/entreprises'
            icon='black-right'
            title='Gestion des Entreprises'
            fontIcon='bi-layers'
        />
        <AsideMenuItem
            to='/admin/gestion/paiements'
            icon='black-right'
            title='Gestion des Paiements'
            fontIcon='bi-layers'
        />
        <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Suppliers</span>
            </div>
        </div>
        <AsideMenuItem
            to='/admin/gestion/fournisseurs'
            icon='black-right'
            title='Gestion des Suppliers'
            fontIcon='bi-layers'
        />
        <AsideMenuItem
            to='/admin/gestion/produits'
            icon='black-right'
            title='Gestion des Produits'
            fontIcon='bi-layers'
        />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
          <a onClick={logout} className='menu-link'>
              <span className='menu-icon'>
            <KTIcon iconName='exit-right' className='fs-2' />
          </span>
              <span className='menu-title'>  Déconnexion</span>
          </a>
      </div>
    </>
  )
}
