import { projectDetailsArea } from "../../../data/projectsArea";
import React,{ useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

const {

  socials,
} = projectDetailsArea;

const ProductDetailsArea = ({product}) => {
  const [copySuccess, setCopySuccess] = useState('Copier la référence du produit');
  return (
    <section style={{marginTop:'50px'}} className="project-details-area pt-120 pb-190">
      <Container>
        <Row>
          <Col lg={7}>
            <div className="project-details-thumb" style={{ backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}/${product.productImages})` }}>
              <div className="icon">
                <i className="fa fa-heart"></i>
              </div>
            </div>
          </Col>
          <Col lg={5}>
            <div className="project-details-content">
              <div className="details-btn">

                <div className={'flag'}>
                  <Image style={{height:'50px',borderRadius:'50px '}}  src={process.env.REACT_APP_PUBLIC_URL+'/'+product.User?.avatar} alt="logo cfa" />
                  <p>{product.User?.companyName} | {product.User?.companyCity}<br/>{product.User?.companyWebsite&&<a target="_blank" rel="noopener noreferrer" href={'https://'+product.User?.companyWebsite}>{product.User?.companyWebsite}</a>}</p>
                </div>
              </div>

              <h3 className="title">{product.productName}</h3>
              {/*<div className="project-details-item">
                <div className="item text-center">
                  <h5 className="title text-dark">xxxxxx €</h5>
                  <span>Collectés</span>
                </div>
                <div className="item text-center">
                  <h5 className="title text-dark">xxxxxxx</h5>
                  <span>Contributeurs</span>
                </div>
                <div className="item text-center">
                  <h5 className="title text-dark"></h5>
                  <span>Jours Restants</span>
                </div>
              </div>*/}
              {/*<div className="projects-range">
                <div className="projects-range-content">
                  <ul>
                    <li>Collecté:</li>
                    <li>{Math.floor((product.totalContributions / product.projectCost) * 100)}%</li>
                  </ul>
                  <div className='h-10px  w-100  mb-3' style={{backgroundColor:'#e8e8e8'}}>
                    <div className='square h-10px' role='progressbar'  aria-valuemin={0} aria-valuemax={100} style={{ width: `${Math.min(Math.floor((product.totalContributions / product.projectCost)*100),100)||0}%`,backgroundColor:'#28f0b4' }}></div>
                  </div>
                </div>
              </div>*/}
              <div className="projects-goal">
                <span>
                  Référence produit: <span className={'text-dark'}>{product.uniqueID} </span>
                </span><br/>
                <span>
                  Prix: <span className={'text-dark'}>{product.productPrice?.toLocaleString('fr-FR')} €</span>
                </span>
              </div>
              <div className="project-btn mt-25" >
                               <a className="main-btn"
                   onClick={(e) => {
                     e.preventDefault();
                     navigator.clipboard.writeText(product.uniqueID)
                         .then(() => {
                           setCopySuccess('Copié!');
                           setTimeout(() => setCopySuccess('Copier la référence du produit'), 2000);
                         })
                         .catch(err => {
                           console.error('Error copying product reference: ', err);
                           setCopySuccess('Echec de copie');
                           setTimeout(() => setCopySuccess('Copier la référence du produit'), 2000);
                         });
                   }}
                   disabled={product.productStock === 0}
                   style={{width:'100%'}}
                   href="#"
                >
                                 {copySuccess && <div style={{ marginTop: '10px' }}>{copySuccess}</div>}
                </a>
              </div>
              <div className="project-btn mt-25" >
                <a className={'main-btn-small main-btn-3'}  style={{width:'100%'}} href={`mailto:${product.User?.email}`} rel="noopener noreferrer">
                  Envoyez un message
                </a>
              </div>
             {/* <div className="project-share d-flex align-items-center">
                <span>Partager ce projet</span>
                <ul>
                  {socials.map(({ id, icon, href }) => (
                    <li key={id}>
                      <a href={href}>
                        <i className={icon}></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>*/}
            </div>
          </Col>
        </Row>
      </Container>
    </section>

  );
};

export default ProductDetailsArea;
