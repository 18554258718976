import React, { FC,useEffect } from 'react';
import { KTIcon } from '../../../../../_rebusiness/helpers';
import {Field} from "formik";

export type StepProps = {
  formik: any;
};

const RegistrationStep1: FC<StepProps> = ({ formik }) => {
  return (
      <div className='w-100'>
        <div className='pb-10 pb-lg-15'>
          <h2 className='fw-bolder d-flex align-items-center text-dark'>
            Choisissez votre type de compte
            <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Le type de compte est obligatoire'
            ></i>
          </h2>

          <div className='text-gray-400 fw-bold fs-6'>
              Si vous avez des questions, <a href={'/contact'}>contactez nous.</a>
          </div>
        </div>

        <div className='fv-row'>
          <div className='row'>

            <div className='col-lg-4'>
              <input
                  type='radio'
                  className='btn-check'
                  name='role'
                  value='cfa'
                  id='role_cfa'
                  onClick={()=>formik.setFieldValue('role', 'cfa')}
              />

              <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex flex-column  align-items-center mb-10'
                  htmlFor='role_cfa'
              >
                <i className='fas fa-user-graduate fs-3x'></i><br/>
                <span className='d-block fw-bold text-center'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Compte<br/> CFA</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  Déposez votre projet et pilotez votre campagne de financement n’a jamais été aussi simple.
                </span>
              </span>
              </label>
            </div>

            <div className='col-lg-4'>
              <input
                  type='radio'
                  className='btn-check'
                  name='role'
                  value='entreprise'
                  id='role_enterprise'
                  onClick={()=>formik.setFieldValue('role', 'entreprise')}
              />
              <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex flex-column  align-items-center mb-10'
                  htmlFor='role_enterprise'
              >
                  <i className='fas fa-hand-holding-heart fs-3x'></i><br/>

                <span className='d-block fw-bold text-center'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Compte Entreprise</span>
                <span className='text-gray-400 fw-bold fs-6'>
                 Choisissez un projet à soutenir, avec le solde de votre taxe d’apprentissage, en lien avec vos métiers, votre territoire ou encore votre démarche RSE…
                </span>
              </span>
              </label>
            </div>
            <div className='col-lg-4'>
              <input
                  type='radio'
                  className='btn-check'
                  name='role'
                  value='fournisseur'
                  id='role_fournisseur'
                  onClick={() => formik.setFieldValue('role', 'fournisseur')}
              />
              <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex flex-column align-items-center mb-10'
                  htmlFor='role_fournisseur'
              >
                  <i className='fas fa-truck-moving fs-3x'></i><br/>


                  <span className='d-block fw-bold text-center'>
      <span className='text-dark fw-bolder d-block fs-4 mb-2'>Compte Fournisseur</span>
      <span className='text-gray-400 fw-bold fs-6'>
        Mettez à disposition votre catalogue pour faciliter le processus de financement.
      </span>
    </span>
              </label>
            </div>


            <div className='text-danger mt-2'>
              {formik.touched.role && formik.errors.role && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.role}</div>
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>
  );
};

export { RegistrationStep1 };
