/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_rebusiness/helpers'
import {Product,User} from '../../core/_models'

type Props = {
  product: Product
    user:User
}

const ProductInfoCell: FC<Props> = ({product,user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-60px symbol-2by3 flex-shrink-0 me-4'>

      <a href={`/project/${product.uniqueID}`}>
        {product.productImages ? (
          <div className='symbol symbol-60px symbol-2by3 flex-shrink-0 me-4'>
            <img src={toAbsoluteUrl(`${process.env.REACT_APP_PUBLIC_URL}/${product.productImages}`)} alt={ product.productName} className='mw-100' />

          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-${user?.contactFirstName?.slice(0,1)}`,
              `text-${user?.contactLastName?.slice(0,1)}`
            )}
          >
            {user.contactFirstName?.slice(0,1)}.{user?.contactLastName?.slice(0,1)}
          </div>
        )}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <a href={`/project/${product.uniqueID}`}  target='_blank'
         rel='noopener noreferrer' className='text-gray-800 text-hover-primary mb-1'>
        {product.productName.slice(0,50)} <div className='badge badge-light fw-bolder'> </div>
      </a>
      <span>{product.productDescription.slice(0,200)}</span>
    </div>
  </div>
)

export {ProductInfoCell}
