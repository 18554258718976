/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../_rebusiness/helpers'

const HelpDrawer = () => {
  return (
    <div
      id='help'
      className='bg-body'
      data-drawer='true'
      data-drawer-name='help'
      data-drawer-activate='true'
      data-drawer-overlay='true'
      data-drawer-width="{default:'350px', 'md': '525px'}"
      data-drawer-direction='end'
      data-drawer-toggle='#help_toggle'
      data-drawer-close='#help_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100'>
        {/* begin::Header */}
        <div className='card-header' id='help_header'>
          <h5 className='card-title fw-bold text-gray-600'>Besoin d'aide</h5>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='help_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body' id='help_body'>
          {/* begin::Content */}
          <div
            id='help_scroll'
            className='hover-scroll-overlay-y'
            data-scroll='true'
            data-scroll-height='auto'
            data-scroll-wrappers='#help_body'
            data-scroll-dependencies='#help_header'
            data-scroll-offset='5px'
          >
            {/* begin::Support */}
            <div className='rounded border border-dashed border-gray-300 p-6 p-lg-8 mb-10'>
              {/* begin::Heading */}
              <h2 className='fw-bolder mb-5'>
                Consulter notre FAQ{' '}
                <a href='#' className=''>
                 Lien FAQ
                </a>
              </h2>
              {/* end::Heading */}

              {/* begin::Description */}
              <div className='fs-5 fw-bold mb-5'>
                <span className='text-gray-500'>
                  Notre foire aux questions est la pour répondre aux questions les plus fréquentes
                </span>
                <a className='explore-link d-none' href='/faq'>
                  FAQs
                </a>
              </div>
              {/* end::Description */}

              {/* begin::Link */}
              <a
                href='/faq'
                className='main-btn-back w-100'
              >
                FAQ
              </a>
              {/* end::Link */}
            </div>
            {/* end::Support */}

            {/* begin::Link */}
            <div className='d-flex align-items-center mb-7'>
              {/* begin::Icon */}
              <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-warning'>
                <KTIcon iconName='abstract-26' className='text-warning fs-2x text-lg-3x' />
              </div>
              {/* end::Icon */}
              {/* begin::Info */}
              <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                {/* begin::Wrapper */}
                <div className='d-flex flex-column me-2 me-lg-5'>
                  {/* begin::Title */}
                  <a
                    href='/faq'
                    className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'
                  >
                    Entreprise Donatrice
                  </a>
                  {/* end::Title */}
                  {/* begin::Description */}
                  <div className='text-muted fw-bold fs-7 fs-lg-6'>
                    Des questions légales, juridiques, financières ou d'ordre administratives.
                  </div>
                  {/* end::Description */}
                </div>
                {/* end::Wrapper */}
                <KTIcon iconName='arrow-right' className='text-gray-400 fs-2' />
              </div>
              {/* end::Info */}
            </div>
            {/* end::Link */}
            {/* begin::Link */}
            <div className='d-flex align-items-center mb-7'>
              {/* begin::Icon */}
              <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-primary'>
                <KTIcon iconName='gift' className='text-primary fs-2x text-lg-3x' />
              </div>
              {/* end::Icon */}
              {/* begin::Info */}
              <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                {/* begin::Wrapper */}
                <div className='d-flex flex-column me-2 me-lg-5'>
                  {/* begin::Title */}
                  <a
                    href='faq'
                    className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'
                  >
                    CFA
                  </a>
                  {/* end::Title */}
                  {/* begin::Description */}
                  <div className='text-muted fw-bold fs-7 fs-lg-6'>
                    Des conseils d'utilisations, de communications ou d'ordre juridique.
                  </div>
                  {/* end::Description */}
                </div>
                {/* end::Wrapper */}
                <KTIcon iconName='arrow-right' className='text-gray-400 fs-2' />
              </div>
              {/* end::Info */}
            </div>
            {/* end::Link */}

          </div>
          {/* end::Content */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Card */}
    </div>
  )
}

export {HelpDrawer}
