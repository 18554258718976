/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_rebusiness/helpers'
import {useAuth} from "../../pages/auth";
import {getProjectsByUserId} from "../../core/_requests";
import {Link} from "react-router-dom";
import {ProjectCard} from "./ProjectCard";
import {Project} from './../../core/_models'
type Props = {
    className: string
}
const ProjectList: React.FC<Props> = ({className}) => {
    const { currentUser,logout,setCurrentUser } = useAuth();
    const [projects, setProjects] = useState<Project[]>([]); // Specify the type of the projects state
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const projectsData = await getProjectsByUserId(logout);
                setProjects(projectsData);
            } catch (error) {
                console.error(error);
                // Handle error if necessary
            }
        };
        fetchProjects();
    }, [logout]);
    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0'>
                <h3 className='card-title fw-bold text-dark'>Mes Projets</h3>
                <div className='card-toolbar'>
                    <Link to='/cfa/myprojects' className='main-btn-3 main-btn-small  align-self-center'>
                       creer votre projet
                    </Link>
                    {/* begin::Menu */}
                    {/*<button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-menu-trigger='click'
            data-menu-placement='bottom-end'
            data-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />*/}
                    {/* end::Menu */}
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-2'>
                {projects.length>0? projects.map((project) => (
                    <div key={project.id} className='d-flex align-items-center mb-7'>
                        {/* begin::Avatar */}
                        <div className='symbol symbol-2by3 symbol-100px me-5'>
                            <img src={toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL+'/'+project?.projectImages)} className='' alt='' />
                        </div>
                        {/* end::Avatar */}
                        {/* begin::Text */}
                        <div className='flex-grow-1'>
                            <a href={'/project/'+project.uniqueID} className='text-dark fw-bold text-hover-primary fs-6'>
                                {project.projectName.slice(0,100) }
                            </a>
                            <span className='text-muted d-block fw-semibold'>{project.projectDescription.slice(0,200)+'[...]'}</span>
                        </div>
                        {/* end::Text */}
                    </div>
                )):null}
                {/* begin::Item */}

                {/* end::Item */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export {ProjectList}
