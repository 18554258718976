import {FC} from 'react'

type Props = {
  status?: string
}

const UserStatus: FC<Props> = ({status}) => (
  <>{status ==='email verification pending' && <div className='badge badge-light-danger fw-bolder'>Email Non vérifié</div>}
    {status ==='admin verification pending' && <div className='badge badge-light-warning fw-bolder'>En attente de l'admin</div>}
    {status ==='banned' && <div className='badge badge-light-danger fw-bolder'>Banni</div>}
    {status ==='activated' && <div className='badge badge-light-success fw-bolder'>Validé</div>}</>
)

export {UserStatus}
