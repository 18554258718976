/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
export type StepProps = {
  formik:any
}
const Step4 = ({formik}: StepProps) => {
  return (
    <>
      {/*begin::Step 4 */}
      <div className='pb-5' data-stepper-element='content'>
        <div className='w-100' style={{minHeight:'400px'}}>
          {/*begin::Form Group */}
          <div className='fv-row mb-10 form-check form-switch form-check-custom form-check-solid'>
            <label className='required fs-5 fw-semibold mb-2'>Diffusion élargie / restreinte</label>
            <input
                className='form-check-input'
                type='checkbox'
                id='isPrivate'
                {...formik.getFieldProps('isPrivate')}
            />
            <label className='form-check-label' htmlFor='isPrivate'>
              Diffusion restreinte
            </label>
          </div>
          {formik.touched.isPrivate && formik.errors.isPrivate && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.isPrivate}</div>
              </div>
          )}
          <div className='fv-row mb-10'>
            <label className='required fs-5 fw-semibold mb-2'>Date de la fin de campagne</label>

            <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                placeholder='Date limite'
                {...formik.getFieldProps('deadlineDate')}
            />
            {formik.touched.deadlineDate && formik.errors.deadlineDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.deadlineDate}</div>
                </div>
            )}
          </div>
          {/*end::Form Group */}
          </div>

      </div>
      {/*end::Step 4 */}
    </>
  )
}

export {Step4}
