import React from "react";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";

const Link = ({
                children,
                to,
                replace,
                innerRef,
                ...props
              }) => {
  const location = useLocation();

  // Get the current pathname
  const currentPathname = location.pathname;

  const linkProps = {
    to,
    replace,
    innerRef,
  };

  // Determine if the link is active
  const isActive = currentPathname === to;

  return (
      <ReactRouterLink {...linkProps}>
        <a href={to} {...props} className={isActive ? "active" : ""}>
          {children}
        </a>
      </ReactRouterLink>
  );
};

export default Link;
