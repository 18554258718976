
import Layout from "../components/Layout/Layout";
import ExploreArea from "../components/ProjectsArea/ExploreArea";
import PageTitle from "../components/Reuseable/PageTitle";
import React from "react";
import HeaderFront2 from "../components/Header/HeaderFront2";

const Projects : React.FC = () => {
  return (
    <Layout>
        <HeaderFront2/>
      <PageTitle title="Explorer" />
      <ExploreArea />
    </Layout>
  );
};

export default Projects;
