import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useProductQueryResponseData, useProductQueryResponseLoading} from '../core/QueryResponseProvider'
import {ProductsColumns} from './columns/_columns'
import {Product} from '../core/_models'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {ProductsListPagination} from '../components/pagination/ProductsListPagination'
import {KTCardBody} from '../../../../../_rebusiness/helpers'

const ProductsTable = () => {
  const products = useProductQueryResponseData()
  const isLoading = useProductQueryResponseLoading()
  const data = useMemo(() => products, [products])
  const columns = useMemo(() => ProductsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='table_products'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Product>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Product>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Rien à afficher
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ProductsListPagination />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export {ProductsTable}
