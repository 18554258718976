import React  from "react";
import BannerSlider from "../components/BannerSlider/BannerSlider";
import CtaArea from "../components/CtaArea/CtaArea";
import Layout from "../components/Layout/Layout.js";
import ProjectsArea from "../components/ProjectsArea/ProjectsArea";
import TogetherArea from "../components/TogetherArea/TogetherArea";
import HeaderFront from "../components/Header/HeaderFront";
import {useLocation} from "react-router-dom";
import {useAuth} from "../../app/pages/auth";
import TestimonialsArea from "../components/Testimonials/TestimonialsArea";
import FunFacts from "../components/FunFacts/FunFacts";
import ClientArea from "../components/ClientArea/ClientArea";

const Home: React.FC = () => {
    return (
        <Layout>
            <HeaderFront/>
            <BannerSlider/>
            <CtaArea />
            <ProjectsArea />
            <FunFacts/>
            <ClientArea/>


        </Layout>
    );
};

export default Home;
