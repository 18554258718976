import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {AsideDefault} from './aside-menus/AsideDefault'
import {Footer} from '../../_rebusiness/layout/components/Footer'
import {HeaderWrapper} from '../../_rebusiness/layout/components/header/HeaderWrapper'
import {RightToolbar} from '../../_rebusiness/partials/layout/RightToolbar'
import {ScrollTop} from '../../_rebusiness/layout/components/ScrollTop'
import {Content} from '../../_rebusiness/layout/components/Content'
import {PageDataProvider} from '../../_rebusiness/layout/core'
import {InviteUsersCFA} from '../pages/cfa/InviteUsersCFA'
import {ThemeModeProvider} from './theme-mode/ThemeModeProvider'
import {MenuComponent} from '../../_rebusiness/assets/ts/components'
import {useAuth} from "../pages/auth";


const MasterLayout = () => {
  const {currentUser} = useAuth()
  const isAdmin = currentUser?.role === 'admin';
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root'>
          <div className='page d-flex flex-row flex-column-fluid'>
            {currentUser?.role == "admin"?<AsideDefault />:null}
            <div className={`${isAdmin ?'wrapper':''} d-flex flex-column flex-row-fluid`} id='wrapper'>
              <HeaderWrapper />

              <div id='content' className='content d-flex flex-column flex-column-fluid'>
                <Content>
                  <Outlet />
                </Content>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        {/* begin:: Drawers */}
        {/*<ActivityDrawer />*/}
        <RightToolbar />
       {/* <DrawerMessenger />*/}
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsersCFA />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
