import footerData from "../../data/siteFooter";
import handleSubmit from "../../utils/handleSubmit";
import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Link from "../Reuseable/Link";
import FooterList from "./FooterList";
import {toAbsoluteUrl} from "../../../_rebusiness/helpers";

const { bg, logo, text,text2, author, year, links, socials, shape } =
  footerData;

const SiteFooter = () => {
  const onSubmit = (data) => console.log(data);
  return (
    <footer
      className="footer-area bg_cover"
      style={{ backgroundImage: `url(${toAbsoluteUrl(bg)})` }}
    >
      <Container >
        <Row style={{ textAlign: 'center', justifyContent: 'center' }}>
          <Col lg={4} md={6} sm={8}>
            <div className="footer-about mt-30 align-items-center">
              <a href="/">
                <Image src={toAbsoluteUrl('/media/assets/images/logo-dark.svg')} alt="" style={{height:'25px'}}/>
              </a>
              <p >{text.split('\n').map((line, index) => (
                  <span key={index}>{line}</span>
              ))}</p>
              <p>Avec le soutien de la FIPA et de l'INFA
                <a target="_blank" rel="noopener noreferrer" href={"https://www.fondation-fipa.net/"}><Image  src={toAbsoluteUrl('/media/logos/fipa.jpg')} alt="" style={{height:'100px'}}/></a>
                <a target="_blank" rel="noopener noreferrer" href={"https://www.infa-formation.com/"}><Image  src={toAbsoluteUrl('/media/logos/INFA.jpg')} alt="" style={{marginLeft:2,height:'100px'}}/></a></p>

              <ul>
                {/*{socials.map(({ id, icon, href }) => (
                  <li key={id}>
                    <a href={href}>
                      <i className={icon}></i>
                    </a>
                  </li>
                ))}*/}
              </ul>
            </div>
          </Col>

        </Row>
        <Row style={{ textAlign: 'center', justifyContent: 'center' }}>
          <Col lg={12}>
            <div className="footer-copyright text-center">
              <p>
                <a href='/mentions_legales.pdf' className='text-muted text-hover-primary px-2'>
                  Mentions Légales
                </a>
                © Copyright {year} by {author}

              </p>

            </div>
          </Col>
        </Row>
      </Container>
      <div className="footer-shape">
        <Image src={shape} alt="" />
      </div>
    </footer>
  );
};

export default SiteFooter;
