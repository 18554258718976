import React from "react";
import { Image } from "react-bootstrap";
import Link from "../Reuseable/Link";
import {toAbsoluteUrl} from "../../../_rebusiness/helpers";

const ProductDetail = ({ product = {} }) => {
  const { productImages, productDescription, productName, productPrice, visits, uniqueID,productCategory,User} = product;
  console.log(product)
  return (
    <div className="explore-projects-item mt-30">
      <div className="explore-projects-thumb">
        <img src={toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL+'/'+`${productImages}`)} alt="explore-projects" />
        <a href="#">
          <i className="fa fa-heart"></i>
        </a>
      </div>
      <div className="explore-projects-content">
        {/*<div className="item d-flex align-items-center">
          <div className='symbol symbol-circle symbol-40px'>
            {product["User.avatar"]?<img src={toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL+'/'+product["User.avatar"])} alt='avatar' />:null}
          </div>
          <p className="ml-20">
            <b>{product["User.companyName"]}</b> <br/>
            <b>{product["User.companyCity"]}</b> <br/>
          </p>
        </div>*/}

        <Link to={`/product/${product.uniqueID}`}>
          <h5 className="title">{productName}</h5>
        </Link>
        <div className="projects-range">
          <div className="projects-range-content">
            {product["productDescription"].slice(0,150)} [...]

          </div>
        </div>
        <div className="projects-goal">
          <span>
            Prix :  <span> {productPrice?.toLocaleString('fr-FR')} €</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
