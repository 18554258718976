/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_rebusiness/helpers'
import { useAuth} from "../../pages/auth";
import {getProductsByUserId} from "../../core/_requests";
import {Link} from "react-router-dom";
import {Product} from './../../core/_models'
import {NewProduct} from "../../pages/supplier/NewProduct";
type Props = {
    className: string
}

const ProductsList: React.FC<Props> = ({className}) => {
    const { currentUser,logout,setCurrentUser } = useAuth();
    const [products, setProducts] = useState<Product[]>();
    const fetchProducts = async () => {
        try {
            const products = await getProductsByUserId(logout);
            console.log(products);
            setProducts(products);
        } catch (error) {
            console.error(error);
            // Handle error if necessary
        }
    };

    const handleModalClose = async () => {
        // Fetch products when the modal is closed
        await fetchProducts();
    };

    useEffect(() => {
        fetchProducts();
    }, [logout]);
    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            {products && ( <><div className='card-header border-0'>
                <h3 className='card-title fw-bold text-dark'>Mes produits</h3>
                <div className='card-toolbar'>
                   <NewProduct onModalClose={handleModalClose}/>
                </div>
            </div>
            <div className='card-body pt-2'>

            {products?.map((product) => (
                        <div key={product.id} className='d-flex align-items-center mb-7'>
                            <div className='symbol symbol-2by3 symbol-100px me-5'>
                                <img src={toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL+'/'+product?.productImages)} className='' alt='' />
                            </div>                            <div className='flex-grow-1'>
                                <a href={'#' + product.id} className='text-dark fw-bold text-hover-primary fs-6'>
                                    {/* Accessing contribution properties */}
                                    {product.productName}
                                </a>
                                <span className='text-muted d-block fw-semibold'>
                                    {product.productDescription}<br/>
                                    {product.productPrice} € - Stock :  {product.productStock}

                </span>
                            </div>
                               {/* <a href={`${process.env.REACT_APP_PUBLIC_URL}/${product?.quote}`}>Modifier</a>*/}
                                <a href={`#`}>Modifier</a>

                        </div>
                    ))}
            </div></>)}
            {/* end::Body */}
        </div>
    )
}

export {ProductsList}
