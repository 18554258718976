import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { KTSVG, toAbsoluteUrl } from '../../../../../_rebusiness/helpers';

// Define the type for the onModalClose prop
interface EditProductModalProps {
    onModalClose: any;
    editProduct: any;
}

const ProductViewModal: React.FC<EditProductModalProps> = ({ editProduct, onModalClose }) => {
    const handleModalClose = () => {
        // Call the callback function to close the modal
        onModalClose();
    };

    return (
        <Modal size="lg" show={editProduct.id !== 0} onHide={handleModalClose} backdrop={true} keyboard={true}>
            <Modal.Header closeButton>
                <Modal.Title>{editProduct.projectName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body">
                    <div id="account_project_details" className="collapse show">
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">Image de couverture</label>
                            <div className="col-lg-8">
                                <div className="image-input image-input-outline" data-image-input="true">
                                    {editProduct.projectImages ? (
                                        <div
                                            className="image-input-wrapper symbol-2by3 w-200px h-125px"
                                            style={{
                                                backgroundSize: '100% auto',
                                                backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL + '/' + editProduct.projectImages})`,
                                            }}
                                        ></div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">Nom du projet</label>
                            <div className="col-lg-8 fv-row">
                                <p>{editProduct.productName}</p>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">Description du projet</label>
                            <div className="col-lg-8 fv-row">
                                <p>{editProduct.productDescription}</p>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">Coût du projet</label>
                            <div className="col-lg-8 fv-row">
                                <p>{editProduct.productPrice}</p>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">Date limite</label>
                            <div className="col-lg-8 fv-row">
                                <p>{new Date(editProduct.deadlineDate).toISOString().split('T')[0]}</p>
                            </div>
                        </div>
                        {/*<div className="mb-10">
                            <div className="form-check form-switch form-check-custom form-check-solid">
                                <label className="col-lg-4 col-form-label required fw-bold fs-6">Accès restreint</label>
                                <input className="form-check-input" type="checkbox" id="isPrivate" checked={editProduct.isPrivate} disabled />
                                <label className="form-check-label" htmlFor="isPrivate">
                                    Projet privé
                                </label>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleModalClose}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export { ProductViewModal };
