import {DisableSidebar, PageTitle} from '../../../_rebusiness/layout/core'

import {ProductsListWrapper} from "./management-list/ProductsList";
import {useAuth} from "../auth";


const ProductsManager = () => {
    const {currentUser}=useAuth()

    return (

        <DisableSidebar>
            <PageTitle description='Administration du site' breadcrumbs={[]}>
                Bonjour{currentUser?.contactFirstName}
            </PageTitle>
            <ProductsListWrapper type={'products'}/>
        </DisableSidebar>
    )
}

export {ProductsManager}
