import shape1 from "../assets/images/fun-facts-shape-1.png";
import shape2 from "../assets/images/fun-facts-shape-2.png";

export const funFacts = {
  facts: [
    {
      id: 1,
      icon: "flaticon-scrum",
      count: 3.2,
      text: "M€ déduits du solde de la TA en 2022 au titre des subventions en nature aux CFA",
    },
    {
      id: 2,
      icon: "flaticon-mission",
      count: 160,
      text: " % d’entrées annuelles en apprentissage entre 2018 et 2022",
    },
    {
      id: 3,
      icon: "flaticon-team",
      count: 837000,
      text: "entrées en apprentissage en 2022",
    },
  ],
  shape1,
  shape2,
};
