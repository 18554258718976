import React, {useRef, useEffect} from 'react'
import {useLocation} from 'react-router'
import {AsideMenuAdmin} from './AsideMenuAdmin'
import {DrawerComponent, ScrollComponent, ToggleComponent} from '../../../_rebusiness/assets/ts/components'
import {useAuth} from "../auth";

type Props = {
  asideMenuCSSClasses: string[]
}

const AsideMenu: React.FC<Props> = ({asideMenuCSSClasses}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const {pathname} = useLocation()
  const {currentUser} = useAuth()

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0
      }
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <div
      id='aside_menu_wrapper'
      ref={scrollRef}
      className='w-100 hover-scroll-overlay-y d-flex pe-2'
      data-scroll='true'
      data-scroll-activate='{default: false, lg: true}'
      data-scroll-height='auto'
      data-scroll-dependencies='#aside_logo, #aside_footer'
      data-scroll-wrappers='#aside, #aside_menu, #aside_menu_wrapper'
      data-scroll-offset='100'
    >
      <div
        id='#aside_menu'
        data-menu='true'
        className='menu menu-column menu-rounded fw-bold my-auto'
      >
        {/*{currentUser?.role === 'admin'? <AsideMenuAdmin />:
            currentUser?.role === 'enterprise'? <AsideMenuEnterprise/>:
                currentUser?.role === 'cfa'? <AsideMenuCFA/> : null}*/}
        {currentUser?.role === 'admin'? <AsideMenuAdmin />: null}

      </div>
    </div>
  )
}

export {AsideMenu}
