import React, { FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { MenuComponent } from '../../../../../../_rebusiness/assets/ts/components';
import { ID, KTIcon, QUERIES } from '../../../../../../_rebusiness/helpers';
import { useUserListView } from '../../core/ListViewProvider';
import { useUserQueryResponse } from '../../core/QueryResponseProvider';
import { deleteUser } from '../../core/_requests';
import { statusUsers } from '../../core/_requests';
import { Modal, Button } from 'react-bootstrap'; // Import the necessary components from react-bootstrap

type Props = {
    id: ID;
    user: any;
};

const UserActionsCell: FC<Props> = ({ id, user }) => {
    const { setItemIdForUpdate } = useUserListView();
    const { query } = useUserQueryResponse();
    const queryClient = useQueryClient();

    useEffect(() => {
        MenuComponent.reinitialization();
    }, []);

    const openEditModal = () => {
        setItemIdForUpdate({ ...user, userId: user.id });
    };

    // Add state to handle the modal visibility
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // Create functions to show and hide the modal
    const showDeleteModalHandler = () => setShowDeleteModal(true);
    const hideDeleteModalHandler = () => setShowDeleteModal(false);

    const validateUser = useMutation(() => statusUsers(id, 'activated'), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
            // ✅ update detail view directly
            queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
        },
    });

    const banUser = useMutation(() => statusUsers(id, 'banned'), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
            // ✅ update detail view directly
            queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
        },
    });

    const deleteItem = useMutation(() => deleteUser(id), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
            // ✅ update detail view directly
            queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
        },
    });

    const confirmDeleteHandler = () => {
        // Close the modal
        hideDeleteModalHandler();
        // Perform the delete action
        deleteItem.mutate();
    };

    return (
        <>
            <div className="d-flex justify-content-end flex-shrink-0">
                {(user?.status === 'admin verification pending' || user?.status === 'banned') && (
                    <a
                        href="#"
                        onClick={() => validateUser.mutate()}
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                    >
                        <KTIcon iconName="check" className="fs-3" />
                    </a>
                )}
                {user?.status === 'activated' && (
                    <a
                        href="#"
                        onClick={() => banUser.mutate()}
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                    >
                        <KTIcon iconName="cross" className="fs-3" />
                    </a>
                )}
                <a href="#" onClick={openEditModal} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <KTIcon iconName="pencil" className="fs-3" />
                </a>
                <a href="#" onClick={showDeleteModalHandler} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                    <KTIcon iconName="trash" className="fs-3" />
                </a>
            </div>

            {/* Modal for delete confirmation */}
            <Modal size="lg" show={showDeleteModal} onHide={hideDeleteModalHandler} backdrop="static" keyboard={false}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Confirmation de suppression</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Êtes-vous sûr de vouloir supprimer cet utilisateur? </strong> <br/>La suppression d'un utilisateur supprimera ses projets, ses invitations envoyées et son activité du site.</p>
                    <p> <strong>Cette action est irréversible</strong></p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideDeleteModalHandler}>
                        Annuler
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Supprimer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export { UserActionsCell };
