import clsx from "clsx";
import React, { useEffect, useState } from "react";
import {Col, Container, Row, Form, Button} from "react-bootstrap";
import ProductDetail from "./SingleProduct";
import {getAllProject, getSearchProduct} from "../../../app/core/_requests";

const SuppliersArea = () => {
    const [products, setProducts] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(1);
    const [items_per_page, setItems_per_page] = useState(10);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const productsData = await getSearchProduct(`page=${page}&items_per_page=${items_per_page}${query && `&search=${query}`}`);
                const productsArray = Object.values(productsData?.products);
                setProducts(productsArray);
                setPagination(productsData?.payload?.pagination);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching projects:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [page, query]); // Fetch data whenever page or query changes

    const updatePage = (newPage) => {
        if (!newPage || loading || pagination.page === newPage) {
            return;
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setPage(newPage);
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent form submission and page reload
        setPage(1);
    };
    const labelMappings = {
        '&laquo; Previous': 'Previous',
        'Next &raquo;': 'Next',
    };

    const mappedLabel = (label) => {
        return labelMappings[label] || label;
    };

    return (
        <section className="explore-area pt-90 pb-120">
            <Container>
<p>Pour que l'enseignement des CFA soit au plus proche de la réalité et des besoins des employeurs, il est primordial de mettre à disposition des apprentis des équipements identiques ou proches de ceux qu'ils utilisent en entreprise. Nous avons donc classés les équipements par catégorie de métiers qui parleront autant aux CFA (projet d'investissement) qu'aux employeurs (soutien à l'acquisition par subvention déductible du solde de la taxe d'apprentissage).</p>
                <Row className="justify-content-center">
                    <Col md={12} className="mb-4">
                        <Form id="searchBar" onSubmit={handleSubmit}>
                            <Row className="align-items-center">
                                <Col xs={9} md={10} className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Rechercher par mot-clés"
                                        style={{height:'64px'}}
                                        value={query}
                                        onChange={(e) => setQuery(e.target.value)}
                                    />
                                </Col>
                                <Col xs={3} md={2} className="mb-3">
                                    <Button type="submit" variant="primary" className="w-100 main-btn-small">
                                        Rechercher
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    {products.map((prod) => (
                        <Col lg={4} md={6} sm={7} key={prod.id}>
                            <ProductDetail product={prod} />
                        </Col>
                    ))}
                </Row>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center mt-10'>
                        <div id='table_projects_paginate'>
                            <ul className='pagination'>
                                {pagination.links
                                    ?.map((link) => {
                                        return {...link, label: mappedLabel(link.label)}
                                    })
                                    .map((link) => (
                                        <li
                                            key={link.label}
                                            className={clsx('page-item', {
                                                active: pagination.page === link.page,
                                                disabled: loading,
                                                previous: link.label === 'Précédent',
                                                next: link.label === 'Suivant',
                                            })}
                                        >
                                            <a
                                                className={clsx('page-link', {
                                                    'page-text': link.label === 'Précédent' || link.label === 'Suivant',
                                                    'me-5': link.label === 'Précédent',
                                                })}
                                                onClick={() => updatePage(link.page)}
                                                style={{cursor: 'pointer'}}
                                            >
                                                {mappedLabel(link.label)}
                                            </a>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default SuppliersArea;
