import React, {useEffect, useState} from "react";
import Layout from "../components/Layout/Layout";
import HeaderFront2 from "../components/Header/HeaderFront2";
import {useLocation} from "react-router-dom";
import {getProductByUniqueId} from "../../app/core/_requests";
import {useAuth} from "../../app/pages/auth";
import {Col, Container, Row} from "react-bootstrap";
import ProductDetailsArea from "../components/Suppliers/ProductDetails/ProductDetailsArea";
import {productDetailsTabBtns} from "../data/productsArea";
import ProductDetailsStory from "../components/Suppliers/ProductDetails/ProductDetailsStory";
import ProjectDetailsSidebar from "../components/ProjectsArea/ProjectDetails/ProjectDetailsSidebar";



const ProductDetails : React.FC =  () => {
    const location=useLocation();
    const {currentUser, logout} = useAuth();
    const [product, setProduct] = useState([]);



        useEffect(() => {
            // Perform any asynchronous operations here
            const fetchData = async () => {
                try {
                    const data = await getProductByUniqueId(location.pathname.split('/')[2], logout)
                    setProduct(data.product)
                } catch (error) {
                    console.error('Error fetching project data:', error);
                }
            };
            fetchData();
        }, [location.pathname]);


    const [current, setCurrent] = useState("pills-home");

    const getClassName = (id = "") => {
        const active = current === id;
        return `tab-pane animated${active ? " fadeIn show active" : ""}`;
    };

    return (
    <Layout>
        <HeaderFront2/>
      {/*<PageTitle title="Single Project" page="Project" />*/}
      <ProductDetailsArea product={product}/>
        <section className="project-details-content-area pb-110">
            <Container>
                <Row >
                    <Col lg={8}>
                        <div className="tab-btns">
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                {productDetailsTabBtns.map(({ id, name }) => (
                                    <li key={id} className="nav-item" role="presentation">
                                        <a
                                            onClick={() => setCurrent(id)}
                                            className={`nav-link cursor-pointer${
                                                id === current ? " active" : ""
                                            }`}
                                            role="tab"
                                        >
                                            {name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                            <ProductDetailsStory product={product} getClassName={getClassName} />
                           {/* <ProjectDetailsUpdates project={project} getClassName={getClassName} />*/}
                        </div>
                    </Col>
                   {/* <Col lg={4} md={7} sm={9}>
                        <ProjectDetailsSidebar />
                    </Col>*/}
                </Row>
            </Container>
        </section>

      {/*<Header />
      <ProjectDetailsContent />
      <SimilarProjects />*/}
    </Layout>
  );
};

export default ProductDetails;
