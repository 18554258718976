import {useContributionListView} from '../../core/ListViewProvider'
import {ContributionsListToolbar} from './ContributionsListToolbar'
import {ContributionsListGrouping} from './ContributionsListGrouping'
import {ContributionsListSearchComponent} from './ContributionsListSearchComponent'

const ContributionsListHeader = () => {
  const {selected} = useContributionListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ContributionsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ContributionsListGrouping /> : <ContributionsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ContributionsListHeader}
