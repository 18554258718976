import FaqDesignArea from "../components/FaqArea/FaqDesignArea";
import FaqTitleArea from "../components/FaqArea/FaqTitleArea";
import Layout from "../components/Layout/Layout";
import PageTitle from "../components/Reuseable/PageTitle";
import React from "react";
import headerData from "../data/headerData";
import HeaderFront2 from "../components/Header/HeaderFront2";
const { logo, navItems, phone, icon, email, address, socials } = headerData;

const Faq : React.FC = () => {
  return (
    <Layout>
        <HeaderFront2/>
        <PageTitle title="FAQs" parent="pages" />
        <FaqTitleArea />
        <FaqDesignArea />
        <p className='text-md-center'>Vous n’avez pas trouvé la réponse à votre question ? <a href={'/contact'}>Posez-nous votre question.</a> Nous y répondrons et la publierons </p><br/><br/>
    </Layout>
  );
};

export default Faq;
