import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import {toAbsoluteUrl} from "../../../_rebusiness/helpers";
import {Swiper, SwiperSlide} from "swiper/react";
import {useSwiperSlide} from "swiper/react";
import { banners } from "../../data/bannerSlider";


const options = {
  slidesPerView: 1,
  loop: true,
  navigation: {
    nextEl: "#main-slider__swiper-button-next",
    prevEl: "#main-slider__swiper-button-prev",
  },
  autoplay: {
    delay: 5000,
  },
};
const BannerSlider : React.FC = () => {

  const isActive = true;
  return (
      <section className={`banner-slider banner-2-area`}>
        <Swiper {...options}>
          <div className="swiper-wrapper">
            {banners.map((singleSlide) => (
                <SwiperSlide key={singleSlide.id}>
                  <div
                      className={`banner-area bg_cover d-flex ${
                          "align-items-center"
                      }`}
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                            '/media/assets/images/' + singleSlide.bg
                        )})`,
                      }}
                  >
                    <Container>
                      <Row
                          className={
                             "justify-content-center"
                          }
                      >
                        <Col lg={8}>
                          <div
                              className={`banner-content 
                                    text-center`}
                          >
                            <div
                                className={`logobox${isActive ? " animated fadeInDown" : ""
                                }`}
                            >
                              <Image
                                  style={{ height: "46px" }}
                                  src={toAbsoluteUrl(
                                      '/media/assets/images/logobox.svg'
                                  )}
                                  alt="crowdfundingcfa Logo"
                              />
                            </div>
                            <br/>
                            <span
                                className={isActive ? " animated fadeInLeft" : ""
                                }
                            >
                          {singleSlide.text}
                        </span>
                            <h3
                                className={`title${isActive ? " animated fadeInRightBig" : ""
                                }`}
                            >
                              {singleSlide.title}
                            </h3>
                            <a
                                className={`main-btn${isActive ? " animated zoomIn" : ""
                                }`}
                                href={singleSlide.link}
                            >
                              {singleSlide.button}
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </Container>

                    <div
                        className={`banner-line${isActive ? " animated fadeInRightBig" : ""
                        }`}
                    >
                      <Image src={'/media/assets/images/banner-line.png'} alt="" />
                    </div>
                  </div>
                </SwiperSlide>
            ))}
          </div>
          <div>
          <div
              className="prev slick-arrow"
              id="main-slider__swiper-button-prev"
          >
            <i className="flaticon-back"></i>
          </div>
            <div
                className="next slick-arrow"
                id="main-slider__swiper-button-next"
            >
              <i className="flaticon-next"></i>
            </div>
          </div>
        </Swiper>
      </section>
  );
};

export default BannerSlider;
