import {FC} from 'react'

type Props = {
  price?: string
}

const PriceTag: FC<Props> = ({price}) => (
    <><span>{price} €</span></>
)

export {PriceTag}
