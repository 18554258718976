import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'
import {KTSVG, toAbsoluteUrl} from "../../../../helpers";
import React from "react";
import {useThemeMode} from "../../../../../app/core/theme-mode/ThemeModeProvider";

const DefaultTitle = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config} = useLayout()
  const {mode} = useThemeMode()
  return (
    <div
      data-swapper='true'
      data-swapper-mode='prepend'
      data-swapper-parent="{default: '#content_container', lg: '#header_container'}"
      className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-5 pb-lg-0'
    >
      {/* begin::Heading */}
      {pageTitle && (
          <div style={{ marginTop: 'auto'}} className='row g-5 g-xxl-8'>
            <div className='col-xl-3' style={{alignSelf: 'center'}}>
              {mode === 'light' && (
                  <img
                      alt='Logo'
                      className='h-50px logo theme-light-show'
                      src={toAbsoluteUrl('/media/assets/images/logobox.svg')}
                  />
              )}
              {mode === 'dark' && (
                  <img
                      alt='Logo'
                      className='h-50px logo theme-dark-show'
                      src={toAbsoluteUrl('/media/assets/images/logobox-dark.svg')}
                  />
              )}
            </div>
            <div className='col-xl-9'>
                <h1 className='d-flex flex-column text-dark fw-bolder my-0 fs-1'>
                  {pageTitle}
                  {pageDescription && (
                    <small className='text-muted fs-6 fw-bold ms-1 pt-1'>{pageDescription}</small>
                  )}
                </h1>
            </div>
          </div>
      )}
      {/* end::Heading */}

      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <ul className='breadcrumb breadcrumb-dot fw-bold fs-base my-1'>
            {Array.from(pageBreadcrumbs)
              .filter((b) => !b.isSeparator)
              .map((item, index) => (
                <li
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  <Link className='text-muted' to={item.path}>
                    {item.title}
                  </Link>
                </li>
              ))}
            <li className='breadcrumb-item text-dark'>{pageTitle}</li>
          </ul>
        )}
    </div>
  )
}

export {DefaultTitle}
