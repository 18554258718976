/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../_rebusiness/helpers';
import {createInvitations, getInvitationsByUserId, getProjectsByUserId} from '../../core/_requests';
import { useAuth } from '../../pages/auth';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';

interface User {
  email: string;
  // Add other properties if available
}

// Define the Project type
interface Project {
  id: string;
  projectName: string;
  projectDescription: string;
  projectImages: string;
  uniqueID: string;
  createdAt: string;
  deadlineDate: string;
  isPrivate: string;
  perks: string;
  projectCost: string;
  projectDiploma: string;
  projectEquipment: string;
  status: string;
  updatedAt: string;
  userId: string;
  visits: string;
}

const emailListSchema = Yup.string()
    .required('Les emails sont requis')
    .test('email-list', 'Format d\'email invalide', (value) => {
      if (!value) return true;
      const emails = value.split(',');
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return emails.every((email) => email.trim().match(emailRegex));
    });

const emailsSchema = Yup.object().shape({
  emails: emailListSchema,
  project: Yup.string().required('Un projet doit être sélectionné'),
});

const InviteUsersCFA: FC = () => {
  const { logout } = useAuth();
  const [invitations, setInvitations] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [SelectedProject, setSelectedProject] = useState<Project | null>(null);
  const [Projects, setProjects] = useState<Project[]>([]);



  const formik = useFormik({
    initialValues: {
      emails: '',
      project: null,
    },
    validationSchema: emailsSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        // Send the data to the server for processing
        const response = await createInvitations(values.emails, SelectedProject, logout);
        if (response.message === 'Success') {
          formik.resetForm({
            values: {
              emails: '',
              project: null, // Reset the project field to null
            },
          });
          setSelectedProject(null);
          const invitationsAPI = await getInvitationsByUserId(logout);
          setInvitations(invitationsAPI.invitations);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchInvitations = async () => {
      try {
        const projectsData = await getProjectsByUserId(logout);
        const onlineProjects = projectsData.filter((project:any) => project.status === "online");
        setProjects(onlineProjects);
        const invitationsAPI = await getInvitationsByUserId(logout);
        if (invitationsAPI.error == 'invitations not found') {
          setInvitations([]);
          return;
        }
        setInvitations(invitationsAPI.invitations);
      } catch (error) {
        console.error(error);
      }
    };
    fetchInvitations();
  }, [logout]);

  const handleProjectSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProjectId = event.target.value;
    const project = Projects.find((p) => p.id.toString() === selectedProjectId);
    formik.setFieldValue('project', selectedProjectId || null);
    if(project){
      setSelectedProject(project);
    }
  };

  return (
      <div className='modal fade' id='modal_invite_cfa' aria-hidden='true'>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                <div className=' mb-13'>
                  <h1 className='text-center mb-3'>Mobilisez votre réseau </h1>

              <div className='text-muted fw-bold fs-5'>
                Pour réussir votre campagne de financement, considérez que votre réseau se compose de 3 cercles :<br/><br/>
                - le 1er cercle est celui des employeurs dont vous accueillez les apprentis ou avez accueillis les apprentis,<br/><br/>
                - le 2ème cercle est celui de vos autres partenaires réguliers (clients, fournisseurs…),<br/><br/>
                - le 3ème cercle est celui de CrowdFundCFA qui, si vous choisissez de publier votre projet en mode public, participe à la promotion de votre projet au quotidien.
                <br/>Tenez compte du moteur de recherche par mot clé dans la rédaction de votre projet


              </div>
            </div>
                <div className='form-group mb-3'>
                 {/* <label htmlFor='projectSelect' className='form-label'>
                    Selectionnez un projet
                  </label>*/}
                  <select
                      id='projectSelect'
                      name='project'
                      className='form-select'
                      onChange={handleProjectSelect}
                      value={SelectedProject?.projectName || ''}
                  >
                    <option value='' disabled>
                      Selectionnez un projet
                    </option>
                    {Projects.map((project) => (
                        <option key={project.id} value={project.id}>
                          {project.projectName}
                        </option>
                    ))}
                  </select>
                  {formik.touched.project && formik.errors.project && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.project}</div>
                      </div>
                  )}
                </div>
                <textarea
                    className='form-control form-control-lg form-control-solid'
                    placeholder='mail1,mail2,mail3,mail4,mail5...'
                    {...formik.getFieldProps('emails')}
                />
                {formik.touched.emails && formik.errors.emails && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.emails}</div>
                    </div>
                )}

                {invitations && invitations.length > 0 ? (
                    <div className='mb-10'>
                      <div className='fs-6 fw-bold mb-2 ' style={{ marginTop: '30px' }}>
                        Mes invitations envoyés
                      </div>

                      <div className='mh-300px scroll-y me-n7 pe-7'>
                        {invitations.map((invitation, i) => {
                          return (
                              <div
                                  className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'
                                  key={i}
                              >
                                <div className='d-flex align-items-center'>
                                  <div className='symbol symbol-35px symbol-circle'>
                                    {invitation?.email && (
                                        <div className='symbol-label fs-1 fw-bold bg-info text-inverse-info'>
                                          {invitation?.email.slice(0, 1)}
                                        </div>
                                    )}
                                  </div>

                                  <div className='ms-5'>
                                    <a
                                        href='#'
                                        className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                                    >
                                      {invitation.email}
                                    </a>
                                    <div className='fw-bold text-muted'>{invitation.email}</div>
                                  </div>
                                </div>

                                <div className='ms-2 w-100px'>
                                  <a
                                      href='#'
                                      className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                                  >
                                    Reçu
                                  </a>
                                </div>
                              </div>
                          );
                        })}
                      </div>
                    </div>
                ) : null}

                <button
                    style={{ marginTop: '20px', width: '100%' }}
                    type='submit'
                    className={'main-btn-small'}
                    disabled={loading}
                >
                  {!loading ? 'Envoyez' : 'Patientez...'}
                  {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
  );
};

export { InviteUsersCFA };
