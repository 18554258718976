import {DisableSidebar, PageTitle} from '../../../_rebusiness/layout/core'
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget9,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15,
} from '../../../_rebusiness/partials/widgets'
import {CfaStatsWidget} from './widgets/CfaStatsWidget'
import {EntrepriseStatsWidget} from './widgets/EntrepriseStatsWidget'
import {SummaryWidget} from './widgets/SummaryWidget'
import {ActivitiesWidget} from './widgets/ActivitiesWidget'
import {ValidationWidget} from './widgets/ValidationWidget'
import {TrendingWidget} from './widgets/TrendingWidget'
import {useAuth} from "../auth";
import {AsideDefault} from "../../core/aside-menus/AsideDefault";
import {AxiosResponse} from "axios";
import {UsersQueryResponse} from "./management-list/core/_models";
import {getAdminStatistics} from "../../core/_requests";
import {useEffect, useState} from "react";



const AdminSpace =   () => {
    const {currentUser,logout}=useAuth()
    const [stats, setStats] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const statistique = await getAdminStatistics(logout);
                setStats(statistique)
                // Do something with the stats, such as storing them in state or displaying them in the component
            } catch (error) {
                // Handle errors if any
                console.error(error);
            }
        };

        fetchData();
    }, [setStats,logout]);
  return (
          <DisableSidebar>
              <PageTitle description='Administration du site' breadcrumbs={[]}>
                  Bonjour{currentUser?.contactFirstName}
              </PageTitle>

              {stats &&<div className='row gy-5 g-xl-10'>
                  {/*begin::Col*/}
                  <div className='col-xl-4'>
                       <CfaStatsWidget
                          statsData={stats}
                          className='card-xl-stretch mb-xl-10'
                          backGroundColor='#ffd54fa8'
                          chartHeight='100px'
                      />
                  </div>
                  {/*end::Col*/}

                  {/*begin::Col*/}
                  <div className='col-xl-4'>
                      <EntrepriseStatsWidget
                          statsData={stats}
                          className='card-xl-stretch mb-xl-10'
                          backGroundColor='#ff775994'
                          chartHeight='100px'
                      />

                  </div>
                  {/*end::Col*/}

                  {/*begin::Col*/}
                  <div className='col-xl-4'>
                      <SummaryWidget  statsData={stats} className='card-xl-stretch mb-xl-10' backGroundColor='#ff4653a6' />
                  </div>
                  {/*end::Col*/}
              </div>}
              {/*end::Row*/}
              <ValidationWidget className='mb-5 mb-xl-10' />

              {/*begin::Row*/}
              <div className='row gy-5 g-xl-10'>
                  <div className='col-xxl-6'>
                      <ActivitiesWidget className='card-xl-stretch mb-xl-10' />
                  </div>
                  <div className='col-xxl-6'>
                      <TrendingWidget statsData={stats} className='card-xl-stretch mb-5 mb-xl-10' items={5} />
                  </div>
              </div>

          </DisableSidebar>
  )
}

export {AdminSpace}
