/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../../_rebusiness/helpers'
import {useThemeMode} from "../../../core/theme-mode/ThemeModeProvider";

const Step5 = () => {
    const {mode} = useThemeMode()
  return (
    <>
      <div data-stepper-element='content'>
        <div className='w-100 text-center' style={{minHeight:'400px'}}>
          {/* begin::Heading */}
          <h1 className='fw-bold text-dark mb-3'>C'est terminé !</h1>
          {/* end::Heading */}

          {/* begin::Description */}
          <div className='text-muted fw-semibold fs-3'>
              Merci nous avons bien reçu votre projet.<br/>
              Patientez le temps de sa validation.

          </div>
          {/* end::Description */}

          {/* begin::Illustration */}
          <div className='text-center px-4 py-15'>
            <img
              src={toAbsoluteUrl(mode === 'light'?'/media/assets/images/illustration-2.png':'/media/assets/images/illustration-2-dark.png')}
              alt=''
              className='mw-100 mh-300px'
            />
          </div>
          {/* end::Illustration */}
        </div>
      </div>
    </>
  )
}

export {Step5}
