

export const banners = [
  {
    id: 1,
    bg: "banner-bg-1.jpg",
    text: "Soutenir l’apprentissage n’a jamais été aussi simple",
    title: "Des CFA porteurs de projets sociétaux, ",
    button:'Lancer votre projet',
    link:'/connexion',
  },
  {
    id: 2,
    bg: "banner-bg-2.jpg",
    text: "Soutenir l’apprentissage n’a jamais été aussi simple",
    title: "Des entreprises engagées sur des achats responsables,",
    button:'Soutenir un projet',
    link:'/projets',
  },
  {
    id: 3,
    bg: "banner-bg-3.jpg",
    text: "Soutenir l’apprentissage n’a jamais été aussi simple",
    title: "Des fournisseurs de matériels investis dans la formation des jeunes,",
    button:'Soutenir un projet',
    link:'/projets',
  },
];
