import {useProjectListView} from '../../core/ListViewProvider'
import {ProjectsListToolbar} from './ProjectsListToolbar'
import {ProjectsListGrouping} from './ProjectsListGrouping'
import {ProjectsListSearchComponent} from './ProjectsListSearchComponent'

const ProjectsListHeader = () => {
  const {selected} = useProjectListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ProjectsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ProjectsListGrouping /> : <ProjectsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ProjectsListHeader}
