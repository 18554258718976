import axios, { AxiosRequestConfig } from 'axios'
import {AuthModel, UserModel} from './_models'
import {getAuth, useAuth} from "../pages/auth";
import {object} from "yup";
const API_URL = process.env.REACT_APP_API_URL
const headers = {
  'api_key': '123',
};


export function login(email: string, password: string) {
  return axios.post<AuthModel>(API_URL+'/login', {
    email,
    password,
  }, { headers })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(API_URL+'/forgot_password', {
    email,
  })
}

export async function promptChatGPT(messages: any, logout: () => void) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.post(API_URL + '/generateAI', { messages }, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data.error && error.response.data.error.name === "TokenExpiredError") {
      logout();
    } else {
      return error.response.data;
    }
  }
}


export function getUserByToken(token: string) {
  return axios.get<UserModel>(API_URL+'/verify_token', {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  });

}

export async function editProfile(data: any, logout: () => void) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.put(API_URL+'/users', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json', // Set the content type to JSON
      },
    });

    return response.data;
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function updateEmail(data: any, logout: () => void) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.put(API_URL+'/users/update-email', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function updatePassword(data: any, logout: () => void) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.put(API_URL+'/users/update-password', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });

    // Handle success response
    return response.data
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function createProject(data: any, logout: () => void) {

  const token = getAuth()?.api_token;
  try {
    const response = await axios.post(API_URL+'/projects', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function createProduct(data: any, logout: () => void) {

  const token = getAuth()?.api_token;
  try {
    const response = await axios.post(API_URL+'/product', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function createUser(data: any, logout: () => void) {

  const token = getAuth()?.api_token;
  try {
    const response = await axios.post(API_URL+'/users', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function projectEdit(data: any, logout: () => void) {

  const token = getAuth()?.api_token;
  try {
    const response = await axios.put(API_URL+'/projects/'+data.id, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function getProject(id: any, logout: () => void) {

  const token = getAuth()?.api_token;
  try {
    const response = await axios.get(API_URL+'/projectsById/'+id, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error
  }
}
export async function getAllProject() {
  try {
    const response = await axios.get(API_URL+'/projects', {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data.projects
  } catch (error: any) {
   return error.response.data
  }
}
export async function getSearchProject(query:any) {
  try {
    const response = await axios.get(API_URL+'/search-projects?'+query, {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    console.log(response)
    // Handle success response
    return response.data
  } catch (error: any) {
   return error.response.data
  }
}
export async function getProjectsByUserId( logout: () => void) {

  const token = getAuth()?.api_token;
  try {
    const response = await axios.get(API_URL+'/projectsByUserId', {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data.projects
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function getAllProduct() {
  try {
    const response = await axios.get(API_URL+'/products', {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data.projects
  } catch (error: any) {
    return error.response.data
  }
}
export async function getSearchProduct(query:any) {
  try {
    const response = await axios.get(API_URL+'/search-products?'+query, {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    console.log(response)
    // Handle success response
    return response.data
  } catch (error: any) {
    return error.response.data
  }
}
export async function getProductsByUserId( logout: () => void) {

  const token = getAuth()?.api_token;
  try {
    const response = await axios.get(API_URL+'/productsByUserId', {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data.products
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function getProductByUniqueId(uniqueID: any, logout: () => void) {
  try {
    console.log(uniqueID)
    const response = await axios.get(API_URL+`/getProductByUniqueId/${uniqueID}`, {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    console.log(response)
    return response.data
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error
  }
}
export async function getContributionsByUserId( logout: () => void) {

  const token = getAuth()?.api_token;
  try {
    const response = await axios.get(API_URL+'/contributionsByUserId', {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data.contributions
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function createContributions(data: any, logout: () => void) {

  const token = getAuth()?.api_token;
  try {
    const response = await axios.post(API_URL+'/contributions', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function createCustomer(data: any, logout: () => void) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.post(API_URL+'/createCus', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    return response
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}

export async function createInvoice(data: any, logout: () => void) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.post(API_URL+'/createInvoice', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    return response
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function confirmPaymentIntent(data: any, logout: () => void) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.post(API_URL+'/confirmPaymentIntent', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function getInvitationsByUserId( logout: () => void) {

  const token = getAuth()?.api_token;
  try {
    const response = await axios.get(API_URL+'/invitations', {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function createInvitations(emails: string,projects:any, logout: () => void) {

  const emailArray = emails.split(',').map((email) => email.trim());

  const token = getAuth()?.api_token;
  try {
    const response = await axios.post(API_URL+'/invitations', {emails:emailArray,projects}, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function passwordRecovery(email: string, logout: () => void) {
  try {
    const response = await axios.post(API_URL+'/reset-password', {email}, {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}
export async function contactMail(email: string, logout: () => void) {
  try {
    const response = await axios.post(API_URL+'/contactus', {msg:email}, {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data
  } catch (error) {
   return error
  }
}
export async function EmailVerification(token: string, logout: () => void) {

  try {
    const response = await axios.get(API_URL+`/verify-email/${token}`,  {
      headers: {
        ...headers,

      },
    });
    // Handle success response
    return response.data
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}

//////////////
//ADMIN
/////////////

// Function to get all users
export async function getAllUsers(query:any,type:any) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.get(`${API_URL}/admin-users-page?filter_role=${type}&${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response;
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      window.location.href = '/logout'
    }else return error.response.data
  }
}
export async function getAllUsersPending(query:any,logout: () => void) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.get(`${API_URL}/admin-users-page?${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response;
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
     logout();
    }else return error.response.data
  }
}
export async function getAllProjects(query:any) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.get(`${API_URL}/admin-projects-page?${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response;
  } catch (error: any) {
    console.log(error)
    console.log(error.data.error.name)
    if (error.response.data.error.name === "TokenExpiredError"){
      window.location.href = '/logout'
    }else return error.response.data


  }
}
export async function getAllProducts(query:any) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.get(`${API_URL}/admin-products-page?${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response;
  } catch (error: any) {
    console.log(error)
    console.log(error.data.error.name)
    if (error.response.data.error.name === "TokenExpiredError"){
      window.location.href = '/logout'
    }else return error.response.data


  }
}
export async function getAllContributions(query:any) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.get(`${API_URL}/admin-contributions-page?${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response;
  } catch (error: any) {
    console.log(error)
    console.log(error.data.error.name)
    if (error.response.data.error.name === "TokenExpiredError"){
      window.location.href = '/logout'
    }else return error.response.data


  }
}
// Function to update user status
export async function updateUserStatus(userId:any, status:any) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.put(
        `${API_URL}/users/${userId}/update-status`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            ...headers,
            'Content-Type': 'application/json',
          },
        }
    );
    // Handle success response
    return response.data.message;
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      window.location.href = '/logout'
    }else return error.response.data
  }
}
// Function to update user
export async function updateUserProfile(data: any) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.put(API_URL+'/admin-users', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json', // Set the content type to JSON
      },
    });

    return response.data;
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      window.location.href = '/logout'
    }else return error
  }
}
// Function to delete user
export async function deleteUserById(userId:any) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.delete(`${API_URL}/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data.message;
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      window.location.href = '/logout'
    }else return error.response.data
  }
}

// Function to update project status
export async function updateProjectStatus(projectId:any, status:any) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.put(
        `${API_URL}/projects/${projectId}/update-status`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            ...headers,
            'Content-Type': 'application/json',
          },
        }
    );
    // Handle success response
    return response.data.message;
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      window.location.href = '/logout'
    }else return error.response.data
  }
}
// Function to update contribution status
export async function updateContributionStatus(contributionId:any, status:any) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.put(
        `${API_URL}/contributions/${contributionId}/update-status`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            ...headers,
            'Content-Type': 'application/json',
          },
        }
    );
    // Handle success response
    return response.data.message;
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      window.location.href = '/logout'
    }else return error.response.data
  }
}

// Function to delete project
export async function deleteProject(projectId:any, logout: () => void) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.delete(`${API_URL}/projects/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data.message;
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}

// Function to get admin activities
export async function getAdminActivities(logout: () => void) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.get(`${API_URL}/admin-activities`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data.activities;
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}

// Function to get admin statistics
export async function getAdminStatistics(logout: () => void) {
  const token = getAuth()?.api_token;
  try {
    const response = await axios.get(`${API_URL}/admin-statistics`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    // Handle success response
    return response.data;
  } catch (error: any) {
    if (error.response.data.error.name === "TokenExpiredError"){
      logout();
    }else return error.response.data
  }
}




