
import Layout from "../components/Layout/Layout";
import SuppliersArea from "../components/Suppliers/SuppliersArea";
import PageTitle from "../components/Reuseable/PageTitle";
import React from "react";
import HeaderFront2 from "../components/Header/HeaderFront2";

const Suppliers : React.FC = () => {
  return (
    <Layout>
        <HeaderFront2/>
      <PageTitle title="Fournisseurs" />
      <SuppliersArea />
    </Layout>
  );
};

export default Suppliers;
