import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../_rebusiness/assets/ts/components'
import {initialQueryState, KTIcon} from '../../../../../../_rebusiness/helpers'
import {useContributionQueryRequest} from '../../core/QueryRequestProvider'
import {useContributionQueryResponse} from '../../core/QueryResponseProvider'

const ContributionsListFilter = () => {
  const {updateState} = useContributionQueryRequest()
  const {isLoading} = useContributionQueryResponse()
  const [status, setStatus] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    console.log('reset')
    updateState({filter: undefined, ...initialQueryState})
  }

  const filterData = () => {
    console.log('st ' + status)
    updateState({
      filter: {status},
      ...initialQueryState,
    })
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-menu-trigger='click'
        data-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-project-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Status:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-user-table-filter='status'
              data-hide-search='true'
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value=''></option>
              <option value="pending">En attente</option>
              <option value="success">Confirmé</option>
              <option value="rejected">Rejeté</option>
              <option value="refunded">Remboursé</option>
            </select>
          </div>
          {/* end::Input group */}


          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-menu-dismiss='true'
              data-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-menu-dismiss='true'
              data-user-table-filter='filter'
            >
              Filtrer
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {ContributionsListFilter}
