import React, { useState } from 'react';
import {KTSVG, toAbsoluteUrl} from '../../../_rebusiness/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAuth} from '../../pages/auth';
import {projectEdit, editProfile, getUserByToken} from "../../core/_requests";
import { getBase64 } from '../../core/getBase64';
import { Modal, Button } from 'react-bootstrap'; // Import the necessary components from react-bootstrap

const projectDetailsSchema = Yup.object().shape({
    projectName: Yup.string().required('Le nom du projet est requis'),
    projectImages: Yup.array().required('Les images du projet sont requises'),
    projectDescription: Yup.string().required('La description du projet est requise'),
    projectEquipment: Yup.string().required("La description du matériel et de l'équipement est requise"),
    projectDiploma: Yup.string().required('La description des diplome est requise'),
    projectCost: Yup.number().required('Le coût du projet est requis'),
    isPrivate: Yup.boolean().required('Le statut de confidentialité est requis'),
    deadlineDate: Yup.date().required('La date limite est requise'),
});
// Define the type for the onModalClose prop
interface EditProjectProps {
    onModalClose: () => Promise<void>;
    editProject:{
        id: number;
        projectName: string;
        projectDescription: string;
        projectEquipment: string;
        projectDiploma: string;
        deadlineDate: string;
        projectCost: number;
        projectImages:string;
        isPrivate:boolean;
    };
}


const EditProject: React.FC<EditProjectProps> = ({editProject, onModalClose,  }) => {
    const {  logout, setCurrentUser } = useAuth();
    const [selectedProjectImages, setSelectedProjectImages] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);

    const handleModalClose = () => {
        // Call the callback function to refetch projects
        onModalClose();
    };
    const formik = useFormik({
        initialValues: {
            projectName: editProject.projectName,
            projectImages: [],
            projectDescription: editProject.projectDescription,
            projectEquipment: editProject.projectEquipment,
            projectDiploma: editProject.projectDiploma,
            projectCost: editProject.projectCost,
            deadlineDate: new Date(editProject.deadlineDate).toISOString().split('T')[0],
            isPrivate: editProject.isPrivate,
        },
        validationSchema: projectDetailsSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                // Prepare the data
                const data = {
                    id:editProject.id,
                    projectName: values.projectName,
                    projectDescription: values.projectDescription,
                    projectEquipment: values.projectEquipment,
                    projectDiploma: values.projectDiploma,
                    projectCost: values.projectCost,
                    isPrivate: values.isPrivate,
                    deadlineDate: values.deadlineDate,
                    projectImages: null as string | null, // Specify the type as string | null and initialize with null
                };
                if (selectedProjectImages) {
                    const base64Image = await getBase64(selectedProjectImages);
                    data.projectImages = base64Image;
                }

                // Send the data to the server for processing
                // Replace `editProfile` with the appropriate function to save project details
                const response = await projectEdit(data, logout);
                console.log(response)
                if(response.message === 'Success'){
                    setSelectedProjectImages(null)
                    formik.resetForm({
                        values: formik.initialValues,
                    });
                    onModalClose();
                    console.log('success')
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        },
    });

    return (
                <Modal show={editProject.id!==0} onHide={handleModalClose} backdrop={true}  keyboard={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modifier votre projet</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <Modal.Body >
                                <div className="modal-body">
                                    <div id='account_project_details' className='collapse show'>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Image de couverture</label>
                                            <div className='col-lg-8'>
                                                <div className='image-input image-input-outline' data-image-input='true' onClick={() => {
                                                    const avatarInput = document.getElementById('avatar');
                                                    if (avatarInput) {
                                                        avatarInput.click();
                                                    }
                                                }}>
                                                    {selectedProjectImages ?<div
                                                            className='image-input-wrapper symbol-2by3  h-200px'
                                                            style={{
                                                                backgroundSize: '100% auto',
                                                                backgroundImage: `url(${
                                                                    URL.createObjectURL(selectedProjectImages)
                                                                })`,
                                                            }}
                                                        ></div>:
                                                        null}
                                                    {editProject.projectImages && !selectedProjectImages?<div
                                                            className='image-input-wrapper symbol-2by3 w-200px h-125px'
                                                            style={{
                                                                backgroundSize: '100% auto',
                                                                backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL+'/'+editProject.projectImages})`,
                                                            }}
                                                        ></div>
                                                        :
                                                        null}

                                                    <label htmlFor='avatar'  className='visually-hidden'>
                                                        Importer
                                                        <input
                                                            id='avatar'
                                                            type='file'
                                                            name='image de couverture'
                                                            accept='.png, .jpg, .jpeg'
                                                            style={{ display: 'none' ,}}
                                                            onChange={(event) => {
                                                                const file = event.target.files?.[0] || null;
                                                                setSelectedProjectImages(file);
                                                            }}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nom du projet</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Nom du projet'
                                                    {...formik.getFieldProps('projectName')}
                                                />
                                                {formik.touched.projectName && formik.errors.projectName && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.projectName}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Description du projet</label>
                                            <div className='col-lg-8 fv-row'>
                                                {/* Replace the textarea with an HTML text editor field */}
                                                <textarea
                                                    style={{height:'300px'}}
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Description du projet'
                                                    {...formik.getFieldProps('projectDescription')}
                                                />
                                                {formik.touched.projectDescription && formik.errors.projectDescription && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.projectDescription}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Description du matériel et équipement</label>
                                            <div className='col-lg-8 fv-row'>
                                                {/* Replace the textarea with an HTML text editor field */}
                                                <textarea
                                                    style={{height:'300px'}}
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Description du matériel et équipement'
                                                    {...formik.getFieldProps('projectEquipment')}
                                                />
                                                {formik.touched.projectDescription && formik.errors.projectEquipment && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.projectEquipment}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Description du diplome</label>
                                            <div className='col-lg-8 fv-row'>
                                                {/* Replace the textarea with an HTML text editor field */}
                                                <textarea
                                                    style={{height:'300px'}}
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Description du diplome'
                                                    {...formik.getFieldProps('projectDiploma')}
                                                />
                                                {formik.touched.projectDiploma && formik.errors.projectDiploma && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.projectDiploma}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Coût du projet</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    readOnly={true}
                                                    type='number'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Coût du projet'
                                                    {...formik.getFieldProps('projectCost')}
                                                />
                                                {formik.touched.projectCost && formik.errors.projectCost && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.projectCost}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Date limite</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='date'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Date limite'
                                                    {...formik.getFieldProps('deadlineDate')}
                                                />
                                                {formik.touched.deadlineDate && formik.errors.deadlineDate && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.deadlineDate}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='mb-10'>
                                            <div className='form-check form-switch form-check-custom form-check-solid'>
                                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Accès restreint</label>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='isPrivate'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    checked={formik.values.isPrivate}
                                                />
                                                <label className='form-check-label' htmlFor='isPrivate'>
                                                    Projet privé
                                                </label>
                                            </div>
                                            {formik.touched.isPrivate && formik.errors.isPrivate && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.isPrivate}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={handleModalClose}>
                                Fermer
                            </Button>
                            <Button type="submit" variant="primary" disabled={loading}>
                                {!loading ? 'Modifier' : 'Patientez...'}
                                {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            );
            };

export { EditProject };

