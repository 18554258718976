import {FC, useState, createContext, useContext} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from '../../../../../_rebusiness/helpers'

const UserQueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)
const ProjectQueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)
const ProductQueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)
const ContributionQueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const UserQueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state)

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState
    setState(updatedState)
  }

  return (
    <UserQueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </UserQueryRequestContext.Provider>
  )
}
const ProjectQueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state)

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState
    setState(updatedState)
  }

  return (
      <ProjectQueryRequestContext.Provider value={{state, updateState}}>
        {children}
      </ProjectQueryRequestContext.Provider>
  )
}
const ProductQueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state)

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState
    setState(updatedState)
  }

  return (
      <ProductQueryRequestContext.Provider value={{state, updateState}}>
        {children}
      </ProductQueryRequestContext.Provider>
  )
}
const ContributionQueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state)

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState
    setState(updatedState)
  }

  return (
      <ContributionQueryRequestContext.Provider value={{state, updateState}}>
        {children}
      </ContributionQueryRequestContext.Provider>
  )
}
const useUserQueryRequest = () => useContext(UserQueryRequestContext)
const useProjectQueryRequest = () => useContext(ProjectQueryRequestContext)
const useProductQueryRequest = () => useContext(ProductQueryRequestContext)
const useContributionQueryRequest = () => useContext(ContributionQueryRequestContext)
export {UserQueryRequestProvider, useUserQueryRequest,ProjectQueryRequestProvider,useProjectQueryRequest,ProductQueryRequestProvider,useProductQueryRequest,ContributionQueryRequestProvider,useContributionQueryRequest,}
