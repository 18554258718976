import icon from "../assets/images/header-icon.png";
import logo2 from "../assets/images/logo-2.png";
import logo from "../assets/images/logo.png";

export const navItems = [
  {
    id: 1,
    name: "Accueil",
    href: "/",
  },
  {
    id: 2,
    name: "Projets",
    href: "/projets",
  },
  {
    id: 3,
    name: "Equipements",
    href: "/equipements",
  },
  {
    id: 4,
    name: "FAQ",
    href: "/faq",
  },
  {
    id: 5,
    name: "Contact",
    href: "/contact",
  },
  {
    id: 6,
    name: "Mon espace",
    href: "/connexion",
  },
];

export const socials = [
  {
    id: 1,
    icon: "fa fa-brands fa-facebook-square",
    href: "#",
  },
  {
    id: 2,
    icon: "fa fa-brands fa-twitter",
    href: "#",
  },
  {
    id: 3,
    icon: "fa fa-brands fa-instagram",
    href: "#",
  },
  {
    id: 4,
    icon: "fa fa-brands fa-dribbble",
    href: "#",
  },
];

const headerData = {
  logo,
  logo2,
  icon,
  navItems,
  email: "needhelp@crowdfundingcfa.com",
  phone: "060000000",
  address: "Paris",
  socials,
};

export default headerData;
