import React from "react";
import { ProfileDetails } from "./ProfileDetails";
import { SignInMethod } from "./SignInMethod";
import { ConnectedAccounts } from "../../modules/accounts/components/settings/cards/ConnectedAccounts";
import { EmailPreferences } from "../../modules/accounts/components/settings/cards/EmailPreferences";
import { Notifications } from "../../modules/accounts/components/settings/cards/Notifications";
import { DeactivateAccount } from "../../modules/accounts/components/settings/cards/DeactivateAccount";

export function CfaAccountSettings() {
    return (
        <>
            <ProfileDetails />
            <SignInMethod />
           {/* <ConnectedAccounts />
            <EmailPreferences />
            <Notifications />
            <DeactivateAccount />*/}
        </>
    );
}
