/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link, Navigate} from 'react-router-dom'
import {useAuth} from '../../pages/auth'
import {toAbsoluteUrl} from '../../../_rebusiness/helpers'
import {Languages} from '../../../_rebusiness/partials/layout/header-menus/Languages'
import React from "react";

const UserMenu = () => {
  const {currentUser, logout} = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-menu='true'
    >
      {/* begin::Menu item */}
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          {/* begin::Avatar */}
          <div className='symbol symbol-50px me-5'>
            {currentUser?.avatar ? <img src={toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL+'/'+currentUser?.avatar)} alt='avatar' />:<div className="symbol symbol-50px">
              <div className="symbol-label fs-4 fw-bold bg-info text-inverse-info">{currentUser?.contactFirstName.slice(0,1)}</div></div>}
          </div>
          {/* end::Avatar */}

          {/* begin::Username */}
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.contactLastName.slice(0,1)}. {currentUser?.contactFirstName}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>{currentUser?.role}</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
          {/* end::Username */}
        </div>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu separator */}
      <div className='separator my-2'></div>
      {/* end::Menu separator */}

      {/* begin::Menu item */}
      <div className='menu-item px-5 my-1'>
        <a href={currentUser?.role =='cfa'?'/cfa/settings':currentUser?.role=='fournisseur'?'/fournisseur/settings':'#'} className='menu-link px-5'>
          Modifier votre profil
        </a>
      </div>

      {/* end::Menu item */}

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <a href={currentUser?.role =='cfa'?'/cfa/myprojects':currentUser?.role=='fournisseur'?'/fournisseur/myproducts':'#'} className='menu-link px-5'>
          <span className='menu-text'>{currentUser?.role =='cfa'?'Mes Projets':currentUser?.role=='fournisseur'?'Mes Produits':''}</span>
          {/*<span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>*/}
        </a>
      </div>
      {/* end::Menu item */}
      {/* begin::Menu item */}
      {/*<div
        className='menu-item px-5'
        data-menu-trigger='hover'
        data-menu-placement='right-end'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>


        <div className='menu-sub menu-sub-dropdown w-175px py-4'>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>



          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>
           end::Menu item

           begin::Menu item
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  checked={true}
                  name='notifications'
                  onChange={() => {}}
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>*/}
      {/* end::Menu item */}


      {/* begin::Menu separator */}
      <div className='separator my-2'> </div>
      {/* end::Menu separator */}

     {/* <Languages languageMenuPlacement='right-end' />*/}


      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Déconnexion
        </a>
      </div>
    </div>
  )
}

export {UserMenu}
