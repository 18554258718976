import {DisableSidebar, PageLink, PageTitle} from '../../../_rebusiness/layout/core'
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget9,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15,
} from '../../../_rebusiness/partials/widgets'
import {useAuth} from "../auth";
import {useThemeMode} from "../../core/theme-mode/ThemeModeProvider";
import React, {useState} from "react";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {ProfileHeader} from "../../modules/profile/ProfileHeader";
import {OverviewCfa} from "../cfa/OverviewCfa";
import {OverviewAccount} from "../../modules/accounts/OverviewAccount";
import {ContributionsList} from "../../modules/enterprise/contributionsList"
import {ProjectsCfa} from "../cfa/ProjectsCfa";
import {CfaAccountSettings} from "../cfa/CfaAccountSettings";
import {OverviewEnt} from "./OverviewEnt";

const profilecfaBreadCrumbs: Array<PageLink> = [
    {
        title: 'entreprise',
        path: 'entreprise/overview',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]
const EnterpriseSpace = () => {
    const {currentUser, logout} = useAuth()
    const {mode} = useThemeMode()
    const [tab, setTab] = useState(0);

    return (
        <DisableSidebar>
            <PageTitle description="Espace d'administration" >
                {/*Hello{currentUser?.contactFirstName.toUpperCase()}*/}
            </PageTitle>
            <Routes>
                <Route
                    element={
                        <>
                            <ProfileHeader />
                            <Outlet />
                        </>
                    }
                >
                    <Route
                        path='overview'
                        element={
                            <>
                                <PageTitle breadcrumbs={profilecfaBreadCrumbs}>Accueil</PageTitle>
                                <OverviewEnt />
                            </>
                        }
                    />
                    <Route
                        path='account'
                        element={
                            <>
                                <PageTitle breadcrumbs={profilecfaBreadCrumbs}>Mon compte</PageTitle>
                                <OverviewAccount />
                            </>
                        }
                    />
                    <Route
                        path='mycontributions'
                        element={
                            <>
                                <PageTitle breadcrumbs={profilecfaBreadCrumbs}>Mes subventions</PageTitle>
                                <ContributionsList className='mb-5 mb-xxl-8'/>
                            </>
                        }
                    />
                    <Route
                        path='settings'
                        element={
                            <>
                                <PageTitle breadcrumbs={profilecfaBreadCrumbs}>Réglages</PageTitle>
                                <CfaAccountSettings />
                            </>
                        }
                    />
                    <Route index element={currentUser?.role === 'entreprise' ?<Navigate to='/entreprise/overview' />:<Navigate to='/logout' />} />
                </Route>
            </Routes>

        </DisableSidebar>
    )
}

export {EnterpriseSpace}
