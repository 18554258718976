import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {AuthInit, useAuth} from "../../pages/auth";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {editProfile} from "../../core/_requests";


const TaxCalculator: React.FC = () => {
    const { currentUser, logout, setCurrentUser, } = useAuth();
    const [calculate, setCalculate] = useState('');
    const [msba, setMsba] = useState('');
    // Function to handle changes in the input field
    const handleMsbaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMsba(event.target.value);
    };

    // Function to perform the calculation
    const performCalculation = async () => {
        // Convert "msba" to a number using parseFloat or parseInt
        const msbaNumber = parseFloat(msba); // Use parseInt(msba) if you want an integer instead of a floating-point number

        // Check if the input is a valid number
        if (isNaN(msbaNumber)) {
            console.error('Invalid input. Please enter a valid number.');
            return;
        }

        // Perform your calculation logic here
        const taxAmount = (msbaNumber ) * 0.09/100;
        console.log('Tax amount:', taxAmount);
        try {
            const response=await editProfile({companyTax:taxAmount % 1 === 0 ? taxAmount.toFixed(0) : taxAmount.toFixed(2)},logout)
            setCurrentUser(response.user)
            setCalculate('Display')


        }catch (error){
            console.log(error)
        }

        // ...
    };


    return (
        <>
            {((!currentUser?.companyTax && calculate !='Calculator'))&&
                <Link to="#" onClick={() => setCalculate('Calculator')} className="main-btn-small w-100 align-self-center">
                    {!currentUser?.companyTax ? "Calculer votre taxe d'apprentissage" : 'Modifier votre taxe'}
                </Link>
            }
            {(calculate=='Calculator' ) && (
                <div className={`card `}>
                    {/* begin::Header */}
                    <div className="card-header border-0">
                        <h3 className="card-title fw-bold text-dark">Calculateur de taxe</h3>
                    </div>
                    <div className="card-body pt-2">
                        <div className="mb-10">
                            <label className="form-label">Entrer votre MSBA</label>
                            <span className='required'>Description du matériel et équipement</span>
                            <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title="Le solde de la taxe d'apprentissage (TA), simplifié par l'ordonnance n° 2021-797 du 23 juin 2021, est obtenu en calculant 0,09% de la masse salariale brute annuelle (MSBA). Pour les versements effectués à un CFA, dans le cadre de projets présentés sur CrowdFundCFA, vous devez vous référer à la MSBA prévisionnelle. Ainsi, pour tout versement effectué en 2023, vous vous référerez à votre MSBA 2023. Une régulation pourrait être effectuée dans un 2nd temps après connaissance début 2024 de la MSBA 2023 réelle"
                            ></i>
                            <input
                                type="number"
                                className="form-control form-control-solid"
                                placeholder="MSBA"
                                value={msba}
                                onChange={handleMsbaChange} // Set the event handler for input changes
                            />
                            <Link to="#" onClick={performCalculation} className="main-btn-3 main-btn-small w-100 align-self-center">
                                Calculer
                            </Link>
                        </div>
                    </div>
                    {/* end::Body */}
                </div>
            )}
            {(calculate!=='Calculator' && currentUser?.companyTax ) && (
                <div className={`card `}>
                    {/* begin::Header */}
                    <div className="card-header border-0">
                        <h3 className="card-title fw-bold text-dark">Votre Taxe d'apprentissage </h3>
                        <div className='card-toolbar'>
                            <Link to="#" onClick={() => setCalculate('Calculator')} className="main-btn-3 main-btn-small  align-self-center">
                               Modifier votre taxe
                            </Link>
                        </div>
                    </div>
                    <div className="card-body pt-2">
                        <div className="mb-10" style={{fontSize: '50px', fontWeight: 'bolder',textAlign: 'center'}}>
                            {currentUser?.companyTax} €
                        </div>
                    </div>
                    {/* end::Body */}
                </div>
            )}
        </>
    );
};

export { TaxCalculator };
