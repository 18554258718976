export const clientArea = {
  title: "Ils nous apportent leur soutien",
  clients: [
    {
      id: 1,
      text: "",
      image: "fipa.jpg",
      name: "FIPA",
    },
    {
      id: 2,
      text: "",
      image: "INFA.jpg",
      name: "INFA",
    },
  ],
};
