import bg from "../assets/images/faq-bg.jpg";

export const faqs = [
  {
    id: 1,
    cat:'entreprise',
    souscat:'Cadre légal et réglementaire',
    title: "Comment est calculé le montant du solde de la taxe d’apprentissage ?",
    text: " Le montant du solde de la taxe d’apprentissage est de 0,09% de la masse salariale brute annuelle \n Article L6241-2 du code du Travail",
  },
  {
    id: 2,
    cat:'entreprise',
    souscat:'Cadre légal et réglementaire',
    title: "Comment s’acquitter du versement du solde de la TA ?",
    text: "2 situations permettent de s’acquitter du versement du solde de la TA :\n" +
        "-\tLa déclaration et le versement se font en ligne, en année N, sur la plateforme Soltéa lorsque vous n’avez pas versé de subvention à un CFA en année N-1. \n" +
        "-\tAvant le 31/12, verser à un CFA une subvention sous forme d'équipements et de matériel. Cette subvention correspond au montant du solde de votre taxe d’apprentissage. Sur Crowdfundingcfa.fr, subventionnez les CFA de votre choix en leur permettant de réaliser leur projet d’investissement dans l’acquisition ou le renouvellement de leurs équipements et matériels pédagogiques.\n" +
        " Article L6241-4 du code du Travail\n",
  },
  {
    cat:'entreprise',
    souscat:'Cadre administratif et fiscal',
    id: 3,
    title:"Comment puis-je prouver que j’ai subventionné l’équipement d’un CFA au titre du solde de la taxe d’apprentissage ?",
    text:"Dès l’acquisition par le CFA du matériel que vous avez en toute ou partie subventionné, vous recevrez un mail avec un lien vous permettant de télécharger, dans votre espace donateur, votre reçu libératoire  \n" ,
  },
  {
    id: 4,
    cat:'entreprise',
    souscat:'Cadre administratif et fiscal',
    title:"Que dois-je faire de mon reçu libératoire ?",
    text:"Votre reçu libératoire, disponible dans votre espace donateur, devra être déposé en année N+1 sur la plateforme Soltéa. Vous n’aurez pas de versement à effectuer puisque vous vous serez acquitté du solde de votre taxe d’apprentissage par voie de subventions ",
  },
  {
    id: 5,
    cat:'entreprise',
    souscat:'Calendrier',
    title:"Jusqu’à quand puis-je subventionner un CFA ?",
    text:"Vous disposez jusqu’au 31/12 d’une année N pour subventionner un CFA au titre du solde de la taxe d’apprentissage. Si vous n’avez pas versé le solde de votre taxe d’apprentissage à un CFA sous forme d'équipements et de matériel, alors vous devrez verser ce montant sur la plateforme Soltéa en année N+1.",
  },
  {
    id: 1,
    cat:'cfa',
    souscat:'Conseils',
    title:"Pourquoi, en tant que CFA, ai-je intérêt à recourir au financement participatif avec CrowdFundCFA pour mon projet d’investissement ? ",
    text:"Tout projet d’investissement porté par un CFA illustre sa vision de l’avenir sur l’évolution des métiers et par conséquent de son offre d’apprentissage dans un territoire ou au niveau national. Lorsqu’il est partagé sur CrowdFundCFA.fr, votre projet bénéficie d’un formidable levier de financement, simple et rapide. Votre projet devient alors un acte d’engagement fort que vous partagez avec vos partenaires actuels et potentiels qui deviennent des donateurs par le fléchage du solde de leur taxe d’apprentissage et les employeurs de vos futurs apprentis. Enfin comme sur CrowdfundCFA.fr le financement participatif porte sur l’équipement et matériel, votre projet incarne la place centrale que vous accordez à la qualité de la formation en offrant à vos enseignants et apprentis un équipement moderne et renouvelé.",
  },{
    id: 2,
    cat:'cfa',
    souscat:'Conseils',
    title:"Peut-on déposer plusieurs projets d’investissement ?",
    text:"Techniquement, notre plateforme vous permet de déposer plusieurs projets. Néanmoins, considérez le risque accru de ne pas réaliser vos objectifs de financement sur un projet du fait d’une dispersion des dons sur plusieurs projets d’investissements que vous déposeriez en même temps ou sur une période donnée. \n" +
        "Pour préserver l’image et la clarté de vos messages, il est préférable de concentrer votre communication sur peu de projet, voire un seul, pour vous laisser de temps d’appréhender les principes du crowdfunding (évaluation de votre capacité de financement selon le profil des donateurs, plan de communication et messages clés, calendrier de collecte…)\n"+
        "Consultez nous pour être accompagné dans vos opérations de crowdfunding : service-clients@crowdfundCFA.fr",
  },
  {
    id: 3,
    cat:'cfa',
    souscat:'Conseils',
    title:"Comment maximiser mes chances d’obtenir le soutien d’entreprises donatrices sur mon projet d’investissement ?",
    text:"1.\tNous vous conseillons d’abord de bien évaluer la capacité de financement de votre projet d’investissement en estimant la part des entreprises de votre réseau (entreprises dont vous accueillez ou avez accueilli des apprentis, vos clients, vos fournisseurs, vos partenaires…) qui pourraient vous subventionnez au titre du solde de leur taxe d’apprentissage.\n" +
        "2.\tCommuniquez avec pédagogie :\n" +
        "a.\tSur votre projet en précisant pour quelles formations vous souhaitez investir dans du matériel et équipement pédagogique. Mettez en avant les enjeux des équipements, neuf ou renouvelés, dans la qualité de l’apprentissage et la découverte des métiers. Soulignez votre intérêt dans les technologies de pointe pour accompagner la transformation des organisations et l’évolution des métiers (intelligence artificielle, digitalisation…)\n" +
        "b.\tSur l’opportunité d’une entreprise d’aider un CFA, tout en s’acquittant d’une obligation fiscale. Un tutoriel sera prochainement disponible sur CrowdfundCFA.fr expliquant le fonctionnement des subventions à verser au titre du solde de la taxe d’apprentissage. \n",
  },
  {
    id: 4,
    cat:'cfa',
    souscat:'Conseils',
    title:"Que se passe-t-il si mon objectif de financement n’est pas réalisé à échéance ?",
    text:"Déposer un projet de financement participatif vous engage, en tant que CFA, vis-à-vis des entreprises donatrices et de la plateforme CrowdFundCFA.fr \n" +
        "Les entreprises qui croient en votre projet vous apporte leur soutien financier et, en retour vous vous engagez à acquérir l'équipement quel que soit le résultat de votre campagne de financement. Si votre objectif n’est pas atteint, vous devrez compléter les versements effectués par les entreprises avec vos fonds propres, ou solliciter parallèlement un co-financement (OPCO, Région, Banque des Territoires...) pour acquérir le matériel. Nos équipes sont à vos côtés pour vous accompagner, en amont, dans l’étude d’opportunité et les solutions de cofinancements.\n" +
        "Le financement participatif est une relation de confiance entre le porteur de projet et ses donateurs. CrowdFundCFA intervient comme intermédiaire d'abord pour garantir la conformité des projets déposés, ensuite pour sécuriser les flux financiers et assurer leur traçabilité avec la remise d'un reçu libératoire dès le versement du don par les entreprises.\n",
  },
  {
    id: 5,
    cat:'cfa',
    souscat:'Conseils',
    title:"Quels conseils et accompagnement proposez-vous ?",
    text:"Selon l’ambition de projet d’investissement (de 5000 € à plus de 100 K€), nous vous conseillons de réaliser une étude d’opportunité en vous posant plusieurs questions sur les solutions de financement à envisager en amont, avant publication de votre projet : Comment présenter mon projet ? Selon quel calendrier ? Quel est mon réseau direct (employeurs actuels / passés / potentiels de mes apprentis, mes anciens apprentis) et indirect (fournisseurs et autres partenaires) ? Quelle est la capacité financière de mon réseau ? Quelle est ma capacité à mobiliser mon réseau ? Quelle est la part de mon projet finançable sur mes fonds propres ? Quelle est la part de mon projet finançable sur des fonds publics (OPCO, Région, Banque des Territoires…). Pour être accompagné par CrowdFundCFA dans la réalisation de l’étude d’opportunité, contactez-nous à contact@crowdfundCFA.fr \n",
  },
  {
    id: 1,
    cat: 'fournisseur',
    souscat: 'Avantages',
    title: "Quel est l’intérêt de CrowdfundCFA en tant que fournisseur d’équipement et matériel ?",
    text: "CrowdfundCFA offre plusieurs avantages aux fournisseurs dont l’équipement et matériel présentent un intérêt pédagogique : L’inscription est gratuite et le catalogue de votre offre de produits est illimitée, Vous disposez d’une vitrine ouverte autant vers les Centres de Formation d’Apprentis (CFA) qui sélectionneront vos produits dans le cadre de leurs investissements que vers les employeurs qui les financeront, Vous bénéficiez d’un paiement sécurisé par virement quelque soit le moyen de paiement effectué par les entreprises, Si vous êtes en relation avec un CFA dont le budget ne lui permet pas de s’équiper chez vous, invitez-le sur CrowdfundCFA. Il disposera d’une plateforme pour mobiliser les employeurs et pour financer l’équipement dont il a besoin au-delà de son budget."
  },
  {
    id: 2,
    cat: 'fournisseur',
    souscat: 'Fonctionnement',
    title: "Comment fonctionne le site lorsque je suis fournisseur ?",
    text: "Si vos équipements et matériels présentant un potentiel pédagogique pour les centres de CFA, invitez vos clients CFA et/ou créez votre boutique en ligne : Selon leur projet d’investissement, les CFA sélectionnent vos produits, classés par catégorie, à partir de la rubrique « Equipements ». Lorsque vos produits sont sélectionnés, vous en êtes informé par mail. Avec l’aide de CrowdfundCFA, les CFA lancent ensuite un appel auprès des employeurs de leur réseau, et au-delà, pour soutenir leur investissement en finançant* les produits qu’ils ont sélectionnés. Potentiellement, les vôtres. Les employeurs reçoivent un lien vers le projet du CFA avec la liste de ses besoins en équipements. Ils choisissent les produits qu’ils souhaitent financer. Lorsque la commande est passée, vous en êtes informé par mail. CrowdfundCFA prépare le transfert de fonds tandis que vous organisez la livraison. Dès que vous avez livré les équipements, vous nous en confirmez la livraison. [Prévoir envoi d’un mail hebdo de relance] CrowdfundCFA procède au virement sur votre compte et établit un reçu libératoire pour l’employeur, attestant de la déduction fiscale."
  },
  {
    id: 3,
    cat: 'fournisseur',
    souscat: 'Inscription',
    title: "Comment proposez mes produits sur CrowdfundCFA ?",
    text: "Il vous suffit de créer un compte et de les présenter (libellé, description, tarif TTC et stock disponible) dans votre boutique en les classant par catégorie. Vous n’avez pas à préciser la référence de vos produits qui est générée automatiquement par le site."
  },
  {
    id: 4,
    cat: 'fournisseur',
    souscat: 'Support',
    title: "Et si j’ai d’autres questions à vous poser ?",
    text: "Vous pouvez nous écrire à partir de la page dédiée à cet effet : https://crowdfundcfa.fr/contact"
  },];


export const faqArea = {
  bg,
  tagline: "Questions Fréquemment Posées",
  title: "Comment pouvons-nous vous aider ?",
  text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Have you done google research which works all the time.",
  categories: [
    {
      id: 1,
      icon: "flaticon-handshake",
      text: "Backers",
      href: "#",
    },
    {
      id: 2,
      icon: "flaticon-computer",
      text: "Campaigns",
      href: "#",
    },
    {
      id: 3,
      icon: "flaticon-pay",
      text: "Payments",
      href: "#",
    },
  ],
  faqs
};

export const faqTitleArea = {
  tagline: "Questions Fréquemment Posées",
  title: "Comment pouvons-nous vous aider ?",
};

export const faqDesignArea = {
  navItems: [
    {
      id: 1,
      href: "pills-1",
      icon: "flaticon-handshake",
      name: "Entreprises donatrices",
    },
    {
      id: 2,
      href: "pills-2",
      icon: "flaticon-account",
      name: "CFA",
    },
    {
      id: 3,
      href: "pills-3",
      icon: "flaticon-entrepreneur-1",
      name: "Fournisseur",
    },

  ],
  tabPane: [
    {
      id: "pills-1",
      faqsData: [faqs.filter((item) => item.cat === 'entreprise')],
    },
    {
      id: "pills-2",
      faqsData: [faqs.filter((item) => item.cat === 'cfa')],

    },
    {
      id: "pills-3",
      faqsData: [faqs.filter((item) => item.cat === 'fournisseur')],

    },

  ],
};
