import {ContributionListViewProvider, useContributionListView} from './core/ListViewProvider'
import {ContributionQueryRequestProvider} from './core/QueryRequestProvider'
import {ContributionQueryResponseProvider} from './core/QueryResponseProvider'
import {ContributionsListHeader, } from './components/header/ContributionsListHeader'
import {ContributionsTable} from './table/ContributionsTable'
import {KTCard} from '../../../../_rebusiness/helpers'

const ContributionsList = () => {
  const {itemIdForUpdate,setItemIdForUpdate} = useContributionListView()
  return (
    <>
      <KTCard>
        <ContributionsListHeader />
          <ContributionsTable/>
      </KTCard>
    </>
  )
}

const ContributionsListWrapper = ({ type }: { type: string }) => (
  <ContributionQueryRequestProvider>
    <ContributionQueryResponseProvider type={type}>
      <ContributionListViewProvider>
        <ContributionsList />
      </ContributionListViewProvider>
    </ContributionQueryResponseProvider>
  </ContributionQueryRequestProvider>
)

export {ContributionsListWrapper}
