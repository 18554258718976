import {DisableSidebar, PageTitle} from '../../../_rebusiness/layout/core'

import {UsersListWrapper} from "./management-list/UsersList";
import {useAuth} from "../auth";

const SuplierManager = () => {
    const {currentUser}=useAuth()
    return (

        <DisableSidebar>
            <PageTitle description='Administration du site' breadcrumbs={[]}>
                Bonjour{currentUser?.contactFirstName}
            </PageTitle>
            <UsersListWrapper type='fournisseur'/>
        </DisableSidebar>
    )
}

export {SuplierManager}
