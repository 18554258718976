import {
    ChartsWidget1,
    FeedsWidget2,
    FeedsWidget3,
    FeedsWidget4,
    FeedsWidget5,
    FeedsWidget6, ListsWidget2, ListsWidget5
} from "../../../_rebusiness/partials/widgets";
import React, {useEffect, useState} from "react";
import {OverviewAccount} from "../../modules/accounts/OverviewAccount";
import {ProjectList} from "../../modules/cfa/ProjectList";
import {getProjectsByUserId} from "../../core/_requests";
import {useAuth} from "../../pages/auth";
import {InviteUsers} from "../../../_rebusiness/partials";
import {TaxCalculator} from "../../modules/enterprise/taxCalculator";
import {ContributionsList} from "../../modules/enterprise/contributionsList"
import {ProductsList} from "../../modules/fournisseur/productsList";

export function  OverviewSup () {
    const { currentUser,logout,setCurrentUser } = useAuth();
    const [Projects, setProjects] = useState(0);
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productsData = await getProjectsByUserId(logout);
                setProjects(productsData.length);
            } catch (error) {
                console.error(error);
                // Handle error if necessary
            }
        };
        fetchProducts();
    }, [logout]);
    return (
        <div className='row g-5 g-xxl-8'>
            <InviteUsers/>
            <div className='col-xl-6'>
                <OverviewAccount />
            </div>
            <div className='col-xl-6'>
                <ProductsList className='mb-5 mb-xxl-8 '/>
            </div>
        </div>
    )
}
