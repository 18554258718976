/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {createPortal} from 'react-dom'
import {Button, Modal} from 'react-bootstrap'
import {defaultCreateAppData, ICreateAppData} from '../../../_rebusiness/partials/modals/create-app-stepper/IAppModels'
import {StepperComponent} from '../../../_rebusiness/assets/ts/components'
import {KTIcon} from '../../../_rebusiness/helpers'
import {Step1} from './stepsProject/Step1'
import {Step2} from './stepsProject/Step2'
import {Step3} from './stepsProject/Step3'
import {Step4} from './stepsProject/Step4'
import {Step5} from './stepsProject/Step5'
import {useFormik} from "formik";
import {getBase64} from "../../core/getBase64";
import {createProject, promptChatGPT} from "../../core/_requests";
import * as Yup from "yup";
import {useAuth} from "../../pages/auth";





type Props = {
    show: boolean
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body
const projectDetailsSchema = Yup.object().shape({
    projectName: Yup.string().required('Le nom du projet est requis'),
    projectImages: Yup.array().required('Les images du projet sont requises'),
    projectDescription: Yup.string().required('La description du projet est requise'),
    projectProducts: Yup.string().required('La liste de matériel du projet est requise'),
    projectDiploma: Yup.string().required('La description des diplome est requise'),
    projectCost: Yup.number().required('Le coût du projet est requis'),
    isPrivate: Yup.boolean().required('Le statut de confidentialité est requis'),
    deadlineDate: Yup.date().required('La date limite est requise'),
});
const NewProject = ({show, handleClose}: Props) => {
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [step, setStep] = useState(0);
    const [hasError, setHasError] = useState(false)
    const {  logout, setCurrentUser } = useAuth();
    const [selectedProjectImages, setSelectedProjectImages] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [buttonVisibility, setButtonVisibility] = useState(false);
    const [projectData,setProjectData]=useState({
        projectName: '',
        projectImages: [],
        projectDescription: '',
        projectEquipment: '',
        projectProducts: '',
        projectDiploma: '',
        projectCost: 0,
        deadlineDate: '',
        isPrivate: false,
    })
    const formik = useFormik({
        initialValues: {
            projectName: '',
            projectImages: [],
            projectProducts: '',
            projectDescription: '',
            projectEquipment: '',
            projectDiploma: '',
            projectCost: 0,
            deadlineDate: '',
            isPrivate: false,
        },
        validationSchema: projectDetailsSchema,
        onSubmit: async (values) => {
            console.log(values)
            //setLoading(true);
            try {
// Prepare the data
                const data = {
                    projectName: values.projectName,
                    projectDescription: values.projectDescription,
                    projectProducts: values.projectProducts,
                    projectDiploma: values.projectDiploma,
                    projectCost: values.projectCost,
                    isPrivate: values.isPrivate,
                    deadlineDate: values.deadlineDate,
                    projectImages: null as string | null, // Specify the type as string | null and initialize with null
                };
                if (selectedProjectImages) {
                    const base64Image = await getBase64(selectedProjectImages);
                    data.projectImages = base64Image;
                }

                // Send the data to the server for processing
                // Replace `editProfile` with the appropriate function to save project details
                const response = await createProject(data, logout);
                if(response.message === 'Success'){

                    //handleClose()
                    stepper.current?.goNext();
                    setSelectedProjectImages(null)
                    formik.resetForm({
                        values: formik.initialValues,
                    });
                    setLoading(false);
                }

            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        },
    });

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const checkStep1 = (): boolean => {
        if (formik.errors.projectCost == undefined && formik.errors.projectProducts == undefined  ) {
            return true
        }
        return false
    }
    const checkStep2 = (): boolean => {
        if ( formik.errors.projectDiploma == undefined ) {
            return true
        }
        return false
    }
    const checkStep3 = (): boolean => {
        if (formik.errors.projectDescription == undefined && formik.errors.projectName == undefined && selectedProjectImages) {
            return true
        }
        return false
    }

    const checkStep4 = (): boolean => {
        if (formik.errors.isPrivate == undefined && formik.errors.deadlineDate == undefined) {
            return true
        }
        return false
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }

        stepper.current.goPrev()
        setStep(stepper.current?.currentStepIndex)
    }

    const nextStep = () => {
        if (!stepper.current) {
            return
        }

        if (stepper.current.getCurrentStepIndex() === 1) {
            if (!checkStep1()) {
                return
            }
        }
        if (stepper.current.getCurrentStepIndex() === 2) {
            if (!checkStep2()) {
                return
            }
        }

        if (stepper.current.getCurrentStepIndex() === 3) {
            if (!checkStep3()) {
                setHasError(true)
                return
            }
        }
        if (stepper.current.getCurrentStepIndex() === 4) {
            if (!checkStep1()) {
                return
            }
        }

        stepper.current.goNext()
        setStep(stepper.current?.currentStepIndex)
    }

    useEffect(() => {

        if (!stepperRef.current) {
            return
        }
        loadStepper()
    }, [stepperRef,formik])


    return createPortal(
        <Modal
            id='modal_create_project'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            onHide={handleClose}
            onEntered={loadStepper}
        >
            <div className='modal-header'>
                <h2>Déposez votre projet</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-lg-10 px-lg-10'>
                {/*begin::Stepper */}
                <div
                    ref={stepperRef}
                    className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'

                >
                    {/* begin::Aside*/}
                    <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
                        {/* begin::Nav*/}
                        <div className='stepper-nav ps-lg-10'>
                            {/* begin::Step 1*/}
                            <div className='stepper-item current' data-stepper-element='nav'>
                                {/* begin::Wrapper*/}
                                <div className='stepper-wrapper'>
                                    {/* begin::Icon*/}
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>1</span>
                                    </div>
                                    {/* end::Icon*/}

                                    {/* begin::Label*/}
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Objectifs</h3>

                                        <div className='stepper-desc'>Listez vos besoins en matériels et équipements</div>
                                    </div>
                                    {/* end::Label*/}
                                </div>
                                {/* end::Wrapper*/}

                                {/* begin::Line*/}
                                <div className='stepper-line h-40px'></div>
                                {/* end::Line*/}
                            </div>
                            {/* end::Step */}

                            {/* begin::Step 2*/}
                            <div className='stepper-item ' data-stepper-element='nav'>
                                {/* begin::Wrapper*/}
                                <div className='stepper-wrapper'>
                                    {/* begin::Icon*/}
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>2</span>
                                    </div>
                                    {/* end::Icon*/}

                                    {/* begin::Label*/}
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Diplomes</h3>

                                        <div className='stepper-desc'>Associez vos besoins à vos diplômes</div>
                                    </div>
                                    {/* end::Label*/}
                                </div>
                                {/* end::Wrapper*/}

                                {/* begin::Line*/}
                                <div className='stepper-line h-40px'></div>
                                {/* end::Line*/}
                            </div>
                            {/* end::Step 2*/}

                            {/* begin::Step 3*/}
                            <div className='stepper-item' data-stepper-element='nav'>
                                {/* begin::Wrapper*/}
                                <div className='stepper-wrapper'>
                                    {/* begin::Icon*/}
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>3</span>
                                    </div>
                                    {/* begin::Icon*/}

                                    {/* begin::Label*/}
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Description</h3>

                                        <div className='stepper-desc'>Décrivez les enjeux de vos besoins en équipement </div>
                                    </div>
                                    {/* begin::Label*/}
                                </div>
                                {/* end::Wrapper*/}

                                {/* begin::Line*/}
                                <div className='stepper-line h-40px'></div>
                                {/* end::Line*/}
                            </div>
                            {/* end::Step 3*/}


                            {/* begin::Step 4*/}
                            <div className='stepper-item' data-stepper-element='nav'>
                                {/* begin::Wrapper*/}
                                <div className='stepper-wrapper'>
                                    {/* begin::Icon*/}
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>4</span>
                                    </div>
                                    {/* end::Icon*/}

                                    {/* begin::Label*/}
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Campagne de financement</h3>
                                        <div className='stepper-desc'>Planifiez votre campagne de financement</div>
                                    </div>
                                    {/* end::Label*/}
                                </div>
                                {/* end::Wrapper*/}
                                {/* begin::Line*/}
                                <div className='stepper-line h-40px'></div>
                                {/* end::Line*/}
                            </div>
                            {/* end::Step 4*/}

                            {/* begin::Step 5*/}
                            <div className='stepper-item' data-stepper-element='nav'>
                                {/* begin::Wrapper*/}
                                <div className='stepper-wrapper'>
                                    {/* begin::Icon*/}
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>5</span>
                                    </div>
                                    {/* end::Icon*/}

                                    {/* begin::Label*/}
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Terminé</h3>

                                        <div className='stepper-desc'>Réviser et soumettre</div>
                                    </div>
                                    {/* end::Label*/}
                                </div>
                                {/* end::Wrapper*/}
                            </div>
                            {/* end::Step 5*/}
                        </div>
                        {/* end::Nav*/}
                    </div>
                    {/* begin::Aside*/}

                    {/*begin::Content */}
                    <div className='flex-row-fluid py-lg-5 px-lg-15'>
                        {/*begin::Form */}
                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                            <Step1 formik={formik} setButton={setButtonVisibility}/>
                            <Step2 formik={formik} />
                            <Step3 formik={formik} updateData={setSelectedProjectImages} />
                            <Step4 formik={formik} />
                            <Step5 />

                            {/*begin::Actions */}
                            <div className='d-flex flex-stack pt-10'>
                                 <div className='me-2'>
                                     {(  stepper.current?.currentStepIndex == 2 || (buttonVisibility && stepper.current?.currentStepIndex == 3) || stepper.current?.currentStepIndex == 4) &&<button
                                        type='button'
                                        className='main-btn-3 me-3 main-btn-small'

                                        onClick={prevStep}
                                    >
                                        <KTIcon iconName='arrow-left' className='fs-3 me-1' /> Précedent
                                    </button>}
                                </div>
                                <div>
                                    {stepper.current?.currentStepIndex == 4 && <button
                                        type='button'
                                        className='main-btn-small'

                                        onClick={()=>formik.submitForm()}
                                        disabled={loading}
                                    >
                                        {!loading ? 'Déposez' : 'Patientez...'}
                                        {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>} <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                                    </button>}


                                    {(!stepper.current?.currentStepIndex ||  stepper.current?.currentStepIndex == 1 || stepper.current?.currentStepIndex == 2|| (buttonVisibility && stepper.current?.currentStepIndex == 3)) &&  <button
                                        type='button'
                                        className='main-btn-small'

                                        onClick={nextStep}
                                        style={{display: 'flex', alignItems: 'center'}}
                                    >
                                        Suivant <KTIcon iconName='arrow-right' className='fs-3 ms-1 me-0' />
                                    </button>}
                                </div>
                            </div>
                            {/*end::Actions */}
                        </form>
                        {/*end::Form */}
                    </div>
                    {/*end::Content */}
                </div>
                {/* end::Stepper */}
            </div>
        </Modal>,
        modalsRoot
    )
}

export {NewProject}
