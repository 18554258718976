/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_rebusiness/helpers'
import {Project,User} from '../../core/_models'

type Props = {
  project: Project
    user:User
}

const ProjectInfoCell2: FC<Props> = ({project,user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column'>
      <a href={`/project/${project.uniqueID}`}  target='_blank'
         rel='noopener noreferrer' className='text-gray-800 text-hover-primary mb-1'>
        {project.projectName.slice(0,50)} <div className='badge badge-light fw-bolder'> {project.status =='canceled' && <div className='badge badge-light-danger fw-bolder'>Annulé</div>}
          {project.status =='online' && <div className='badge badge-light fw-bolder'>En cours</div>}
          {project.status =='success' && <div className='badge badge-light fw-bolder'>Terminé</div>}</div>
      </a>
      <span>{project.projectDescription.slice(0,80)}</span>
    </div>
  </div>
)

export {ProjectInfoCell2}
