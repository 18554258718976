import {KTIcon} from '../../../../../../_rebusiness/helpers'
import {useContributionListView} from '../../core/ListViewProvider'
import {ContributionsListFilter} from './ContributionsListFilter'

const ContributionsListToolbar = () => {
  const {setItemIdForUpdate} = useContributionListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-user-table-toolbar='base'>
      <ContributionsListFilter />

      {/* begin::Export */}
     {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button>*/}
      {/* end::Export */}

      {/* begin::Add user */}
      {/*<button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add User
      </button>*/}
      {/* end::Add user */}
    </div>
  )
}

export {ContributionsListToolbar}
