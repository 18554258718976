/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_rebusiness/helpers'
import {useAuth} from "../../auth";
import {getAdminActivities} from "../../../core/_requests";

type Props = {
  className: string
  items?: number
  statsData:any
}
type ProjectType = {
  id: number;
  projectName: string;
  visits: number;
  uniqueID: string;
  projectDescription: string;
  projectImages: string;
};

const TrendingWidget: React.FC<Props> = ({statsData,className, items = 6}) => {

  return (
    <div className='card card-xl-stretch mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Tendances</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Les projets les plus visités</span>
        </h3>
        <div className='card-toolbar'>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {statsData?.mostVisitedProjects?.map((project: ProjectType) => (
            <div className='d-flex align-items-sm-center mb-7' key={project.id}>

              <div className='symbol symbol-50px me-5'>
            <span className='symbol-label'>
              <img
                  src={toAbsoluteUrl(`${process.env.REACT_APP_PUBLIC_URL}/${project.projectImages}`)}
                  className='h-50 align-self-center'
                  alt=''
              />
            </span>
              </div>
              <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                <div className='flex-grow-1 me-2'>
                  <a href={`/project/${project.uniqueID}`}   target='_blank'
                     rel='noopener noreferrer' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                    {project.projectName.slice(0,50)}...
                  </a>
                  <span className='text-muted fw-semibold d-block fs-7'>{project.projectDescription.slice(0,200)}[...]</span>
                </div>
              </div>
              <span className='badge badge-light fw-bold my-2'>Visites: {project.visits}</span>
            </div>

        ))}
      </div>
      {/* end::Body */}
    </div>
  )
}


export {TrendingWidget}
