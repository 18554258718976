/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState,useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link,useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../../../core/_requests'
import {toAbsoluteUrl} from '../../../../_rebusiness/helpers'
import {useAuth} from '../core/Auth'
import {AuthLayout} from "../AuthPage";

const loginSchema = Yup.object().shape({
  email: Yup.string()
      .email('Format d\'email incorrect')
      .min(3, 'Au moins 3 caractères requis')
      .max(50, 'Maximum 50 caractères autorisés')
      .required('L\'email est requis'),
  password: Yup.string()
      .min(3, 'Au moins 3 caractères requis')
      .max(50, 'Maximum 50 caractères autorisés')
      .required('Le mot de passe est requis'),
})


const initialValues = {
  email: 'supplier@admin.fr',
  password: 'admin*crowdfundcfa!23',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {currentUser,saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate();
  useEffect(() => {
    // Check if currentUser exists and has a role
    if (currentUser && currentUser.role) {
      // Perform redirection based on the role
      switch (currentUser.role) {
        case 'admin':
          navigate('/admin'); // Redirect to the admin dashboard
          break;
        case 'cfa':
          navigate('/cfa'); // Redirect to the user dashboard
          break;
        case 'entreprise':
          navigate('/entreprise'); // Redirect to the user dashboard
          break;
        case 'fournisseur':
          navigate('/fournisseur'); // Redirect to the user dashboard
          break;
      }
    }
  }, [currentUser, navigate]);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth);
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
        window.location.href = '/connexion'
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Les informations de connexion sont incorrectes')
        setSubmitting(false)
        setLoading(false)

      }
    },
  })

  return (
      <AuthLayout>
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='login_signin_form'
        >
          {/* begin::Heading */}
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>Connexion</h1>
            {/*<div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>*/}
          </div>
          {/* begin::Heading */}

          {/* begin::Login options */}
          {/*      <div className='row g-3 mb-9'>
        <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a>
        </div>

        <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
        </div>
      </div>*/}
          {/* end::Login options */}

          {/* begin::Separator */}
          {/*<div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div>*/}
          {/* end::Separator */}

          {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
          ) : (
              <div className='mb-10 bg-light-success p-8 rounded'>
                <div className='text-success'>
                  Vous êtes déja inscrit ?  <strong>connectez vous sans attendre </strong> ou <a href={'/inscription'}><strong>inscrivez-vous</strong></a> et participez à l'aventure
                </div>
              </div>
          )}

          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
                placeholder='Email'
                {...formik.getFieldProps('email')}
                className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                )}
                type='email'
                name='email'
                autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mot de passe</label>
            <input
                placeholder='Mot de passe'
                type='password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                )}
            />
            {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Wrapper */}
          <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
            <div />

            {/* begin::Link */}
            <Link to='/mot-de-passe-oublie' className='link-primary'>
              Mot de passe Oublié ?
            </Link>
            {/* end::Link */}
          </div>
          {/* end::Wrapper */}

          {/* begin::Action */}
          <div className='d-grid mb-10'>
            <button
                type='submit'
                id='sign_in_submit'
                className='main-btn-back'
                disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Connexion</span>}
              {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
              Patientez...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
              )}
            </button>
          </div>
          {/* end::Action */}

          {/*<div className='text-gray-500 text-center fw-semibold fs-6'>
            Pas encore membre{' '}
            <Link to='/inscription' className='link-primary'>
              Inscription
            </Link>
          </div>*/}
        </form>
      </AuthLayout>

  )
}
