export const contactFormArea = {
  tagline: "Contactez-nous maintenant",
  title: "Écrire un message",
  inputs: [
    {
      id: 1,
      type: "text",
      placeholder: "Prénom",
      name: "fname",
    },
    {
      id: 2,
      type: "text",
      placeholder: "Nom",
      name: "lname",
    },/*
    {
      id: 3,
      type: "text",
      placeholder: "Nom de l'entreprise ",
      name: "companyName",
    },*/
    {
      id: 3,
      type: "text",
      placeholder: "Raison sociale ",
      name: "type",
    },
    {
      id: 4,
      type: "email",
      placeholder: "Email ",
      name: "email",
    },
    {
      id: 5,
      type: "text",
      placeholder: "Téléphone",
      name: "number",
    },
    {
      id: 6,
      placeholder: "Votre message",
      name: "message",
      cols: 30,
      rows: 10,
    },
  ],
};

export const contactInfoArea = [
  {
    id: 1,
    icon: "flaticon-entrepreneur-1",
    title: "About Krowd",
    text: "Morbi ut tellus ac leo molestie luctus nec \n vehicula sed justo onec tempor rhoncus \n volutpat ras lorem.",
  },
  {
    id: 2,
    icon: "flaticon-location",
    title: "About Krowd",
    lists: [
      "66 Road Broklyn Street, 600 New York.",
      "United States of America",
      "P.O Box 0000",
    ],
  },
  {
    id: 3,
    icon: "flaticon-contact",
    title: "About Krowd",
    lists: ["needhelp@crowdfundcfa.com", "info@crowdfundcfa.com", "666 888 0000"],
  },
];
