/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, ChangeEvent, useEffect} from 'react'
import {useAuth} from "../../app/pages/auth";
import {ErrorMessage, Field} from 'formik'
import {KTIcon, toAbsoluteUrl} from '../../_rebusiness/helpers'
import {Container} from "react-bootstrap";
import {
    confirmPaymentIntent,
    createContributions,
    createCustomer,
    createInvoice,
    getProject
} from "../../app/core/_requests";
import {useLocation} from "react-router-dom";
import $ from 'jquery';
import {CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {Product} from "../../app/core/_models";


const Payment: FC = (products: any) => {
    const [currentState, setCurrentState] = useState<'month' | 'annual'>('month')
    const [selected, setSelected] = useState('Startup')
    const {currentUser, logout} = useAuth()
    const [dialerValue, setDialerValue] = useState(0);
    const location = useLocation()
    const [cardHolderName, setCardHolderName] = useState(null);
    const [cardNumber, setCardNumber] = useState(null);
    const [cardExpiry, setCardExpiry] = useState(null);
    const [cardCvc, setCardCvc] = useState(null);
    const [cardNumberError, setCardNumberError] = useState(null);
    const [cardExpiryError, setCardExpiryError] = useState(null);
    const [cardCvcError, setCardCvcError] = useState(null);
    const [cardError, setCardError] = useState("");
    const [productError, setProductError] = useState("");
    const [transferError, setTransferError] = useState("");
    const [product, setProduct] = useState([]);
    const [projectName, setProjectName] = useState("");

    const fetchData = async () => {
        try {
            const data = await getProject(location.pathname.split('/')[2], logout)
            setProjectName(data.project?.projectName);

            const updatedProducts = data?.project?.ProjectProducts?.map((product: Product) => ({
                ...product,
                selectedQuantity: 0,
            }));
            setProduct(updatedProducts);
        } catch (error) {
            console.error('Error fetching project data:', error);
        }
    };
    useEffect(() => {
        // Perform any asynchronous operations here
        if (location.pathname.split('/')[1] == 'project') {
            fetchData();
        }
    }, [location.pathname,]);
    useEffect(() => {
        calculateContribution();
        const productsConcerned = product.filter((item: any) => item.quantity > 0)
        if(product.length>0 && productsConcerned?.length == 0 && selected =='Startup'){
            setSelected('finish')
        }
    }, [product]);
    useEffect(() => {
    }, [dialerValue]);
    const handleCardHolderName = (event: any) => {
        setCardHolderName(event.target.value);
    };
    const handleCardNumberChange = (event: any) => {
        setCardNumber(event.complete ? event.value : null);
        setCardNumberError(!event.complete ? event.error.message : null);
    };

    const handleCardExpiryChange = (event: any) => {
        setCardExpiry(event.complete ? event.value : null);
        setCardExpiryError(!event.complete ? event.error.message : null)
    };

    const handleCardCvcChange = (event: any) => {
        setCardCvc(event.complete ? event.value : null);
        setCardCvcError(!event.complete ? event.error.message : null)
    };
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (event: any) => {
        setCardError("")
        if (dialerValue > 0) {
            event.preventDefault();
            const cardElement = elements?.getElement(CardNumberElement);
            if (!stripe || !elements || !cardElement) {
                console.error('Stripe.js has not loaded.');
                return;
            }

            try {
                const {error, paymentMethod} = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                });
                if (error) {
                    console.error('Error creating PaymentMethod:', error.message);
                    // Handle error state
                    return;
                }
                // Pass this payment method to your server to create a PaymentIntent
                const serverResponse = await confirmPaymentIntent({
                    paymentMethod: paymentMethod,
                    amount: dialerValue,
                    name: cardHolderName
                }, logout)
                if (serverResponse.clientSecret) {
                    if (serverResponse.response.status == "succeeded" && !serverResponse.response.next_action) {
                        handlePaymentSuccess({id: serverResponse.response.id, status: serverResponse.response.status})
                    } else {
                        try {
                            // Use the clientSecret to confirm the Payment Intent
                            const {
                                paymentIntent,
                                error
                            } = await stripe.confirmCardPayment(serverResponse.clientSecret, {
                                payment_method: paymentMethod.id,
                            });

                            if (error) {
                                console.error('Error confirming PaymentIntent:', error);
                            } else {
                                if (paymentIntent.status === 'succeeded') {
                                    // Call your function to handle successful payment
                                    handlePaymentSuccess({id: paymentIntent.id, status: paymentIntent.status})

                                } else if (paymentIntent.status === 'requires_action') {
                                    // Additional action is required
                                    const {error} = await stripe.handleCardAction(serverResponse.clientSecret);
                                    if (error) {
                                        console.error('Error handling card action:', error.message);
                                        // error in the card action
                                    } else {
                                        handlePaymentSuccess({id: paymentIntent.id, status: paymentIntent.status})
                                    }
                                } else {
                                    console.error('Unexpected PaymentIntent status:', paymentIntent.status);
                                    // unexpected state
                                }
                            }
                        } catch (error) {
                            console.error('Error confirming PaymentIntent:', error);
                            // Handle other errors
                        }
                    }

                } else {
                    setCardError("Paiement échoué")

                }
            } catch (error) {
                console.error('Error creating PaymentMethod:', error);
                // Handle other errors
            }
        } else {
            setCardError('Choisissez les produits à financer')
        }

    };
    const handlePaymentSuccess = async (event: any) => {
        const productsConcerned = product.filter((item: any) => item.selectedQuantity > 0)
        let productsContribution: any = [];
        productsConcerned.map((prod: any) => {
            if (prod['productId']) {
                productsContribution.push({
                    id: prod['id'],
                    productName: prod?.Product?.productName,
                    productPrice: prod?.Product?.productPrice,
                    quantity: prod.selectedQuantity
                })
            } else {
                productsContribution.push({
                    id: prod['id'],
                    productName: prod['productName'],
                    productPrice: prod['productPrice'],
                    quantity: prod.selectedQuantity
                })

            }
        })
        await createContributions({
            amount: dialerValue,
            method: selected,
            projectPath: location.pathname.split('/')[2],
            stripeId: event.id,
            stripeStatus: event.status,
            products: productsContribution
        }, logout);
        setCardNumberError(null)
        setCardExpiryError(null)
        setCardCvcError(null)
        setCardError("")
        setProductError("")
        setTransferError("")
        setCardCvc(null)
        setCardExpiry(null)
        setCardNumber(null)
        setCardHolderName(null)
        setDialerValue(0)
        setSelected('Startup')
        setProduct([])
        await fetchData()
        $("[data-bs-dismiss='modal']").trigger("click");

    }
    const validateTransfer = async () => {
        const productsConcerned = product.filter((item: any) => item.selectedQuantity > 0)
        let productsContribution: any = [];
        productsConcerned.map((prod: any) => {
            if (prod['productId']) {
                productsContribution.push({
                    id: prod['id'],
                    productName: prod?.Product?.productName,
                    productPrice: prod?.Product?.productPrice,
                    quantity: prod.selectedQuantity
                })
            } else {
                productsContribution.push({
                    id: prod['id'],
                    productName: prod['productName'],
                    productPrice: prod['productPrice'],
                    quantity: prod.selectedQuantity
                })

            }
        })
        setTransferError("")
        if (dialerValue > 0) {
            if (!stripe) {
                console.error('Stripe.js has not loaded.');
                return;
            }
            try {
                const customer = await createCustomer({
                    name: currentUser?.companyName,
                    email: currentUser?.email
                }, logout);
                 await createInvoice({
                        amount: dialerValue,
                        customerId: customer?.data.id,
                        projectPath: location.pathname.split('/')[2],
                        products: productsContribution
                    }
                    , logout);
                setCardNumberError(null)
                setCardExpiryError(null)
                setCardCvcError(null)
                setCardError("")
                setProductError("")
                setTransferError("")
                setCardCvc(null)
                setCardExpiry(null)
                setCardNumber(null)
                setCardHolderName(null)
                setDialerValue(0)
                setSelected('Startup')
                setProduct([])
                await fetchData()
                $("[data-bs-dismiss='modal']").trigger("click")
            } catch (e) {
            }
        } else {
            setTransferError('Choisissez les produits à financer')
        }
    };
    const calculateContribution = () => {
        let total = 0;
        if (product) {
            product.map((prod: any) => {
                if (prod.selectedQuantity !== 0) {
                    if (prod.productId) {
                        total += Number(prod?.selectedQuantity * prod?.Product?.productPrice)
                    } else {
                        total += Number(prod?.selectedQuantity * prod?.productPrice)
                    }
                }
            });
            setDialerValue(total);
        }
    }
    const increaseDialerValue = (selectedProduct: any) => {
        setProduct((product: any) => product.map((prod: any) => {
                if (prod?.id === selectedProduct.id && Number(prod?.quantity - prod?.selectedQuantity) !== 0) {
                    return {...prod, selectedQuantity: Number(prod?.selectedQuantity + 1)};
                }
                return prod


            })
        );
    };
    const decreaseDialerValue = (selectedProduct: any) => {
        setProduct((product: any) => product.map((prod: any) => {
                if (prod?.id === selectedProduct.id && prod?.selectedQuantity !== 0) {
                    return {...prod, selectedQuantity: Number(prod?.selectedQuantity - 1)};
                }
                return prod
            })
        );
    };
    const handleInputChange = (product: any, quantity: number) => {
        if (quantity > 0) {
            const clampedQuantity = Math.max(0, product?.Product?.productStock);
            setProduct((product: any) => product.map((prod: any) => {
                if (prod.Product.uniqueID === product?.Product?.uniqueID) {
                    return {...prod, selectedQuantity: Number(clampedQuantity)};
                }
                return prod
            }));
        }
    };
    return (
        <div className='modal fade' id='modal_funding_project' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered modal-xl'>
                <div className='modal-content rounded'>
                    <div className='modal-header justify-content-end border-0 pb-0'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                            <KTIcon iconName='cross' className='fs-1'/>
                        </div>
                    </div>
                    <div className='modal-body pt-0 pb-15 px-5 px-xl-20'>
                        <div className='mb-13 text-center'>
                            <h1 className='mb-3'>{projectName}</h1>
                            {!currentUser && <div className='text-muted fw-bold fs-5'>
                                Connectez vous pour pouvoir soutenir un projet <a href='/connexion'
                                                                                  className='link-primary fw-bolder'>
                                Connexion
                            </a>
                                . <br/>
                                Vous n'avez pas encore de compte <a href='/inscription'
                                                                    className='link-primary fw-bolder'>
                                Inscrivez-vous
                            </a>
                            </div>}
                        </div>
                        {selected == 'finish' && <div className='fw-bold fs-5' style={{textAlign:'center',}}>

                            <p className='fw-semibold' style={{color:'black'}}>Ce projet à été financé intégralement. </p><br/>
                            <p style={{color:'black'}}>Vous pouvez trouver d'autres projets en attente de financement. <a href='/projets'
                                                                                              className='link-primary fw-bolder'>
                            Voir d'autres projets.
                            </a></p>
                        </div>}
                        {currentUser && selected !== 'finish' && <div>
                            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                                <span className='required'>Montant de votre subvention</span><br/>
                                <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='Montant'
                                ></i>
                            </label>
                            <p>Ce montant est calculé automatiquement, veuillez sélectionnez le produit a financé en
                                augmentant sa quantité.</p>
                            <p>A partir de vos données communiquées, le solde prévisionnel de votre taxe d'apprentissage
                                est de <b>{currentUser?.companyTax} €</b></p>
                            <div className="input-group w-md-100 mb-20" data-dialer="true" data-dialer-prefix="€">
                                <input type="text" className="form-control" placeholder="Amount" readOnly={true}
                                       value={`${dialerValue}€`} data-dialer-control="input"
                                       style={{fontSize: '5rem', textAlign: 'center', height: '90px'}}/>
                                {/*<button className="btn btn-icon btn-outline btn-active-color-primary" type="button" onClick={decreaseDialerValue} data-dialer-control="decrease" style={{width:'90px',height:'90px'}}>
                      <i className="ki-duotone ki-minus fs-2"></i>
                  </button>
                  <input type="text" className="form-control" placeholder="Amount"  onChange={handleInputChange} value={`${dialerValue}€`} data-dialer-control="input" style={{fontSize:'5rem',textAlign:'center',height:'90px'}}/>
                  <button className="btn btn-icon btn-outline btn-active-color-primary" type="button" onClick={increaseDialerValue} data-dialer-control="increase" style={{width:'90px',height:'90px'}}>
                      <i className="ki-duotone ki-plus fs-2"></i>
                  </button>*/}
                            </div>

                            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                                <span className='required'>Produit en attente de financement :</span>

                            </label>
                            {product?.length > 0 ? product?.map((product: any) => (
                                <div style={{margin: '10px', width: '100%'}} key={product?.id}
                                     className='d-flex justify-content-between mb-7'>
                                    <div className='d-flex align-items-center '>
                                        {product.productId && <div className='symbol symbol-2by3 symbol-50px me-5'>
                                            <img
                                                src={toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL + '/' + product?.Product?.productImages)}
                                                className='' alt=''/>
                                        </div>}
                                        <div className='flex-grow-1'>
                                            <a
                                                href={product.productId ? '/product/' + product?.Product?.uniqueID : '#'}
                                                target={'_blank'}
                                                className='text-dark fw-bold text-hover-primary fs-6'
                                                onClick={(e) => {
                                                    if (!product.productId) {
                                                        e.preventDefault(); // Prevent navigation
                                                    }
                                                }}
                                            >
                                                {product?.Product?.productName.slice(0, 100) || product?.productName.slice(0, 100)}
                                            </a>
                                            <span className='text-dark fw-bold  d-block fw-semibold'>Quantité à financer: {Number(product?.quantity - product?.selectedQuantity)} | Prix unitaire: {product?.productId ? product?.Product?.productPrice + ' €' : product.productPrice + ' €'}{/*| Prix total: {Number(product?.quantity * product?.Product?.productPrice)+' €'}*/}</span>
                                            {product.productId && <span
                                                className='text-muted d-block fw-semibold'>{product?.Product?.productDescription?.slice(0, 100) + '[...]'}</span>}
                                        </div>
                                    </div>
                                    {/* end::Text */}
                                    <div className="input-group " style={{width: '30%', marginTop: '20px'}}
                                         data-dialer="true" aria-valuemin={0}
                                         aria-valuemax={product?.Product?.productStock || null}>
                                        <button className="btn btn-icon btn-outline btn-active-color-primary"
                                                type="button" onClick={() => decreaseDialerValue(product)}
                                                data-dialer-control="decrease" style={{width: '50px', height: '50px'}}>
                                            <i className="ki-duotone ki-minus fs-2"></i>
                                        </button>
                                        <input type="number"
                                               readOnly={true}
                                               disabled={product.quantity == 0}
                                               value={product?.selectedQuantity} className="form-control"
                                               placeholder="Amount"
                                               min={0}
                                               max={product?.Product?.productStock}
                                               onChange={e => handleInputChange(product, parseInt(e.target.value))}
                                               style={{
                                                   fontSize: '2rem',
                                                   textAlign: 'center',
                                                   height: '50px',
                                                   width: '30%'
                                               }}/>
                                        <button className="btn btn-icon btn-outline btn-active-color-primary"
                                                type="button" onClick={() => increaseDialerValue(product)}
                                                data-dialer-control="increase" style={{width: '50px', height: '50px'}}>
                                            <i className="ki-duotone ki-plus fs-2"></i>
                                        </button>
                                    </div>
                                </div>
                            )) : null}

                            <div className='fv-row'>
                                <div className='text-danger mt-2'>
                                    {/*<ErrorMessage name='nameOnCard' />*/}
                                    {productError}
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <input
                                            type='radio'
                                            className='btn-check'
                                            name='role'
                                            value='cfa'
                                            id='role_cfa'
                                            onClick={() => {
                                                setProductError('')
                                                if (dialerValue > 0) {
                                                    setSelected('carte')
                                                } else {
                                                    setProductError('Choisissez les produits à financer')
                                                }

                                            }}
                                        />
                                        <label
                                            className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex flex-column  align-items-center mb-10'
                                            htmlFor='role_cfa'
                                        >
                                            <KTIcon iconName='credit-cart' className='fs-3x '/><br/>

                                            <span className='d-block fw-bold text-center'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Paiement par carte</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  Sécurité, rapidité et simplicité.
                </span>
              </span>
                                        </label>
                                    </div>

                                    <div className='col-lg-6'>
                                        <input
                                            type='radio'
                                            className='btn-check'
                                            name='role'
                                            value='entreprise'
                                            id='role_enterprise'
                                            onClick={() => {
                                                setProductError('')
                                                if (dialerValue > 0) {
                                                    setSelected('virement')
                                                } else {
                                                    setProductError('Choisissez les produits à financer')
                                                }

                                            }}
                                        />
                                        <label
                                            className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex flex-column  align-items-center mb-10'
                                            htmlFor='role_enterprise'
                                        >
                                            <KTIcon iconName='bank' className='fs-3x '/><br/>

                                            <span className='d-block fw-bold text-center'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Paiement par virement</span>
                <span className='text-gray-400 fw-bold fs-6'>
                 Sécurité et frais réduits.
                </span>
              </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {selected == 'carte' && <Container>
                            <div className='w-100'>
                                <div className='pb-10 pb-lg-15'>
                                <h2 className='fw-bolder text-dark'>Paiement par carte</h2>
                                </div>
                                {cardError && <div className='mb-10 bg-light-danger p-8 rounded'>
                                    <div className='text-danger'>
                                        <strong>Erreur de paiement : </strong> {cardError}
                                    </div>
                                </div>}
                                <div className='d-flex flex-column mb-7 fv-row'>
                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                        <span className='required'>Nom</span>
                                        <i
                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                            data-bs-toggle='tooltip'
                                            title="Nom du détenteur de la carte"
                                        ></i>
                                    </label>

                                    <input
                                        type='text'
                                        className='form-control form-control-solid'
                                        placeholder=''
                                        name='nameOnCard'
                                        onChange={handleCardHolderName}
                                    />
                                    <div className='text-danger mt-2'>
                                        {/* <ErrorMessage name='nameOnCard' />*/}

                                    </div>
                                </div>

                                <div className='d-flex flex-column mb-7 fv-row'>
                                    <label className='required fs-6 fw-bold form-label mb-2'>Numéros de la carte</label>

                                    <div className='position-relative'>
                                        <CardNumberElement
                                            onChange={handleCardNumberChange}
                                            className='form-control form-control-solid'
                                            options={{
                                                style: {
                                                    base: {
                                                        fontSize: '14px',
                                                        color: '#424770',
                                                        '::placeholder': {
                                                            color: '#aab7c4',
                                                        },
                                                    },
                                                    invalid: {
                                                        color: '#9e2146',
                                                    },
                                                },
                                            }}
                                        />
                                        <div className='text-danger mt-2'>
                                            {/*<ErrorMessage name='nameOnCard' />*/}
                                            {cardNumberError}
                                        </div>


                                        <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                                            <img src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')} alt=''
                                                 className='h-25px'/>
                                            <img
                                                src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                                                alt=''
                                                className='h-25px'
                                            />
                                            <img
                                                src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}
                                                alt=''
                                                className='h-25px'
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-10'>
                                    <div className='col-md-8 fv-row'>
                                        <label className='required fs-6 fw-bold form-label mb-2'>Date
                                            d'expiration</label>

                                        <div className='row fv-row'>
                                            <CardExpiryElement onChange={handleCardExpiryChange}
                                                               className='form-control form-control-solid'
                                                               options={{
                                                                   style: {
                                                                       base: {
                                                                           fontSize: '14px',
                                                                           color: '#424770',
                                                                           '::placeholder': {
                                                                               color: '#aab7c4',
                                                                           },
                                                                       },
                                                                       invalid: {
                                                                           color: '#9e2146',
                                                                       },
                                                                   },
                                                               }}
                                            />
                                            <div className='text-danger mt-2'>
                                                {/*<ErrorMessage name='nameOnCard' />*/}
                                                {cardExpiryError}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-4 fv-row'>
                                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                            <span className='required'>CVV</span>
                                            <i
                                                className='fas fa-exclamation-circle ms-2 fs-7'
                                                data-bs-toggle='tooltip'
                                                title='Enter a card CVV code'
                                            ></i>
                                        </label>

                                        <div className='position-relative'>
                                            <CardCvcElement onChange={handleCardCvcChange}
                                                            className='form-control form-control-solid'
                                                            options={{
                                                                style: {
                                                                    base: {
                                                                        fontSize: '14px',
                                                                        color: '#424770',
                                                                        '::placeholder': {
                                                                            color: '#aab7c4',
                                                                        },
                                                                    },
                                                                    invalid: {
                                                                        color: '#9e2146',
                                                                    },
                                                                },
                                                            }}
                                            />

                                            <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                                                <KTIcon iconName='credit-cart' className='fs-2hx'/>
                                            </div>
                                            <div className='text-danger mt-2'>
                                                {/*<ErrorMessage name='nameOnCard' />*/}
                                                {cardCvcError}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="project-btn mt-25">
                                <a className="main-btn"
                                   style={{width: '100%'}} href="#" onClick={handleSubmit}>
                                    Valider le paiement
                                </a>
                            </div>
                        </Container>}
                        {selected == 'virement' && <Container>
                            <p> Cliquer sur le bouton pour recevoir les instructions de paiement par email</p>
                            <p> une fois votre virement réalisé il sera automatiquement mis a jour dès sa reception </p>
                            {transferError && <div className='mb-10 bg-light-danger p-8 rounded'>
                                <div className='text-danger'>
                                    <strong>Erreur de paiement : </strong> {transferError}
                                </div>
                            </div>}
                            <div className="project-btn mt-25">
                                <a className="main-btn"
                                   style={{width: '100%'}} href="#" onClick={() => validateTransfer()}>
                                    Envoyer les instructions du virement
                                </a>
                            </div>
                        </Container>}

                    </div>
                </div>
            </div>
        </div>
    )
}

export {Payment}
