import React, { FC, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { MenuComponent } from '../../../../../../_rebusiness/assets/ts/components';
import { ID, KTIcon, QUERIES } from '../../../../../../_rebusiness/helpers';
import { useProjectListView } from '../../core/ListViewProvider';
import { useProjectQueryResponse } from '../../core/QueryResponseProvider';
import { deleteUser } from '../../core/_requests';
import { statusProjects } from '../../core/_requests';

type Props = {
    id: ID;
    project: any;
};

const ProjectActionsCell: FC<Props> = ({ id, project }) => {
    const { setItemIdForUpdate } = useProjectListView();
    const { query } = useProjectQueryResponse();
    const queryClient = useQueryClient();

    useEffect(() => {
        MenuComponent.reinitialization();
    }, []);

    const openProjectModal = () => {
        setItemIdForUpdate({ ...project, projectId: project.id });
    };

    const validateProject = useMutation(() => statusProjects(id, 'online'), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
            // ✅ update detail view directly
            queryClient.invalidateQueries([`${QUERIES.PROJECTS_LIST}-${query}`]);
        },
    });

    const banUser = useMutation(() => statusProjects(id, 'rejected'), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
            // ✅ update detail view directly
            queryClient.invalidateQueries([`${QUERIES.PROJECTS_LIST}-${query}`]);
        },
    });

    return (
        <>
            <div className='d-flex justify-content-end flex-shrink-0'>

                    <a href='#' onClick={() => validateProject.mutate()} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                        <KTIcon iconName='check' className='fs-3' />
                    </a>
                    <a href='#' onClick={() => banUser.mutate()} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                        <KTIcon iconName='cross' className='fs-3' />
                    </a>
                <a href='#' onClick={openProjectModal} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                    <KTIcon iconName='eye' className='fs-3' />
                </a>
            </div>
        </>
    );
};

export { ProjectActionsCell };
