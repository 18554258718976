import {DisableSidebar, PageTitle} from '../../../_rebusiness/layout/core'

import {ProjectsListWrapper} from "./management-list/ProjectsList";
import {useAuth} from "../auth";


const ProjectsManager = () => {
    const {currentUser}=useAuth()

    return (

        <DisableSidebar>
            <PageTitle description='Administration du site' breadcrumbs={[]}>
                Bonjour{currentUser?.contactFirstName}
            </PageTitle>
            <ProjectsListWrapper type={'projects'}/>
        </DisableSidebar>
    )
}

export {ProjectsManager}
