import {DisableSidebar, PageLink, PageTitle} from '../../../_rebusiness/layout/core'
import {useAuth} from "../../pages/auth";
import {KTSVG,KTIcon,toAbsoluteUrl} from "../../../_rebusiness/helpers";
import React, { createContext, useState, ReactNode, Dispatch, SetStateAction,useContext } from 'react';
import {useThemeMode} from "../../core/theme-mode/ThemeModeProvider";
import {Link, Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {CfaAccountSettings } from "./CfaAccountSettings";
import {ProfileHeader } from "../../modules/profile/ProfileHeader";
import {OverviewAccount } from "../../modules/accounts/OverviewAccount";
import {OverviewCfa } from "./OverviewCfa";
import {ProjectsCfa } from "./ProjectsCfa";

const profilecfaBreadCrumbs: Array<PageLink> = [
    {
        title: 'cfa',
        path: 'cfa/overview',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]
const CfaSpace : React.FC = () => {
    const {currentUser, logout} = useAuth()
    const {mode} = useThemeMode()
    const [tab, setTab] = useState(0);

    return (
    <DisableSidebar>
      <PageTitle description="Espace d'administration" >
          {/*Hello{currentUser?.contactFirstName.toUpperCase()}*/}
      </PageTitle>
        <Routes>
            <Route
                element={
                    <>
                        <ProfileHeader />
                        <Outlet />
                    </>
                }
            >
                <Route
                    path='overview'
                    element={
                        <>
                            <PageTitle breadcrumbs={profilecfaBreadCrumbs}>Accueil</PageTitle>
                            <OverviewCfa />
                        </>
                    }
                />
                <Route
                    path='account'
                    element={
                        <>
                            <PageTitle breadcrumbs={profilecfaBreadCrumbs}>Mon compte</PageTitle>
                            <OverviewAccount />
                        </>
                    }
                />
                <Route
                    path='myprojects'
                    element={
                        <>
                            <PageTitle breadcrumbs={profilecfaBreadCrumbs}>Mes projets</PageTitle>
                            <ProjectsCfa />
                        </>
                    }
                />
                <Route
                    path='settings'
                    element={
                        <>
                            <PageTitle breadcrumbs={profilecfaBreadCrumbs}>Réglages</PageTitle>
                            <CfaAccountSettings />
                        </>
                    }
                />
                <Route index element={currentUser?.role === 'cfa' ?<Navigate to='/cfa/overview' />:<Navigate to='/logout' />} />
            </Route>
        </Routes>

    </DisableSidebar>
  )
}

export {CfaSpace}
