/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../../_rebusiness/helpers'
import React, {useState} from "react";
import {promptChatGPT} from "../../../core/_requests";
import {useAuth} from "../../auth";
export type StepProps = {
  formik:any
  updateData: (fieldsToUpdate: any) => void
}


const Step3 = ({formik, updateData}: StepProps) => {
  const [selectedProjectImages, setSelectedProjectImages] = useState<File | null>(null);
  const { currentUser, logout, } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [prompt, setPrompt] = useState('');
  const handleGenerateDescription = async () => {
    setLoading(true);
    try {
      const messages = [
        {
          "role": "system",
          "content": "L'utilisateur va fournir un texte UserDescription.\n" +
              "Rédiger la description de ce projet de financement pour un CFA , cette description doit inclure une liste de produits a financer si aucune liste de materiels n'est incluse dans UserDescription en generer une de 10 materiels ou plus sans dire \"voici une liste de 10 ..\".\n" +
              "Tu dois parler comme si tu était le CFA en question.\n" +
              "Si un site internet est disponible dans UserDescription naviguer sur le site pour connaitre mieux le  CFA et rédiger une description succinte du CFA en question. "+
              " userName contient le nom du CFA . userWebsite contient le site du CFA . userTitle contient le titre du projet de financement. userEquipment contient le matériel a financé. userDiploma contient le diplome d'étude concerné par ce projet de financement. Essayez de les intégrer dans la description"
        },
        {
          "role": "user",
          "content": 'userName= ' + currentUser?.companyName + 'userWebsite = ' + currentUser?.companyWebsite + 'userTitle = '+ formik.values.projectName +'userEquipment =' + formik.values.projectProducts + 'userDiploma =' + formik.values.projectDiploma + 'UserDescription = ' +prompt
        }
      ]
      const response = await promptChatGPT(messages, logout);
      if (response.message == 'Success') {
        formik.setFieldValue('projectDescription', response.AI);
      } else {
        console.error('Invalid response structure:', response);
      }
    } catch (error) {
      console.error('Error generating description:', error);
    }
    setLoading(false);
  };
  const helpIA = async (subject:any)=>{

    if (subject == 'title'){
      setLoading1(true)
      const messages = [
        {
          "role": "system",
          "content": "L'utilisateur  qui est un CFA  doit creer un titre pour son projet de financement, pour cela il peut si il a une idée fournir une idée appelé userTitle. userName contient le nom du CFA . si userTitle est vide alors générer un titre pour un projet de financement pour un cfa. si userTitle n'est pas vide alors améliorer le texte de maniere a ce que le titre soit accrocheur et vendeur. Ecrire le titre directement"
        },
        {
          "role": "user",
          "content": 'userName= ' + currentUser?.companyName + 'userTitle = '+ formik.values.projectName
        }
      ]
      const response = await promptChatGPT(messages,logout)
      formik.setFieldValue('projectName', response.AI.replace(/"/g, ''));
      setLoading1(false)
    }
  }



  return (
      <div data-stepper-element='content'>
        <div className='w-100' style={{minHeight: '400px'}}>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Laisser notre IA vous assister</span>
              <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Décrivez votre projet en quelques lignes, équipements nécessaires, diplome préparé'
              ></i>
            </label>
            <textarea style={{minHeight: '100px'}}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Parlez nous de votre projet en quelques lignes, équipements nécessaires, diplome préparé etc. notre IA se chargera de rédiger le reste.'
                      onChange={(e) => setPrompt(e.target.value)}
            />
            <button className="main-btn-back main-btn-2 " style={{width: '100%'}} onClick={handleGenerateDescription}
                    disabled={loading}>
              <i
                  style={{color: 'white', marginRight: '10px'}}
                  className='fas  fa-robot ms-2 fs-7 ml-2'
              ></i> {loading ? 'Génération en cours...' : 'Assistance AI'}
            </button>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Description du projet</span>
              <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Décrivez votre projet'
              ></i>
            </label>
            <textarea style={{height: 'auto', minHeight: '100px'}}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Description du projet'
                      {...formik.getFieldProps('projectDescription')}
            />
            {formik.touched.projectDescription && formik.errors.projectDescription && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.projectDescription}</div>
                </div>
            )}
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Image de couverture</span>
              {/* <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify your unique app name'
            ></i>*/}

            </label>
            {selectedProjectImages ? <div
                    className='image-input-wrapper symbol-2by3  h-200px'
                    style={{
                      backgroundSize: '100% auto',
                      backgroundImage: `url(${
                          URL.createObjectURL(selectedProjectImages)
                      })`,
                    }}
                ></div> :
                null}
            <label style={{width: '100%'}} htmlFor='avatar' className='btn btn-primary align-self-center'>
              Importer
              <input
                  id='avatar'
                  type='file'
                  name='image de couverture'
                  accept='.png, .jpg, .jpeg'
                  style={{display: 'none'}}
                  onChange={(event) => {
                    const file = event.target.files?.[0] || null;
                    setSelectedProjectImages(file)
                    updateData(file);
                  }}
              />
            </label>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex justify-content-between fs-5 fw-semibold mb-2'>
            <span className='required'>Nom du projet  <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Nom du projet (assisté par IA)'
            ></i></span>

              <button disabled={loading1} onClick={() => helpIA('title')} className='p-2 ml-20 helpAI'>
                <i
                    style={{color: 'white', marginRight: '10px'}}
                    className='fas  fa-robot ms-2 fs-7 ml-2'
                ></i>
                {loading1 ? 'en cours ...' : 'Assistance IA'}</button>
            </label>
            <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Nom du projet'
                {...formik.getFieldProps('projectName')}
            />
            {formik.touched.projectName && formik.errors.projectName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.projectName}</div>
                </div>
            )}
          </div>
        </div>
      </div>
  )
}
export {Step3}
