import {DisableSidebar, PageTitle} from '../../../_rebusiness/layout/core'

import {UsersListWrapper} from "./management-list/UsersList";
import {useAuth} from "../auth";

const CfaManager = () => {
    const {currentUser}=useAuth()

    return (
        <DisableSidebar>
            <PageTitle description='Administration du site' breadcrumbs={[]}>
                Bonjour{currentUser?.contactFirstName}
            </PageTitle>
            <UsersListWrapper type='cfa'/>
        </DisableSidebar>
    )
}

export {CfaManager}
