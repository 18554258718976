import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { KTSVG, toAbsoluteUrl } from '../../../../../_rebusiness/helpers';
import {Product} from "../../../../core/_models";

// Define the type for the onModalClose prop
interface EditProjectModalProps {
    onModalClose: any;
    editProject: any;
}

const ProjectViewModal: React.FC<EditProjectModalProps> = ({ editProject, onModalClose }) => {
    console.log(editProject)
    const handleModalClose = () => {
        // Call the callback function to close the modal
        onModalClose();
    };

    return (
        <Modal size="lg" show={editProject.id !== 0} onHide={handleModalClose} backdrop={true} keyboard={true}>
            <Modal.Header closeButton>
                <Modal.Title>{editProject.projectName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body">
                    <div id="account_project_details" className="collapse show">
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">Image de couverture</label>
                            <div className="col-lg-8">
                                <div className="image-input image-input-outline" data-image-input="true">
                                    {editProject.projectImages ? (
                                        <div
                                            className="image-input-wrapper symbol-2by3 w-200px h-125px"
                                            style={{
                                                backgroundSize: '100% auto',
                                                backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL + '/' + editProject.projectImages})`,
                                            }}
                                        ></div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">Nom du projet</label>
                            <div className="col-lg-8 fv-row">
                                <p>{editProject.projectName}</p>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">Description du projet</label>
                            <div className="col-lg-8 fv-row">
                                <p>{editProject.projectDescription}</p>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">Coût du projet</label>
                            <div className="col-lg-8 fv-row">
                                <p>{editProject.projectCost}</p>
                            </div>
                        </div>
                        <div className='fv-row mb-10'>
                            <label className='required fs-5 fw-semibold mb-2'>Liste des produits</label>


                            {editProject.ProjectProducts?.length>0? editProject.ProjectProducts.map((product:any) => (

                                <div style={{margin:'10px'}} key={product.id} className='d-flex align-items-center mb-7'>
                                    {/* begin::Avatar */}
                                    {product.productId && <div className='symbol symbol-2by3 symbol-50px me-5'>
                                        <img
                                            src={toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL + '/' + product?.Product?.productImages)}
                                            className='' alt=''/>
                                    </div>}
                                    {/* end::Avatar */}
                                    {/* begin::Text */}
                                    <div className='flex-grow-1'>
                                        <a
                                            href={product.productId ? '/product/'+product?.Product?.uniqueID : '#'}
                                            target={'_blank'}
                                            className='text-dark fw-bold text-hover-primary fs-6'
                                            onClick={(e) => {
                                                if (!product.productId) {
                                                    e.preventDefault(); // Prevent navigation
                                                }
                                            }}
                                        >
                                            {product?.Product?.productName.slice(0,100) || product?.productName.slice(0,100)}
                                        </a>
                                        <span className='text-dark fw-bold  d-block fw-semibold'>Quantité: {product.quantity} | Prix total: {product.productId ? Number(product.quantity * product?.Product?.productPrice)+' €':Number(product.quantity * product?.productPrice)+' €'}</span>
                                        {product.productId && <span
                                            className='text-muted d-block fw-semibold'>{product?.Product?.productDescription?.slice(0, 100) + '[...]'}</span>}
                                    </div>
                                    {/* end::Text */}
                                </div>
                            )):null}
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">Date limite</label>
                            <div className="col-lg-8 fv-row">
                                <p>{new Date(editProject.deadlineDate).toISOString().split('T')[0]}</p>
                            </div>
                        </div>
                        <div className="mb-10">
                            <div className="form-check form-switch form-check-custom form-check-solid">
                                <label className="col-lg-4 col-form-label required fw-bold fs-6">Accès restreint</label>
                                <input className="form-check-input" type="checkbox" id="isPrivate" checked={editProject.isPrivate} disabled />
                                <label className="form-check-label" htmlFor="isPrivate">
                                    Projet privé
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleModalClose}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export { ProjectViewModal };
