/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect,ReactNode} from 'react'
import {toAbsoluteUrl} from '../../../_rebusiness/helpers'
import {useLocation} from "react-router";
interface AuthLayoutProps {
  children: ReactNode; // Define the type for children as ReactNode
}
const AuthLayout = ({ children }: AuthLayoutProps) => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])
  const location = useLocation()
  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    /*  style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}*/
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='/' className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/assets/images/logo-dark.svg')}
            className='theme-dark-show h-45px'
          />
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/assets/images/logo.svg')}
            className='theme-light-show h-45px'
          ></img>
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className={`bg-body rounded shadow-sm p-10 p-lg-15 mx-auto ${location.pathname === '/inscription' ? '' : 'w-lg-500px'}`}>
          {children}
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-semibold fs-6'>

          <a href='/faq' className='text-muted text-hover-primary px-2'>
            FAQ
          </a>

          <a href='/mentions_legales.pdf' className='text-muted text-hover-primary px-2'>
            Mentions Légales
          </a>
          <a href='/contact' className='text-muted text-hover-primary px-2'>
            Contactez nous
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}



export {AuthLayout}
