import React, { useState } from 'react';
import {KTSVG, toAbsoluteUrl} from '../../../_rebusiness/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAuth} from '../../pages/auth';
import {createProduct, editProfile, getUserByToken, promptChatGPT} from "../../core/_requests";
import { getBase64 } from '../../core/getBase64';
import { Modal, Button } from 'react-bootstrap'; // Import the necessary components from react-bootstrap

const productDetailsSchema = Yup.object().shape({
    projectName: Yup.string().required('Le nom du produit est requis'),
    projectImages: Yup.array().required('Les images du produit sont requises'),
    productDescription: Yup.string().required('La description du produit est requise'),
    productCategory: Yup.string().required('La catégorie du produit est requise'),
    productPrice: Yup.number().required('Le prix du produit est requis'),
    productStock: Yup.number().required('Le stock du produit est requis'),
});
// Define the type for the onModalClose prop
interface NewProductProps {
    onModalClose: () => Promise<void>;
}


const NewProduct: React.FC<NewProductProps> = ({onModalClose}) => {
    const {  logout, setCurrentUser } = useAuth();
    const [selectedProductImages, setSelectedProductImages] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false); // State to manage modal visibility
    const handleModalShow = () => setShowModal(true);
    const handleModalClose =  () => {
        onModalClose();
        setShowModal(false)
    };
    const handleSubmit = async () => {
        setLoading(true);
        try {
// Prepare the data
            const data = {
                productName: formik.values.productName,
                productDescription: formik.values.productDescription,
                productPrice: formik.values.productPrice,
                productStock: formik.values.productStock,
                productCategory: formik.values.productCategory,
                productImages: null as string | null, // Specify the type as string | null and initialize with null
            };
            if (selectedProductImages) {
                const base64Image = await getBase64(selectedProductImages);
                data.productImages = base64Image;
            }

            // Send the data to the server for processing
            // Replace `editProfile` with the appropriate function to save project details
            const response = await createProduct(data, logout);
            if(response.message === 'Success'){
                setShowModal(false)
                setSelectedProductImages(null)
                formik.resetForm({
                    values: formik.initialValues,
                });
                //onModalClose();
                console.log('success')
                setLoading(false);
            }

        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };
    const formik = useFormik({
        initialValues: {
            productName: '',
            productImages: [],
            productDescription: '',
            productPrice: 0,
            productStock: 0,
            productCategory: '',
        },
        validationSchema: productDetailsSchema,
        onSubmit: async (values) => {
            console.log('try')
            setLoading(true);

        },
    });

    return (
        <div className='card mb-5 mb-xl-10'>
            <button
                type="button"
                className="btn btn-primary"
                onClick={handleModalShow} // Show the modal when the button is clicked
            >
                Créer un nouveau produit
            </button>
                <Modal
                    show={showModal} onHide={handleModalClose} backdrop={true} keyboard={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>Créer un nouveau produit</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <Modal.Body >
                                <div className="modal-body">
                                    <div id='account_project_details' className='collapse show'>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Image du produit</label>
                                            <div className='col-lg-8'>
                                                <div className='image-input image-input-outline' data-image-input='true'>
                                                    {selectedProductImages ?<div
                                                            className='image-input-wrapper symbol-2by3 w-125px h-125px'
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    URL.createObjectURL(selectedProductImages)
                                                                })`,
                                                            }}
                                                        ></div>:
                                                        null}

                                                    <label htmlFor='avatar' className='btn btn-primary align-self-center'>
                                                        Importer
                                                        <input
                                                            id='avatar'
                                                            type='file'
                                                            name='image du produit'
                                                            accept='.png, .jpg, .jpeg'
                                                            style={{ display: 'none' }}
                                                            onChange={(event) => {
                                                                const file = event.target.files?.[0] || null;
                                                                setSelectedProductImages(file);
                                                            }}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nom du produit</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Nom du produit'
                                                    {...formik.getFieldProps('productName')}
                                                />
                                                {formik.touched.productName && formik.errors.productName && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.productName}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Catégorie du produit</label>
                                            <div className='col-lg-8 fv-row'>
                                                <select
                                                    className='form-control form-control-lg form-control-solid'
                                                    {...formik.getFieldProps('productCategory')}
                                                >
                                                    <option value="">Sélectionner une catégorie</option>
                                                    <option value="Agriculture et Pêche, Espaces naturels et Espaces verts, Soins aux animaux">Agriculture et Pêche, Espaces naturels et Espaces verts, Soins aux animaux</option>
                                                    <option value="Arts et Façonnage d'ouvrages d'art">Arts et Façonnage d'ouvrages d'art</option>
                                                    <option value="Banque, Assurance, Immobilier">Banque, Assurance, Immobilier</option>
                                                    <option value="Commerce, Vente et Grande distribution">Commerce, Vente et Grande distribution</option>
                                                    <option value="Communication, Média et Multimédia">Communication, Média et Multimédia</option>
                                                    <option value="Construction, Bâtiment et Travaux publics">Construction, Bâtiment et Travaux publics</option>
                                                    <option value="Hôtellerie-Restauration, Tourisme, Loisirs et Animation">Hôtellerie-Restauration, Tourisme, Loisirs et Animation</option>
                                                    <option value="Industrie">Industrie</option>
                                                    <option value="Installation et Maintenance">Installation et Maintenance</option>
                                                    <option value="Santé">Santé</option>
                                                    <option value="Services à la personne et à la collectivité">Services à la personne et à la collectivité</option>
                                                    <option value="Spectacle">Spectacle</option>
                                                    <option value="Support à l'entreprise">Support à l'entreprise</option>
                                                    <option value="Transport et Logistique">Transport et Logistique</option>
                                                </select>
                                                {formik.touched.productCategory && formik.errors.productCategory && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.productCategory}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Description du produit</label>
                                            <div className='col-lg-8 fv-row'>
                                                {/* Replace the textarea with an HTML text editor field */}
                                                <textarea
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Description du produit'
                                                    {...formik.getFieldProps('productDescription')}
                                                />
                                                {formik.touched.productDescription && formik.errors.productDescription && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.productDescription}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Prix (TTC)</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='number'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Prix (TTC)'
                                                    {...formik.getFieldProps('productPrice')}
                                                />
                                                {formik.touched.productPrice && formik.errors.productPrice && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.productPrice}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Stock</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='number'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Stock'
                                                    {...formik.getFieldProps('productStock')}
                                                />
                                                {formik.touched.productStock && formik.errors.productStock && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.productStock}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>


                                    </div>
                                </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={handleModalClose}>
                                Fermer
                            </Button>
                            <Button variant="light" onClick={handleSubmit} disabled={loading}>
                                {!loading ? 'Créer' : 'Patientez...'}
                                {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
            );
            };

export { NewProduct };

