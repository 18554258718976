import React, {useEffect,useState} from "react";
import {IconUserModel} from "../../modules/profile/ProfileModels";
import {Link} from "react-router-dom";
import {NewProject} from "./NewProject";
import {getProjectsByUserId} from "../../core/_requests";
import {useAuth} from "../../pages/auth";

import {ProjectCard} from "../../modules/cfa/ProjectCard";
import {toAbsoluteUrl} from "../../../_rebusiness/helpers";
import {EditProject} from "./EditProject";
import {NewProjectOLD} from "./NewProjectOLD";


const users6 = [
    {name: 'Emma Smith', avatar: '/media/avatars/300-6.jpg'},
    {name: 'Rudy Stone', avatar: '/media/avatars/300-1.jpg'},
    {name: 'Susan Redwood', initials: 'S', color: 'primary'},
]
interface Project {
    id: number;
    projectName: string;
    projectDescription: string;
    projectEquipment: string;
    projectDiploma: string;
    deadlineDate: string;
    projectCost: number;
    status:string;
    uniqueID:string;
    projectImages:string;
    isPrivate:boolean;
}


export  function ProjectsCfa  () {
    const { currentUser,logout,setCurrentUser } = useAuth();
    const [Projects, setProjects] = useState<Project[]>([]); // Specify the type of the projects state
    const [showModalCreateProject, setShowModalCreateProject] = useState(false); // State to manage modal visibility
    const [EditProjects, setEditProjects] = useState<Project>({

        id: 0,
        projectName: '',
        projectImages: '',
        projectDescription: '',
        projectEquipment: '',
        projectDiploma: '',
        projectCost: 0,
        status:'',
        deadlineDate: '',
        uniqueID: '',
        isPrivate: false,
    }); // Specify the type of the projects state
    const handleEditClick = (project: Project) => {
        console.log(project)
        setEditProjects(project);
    };
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const projectsData = await getProjectsByUserId(logout);
                setProjects(projectsData);
            } catch (error) {
                console.error(error);
                // Handle error if necessary
            }
        };
        fetchProjects();
    }, [logout]);
    // Callback function to refetch projects when the modal closes
    const handleModalClose = async () => {
        try {
            setEditProjects({
                id: 0,
                projectName: '',
                projectImages: '',
                projectDescription: '',
                projectEquipment: '',
                projectDiploma: '',
                projectCost: 0,
                status:'',
                deadlineDate: '',
                uniqueID: '',
                isPrivate: false,
            })

            const projectsData = await getProjectsByUserId(logout);
            setProjects(projectsData);

        } catch (error) {
            console.error(error);
            // Handle error if necessary
        }
        setShowModalCreateProject(false)
    };
    const handleModalShow = () => setShowModalCreateProject(true);

    return (
        <>
            <div className='card mb-5 mb-xl-10'>
            <button
                type="button"
                className="main-btn-small"
                onClick={handleModalShow} // Show the modal when the button is clicked
            >
                Déposer un projet
            </button>
            </div>
            <NewProject show={showModalCreateProject} handleClose={handleModalClose}/>
            {EditProjects.id !=0 && <EditProject onModalClose={handleModalClose} editProject={EditProjects} />}
            <div className='row g-6 g-xl-9'>
                {Projects.length>0 && Projects.map((project) => (
                    <div key={project.id} className='col-md-6 col-xl-4'>
                        <ProjectCard
                            onClick={() => project.status !=='rejected'? handleEditClick(project):null}
                            id={project.id}
                            icon={process.env.REACT_APP_PUBLIC_URL+'/'+project.projectImages}
                            badgeColor={project.status ==='pending'?'info'
                                :project.status ==='online'?'success'
                                    :project.status ==='rejected'?'danger':''}
                            status={project.status ==='pending'?'en attente'
                                :project.status ==='online'?'en ligne'
                                    :project.status ==='rejected'?'refusé':''}
                            statusColor='primary' //primary = bleu /info = violet/success= vert/danger=rouge
                            title={project.projectName} // Use `project.projectName` here to get the project name
                            description={project.projectDescription.slice(0,300)}
                            date={project.deadlineDate}
                            budget={`${project.projectCost}€`}
                            link={`/project/${project.uniqueID}`}
                            progress={2} // Replace with the actual progress value from the project data
                            users={users6} // Replace with the actual users array from the project data
                        />
                    </div>

                ))}
            </div>

            {/*<div className='d-flex flex-stack flex-wrap pt-10'>
                <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of 50 entries</div>

                <ul className='pagination'>
                    <li className='page-item previous'>
                        <a href='#' className='page-link'>
                            <i className='previous'></i>
                        </a>
                    </li>

                    <li className='page-item active'>
                        <a href='#' className='page-link'>
                            1
                        </a>
                    </li>

                    <li className='page-item'>
                        <a href='#' className='page-link'>
                            2
                        </a>
                    </li>

                    <li className='page-item'>
                        <a href='#' className='page-link'>
                            3
                        </a>
                    </li>

                    <li className='page-item'>
                        <a href='#' className='page-link'>
                            4
                        </a>
                    </li>

                    <li className='page-item'>
                        <a href='#' className='page-link'>
                            5
                        </a>
                    </li>

                    <li className='page-item'>
                        <a href='#' className='page-link'>
                            6
                        </a>
                    </li>

                    <li className='page-item next'>
                        <a href='#' className='page-link'>
                            <i className='next'></i>
                        </a>
                    </li>
                </ul>
            </div>*/}
        </>
    )
}