import { projectDetailsSidebar } from "../../../data/projectsArea";
import React, {useEffect} from "react";
import { Image } from "react-bootstrap";
import {toAbsoluteUrl} from "../../../../app/core/AssetHelpers";



const ProjectDetailsEquipment = ({ product = {}, }) => {
  const {Product,productId,quantity,productName,productPrice,productSupplier} = product;

  return (
    <div>
      {Product?.productImages && <Image src={toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL+'/'+Product?.productImages)} alt="" />}
      <span style={{fontWeight:'bold'}}>{quantity} {Product?.productName || productName}</span>
      <p style={{color:'#aaa'}}>
        Prix unitaire : {Product?.productPrice || productPrice} €
        <br/>
          Prix total : {productId?Number(quantity * Product?.productPrice)+' €':Number(quantity * productPrice)+' €'}
      </p>
    </div>
  );
};

const ProjectDetailsSidebar = ({project,fetchData}) => {
    useEffect(() => {
        const handleModalClose = () => {
            fetchData() // Fetch data again when the modal is closed
        };
        const modalElement = document.getElementById('modal_funding_project');
        modalElement.addEventListener('hidden.bs.modal', handleModalClose);
        // Cleanup event listener on component unmount
        return () => {
            modalElement.removeEventListener('hidden.bs.modal', handleModalClose);
        };
    }, []);
  // Convert the target date to a JavaScript Date object
  const targetDate = new Date(project.deadlineDate);
// Get the current date
  const currentDate = new Date();
// Calculate the difference in milliseconds between the target date and the current date
  const timeDifference = targetDate.getTime() - currentDate.getTime();
// Convert the time difference to days
  const daysLeft = Math.max(Math.ceil(timeDifference / (1000 * 3600 * 24)),0);
  return (
    <div className="project-details-sidebar">
      {/*<div className="project-details-info mt-70 box">
        <div className="info">
          <Image src={info.image.src} alt="" />
          <h5 className="title">{info.name}</h5>
          <span>{info.backed} backed</span>
        </div>
        <p>{info.text}</p>
      </div>*/}
      <div
          className={`project-details-park mt-30 box item-2`}
      >
        <h4 className="title">Besoins en matériels</h4>
      {project?.ProjectProducts?.map((product) => (
        <ProjectDetailsEquipment product={product}  key={product.id} />
      ))}
        <a className="main-btn" data-bs-toggle='modal'
           data-bs-target={'#modal_funding_project'}
           disabled={daysLeft === 0}>
          Soutenir
        </a>
      </div>
    </div>
  );
};

export default ProjectDetailsSidebar;
