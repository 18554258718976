import {Link} from "react-router-dom";
import {KTIcon} from "../../../_rebusiness/helpers";
import {ChartsWidget1, ListsWidget5, TablesWidget1, TablesWidget5} from "../../../_rebusiness/partials/widgets";
import React from "react";
import {useAuth} from "../../pages/auth";

export function OverviewAccount() {
    const {currentUser, logout} = useAuth()
    return (
        <>
            <div className='card mb-5 mb-xl-10' id='profile_details_view'>

                <div className='card-header cursor-pointer'>
                        <h3 className='card-title fw-bold text-dark'>Détails  {currentUser?.role=='cfa'?'du CFA':"de l'entreprise"}</h3>


                    <Link to={`/${currentUser?.role}/settings`} className='main-btn-3 main-btn-small align-self-center'>
                   Modifier
                    </Link>
                </div>
                {!currentUser?.avatar &&<div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 m-10'>
                    <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />
                    <div className='d-flex flex-stack flex-grow-1' >
                        <div className='fw-bold'>
                            <h4 className='text-gray-800 fw-bolder'>Compléter votre profil</h4>
                            <div className='fs-6 text-gray-600'>


                                {!currentUser?.avatar &&<>  1) <Link className='fw-bolder' to={`/${currentUser?.role}/settings`}>
                                    Ajoutez votre logo.
                                </Link></>}<br/>
                                {(currentUser?.role=='entreprise'&& !currentUser?.companyTax)&&<>  2) <Link className='fw-bolder' to={`/${currentUser?.role}/settings`}>
                                    Caluculer votre taxe d'apprentissage
                                </Link></>}
                            </div>
                        </div>
                    </div>
                </div>}
                <div className='card-body p-9'>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>CFA</label>

                        <div className='col-lg-8 fv-row'>
                            <span className='fw-bold fs-6'>{currentUser?.companyName}</span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>
                            Adresse du cfa
                            {/*<i
                                className='fas fa-exclamation-circle ms-1 fs-7'
                                data-bs-toggle='tooltip'
                                title='Country of origination'
                            ></i>*/}
                        </label>

                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>{currentUser?.companyAddress}</span>
                        </div>
                    </div>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>
                            Code Postal
                          {/*  <i
                                className='fas fa-exclamation-circle ms-1 fs-7'
                                data-bs-toggle='tooltip'
                                title='Country of origination'
                            ></i>*/}
                        </label>

                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>{currentUser?.companyZipCode}</span>
                        </div>
                    </div>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>
                            Ville
                            {/*<i
                                className='fas fa-exclamation-circle ms-1 fs-7'
                                data-bs-toggle='tooltip'
                                title='Country of origination'
                            ></i>*/}
                        </label>

                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>{currentUser?.companyCity}</span>
                        </div>
                    </div>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>
                            Téléphone
                           {/* <i
                                className='fas fa-exclamation-circle ms-1 fs-7'
                                data-bs-toggle='tooltip'
                                title='= 10 chiffres'
                            ></i>*/}
                        </label>

                        <div className='col-lg-8 d-flex align-items-center'>
                            <span className='fw-bolder fs-6 me-2'>{currentUser?.companyPhoneNumber}</span>

                            {/*<span className='badge badge-success'>Verifié</span>*/}
                        </div>
                    </div>



                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Email</label>

                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>{currentUser?.email}</span>
                        </div>
                    </div>
                    {currentUser?.role=="cfa"&&<div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>UAI</label>

                        <div className='col-lg-8'>
                            <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                                {currentUser?.uai}
                            </a>
                        </div>
                    </div>}
                    {currentUser?.role=="cfa"&&<div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Site internet</label>

                        <div className='col-lg-8'>
                            <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                                {currentUser?.companyWebsite}
                            </a>
                        </div>
                    </div>}
                    {currentUser?.role=="entreprise"?<div className='row mb-10'>
                        <label className='col-lg-4 fw-bold text-muted'>siret</label>

                        <div className='col-lg-8'>
                            <span className='fw-bold fs-6'>{currentUser?.siret}</span>
                        </div>
                    </div>:null}
                        {currentUser?.role=="entreprise"?<div className='row mb-10'>
                        <label className='col-lg-4 fw-bold text-muted'>Siren</label>

                        <div className='col-lg-8'>
                            <span className='fw-bold fs-6'>{currentUser?.siren}</span>
                        </div>
                    </div>:null}
                </div>
                    <div className='card-header cursor-pointer'>
                        <div className='card-title m-0'>
                            <h3 className='fw-bolder m-0'>Détails du Contact</h3>
                        </div>
                    </div>
                    <div className='card-body p-9'>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Nom complet</label>

                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>{currentUser?.contactLastName} {currentUser?.contactFirstName}</span>
                        </div>
                    </div>
                    <div className='row mb-10'>
                        <label className='col-lg-4 fw-bold text-muted'>Position</label>

                        <div className='col-lg-8'>
                            <span className='fw-bold fs-6'>{currentUser?.contactPosition}</span>
                        </div>
                    </div>
                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>
                                Téléphone
                                {/*<i
                                    className='fas fa-exclamation-circle ms-1 fs-7'
                                    data-bs-toggle='tooltip'
                                    title=' = 10 chiffres'
                                ></i>*/}
                            </label>

                            <div className='col-lg-8 d-flex align-items-center'>
                                <span className='fw-bolder fs-6 me-2'>{currentUser?.contactPhoneNumber}</span>

                               {/* <span className='badge badge-success'>Verifié</span>*/}
                            </div>
                        </div>
                </div>
            </div>
        </>
    )}