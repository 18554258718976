export interface AuthModel {
  api_token: string
  refreshToken?: string
}

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface UserSocialNetworksModel {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}
// Statistics for CFA
export interface Statistics {
  totalProjects: number;
  totalMoneyReceived: number;
  progressPercentage: number;
  totalContributions: number;
  totalProjectsHelped: number;
  totalAmountSpent: number;
  profileProgress:number;
  totalProducts:number;
}
export interface ProductLight {

  productName: string;
  productPrice: number;
  uniqueID: string;
  quantity: number;
  productImages: string;
  productDescription: string;
}
export interface Product {
  id: number;
  productName: string;
  productDescription: string;
  productCategory: string;
  productPrice: number;
  productStock: number;
  productImages: string;
  uniqueID: string;
  userId: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  visits: number;
  quantity: number;
}
export interface Contribution {
  id: number;
  UserId: number;
  ProjectId: number;
  reference: string;
  quote: string;
  amount: number;
  status: 'success' | 'pending' | 'rejected' | 'refunded';
}
export interface Product {
  id: number;
  UserId: number;
  productName: string;
  productImages: string;
  productDescription: string;
  productCategory: string;
  productPrice: number;
  productStock: number;
}
export interface Project {
  id: number;
  projectName: string;
  projectDescription: string;
  deadlineDate: string;
  projectCost: number;
  status:string;
  projectImages:string;
  uniqueID:string;
}
// Statistics for Entreprise

export interface UserModel {
  id: number
  companyName: string
  siret: string
  siren: string
  companyAddress: string
  companyPhoneNumber: string
  companyZipCode:string
  companyCity:string

  companyWebsite:string
  email: string
  contactFirstName: string
  contactLastName: string
  contactPhoneNumber: string
  contactPosition: string
  companyTax : string
  uai: string | null
  role: string
  auth?: AuthModel
  lastConnection: string | null
  avatar: string | null
  stats?: Statistics|null
}



export interface UserModelOLD {
  id: number
  username: string
  password: string | undefined
  email: string
  first_name: string
  last_name: string
  fullname?: string
  occupation?: string
  companyName?: string
  phone?: string
  roles?: Array<number>
  pic?: string
  language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
  timeZone?: string
  website?: 'https://keenthemes.com'
  emailSettings?: UserEmailSettingsModel
  auth?: AuthModel
  communication?: UserCommunicationModel
  address?: UserAddressModel
  socialNetworks?: UserSocialNetworksModel
}

export type OptionType = {
  value: string;
  label: string;
}
export const cfaDiploma: OptionType[] = [
  { value: "AGENT DE CONTROLE NON DESTRUCTIF (MC NIVEAU IV)", label: "AGENT DE CONTROLE NON DESTRUCTIF (MC NIVEAU IV)" },
  { value: "EMPLOYE TRAITEUR (MC NIVEAU V)", label: "EMPLOYE TRAITEUR (MC NIVEAU V)" },
  { value: "CUISINIER EN DESSERTS DE RESTAURANT (MC NIVEAU V)", label: "CUISINIER EN DESSERTS DE RESTAURANT (MC NIVEAU V)" },
  { value: "VENDEUR SPECIALISE EN ALIMENTATION (MC NIVEAU V)", label: "VENDEUR SPECIALISE EN ALIMENTATION (MC NIVEAU V)" },
  { value: "BOULANGERIE SPECIALISEE (MC NIVEAU V)", label: "BOULANGERIE SPECIALISEE (MC NIVEAU V)" },
  { value: "PATISSERIE BOULANGERE (MC5)", label: "PATISSERIE BOULANGERE (MC5)" },
  { value: "ART DE LA CUISINE ALLEGEE (MC NIVEAU V)", label: "ART DE LA CUISINE ALLEGEE (MC NIVEAU V)" },
  { value: "JOAILLERIE (MC NIVEAU V)", label: "JOAILLERIE (MC NIVEAU V)" },
  { value: "CONDUCTEUR DE MACHINES DE VERRERIE (MC NIVEAU V)", label: "CONDUCTEUR DE MACHINES DE VERRERIE (MC NIVEAU V)" },
  { value: "MAINTENANCE EN EQUIPEMENT THERMIQUE INDIVIDUEL (MC NIVEAU V)", label: "MAINTENANCE EN EQUIPEMENT THERMIQUE INDIVIDUEL (MC NIVEAU V)" },
  { value: "TECHNICIEN(NE) DES SERVICES A L'ENERGIE (NIVEAU IV)", label: "TECHNICIEN(NE) DES SERVICES A L'ENERGIE (NIVEAU IV)" },
  { value: "TECHNICIEN EN ENERGIES RENOUVELABLES OPTION A ENERGIE ELECTRIQUE (MC NIVEAU IV)", label: "TECHNICIEN EN ENERGIES RENOUVELABLES OPTION A ENERGIE ELECTRIQUE (MC NIVEAU IV)" },
  { value: "TECHNICIEN EN ENERGIES RENOUVELABLES OPTION B ENERGIE THERMIQUE (MC NIVEAU IV)", label: "TECHNICIEN EN ENERGIES RENOUVELABLES OPTION B ENERGIE THERMIQUE (MC NIVEAU IV)" },
  { value: "ZINGUERIE (MC NIVEAU V)", label: "ZINGUERIE (MC NIVEAU V)" },
  { value: "PLAQUISTE (MC NIVEAU V)", label: "PLAQUISTE (MC NIVEAU V)" },
  { value: "PEINTURE DECORATION (MC NIVEAU IV)", label: "PEINTURE DECORATION (MC NIVEAU IV)" },
  { value: "VENDEUR-CONSEIL EN PRODUITS TECHNIQUES POUR L'HABITAT (MC NIVEAU IV)", label: "VENDEUR-CONSEIL EN PRODUITS TECHNIQUES POUR L'HABITAT (MC NIVEAU IV)" },
  { value: "TECHNICIEN(NE) ASCENSORISTE (SERVICE ET MODERNISATION) (MC NIVEAU IV)", label: "TECHNICIEN(NE) ASCENSORISTE (SERVICE ET MODERNISATION) (MC NIVEAU IV)" },
  { value: "MECATRONIQUE NAVALE (MC NIVEAU IV)", label: "MECATRONIQUE NAVALE (MC NIVEAU IV)" },
  { value: "REALISATION DE CIRCUITS OLEOHYDRAULIQUES ET PNEUMATIQUES (MC NIVEAU V)", label: "REALISATION DE CIRCUITS OLEOHYDRAULIQUES ET PNEUMATIQUES (MC NIVEAU V)" },
  { value: "MAQUETTES ET PROTOTYPES (MC NIVEAU IV)", label: "MAQUETTES ET PROTOTYPES (MC NIVEAU IV)" },
  { value: "MAINTENANCE DES INSTALLATIONS OLEOHYDRAULIQUES ET PNEUMATIQUES (MC NIVEAU IV)", label: "MAINTENANCE DES INSTALLATIONS OLEOHYDRAULIQUES ET PNEUMATIQUES (MC NIVEAU IV)" },
  { value: "MAINTENANCE DES MOTEURS DIESEL ET DE LEURS EQUIPEMENTS (MC NIVEAU V)", label: "MAINTENANCE DES MOTEURS DIESEL ET DE LEURS EQUIPEMENTS (MC NIVEAU V)" },
  { value: "AERONAUTIQUE OPTION AVIONS A MOTEURS A TURBINES (MC NIVEAU IV)", label: "AERONAUTIQUE OPTION AVIONS A MOTEURS A TURBINES (MC NIVEAU IV)" },
  { value: "AERONAUTIQUE OPTION AVIONS A MOTEURS A PISTONS (MC NIVEAU IV)", label: "AERONAUTIQUE OPTION AVIONS A MOTEURS A PISTONS (MC NIVEAU IV)" },
  { value: "AERONAUTIQUE OPTION HELICOPTERES A MOTEURS A TURBINES (MC NIVEAU IV)", label: "AERONAUTIQUE OPTION HELICOPTERES A MOTEURS A TURBINES (MC NIVEAU IV)" },
  { value: "AERONAUTIQUE OPTION HELICOPTERES A MOTEURS A PISTONS (MC NIVEAU IV)", label: "AERONAUTIQUE OPTION HELICOPTERES A MOTEURS A PISTONS (MC NIVEAU IV)" },
  { value: "AERONAUTIQUE OPTION AVIONIQUE (MC NIVEAU IV)", label: "AERONAUTIQUE OPTION AVIONIQUE (MC NIVEAU IV)" },
  { value: "TECHNICIEN(NE) EN SOUDAGE (MC NIVEAU IV)", label: "TECHNICIEN(NE) EN SOUDAGE (MC NIVEAU IV)" },
  { value: "MAINTENANCE DES SYSTEMES EMBARQUES DE l'AUTOMOBILE (MC NIVEAU V)", label: "MAINTENANCE DES SYSTEMES EMBARQUES DE l'AUTOMOBILE (MC NIVEAU V)" },
  { value: "TECHNICIEN(NE) EN RESEAUX ELECTRIQUES (MC NIVEAU IV)", label: "TECHNICIEN(NE) EN RESEAUX ELECTRIQUES (MC NIVEAU IV)" },
  { value: "ACCUEIL DANS LES TRANSPORTS (MC NIVEAU IV)", label: "ACCUEIL DANS LES TRANSPORTS (MC NIVEAU IV)" },
  { value: "AGENT TRANSPORT EXPLOITATION FERROVIAIRE (MC NIVEAU IV)", label: "AGENT TRANSPORT EXPLOITATION FERROVIAIRE (MC NIVEAU IV)" },
  { value: "ASSISTANCE, CONSEIL, VENTE A DISTANCE (MC NIVEAU IV)", label: "ASSISTANCE, CONSEIL, VENTE A DISTANCE (MC NIVEAU IV)" },
  { value: "SERVICES FINANCIERS (MC NIVEAU IV)", label: "SERVICES FINANCIERS (MC NIVEAU IV)" },
  { value: "AIDE A DOMICILE (MC NIVEAU V)", label: "AIDE A DOMICILE (MC NIVEAU V)" },
  { value: "SOMMELLERIE (MC NIVEAU V)", label: "SOMMELLERIE (MC NIVEAU V)" },
  { value: "EMPLOYE BARMAN (MC NIVEAU V)", label: "EMPLOYE BARMAN (MC NIVEAU V)" },
  { value: "ORGANISATEUR DE RECEPTION (MC NIVEAU IV)", label: "ORGANISATEUR DE RECEPTION (MC NIVEAU IV)" },
  { value: "ACCUEIL-RECEPTION (MC NIVEAU IV)", label: "ACCUEIL-RECEPTION (MC NIVEAU IV)" },
  { value: "COIFFURE COUPE COULEUR (MC NIVEAU V)", label: "COIFFURE COUPE COULEUR (MC NIVEAU V)" },
  { value: "SURETE DES ESPACES OUVERTS AU PUBLIC (MC NIVEAU V)", label: "SURETE DES ESPACES OUVERTS AU PUBLIC (MC NIVEAU V)" },
  { value: "DE DOCTEUR EN PHARMACIE", label: "DE DOCTEUR EN PHARMACIE" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER NANTES)", label: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER NANTES)" },
  { value: "SCIENCES DE L'EAU (MASTER LYON 2)", label: "SCIENCES DE L'EAU (MASTER LYON 2)" },
  { value: "SCIENCES ET TECHNOLOGIES : SCIENCES ET GENIE DES MATERIAUX (MASTER VALENCIENNES)", label: "SCIENCES ET TECHNOLOGIES : SCIENCES ET GENIE DES MATERIAUX (MASTER VALENCIENNES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES ET GENIE DES MATERIAUX (MASTER PARIS SACLAY)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES ET GENIE DES MATERIAUX (MASTER PARIS SACLAY)" },
  { value: "CHIMIE PHYSIQUE ET ANALYTIQUE (MASTER LYON 1)", label: "CHIMIE PHYSIQUE ET ANALYTIQUE (MASTER LYON 1)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER GRENOBLE ALPES)", label: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER GRENOBLE ALPES)" },
  { value: "CHIMIE (MASTER STRASBOURG)", label: "CHIMIE (MASTER STRASBOURG)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER PARIS 12)", label: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER PARIS 12)" },
  { value: "CHIMIE MOLECULAIRE (MASTER COMUE COTE D'AZUR)", label: "CHIMIE MOLECULAIRE (MASTER COMUE COTE D'AZUR)" },
  { value: "GEORESSOURCES, GEORISQUES, GEOTECHNIQUE (MASTER BESANCON)", label: "GEORESSOURCES, GEORISQUES, GEOTECHNIQUE (MASTER BESANCON)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : ACTUARIAT (MARNE LA VALLEE)", label: "SCIENCES, TECHNOLOGIES, SANTE : ACTUARIAT (MARNE LA VALLEE)" },
  { value: "MATHEMATIQUES, INFORMATIQUE, DECISION ET ORGANISATION (MIDO) : MATHEMATIQUES APPLIQUEES (MASTER DAUPHINE)", label: "MATHEMATIQUES, INFORMATIQUE, DECISION ET ORGANISATION (MIDO) : MATHEMATIQUES APPLIQUEES (MASTER DAUPHINE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : MATHEMATIQUES ET APPLICATIONS SPE MATHEMATIQUES, INFORMATIQUE ET APPLICATIONS A LA CRYPTOLOGIE (MASTER PARIS 7)", label: "SCIENCES, TECHNOLOGIES, SANTE : MATHEMATIQUES ET APPLICATIONS SPE MATHEMATIQUES, INFORMATIQUE ET APPLICATIONS A LA CRYPTOLOGIE (MASTER PARIS 7)" },
  { value: "INFORMATIQUE (MASTER AVIGNON)", label: "INFORMATIQUE (MASTER AVIGNON)" },
  { value: "ACTUARIAT (MASTER STRASBOURG)", label: "ACTUARIAT (MASTER STRASBOURG)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : ACTUARIAT (MASTER LE MANS)", label: "SCIENCES, TECHNOLOGIES, SANTE : ACTUARIAT (MASTER LE MANS)" },
  { value: "MATHEMATIQUES ET INFORMATIQUE APPLIQUEES AUX SCIENCES HUMAINES ET SOCIALES -MIASHS (MASTER MONTPELLIER 3)", label: "MATHEMATIQUES ET INFORMATIQUE APPLIQUEES AUX SCIENCES HUMAINES ET SOCIALES -MIASHS (MASTER MONTPELLIER 3)" },
  { value: "MATHEMATIQUES ET INFORMATIQUE APPLIQUEES AUX SCIENCES HUMAINES ET SOCIALES -MIASHS (MASTER ANGERS)", label: "MATHEMATIQUES ET INFORMATIQUE APPLIQUEES AUX SCIENCES HUMAINES ET SOCIALES -MIASHS (MASTER ANGERS)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : APPROCHE INTERDISCIPLINAIRE DES ENERGIES DE DEMAIN SPE INGENIERIE PHYSIQUE DES ENERGIES (MASTER PARIS 7)", label: "SCIENCES, TECHNOLOGIES, SANTE : APPROCHE INTERDISCIPLINAIRE DES ENERGIES DE DEMAIN SPE INGENIERIE PHYSIQUE DES ENERGIES (MASTER PARIS 7)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : PHYSIQUE FONDAMENTALE ET APPLICATIONS (MASTER PARIS 6)", label: "SCIENCES, TECHNOLOGIES, SANTE : PHYSIQUE FONDAMENTALE ET APPLICATIONS (MASTER PARIS 6)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : APPROCHE INTERDISCIPLINAIRE DES ENERGIES DE DEMAIN SPE ENERGIE, ECOLOGIE , SOCIETE (MASTER PARIS 7)", label: "SCIENCES, TECHNOLOGIES, SANTE : APPROCHE INTERDISCIPLINAIRE DES ENERGIES DE DEMAIN SPE ENERGIE, ECOLOGIE , SOCIETE (MASTER PARIS 7)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : PHYSIQUE FONDAMENTALE ET APPLICATIONS (MASTER BORDEAUX)", label: "SCIENCES, TECHNOLOGIES, SANTE : PHYSIQUE FONDAMENTALE ET APPLICATIONS (MASTER BORDEAUX)" },
  { value: "PHYSIQUE (MASTER DIJON)", label: "PHYSIQUE (MASTER DIJON)" },
  { value: "PHYSIQUE APPLIQUEE ET INGENIERIE PHYSIQUE (MASTER ORLEASN)", label: "PHYSIQUE APPLIQUEE ET INGENIERIE PHYSIQUE (MASTER ORLEASN)" },
  { value: "PHYSIQUE APPLIQUEE ET INGENIERIE PHYSIQUE (MASTER LIMOGES)", label: "PHYSIQUE APPLIQUEE ET INGENIERIE PHYSIQUE (MASTER LIMOGES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE ET SCIENCES DES MATERIAUX (MASTER EVRY)", label: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE ET SCIENCES DES MATERIAUX (MASTER EVRY)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER PARIS 6)", label: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER PARIS 6)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER LYON 1)", label: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER LYON 1)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER ANGERS)", label: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER ANGERS)" },
  { value: "CHIMIE (MASTER CHAMBERY)", label: "CHIMIE (MASTER CHAMBERY)" },
  { value: "CHIMIE (MASTER POITIERS)", label: "CHIMIE (MASTER POITIERS)" },
  { value: "CHIMIE (MASTER LORRAINE)", label: "CHIMIE (MASTER LORRAINE)" },
  { value: "RISQUES ET ENVIRONNEMENT (MASTER CORSE)", label: "RISQUES ET ENVIRONNEMENT (MASTER CORSE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DE LA TERRE, DE L'ENVIRONNEMENT ET DES PLANETES SPE GENIE DE L'ENVIRONNEMENT ET INDUSTRIE (MASTER PARIS 7)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DE LA TERRE, DE L'ENVIRONNEMENT ET DES PLANETES SPE GENIE DE L'ENVIRONNEMENT ET INDUSTRIE (MASTER PARIS 7)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DE LA TERRE ET DES PLANETES, ENVIRONNEMENT (MASTER PARIS SACLAY)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DE LA TERRE ET DES PLANETES, ENVIRONNEMENT (MASTER PARIS SACLAY)" },
  { value: "BIO-INFORMATIQUE (MASTER ROUEN)", label: "BIO-INFORMATIQUE (MASTER ROUEN)" },
  { value: "SCIENCES, SANTE ET APPLICATIONS : INFECTIOLOGIE : MICROBIOLOGIE, VIROLOGIE, IMMUNOLOGIE SPE MICROBIOLOGIE APPLIQUEE ET GENIE BIOLOGIQUE (MASTER PARIS 7)", label: "SCIENCES, SANTE ET APPLICATIONS : INFECTIOLOGIE : MICROBIOLOGIE, VIROLOGIE, IMMUNOLOGIE SPE MICROBIOLOGIE APPLIQUEE ET GENIE BIOLOGIQUE (MASTER PARIS 7)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : BIOLOGIE MOLECULAIRE ET CELLULAIRE (MASTER PARIS 6)", label: "SCIENCES, TECHNOLOGIES, SANTE : BIOLOGIE MOLECULAIRE ET CELLULAIRE (MASTER PARIS 6)" },
  { value: "INGENIERIE DE LA SANTE (MASTER POITIERS)", label: "INGENIERIE DE LA SANTE (MASTER POITIERS)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : BIODIVERSITE , ECOLOGIE, EVOLUTION (MASTER MONTPELLIER)", label: "SCIENCES, TECHNOLOGIES, SANTE : BIODIVERSITE , ECOLOGIE, EVOLUTION (MASTER MONTPELLIER)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : BIOLOGIE CELLULAIRE, PHYSIOLOGIE, PATHOLOGIES SPE INGENIEUR DE PLATE-FORME EN BIOLOGIE (MASTER PARIS 7)", label: "SCIENCES, TECHNOLOGIES, SANTE : BIOLOGIE CELLULAIRE, PHYSIOLOGIE, PATHOLOGIES SPE INGENIEUR DE PLATE-FORME EN BIOLOGIE (MASTER PARIS 7)" },
  { value: "SCIENCES DU VIVANT (MASTER STRASBOURG)", label: "SCIENCES DU VIVANT (MASTER STRASBOURG)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DE L'EAU (MASTER MONTPELLIER)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DE L'EAU (MASTER MONTPELLIER)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : BIOLOGIE, AGROSCIENCES (MASTER BORDEAUX)", label: "SCIENCES, TECHNOLOGIES, SANTE : BIOLOGIE, AGROSCIENCES (MASTER BORDEAUX)" },
  { value: "SCIENCES DU VIVANT (MASTER TOURS)", label: "SCIENCES DU VIVANT (MASTER TOURS)" },
  { value: "BIODIVERSITE, ECOLOGIE ET EVOLUTION (MASTER TOURS)", label: "BIODIVERSITE, ECOLOGIE ET EVOLUTION (MASTER TOURS)" },
  { value: "BIODIVERSITE, ECOLOGIE ET EVOLUTION (MASTER POITIERS)", label: "BIODIVERSITE, ECOLOGIE ET EVOLUTION (MASTER POITIERS)" },
  { value: "DROIT : DROIT PUBLIC SPE JURISTE CONSEIL DES COLLECTIVITES TERRITORIALES (MASTER PARIS 2)", label: "DROIT : DROIT PUBLIC SPE JURISTE CONSEIL DES COLLECTIVITES TERRITORIALES (MASTER PARIS 2)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : URBANISME ET AMENAGEMENT (MASTER PERPIGNAN)", label: "SCIENCES HUMAINES ET SOCIALES : URBANISME ET AMENAGEMENT (MASTER PERPIGNAN)" },
  { value: "SCIENCES DES ORGANISATIONS (DROIT, ECONOMIE, GESTION, SCIENCES SOCIALES) : ACTIONS PUBLIQUES ET REGULATIONS SOCIALES (MASTER DAUPHINE)", label: "SCIENCES DES ORGANISATIONS (DROIT, ECONOMIE, GESTION, SCIENCES SOCIALES) : ACTIONS PUBLIQUES ET REGULATIONS SOCIALES (MASTER DAUPHINE)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : ETUDES EUROPEENNES ET RELATIONS INTERNATIONALES (MASTER PRO CERGY)", label: "SCIENCES HUMAINES ET SOCIALES : ETUDES EUROPEENNES ET RELATIONS INTERNATIONALES (MASTER PRO CERGY)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : GEOGRAPHIE, AMENAGEMENT, ENVIRONNEMENT ET DEVELOPPEMENT (MASTER PARIS 8)", label: "SCIENCES HUMAINES ET SOCIALES : GEOGRAPHIE, AMENAGEMENT, ENVIRONNEMENT ET DEVELOPPEMENT (MASTER PARIS 8)" },
  { value: "ECONOMIE DES ORGANISATIONS (MASTER GRENOBLE ALPES)", label: "ECONOMIE DES ORGANISATIONS (MASTER GRENOBLE ALPES)" },
  { value: "LETTRES, LANGUES, SCIENCES HUMAINES ET SOCIALES : METIERS DES ETUDES, DU CONSEIL ET DE L'INTERVENTION (MECI) SPE CONSULTANTS ET CHARGES D'ETUDES SOCIO-ECONOMIQUES (MASTER PARIS 7)", label: "LETTRES, LANGUES, SCIENCES HUMAINES ET SOCIALES : METIERS DES ETUDES, DU CONSEIL ET DE L'INTERVENTION (MECI) SPE CONSULTANTS ET CHARGES D'ETUDES SOCIO-ECONOMIQUES (MASTER PARIS 7)" },
  { value: "ECONOMIE-GESTION : INGENIERIE ECONOMIQUE ET STATISTIQUE SPE INGENIERIE ECONOMIQUE (MASTER PARIS 2)", label: "ECONOMIE-GESTION : INGENIERIE ECONOMIQUE ET STATISTIQUE SPE INGENIERIE ECONOMIQUE (MASTER PARIS 2)" },
  { value: "SCIENCES POLITIQUES ET SOCIALES : SCIENCES POLITIQUES SPE DEFENSE ET DYNAMIQUES INDUSTRIELLES (MASTER PARIS 2)", label: "SCIENCES POLITIQUES ET SOCIALES : SCIENCES POLITIQUES SPE DEFENSE ET DYNAMIQUES INDUSTRIELLES (MASTER PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMETRIE, STATISTIQUES (MASTER PARIS 1)", label: "DROIT, ECONOMIE, GESTION : ECONOMETRIE, STATISTIQUES (MASTER PARIS 1)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE DE L'ENVIRONNEMENT, DE L'ENERGIE ET DES TRANSPORTS (PARIS 10 PARIS SACLAY)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE DE L'ENVIRONNEMENT, DE L'ENERGIE ET DES TRANSPORTS (PARIS 10 PARIS SACLAY)" },
  { value: "DROIT, ECONOMIE, GESTION : INNOVATION, ENTREPRISE ET SOCIETE (MASTER PARIS SACLAY)", label: "DROIT, ECONOMIE, GESTION : INNOVATION, ENTREPRISE ET SOCIETE (MASTER PARIS SACLAY)" },
  { value: "ECONOMIE APPLIQUEE (MASTER PARIS 12)", label: "ECONOMIE APPLIQUEE (MASTER PARIS 12)" },
  { value: "INTELLIGENCE ECONOMIQUE (MASTER POITIERS)", label: "INTELLIGENCE ECONOMIQUE (MASTER POITIERS)" },
  { value: "DROIT, ECONOMIE, GESTION : INTELLIGENCE ECONOMIQUE (MASTER MARNE LA VALLEE)", label: "DROIT, ECONOMIE, GESTION : INTELLIGENCE ECONOMIQUE (MASTER MARNE LA VALLEE)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE INTERNATIONALE (MASTER PARIS 1)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE INTERNATIONALE (MASTER PARIS 1)" },
  { value: "ANALYSE ET POLITIQUE ECONOMIQUE (MASTER SAINT ETIENNE)", label: "ANALYSE ET POLITIQUE ECONOMIQUE (MASTER SAINT ETIENNE)" },
  { value: "ECONOMIE DES ORGANISATIONS (MASTER COMUE COTE D'AZUR)", label: "ECONOMIE DES ORGANISATIONS (MASTER COMUE COTE D'AZUR)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMETRIE, STATISTIQUES (MASTER TOULOUSE 1)", label: "DROIT, ECONOMIE, GESTION : ECONOMETRIE, STATISTIQUES (MASTER TOULOUSE 1)" },
  { value: "SCIENCES DE L'EDUCATION (MASTER STRASBOURG)", label: "SCIENCES DE L'EDUCATION (MASTER STRASBOURG)" },
  { value: "INFORMATION, COMMUNICATION (MASTER CORSE)", label: "INFORMATION, COMMUNICATION (MASTER CORSE)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : DEMOGRAPHIE (MASTER PARIS 1)", label: "SCIENCES HUMAINES ET SOCIALES : DEMOGRAPHIE (MASTER PARIS 1)" },
  { value: "SCIENCES SOCIALES (MASTER MARNE LA VALLEE)", label: "SCIENCES SOCIALES (MASTER MARNE LA VALLEE)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : SOCIOLOGIE (MASTER PARIS SACLAY)", label: "SCIENCES HUMAINES ET SOCIALES : SOCIOLOGIE (MASTER PARIS SACLAY)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : SCIENCES DE LA SOCIETE SPE CHARGE D'ETUDES EN SOCIOLOGIE APPLIQUEE : CONSOMMATION-COMMUNICATION-MEDIA (MASTER CESSA PARIS 5)", label: "SCIENCES HUMAINES ET SOCIALES : SCIENCES DE LA SOCIETE SPE CHARGE D'ETUDES EN SOCIOLOGIE APPLIQUEE : CONSOMMATION-COMMUNICATION-MEDIA (MASTER CESSA PARIS 5)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : SOCIOLOGIE (MASTER CHAMBERY)", label: "SCIENCES HUMAINES ET SOCIALES : SOCIOLOGIE (MASTER CHAMBERY)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : PSYCHOLOGIE SOCIALE, DU TRAVAIL ET DES ORGANISATIONS (MASTER MONTPELLIER 3)", label: "SCIENCES HUMAINES ET SOCIALES : PSYCHOLOGIE SOCIALE, DU TRAVAIL ET DES ORGANISATIONS (MASTER MONTPELLIER 3)" },
  { value: "PSYCHOLOGIE SOCIALE, DU TRAVAIL ET DES ORGANISATIONS (MASTER STRASBOURG)", label: "PSYCHOLOGIE SOCIALE, DU TRAVAIL ET DES ORGANISATIONS (MASTER STRASBOURG)" },
  { value: "PSYCHOLOGIE (MASTER POITIERS)", label: "PSYCHOLOGIE (MASTER POITIERS)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : DIDACTIQUE DES LANGUES SPE DIDACTIQUE DU FRANCAIS LANGUE ETRANGERE/SECONDE ET LANGUE DU MONDE (MASTER PARIS 3)", label: "SCIENCES HUMAINES ET SOCIALES : DIDACTIQUE DES LANGUES SPE DIDACTIQUE DU FRANCAIS LANGUE ETRANGERE/SECONDE ET LANGUE DU MONDE (MASTER PARIS 3)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : HISTOIRE (MASTER MARNE LA VALLEE)", label: "SCIENCES HUMAINES ET SOCIALES : HISTOIRE (MASTER MARNE LA VALLEE)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : HISTOIRE (PARIS 1)", label: "SCIENCES HUMAINES ET SOCIALES : HISTOIRE (PARIS 1)" },
  { value: "DROIT : DROIT SOCIAL SPE DROIT ET PRATIQUE DES RELATIONS DE TRAVAIL (MASTER PARIS 2)", label: "DROIT : DROIT SOCIAL SPE DROIT ET PRATIQUE DES RELATIONS DE TRAVAIL (MASTER PARIS 2)" },
  { value: "DROIT : CARRIERES JUDICIAIRES ET SCIENCES CRIMINELLES SPE DROIT ET PRATIQUE DU PROCES EN APPEL (MASTER PARIS 2)", label: "DROIT : CARRIERES JUDICIAIRES ET SCIENCES CRIMINELLES SPE DROIT ET PRATIQUE DU PROCES EN APPEL (MASTER PARIS 2)" },
  { value: "DROIT, SCIENCE POLITIQUE : DROIT EUROPEEN (MASTER PARIS 1)", label: "DROIT, SCIENCE POLITIQUE : DROIT EUROPEEN (MASTER PARIS 1)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT PUBLIC (MASTER CERGY)", label: "DROIT, ECONOMIE, GESTION : DROIT PUBLIC (MASTER CERGY)" },
  { value: "SCIENCES POLITIQUES ET SOCIALES : SCIENCES POLITIQUES SPE DROIT SANITAIRE ET SOCIAL (MASTER PARIS 2)", label: "SCIENCES POLITIQUES ET SOCIALES : SCIENCES POLITIQUES SPE DROIT SANITAIRE ET SOCIAL (MASTER PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT DE LA SANTE (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : DROIT DE LA SANTE (MASTER MONTPELLIER)" },
  { value: "DROIT : DROIT DES AFFAIRES SPE DROIT FISCAL (MASTER PARIS 2)", label: "DROIT : DROIT DES AFFAIRES SPE DROIT FISCAL (MASTER PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT DES ASSURANCES (MASTER PARIS 12)", label: "DROIT, ECONOMIE, GESTION : DROIT DES ASSURANCES (MASTER PARIS 12)" },
  { value: "DROIT : DROIT EUROPEEN SPE DROIT ET CONTENTIEUX DE L'UNION EUROPEENNE (MASTER PARIS 2)", label: "DROIT : DROIT EUROPEEN SPE DROIT ET CONTENTIEUX DE L'UNION EUROPEENNE (MASTER PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : SCIENCE POLITIQUE (MASTER VERSAILLES)", label: "DROIT, ECONOMIE, GESTION : SCIENCE POLITIQUE (MASTER VERSAILLES)" },
  { value: "DROIT SOCIAL (MASTER ORLEANS)", label: "DROIT SOCIAL (MASTER ORLEANS)" },
  { value: "DROIT DES ASSURANCES (MASTER LA ROCHELLE)", label: "DROIT DES ASSURANCES (MASTER LA ROCHELLE)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT ADMINISTRATIF (MASTER VERSAILLES)", label: "DROIT, ECONOMIE, GESTION : DROIT ADMINISTRATIF (MASTER VERSAILLES)" },
  { value: "DROIT : DROIT DES AFFAIRES SPE DROIT BANCAIRE ET FINANCIER (MASTER PARIS 2)", label: "DROIT : DROIT DES AFFAIRES SPE DROIT BANCAIRE ET FINANCIER (MASTER PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT DE L'ENVIRONNEMENT ET DE L'URBANISME (MASTER PERPIGNAN)", label: "DROIT, ECONOMIE, GESTION : DROIT DE L'ENVIRONNEMENT ET DE L'URBANISME (MASTER PERPIGNAN)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT PRIVE (MASTER PARIS 10)", label: "DROIT, ECONOMIE, GESTION : DROIT PRIVE (MASTER PARIS 10)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT SOCIAL (MASTER TOULOUSE 1)", label: "DROIT, ECONOMIE, GESTION : DROIT SOCIAL (MASTER TOULOUSE 1)" },
  { value: "SCIENCES DES ORGANISATIONS (DROIT, ECONOMIE, GESTION, SCIENCES SOCIALES) : DROIT (MASTER DAUPHINE)", label: "SCIENCES DES ORGANISATIONS (DROIT, ECONOMIE, GESTION, SCIENCES SOCIALES) : DROIT (MASTER DAUPHINE)" },
  { value: "DROIT INTERNATIONAL (MASTER GRENOBLE ALPES)", label: "DROIT INTERNATIONAL (MASTER GRENOBLE ALPES)" },
  { value: "DROIT PUBLIC DES AFFAIRES (MASTER POITIERS)", label: "DROIT PUBLIC DES AFFAIRES (MASTER POITIERS)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT DE LA SANTÉ SPE DROIT DES INDUSTRIES DES PRODUITS DE SANTE (MASTER PARIS 5)", label: "DROIT, ECONOMIE, GESTION : DROIT DE LA SANTÉ SPE DROIT DES INDUSTRIES DES PRODUITS DE SANTE (MASTER PARIS 5)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT PRIVE SPE DROIT ET GESTION BANCAIRE DE PATRIMOINE (MASTER PARIS 5)", label: "DROIT, ECONOMIE, GESTION : DROIT PRIVE SPE DROIT ET GESTION BANCAIRE DE PATRIMOINE (MASTER PARIS 5)" },
  { value: "DROIT : DROIT PRIVE SPE DROIT IMMOBILIER ET DE LA CONSTRUCTION (MASTER PARIS 2)", label: "DROIT : DROIT PRIVE SPE DROIT IMMOBILIER ET DE LA CONSTRUCTION (MASTER PARIS 2)" },
  { value: "DROIT DU PATRIMOINE (MASTER AMIENS)", label: "DROIT DU PATRIMOINE (MASTER AMIENS)" },
  { value: "DROIT : DROIT DES AFFAIRES SPE DROIT DES AFFAIRES ET MANAGEMENT (MASTER MBA PARIS 2)", label: "DROIT : DROIT DES AFFAIRES SPE DROIT DES AFFAIRES ET MANAGEMENT (MASTER MBA PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT SOCIAL SPE DROIT SOCIAL (MASTER NANTES)", label: "DROIT, ECONOMIE, GESTION : DROIT SOCIAL SPE DROIT SOCIAL (MASTER NANTES)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT DES AFFAIRES (MASTER LILLE 2)", label: "DROIT, ECONOMIE, GESTION : DROIT DES AFFAIRES (MASTER LILLE 2)" },
  { value: "DROIT PUBLIC (MASTER AIX MARSEILLE)", label: "DROIT PUBLIC (MASTER AIX MARSEILLE)" },
  { value: "DROIT, ECONOMIE, GESTION : FINANCES PUBLIQUES (MONTPELLIER 1)", label: "DROIT, ECONOMIE, GESTION : FINANCES PUBLIQUES (MONTPELLIER 1)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT PRIVE SPE DROIT DES ACTIVITES NUMERIQUES (MASTER PARIS 5)", label: "DROIT, ECONOMIE, GESTION : DROIT PRIVE SPE DROIT DES ACTIVITES NUMERIQUES (MASTER PARIS 5)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT PRIVE (MASTER CERGY)", label: "DROIT, ECONOMIE, GESTION : DROIT PRIVE (MASTER CERGY)" },
  { value: "DROIT SOCIAL (MASTER STRASBOURG)", label: "DROIT SOCIAL (MASTER STRASBOURG)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT SPE DROIT DU MARCHE ET DU PATRIMOINE ARTISTIQUE (MASTER PARIS 2)", label: "DROIT, ECONOMIE, GESTION : DROIT SPE DROIT DU MARCHE ET DU PATRIMOINE ARTISTIQUE (MASTER PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT SOCIAL (MASTER PARIS SACLAY)", label: "DROIT, ECONOMIE, GESTION : DROIT SOCIAL (MASTER PARIS SACLAY)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT DE LA SANTE (MASTER PARIS SACLAY)", label: "DROIT, ECONOMIE, GESTION : DROIT DE LA SANTE (MASTER PARIS SACLAY)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT SOCIAL (MASTER PARIS 10)", label: "DROIT, ECONOMIE, GESTION : DROIT SOCIAL (MASTER PARIS 10)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT DES AFFAIRES (MASTER CERGY)", label: "DROIT, ECONOMIE, GESTION : DROIT DES AFFAIRES (MASTER CERGY)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT DU PATRIMOINE (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : DROIT DU PATRIMOINE (MASTER MONTPELLIER)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT DES AFFAIRES (MASTER PARIS SACLAY)", label: "DROIT, ECONOMIE, GESTION : DROIT DES AFFAIRES (MASTER PARIS SACLAY)" },
  { value: "DROIT PUBLIC (MASTER REIMS)", label: "DROIT PUBLIC (MASTER REIMS)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT SOCIAL (MASTER PARIS 1)", label: "DROIT, ECONOMIE, GESTION : DROIT SOCIAL (MASTER PARIS 1)" },
  { value: "DROIT SOCIAL (MASTER LYON 2)", label: "DROIT SOCIAL (MASTER LYON 2)" },
  { value: "DROIT DES AFFAIRES (MASTER POITIERS)", label: "DROIT DES AFFAIRES (MASTER POITIERS)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT DES AFFAIRES (MASTER SAINT ETIENNE)", label: "DROIT, ECONOMIE, GESTION : DROIT DES AFFAIRES (MASTER SAINT ETIENNE)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT PUBLIC DES AFFAIRES (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : DROIT PUBLIC DES AFFAIRES (MASTER MONTPELLIER)" },
  { value: "DROIT, ECONOMIE, GESTION : SCIENCE POLITIQUE (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : SCIENCE POLITIQUE (MASTER MONTPELLIER)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT PUBLIC (MASTER CHAMBERY)", label: "DROIT, ECONOMIE, GESTION : DROIT PUBLIC (MASTER CHAMBERY)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT PRIVE (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : DROIT PRIVE (MASTER MONTPELLIER)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT DES AFFAIRES (MASTER VALENCIENNES)", label: "DROIT, ECONOMIE, GESTION : DROIT DES AFFAIRES (MASTER VALENCIENNES)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT DE LA SANTE SPE SANTE, PREVOYANCE ET PROTECTION SOCIALE (MASTER PARIS 5)", label: "DROIT, ECONOMIE, GESTION : DROIT DE LA SANTE SPE SANTE, PREVOYANCE ET PROTECTION SOCIALE (MASTER PARIS 5)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT PUBLIC (MASTER PARIS SACLAY)", label: "DROIT, ECONOMIE, GESTION : DROIT PUBLIC (MASTER PARIS SACLAY)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT PRIVE (MASTER CLERMONT AUVERGNE)", label: "DROIT, ECONOMIE, GESTION : DROIT PRIVE (MASTER CLERMONT AUVERGNE)" },
  { value: "RELATIONS INTERNATIONALES (MASTER AIX MARSEILLE)", label: "RELATIONS INTERNATIONALES (MASTER AIX MARSEILLE)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT PUBLIC (MASTER PARIS 1)", label: "DROIT, ECONOMIE, GESTION : DROIT PUBLIC (MASTER PARIS 1)" },
  { value: "DROIT DES AFFAIRES (MASTER DIJON)", label: "DROIT DES AFFAIRES (MASTER DIJON)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT PUBLIC (MASTER DIJON)", label: "DROIT, ECONOMIE, GESTION : DROIT PUBLIC (MASTER DIJON)" },
  { value: "DROIT PUBLIC (MASTER LORRAINE)", label: "DROIT PUBLIC (MASTER LORRAINE)" },
  { value: "ARTS, LETTRES, LANGUES : LETTRES SPE PROFESSIONNELS DE L'ECRIT (MASTER PARIS 7)", label: "ARTS, LETTRES, LANGUES : LETTRES SPE PROFESSIONNELS DE L'ECRIT (MASTER PARIS 7)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : DIRECTION DE PROJETS OU ETABLISSEMENTS CULTURELS (MASTER BORDEAUX 3)", label: "SCIENCES HUMAINES ET SOCIALES : DIRECTION DE PROJETS OU ETABLISSEMENTS CULTURELS (MASTER BORDEAUX 3)" },
  { value: "ARTS, LETTRES, LANGUES : LITTERATURE, PHILOLOGIE, LINGUISTIQUE SPE METIERS DE L'EDITION ET DE L'AUDIOVISUEL:LETTRES ET MULTIMEDIA (MASTER PARIS)", label: "ARTS, LETTRES, LANGUES : LITTERATURE, PHILOLOGIE, LINGUISTIQUE SPE METIERS DE L'EDITION ET DE L'AUDIOVISUEL:LETTRES ET MULTIMEDIA (MASTER PARIS)" },
  { value: "ARTS, LETTRES, LANGUES : MUSICOLOGIE (MASTER COMUE SACLAY)", label: "ARTS, LETTRES, LANGUES : MUSICOLOGIE (MASTER COMUE SACLAY)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES SPE NDUSTRIES DE LA LANGUE ET TRADUCTION SPECIALISEE (MASTER PARIS 7)", label: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES SPE NDUSTRIES DE LA LANGUE ET TRADUCTION SPECIALISEE (MASTER PARIS 7)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES SPE CONCEPTION DE DOCUMENTATION MULTILINGUE ET MULTIMEDIA (CDMM) (MASTER PARIS 7)", label: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES SPE CONCEPTION DE DOCUMENTATION MULTILINGUE ET MULTIMEDIA (CDMM) (MASTER PARIS 7)" },
  { value: "LANGUES ET SOCIETES (MASTER STRASBOURG)", label: "LANGUES ET SOCIETES (MASTER STRASBOURG)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES (MASTER EVRY)", label: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES (MASTER EVRY)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES SPE LANGUES, CULTURES ET AFFAIRES INTERNATIONALES (MASTER PARIS 3)", label: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES SPE LANGUES, CULTURES ET AFFAIRES INTERNATIONALES (MASTER PARIS 3)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES (MASTER MARNE LA VALLEE)", label: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES (MASTER MARNE LA VALLEE)" },
  { value: "LANGUES, CULTURES ET SOCIETES DU MONDE : METIERS DE L'INTERNATIONAL SPE RELATIONS INTERNATIONALES (MASTER INALCO)", label: "LANGUES, CULTURES ET SOCIETES DU MONDE : METIERS DE L'INTERNATIONAL SPE RELATIONS INTERNATIONALES (MASTER INALCO)" },
  { value: "TRADUCTION ET INTERPRETATION (MASTER STRASBOURG)", label: "TRADUCTION ET INTERPRETATION (MASTER STRASBOURG)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES SPE TRADUCTION ET TERMINOLOGIE JURIDIQUES ET FINANCIERES (MASTER PARIS 3)", label: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES SPE TRADUCTION ET TERMINOLOGIE JURIDIQUES ET FINANCIERES (MASTER PARIS 3)" },
  { value: "ARTS, LETTRES, LANGUES : TRADUCTION ET INTERPRETATION (MASTER MARNE LA VALLEE)", label: "ARTS, LETTRES, LANGUES : TRADUCTION ET INTERPRETATION (MASTER MARNE LA VALLEE)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES ET CULTURES ETRANGERES SPE MEDIATION - INTERPRETATION SOCIALE ET COMMERCIALE (MISC) (MASTER PARIS 7)", label: "ARTS, LETTRES, LANGUES : LANGUES ET CULTURES ETRANGERES SPE MEDIATION - INTERPRETATION SOCIALE ET COMMERCIALE (MISC) (MASTER PARIS 7)" },
  { value: "LANGUES ETRANGERES APPLIQUEES (MASTER LA ROCHELLE)", label: "LANGUES ETRANGERES APPLIQUEES (MASTER LA ROCHELLE)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : LANGUES ETRANGERES APPLIQUEES ( MASTER LILLE 3)", label: "SCIENCES HUMAINES ET SOCIALES : LANGUES ETRANGERES APPLIQUEES ( MASTER LILLE 3)" },
  { value: "LANGUES ETRANGERES APPLIQUEES (MASTER DIJON)", label: "LANGUES ETRANGERES APPLIQUEES (MASTER DIJON)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES (MASTER GRENOBLE ALPES)", label: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES (MASTER GRENOBLE ALPES)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES (MASTER PARIS 12)", label: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES (MASTER PARIS 12)" },
  { value: "LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (MASTER CORSE)", label: "LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (MASTER CORSE)" },
  { value: "LANGUES ETRANGERES APPLIQUEES (MASTER ORLEANS)", label: "LANGUES ETRANGERES APPLIQUEES (MASTER ORLEANS)" },
  { value: "INSTRUMENTATION, MESURE, METROLOGIE (MASTER AIX MARSEILLE)", label: "INSTRUMENTATION, MESURE, METROLOGIE (MASTER AIX MARSEILLE)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : SCIENCES DE LA SOCIETE SPE INGENIERIE DES RISQUES (MASTER PARIS 5)", label: "SCIENCES HUMAINES ET SOCIALES : SCIENCES DE LA SOCIETE SPE INGENIERIE DES RISQUES (MASTER PARIS 5)" },
  { value: "GENIE DES PROCEDES ET DES BIO-PROCEDES (MASTER LORRAINE)", label: "GENIE DES PROCEDES ET DES BIO-PROCEDES (MASTER LORRAINE)" },
  { value: "GENIE INDUSTRIEL (MASTER AMIENS)", label: "GENIE INDUSTRIEL (MASTER AMIENS)" },
  { value: "GENIE INDUSTRIEL (MASTER STRASBOURG)", label: "GENIE INDUSTRIEL (MASTER STRASBOURG)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : ENERGIE (MASTER PERPIGNAN)", label: "SCIENCES, TECHNOLOGIES, SANTE : ENERGIE (MASTER PERPIGNAN)" },
  { value: "ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER REIMS)", label: "ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER REIMS)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : GENIE INDUSTRIEL (MASTER PARIS SACLAY)", label: "SCIENCES, TECHNOLOGIES, SANTE : GENIE INDUSTRIEL (MASTER PARIS SACLAY)" },
  { value: "SCIENCES, TECHNOLOGIE, SANTE : GENIE INDUSTRIEL (MASTER MARNE LA VALLEE)", label: "SCIENCES, TECHNOLOGIE, SANTE : GENIE INDUSTRIEL (MASTER MARNE LA VALLEE)" },
  { value: "INGENIERIE NUCLAIRE (MASTER GRENOBLE ALPES)", label: "INGENIERIE NUCLAIRE (MASTER GRENOBLE ALPES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (MASTER PARIS 6)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (MASTER PARIS 6)" },
  { value: "DESIGN (MASTER BORDEAUX 3)", label: "DESIGN (MASTER BORDEAUX 3)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INGENIERIE DE CONCEPTION (MASTER CLERMONT AUVERGNE)", label: "SCIENCES, TECHNOLOGIES, SANTE : INGENIERIE DE CONCEPTION (MASTER CLERMONT AUVERGNE)" },
  { value: "ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER GRENOBLE ALPES)", label: "ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER GRENOBLE ALPES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INGENIERIE DES SYSTEMES COMPLEXES (MASTER BORDEAUX)", label: "SCIENCES, TECHNOLOGIES, SANTE : INGENIERIE DES SYSTEMES COMPLEXES (MASTER BORDEAUX)" },
  { value: "INGENIERIE DES SYSTEMES COMPLEXES (MASTER LORRAINE)", label: "INGENIERIE DES SYSTEMES COMPLEXES (MASTER LORRAINE)" },
  { value: "BIOLOGIE AGROSCIENCES (MASTER TOURS)", label: "BIOLOGIE AGROSCIENCES (MASTER TOURS)" },
  { value: "SCIENCES ET TECHNOLOGIES DE L'AGRICULTURE, DE L'ALIMENTATION ET DE L'ENVIRONNEMENT (MASTER CORSE)", label: "SCIENCES ET TECHNOLOGIES DE L'AGRICULTURE, DE L'ALIMENTATION ET DE L'ENVIRONNEMENT (MASTER CORSE)" },
  { value: "AGROSCIENCES, ENVIRONNEMENT, PAYSAGE, FORET (MASTER ORLEANS)", label: "AGROSCIENCES, ENVIRONNEMENT, PAYSAGE, FORET (MASTER ORLEANS)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES ET GENIE DES MATERIAUX (MASTER CNAM)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES ET GENIE DES MATERIAUX (MASTER CNAM)" },
  { value: "CHIMIE ET SCIENCES DES MATERIAUX (MASTER TOURS)", label: "CHIMIE ET SCIENCES DES MATERIAUX (MASTER TOURS)" },
  { value: "GENIE DES PROCEDES ET DES BIOPROCEDES (MASTER LYON 1)", label: "GENIE DES PROCEDES ET DES BIOPROCEDES (MASTER LYON 1)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE ET SCIENCES DES MATERIAUX (MASTER LYON 1)", label: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE ET SCIENCES DES MATERIAUX (MASTER LYON 1)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE ET SCIENCES DES MATERIAUX (MASTER SAINT ETIENNE)", label: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE ET SCIENCES DES MATERIAUX (MASTER SAINT ETIENNE)" },
  { value: "SCIENCES ET GENIE DES MATERIAUX (MASTER COMUE COTE D'AZUR)", label: "SCIENCES ET GENIE DES MATERIAUX (MASTER COMUE COTE D'AZUR)" },
  { value: "SCIENCES ET GENIE DES MATERIAUX (MASTER LA ROCHELLE)", label: "SCIENCES ET GENIE DES MATERIAUX (MASTER LA ROCHELLE)" },
  { value: "SCIENCES ET GENIE DES MATERIAUX (MASTER PAU)", label: "SCIENCES ET GENIE DES MATERIAUX (MASTER PAU)" },
  { value: "NUTRITION ET SCIENCES DES ALIMENTS (MASTER BESANCON)", label: "NUTRITION ET SCIENCES DES ALIMENTS (MASTER BESANCON)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : NUTRITION ET SCIENCES DES ALIMENTS (MASTER VALENCIENNES)", label: "SCIENCES, TECHNOLOGIES, SANTE : NUTRITION ET SCIENCES DES ALIMENTS (MASTER VALENCIENNES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : NUTRITION ET SCIENCES DES ALIMENTS (MASTER PARIS SACLAY)", label: "SCIENCES, TECHNOLOGIES, SANTE : NUTRITION ET SCIENCES DES ALIMENTS (MASTER PARIS SACLAY)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : NUTRITION ET SCIENCES DES ALIMENTS (MASTER LORRAINE)", label: "SCIENCES, TECHNOLOGIES, SANTE : NUTRITION ET SCIENCES DES ALIMENTS (MASTER LORRAINE)" },
  { value: "BIOLOGIE (MASTER GRENOBLE ALPES)", label: "BIOLOGIE (MASTER GRENOBLE ALPES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DU MEDICAMENT (MASTER BORDEAUX)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DU MEDICAMENT (MASTER BORDEAUX)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DU MEDICAMENT (MASTER MONTPELLIER)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DU MEDICAMENT (MASTER MONTPELLIER)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DU MEDICAMENT (MASTER PARIS SACLAY)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DU MEDICAMENT (MASTER PARIS SACLAY)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER MONTPELLIER)", label: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER MONTPELLIER)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER CERGY)", label: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE (MASTER CERGY)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE ET SCIENCES DU VIVANT (MASTER VERSAILLES)", label: "SCIENCES, TECHNOLOGIES, SANTE : CHIMIE ET SCIENCES DU VIVANT (MASTER VERSAILLES)" },
  { value: "CHIMIE MOLECULAIRE (MASTER ORLEANS)", label: "CHIMIE MOLECULAIRE (MASTER ORLEANS)" },
  { value: "SCIENCES DU MEDICAMENT (MASTER STRASBOURG)", label: "SCIENCES DU MEDICAMENT (MASTER STRASBOURG)" },
  { value: "CHIMIE (MASTER DIJON)", label: "CHIMIE (MASTER DIJON)" },
  { value: "PHYSIQUE APPLIQUEE ET INGENIERIE PHYSIQUE (MASTER CORSE)", label: "PHYSIQUE APPLIQUEE ET INGENIERIE PHYSIQUE (MASTER CORSE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : RISQUE ET ENVIRONNEMENT (MASTER NIMES)", label: "SCIENCES, TECHNOLOGIES, SANTE : RISQUE ET ENVIRONNEMENT (MASTER NIMES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : GENIE CIVIL (MASTER VALENCIENNES)", label: "SCIENCES, TECHNOLOGIES, SANTE : GENIE CIVIL (MASTER VALENCIENNES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : GENIE URBAIN (MASTER MARNE LA VALLEE)", label: "SCIENCES, TECHNOLOGIES, SANTE : GENIE URBAIN (MASTER MARNE LA VALLEE)" },
  { value: "GEOGRAPHIE, AMENAGEMENT, ENVIRONNEMENT, DEVELOPPEMENT (MASTER GRENOBLE ALPES)", label: "GEOGRAPHIE, AMENAGEMENT, ENVIRONNEMENT, DEVELOPPEMENT (MASTER GRENOBLE ALPES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : GENIE CIVIL (MASTER CERGY)", label: "SCIENCES, TECHNOLOGIES, SANTE : GENIE CIVIL (MASTER CERGY)" },
  { value: "GENIE CIVIL (MASTER LIMOGES)", label: "GENIE CIVIL (MASTER LIMOGES)" },
  { value: "GENIE CIVIL (MASTER LORRAINE)", label: "GENIE CIVIL (MASTER LORRAINE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : TRANSPORTS, MOBILITE, RESEAUX (MASTER VALENCIENNES)", label: "SCIENCES, TECHNOLOGIES, SANTE : TRANSPORTS, MOBILITE, RESEAUX (MASTER VALENCIENNES)" },
  { value: "PHYSIQUE APPLIQUEE ET INGENIERIE PHYSIQUE (MASTER STRASBOURG)", label: "PHYSIQUE APPLIQUEE ET INGENIERIE PHYSIQUE (MASTER STRASBOURG)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : MECANIQUE (MASTER BORDEAUX)", label: "SCIENCES, TECHNOLOGIES, SANTE : MECANIQUE (MASTER BORDEAUX)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : MECANIQUE (MASTER LYON 1)", label: "SCIENCES, TECHNOLOGIES, SANTE : MECANIQUE (MASTER LYON 1)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : GENIE INDUSTRIEL (MASTER PARIS 10)", label: "SCIENCES, TECHNOLOGIES, SANTE : GENIE INDUSTRIEL (MASTER PARIS 10)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : GENIE MECANIQUE (MASTER EVRY)", label: "SCIENCES, TECHNOLOGIES, SANTE : GENIE MECANIQUE (MASTER EVRY)" },
  { value: "MECANIQUE (MASTER GRENOBLE ALPES)", label: "MECANIQUE (MASTER GRENOBLE ALPES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : GENIE MECANIQUE (MASTER TOULOUSE 3)", label: "SCIENCES, TECHNOLOGIES, SANTE : GENIE MECANIQUE (MASTER TOULOUSE 3)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : MAINTENANCE AERONAUTIQUE (MASTER BORDEAUX)", label: "SCIENCES, TECHNOLOGIES, SANTE : MAINTENANCE AERONAUTIQUE (MASTER BORDEAUX)" },
  { value: "ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER AMIENS)", label: "ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER AMIENS)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER CERGY)", label: "SCIENCES, TECHNOLOGIES, SANTE : ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER CERGY)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER MARNE LA VALLEE)", label: "SCIENCES, TECHNOLOGIES, SANTE : ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER MARNE LA VALLEE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER PARIS SACLAY)", label: "SCIENCES, TECHNOLOGIES, SANTE : ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER PARIS SACLAY)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER TOULOUSE 3)", label: "SCIENCES, TECHNOLOGIES, SANTE : ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER TOULOUSE 3)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER LORRAINE)", label: "SCIENCES, TECHNOLOGIES, SANTE : ELECTRONIQUE, ENERGIE ELECTRIQUE, AUTOMATIQUE (MASTER LORRAINE)" },
  { value: "MANAGEMENT DES SYSTEMES D'INFORMATION (MASTER GRENOBLE ALPES)", label: "MANAGEMENT DES SYSTEMES D'INFORMATION (MASTER GRENOBLE ALPES)" },
  { value: "MANAGEMENT STRATEGIQUE (MASTER AIX MARSEILLE)", label: "MANAGEMENT STRATEGIQUE (MASTER AIX MARSEILLE)" },
  { value: "MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER AIX MARSEILLE)", label: "MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER AIX MARSEILLE)" },
  { value: "GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER CAEN)", label: "GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER CAEN)" },
  { value: "SCIENCES, TECHNOLOGIE, SANTE : QUALITE, HYGIENE, SECURITE (MASTER MARNE LA VALLEE)", label: "SCIENCES, TECHNOLOGIE, SANTE : QUALITE, HYGIENE, SECURITE (MASTER MARNE LA VALLEE)" },
  { value: "MANAGEMENT (MASTER LILLE 1)", label: "MANAGEMENT (MASTER LILLE 1)" },
  { value: "ECONOMIE-GESTION : SCIENCES DE GESTION SPE MANAGEMENT DES PROJETS LOGISTIQUES (MASTER PARIS 2)", label: "ECONOMIE-GESTION : SCIENCES DE GESTION SPE MANAGEMENT DES PROJETS LOGISTIQUES (MASTER PARIS 2)" },
  { value: "DROIT : DROIT PUBLIC SPE ADMINISTRATION ET POLITIQUES PUBLIQUES (MASTER PARIS 2)", label: "DROIT : DROIT PUBLIC SPE ADMINISTRATION ET POLITIQUES PUBLIQUES (MASTER PARIS 2)" },
  { value: "DROIT ECONOMIE GESTION : MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER BORDEAUX)", label: "DROIT ECONOMIE GESTION : MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER BORDEAUX)" },
  { value: "GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (RENNES 1)", label: "GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (RENNES 1)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE DE L'ENTREPRISE ET DES MARCHES SPE MARKETING (MASTER PARIS 13)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE DE L'ENTREPRISE ET DES MARCHES SPE MARKETING (MASTER PARIS 13)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER EVRY)", label: "DROIT, ECONOMIE, GESTION : GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER EVRY)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (PARIS 1)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (PARIS 1)" },
  { value: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER RENNES 1)", label: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER RENNES 1)" },
  { value: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER DIJON)", label: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER DIJON)" },
  { value: "DROIT, ECONOMIE, GESTION : ENTREPRENEURIAT ET MANAGEMENT DE PROJETS (MASTER VALENCIENNES)", label: "DROIT, ECONOMIE, GESTION : ENTREPRENEURIAT ET MANAGEMENT DE PROJETS (MASTER VALENCIENNES)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT STRATEGIQUE (MASTER PARIS 10)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT STRATEGIQUE (MASTER PARIS 10)" },
  { value: "MANAGEMENT DES PME-PMI (MASTER TOURS)", label: "MANAGEMENT DES PME-PMI (MASTER TOURS)" },
  { value: "ECONOMIE - GESTION : SCIENCES DE GESTION SPE MANAGEMENT STRATEGIQUE ET ENTREPRENEURIAT (MASTER PARIS 2)", label: "ECONOMIE - GESTION : SCIENCES DE GESTION SPE MANAGEMENT STRATEGIQUE ET ENTREPRENEURIAT (MASTER PARIS 2)" },
  { value: "ECONOMIE - GESTION : SCIENCES DE GESTION SPE MANAGEMENT ET NOUVELLES TECHNOLOGIES DES SYSTEMES D'INFORMATION (MASTER PARIS 2)", label: "ECONOMIE - GESTION : SCIENCES DE GESTION SPE MANAGEMENT ET NOUVELLES TECHNOLOGIES DES SYSTEMES D'INFORMATION (MASTER PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE APPLIQUEE (MASTER MARNE LA VALLEE)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE APPLIQUEE (MASTER MARNE LA VALLEE)" },
  { value: "QUALITE, HYGIENE, SECURITE (MASTER AIX MARSEILLE)", label: "QUALITE, HYGIENE, SECURITE (MASTER AIX MARSEILLE)" },
  { value: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER CAEN)", label: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER CAEN)" },
  { value: "ECONOMIE - GESTION : SCIENCES DE GESTION (MASTER 1 PARIS 2)", label: "ECONOMIE - GESTION : SCIENCES DE GESTION (MASTER 1 PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER PARIS 12)", label: "DROIT, ECONOMIE, GESTION : GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER PARIS 12)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE DE L'ENTREPRISE ET DES MARCHES SPE ECONOMIE ET GESTION DES ENTREPRISES (MASTER PARIS 13)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE DE L'ENTREPRISE ET DES MARCHES SPE ECONOMIE ET GESTION DES ENTREPRISES (MASTER PARIS 13)" },
  { value: "DROIT, ECONOMIE, GESTION : ADMINISTRATION ECONOMIQUE ET SOCIALE (MASTER EVRY)", label: "DROIT, ECONOMIE, GESTION : ADMINISTRATION ECONOMIQUE ET SOCIALE (MASTER EVRY)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT DES SYSTEMES D'INFORMATION (MASTER PAU)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT DES SYSTEMES D'INFORMATION (MASTER PAU)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER LILLE 1)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER LILLE 1)" },
  { value: "MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER LIMOGES)", label: "MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER LIMOGES)" },
  { value: "MANAGEMENT SECTORIEL (MASTER LORRAINE)", label: "MANAGEMENT SECTORIEL (MASTER LORRAINE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : MANAGEMENT DE L'INNOVATION (MASTER PARIS 6)", label: "SCIENCES, TECHNOLOGIES, SANTE : MANAGEMENT DE L'INNOVATION (MASTER PARIS 6)" },
  { value: "MANAGEMENT DE L'INNOVATION (MASTER GRENOBLE ALPES)", label: "MANAGEMENT DE L'INNOVATION (MASTER GRENOBLE ALPES)" },
  { value: "DROIT, ECONOMIE, GESTION : TOURISME (MASTER CHAMBERY)", label: "DROIT, ECONOMIE, GESTION : TOURISME (MASTER CHAMBERY)" },
  { value: "MANAGEMENT DE L'INNOVATION (MASTER LIMOGES)", label: "MANAGEMENT DE L'INNOVATION (MASTER LIMOGES)" },
  { value: "DROIT, ECONOMIE, GESTION GESTION DE L'ENVIRONNEMENT (MASTER VERSAILLES)", label: "DROIT, ECONOMIE, GESTION GESTION DE L'ENVIRONNEMENT (MASTER VERSAILLES)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : GEOGRAPHIE, AMENAGEMENT, ENVIRONNEMENT ET LOGISTIQUE DES ECHANGES SPE TRANSPORTS, LOGISTIQUE, TERRITOIRES, ENVIRONNEMENT (MASTER PARIS 4)", label: "SCIENCES HUMAINES ET SOCIALES : GEOGRAPHIE, AMENAGEMENT, ENVIRONNEMENT ET LOGISTIQUE DES ECHANGES SPE TRANSPORTS, LOGISTIQUE, TERRITOIRES, ENVIRONNEMENT (MASTER PARIS 4)" },
  { value: "GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER AMIENS)", label: "GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER AMIENS)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER U LITTORAL)", label: "DROIT, ECONOMIE, GESTION : GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER U LITTORAL)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER MONTPELLIER)" },
  { value: "GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER LYON 3)", label: "GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER LYON 3)" },
  { value: "GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER U ARTOIS)", label: "GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER U ARTOIS)" },
  { value: "MARKETING, VENTE (MASTER RENNE 1)", label: "MARKETING, VENTE (MASTER RENNE 1)" },
  { value: "LANGUES ETRANGERES APPLIQUEES (MASTER AIX MARSEILLE)", label: "LANGUES ETRANGERES APPLIQUEES (MASTER AIX MARSEILLE)" },
  { value: "MANAGEMENT ET COMMERCE INTERNATIONAL (MASTER LE HAVRE)", label: "MANAGEMENT ET COMMERCE INTERNATIONAL (MASTER LE HAVRE)" },
  { value: "MARKETING VENTE (MASTER PARIS 1)", label: "MARKETING VENTE (MASTER PARIS 1)" },
  { value: "ECONOMIE-GESTION : SCIENCES DE GESTION SPE MARKETING ET COMMUNICATION DES ENTREPRISES (MASTER PARIS 2)", label: "ECONOMIE-GESTION : SCIENCES DE GESTION SPE MARKETING ET COMMUNICATION DES ENTREPRISES (MASTER PARIS 2)" },
  { value: "ECONOMIE GESTION : GOUVERNANCE ECONOMIQUE INTERNATIONALE SPE COMMERCE ET MANAGEMENT INTERNATIONAL (MASTER PARIS 2)", label: "ECONOMIE GESTION : GOUVERNANCE ECONOMIQUE INTERNATIONALE SPE COMMERCE ET MANAGEMENT INTERNATIONAL (MASTER PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER PARIS 10)", label: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER PARIS 10)" },
  { value: "CULTURE ET COMMUNICATION : METIERS DU LIVRE ET DE L'EDITION SPE COMMERCIALISATION DU LIVRE (MASTER PARIS 13)", label: "CULTURE ET COMMUNICATION : METIERS DU LIVRE ET DE L'EDITION SPE COMMERCIALISATION DU LIVRE (MASTER PARIS 13)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES SPE GESTION DU COMMERCE INTERNATIONAL (MASTER PARIS 4)", label: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES SPE GESTION DU COMMERCE INTERNATIONAL (MASTER PARIS 4)" },
  { value: "MARKETING VENTE (MASTER TOURS)", label: "MARKETING VENTE (MASTER TOURS)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : RELATIONS INTERNATIONALES (MASTER PARIS 1)", label: "SCIENCES HUMAINES ET SOCIALES : RELATIONS INTERNATIONALES (MASTER PARIS 1)" },
  { value: "MARKETING, VENTE (MASTER POITIERS)", label: "MARKETING, VENTE (MASTER POITIERS)" },
  { value: "MARKETING, VENTE (MASTER DIJON)", label: "MARKETING, VENTE (MASTER DIJON)" },
  { value: "LANGUES, CULTURES ET SOCIETES DU MONDE : METIERS DE L'INTERNATIONAL SPE COMMERCE INTERNATIONAL (MASTER INALCO)", label: "LANGUES, CULTURES ET SOCIETES DU MONDE : METIERS DE L'INTERNATIONAL SPE COMMERCE INTERNATIONAL (MASTER INALCO)" },
  { value: "DROIT, ECONOMIE, GESTION : MARKETING VENTE (MASTER CNAM)", label: "DROIT, ECONOMIE, GESTION : MARKETING VENTE (MASTER CNAM)" },
  { value: "MARKETING, VENTE (MASTER CAEN)", label: "MARKETING, VENTE (MASTER CAEN)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER PERPIGNAN)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER PERPIGNAN)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES SPE NEGOCIATEUR TRILINGUE EN COMMERCE INTERNATIONAL (MASTER GRENOBLE 3)", label: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES SPE NEGOCIATEUR TRILINGUE EN COMMERCE INTERNATIONAL (MASTER GRENOBLE 3)" },
  { value: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER MARNE LA VALLEE)", label: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER MARNE LA VALLEE)" },
  { value: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER LILLE 1)", label: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER LILLE 1)" },
  { value: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER LILLE 2)", label: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER LILLE 2)" },
  { value: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER MONTPELLIER)" },
  { value: "DROIT, ECONOMIE, GESTION : ADMINISTRATION ET ECHANGES INTERNATIONAUX (MASTER PARIS 12)", label: "DROIT, ECONOMIE, GESTION : ADMINISTRATION ET ECHANGES INTERNATIONAUX (MASTER PARIS 12)" },
  { value: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER PARIS 12)", label: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER PARIS 12)" },
  { value: "MARKETING, VENTE (MASTER ARTOIS)", label: "MARKETING, VENTE (MASTER ARTOIS)" },
  { value: "ECONOMIE-GESTION : SCIENCES DE GESTION SPE INTELLIGENCE MARKETING ET MESURE DES MARCHES (PARIS 2)", label: "ECONOMIE-GESTION : SCIENCES DE GESTION SPE INTELLIGENCE MARKETING ET MESURE DES MARCHES (PARIS 2)" },
  { value: "MARKETING, VENTE (MASTER LYON 3)", label: "MARKETING, VENTE (MASTER LYON 3)" },
  { value: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER GRENOBLE ALPES)", label: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER GRENOBLE ALPES)" },
  { value: "ARTS, LETTRES, LANGUES : MANAGEMENT ET COMMERCE INTERNATIONAL (MASTER BORDEAUX 3)", label: "ARTS, LETTRES, LANGUES : MANAGEMENT ET COMMERCE INTERNATIONAL (MASTER BORDEAUX 3)" },
  { value: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER ROUEN)", label: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER ROUEN)" },
  { value: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER CLERMONT AUVERGNE)", label: "DROIT, ECONOMIE, GESTION : MARKETING, VENTE (MASTER CLERMONT AUVERGNE)" },
  { value: "MARKETING, VENTE (MASTER AMIENS)", label: "MARKETING, VENTE (MASTER AMIENS)" },
  { value: "MARKETING, VENTE (MASTER ORLEANS)", label: "MARKETING, VENTE (MASTER ORLEANS)" },
  { value: "MARKETING, VENTE (MASTER MULHOUSE)", label: "MARKETING, VENTE (MASTER MULHOUSE)" },
  { value: "MARKETING, VENTE (MASTER LA ROCHELLE)", label: "MARKETING, VENTE (MASTER LA ROCHELLE)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE INTERNATIONALE BANQUE FINANCE SPE BANQUE, FINANCE, GESTION DES RISQUES (MASTER PARIS 13)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE INTERNATIONALE BANQUE FINANCE SPE BANQUE, FINANCE, GESTION DES RISQUES (MASTER PARIS 13)" },
  { value: "DROIT, ECONOMIE, GESTION : MONNAIE BANQUE FINANCE ASSURANCE SPE BANQUE, FINANCE, ASSURANCE (MASTER PARIS 13)", label: "DROIT, ECONOMIE, GESTION : MONNAIE BANQUE FINANCE ASSURANCE SPE BANQUE, FINANCE, ASSURANCE (MASTER PARIS 13)" },
  { value: "DROIT : DROIT DES AFFAIRES SPE FISCALITE INTERNATIONALE (MASTER PARIS 2)", label: "DROIT : DROIT DES AFFAIRES SPE FISCALITE INTERNATIONALE (MASTER PARIS 2)" },
  { value: "DROIT,ECONOMIE, GESTION : MONNAIE, BANQUE, FINANCE (MASTER BORDEAUX)", label: "DROIT,ECONOMIE, GESTION : MONNAIE, BANQUE, FINANCE (MASTER BORDEAUX)" },
  { value: "DROIT : DROIT PRIVE SPE ASSURANCES (MASTER PARIS 2)", label: "DROIT : DROIT PRIVE SPE ASSURANCES (MASTER PARIS 2)" },
  { value: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER REIMS)", label: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER REIMS)" },
  { value: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER RENNES 1)", label: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER RENNES 1)" },
  { value: "ECONOMIE - GESTION : MONNAIE - FINANCE - BANQUE SPE TECHNIQUES FINANCIERES ET BANCAIRES (MASTER PARIS 2)", label: "ECONOMIE - GESTION : MONNAIE - FINANCE - BANQUE SPE TECHNIQUES FINANCIERES ET BANCAIRES (MASTER PARIS 2)" },
  { value: "MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER BESANCON)", label: "MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER BESANCON)" },
  { value: "FINANCE (MASTER GRENOBLE ALPES)", label: "FINANCE (MASTER GRENOBLE ALPES)" },
  { value: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER PARIS 10)", label: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER PARIS 10)" },
  { value: "DROIT, ECONOMIE, GESTION : COMPTABILITE - CONTROLE - AUDIT (MASTER LILLE 1)", label: "DROIT, ECONOMIE, GESTION : COMPTABILITE - CONTROLE - AUDIT (MASTER LILLE 1)" },
  { value: "DROIT, ECONOMIE, GESTION : CONTROLE DE GESTION ET AUDIT ORGANISATIONEL (MASTER PAU)", label: "DROIT, ECONOMIE, GESTION : CONTROLE DE GESTION ET AUDIT ORGANISATIONEL (MASTER PAU)" },
  { value: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER NANTES)", label: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER NANTES)" },
  { value: "FINANCE (MASTER DIJON)", label: "FINANCE (MASTER DIJON)" },
  { value: "DROIT, ECONOMIE, GESTION : MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER PARIS 1)", label: "DROIT, ECONOMIE, GESTION : MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER PARIS 1)" },
  { value: "DROIT : DROIT PRIVE SPE GESTION DU PATRIMOINE PRIVE ET PROFESSIONNEL (MASTER PARIS 2)", label: "DROIT : DROIT PRIVE SPE GESTION DU PATRIMOINE PRIVE ET PROFESSIONNEL (MASTER PARIS 2)" },
  { value: "GESTION DE PATRIMOINE (MASTER CAEN)", label: "GESTION DE PATRIMOINE (MASTER CAEN)" },
  { value: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER DIJON)", label: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER DIJON)" },
  { value: "FINANCE (MASTER TOURS)", label: "FINANCE (MASTER TOURS)" },
  { value: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER MONTPELLIER)" },
  { value: "DROIT, ECONOMIE, GESTION : MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER TOULOUSE 1)", label: "DROIT, ECONOMIE, GESTION : MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER TOULOUSE 1)" },
  { value: "FINANCE (MASTER STRASBOURG)", label: "FINANCE (MASTER STRASBOURG)" },
  { value: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER BRETAGNE SUD)", label: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER BRETAGNE SUD)" },
  { value: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER MARNE LA VALLEE)", label: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER MARNE LA VALLEE)" },
  { value: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER CERGY)", label: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER CERGY)" },
  { value: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER PARIS 12)", label: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER PARIS 12)" },
  { value: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER LILLE 2)", label: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER LILLE 2)" },
  { value: "DROIT, ECONOMIE, GESTION : MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER PARIS 12)", label: "DROIT, ECONOMIE, GESTION : MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER PARIS 12)" },
  { value: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER LIMOGES)", label: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER LIMOGES)" },
  { value: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER LILLE 1)", label: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER LILLE 1)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DE PATRIMOINE (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : GESTION DE PATRIMOINE (MASTER MONTPELLIER)" },
  { value: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER SACLAY)", label: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER SACLAY)" },
  { value: "COMPTABILITE-CONTROLE-AUDIT (MASTER REIMS)", label: "COMPTABILITE-CONTROLE-AUDIT (MASTER REIMS)" },
  { value: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER PARIS 1)", label: "DROIT, ECONOMIE, GESTION : FINANCE (MASTER PARIS 1)" },
  { value: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER LYON 2)", label: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER LYON 2)" },
  { value: "ACTUARIAT (MASTER LYON 1)", label: "ACTUARIAT (MASTER LYON 1)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : ACTUARIAT (MASTER PARIS 6)", label: "SCIENCES, TECHNOLOGIES, SANTE : ACTUARIAT (MASTER PARIS 6)" },
  { value: "DROIT, ECONOMIE, GESTION : MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER CNAM)", label: "DROIT, ECONOMIE, GESTION : MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER CNAM)" },
  { value: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER CAEN)", label: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER CAEN)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DE PATRIMOINE (MASTER NANTES)", label: "DROIT, ECONOMIE, GESTION : GESTION DE PATRIMOINE (MASTER NANTES)" },
  { value: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER SAINT ETIENNE)", label: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER SAINT ETIENNE)" },
  { value: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER PARIS 8)", label: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER PARIS 8)" },
  { value: "FINANCE (MASTER LORRAINE)", label: "FINANCE (MASTER LORRAINE)" },
  { value: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER COMUE COTE D'AZUR)", label: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER COMUE COTE D'AZUR)" },
  { value: "FINANCE (MASTER POITIERS)", label: "FINANCE (MASTER POITIERS)" },
  { value: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER POITIERS)", label: "MONNAIE, BANQUE, FINANCE, ASSURANCE (MASTER POITIERS)" },
  { value: "ACTUARIAT (MASTER POITIERS)", label: "ACTUARIAT (MASTER POITIERS)" },
  { value: "CONTRÔLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER GRENOBLE ALPES)", label: "CONTRÔLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER GRENOBLE ALPES)" },
  { value: "DROIT, ECONOMIE, GESTION : CONTROLE DE GESTION ET AUDIT OPERATIONNEL (MASTER PARIS 1)", label: "DROIT, ECONOMIE, GESTION : CONTROLE DE GESTION ET AUDIT OPERATIONNEL (MASTER PARIS 1)" },
  { value: "DROIT, ECONOMIE, GESTION : CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER PARIS 12)", label: "DROIT, ECONOMIE, GESTION : CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER PARIS 12)" },
  { value: "COMPTABILITE-CONTROLE-AUDIT (MASTER RENNES 1)", label: "COMPTABILITE-CONTROLE-AUDIT (MASTER RENNES 1)" },
  { value: "DROIT, ECONOMIE, GESTION : CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER PARIS 10)", label: "DROIT, ECONOMIE, GESTION : CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER PARIS 10)" },
  { value: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER MULHOUSE)", label: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER MULHOUSE)" },
  { value: "DROIT ECONOMIE GESTION : COMPTABILITE-CONTROLE-AUDIT (MASTER PARIS 11)", label: "DROIT ECONOMIE GESTION : COMPTABILITE-CONTROLE-AUDIT (MASTER PARIS 11)" },
  { value: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER TOURS)", label: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER TOURS)" },
  { value: "DROIT, ECONOMIE, GESTION : CONTRÔLE ET GOUVERNANCE SPE COMPTABILITE, CONTROLE, AUDIT (MASTER PARIS 5)", label: "DROIT, ECONOMIE, GESTION : CONTRÔLE ET GOUVERNANCE SPE COMPTABILITE, CONTROLE, AUDIT (MASTER PARIS 5)" },
  { value: "DROIT, ECONOMIE, GESTION : COMPTABILITE-CONTROLE-AUDIT (MASTER CAEN)", label: "DROIT, ECONOMIE, GESTION : COMPTABILITE-CONTROLE-AUDIT (MASTER CAEN)" },
  { value: "DROIT, ECONOMIE, GESTION : COMPTABILITE, CONTROLE, AUDIT (MASTER PARIS 12)", label: "DROIT, ECONOMIE, GESTION : COMPTABILITE, CONTROLE, AUDIT (MASTER PARIS 12)" },
  { value: "DROIT, ECONOMIE, GESTION : COMPTABILITE, CONTROLE, AUDIT (MASTER PARIS 13)", label: "DROIT, ECONOMIE, GESTION : COMPTABILITE, CONTROLE, AUDIT (MASTER PARIS 13)" },
  { value: "COMPTABILITE-CONTROLE-AUDIT (MASTER ORLEANS)", label: "COMPTABILITE-CONTROLE-AUDIT (MASTER ORLEANS)" },
  { value: "SCIENCES DES ORGANISATIONS (DROIT, ECONOMIE, GESTION, SCIENCES SOCIALES) : COMPTABILITE, CONTROLE, AUDIT (MASTER DAUPHINE)", label: "SCIENCES DES ORGANISATIONS (DROIT, ECONOMIE, GESTION, SCIENCES SOCIALES) : COMPTABILITE, CONTROLE, AUDIT (MASTER DAUPHINE)" },
  { value: "DROIT, ECONOMIE, GESTION : COMPTABILITE, CONTROLE, AUDIT (MASTER EVRY)", label: "DROIT, ECONOMIE, GESTION : COMPTABILITE, CONTROLE, AUDIT (MASTER EVRY)" },
  { value: "DROIT, ECONOMIE, GESTION : COMPTABILITE - CONTROLE - AUDIT (MASTER MARNE LA VALLEE)", label: "DROIT, ECONOMIE, GESTION : COMPTABILITE - CONTROLE - AUDIT (MASTER MARNE LA VALLEE)" },
  { value: "COMPTABILITE-CONTROLE-AUDIT (MASTER BRETAGNE SUD)", label: "COMPTABILITE-CONTROLE-AUDIT (MASTER BRETAGNE SUD)" },
  { value: "DROIT, ECONOMIE, GESTION : COMPTABILITE - CONTROLE - AUDIT (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : COMPTABILITE - CONTROLE - AUDIT (MASTER MONTPELLIER)" },
  { value: "COMPTABILITE-CONTROLE-AUDIT (MASTER LIMOGES)", label: "COMPTABILITE-CONTROLE-AUDIT (MASTER LIMOGES)" },
  { value: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER ORLEANS)", label: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER ORLEANS)" },
  { value: "COMPTABILITE-CONTROLE-AUDIT (MASTER AMIENS)", label: "COMPTABILITE-CONTROLE-AUDIT (MASTER AMIENS)" },
  { value: "DROIT, ECONOMIE, GESTION : CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL SPE AUDIT DES ORGANISATIONS ET MAITRISE DES RISQUES (MASTER PARIS 5)", label: "DROIT, ECONOMIE, GESTION : CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL SPE AUDIT DES ORGANISATIONS ET MAITRISE DES RISQUES (MASTER PARIS 5)" },
  { value: "DROIT, ECONOMIE, GESTION : CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER MONTPELLIER)" },
  { value: "DROIT, ECONOMIE, GESTION : COMPTABILITE, CONTROLE, AUDIT SPE COMPTABILITE, CONTROLE, AUDIT (MASTER CNAM)", label: "DROIT, ECONOMIE, GESTION : COMPTABILITE, CONTROLE, AUDIT SPE COMPTABILITE, CONTROLE, AUDIT (MASTER CNAM)" },
  { value: "COMPTABILITE-CONTROLE-AUDIT (MASTER SAINT ETIENNE)", label: "COMPTABILITE-CONTROLE-AUDIT (MASTER SAINT ETIENNE)" },
  { value: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER SAINT ETIENNE)", label: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER SAINT ETIENNE)" },
  { value: "DROIT, ECONOMIE, GESTION : COMPTABILITE-CONTROLE-AUDIT (MASTER CLERMONT AUVERGNE)", label: "DROIT, ECONOMIE, GESTION : COMPTABILITE-CONTROLE-AUDIT (MASTER CLERMONT AUVERGNE)" },
  { value: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER LILLE 1)", label: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER LILLE 1)" },
  { value: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER POITIERS)", label: "CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER POITIERS)" },
  { value: "GESTION DES RESSOURCES HUMAINES (MASTER GRENOBLE ALPES)", label: "GESTION DES RESSOURCES HUMAINES (MASTER GRENOBLE ALPES)" },
  { value: "GESTION DES RESSOURCES HUMAINES (MASTER AMIENS)", label: "GESTION DES RESSOURCES HUMAINES (MASTER AMIENS)" },
  { value: "ECONOMIE-GESTION : SCIENCES DE GESTION SPE GESTION DES RESSOURCES HUMAINES ET RELATIONS DU TRAVAIL (MASTER PARIS 2)", label: "ECONOMIE-GESTION : SCIENCES DE GESTION SPE GESTION DES RESSOURCES HUMAINES ET RELATIONS DU TRAVAIL (MASTER PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER MONTPELLIER 3)", label: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER MONTPELLIER 3)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER VERSAILLES)", label: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER VERSAILLES)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER CAEN)", label: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER CAEN)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER MARNE LA VALLEE)", label: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER MARNE LA VALLEE)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION SPE RESSOURCES HUMAINES ET COMMUNICATION (MASTER PARIS 4)", label: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION SPE RESSOURCES HUMAINES ET COMMUNICATION (MASTER PARIS 4)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT SPE INGENIERIE DES RESSOURCES HUMAINES (MASTER PARIS 5)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT SPE INGENIERIE DES RESSOURCES HUMAINES (MASTER PARIS 5)" },
  { value: "GESTION DES RESSOURCES HUMAINES (MASTER POITIERS)", label: "GESTION DES RESSOURCES HUMAINES (MASTER POITIERS)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER LILLE 1)", label: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER LILLE 1)" },
  { value: "DROIT ECONOMIE GESTION : GESTION DES RESSOURCES HUMAINES (MASTER BORDEAUX)", label: "DROIT ECONOMIE GESTION : GESTION DES RESSOURCES HUMAINES (MASTER BORDEAUX)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER PARIS 12)", label: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER PARIS 12)" },
  { value: "GESTION DES RESSOURCES HUMAINES (MASTER DIJON)", label: "GESTION DES RESSOURCES HUMAINES (MASTER DIJON)" },
  { value: "GESTION DES RESSOURCES HUMAINES (MASTER RENNES 1)", label: "GESTION DES RESSOURCES HUMAINES (MASTER RENNES 1)" },
  { value: "ECONOMIE-GESTION : SCIENCES DE GESTION SPE GESTION DES RESSOURCES HUMAINES INTERNATIONALES (MASTER PARIS 2)", label: "ECONOMIE-GESTION : SCIENCES DE GESTION SPE GESTION DES RESSOURCES HUMAINES INTERNATIONALES (MASTER PARIS 2)" },
  { value: "INTERVENTION ET DEVELOPPEMENT SOCIAL (MASTER MONTPELLIER 3)", label: "INTERVENTION ET DEVELOPPEMENT SOCIAL (MASTER MONTPELLIER 3)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOUCES HUMAINES (MASTER LA REUNION)", label: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOUCES HUMAINES (MASTER LA REUNION)" },
  { value: "GESTION DES RESSOURCES HUMAINES (MASTER BESANCON)", label: "GESTION DES RESSOURCES HUMAINES (MASTER BESANCON)" },
  { value: "GESTION DES RESSOURCES HUMAINES (MASTER ANGERS)", label: "GESTION DES RESSOURCES HUMAINES (MASTER ANGERS)" },
  { value: "ECONOMIE-GESTION : SCIENCES DE GESTION SPE GESTION DES RESSOURCES HUMAINES ET MANAGEMENT PUBLIC (PARIS 2)", label: "ECONOMIE-GESTION : SCIENCES DE GESTION SPE GESTION DES RESSOURCES HUMAINES ET MANAGEMENT PUBLIC (PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER PARIS 1)", label: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER PARIS 1)" },
  { value: "GESTION DES RESSOURCES HUMAINES (MASTER REIMS)", label: "GESTION DES RESSOURCES HUMAINES (MASTER REIMS)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOUCES HUMAINES (MASTER PARIS 1)", label: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOUCES HUMAINES (MASTER PARIS 1)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER ROUEN)", label: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER ROUEN)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER CLERMONT AUVERGNE)", label: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER CLERMONT AUVERGNE)" },
  { value: "ERGONOMIE (MASTER CNAM)", label: "ERGONOMIE (MASTER CNAM)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER COMUE COTE D'AZUR)", label: "DROIT, ECONOMIE, GESTION : GESTION DES RESSOURCES HUMAINES (MASTER COMUE COTE D'AZUR)" },
  { value: "GESTION DES RESSOURCES HUMAINES (MASTER ORLEANS)", label: "GESTION DES RESSOURCES HUMAINES (MASTER ORLEANS)" },
  { value: "GESTION DES RESSOURCES HUMAINES (MASTER PARIS 10)", label: "GESTION DES RESSOURCES HUMAINES (MASTER PARIS 10)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : INFORMATION COMMUNICATION SPE COMMUNICATION DES ENTREPRISES ET DES INSTITUTIONS (MASTER PARIS 3)", label: "SCIENCES HUMAINES ET SOCIALES : INFORMATION COMMUNICATION SPE COMMUNICATION DES ENTREPRISES ET DES INSTITUTIONS (MASTER PARIS 3)" },
  { value: "SCIENCES POLITIQUES ET SOCIALES : MEDIA, INFORMATION ET COMMUNICATION SPE COMMUNICATION, MULTIMEDIA ET CULTURES NUMERIQUES (MASTER PARIS 2)", label: "SCIENCES POLITIQUES ET SOCIALES : MEDIA, INFORMATION ET COMMUNICATION SPE COMMUNICATION, MULTIMEDIA ET CULTURES NUMERIQUES (MASTER PARIS 2)" },
  { value: "CULTURE ET COMMUNICATION : INFORMATION ET COMMUNICATION DANS LES ORGANISATIONS SPE COMMUNICATION ET RESSOURCES HUMAINES (MASTER PARIS 13)", label: "CULTURE ET COMMUNICATION : INFORMATION ET COMMUNICATION DANS LES ORGANISATIONS SPE COMMUNICATION ET RESSOURCES HUMAINES (MASTER PARIS 13)" },
  { value: "ARTS, LETTRES, LANGUES : METIERS DU LIVRE ET DE L'EDITION (MASTER CERGY)", label: "ARTS, LETTRES, LANGUES : METIERS DU LIVRE ET DE L'EDITION (MASTER CERGY)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION SPE MARKETING PUBLICITE ET COMMUNICATION (MASTER PARIS 4)", label: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION SPE MARKETING PUBLICITE ET COMMUNICATION (MASTER PARIS 4)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION SPE MANAGEMENT ET COMMUNICATION (MASTER PARIS 4)", label: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION SPE MANAGEMENT ET COMMUNICATION (MASTER PARIS 4)" },
  { value: "SCIENCES POLITIQUES ET SOCIALES : MEDIA, INFORMATION ET COMMUNICATION SPE MEDIAS, PUBLICS ET CULTURES NUMERIQUES (MASTER PARIS 2)", label: "SCIENCES POLITIQUES ET SOCIALES : MEDIA, INFORMATION ET COMMUNICATION SPE MEDIAS, PUBLICS ET CULTURES NUMERIQUES (MASTER PARIS 2)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION SPE COMMUNICATION DES ENTREPRISES ET DES INSTITUTIONS (MASTER PARIS 4)", label: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION SPE COMMUNICATION DES ENTREPRISES ET DES INSTITUTIONS (MASTER PARIS 4)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION SPE MEDIAS ET COMMUNICATION (MASTER PARIS 4)", label: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION SPE MEDIAS ET COMMUNICATION (MASTER PARIS 4)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : INFORMATION, COMMUNICATION (MASTER LILLE 3)", label: "SCIENCES HUMAINES ET SOCIALES : INFORMATION, COMMUNICATION (MASTER LILLE 3)" },
  { value: "CULTURE ET COMMUNICATION : INFORMATION, COMMUNICATION SPE DYNAMIQUES CULTURELLES (MASTER PARIS 13)", label: "CULTURE ET COMMUNICATION : INFORMATION, COMMUNICATION SPE DYNAMIQUES CULTURELLES (MASTER PARIS 13)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION (MASTER MONTPELLIER 3)", label: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION (MASTER MONTPELLIER 3)" },
  { value: "SCIENCES POLITIQUES ET SOCIALES : IMEDIAS, INFORMATION ET COMMUNICATION SPE MEDIAS ET MONDIALISATION (PARIS 2)", label: "SCIENCES POLITIQUES ET SOCIALES : IMEDIAS, INFORMATION ET COMMUNICATION SPE MEDIAS ET MONDIALISATION (PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT DE L'INNOVATION (MASTER MARNE LA VALLEE)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT DE L'INNOVATION (MASTER MARNE LA VALLEE)" },
  { value: "MANAGEMENT DE L'INNOVATION (MASTER AIX MARSEILLE)", label: "MANAGEMENT DE L'INNOVATION (MASTER AIX MARSEILLE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : AUDIOVISUEL, MEDIAS INTERACTIFS NUMERIQUES, JEUX (MASTER INST CHAMPOLLION)", label: "SCIENCES, TECHNOLOGIES, SANTE : AUDIOVISUEL, MEDIAS INTERACTIFS NUMERIQUES, JEUX (MASTER INST CHAMPOLLION)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : HUMANITES NUMERIQUES (MASTER MONTPELLIER 3)", label: "SCIENCES HUMAINES ET SOCIALES : HUMANITES NUMERIQUES (MASTER MONTPELLIER 3)" },
  { value: "ARTS, LETTRES, LANGUES : CREATION NUMERIQUE (MASTER CHAMBERY)", label: "ARTS, LETTRES, LANGUES : CREATION NUMERIQUE (MASTER CHAMBERY)" },
  { value: "INFORMATION, COMMUNICATION (MASTER TOULOUSE 3)", label: "INFORMATION, COMMUNICATION (MASTER TOULOUSE 3)" },
  { value: "HUMANITES NUMERIQUES (MASTER CNAM)", label: "HUMANITES NUMERIQUES (MASTER CNAM)" },
  { value: "INFORMATION, COMMUNICATION (MASTER DIJON)", label: "INFORMATION, COMMUNICATION (MASTER DIJON)" },
  { value: "INFORMATION, COMMUNICATION (MASTER COMUE COTE D'AZUR)", label: "INFORMATION, COMMUNICATION (MASTER COMUE COTE D'AZUR)" },
  { value: "SCIENCES POLITIQUES ET SOCIALES : MEDIAS, INFORMATION ET COMMUNICATION SPE JOURNALISME (PARIS 2)", label: "SCIENCES POLITIQUES ET SOCIALES : MEDIAS, INFORMATION ET COMMUNICATION SPE JOURNALISME (PARIS 2)" },
  { value: "INFORMATION, COMMUNICATION (MASTER MULOUSE)", label: "INFORMATION, COMMUNICATION (MASTER MULOUSE)" },
  { value: "CULTURE ET COMMUNICATION (MASTER LIMOGES)", label: "CULTURE ET COMMUNICATION (MASTER LIMOGES)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION SPE JOURNALISME CULTUREL (MASTER PARIS 3)", label: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION SPE JOURNALISME CULTUREL (MASTER PARIS 3)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION SPE JOURNALISME (MASTER PARIS 4)", label: "SCIENCES HUMAINES ET SOCIALES : INFORMATION ET COMMUNICATION SPE JOURNALISME (MASTER PARIS 4)" },
  { value: "ARTS, LETTRES, LANGUES : CINEMA, DOCUMENTAIRE, MEDIAS SPE JOURNALISME, CULTURE ET COMMUNICATION SCIENTIFIQUES (MASTER PARIS 7)", label: "ARTS, LETTRES, LANGUES : CINEMA, DOCUMENTAIRE, MEDIAS SPE JOURNALISME, CULTURE ET COMMUNICATION SCIENTIFIQUES (MASTER PARIS 7)" },
  { value: "JOURNALISME (MASTER STRASBOURG)", label: "JOURNALISME (MASTER STRASBOURG)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : JOURNALISME (BORDEAUX 3)", label: "SCIENCES HUMAINES ET SOCIALES : JOURNALISME (BORDEAUX 3)" },
  { value: "ARTS, LETTRES, LANGUES : METIERS DU LIVRE ET DE L'EDITION (MASTER MARNE LA VALLEE)", label: "ARTS, LETTRES, LANGUES : METIERS DU LIVRE ET DE L'EDITION (MASTER MARNE LA VALLEE)" },
  { value: "CULTURE ET COMMUNICATION : METIERS DU LIVRE ET DE L'EDITION SPE POLITIQUES EDITORIALES (MASTER PARIS 13)", label: "CULTURE ET COMMUNICATION : METIERS DU LIVRE ET DE L'EDITION SPE POLITIQUES EDITORIALES (MASTER PARIS 13)" },
  { value: "ARTS, LETTRES, LANGUES : ETUDES THEATRALES SPE METIERS DE LA PRODUCTION THEATRALE (MASTER PARIS 3)", label: "ARTS, LETTRES, LANGUES : ETUDES THEATRALES SPE METIERS DE LA PRODUCTION THEATRALE (MASTER PARIS 3)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : METIERS DU LIVRE ET DE L'EDITION (MASTER PARIS 10)", label: "SCIENCES HUMAINES ET SOCIALES : METIERS DU LIVRE ET DE L'EDITION (MASTER PARIS 10)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : ARCHIVES (PARIS 8)", label: "SCIENCES HUMAINES ET SOCIALES : ARCHIVES (PARIS 8)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : INFORMATION, DOCUMENTATION (MASTER MONTPELLIER 3)", label: "SCIENCES HUMAINES ET SOCIALES : INFORMATION, DOCUMENTATION (MASTER MONTPELLIER 3)" },
  { value: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES-MIAGE (MASTER AIX MARSEILLE)", label: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES-MIAGE (MASTER AIX MARSEILLE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : RESEAUX ET TELECOMMUNICATION (MASTER TOULOUSE 3)", label: "SCIENCES, TECHNOLOGIES, SANTE : RESEAUX ET TELECOMMUNICATION (MASTER TOULOUSE 3)" },
  { value: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES-MIAGE (MASTER AMIENS)", label: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES-MIAGE (MASTER AMIENS)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : MATHEMATIQUES ET INFORMATIQUE SPE METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES (MASTER PARIS 5)", label: "SCIENCES, TECHNOLOGIES, SANTE : MATHEMATIQUES ET INFORMATIQUE SPE METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES (MASTER PARIS 5)" },
  { value: "MATHEMATIQUE ET INFORMATIQUE APPLIQUEES AUX SCIENCES HUMAINES ET SOCIALES - MIASHS (MASTER TOULOUSE 2)", label: "MATHEMATIQUE ET INFORMATIQUE APPLIQUEES AUX SCIENCES HUMAINES ET SOCIALES - MIASHS (MASTER TOULOUSE 2)" },
  { value: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER GRENOBLE ALPES)", label: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER GRENOBLE ALPES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE ET INGENIERIE DES SYSTEMES COMPLEXES (MASTER CERGY)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE ET INGENIERIE DES SYSTEMES COMPLEXES (MASTER CERGY)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER PARIS 10)", label: "SCIENCES, TECHNOLOGIES, SANTE : METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER PARIS 10)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : RESEAUX ET TELECOMMUNICATION (MASTER CNAM)", label: "SCIENCES, TECHNOLOGIES, SANTE : RESEAUX ET TELECOMMUNICATION (MASTER CNAM)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER MARNE LA VALLEE)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER MARNE LA VALLEE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INGENIERIE DES SYTEMES COMPLEXES (MASTER PARIS 12)", label: "SCIENCES, TECHNOLOGIES, SANTE : INGENIERIE DES SYTEMES COMPLEXES (MASTER PARIS 12)" },
  { value: "SCIENCES TECHNOLOGIES SANTE : METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER BORDEAUX)", label: "SCIENCES TECHNOLOGIES SANTE : METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER BORDEAUX)" },
  { value: "MATHEMATIQUE, INFORMATIQUE, DECISION ET ORGANISATION : INFORMATIQUE DES ORGANISATIONS (MASTER DAUPHINE)", label: "MATHEMATIQUE, INFORMATIQUE, DECISION ET ORGANISATION : INFORMATIQUE DES ORGANISATIONS (MASTER DAUPHINE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER PARIS 6)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER PARIS 6)" },
  { value: "INFORMATIQUE (MASTER LA ROCHELLE)", label: "INFORMATIQUE (MASTER LA ROCHELLE)" },
  { value: "RESEAUX ET TELECOMMUNICATION (MASTER REIMS)", label: "RESEAUX ET TELECOMMUNICATION (MASTER REIMS)" },
  { value: "INFORMATIQUE (MASTER CORSE)", label: "INFORMATIQUE (MASTER CORSE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER VALENCIENNES)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER VALENCIENNES)" },
  { value: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES-MIAGE (MASTER MULHOUSE)", label: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES-MIAGE (MASTER MULHOUSE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER PARIS EST CRETEIL)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER PARIS EST CRETEIL)" },
  { value: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER TOULOUSE 3)", label: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER TOULOUSE 3)" },
  { value: "INFORMATIQUE (MASTER RENNES 1)", label: "INFORMATIQUE (MASTER RENNES 1)" },
  { value: "INFORMATIQUE (MASTER ANGERS)", label: "INFORMATIQUE (MASTER ANGERS)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER LITTORAL)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER LITTORAL)" },
  { value: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES-MIAGE (MASTER NANTES)", label: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES-MIAGE (MASTER NANTES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER LE MANS)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER LE MANS)" },
  { value: "SCIENCES DES ORGANISATIONS (DROIT, ECONOMIE, GESTION, SCIENCES SOCIALES) : MANAGEMENT DES SYSTEMES D'INFORMATION (MASTER DAUPHINE)", label: "SCIENCES DES ORGANISATIONS (DROIT, ECONOMIE, GESTION, SCIENCES SOCIALES) : MANAGEMENT DES SYSTEMES D'INFORMATION (MASTER DAUPHINE)" },
  { value: "DROIT, ECONOMIE, GESTION : METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER PARIS 12)", label: "DROIT, ECONOMIE, GESTION : METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER PARIS 12)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER CNAM)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER CNAM)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES- MIAGE (MASTER PARIS SACLAY)", label: "SCIENCES, TECHNOLOGIES, SANTE : METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES- MIAGE (MASTER PARIS SACLAY)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT DES SYSTEMES D'INFORMATION (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT DES SYSTEMES D'INFORMATION (MASTER MONTPELLIER)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER PARIS SACLAY)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER PARIS SACLAY)" },
  { value: "INFORMATIQUE (MASTER LYON 1)", label: "INFORMATIQUE (MASTER LYON 1)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER PARIS 1)", label: "SCIENCES, TECHNOLOGIES, SANTE : METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER PARIS 1)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT DES SYSTEMES D'INFORMATION (MASTER PARIS 1)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT DES SYSTEMES D'INFORMATION (MASTER PARIS 1)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : RESEAUX ET TELECOMMUNICATION (MASTER CHAMBERY)", label: "SCIENCES, TECHNOLOGIES, SANTE : RESEAUX ET TELECOMMUNICATION (MASTER CHAMBERY)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER GRENOBLE ALPES)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (MASTER GRENOBLE ALPES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE SPE INTELLIGENCE ARTIFICIELLE (MASTER PARIS 5)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE SPE INTELLIGENCE ARTIFICIELLE (MASTER PARIS 5)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE SPE SECURITE, RESEAUX ET E-SANTE (MASTER PARIS 5)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE SPE SECURITE, RESEAUX ET E-SANTE (MASTER PARIS 5)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER COMUE COTE D'AZUR)", label: "SCIENCES, TECHNOLOGIES, SANTE : METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER COMUE COTE D'AZUR)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : RESEAUX ET TELECOMMUNICATION (MASTER VALENCIENNES)", label: "SCIENCES, TECHNOLOGIES, SANTE : RESEAUX ET TELECOMMUNICATION (MASTER VALENCIENNES)" },
  { value: "INFORMATIQUE (MASTER LE HAVRE)", label: "INFORMATIQUE (MASTER LE HAVRE)" },
  { value: "CULTURE ET COMMUNICATION : CREATION NUMERIQUE DESIGN D'INTERFACE (MASTER PARIS 13)", label: "CULTURE ET COMMUNICATION : CREATION NUMERIQUE DESIGN D'INTERFACE (MASTER PARIS 13)" },
  { value: "INFORMATIQUE (MASTER LA REUNION)", label: "INFORMATIQUE (MASTER LA REUNION)" },
  { value: "INFORMATIQUE (MASTER LORRANE)", label: "INFORMATIQUE (MASTER LORRANE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER lorraine)", label: "SCIENCES, TECHNOLOGIES, SANTE : METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER lorraine)" },
  { value: "INFORMATIQUE (MASTER COMUE COTE D'AZUR)", label: "INFORMATIQUE (MASTER COMUE COTE D'AZUR)" },
  { value: "RESEAUX ET TELECOMMUNICATION (MASTER AIX MARSEILLE)", label: "RESEAUX ET TELECOMMUNICATION (MASTER AIX MARSEILLE)" },
  { value: "INFORMATIQUE (MASTER AMIENS)", label: "INFORMATIQUE (MASTER AMIENS)" },
  { value: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER TOULOUSE 1)", label: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER TOULOUSE 1)" },
  { value: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER ORLEANS)", label: "METHODES INFORMATIQUES APPLIQUEES A LA GESTION DES ENTREPRISES - MIAGE (MASTER ORLEANS)" },
  { value: "INFORMATIQUE (MASTER STRASBOURG)", label: "INFORMATIQUE (MASTER STRASBOURG)" },
  { value: "MANAGEMENT DES SYSTEMES D'INFORMATION (MASTER TOULOUSE 3)", label: "MANAGEMENT DES SYSTEMES D'INFORMATION (MASTER TOULOUSE 3)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : INTERVENTION ET DEVELOPPEMENT SOCIAL (MASTER PARIS 12)", label: "SCIENCES HUMAINES ET SOCIALES : INTERVENTION ET DEVELOPPEMENT SOCIAL (MASTER PARIS 12)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SANTE PUBLIQUE SPE SECURITE SANITAIRE ET QUALITE DES SOINS (MASTER PARIS 5)", label: "SCIENCES, TECHNOLOGIES, SANTE : SANTE PUBLIQUE SPE SECURITE SANITAIRE ET QUALITE DES SOINS (MASTER PARIS 5)" },
  { value: "INGENIERIE DE LA SANTE (MASTER AIX MARSEILLE)", label: "INGENIERIE DE LA SANTE (MASTER AIX MARSEILLE)" },
  { value: "SCIENCES ET TECHNOLOGIES DE LA SANTE : INGENIERIE DE LA SANTE (MASTER MONTPELLIER)", label: "SCIENCES ET TECHNOLOGIES DE LA SANTE : INGENIERIE DE LA SANTE (MASTER MONTPELLIER)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INGENIERIE DE LA SANTE (MASTER PARIS 11)", label: "SCIENCES, TECHNOLOGIES, SANTE : INGENIERIE DE LA SANTE (MASTER PARIS 11)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : BIOLOGIE-SANTE (MASTER COMUE SACLAY)", label: "SCIENCES, TECHNOLOGIES, SANTE : BIOLOGIE-SANTE (MASTER COMUE SACLAY)" },
  { value: "INGENIERIE DE LA SANTE (MASTER LYON 1)", label: "INGENIERIE DE LA SANTE (MASTER LYON 1)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INGENIERIE DE LA SANTE (MASTER LILLE2)", label: "SCIENCES, TECHNOLOGIES, SANTE : INGENIERIE DE LA SANTE (MASTER LILLE2)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SANTE (MASTER PARIS 6)", label: "SCIENCES, TECHNOLOGIES, SANTE : SANTE (MASTER PARIS 6)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SANTE PUBLIQUE (MASTER BORDEAUX)", label: "SCIENCES, TECHNOLOGIES, SANTE : SANTE PUBLIQUE (MASTER BORDEAUX)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INGENIERIE DE LA SANTE (MASTER CLERMONT AUVERGNE)", label: "SCIENCES, TECHNOLOGIES, SANTE : INGENIERIE DE LA SANTE (MASTER CLERMONT AUVERGNE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SANTE (MASTER VERSAILLES)", label: "SCIENCES, TECHNOLOGIES, SANTE : SANTE (MASTER VERSAILLES)" },
  { value: "SCIENCES DES ORGANISATIONS (DROIT, ECONOMIE, GESTION, SCIENCES SOCIALES) : ECONOMIE ET GESTION DE LA SANTE (MASTER DAUPHINE)", label: "SCIENCES DES ORGANISATIONS (DROIT, ECONOMIE, GESTION, SCIENCES SOCIALES) : ECONOMIE ET GESTION DE LA SANTE (MASTER DAUPHINE)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE SOCIALE ET SOLIDAIRE (MASTER MARNE LA VALLEE", label: "DROIT, ECONOMIE, GESTION : ECONOMIE SOCIALE ET SOLIDAIRE (MASTER MARNE LA VALLEE" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE SOCIALE ET SOLIDAIRE (MASTER EVRU)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE SOCIALE ET SOLIDAIRE (MASTER EVRU)" },
  { value: "ADMINISTRATION ECONOMIQUE ET SOCIALE (MASTER PARIS 1)", label: "ADMINISTRATION ECONOMIQUE ET SOCIALE (MASTER PARIS 1)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : SCIENCES DE L'EDUCATION SPE CADRES ET CONSULTANTS EN FORMATION CONTINUE CPE-ESPE (MASTER PARIS 5)", label: "SCIENCES HUMAINES ET SOCIALES : SCIENCES DE L'EDUCATION SPE CADRES ET CONSULTANTS EN FORMATION CONTINUE CPE-ESPE (MASTER PARIS 5)" },
  { value: "SCIENCES DE L'EDUCATION (MASTER RENNES 2)", label: "SCIENCES DE L'EDUCATION (MASTER RENNES 2)" },
  { value: "MANAGEMENT SECTORIEL (MASTER DIJON)", label: "MANAGEMENT SECTORIEL (MASTER DIJON)" },
  { value: "TOURISME (MASTER PARIS 1)", label: "TOURISME (MASTER PARIS 1)" },
  { value: "TOURISME (MASTER LA ROCHELLE)", label: "TOURISME (MASTER LA ROCHELLE)" },
  { value: "DROIT DE L'ENTREPRISE (MASTER LIMOGES)", label: "DROIT DE L'ENTREPRISE (MASTER LIMOGES)" },
  { value: "STAPS : ENTRAINEMENT ET OPTIMISATION DE LA PERFORMANCE SPORTIVE (MASTER DIJON)", label: "STAPS : ENTRAINEMENT ET OPTIMISATION DE LA PERFORMANCE SPORTIVE (MASTER DIJON)" },
  { value: "DROIT, ECONOMIE, GESTION : STAPS MANAGEMENT DU SPORT (MASTER MARNE LA VALLEE)", label: "DROIT, ECONOMIE, GESTION : STAPS MANAGEMENT DU SPORT (MASTER MARNE LA VALLEE)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : SCIENCES DU SPORT SPE SANTE PSYCHO SOCIALE PAR LE SPORT (MASTER PARIS 5)", label: "SCIENCES HUMAINES ET SOCIALES : SCIENCES DU SPORT SPE SANTE PSYCHO SOCIALE PAR LE SPORT (MASTER PARIS 5)" },
  { value: "STAPS:INGENIERIE ET ERGONOMIE DE L'ACTIVITE PHYSIQUE (MASTER ORLEANS)", label: "STAPS:INGENIERIE ET ERGONOMIE DE L'ACTIVITE PHYSIQUE (MASTER ORLEANS)" },
  { value: "STAPS : MANAGEMENT DU SPORT (MASTER BORDEAUX)", label: "STAPS : MANAGEMENT DU SPORT (MASTER BORDEAUX)" },
  { value: "STAPS : ACTIVITE PHYSIQUE ADAPTEE ET SANTE (MASTER MONTPELLIER)", label: "STAPS : ACTIVITE PHYSIQUE ADAPTEE ET SANTE (MASTER MONTPELLIER)" },
  { value: "SCIENCES ET TECHNIQUES DES ACTIVITES PHYSIQUES ET SPORTIVES : ACTIVITE PHYSIQUE ADAPTEE ET SANTE (COMUE PARIS SACLAY)", label: "SCIENCES ET TECHNIQUES DES ACTIVITES PHYSIQUES ET SPORTIVES : ACTIVITE PHYSIQUE ADAPTEE ET SANTE (COMUE PARIS SACLAY)" },
  { value: "ARTS, LETTRES, LANGUES : MEDIATION CULTURELLE SPE MUSEOLOGIE ET MEDIATION (MASTER PARIS 3)", label: "ARTS, LETTRES, LANGUES : MEDIATION CULTURELLE SPE MUSEOLOGIE ET MEDIATION (MASTER PARIS 3)" },
  { value: "STAPS:INGENIERIE ET ERGONOMIE DE L'ACTIVITE PHYSIQUE (MASTER CLERMONT AUVERGNE)", label: "STAPS:INGENIERIE ET ERGONOMIE DE L'ACTIVITE PHYSIQUE (MASTER CLERMONT AUVERGNE)" },
  { value: "ARTS, LETTRES, LANGUES : MEDIATION CULTURELLE SPE CONCEPTION ET DIRECTION DE PROJETS CULTURELS (MASTER PARIS 3)", label: "ARTS, LETTRES, LANGUES : MEDIATION CULTURELLE SPE CONCEPTION ET DIRECTION DE PROJETS CULTURELS (MASTER PARIS 3)" },
  { value: "STAPS:MANAGEMENT DU SPORT (MASTER DIJON)", label: "STAPS:MANAGEMENT DU SPORT (MASTER DIJON)" },
  { value: "STAPS:MANAGEMENT DU SPORT (MASTER PARIS EST CRETEIL)", label: "STAPS:MANAGEMENT DU SPORT (MASTER PARIS EST CRETEIL)" },
  { value: "SCIENCES SANTE ET APPLICATIONS : SCIENCES ET GENIE DE L'ENVIRONNEMENT SPE MANAGEMENT DE L'ENVIRONNEMENT DES COLLECTIVITES ET DES ENTREPRISES (MASTER PARIS 7)", label: "SCIENCES SANTE ET APPLICATIONS : SCIENCES ET GENIE DE L'ENVIRONNEMENT SPE MANAGEMENT DE L'ENVIRONNEMENT DES COLLECTIVITES ET DES ENTREPRISES (MASTER PARIS 7)" },
  { value: "DROIT, ECONOMIE, GESTION : SCIENCE POLITIQUE (MASTER PARIS 10)", label: "DROIT, ECONOMIE, GESTION : SCIENCE POLITIQUE (MASTER PARIS 10)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : GEOGRAPHIE, AMENAGEMENT, ENVIRONNEMENT (MASTER CERGY)", label: "SCIENCES HUMAINES ET SOCIALES : GEOGRAPHIE, AMENAGEMENT, ENVIRONNEMENT (MASTER CERGY)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : GEOPOLITIQUE (MASTER PARIS 8)", label: "SCIENCES HUMAINES ET SOCIALES : GEOPOLITIQUE (MASTER PARIS 8)" },
  { value: "RISQUES ET ENVIRONNEMENT (MASTER PARIS 1)", label: "RISQUES ET ENVIRONNEMENT (MASTER PARIS 1)" },
  { value: "RISQUES ET ENVIRONNEMENT (MASTER TROYES)", label: "RISQUES ET ENVIRONNEMENT (MASTER TROYES)" },
  { value: "LETTRES, LANGUES, SCIENCES HUMAINES ET SOCIALES : METIERS DES ETUDES, DU CONSEIL ET DE L'INTERVENTION (MECI) SPE PROJETS INFORMATIQUES ET STRATEGIE D'ENTREPRISE (MASTER PARIS 7)", label: "LETTRES, LANGUES, SCIENCES HUMAINES ET SOCIALES : METIERS DES ETUDES, DU CONSEIL ET DE L'INTERVENTION (MECI) SPE PROJETS INFORMATIQUES ET STRATEGIE D'ENTREPRISE (MASTER PARIS 7)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : URBANISME ET AMENAGEMENT (MASTER MARNE LA VALLEE)", label: "SCIENCES HUMAINES ET SOCIALES : URBANISME ET AMENAGEMENT (MASTER MARNE LA VALLEE)" },
  { value: "MANAGEMENT SECTORIEL (MASTER CORSE)", label: "MANAGEMENT SECTORIEL (MASTER CORSE)" },
  { value: "ECONOMIE APPLIQUEE (MASTER CORSE)", label: "ECONOMIE APPLIQUEE (MASTER CORSE)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : GESTION DES TERRITOIRES ET DEVELOPPEMENT LOCAL (MASTER PARIS SACLAY)", label: "SCIENCES HUMAINES ET SOCIALES : GESTION DES TERRITOIRES ET DEVELOPPEMENT LOCAL (MASTER PARIS SACLAY)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : URBANISME ET AMENAGEMENT (MASTER PARIS 12)", label: "SCIENCES HUMAINES ET SOCIALES : URBANISME ET AMENAGEMENT (MASTER PARIS 12)" },
  { value: "GESTION DES TERRITOIRES ET DEVELOPPEMENT LOCAL (MASTER LYON 3)", label: "GESTION DES TERRITOIRES ET DEVELOPPEMENT LOCAL (MASTER LYON 3)" },
  { value: "URBANISME ET AMENAGEMENT (MASTER GRENOBLE ALPES)", label: "URBANISME ET AMENAGEMENT (MASTER GRENOBLE ALPES)" },
  { value: "URBANISME ET AMENAGEMENT (MASTER BORDEAUX 3)", label: "URBANISME ET AMENAGEMENT (MASTER BORDEAUX 3)" },
  { value: "URBANISME ET AMENAGEMENT (MASTER AIX MARSEILLE)", label: "URBANISME ET AMENAGEMENT (MASTER AIX MARSEILLE)" },
  { value: "ARTS, LETTRES, LANGUES : MUSEOLOGIE, MUSEAO EXPOGRAPHIE (MASTER UNIV ARTOIS)", label: "ARTS, LETTRES, LANGUES : MUSEOLOGIE, MUSEAO EXPOGRAPHIE (MASTER UNIV ARTOIS)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DE L'ENVIRONNEMENT (MASTER VERSAILLES)", label: "DROIT, ECONOMIE, GESTION : GESTION DE L'ENVIRONNEMENT (MASTER VERSAILLES)" },
  { value: "SCIENCES, TECHNOLOGIE, SANTE : GENIE INDUSTRIEL (MASTER PARIS 12)", label: "SCIENCES, TECHNOLOGIE, SANTE : GENIE INDUSTRIEL (MASTER PARIS 12)" },
  { value: "SCIENCES ET TECHNOLOGIES : QUALITE, HYGIENE, SECURITE (MASTER VALENCIENNES)", label: "SCIENCES ET TECHNOLOGIES : QUALITE, HYGIENE, SECURITE (MASTER VALENCIENNES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES ET GENIE DE L'ENVIRONNEMENT SPE AIR (MASTER PRO PARIS 7)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES ET GENIE DE L'ENVIRONNEMENT SPE AIR (MASTER PRO PARIS 7)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DE LA TERRE, DES PLANETES, ENVIRONNEMENT (MASTER CERGY)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DE LA TERRE, DES PLANETES, ENVIRONNEMENT (MASTER CERGY)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DE L'UNIVERS, ENVIRONNEMENT, ECOLOGIE (MASTER PARIS 6)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DE L'UNIVERS, ENVIRONNEMENT, ECOLOGIE (MASTER PARIS 6)" },
  { value: "DROIT, ECONOMIE, GESTION : RISQUES ET ENVIRONNEMENT (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : RISQUES ET ENVIRONNEMENT (MASTER MONTPELLIER)" },
  { value: "SCIENCES DE L'ENVIRONNEMENT (MASTER LA ROCHELLE)", label: "SCIENCES DE L'ENVIRONNEMENT (MASTER LA ROCHELLE)" },
  { value: "RISQUES ET ENVIRONNEMENT (MASTER LYON 1)", label: "RISQUES ET ENVIRONNEMENT (MASTER LYON 1)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES ET GENIE DE L'ENVIRONNEMENT SPE SYSTEMES AQUATIQUES ET GESTION DE L'EAU (MASTER PARIS 7)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES ET GENIE DE L'ENVIRONNEMENT SPE SYSTEMES AQUATIQUES ET GESTION DE L'EAU (MASTER PARIS 7)" },
  { value: "GESTION DE L'ENVIRONNEMENT (MASTER LORRAINE)", label: "GESTION DE L'ENVIRONNEMENT (MASTER LORRAINE)" },
  { value: "GESTION DE L'ENVIRONNEMENT (MASTER CORSE)", label: "GESTION DE L'ENVIRONNEMENT (MASTER CORSE)" },
  { value: "GESTION DE L'ENVIRONNEMENT (MASTER COMUE COTE D'AZUR)", label: "GESTION DE L'ENVIRONNEMENT (MASTER COMUE COTE D'AZUR)" },
  { value: "RISQUES ET ENVIRONNEMENT (MASTER MULHOUSE)", label: "RISQUES ET ENVIRONNEMENT (MASTER MULHOUSE)" },
  { value: "RISQUES ET ENVIRONNEMENT (MASTER POITIERS)", label: "RISQUES ET ENVIRONNEMENT (MASTER POITIERS)" },
  { value: "SCIENCES, TECHNOLOGIE, SANTE : SANTE PUBLIQUE SPE MAINTENANCE, QUALITE, SECURITE, ENVIRONNEMENT (MASTER PARIS 13)", label: "SCIENCES, TECHNOLOGIE, SANTE : SANTE PUBLIQUE SPE MAINTENANCE, QUALITE, SECURITE, ENVIRONNEMENT (MASTER PARIS 13)" },
  { value: "DESIGN (DSAA)", label: "DESIGN (DSAA)" },
  { value: "DIPLOME GRADE MASTER DE SCIENCES PO : STRATEGIES TERRITORIALES ET URBAINES", label: "DIPLOME GRADE MASTER DE SCIENCES PO : STRATEGIES TERRITORIALES ET URBAINES" },
  { value: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : ECONOMIE INGENIERIE FINANCIERE", label: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : ECONOMIE INGENIERIE FINANCIERE" },
  { value: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : AFFAIRES INTERNATIONALES ET DEVELOPPEMENT", label: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : AFFAIRES INTERNATIONALES ET DEVELOPPEMENT" },
  { value: "DIPLOME GRADE MASTER DE SCIENCES PO : DROIT ECONOMIQUE", label: "DIPLOME GRADE MASTER DE SCIENCES PO : DROIT ECONOMIQUE" },
  { value: "DIPLOME DE L'INSTITUT D'ETUDES POLITIQUES DE LILLE", label: "DIPLOME DE L'INSTITUT D'ETUDES POLITIQUES DE LILLE" },
  { value: "DIPLOME GRADE MASTER DE SCIENCES PO : POLITIQUES PUBLIQUES", label: "DIPLOME GRADE MASTER DE SCIENCES PO : POLITIQUES PUBLIQUES" },
  { value: "DIPLOME GRADE MASTER DE SCIENCES PO : AFFAIRES EUROPPENNES", label: "DIPLOME GRADE MASTER DE SCIENCES PO : AFFAIRES EUROPPENNES" },
  { value: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : MANAGEMENT GENERAL", label: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : MANAGEMENT GENERAL" },
  { value: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : MANAGEMENT INTERNATIONAL", label: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : MANAGEMENT INTERNATIONAL" },
  { value: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : MANAGEMENT STRATEGIQUE ET CONSEIL/DIPLOME GRADE MASTER DE PARIS DAUPHINE : MANAGEMENT ET ORGANISATIONS", label: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : MANAGEMENT STRATEGIQUE ET CONSEIL/DIPLOME GRADE MASTER DE PARIS DAUPHINE : MANAGEMENT ET ORGANISATIONS" },
  { value: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : MANAGEMENT DES PROCESSUS DE PRODUCTION DE BIENS ET SERVICES", label: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : MANAGEMENT DES PROCESSUS DE PRODUCTION DE BIENS ET SERVICES" },
  { value: "DIPLOME GRADE MASTER DE SCIENCES PO : MARKETING ET ETUDES", label: "DIPLOME GRADE MASTER DE SCIENCES PO : MARKETING ET ETUDES" },
  { value: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : MARKETING ET STRATEGIE", label: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : MARKETING ET STRATEGIE" },
  { value: "DIPLOME GRADE MASTER DE SCIENCES PO : FINANCE ET STRATEGIE", label: "DIPLOME GRADE MASTER DE SCIENCES PO : FINANCE ET STRATEGIE" },
  { value: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : FINANCE", label: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : FINANCE" },
  { value: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : GESTION DE PATRIMOINE (BANQUE PRIVEE)", label: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : GESTION DE PATRIMOINE (BANQUE PRIVEE)" },
  { value: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : CONTROLE, AUDIT, REPORTING", label: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : CONTROLE, AUDIT, REPORTING" },
  { value: "DIPLOME GRADE MASTER DE SCIENCES PO : ORGANISATION ET MANAGEMENT DES RESSOURCES HUMAINES", label: "DIPLOME GRADE MASTER DE SCIENCES PO : ORGANISATION ET MANAGEMENT DES RESSOURCES HUMAINES" },
  { value: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : GESTION DES RESSOURCES HUMAINES", label: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : GESTION DES RESSOURCES HUMAINES" },
  { value: "DIPLOME GRADE MASTER DE SCIENCES PO : COMMUNICATION", label: "DIPLOME GRADE MASTER DE SCIENCES PO : COMMUNICATION" },
  { value: "DIPLOME GRADE MASTER DE SCIENCES PO : JOURNALISME", label: "DIPLOME GRADE MASTER DE SCIENCES PO : JOURNALISME" },
  { value: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : JOURNALISME", label: "DIPLOME GRADE MASTER DE PARIS DAUPHINE : JOURNALISME" },
  { value: "DIPLOME DE DESIGN DE L'ECOLE DE DESIGN NANTES ATLANTIQUE", label: "DIPLOME DE DESIGN DE L'ECOLE DE DESIGN NANTES ATLANTIQUE" },
  { value: "DIPLOME NATIONAL D'OENOLOGIE", label: "DIPLOME NATIONAL D'OENOLOGIE" },
  { value: "PAYSAGISTE (DPLG)", label: "PAYSAGISTE (DPLG)" },
  { value: "DIPLOME DE MANAGER MODE ET LUXE DE L'INSTITUT FRANCAIS DE LA MODE", label: "DIPLOME DE MANAGER MODE ET LUXE DE L'INSTITUT FRANCAIS DE LA MODE" },
  { value: "DIPLOME TEMA DE NEOMA", label: "DIPLOME TEMA DE NEOMA" },
  { value: "DIPLOME D'INGENIEUR D'AFFAIRES DE L'ESCT DE TOULON", label: "DIPLOME D'INGENIEUR D'AFFAIRES DE L'ESCT DE TOULON" },
  { value: "MANAGER EN INGENIERIE D'AFFAIRES INDUSTRIELLES (ESTA BELFORT)", label: "MANAGER EN INGENIERIE D'AFFAIRES INDUSTRIELLES (ESTA BELFORT)" },
  { value: "COMPTABILITE ET GESTION (DIPLÔME SUPERIEUR DE) (DSCG)", label: "COMPTABILITE ET GESTION (DIPLÔME SUPERIEUR DE) (DSCG)" },
  { value: "DIPLOME D'EXPERTISE COMPTABLE (DEC)", label: "DIPLOME D'EXPERTISE COMPTABLE (DEC)" },
  { value: "RESPONSABLE COMMUNICATION ET MEDIA, DIPLOME DE SCIENCES COM NANTES", label: "RESPONSABLE COMMUNICATION ET MEDIA, DIPLOME DE SCIENCES COM NANTES" },
  { value: "DIPLOME DE JOURNALISTE DU CENTRE DE FORMATION DES JOURNALISTES", label: "DIPLOME DE JOURNALISTE DU CENTRE DE FORMATION DES JOURNALISTES" },
  { value: "MASSEUR-KINESITHERAPEUTE (DIPLOME D'ETAT)", label: "MASSEUR-KINESITHERAPEUTE (DIPLOME D'ETAT)" },
  { value: "DIPLOME D'ETAT D'INGENIERIE SOCIALE (DEIS)", label: "DIPLOME D'ETAT D'INGENIERIE SOCIALE (DEIS)" },
  { value: "DIPLOME DE L'INSTITUT DE MANAGEMENT ET DE COMMUNICATION INTERCULTURELS DE PARIS (ISIT)", label: "DIPLOME DE L'INSTITUT DE MANAGEMENT ET DE COMMUNICATION INTERCULTURELS DE PARIS (ISIT)" },
  { value: "DIPLOME D'ETAT DE PAYSAGISTE", label: "DIPLOME D'ETAT DE PAYSAGISTE" },
  { value: "DIPLOME VISE ET GRADE NIVEAU MASTER DE L'ECOLE DE MANAGEMENT DE LYON", label: "DIPLOME VISE ET GRADE NIVEAU MASTER DE L'ECOLE DE MANAGEMENT DE LYON" },
  { value: "DIPLOME VISE ET GRADE NIVEAU MASTER DE L'ICN NANCY", label: "DIPLOME VISE ET GRADE NIVEAU MASTER DE L'ICN NANCY" },
  { value: "DIPLOME DE PARIS SCHOOL OF BUSINESS", label: "DIPLOME DE PARIS SCHOOL OF BUSINESS" },
  { value: "PROGRAMME GRANDE ECOLE DE L'ESCP EUROPE (MIM)", label: "PROGRAMME GRANDE ECOLE DE L'ESCP EUROPE (MIM)" },
  { value: "DIPLOME DE L'INSTITUT D'ECONOMIE SCIENTIFIQUE ET DE GESTION DE LILLE", label: "DIPLOME DE L'INSTITUT D'ECONOMIE SCIENTIFIQUE ET DE GESTION DE LILLE" },
  { value: "DIPLOME DE L'ECOLE SUPERIEURE DES SCIENCES ECONOMIQUES ET COMMERCIALES (ESSEC)", label: "DIPLOME DE L'ECOLE SUPERIEURE DES SCIENCES ECONOMIQUES ET COMMERCIALES (ESSEC)" },
  { value: "DIPLOME D'ETUDES SUPERIEURES DE GESTION (TELECOM MANAGEMENT)", label: "DIPLOME D'ETUDES SUPERIEURES DE GESTION (TELECOM MANAGEMENT)" },
  { value: "DIPLOME DE FORMATION SUPERIEURE AU MANAGEMENT DE L'IPAG", label: "DIPLOME DE FORMATION SUPERIEURE AU MANAGEMENT DE L'IPAG" },
  { value: "DIPLOME DE L'ECOLE DES DIRIGEANTS ET CREATEURS D'ENTREPRISE DE COURBEVOIE (EDC)", label: "DIPLOME DE L'ECOLE DES DIRIGEANTS ET CREATEURS D'ENTREPRISE DE COURBEVOIE (EDC)" },
  { value: "DIPLOME GRADE MASTER DE SCIENCES PO RENNES : MANAGEMENT STRATEGIQUE ET ORGANISATIONNEL", label: "DIPLOME GRADE MASTER DE SCIENCES PO RENNES : MANAGEMENT STRATEGIQUE ET ORGANISATIONNEL" },
  { value: "DIPLOME DE L'ECOLE DE MANAGEMENT LEONARD DE VINCI LA DEFENSE", label: "DIPLOME DE L'ECOLE DE MANAGEMENT LEONARD DE VINCI LA DEFENSE" },
  { value: "PROGRAMME GRANDE ECOLE - PGE KEDGE BUSINESS SCHOOL", label: "PROGRAMME GRANDE ECOLE - PGE KEDGE BUSINESS SCHOOL" },
  { value: "DIPLOME DE L'ECOLE DE MANAGEMENT DE STRASBOURG", label: "DIPLOME DE L'ECOLE DE MANAGEMENT DE STRASBOURG" },
  { value: "DIPLOME VISE ET GRADE NIVEAU MASTER DE L'ECOLE DES HAUTES ETUDES COMMERCIALES", label: "DIPLOME VISE ET GRADE NIVEAU MASTER DE L'ECOLE DES HAUTES ETUDES COMMERCIALES" },
  { value: "PROGRAMME GRANDE ECOLE DE L'ECOLE SUPERIEURE DE COMMERCE DE CLERMONT", label: "PROGRAMME GRANDE ECOLE DE L'ECOLE SUPERIEURE DE COMMERCE DE CLERMONT" },
  { value: "PROGRAMME GRANDE ECOLE DE L'ECOLE SUPERIEURE DE COMMERCE DE PAU", label: "PROGRAMME GRANDE ECOLE DE L'ECOLE SUPERIEURE DE COMMERCE DE PAU" },
  { value: "DIPLOME DE L'ECOLE AUDENCIA MANAGEMENT DE NANTES", label: "DIPLOME DE L'ECOLE AUDENCIA MANAGEMENT DE NANTES" },
  { value: "DIPLOME DE L'INSTITUT DES HAUTES ETUDES ECONOMIQUES ET COMMERCIALES PROGRAMME GRANDE ECOLE INSEEC BUSINESS SCHOOL", label: "DIPLOME DE L'INSTITUT DES HAUTES ETUDES ECONOMIQUES ET COMMERCIALES PROGRAMME GRANDE ECOLE INSEEC BUSINESS SCHOOL" },
  { value: "DIPLOME DE SKEMA - PGE", label: "DIPLOME DE SKEMA - PGE" },
  { value: "DIPLOME GRADE MASTER DE L'ECOLE SUPERIEURE DE COMMERCE DE LA ROCHELLE", label: "DIPLOME GRADE MASTER DE L'ECOLE SUPERIEURE DE COMMERCE DE LA ROCHELLE" },
  { value: "DIPLOME DE L'ECOLE SUPERIEURE DE COMMERCE DE DIJON - PGE", label: "DIPLOME DE L'ECOLE SUPERIEURE DE COMMERCE DE DIJON - PGE" },
  { value: "PROGRAMME GRANDE ECOLE DE L'ECOLE SUPERIEURE DE COMMERCE DE BREST", label: "PROGRAMME GRANDE ECOLE DE L'ECOLE SUPERIEURE DE COMMERCE DE BREST" },
  { value: "PROGRAMME GRANDE ECOLE DE L'ECOLE SUPERIEURE DE COMMERCE DE TOULOUSE", label: "PROGRAMME GRANDE ECOLE DE L'ECOLE SUPERIEURE DE COMMERCE DE TOULOUSE" },
  { value: "PROGRAMME GRANDE ECOLE DE L'ECOLE SUPERIEURE DE COMMERCE DE MONTPELLIER", label: "PROGRAMME GRANDE ECOLE DE L'ECOLE SUPERIEURE DE COMMERCE DE MONTPELLIER" },
  { value: "DIPLOME GRADE MASTER DE L'ECOLE SUPERIEURE DE COMMERCE DE GRENOBLE", label: "DIPLOME GRADE MASTER DE L'ECOLE SUPERIEURE DE COMMERCE DE GRENOBLE" },
  { value: "DIPLOME GRADE MASTER DE L'ECOLE SUPERIEURE DE COMMERCE DE TROYES", label: "DIPLOME GRADE MASTER DE L'ECOLE SUPERIEURE DE COMMERCE DE TROYES" },
  { value: "DIPLOME DE L'ECOLE DE MANAGEMENT DE NORMANDIE", label: "DIPLOME DE L'ECOLE DE MANAGEMENT DE NORMANDIE" },
  { value: "DIPLOME DE L'ECOLE SUPERIEURE DU COMMERCE EXTERIEUR DE PARIS", label: "DIPLOME DE L'ECOLE SUPERIEURE DU COMMERCE EXTERIEUR DE PARIS" },
  { value: "DIPLOME DE L'ECOLE SUPERIEURE DES SCIENCES COMMERCIALES D'ANGERS - PGE (ESSCA)", label: "DIPLOME DE L'ECOLE SUPERIEURE DES SCIENCES COMMERCIALES D'ANGERS - PGE (ESSCA)" },
  { value: "DIPLOME D'ETUDES SUPERIEURES EN ENTREPRENEURIAT ET MANAGEMENT COMMERCIAL DE PARIS (NOVANCIA)", label: "DIPLOME D'ETUDES SUPERIEURES EN ENTREPRENEURIAT ET MANAGEMENT COMMERCIAL DE PARIS (NOVANCIA)" },
  { value: "PROGRAMME GRANDE ECOLE DE L'EBS (ECOLE EUROPEENNE DE GESTION)", label: "PROGRAMME GRANDE ECOLE DE L'EBS (ECOLE EUROPEENNE DE GESTION)" },
  { value: "PROGRAMME GRANDE ECOLE DE L'INSTITUT SUPERIEUR DES SCIENCES TECHNIQUES ET ECONOMIE COMMERCIALES DE PARIS (ISTEC)", label: "PROGRAMME GRANDE ECOLE DE L'INSTITUT SUPERIEUR DES SCIENCES TECHNIQUES ET ECONOMIE COMMERCIALES DE PARIS (ISTEC)" },
  { value: "DIPLOME DE L'ECOLE SUPERIEURE DE COMMERCE DE RENNES - PGE", label: "DIPLOME DE L'ECOLE SUPERIEURE DE COMMERCE DE RENNES - PGE" },
  { value: "DIPLOME GRADE MASTER NEOMA", label: "DIPLOME GRADE MASTER NEOMA" },
  { value: "DIPLOME DE L'INSTITUT INTERNATIONAL DE COMMERCE ET DE DEVELOPPEMENT DE PARIS (ICD)", label: "DIPLOME DE L'INSTITUT INTERNATIONAL DE COMMERCE ET DE DEVELOPPEMENT DE PARIS (ICD)" },
  { value: "DIPLOME DE L'INSTITUT D'ETUDES POLITIQUE DE GRENOBLE (MANAGEMENT ET GESTION DES ENTREPRISES)", label: "DIPLOME DE L'INSTITUT D'ETUDES POLITIQUE DE GRENOBLE (MANAGEMENT ET GESTION DES ENTREPRISES)" },
  { value: "DIPLOME VISE ET GRADE NIVEAU MASTER DE L'ECOLE SUPERIEURE DE JOURNALISME DE LILLE", label: "DIPLOME VISE ET GRADE NIVEAU MASTER DE L'ECOLE SUPERIEURE DE JOURNALISME DE LILLE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE LILLE DE L'UNIVERSITE LILLE 1, SPECIALITE GENIE INFORMATIQUE ET MATHEMATIQUES APPLIQUEES", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE LILLE DE L'UNIVERSITE LILLE 1, SPECIALITE GENIE INFORMATIQUE ET MATHEMATIQUES APPLIQUEES" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS, SPECIALITE GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS, SPECIALITE GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE D'INGENIEUR DE L'UNIVERSITE DE TOULON, SPECIALITE MATERIAUX", label: "INGENIEUR DIPLOME DE L'ECOLE D'INGENIEUR DE L'UNIVERSITE DE TOULON, SPECIALITE MATERIAUX" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE LILLE DE L'UNIVERSITE LILLE 1, SPECIALITE PRODUCTION", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE LILLE DE L'UNIVERSITE LILLE 1, SPECIALITE PRODUCTION" },
  { value: "INGENIEUR DIPLOME DE L'UNIVERSITE DE DIJON, SPECIALITE GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'UNIVERSITE DE DIJON, SPECIALITE GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE LOUIS DE BROGLIE, SPECIALITE GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'ECOLE LOUIS DE BROGLIE, SPECIALITE GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE NANCY DE L'UNIVERSITE DE LORRAINE, SPECIALITE GENIE MECANIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE NANCY DE L'UNIVERSITE DE LORRAINE, SPECIALITE GENIE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES TECHNIQUES INDUSTRIELLES ET DES MINES D'ALBI-CARMAUX DE L'INSTITUT MINES TELECOM", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES TECHNIQUES INDUSTRIELLES ET DES MINES D'ALBI-CARMAUX DE L'INSTITUT MINES TELECOM" },
  { value: "INGENIEUR DIPLOME DU CENTRE UNIVERSITAIRE DES SCIENCES ET TECHNIQUES DE L'UNIVERSITE DE CLERMONT-FERRAND 2, SPECIALITE GENIE DES SYSTEMES DE PRODUCTION", label: "INGENIEUR DIPLOME DU CENTRE UNIVERSITAIRE DES SCIENCES ET TECHNIQUES DE L'UNIVERSITE DE CLERMONT-FERRAND 2, SPECIALITE GENIE DES SYSTEMES DE PRODUCTION" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR DE MECANIQUE DE PARIS, SPECIALITE GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR DE MECANIQUE DE PARIS, SPECIALITE GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE DE NANTES", label: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE DE NANTES" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS SUD-ALSACE DE L'UNIVERSITE DE MULHOUSE, SPECIALITE GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS SUD-ALSACE DE L'UNIVERSITE DE MULHOUSE, SPECIALITE GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS DE CAEN SPECIALITE MATERIAUX ET MECANIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS DE CAEN SPECIALITE MATERIAUX ET MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES INGENIEURS EN ARTS CHIMIQUES ET TECHNOLOGIQUES, DE L'INSTITUT NATIONAL POLYTECHNIQUE DE TOULOUSE SPECIALITE GENIE DES PROCEDES (ENSIACET)", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES INGENIEURS EN ARTS CHIMIQUES ET TECHNOLOGIQUES, DE L'INSTITUT NATIONAL POLYTECHNIQUE DE TOULOUSE SPECIALITE GENIE DES PROCEDES (ENSIACET)" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE DES MINES DE SAINT-ETIENNE, SPECIALITE GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE DES MINES DE SAINT-ETIENNE, SPECIALITE GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT FRANCAIS DE MECANIQUE AVANCEE, SPECIALITE MECANIQUE ET GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'INSTITUT FRANCAIS DE MECANIQUE AVANCEE, SPECIALITE MECANIQUE ET GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE DE NANTES, SPECIALITE GENIE ELECTRIQUE ET ENERGETIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE DE NANTES, SPECIALITE GENIE ELECTRIQUE ET ENERGETIQUE" },
  { value: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS, SPECIALITE MATERIAUX", label: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS, SPECIALITE MATERIAUX" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS, SPECIALITE ENVIRONNEMENT ET GESTION DES RISQUES", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS, SPECIALITE ENVIRONNEMENT ET GESTION DES RISQUES" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE DES ARTS ET MANUFACTURES", label: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE DES ARTS ET MANUFACTURES" },
  { value: "INGENIEUR DIPLOME DE L'UNIVERSITE PARIS 13, SPECIALITE ENERGETIQUE", label: "INGENIEUR DIPLOME DE L'UNIVERSITE PARIS 13, SPECIALITE ENERGETIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS LEONARD DE VINCI", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS LEONARD DE VINCI" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE ANGEVINE D'INFORMATIQUE ET DE PRODUCTIQUE, SPECIALITE SECURITE ET PREVENTION DES RISQUES", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE ANGEVINE D'INFORMATIQUE ET DE PRODUCTIQUE, SPECIALITE SECURITE ET PREVENTION DES RISQUES" },
  { value: "INGENIEUR DIPLOME DE L'UNIVERSITE DE TECHNOLOGIE DE TROYES, SPECIALITE MATERIAUX ET MECANIQUE", label: "INGENIEUR DIPLOME DE L'UNIVERSITE DE TECHNOLOGIE DE TROYES, SPECIALITE MATERIAUX ET MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE D'INGENIEURS DE L'UNIVERSITE DE CAEN, SPECIALITE GENIE DES SYSTEMES INDUSTRIELS", label: "INGENIEUR DIPLOME DE L'ECOLE D'INGENIEURS DE L'UNIVERSITE DE CAEN, SPECIALITE GENIE DES SYSTEMES INDUSTRIELS" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE DES HAUTES ETUDES D'INGENIEUR DU GROUPE HEI-ISA-ISEN", label: "INGENIEUR DIPLOME DE L'ECOLE DES HAUTES ETUDES D'INGENIEUR DU GROUPE HEI-ISA-ISEN" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT POLYTECHNIQUE DE GRENOBLE, ECOLE NATIONALE SUPERIEURE DE GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'INSTITUT POLYTECHNIQUE DE GRENOBLE, ECOLE NATIONALE SUPERIEURE DE GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE DE MARSEILLE", label: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE DE MARSEILLE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS DU MANS DE L'UNIVERSITE DU MANS, SPECIALITE VIBRATIONS, ACOUSTIQUE, CAPTEURS", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS DU MANS DE L'UNIVERSITE DU MANS, SPECIALITE VIBRATIONS, ACOUSTIQUE, CAPTEURS" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE ROUEN, SPECIALITE GENIE INDUSTRIEL, EN CONVENTION AVEC LE CESI", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE ROUEN, SPECIALITE GENIE INDUSTRIEL, EN CONVENTION AVEC LE CESI" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE RENNES SPECIALITE GENIE INDUSTRIEL EN CONVENTION AVEC LE CESI", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE RENNES SPECIALITE GENIE INDUSTRIEL EN CONVENTION AVEC LE CESI" },
  { value: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS, SPECIALITE METROLOGIE QUALITE", label: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS, SPECIALITE METROLOGIE QUALITE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS SPECIALITE MECANIQUE ET MECATRONIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS SPECIALITE MECANIQUE ET MECATRONIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES INGENIEURS EN ARTS CHIMIQUES ET TECHNOLOGIQUES, DE L'INSTITUT NATIONAL POLYTECHNIQUE DE TOULOUSE SPECIALITE GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES INGENIEURS EN ARTS CHIMIQUES ET TECHNOLOGIQUES, DE L'INSTITUT NATIONAL POLYTECHNIQUE DE TOULOUSE SPECIALITE GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES CENTRE VAL DE LOIRE SPECIALITE GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES CENTRE VAL DE LOIRE SPECIALITE GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE TECHNIQUES AVANCEES", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE TECHNIQUES AVANCEES" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES SCIENCES AGRONOMIQUES DE BORDEAUX", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES SCIENCES AGRONOMIQUES DE BORDEAUX" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'AGRONOMIQUE DE TOULOUSE, DE L'INSTITUT NATIONAL POLYTECHNIQUE DE TOULOUSE (ENSAT)", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'AGRONOMIQUE DE TOULOUSE, DE L'INSTITUT NATIONAL POLYTECHNIQUE DE TOULOUSE (ENSAT)" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR D'AGRICULTURE DE LILLE DU GROUPE HEI-ISA-ISEN", label: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR D'AGRICULTURE DE LILLE DU GROUPE HEI-ISA-ISEN" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT DES SCIENCES ET INDUSTRIES DU VIVANT ET DE L'ENVIRONNEMENT - AGRO PARIS TECH", label: "INGENIEUR DIPLOME DE L'INSTITUT DES SCIENCES ET INDUSTRIES DU VIVANT ET DE L'ENVIRONNEMENT - AGRO PARIS TECH" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR D'AGRICULTURE RHONE-ALPES", label: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR D'AGRICULTURE RHONE-ALPES" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE VETERINAIRE, AGROALIMENTAIRE ET DE L'ALIMENTATION, NANTES-ATLANTIQUE (ONIRIS)", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE VETERINAIRE, AGROALIMENTAIRE ET DE L'ALIMENTATION, NANTES-ATLANTIQUE (ONIRIS)" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR DES SCIENCES AGRONOMIQUES, AGROALIMENTAIRES, HORTICOLES ET DU PAYSAGE (AGRO CAMPUS OUEST), SPECIALITE AGROALIMENTAIRE", label: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR DES SCIENCES AGRONOMIQUES, AGROALIMENTAIRES, HORTICOLES ET DU PAYSAGE (AGRO CAMPUS OUEST), SPECIALITE AGROALIMENTAIRE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT POLYTECHNIQUE UNILASALLE, SPECIALITE AGRICULTURE", label: "INGENIEUR DIPLOME DE L'INSTITUT POLYTECHNIQUE UNILASALLE, SPECIALITE AGRICULTURE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT D'ENSEIGNEMENT SUPERIEUR ET DE RECHERCHE EN ALIMENTATION, SANTE ANIMALE, SCIENCES AGRONOMIQUES ET DE L'ENVIRONNEMENT ( VETAGRO SUP)", label: "INGENIEUR DIPLOME DE L'INSTITUT D'ENSEIGNEMENT SUPERIEUR ET DE RECHERCHE EN ALIMENTATION, SANTE ANIMALE, SCIENCES AGRONOMIQUES ET DE L'ENVIRONNEMENT ( VETAGRO SUP)" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL D'ETUDES SUPERIEURES AGRONOMIQUES DE MONTPELLIER, SPECIALITE AGRONOME A VOCATION GENERALE", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL D'ETUDES SUPERIEURES AGRONOMIQUES DE MONTPELLIER, SPECIALITE AGRONOME A VOCATION GENERALE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL D'ETUDES SUPERIEURES AGRONOMIQUES DE MONTPELLIER, SPECIALITE SYSTEMES AGRICOLES ET AGROALIMENTAIRES DURABLES AU SUD", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL D'ETUDES SUPERIEURES AGRONOMIQUES DE MONTPELLIER, SPECIALITE SYSTEMES AGRICOLES ET AGROALIMENTAIRES DURABLES AU SUD" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL SUPERIEUR DES SCIENCES AGRONOMIQUES, DE L'ALIMENTATION ET DE L'ENVIRONNEMENT, SPECIALITE AGROALIMENTAIRE", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL SUPERIEUR DES SCIENCES AGRONOMIQUES, DE L'ALIMENTATION ET DE L'ENVIRONNEMENT, SPECIALITE AGROALIMENTAIRE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE PARIS 11, SPECIALITE MATERIAUX", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE PARIS 11, SPECIALITE MATERIAUX" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE EUROPEENNE D'INGENIEUR EN GENIE DES MATERIAUX DE L'UNIVERSITE DE LORRAINE", label: "INGENIEUR DIPLOME DE L'ECOLE EUROPEENNE D'INGENIEUR EN GENIE DES MATERIAUX DE L'UNIVERSITE DE LORRAINE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES INGENIEURS EN ARTS CHIMIQUES ET TECHNOLOGIQUES, DE L'INSTITUT NATIONAL POLYTECHNIQUE DE TOULOUSE SPECIALITE MATERIAUX (ENSIACET)", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES INGENIEURS EN ARTS CHIMIQUES ET TECHNOLOGIQUES, DE L'INSTITUT NATIONAL POLYTECHNIQUE DE TOULOUSE SPECIALITE MATERIAUX (ENSIACET)" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE ROUEN, SPECIALITE GENIE DES PROCEDES", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE ROUEN, SPECIALITE GENIE DES PROCEDES" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS EN AGROALIMENTAIRE DE BRETAGNE ATLANTIQUE (ESIAB)", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS EN AGROALIMENTAIRE DE BRETAGNE ATLANTIQUE (ESIAB)" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT POLYTECHNIQUE LASALLE BEAUVAIS, SPECIALITE SCIENCES DE LA TERRE ET ENVIRONNEMENT", label: "INGENIEUR DIPLOME DE L'INSTITUT POLYTECHNIQUE LASALLE BEAUVAIS, SPECIALITE SCIENCES DE LA TERRE ET ENVIRONNEMENT" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE CHIMIE DE BIOLOGIE ET DE PHYSIQUE DE L'INSTITUT POLYTECHNIQUE DE BORDEAUX SPECIALITE AGROALIMENTAIRE ET GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE CHIMIE DE BIOLOGIE ET DE PHYSIQUE DE L'INSTITUT POLYTECHNIQUE DE BORDEAUX SPECIALITE AGROALIMENTAIRE ET GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE D'INGENIEURS DE L'UNIVERSITE DE CAEN, SPECIALITE AGROALIMENTAIRE", label: "INGENIEUR DIPLOME DE L'ECOLE D'INGENIEURS DE L'UNIVERSITE DE CAEN, SPECIALITE AGROALIMENTAIRE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'AGRONOMIE ET DES INDUSTRIES ALIMENTAIRES DE L'UNIVERSITE DE LORRAINE, SPECIALITE PRODUCTION AGROALIMENTAIRE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'AGRONOMIE ET DES INDUSTRIES ALIMENTAIRES DE L'UNIVERSITE DE LORRAINE, SPECIALITE PRODUCTION AGROALIMENTAIRE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT POLYTECHNIQUE DE BORDEAUX - ECOLE NATIONALE SUPERIEURE DE CHIMIE DE BIOLOGIE ET DE PHYSIQUE SPE MATERIAUX COMPOSITES ET MECANIQUE", label: "INGENIEUR DIPLOME DE L'INSTITUT POLYTECHNIQUE DE BORDEAUX - ECOLE NATIONALE SUPERIEURE DE CHIMIE DE BIOLOGIE ET DE PHYSIQUE SPE MATERIAUX COMPOSITES ET MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE DOUAI SPE PLASTURGIE ET MATERIAUX COMPOSITES", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE DOUAI SPE PLASTURGIE ET MATERIAUX COMPOSITES" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE CATHOLIQUE D'ARTS ET METIERS DE LYON SPECIALITE ENERGIE", label: "INGENIEUR DIPLOME DE L'ECOLE CATHOLIQUE D'ARTS ET METIERS DE LYON SPECIALITE ENERGIE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE DE LYON SPECIALITE ENERGIE", label: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE DE LYON SPECIALITE ENERGIE" },
  { value: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE GENIE NUCLEAIRE", label: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE GENIE NUCLEAIRE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE ROUEN, SPECIALITE GENIE ENERGETIQUE", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE ROUEN, SPECIALITE GENIE ENERGETIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE L'ENERGIE, L'EAU ET L'ENVIRONNEMENT DE L'INSTITUT POLYTECHNIQUE DE GRENOBLE SPECIALITE GENIE ELECTRIQUE ET ENERGETIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE L'ENERGIE, L'EAU ET L'ENVIRONNEMENT DE L'INSTITUT POLYTECHNIQUE DE GRENOBLE SPECIALITE GENIE ELECTRIQUE ET ENERGETIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE DES MINES DE SAINT-ETIENNE DE L'INSTITUT MINES-TELECOM SPE ENERGETIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE DES MINES DE SAINT-ETIENNE DE L'INSTITUT MINES-TELECOM SPE ENERGETIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE PARIS, EN CONVENTION AVEC LE CNAM, SPECIALITE ENERGETIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE PARIS, EN CONVENTION AVEC LE CNAM, SPECIALITE ENERGETIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE LILLE DE L'UNIVERSITE LILLE 1, SPECIALITE GENIE CIVIL", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE LILLE DE L'UNIVERSITE LILLE 1, SPECIALITE GENIE CIVIL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE D'INGENIEURS DE SAINT-ETIENNE, SPECIALITE GENIE CIVIL", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE D'INGENIEURS DE SAINT-ETIENNE, SPECIALITE GENIE CIVIL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURES D'INGENIEURS DE PARIS-EST DE L'UNIVERSITE DE MARNE LA VALLEE, SPECIALITE GENIE CIVIL", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURES D'INGENIEURS DE PARIS-EST DE L'UNIVERSITE DE MARNE LA VALLEE, SPECIALITE GENIE CIVIL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SPECIALE DES TRAVAUX PUBLICS, DU BATIMENT ET DE L'INDUSTRIE, SPECIALITE ENERGETIQUE DE LA CONSTRUCTION", label: "INGENIEUR DIPLOME DE L'ECOLE SPECIALE DES TRAVAUX PUBLICS, DU BATIMENT ET DE L'INDUSTRIE, SPECIALITE ENERGETIQUE DE LA CONSTRUCTION" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE DU BOIS", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE DU BOIS" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SPECIALE DE MECANIQUE ET D'ELECTRICITE (ESME)", label: "INGENIEUR DIPLOME DE L'ECOLE SPECIALE DE MECANIQUE ET D'ELECTRICITE (ESME)" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT DES SCIENCES ET TECHNIQUES DES YVELINES DE L'UNIVERSITE VERSAILLES-SAINT QUENTIN EN YVELINES, SPECIALITE MECATRONIQUE", label: "INGENIEUR DIPLOME DE L'INSTITUT DES SCIENCES ET TECHNIQUES DES YVELINES DE L'UNIVERSITE VERSAILLES-SAINT QUENTIN EN YVELINES, SPECIALITE MECATRONIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES INGENIEURS DES TECHNIQUES AVANCEES BRETAGNE, SPECIALITE MECANIQUE ET ELECTRONIQUE (ENSTA BREST)", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES INGENIEURS DES TECHNIQUES AVANCEES BRETAGNE, SPECIALITE MECANIQUE ET ELECTRONIQUE (ENSTA BREST)" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE D'INGENIEURS DE TARBES", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE D'INGENIEURS DE TARBES" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE D'INGENIEURS EN GENIE DES SYSTEMES INDUSTRIELS", label: "INGENIEUR DIPLOME DE L'ECOLE D'INGENIEURS EN GENIE DES SYSTEMES INDUSTRIELS" },
  { value: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE MECATRONIQUE", label: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE MECATRONIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES D'ALES DE L'INSTITUT MINES-TELECOM, SPECIALITE MECATRONIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES D'ALES DE L'INSTITUT MINES-TELECOM, SPECIALITE MECATRONIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR DE L'ELECTRONIQUE ET DU NUMERIQUE DE LILLE DU GROUPE HEI-ISA-ISEN", label: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR DE L'ELECTRONIQUE ET DU NUMERIQUE DE LILLE DU GROUPE HEI-ISA-ISEN" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE STRASBOURG, SPECIALITE MECATRONIQUE", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE STRASBOURG, SPECIALITE MECATRONIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ELECTRICITE ET DE MECANIQUE DE L'UNIVERSITE DE LORRAINE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ELECTRICITE ET DE MECANIQUE DE L'UNIVERSITE DE LORRAINE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE CATHOLIQUE D'ARTS ET METIERS DE LA FONDATION ECAM SPECIALITE GENIE INDUSTRIEL ET MECANIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE CATHOLIQUE D'ARTS ET METIERS DE LA FONDATION ECAM SPECIALITE GENIE INDUSTRIEL ET MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE TELECOM SAINT ETIENNE DE L'UNIVERSITE DE SAINT-ETIENNE SPECIALITE INFORMATIQUE ET ELECTRONIQUE", label: "INGENIEUR DIPLOME DE TELECOM SAINT ETIENNE DE L'UNIVERSITE DE SAINT-ETIENNE SPECIALITE INFORMATIQUE ET ELECTRONIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE D'INGENIEURS DE METZ, SPECIALITE MECANIQUE ET PRODUCTION", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE D'INGENIEURS DE METZ, SPECIALITE MECANIQUE ET PRODUCTION" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE MONTPELLIER, SPECIALITE MECANIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE MONTPELLIER, SPECIALITE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE MECANIQUE ET DES MICROTECHNIQUES, SPECIALITE MECANIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE MECANIQUE ET DES MICROTECHNIQUES, SPECIALITE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS EN INFORMATIQUE, AUTOMATIQUE, MECANIQUE, ENERGETIQUE ET ELECTRONIQUE DE L'UNIVERSITE DE VALENCIENNES, SPECIALITE GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS EN INFORMATIQUE, AUTOMATIQUE, MECANIQUE, ENERGETIQUE ET ELECTRONIQUE DE L'UNIVERSITE DE VALENCIENNES, SPECIALITE GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE PIERRE ET MARIE CURIE UNIVERSITE PARIS 6 SPECIALITE GENIE MECANIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE PIERRE ET MARIE CURIE UNIVERSITE PARIS 6 SPECIALITE GENIE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'UNIVERSITE DE TECHNOLOGIE DE COMPIEGNE SPE MECANIQUE", label: "INGENIEUR DIPLOME DE L'UNIVERSITE DE TECHNOLOGIE DE COMPIEGNE SPE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE LYON, SPECIALITE GENIE MECANIQUE", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE LYON, SPECIALITE GENIE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE D'INGENIEURS DE SAINT-ETIENNE SPECIALITE GENIE MECANIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE D'INGENIEURS DE SAINT-ETIENNE SPECIALITE GENIE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT CATHOLIQUE D'ARTS ET METIERS, SPECIALITE MECANIQUE ET AUTOMATIQUE", label: "INGENIEUR DIPLOME DE L'INSTITUT CATHOLIQUE D'ARTS ET METIERS, SPECIALITE MECANIQUE ET AUTOMATIQUE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE TOULOUSE SPE GENIE MECANIQUE", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE TOULOUSE SPE GENIE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'UNIVERSITE DE TECHNOLOGIE DE BELFORT-MONTBELIARD SPECIALITE MECANIQUE", label: "INGENIEUR DIPLOME DE L'UNIVERSITE DE TECHNOLOGIE DE BELFORT-MONTBELIARD SPECIALITE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS SPECIALITE GENIE MECANIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS SPECIALITE GENIE MECANIQUE" },
  { value: "INGENIEUR SPECIALISE EN MOTORISATIONS, DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DU PETROLE ET DES MOTEURS", label: "INGENIEUR SPECIALISE EN MOTORISATIONS, DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DU PETROLE ET DES MOTEURS" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR DE L'AUTOMOBILE ET DES TRANSPORTS DE NEVERS DE L'UNIVERSITE DE DIJON SPE GENIE MECANIQUE", label: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR DE L'AUTOMOBILE ET DES TRANSPORTS DE NEVERS DE L'UNIVERSITE DE DIJON SPE GENIE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE AERONAUTIQUE ET ESPACE", label: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE AERONAUTIQUE ET ESPACE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE D'ELECTRONIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE D'ELECTRONIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ENS D'ELECTROTECHNIQUE, D'ELECTRONIQUE, D'INFORMATIQUE, D'HYDRAULIQUE ET DES TELECOMMUNICATIONS, SPECIALITE ELECTRONIQUE ET GENIE ELECTRIQUE", label: "INGENIEUR DIPLOME DE L'ENS D'ELECTROTECHNIQUE, D'ELECTRONIQUE, D'INFORMATIQUE, D'HYDRAULIQUE ET DES TELECOMMUNICATIONS, SPECIALITE ELECTRONIQUE ET GENIE ELECTRIQUE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE LYON, SPECIALITE GENIE ELECTRIQUE", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE LYON, SPECIALITE GENIE ELECTRIQUE" },
  { value: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE GENIE ELECTRIQUE", label: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE GENIE ELECTRIQUE" },
  { value: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE SYSTEMES ELECTRONIQUES", label: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE SYSTEMES ELECTRONIQUES" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE STRASBOURG, SPECIALITE GENIE ELECTRIQUE", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE STRASBOURG, SPECIALITE GENIE ELECTRIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS EN ELECTRONIQUE ET ELECTROTECHNIQUE (ESIEE)", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS EN ELECTRONIQUE ET ELECTROTECHNIQUE (ESIEE)" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS SPECIALITE GENIE ELECTRIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS SPECIALITE GENIE ELECTRIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE DE NANTES, SPECIALITE ELECTRONIQUE ET TECHNOLOGIES NUMERIQUES", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE DE NANTES, SPECIALITE ELECTRONIQUE ET TECHNOLOGIES NUMERIQUES" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE SAINT-ETIENNE DE L'INSTITUT MINES-TELECOM SPE SYSTEMES ELECTRONIQUES EMBARQUES", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE SAINT-ETIENNE DE L'INSTITUT MINES-TELECOM SPE SYSTEMES ELECTRONIQUES EMBARQUES" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'ELECTRONIQUE DE L'OUEST", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'ELECTRONIQUE DE L'OUEST" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE RENNES SPECIALITE ELECTRONIQUE EN CONVENTION AVEC LE CESI", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE RENNES SPECIALITE ELECTRONIQUE EN CONVENTION AVEC LE CESI" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS DE CAEN, SPECIALITE ELECTRONIQUE ET PHYSIQUE APPLIQUEE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS DE CAEN, SPECIALITE ELECTRONIQUE ET PHYSIQUE APPLIQUEE" },
  { value: "INGENIEUR DIPLOME DE CENTRALESUPELEC", label: "INGENIEUR DIPLOME DE CENTRALESUPELEC" },
  { value: "INGENIEUR DIPLOME DE L'UNIVERSITE DE TECHNOLOGIE DE BELFORT-MONTBELIARD SPECIALITE LOGISTIQUE INDUSTRIELLE", label: "INGENIEUR DIPLOME DE L'UNIVERSITE DE TECHNOLOGIE DE BELFORT-MONTBELIARD SPECIALITE LOGISTIQUE INDUSTRIELLE" },
  { value: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE CIRCULATION FERROVIAIRE", label: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE CIRCULATION FERROVIAIRE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE DE L'AVIATION CIVILE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE DE L'AVIATION CIVILE" },
  { value: "INGENIEUR DIPLOME DE L'UNIVERSITE PARIS 13, SPECIALITE INFORMATIQUE", label: "INGENIEUR DIPLOME DE L'UNIVERSITE PARIS 13, SPECIALITE INFORMATIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE L'UNIVERSITE LYON I, SPECIALITE INFORMATIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE L'UNIVERSITE LYON I, SPECIALITE INFORMATIQUE" },
  { value: "INGENIEUR DIPLOME DE L'UNIVERSITE DE TECHNOLOGIE DE COMPIEGNE, SPECIALITE INFORMATIQUE", label: "INGENIEUR DIPLOME DE L'UNIVERSITE DE TECHNOLOGIE DE COMPIEGNE, SPECIALITE INFORMATIQUE" },
  { value: "INGENIEUR DIPLOME DE L'UNIVERSITE DE TECHNOLOGIE DE BELFORT-MONTBELIARD SPECIALITE INFORMATIQUE", label: "INGENIEUR DIPLOME DE L'UNIVERSITE DE TECHNOLOGIE DE BELFORT-MONTBELIARD SPECIALITE INFORMATIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS EN INFORMATIQUE ET GENIE DES TELECOMMUNICATIONS", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS EN INFORMATIQUE ET GENIE DES TELECOMMUNICATIONS" },
  { value: "INGENIEUR DIPLOME DE TELECOM NANCY DE L'UNIVERSITE DE LORRAINE", label: "INGENIEUR DIPLOME DE TELECOM NANCY DE L'UNIVERSITE DE LORRAINE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS DE BRETAGNE SUD SPECIALITE SECURITE DES SYSTEMES D'INFORMATION", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS DE BRETAGNE SUD SPECIALITE SECURITE DES SYSTEMES D'INFORMATION" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE LYON SPECIALITE TELECOMMUNICATIONS", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE LYON SPECIALITE TELECOMMUNICATIONS" },
  { value: "INGENIEUR DIPLOME DE TELECOM PHYSIQUE STRASBOURG DE L'UNIVERSITE DE STRASBOURG SPE TECHNOLOGIES DE L'INFORMATION ET DE LA COMMUNICATION POUR LA SANTE", label: "INGENIEUR DIPLOME DE TELECOM PHYSIQUE STRASBOURG DE L'UNIVERSITE DE STRASBOURG SPE TECHNOLOGIES DE L'INFORMATION ET DE LA COMMUNICATION POUR LA SANTE" },
  { value: "INGENIEUR DIPLOME TELECOM SUD PARIS DE L'INSTITUT MINES TELECOM SPECIALITE RESEAUX", label: "INGENIEUR DIPLOME TELECOM SUD PARIS DE L'INSTITUT MINES TELECOM SPECIALITE RESEAUX" },
  { value: "INGENIEUR DIPLOME DU CUFR NORD-EST MIDI-PYRENEES JEAN-FRANCOIS CHAMPOLLION, SPECIALITE INFORMATIQUE POUR LA SANTE", label: "INGENIEUR DIPLOME DU CUFR NORD-EST MIDI-PYRENEES JEAN-FRANCOIS CHAMPOLLION, SPECIALITE INFORMATIQUE POUR LA SANTE" },
  { value: "INGENIEUR DIPLOME DE L'UNIVERSITE PARIS 12 , SPECIALITE SYSTEMES D'INFORMATION", label: "INGENIEUR DIPLOME DE L'UNIVERSITE PARIS 12 , SPECIALITE SYSTEMES D'INFORMATION" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE LYON, SPECIALITE INFORMATIQUE", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE LYON, SPECIALITE INFORMATIQUE" },
  { value: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE INFORMATIQUE", label: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE INFORMATIQUE" },
  { value: "INGENIEUR DIPLOME DE TELECOM PHYSIQUE STRASBOURG DE L'UNIVERSITE DE STRASBOURG, SPECIALITE TECHNOLOGIE DE L'INFORMATION ET DE LA COMMUNICATION POUR LA SANTE", label: "INGENIEUR DIPLOME DE TELECOM PHYSIQUE STRASBOURG DE L'UNIVERSITE DE STRASBOURG, SPECIALITE TECHNOLOGIE DE L'INFORMATION ET DE LA COMMUNICATION POUR LA SANTE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEUR D'INGENIEURS DE PARIS EST-CRETEIL DE L'UNIVERSITE PARIS-XII, SPECIALITE GENIE BIOMEDICAL ET SANTE", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEUR D'INGENIEURS DE PARIS EST-CRETEIL DE L'UNIVERSITE PARIS-XII, SPECIALITE GENIE BIOMEDICAL ET SANTE" },
  { value: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE GESTION DES RISQUES", label: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE GESTION DES RISQUES" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE MONTPELLIER DE L'UNIVERSITE DE MONTPELLIER, SPECIALITE GENIE DE L'EAU", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE MONTPELLIER DE L'UNIVERSITE DE MONTPELLIER, SPECIALITE GENIE DE L'EAU" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES CENTRE VAL DE LOIRE SPECIALITE ENERGIE, RISQUES ET ENVIRONNEMENT", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES CENTRE VAL DE LOIRE SPECIALITE ENERGIE, RISQUES ET ENVIRONNEMENT" },
  { value: "SCIENCES ET TECHNOLOGIES (LIC LMD VALENCIENNES)", label: "SCIENCES ET TECHNOLOGIES (LIC LMD VALENCIENNES)" },
  { value: "SCIENCES ET TECHNOLOGIES (LIC LMD NICE)", label: "SCIENCES ET TECHNOLOGIES (LIC LMD NICE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : MATHEMATIQUES ET INFORMATIQUE APPLIQUEES AUX SCIENCES HUMAINES ET SOCIALES (LIC LMD PARIS 10)", label: "SCIENCES, TECHNOLOGIES, SANTE : MATHEMATIQUES ET INFORMATIQUE APPLIQUEES AUX SCIENCES HUMAINES ET SOCIALES (LIC LMD PARIS 10)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD PARIS 5)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD PARIS 5)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : MATHEMATIQUES ET INFORMATIQUE APPLIQUEES AUX SCIENCES HUMAINES ET SOCIALES (LIC LMD TOULOUSE 3)", label: "SCIENCES, TECHNOLOGIES, SANTE : MATHEMATIQUES ET INFORMATIQUE APPLIQUEES AUX SCIENCES HUMAINES ET SOCIALES (LIC LMD TOULOUSE 3)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (LIC LMD BORDEAUX)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (LIC LMD BORDEAUX)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DE LA VIE (LIC LMD BORDEAUX)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DE LA VIE (LIC LMD BORDEAUX)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DE LA VIE (LIC LMD PARIS 11)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES DE LA VIE (LIC LMD PARIS 11)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION (LIC LMD CNAM)", label: "DROIT, ECONOMIE, GESTION : GESTION (LIC LMD CNAM)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC LMD EVRY)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC LMD EVRY)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC LMD PARIS 5)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC LMD PARIS 5)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION (LIC LMD CAEN)", label: "DROIT, ECONOMIE, GESTION : GESTION (LIC LMD CAEN)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC PARIS 12)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC PARIS 12)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC LMD LA REUNION)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC LMD LA REUNION)" },
  { value: "ECONOMIE ET GESTION (LIC LMD AMIENS)", label: "ECONOMIE ET GESTION (LIC LMD AMIENS)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION (LIC LMD GRENOBLES ALPES)", label: "DROIT, ECONOMIE, GESTION : GESTION (LIC LMD GRENOBLES ALPES)" },
  { value: "ECONOMIE ET GESTION (LIC LMD NICE)", label: "ECONOMIE ET GESTION (LIC LMD NICE)" },
  { value: "SCIENCES SOCIALES (LIC LMD ANGERS)", label: "SCIENCES SOCIALES (LIC LMD ANGERS)" },
  { value: "DROIT, ECONOMIE, GESTION : ADMINISTRATION ECONOMIQUE ET SOCIALE (LIC LMD POITIERS)", label: "DROIT, ECONOMIE, GESTION : ADMINISTRATION ECONOMIQUE ET SOCIALE (LIC LMD POITIERS)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT (LIC LMD MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : DROIT (LIC LMD MONTPELLIER)" },
  { value: "DROIT : DROIT (LIC LMD PARIS 2)", label: "DROIT : DROIT (LIC LMD PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT (LIC LMD TOULOUSE 1)", label: "DROIT, ECONOMIE, GESTION : DROIT (LIC LMD TOULOUSE 1)" },
  { value: "ARTS, LETTRES, LANGUES : LETTRES (LIC LMD MARNE LA VALLEE)", label: "ARTS, LETTRES, LANGUES : LETTRES (LIC LMD MARNE LA VALLEE)" },
  { value: "ARTS, LETTRES, LANGUES : MUSICOLOGIE (LIC LMD EVRY)", label: "ARTS, LETTRES, LANGUES : MUSICOLOGIE (LIC LMD EVRY)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES (LIC LMD CERGY)", label: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES (LIC LMD CERGY)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD NANTES)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD NANTES)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD ROUEN)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD ROUEN)" },
  { value: "LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD LIMOGES)", label: "LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD LIMOGES)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD ANGERS)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD ANGERS)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD LE MANS)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD LE MANS)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD PARIS 3)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD PARIS 3)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES (LIC LMD PARIS 4)", label: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES (LIC LMD PARIS 4)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD VERSAILLES)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD VERSAILLES)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD PARIS 10)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD PARIS 10)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD CERGY)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD CERGY)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD PARIS 7)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD PARIS 7)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES (LIC LMD BORDEAUX 3)", label: "ARTS, LETTRES, LANGUES : LANGUES ETRANGERES APPLIQUEES (LIC LMD BORDEAUX 3)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD BORDEAUX 3)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD BORDEAUX 3)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD PAU)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD PAU)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD LORRAINE)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD LORRAINE)" },
  { value: "LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD CORSE)", label: "LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD CORSE)" },
  { value: "LANGUES ETRANGERES APPLIQUEES (LIC LMD CORSE)", label: "LANGUES ETRANGERES APPLIQUEES (LIC LMD CORSE)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD CAEN)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD CAEN)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD MULOUSE)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD MULOUSE)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD STRASBOURG)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD STRASBOURG)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD POTIERS)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD POTIERS)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD BESANCON)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD BESANCON)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD TOULOUSE 2)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD TOULOUSE 2)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD INU CHAMPOLLION)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD INU CHAMPOLLION)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD RENNES 2)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD RENNES 2)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD UNIV BRETAGNE OCCIDENTALE)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD UNIV BRETAGNE OCCIDENTALE)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD UNIV BRETAGNE SUD)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC LMD UNIV BRETAGNE SUD)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC MONTPELLIER 3)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES (LIC MONTPELLIER 3)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (LIC LMD VALENCIENNES)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (LIC LMD VALENCIENNES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (LIC LMD PARIS 12)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (LIC LMD PARIS 12)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (LIC LMD MARNE LA VALLEE)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (LIC LMD MARNE LA VALLEE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES ET TECHNOLOGIES (LIC LMD MONTPELLIER)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES ET TECHNOLOGIES (LIC LMD MONTPELLIER)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (LIC LMD EVRY)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (LIC LMD EVRY)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (LIC LMD PARIS 10)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (LIC LMD PARIS 10)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (LIC LMD CNAM)", label: "SCIENCES, TECHNOLOGIES, SANTE : SCIENCES POUR L'INGENIEUR (LIC LMD CNAM)" },
  { value: "SCIENCES POUR L'INGENIEUR (LIC LMD CORSE)", label: "SCIENCES POUR L'INGENIEUR (LIC LMD CORSE)" },
  { value: "GENIE CIVIL (LIC LMD CNAM)", label: "GENIE CIVIL (LIC LMD CNAM)" },
  { value: "SCIENCES, TECHNOLOGIE, SANTE : MECANIQUE (LIC LMD TOULOUSE 3)", label: "SCIENCES, TECHNOLOGIE, SANTE : MECANIQUE (LIC LMD TOULOUSE 3)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : ELECTRONIQUE,ENERGIE ELECTRIQUE, AUTOMATIQUE (LIC LMD PARIS 6)", label: "SCIENCES, TECHNOLOGIES, SANTE : ELECTRONIQUE,ENERGIE ELECTRIQUE, AUTOMATIQUE (LIC LMD PARIS 6)" },
  { value: "GESTION (LIC LMD VALENCIENNES)", label: "GESTION (LIC LMD VALENCIENNES)" },
  { value: "SCIENCES DES ORGANISATIONS ET DES MARCHES : GESTION (LIC LMD DAUPHINE)", label: "SCIENCES DES ORGANISATIONS ET DES MARCHES : GESTION (LIC LMD DAUPHINE)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION (LIC LMD PARIS 10)", label: "DROIT, ECONOMIE, GESTION : GESTION (LIC LMD PARIS 10)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC LMD VERSAILLES)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC LMD VERSAILLES)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION (LIC LMD LILLE2)", label: "DROIT, ECONOMIE, GESTION : GESTION (LIC LMD LILLE2)" },
  { value: "GESTION ET COMMERCE : SCIENCES DE GESTION (LIC LMD MULHOUSE)", label: "GESTION ET COMMERCE : SCIENCES DE GESTION (LIC LMD MULHOUSE)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION (LIC LMD MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : GESTION (LIC LMD MONTPELLIER)" },
  { value: "ECONOMIE,GESTION : SCIENCES DE GESTION (LIC LMD PARIS 2)", label: "ECONOMIE,GESTION : SCIENCES DE GESTION (LIC LMD PARIS 2)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION (LIC LMD LILLE 1)", label: "DROIT, ECONOMIE, GESTION : GESTION (LIC LMD LILLE 1)" },
  { value: "DROIT, ECONOMIE, GESTION : ADMINISTRATION PUBLIQUE (LIC LMD MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : ADMINISTRATION PUBLIQUE (LIC LMD MONTPELLIER)" },
  { value: "DROIT, ECONOMIE, GESTION : ADMINISTRATION PUBLIQUE (LIC LMD VALENCIENNE)", label: "DROIT, ECONOMIE, GESTION : ADMINISTRATION PUBLIQUE (LIC LMD VALENCIENNE)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC LMD PARIS 11)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC LMD PARIS 11)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC LMD MARNE LA VALLEE)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC LMD MARNE LA VALLEE)" },
  { value: "DROIT, ECONOMIE, GESTION : ADMINISTRATION ECONOMIQUE ET SOCIALE (LIC LMD EVRY)", label: "DROIT, ECONOMIE, GESTION : ADMINISTRATION ECONOMIQUE ET SOCIALE (LIC LMD EVRY)" },
  { value: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC LMD CNAM)", label: "DROIT, ECONOMIE, GESTION : ECONOMIE ET GESTION (LIC LMD CNAM)" },
  { value: "ECONOMIE ET GESTION (LIC LMD LE HAVRE)", label: "ECONOMIE ET GESTION (LIC LMD LE HAVRE)" },
  { value: "SCIENCES HUMAINES, SOCIALES ET GESTION : SCIENCES DE L'INFORMATION ET DE LA COMMUNICATION (UCO)", label: "SCIENCES HUMAINES, SOCIALES ET GESTION : SCIENCES DE L'INFORMATION ET DE LA COMMUNICATION (UCO)" },
  { value: "MATHEMATIQUES, INFORMATIQUE, DECISION ET ORGANISATION (MIDO) : INFORMATIQUE (LIC LMD DAUPHINE)", label: "MATHEMATIQUES, INFORMATIQUE, DECISION ET ORGANISATION (MIDO) : INFORMATIQUE (LIC LMD DAUPHINE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : MATHEMATIQUE ET INFORMATIQUE APPLIQUEES AUX SCIENCES HUMAINES ET SOCIALES (LIC LMD PARIS 1)", label: "SCIENCES, TECHNOLOGIES, SANTE : MATHEMATIQUE ET INFORMATIQUE APPLIQUEES AUX SCIENCES HUMAINES ET SOCIALES (LIC LMD PARIS 1)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD EVRY)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD EVRY)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD PARIS 11)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD PARIS 11)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD CNAM)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD CNAM)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC PARIS 6)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC PARIS 6)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD PARIS 12)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD PARIS 12)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD TOULOUSE 3)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD TOULOUSE 3)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD MARNE LA VALLEE)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD MARNE LA VALLEE)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD U DU LITTORAL)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD U DU LITTORAL)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD NANTES)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD NANTES)" },
  { value: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD ANTILLES)", label: "SCIENCES, TECHNOLOGIES, SANTE : INFORMATIQUE (LIC LMD ANTILLES)" },
  { value: "INFORMATIQUE (LIC LMD PARIS 8)", label: "INFORMATIQUE (LIC LMD PARIS 8)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : SCIENCES DE L'EDUCATION ( LIC LMD PARIS 12)", label: "SCIENCES HUMAINES ET SOCIALES : SCIENCES DE L'EDUCATION ( LIC LMD PARIS 12)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : SCIENCES DE L'EDUCATION (LIC LMD PARIS 8)", label: "SCIENCES HUMAINES ET SOCIALES : SCIENCES DE L'EDUCATION (LIC LMD PARIS 8)" },
  { value: "SCIENCES POUR LA SANTE (LIC LMD LORRAINE)", label: "SCIENCES POUR LA SANTE (LIC LMD LORRAINE)" },
  { value: "SCIENCES ET TECHNIQUES DES ACTIVITES PHYSIQUES ET SPORTIVES (LIC LMD DIJON)", label: "SCIENCES ET TECHNIQUES DES ACTIVITES PHYSIQUES ET SPORTIVES (LIC LMD DIJON)" },
  { value: "SCIENCES HUMAINES ET SOCIALES : STAPS (SCIENCES ET TECHNIQUES DES ACTIVITES PHYSIQUES ET SPORTIVES) (LIC LMD PARIS 5)", label: "SCIENCES HUMAINES ET SOCIALES : STAPS (SCIENCES ET TECHNIQUES DES ACTIVITES PHYSIQUES ET SPORTIVES) (LIC LMD PARIS 5)" },
  { value: "SCIENCES ET TECHNIQUES DES ACTIVITES PHYSIQUES ET SPORTIVES (LIC LMD LIMOGES)", label: "SCIENCES ET TECHNIQUES DES ACTIVITES PHYSIQUES ET SPORTIVES (LIC LMD LIMOGES)" },
  { value: "SCIENCES ET TECHNIQUES DES ACTIVITES PHYSIQUES ET SPORTIVES (LIC LMD PARIS 11)", label: "SCIENCES ET TECHNIQUES DES ACTIVITES PHYSIQUES ET SPORTIVES (LIC LMD PARIS 11)" },
  { value: "DIPLOME D'ETAT SUPERIEUR DE LA JEUNESSE, DE L'EDUCATION POPULAIRE ET DU SPORT SPE ANIMATION SOCIO-EDUCATIVE OU CULTURELLE (DES JEPS)", label: "DIPLOME D'ETAT SUPERIEUR DE LA JEUNESSE, DE L'EDUCATION POPULAIRE ET DU SPORT SPE ANIMATION SOCIO-EDUCATIVE OU CULTURELLE (DES JEPS)" },
  { value: "DIPLOME D'ETAT SUPERIEUR DE LA JEUNESSE, DE L'EDUCATION POPULAIRE ET DU SPORT SPE PERFORMANCE SPORTIVE (DES JEPS)", label: "DIPLOME D'ETAT SUPERIEUR DE LA JEUNESSE, DE L'EDUCATION POPULAIRE ET DU SPORT SPE PERFORMANCE SPORTIVE (DES JEPS)" },
  { value: "RESPONSABLE CONCEPTION, MISE EN PLACE ET MAINTENANCE DES INSTALLATIONS FRIGORIFIQUES ET CLIMATIQUES (CNAM IFFFI)", label: "RESPONSABLE CONCEPTION, MISE EN PLACE ET MAINTENANCE DES INSTALLATIONS FRIGORIFIQUES ET CLIMATIQUES (CNAM IFFFI)" },
  { value: "RESPONSABLE EN GESTION (CNAM)", label: "RESPONSABLE EN GESTION (CNAM)" },
  { value: "RESPONSABLE DE PROGRAMME IMMOBILIER (CNAM)", label: "RESPONSABLE DE PROGRAMME IMMOBILIER (CNAM)" },
  { value: "RESPONSABLE RESSOURCES HUMAINES (CNAM)", label: "RESPONSABLE RESSOURCES HUMAINES (CNAM)" },
  { value: "CONCEPTEUR EN ARCHITECTURE INFORMATIQUE (CNAM)", label: "CONCEPTEUR EN ARCHITECTURE INFORMATIQUE (CNAM)" },
  { value: "CHIMIE ET PHYSIQUE DES MATERIAUX (LP PARIS 12)", label: "CHIMIE ET PHYSIQUE DES MATERIAUX (LP PARIS 12)" },
  { value: "CHIMIE ET PHYSIQUE DES MATERIAUX (LP LIMOGES)", label: "CHIMIE ET PHYSIQUE DES MATERIAUX (LP LIMOGES)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP ARTOIS)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP ARTOIS)" },
  { value: "BIOLOGIE ANALYTIQUE ET EXPERIMENTALE (LP CERGY)", label: "BIOLOGIE ANALYTIQUE ET EXPERIMENTALE (LP CERGY)" },
  { value: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP LYON 2)", label: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP LYON 2)" },
  { value: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP LORRAINE)", label: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP LORRAINE)" },
  { value: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (PARIS 5)", label: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (PARIS 5)" },
  { value: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP CAEN)", label: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP CAEN)" },
  { value: "ACOUSTIQUE ET VIBRATIONS (LP LE MANS)", label: "ACOUSTIQUE ET VIBRATIONS (LP LE MANS)" },
  { value: "BIOLOGIE ANALYTIQUE EXPERIMENTALE (LP TOURS)", label: "BIOLOGIE ANALYTIQUE EXPERIMENTALE (LP TOURS)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP CNAM)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP CNAM)" },
  { value: "BIOTECHNOLOGIES SPE BIOPHOTONIQUE (LP PARIS 7)", label: "BIOTECHNOLOGIES SPE BIOPHOTONIQUE (LP PARIS 7)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP LYON 1)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP LYON 1)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP MULHOUSE)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP MULHOUSE)" },
  { value: "BIOLOGIE ANALYTIQUE ET EXPERIMENTALE (LP ANGERS)", label: "BIOLOGIE ANALYTIQUE ET EXPERIMENTALE (LP ANGERS)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP BESANCON)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP BESANCON)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP AMIENS)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP AMIENS)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP DIJON)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP DIJON)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP NICE)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP NICE)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP TOURS)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP TOURS)" },
  { value: "BIOLOGIE ANALYTIQUE ET EXPERIMENTALE (LP CLERMONT AUVERGNE)", label: "BIOLOGIE ANALYTIQUE ET EXPERIMENTALE (LP CLERMONT AUVERGNE)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP NANTES)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP NANTES)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP PAU)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP PAU)" },
  { value: "ACTIVITES JURIDIQUES : METIERS DU DROIT DE L'IMMOBILIER (LP PERPIGNAN)", label: "ACTIVITES JURIDIQUES : METIERS DU DROIT DE L'IMMOBILIER (LP PERPIGNAN)" },
  { value: "ACTIVITES JURIDIQUES : METIERS DU DROIT PRIVE (LP VALENCIENNES)", label: "ACTIVITES JURIDIQUES : METIERS DU DROIT PRIVE (LP VALENCIENNES)" },
  { value: "ACTIVITES JURIDIQUES : MARCHES PUBLICS-METIERS DE L'ACHAT PUBLIC (LP ORLEANS)", label: "ACTIVITES JURIDIQUES : MARCHES PUBLICS-METIERS DE L'ACHAT PUBLIC (LP ORLEANS)" },
  { value: "ACTIVITES JURIDIQUES : ASSISTANT JURIDIQUE (LP MONTPELLIER)", label: "ACTIVITES JURIDIQUES : ASSISTANT JURIDIQUE (LP MONTPELLIER)" },
  { value: "ACTIVITES JURIDIQUES : METIERS DU DROIT PRIVE (LP PERPIGNAN)", label: "ACTIVITES JURIDIQUES : METIERS DU DROIT PRIVE (LP PERPIGNAN)" },
  { value: "ACTIVITES JURIDIQUES : MARCHES PUBLICS-METIERS DE L'ACHAT PUBLIC (LP PARIS 13)", label: "ACTIVITES JURIDIQUES : MARCHES PUBLICS-METIERS DE L'ACHAT PUBLIC (LP PARIS 13)" },
  { value: "ACTIVITES JURIDIQUES : METIERS DU DROIT SOCIAL (LP LORRAINE)", label: "ACTIVITES JURIDIQUES : METIERS DU DROIT SOCIAL (LP LORRAINE)" },
  { value: "GUIDE CONFERENCIER (LP MARNE LA VALLEE)", label: "GUIDE CONFERENCIER (LP MARNE LA VALLEE)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP CERGY)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP CERGY)" },
  { value: "GESTION DE LA PRODUCTION INDUSTRIELLE SPE METROLOGIE, QUALITE ET SURETE INDUSTRIELLE (LP PARIS 7)", label: "GESTION DE LA PRODUCTION INDUSTRIELLE SPE METROLOGIE, QUALITE ET SURETE INDUSTRIELLE (LP PARIS 7)" },
  { value: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP ORLEANS)", label: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP ORLEANS)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP AMIENS)", label: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP AMIENS)" },
  { value: "MECANIQUE SPE METIERS DE LA CONCEPTION ET DE LA FABRICATION (LP PARIS 13)", label: "MECANIQUE SPE METIERS DE LA CONCEPTION ET DE LA FABRICATION (LP PARIS 13)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP TOULOUSE 3)", label: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP TOULOUSE 3)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP VALENCIENNES)", label: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP VALENCIENNES)" },
  { value: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP ARTOIS)", label: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP ARTOIS)" },
  { value: "TECHNICO-COMMERCIAL (LP LE HAVRE)", label: "TECHNICO-COMMERCIAL (LP LE HAVRE)" },
  { value: "CONCEPTION DE PRODUITS INDUSTRIELS (LP VERSAILLES)", label: "CONCEPTION DE PRODUITS INDUSTRIELS (LP VERSAILLES)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP ORLEANS)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP ORLEANS)" },
  { value: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP AIX MARSEILLE)", label: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP AIX MARSEILLE)" },
  { value: "MAINTENANCE DES SYSTEMES INDUSTRIELS, DE PRODUCTION ET D'ENERGIE (LP CAEN)", label: "MAINTENANCE DES SYSTEMES INDUSTRIELS, DE PRODUCTION ET D'ENERGIE (LP CAEN)" },
  { value: "GESTION DE LA PRODUCTION INDUSTRIELLE SPE COORDONNATEUR DES AMELIORATIONS DES PROCESSUS D'ENTREPRISE (LP PARIS 8)", label: "GESTION DE LA PRODUCTION INDUSTRIELLE SPE COORDONNATEUR DES AMELIORATIONS DES PROCESSUS D'ENTREPRISE (LP PARIS 8)" },
  { value: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP ORLEANS)", label: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP ORLEANS)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP AMIENS)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP AMIENS)" },
  { value: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP NANTES)", label: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP NANTES)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP PARIS 11)", label: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP PARIS 11)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP TOULOUSE 2)", label: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP TOULOUSE 2)" },
  { value: "MAINTENANCE DES SYSTEMES INDUSTRIELS? DE PRODUCTION ET D'ENERGIE (LP MARNE LA VALLEE)", label: "MAINTENANCE DES SYSTEMES INDUSTRIELS? DE PRODUCTION ET D'ENERGIE (LP MARNE LA VALLEE)" },
  { value: "METIERS DE LA MODE (LP MARNE LA VALLEE)", label: "METIERS DE LA MODE (LP MARNE LA VALLEE)" },
  { value: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP LYON 2)", label: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP LYON 2)" },
  { value: "LOGISTIQUE ET SYSTEMES D'INFORMATION (LP LYON 2)", label: "LOGISTIQUE ET SYSTEMES D'INFORMATION (LP LYON 2)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP LYON 2)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP LYON 2)" },
  { value: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP CERGY)", label: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP CERGY)" },
  { value: "LOGISTIQUE ET PILOTAGE DES FLUX (LP BESANCON)", label: "LOGISTIQUE ET PILOTAGE DES FLUX (LP BESANCON)" },
  { value: "METIERS DU DESIGN (LP CHAMBERY)", label: "METIERS DU DESIGN (LP CHAMBERY)" },
  { value: "LOGISTIQUE ET PILOTAGE DES FLUX (LP STRASBOURG)", label: "LOGISTIQUE ET PILOTAGE DES FLUX (LP STRASBOURG)" },
  { value: "METIERS DE L'EMBALLAGE ET DU CONDITIONNEMENT (LP ROUEN)", label: "METIERS DE L'EMBALLAGE ET DU CONDITIONNEMENT (LP ROUEN)" },
  { value: "METIERS DE LA QUALITE (LP TOULOUSE 1)", label: "METIERS DE LA QUALITE (LP TOULOUSE 1)" },
  { value: "METIERS DE LA QUALITE (LP MULHOUSE)", label: "METIERS DE LA QUALITE (LP MULHOUSE)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : CONTROLE INDUSTRIEL (LP STRASBOURG)", label: "MAINTENANCE ET TECHNOLOGIE : CONTROLE INDUSTRIEL (LP STRASBOURG)" },
  { value: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP ORLEANS)", label: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP ORLEANS)" },
  { value: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP PARIS 11)", label: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP PARIS 11)" },
  { value: "METIERS DU DESIGN (LP NANTES)", label: "METIERS DU DESIGN (LP NANTES)" },
  { value: "TECHNICO-COMMERCIAL (LP LORRAINE)", label: "TECHNICO-COMMERCIAL (LP LORRAINE)" },
  { value: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP TOURS)", label: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP TOURS)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP REIMS)", label: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP REIMS)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP STRASBOURG)", label: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP STRASBOURG)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP CNAM)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP CNAM)" },
  { value: "TECHNICO-COMMERCIAL (LP LA REUNION)", label: "TECHNICO-COMMERCIAL (LP LA REUNION)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : ORGANISATION DE LA MAINTENANCE (LP MONTPELLIER)", label: "MAINTENANCE ET TECHNOLOGIE : ORGANISATION DE LA MAINTENANCE (LP MONTPELLIER)" },
  { value: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP LIMOGES)", label: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP LIMOGES)" },
  { value: "METIERS DU DESIGN (LP MARNE LA VALLEE)", label: "METIERS DU DESIGN (LP MARNE LA VALLEE)" },
  { value: "GESTION DES RISQUES INDUSTRIELS ET TECHNOLOGIQUES (LP LORRAINE)", label: "GESTION DES RISQUES INDUSTRIELS ET TECHNOLOGIQUES (LP LORRAINE)" },
  { value: "MAINTENANCE DES SYSTEMES INDUSTRIELS, DE PRODUCTION ET D'ENERGIE (LP LORRAINE)", label: "MAINTENANCE DES SYSTEMES INDUSTRIELS, DE PRODUCTION ET D'ENERGIE (LP LORRAINE)" },
  { value: "METIERS DU DESIGN (LP PAU)", label: "METIERS DU DESIGN (LP PAU)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP BESANCON)", label: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP BESANCON)" },
  { value: "MAINTENANCE TECHNO SYSTM PLURITEC (LP LIMOGES)", label: "MAINTENANCE TECHNO SYSTM PLURITEC (LP LIMOGES)" },
  { value: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP ORLEANS)", label: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP ORLEANS)" },
  { value: "METIERS DE L'INDUSTRIE : INDUSTRIE NAVALE ET MARITIME (LP NANTES)", label: "METIERS DE L'INDUSTRIE : INDUSTRIE NAVALE ET MARITIME (LP NANTES)" },
  { value: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP NANTES)", label: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP NANTES)" },
  { value: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP STRASBOURG)", label: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP STRASBOURG)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP CORSE)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP CORSE)" },
  { value: "CONCEPTION DE PDTS INDUSTRIELS (LP POITIERS)", label: "CONCEPTION DE PDTS INDUSTRIELS (LP POITIERS)" },
  { value: "GESTION DE LA PRODUCTION INDUSTRIELLE SPE MANAGEMENT ET INGENIERIE DES SYSTEMES MULTITECHNIQUES (LP PARIS 13)", label: "GESTION DE LA PRODUCTION INDUSTRIELLE SPE MANAGEMENT ET INGENIERIE DES SYSTEMES MULTITECHNIQUES (LP PARIS 13)" },
  { value: "PRODUCTION INDUSTRIELLE SPE METROLOGIE DIMENSIONNELLE, QUALITE DE LA PRODUCTION EN MECANIQUE (LP PARIS13)", label: "PRODUCTION INDUSTRIELLE SPE METROLOGIE DIMENSIONNELLE, QUALITE DE LA PRODUCTION EN MECANIQUE (LP PARIS13)" },
  { value: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP PARIS 10)", label: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP PARIS 10)" },
  { value: "SYSTEMES AUTOMATISES : RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP SAINT ETIENNE)", label: "SYSTEMES AUTOMATISES : RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP SAINT ETIENNE)" },
  { value: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP AMIENS)", label: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP AMIENS)" },
  { value: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP MULHOUSE)", label: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP MULHOUSE)" },
  { value: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP EVRY)", label: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP EVRY)" },
  { value: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP LORRAINE)", label: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP LORRAINE)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP GRENOBLE ALPES)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP GRENOBLE ALPES)" },
  { value: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP ORLEANS)", label: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP ORLEANS)" },
  { value: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP BESANCON)", label: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP BESANCON)" },
  { value: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP BESANCON)", label: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP BESANCON)" },
  { value: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP TOURS)", label: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP TOURS)" },
  { value: "ANALYSE, QUALITE ET CONTROLE DES MATERIAUX PRODUITS (LP TOURS)", label: "ANALYSE, QUALITE ET CONTROLE DES MATERIAUX PRODUITS (LP TOURS)" },
  { value: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP PARIS 11)", label: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP PARIS 11)" },
  { value: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP NANTES)", label: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP NANTES)" },
  { value: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP LORRAINE)", label: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP LORRAINE)" },
  { value: "ELECTRICITE ET ELECTRONIQUE SPE CONCEPTEUR EN SYSTEMES NUMERIQUES PROGRAMMABLES EMBARQUES (LP COSYPE CNAM)", label: "ELECTRICITE ET ELECTRONIQUE SPE CONCEPTEUR EN SYSTEMES NUMERIQUES PROGRAMMABLES EMBARQUES (LP COSYPE CNAM)" },
  { value: "CONCEPTION ET CONTROLE DES PROCEDES (LP LORRAINE)", label: "CONCEPTION ET CONTROLE DES PROCEDES (LP LORRAINE)" },
  { value: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP CAEN)", label: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP CAEN)" },
  { value: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (MASTER AMIENS)", label: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (MASTER AMIENS)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP LE MANS)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP LE MANS)" },
  { value: "DOMOTIQUE (LP EVRY)", label: "DOMOTIQUE (LP EVRY)" },
  { value: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP NANTES)", label: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP NANTES)" },
  { value: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP PARIS 11)", label: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP PARIS 11)" },
  { value: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (CNAM)", label: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (CNAM)" },
  { value: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP CLERMONT AUVERGNE)", label: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP CLERMONT AUVERGNE)" },
  { value: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP DIJON)", label: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP DIJON)" },
  { value: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP STRASBOURG)", label: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP STRASBOURG)" },
  { value: "AGRONOMIE (LP AMIENS)", label: "AGRONOMIE (LP AMIENS)" },
  { value: "GESTION DES ORGANISATIONS AGRICOLES ET AGROALIMENTAIRES (LP CAEN)", label: "GESTION DES ORGANISATIONS AGRICOLES ET AGROALIMENTAIRES (LP CAEN)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP PARIS 12)", label: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP PARIS 12)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP DIJON)", label: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP DIJON)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP ARTOIS)", label: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP ARTOIS)" },
  { value: "AGRONOMIE (LP POITIERS)", label: "AGRONOMIE (LP POITIERS)" },
  { value: "AGRONOMIE (LP LA REUNION)", label: "AGRONOMIE (LP LA REUNION)" },
  { value: "AGRONOMIE (LP GRENOBLE ALPES)", label: "AGRONOMIE (LP GRENOBLE ALPES)" },
  { value: "AGRONOMIE (LP LORRAINE)", label: "AGRONOMIE (LP LORRAINE)" },
  { value: "GESTION DES ORGANISATIONS AGRICOLES ET AGROALIMENTAIRES (LP AMIENS)", label: "GESTION DES ORGANISATIONS AGRICOLES ET AGROALIMENTAIRES (LP AMIENS)" },
  { value: "AGRICULTURE BIOLOGIQUE : PRODUCTION, CONSEIL, CERTIFICATION ET COMMERCIALISATION (LP ANGERS)", label: "AGRICULTURE BIOLOGIQUE : PRODUCTION, CONSEIL, CERTIFICATION ET COMMERCIALISATION (LP ANGERS)" },
  { value: "GESTION DES ENTREPRISES AGRICOLES ET AGROALIMENTAIRES (LP ANGERS)", label: "GESTION DES ENTREPRISES AGRICOLES ET AGROALIMENTAIRES (LP ANGERS)" },
  { value: "GESTION DES ORGANISATIONS AGRICOLES ET AGROALIMENTAIRES (LP ORLEANS)", label: "GESTION DES ORGANISATIONS AGRICOLES ET AGROALIMENTAIRES (LP ORLEANS)" },
  { value: "AGRONOMIE (LP MONTPELLIER)", label: "AGRONOMIE (LP MONTPELLIER)" },
  { value: "AGRONOMIE (LP TOULOUSE 3)", label: "AGRONOMIE (LP TOULOUSE 3)" },
  { value: "AGRICULTURE BIOLOGIQUE : PRODUCTION, CONSEIL, CERTIFICATION ET COMMERCIALISATION (LP CLERMONT AUVERGNE)", label: "AGRICULTURE BIOLOGIQUE : PRODUCTION, CONSEIL, CERTIFICATION ET COMMERCIALISATION (LP CLERMONT AUVERGNE)" },
  { value: "AGRONOMIE (LP ANTILLES)", label: "AGRONOMIE (LP ANTILLES)" },
  { value: "PRODUCTIONS VEGETALES (LP LE HAVRE)", label: "PRODUCTIONS VEGETALES (LP LE HAVRE)" },
  { value: "PRODUCTIONS VEGETALES (LP ANGERS)", label: "PRODUCTIONS VEGETALES (LP ANGERS)" },
  { value: "PRODUCTIONS VEGETALES (LP REIMS)", label: "PRODUCTIONS VEGETALES (LP REIMS)" },
  { value: "PRODUCTIONS VEGETALES (LP LYON 1)", label: "PRODUCTIONS VEGETALES (LP LYON 1)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP MARSEILLE)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP MARSEILLE)" },
  { value: "PRODUCTIONS VEGETALES (LP GRENOBLE ALPES)", label: "PRODUCTIONS VEGETALES (LP GRENOBLE ALPES)" },
  { value: "PRODUCTIONS VEGETALES (LP TOULOUSE 3)", label: "PRODUCTIONS VEGETALES (LP TOULOUSE 3)" },
  { value: "PRODUCTIONS ANIMALES (LP GRENOBLES ALPES)", label: "PRODUCTIONS ANIMALES (LP GRENOBLES ALPES)" },
  { value: "PRODUCTIONS ANIMALES (LP ANGERS)", label: "PRODUCTIONS ANIMALES (LP ANGERS)" },
  { value: "PRODUCTIONS ANIMALES (LP CNAM)", label: "PRODUCTIONS ANIMALES (LP CNAM)" },
  { value: "PRODUCTIONS ANIMALES (LP ROUEN)", label: "PRODUCTIONS ANIMALES (LP ROUEN)" },
  { value: "PRODUCTIONS ANIMALES (INU CHAMPOLLION)", label: "PRODUCTIONS ANIMALES (INU CHAMPOLLION)" },
  { value: "PRODUCTIONS ANIMALES (LP LIMOGES)", label: "PRODUCTIONS ANIMALES (LP LIMOGES)" },
  { value: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP VERSAILLES)", label: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP VERSAILLES)" },
  { value: "AMENAGEMENT DU TERRITOIRE ET URBANISME SPE GESTIONNAIRE DES ESPACES NATURELS DE LOISIRS (LP GRENOBLE 1)", label: "AMENAGEMENT DU TERRITOIRE ET URBANISME SPE GESTIONNAIRE DES ESPACES NATURELS DE LOISIRS (LP GRENOBLE 1)" },
  { value: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP GRENOBLE ALPES)", label: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP GRENOBLE ALPES)" },
  { value: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP AIX MARSEILLE)", label: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP AIX MARSEILLE)" },
  { value: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP TOULOUSE 1)", label: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP TOULOUSE 1)" },
  { value: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP PARIS 11)", label: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP PARIS 11)" },
  { value: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP PARIS 6)", label: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP PARIS 6)" },
  { value: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP ANGERS)", label: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP ANGERS)" },
  { value: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP DIJON)", label: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP DIJON)" },
  { value: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP RENNES 2)", label: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP RENNES 2)" },
  { value: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP LIMOGES)", label: "AMENAGEMENT PAYSAGER : CONCEPTION, GESTION, ENTRETIEN (LP LIMOGES)" },
  { value: "MATERIAUX ET STRUCTURES : FONCTIONNALISATION ET TRAITEMENT DES SURFACES (LP BESANCON°", label: "MATERIAUX ET STRUCTURES : FONCTIONNALISATION ET TRAITEMENT DES SURFACES (LP BESANCON°" },
  { value: "MATERIAUX ET STRUCTURES : GESTION, CONCEPTION ET INDUSTRIALISATION (LP EVRY)", label: "MATERIAUX ET STRUCTURES : GESTION, CONCEPTION ET INDUSTRIALISATION (LP EVRY)" },
  { value: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP AMIENS)", label: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP AMIENS)" },
  { value: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP PARIS 12)", label: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP PARIS 12)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP LORRAINE)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP LORRAINE)" },
  { value: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP CNAM)", label: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP CNAM)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : CONTROLE INDUSTRIEL (LP LE MANS)", label: "MAINTENANCE ET TECHNOLOGIE : CONTROLE INDUSTRIEL (LP LE MANS)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP NIMES)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP NIMES)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP LIMOGES)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP LIMOGES)" },
  { value: "METIERS DE L'EMBALLAGE ET DU CONDITIONNEMENT (LP CLERMONT AUVERGNE)", label: "METIERS DE L'EMBALLAGE ET DU CONDITIONNEMENT (LP CLERMONT AUVERGNE)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP NANTES)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP NANTES)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP CLERMONT AUVERGNE)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP CLERMONT AUVERGNE)" },
  { value: "ANALYSE, QUALITE ET CONTROLE DES MATERIAUX PRODUITS (LP TOULOUSE 3)", label: "ANALYSE, QUALITE ET CONTROLE DES MATERIAUX PRODUITS (LP TOULOUSE 3)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP GRENOBLE ALPES)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP GRENOBLE ALPES)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP BREST)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP BREST)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP GRENOBLE ALPES)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP GRENOBLE ALPES)" },
  { value: "ALORISATION DES AGRO-RESSOURCES (LP PAU)", label: "ALORISATION DES AGRO-RESSOURCES (LP PAU)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP BESANCON)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP BESANCON)" },
  { value: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELLIERS ET DE RESTAURATION (LP PARIS 11)", label: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELLIERS ET DE RESTAURATION (LP PARIS 11)" },
  { value: "LOGISTIQUE ET PILOTAGE DES FLUX (LP BREST)", label: "LOGISTIQUE ET PILOTAGE DES FLUX (LP BREST)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP LE HAVRE)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP LE HAVRE)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP LA ROCHELLE)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP LA ROCHELLE)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP CAEN)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP CAEN)" },
  { value: "VALORISATION DES AGRO-RESSOURCES (LP REIMS)", label: "VALORISATION DES AGRO-RESSOURCES (LP REIMS)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP ARTOIS)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP ARTOIS)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP EVRY)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP EVRY)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP LA REUNION)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP LA REUNION)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP BORDEAUX)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP BORDEAUX)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP BREST)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP BREST)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP GRENOBLE ALPES)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP GRENOBLE ALPES)" },
  { value: "METIERS DE L'EMBALLAGE ET DU CONDITIONNEMENT (LP MONTPELLIER)", label: "METIERS DE L'EMBALLAGE ET DU CONDITIONNEMENT (LP MONTPELLIER)" },
  { value: "METIERS DE LA SANTE : NUTRITION, ALIMENTATION (LP MONTPELLIER)", label: "METIERS DE LA SANTE : NUTRITION, ALIMENTATION (LP MONTPELLIER)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP LE MANS)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP LE MANS)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP STRASBOURG)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP STRASBOURG)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP PARIS 12)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP PARIS 12)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP ANGERS)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP ANGERS)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP RENNES 1)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP RENNES 1)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP TOURS)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP TOURS)" },
  { value: "COMMERCE ET DISTRIBUTION (LP LE MANS)", label: "COMMERCE ET DISTRIBUTION (LP LE MANS)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP LYON 1)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP LYON 1)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP CNAM)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP CNAM)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP CLERMONT AUVERGNE)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP CLERMONT AUVERGNE)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP DIJON)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP DIJON)" },
  { value: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP LORRAINE)", label: "INDUSTRIES AGROALIMENTAIRES : GESTION, PRODUCTION ET VALORISATION (LP LORRAINE)" },
  { value: "CHIMIE : FORMULATION (LP TOULOUSE 3)", label: "CHIMIE : FORMULATION (LP TOULOUSE 3)" },
  { value: "CHIMIE : FORMULATION (LP ORLEANS)", label: "CHIMIE : FORMULATION (LP ORLEANS)" },
  { value: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP NICE)", label: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP NICE)" },
  { value: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP ROUEN)", label: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP ROUEN)" },
  { value: "CHIMIE : FORMULATION (LP PARIS 6)", label: "CHIMIE : FORMULATION (LP PARIS 6)" },
  { value: "CHIMIE DE SYNTHESE (LP PARIS 6)", label: "CHIMIE DE SYNTHESE (LP PARIS 6)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP PARIS 6)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP PARIS 6)" },
  { value: "CHIMIE : FORMULATION (LP VERSAILLES)", label: "CHIMIE : FORMULATION (LP VERSAILLES)" },
  { value: "INDUSTRIES CHIMIQUES ET PHARMACEUTIQUES SPE MICROBIOLOGIE INDUSTRIELLE ET BIOTECHNOLOGIE (LP PARIS 7)", label: "INDUSTRIES CHIMIQUES ET PHARMACEUTIQUES SPE MICROBIOLOGIE INDUSTRIELLE ET BIOTECHNOLOGIE (LP PARIS 7)" },
  { value: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP LYON 1)", label: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP LYON 1)" },
  { value: "CHIMIE INDUSTRIELLE (LP AIX MARSEILLE)", label: "CHIMIE INDUSTRIELLE (LP AIX MARSEILLE)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP PARIS 11)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP PARIS 11)" },
  { value: "BIOLOGIE ANALYTIQUE EXPERIMENTALE (LP CNAM)", label: "BIOLOGIE ANALYTIQUE EXPERIMENTALE (LP CNAM)" },
  { value: "INDUSTRIES CHIMIQUES ET PHARMACEUTIQUES SPE DEVELOPPEMENT ET PRODUCTION PHARMACEUTIQUE : MAITRISE DES PROCESSUS DE FABRICATION DES MEDICAMENTS (LP CNAM)", label: "INDUSTRIES CHIMIQUES ET PHARMACEUTIQUES SPE DEVELOPPEMENT ET PRODUCTION PHARMACEUTIQUE : MAITRISE DES PROCESSUS DE FABRICATION DES MEDICAMENTS (LP CNAM)" },
  { value: "GENIE DES PROCEDES ET BIOPROCEDES INDUSTRIELS (LP LYON 1)", label: "GENIE DES PROCEDES ET BIOPROCEDES INDUSTRIELS (LP LYON 1)" },
  { value: "CHIMIE DE SYNTHESE (LP PARIS 11)", label: "CHIMIE DE SYNTHESE (LP PARIS 11)" },
  { value: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP STRASBOURG)", label: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP STRASBOURG)" },
  { value: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP AIX MARSEILLE)", label: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP AIX MARSEILLE)" },
  { value: "CHIMIE : FORMULATION (LP CERGY)", label: "CHIMIE : FORMULATION (LP CERGY)" },
  { value: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP PARIS 5)", label: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP PARIS 5)" },
  { value: "CHIME ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP PARIS 11)", label: "CHIME ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP PARIS 11)" },
  { value: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP LILLE 1)", label: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP LILLE 1)" },
  { value: "INDUSTRIES CHIMIQUES ET PHARMACEUTIQUES SPE ANALYSE CHIMIQUE (LP PARIS 7)", label: "INDUSTRIES CHIMIQUES ET PHARMACEUTIQUES SPE ANALYSE CHIMIQUE (LP PARIS 7)" },
  { value: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP LE MANS)", label: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP LE MANS)" },
  { value: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (PARIS 12)", label: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (PARIS 12)" },
  { value: "CHIMIE DE SYNTHESE (LP LE MANS)", label: "CHIMIE DE SYNTHESE (LP LE MANS)" },
  { value: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP CAEN)", label: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP CAEN)" },
  { value: "CHIMIE ET PHYSIQUE DES MATERIAUX (LP PARIS 6)", label: "CHIMIE ET PHYSIQUE DES MATERIAUX (LP PARIS 6)" },
  { value: "CHIME ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP ARTOIS)", label: "CHIME ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP ARTOIS)" },
  { value: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP STRASBOURG)", label: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP STRASBOURG)" },
  { value: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP PARIS 12)", label: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP PARIS 12)" },
  { value: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP PARIS 6)", label: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP PARIS 6)" },
  { value: "CHIMIE DE SYNTHESE (LP STRASBOURG)", label: "CHIMIE DE SYNTHESE (LP STRASBOURG)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP LA ROCHELLE)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP LA ROCHELLE)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP POITIERS)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (LP POITIERS)" },
  { value: "BIOLOGIE ANALYTIQUE ET EXPERIMENTALE (LP MONTPELLIER)", label: "BIOLOGIE ANALYTIQUE ET EXPERIMENTALE (LP MONTPELLIER)" },
  { value: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP BESANCON)", label: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP BESANCON)" },
  { value: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP ORLEANS)", label: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP ORLEANS)" },
  { value: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP TOURS)", label: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP TOURS)" },
  { value: "CHIMIE ET PHYSIQUE DES MATERIAUX (LP MONTPELLIER)", label: "CHIMIE ET PHYSIQUE DES MATERIAUX (LP MONTPELLIER)" },
  { value: "INDUSTRIES CHIMIQUES ET PHARMACEUTIQUES SPE METIERS DES VIGILANCES INDUSTRIELLES (LP PARIS 6)", label: "INDUSTRIES CHIMIQUES ET PHARMACEUTIQUES SPE METIERS DES VIGILANCES INDUSTRIELLES (LP PARIS 6)" },
  { value: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP PARIS 11)", label: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP PARIS 11)" },
  { value: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP CERGY)", label: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP CERGY)" },
  { value: "CHIMIE INDUSTRIELLE (LP ORLEANS)", label: "CHIMIE INDUSTRIELLE (LP ORLEANS)" },
  { value: "CHIME ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP BORDEAUX)", label: "CHIME ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP BORDEAUX)" },
  { value: "CHIME ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP TOULOUSE 3)", label: "CHIME ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP TOULOUSE 3)" },
  { value: "CHIMIE INDUSTRIELLE (LP LYON 1)", label: "CHIMIE INDUSTRIELLE (LP LYON 1)" },
  { value: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP BORDEAUX)", label: "INDUSTRIES PHARMACEUTIQUES, COSMETOLOGIQUES ET DE SANTE : GESTION, PRODUCTION ET VALORISATION (LP BORDEAUX)" },
  { value: "METIERS DE LA QUALITE (LP BORDEAUX)", label: "METIERS DE LA QUALITE (LP BORDEAUX)" },
  { value: "CHIMIE : FORMULATION (LP MONTPELLIER)", label: "CHIMIE : FORMULATION (LP MONTPELLIER)" },
  { value: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP NANTES)", label: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP NANTES)" },
  { value: "METIERS DE L'INDUSTRIE : METALLURGIE, MISE EN FORME DES MATERIAUX ET SOUDAGE (LP LYON 1)", label: "METIERS DE L'INDUSTRIE : METALLURGIE, MISE EN FORME DES MATERIAUX ET SOUDAGE (LP LYON 1)" },
  { value: "TRANSFORMATION DES METAUX SPE ANALYSE DES MATERIAUX (LP PARIS 7)", label: "TRANSFORMATION DES METAUX SPE ANALYSE DES MATERIAUX (LP PARIS 7)" },
  { value: "METIERS DE L'INDUSTRIE : METALLURGIE, MISE EN FORME DES MATERIAUX ET SOUDAGE (LP LORRAINE)", label: "METIERS DE L'INDUSTRIE : METALLURGIE, MISE EN FORME DES MATERIAUX ET SOUDAGE (LP LORRAINE)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP LYON 1)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP LYON 1)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP MULHOUSE)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP MULHOUSE)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP VALENCIENNES)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP VALENCIENNES)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP CAEN)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP CAEN)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP CHAMBERY)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP CHAMBERY)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP PARIS 10)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP PARIS 10)" },
  { value: "TECHNICO-COMMERCIAL (LP MARNE LA VALLEE)", label: "TECHNICO-COMMERCIAL (LP MARNE LA VALLEE)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP CNAM)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP CNAM)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP CORSE)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP CORSE)" },
  { value: "GESTION ET MAINTENANCE DES INSTALLATIONS ENERGETIQUES (LP MARNE LA VALLEE)", label: "GESTION ET MAINTENANCE DES INSTALLATIONS ENERGETIQUES (LP MARNE LA VALLEE)" },
  { value: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP VERSAILLES)", label: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP VERSAILLES)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP PAU)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP PAU)" },
  { value: "ENERGIE ET GENIE CLIMATIQUE SPE TECHNIQUES PHYSIQUES DES ENERGIES (LP PARIS 7)", label: "ENERGIE ET GENIE CLIMATIQUE SPE TECHNIQUES PHYSIQUES DES ENERGIES (LP PARIS 7)" },
  { value: "METIERS DE LA RADIOPROTECTION ET DE LA SECURITE NUCLEAIRE (LP LYON 1)", label: "METIERS DE LA RADIOPROTECTION ET DE LA SECURITE NUCLEAIRE (LP LYON 1)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP NICE)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP NICE)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP EVRY)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP EVRY)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP ANGERS)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP ANGERS)" },
  { value: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP LA ROCHELLE)", label: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP LA ROCHELLE)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP MULHOUSE)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP MULHOUSE)" },
  { value: "GESTION ET MAINTENANCE DES INSTALLATIONS ENERGETIQUES (LP BORDEAUX)", label: "GESTION ET MAINTENANCE DES INSTALLATIONS ENERGETIQUES (LP BORDEAUX)" },
  { value: "METIER DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP PARIS 10)", label: "METIER DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP PARIS 10)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP TROYES)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP TROYES)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP BESANCON)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP BESANCON)" },
  { value: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (PARIS 11)", label: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (PARIS 11)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP LORRAINE)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP LORRAINE)" },
  { value: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP LORRAINE)", label: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP LORRAINE)" },
  { value: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP CAEN)", label: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP CAEN)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP TOURS)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP TOURS)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP ORLEANS)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP ORLEANS)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP AMIENS)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP AMIENS)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP POITIERS)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP POITIERS)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP LA REUNION)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP LA REUNION)" },
  { value: "GESTION ET MAINTENANCE DES INSTALLATIONS ENERGETIQUES (LP TOULOUSE 3)", label: "GESTION ET MAINTENANCE DES INSTALLATIONS ENERGETIQUES (LP TOULOUSE 3)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP PARIS 12)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP PARIS 12)" },
  { value: "INSTALLATIONS FRIGORIFIQUES ET DE CONDITIONNEMENT D'AIR (LP LORRAINE)", label: "INSTALLATIONS FRIGORIFIQUES ET DE CONDITIONNEMENT D'AIR (LP LORRAINE)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP ORLEANS)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP ORLEANS)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP AIX MARSEILLE)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP AIX MARSEILLE)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP BORDEAUX)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP BORDEAUX)" },
  { value: "INSTALLATIONS FRIGORIFIQUES ET DE CONDITIONNEMENT D'AIR (LP NANTES)", label: "INSTALLATIONS FRIGORIFIQUES ET DE CONDITIONNEMENT D'AIR (LP NANTES)" },
  { value: "GESTION ET MAINTENANCE DES INSTALLATIONS ENERGETIQUES (LP VERSAILLES)", label: "GESTION ET MAINTENANCE DES INSTALLATIONS ENERGETIQUES (LP VERSAILLES)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP BORDEAUX)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP BORDEAUX)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP PARIS 6)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP PARIS 6)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP TOULOUSE 3)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP TOULOUSE 3)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP TOULOUSE 3)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP TOULOUSE 3)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP CLERMONT AUVERGNE)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP CLERMONT AUVERGNE)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP BRETAGNE SUD)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP BRETAGNE SUD)" },
  { value: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP LIMOGES)", label: "METIERS DE L'ENERGETIQUE, DE L'ENVIRONNEMENT ET DU GENIE CLIMATIQUE (LP LIMOGES)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP GUYANE)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP GUYANE)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP LORRAINE)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP LORRAINE)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP LILLE1)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP LILLE1)" },
  { value: "GESTION ET MAINTENANCE DES INSTALLATIONS ENERGETIQUES (LP PARIS 13)", label: "GESTION ET MAINTENANCE DES INSTALLATIONS ENERGETIQUES (LP PARIS 13)" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP MONTPELLIER)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP MONTPELLIER)" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP STRASBOURG)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP STRASBOURG)" },
  { value: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP STRASBOURG)", label: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP STRASBOURG)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP PAU)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP PAU)" },
  { value: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP BREST)", label: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP BREST)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP MARNE LA VALLEE)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP MARNE LA VALLEE)" },
  { value: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP AMIENS)", label: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP AMIENS)" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP LIMOGES)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP LIMOGES)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP BORDEAUX)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP BORDEAUX)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP PARIS 12)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP PARIS 12)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP PARIS 6)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP PARIS 6)" },
  { value: "METIERS DU BTP : TRAVAUX PUBLICS (LP CERGY)", label: "METIERS DU BTP : TRAVAUX PUBLICS (LP CERGY)" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP RENNES 1)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP RENNES 1)" },
  { value: "METIERS DE L'IMMOBILIER : TRANSACTION ET COMMERCIALISATION DE BIENS IMMOBILIERS (LP VERSAILLES)", label: "METIERS DE L'IMMOBILIER : TRANSACTION ET COMMERCIALISATION DE BIENS IMMOBILIERS (LP VERSAILLES)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP LYON 3)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP LYON 3)" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP MARNE LA VALLEE)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP MARNE LA VALLEE)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP VERSAILLES)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP VERSAILLES)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP CERGY)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP CERGY)" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP CERGY)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP CERGY)" },
  { value: "AGENCEMENT (LP MARNE LA VALLEE)", label: "AGENCEMENT (LP MARNE LA VALLEE)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP CNAM)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP CNAM)" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP CNAM)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP CNAM)" },
  { value: "METIERS DU BTP : TRAVAUX PUBLICS (LP MONTPELIER)", label: "METIERS DU BTP : TRAVAUX PUBLICS (LP MONTPELIER)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP NANTES)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP NANTES)" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP LE HAVRE)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP LE HAVRE)" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP NANTES)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP NANTES)" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP DIJON)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP DIJON)" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP REIMS)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP REIMS)" },
  { value: "METIERS DU BTP : TRAVAUX PUBLICS (LP AMIENS)", label: "METIERS DU BTP : TRAVAUX PUBLICS (LP AMIENS)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP LA ROCHELLE)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP LA ROCHELLE)" },
  { value: "METIERS DU BTP : TRAVAUX PUBLICS (LP LIMOGES)", label: "METIERS DU BTP : TRAVAUX PUBLICS (LP LIMOGES)" },
  { value: "METIERS DU BTP : TRAVAUX PUBLICS (LP LA ROCHELLE)", label: "METIERS DU BTP : TRAVAUX PUBLICS (LP LA ROCHELLE)" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP CORSE)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP CORSE)" },
  { value: "METIERS DU BTP : TRAVAUX PUBLICS (LP LYON 1)", label: "METIERS DU BTP : TRAVAUX PUBLICS (LP LYON 1)" },
  { value: "METIERS DU BTP : TRAVAUX PUBLICS (LP NANTES)", label: "METIERS DU BTP : TRAVAUX PUBLICS (LP NANTES)" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP LA REUNION)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP LA REUNION)" },
  { value: "METIERS DE BATIMENT : BATIMENT ET CONSTRUCTION (LP EVRY)", label: "METIERS DE BATIMENT : BATIMENT ET CONSTRUCTION (LP EVRY)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP BESANCON)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP BESANCON)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP POITIERS)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP POITIERS)" },
  { value: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP GRENOBLE ALPES)", label: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP GRENOBLE ALPES)" },
  { value: "METIERS DU BTP : PERFORMANCES ENERGETIQUES ET ENVIRONNEMENTALES DES BATIMENTS (LP MARNE LA VALLEE)", label: "METIERS DU BTP : PERFORMANCES ENERGETIQUES ET ENVIRONNEMENTALES DES BATIMENTS (LP MARNE LA VALLEE)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP VALENCIENNES)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP VALENCIENNES)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP MONTPELLIER)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP MONTPELLIER)" },
  { value: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP U LITTORAL)", label: "METIERS DU BTP : BATIMENT ET CONSTRUCTION (LP U LITTORAL)" },
  { value: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP CLERMONT AUVERGNE)", label: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP CLERMONT AUVERGNE)" },
  { value: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP TOULOUSE 3)", label: "METIERS DU BTP : PERFORMANCE ENERGETIQUE ET ENVIRONNEMENTALE DES BATIMENTS (LP TOULOUSE 3)" },
  { value: "METIERS DU BOIS (LP PAU)", label: "METIERS DU BOIS (LP PAU)" },
  { value: "BOIS ET AMEUBLEMENT (LP LORRAINE)", label: "BOIS ET AMEUBLEMENT (LP LORRAINE)" },
  { value: "METIERS DE LA MODE (LP NIMES)", label: "METIERS DE LA MODE (LP NIMES)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP LILLE 1)", label: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP LILLE 1)" },
  { value: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP CERGY)", label: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP CERGY)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP NANTES)", label: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP NANTES)" },
  { value: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP TOULOUSE 3)", label: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP TOULOUSE 3)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP CAEN)", label: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP CAEN)" },
  { value: "METIERS DE L'INDUSTRIE : MECANIQUE (LP PARIS 6)", label: "METIERS DE L'INDUSTRIE : MECANIQUE (LP PARIS 6)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : ORGANISATION DE LA MAINTENANCE (LP NANTES)", label: "MAINTENANCE ET TECHNOLOGIE : ORGANISATION DE LA MAINTENANCE (LP NANTES)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP LYON 1)", label: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP LYON 1)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP LE MANS)", label: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP LE MANS)" },
  { value: "METIERS DE L'INDUSTRIE : INDUSTRIE NAVALE ET MARITIME (LP TOULON)", label: "METIERS DE L'INDUSTRIE : INDUSTRIE NAVALE ET MARITIME (LP TOULON)" },
  { value: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP ROUEN)", label: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP ROUEN)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP TOULOUSE 2)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP TOULOUSE 2)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP VALENCIENNES)", label: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP VALENCIENNES)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP STRASBOURG)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP STRASBOURG)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP EVRY)", label: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP EVRY)" },
  { value: "METIERS DE L'INDUSTRIE : MECANIQUE (LP MULHOUSE)", label: "METIERS DE L'INDUSTRIE : MECANIQUE (LP MULHOUSE)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP MULHOUSE)", label: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP MULHOUSE)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP BREST)", label: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP BREST)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP LE MANS)", label: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP LE MANS)" },
  { value: "METIERS DE L'INDUSTRIE : MECANIQUE (LP NANTES)", label: "METIERS DE L'INDUSTRIE : MECANIQUE (LP NANTES)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP LYON 1)", label: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP LYON 1)" },
  { value: "METIERS DE L'INDUSTRIE : MECANIQUE (LP ORLEANS)", label: "METIERS DE L'INDUSTRIE : MECANIQUE (LP ORLEANS)" },
  { value: "MAINTENANCE TECHNO SYSTM PLURITEC (LP POITIERS)", label: "MAINTENANCE TECHNO SYSTM PLURITEC (LP POITIERS)" },
  { value: "ORGANISATION, MANAGEMENT DES SERVICES DE L'AUTOMOBILE (LP MARNE LA VALLEE)", label: "ORGANISATION, MANAGEMENT DES SERVICES DE L'AUTOMOBILE (LP MARNE LA VALLEE)" },
  { value: "METIERS DE L'ELECTRONIQUE : COMMUNICATIONS, SYSTEMES EMBARQUE (LP PARIS 10)", label: "METIERS DE L'ELECTRONIQUE : COMMUNICATIONS, SYSTEMES EMBARQUE (LP PARIS 10)" },
  { value: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP AIX MARSEILLE)", label: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP AIX MARSEILLE)" },
  { value: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP BORDEAUX)", label: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP BORDEAUX)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP EVRY)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP EVRY)" },
  { value: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP TOULOUSE 2)", label: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP TOULOUSE 2)" },
  { value: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP PARIS 10)", label: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP PARIS 10)" },
  { value: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP CLERMONT AUVERGNE)", label: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP CLERMONT AUVERGNE)" },
  { value: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP EVRY)", label: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP EVRY)" },
  { value: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP MONTPELLIER)", label: "METIERS DE L'INDUSTRIE : INDUSTRIE AERONAUTIQUE (LP MONTPELLIER)" },
  { value: "METIERS DE L'INDUSTRIE : METALLURGIE, MISE EN FORME DES MATERIAUX ET SOUDAGE (LP MONTPELLIER)", label: "METIERS DE L'INDUSTRIE : METALLURGIE, MISE EN FORME DES MATERIAUX ET SOUDAGE (LP MONTPELLIER)" },
  { value: "METIERS DE L'INDUSTRIE : METALLURGIE, MISE EN FORME DES MATERIAUX ET SOUDAGE (LP BREST)", label: "METIERS DE L'INDUSTRIE : METALLURGIE, MISE EN FORME DES MATERIAUX ET SOUDAGE (LP BREST)" },
  { value: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP LYON 3)", label: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP LYON 3)" },
  { value: "TECHNICO-COMMERCIAL (LP PARIS 11)", label: "TECHNICO-COMMERCIAL (LP PARIS 11)" },
  { value: "METIERS DE L'ELECTRONIQUE : MICROELECTRONIQUE, OPTRONIQUE (LP PARIS 6)", label: "METIERS DE L'ELECTRONIQUE : MICROELECTRONIQUE, OPTRONIQUE (LP PARIS 6)" },
  { value: "METIERS DE L'ELECTRONIQUE : FABRICATION DE CARTES ET SOUS-ENSEMBLES ELECTRONIQUES (LP TOULOUSE 3)", label: "METIERS DE L'ELECTRONIQUE : FABRICATION DE CARTES ET SOUS-ENSEMBLES ELECTRONIQUES (LP TOULOUSE 3)" },
  { value: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP GRENOBLE ALPES)", label: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP GRENOBLE ALPES)" },
  { value: "METIERS DE L'ELECTRONIQUE : MICROELECTRONIQUE, OPTRONIQUE (LP GRENOBLE ALPES)", label: "METIERS DE L'ELECTRONIQUE : MICROELECTRONIQUE, OPTRONIQUE (LP GRENOBLE ALPES)" },
  { value: "MAINTENANCE DES SYSTEMES INDUSTRIELS, DE PRODUCTION ET D'ENERGIE (LP CERGY)", label: "MAINTENANCE DES SYSTEMES INDUSTRIELS, DE PRODUCTION ET D'ENERGIE (LP CERGY)" },
  { value: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (INU CHAMPOLLION)", label: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (INU CHAMPOLLION)" },
  { value: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP VALENCIENNES)", label: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP VALENCIENNES)" },
  { value: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP VERSAILLES)", label: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP VERSAILLES)" },
  { value: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP MONTPELLIER)", label: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP MONTPELLIER)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP BORDEAUX)", label: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP BORDEAUX)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP STRASBOURG)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP STRASBOURG)" },
  { value: "METIERS DE L'ELECTRONIQUE : FABRICATION DE CARTES ET SOUS-ENSEMBLES ELECTRONIQUES (LP TOURS)", label: "METIERS DE L'ELECTRONIQUE : FABRICATION DE CARTES ET SOUS-ENSEMBLES ELECTRONIQUES (LP TOURS)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP CNAM)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP CNAM)" },
  { value: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP BESANCON)", label: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP BESANCON)" },
  { value: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP PARIS 12)", label: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP PARIS 12)" },
  { value: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP VERSAILLES)", label: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP VERSAILLES)" },
  { value: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP GRENOBLE ALPES)", label: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP GRENOBLE ALPES)" },
  { value: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP ARTOIS)", label: "METIERS DE L'INDUSTRIE : MECATRONIQUE, ROBOTIQUE (LP ARTOIS)" },
  { value: "METIERS DE L'ELECTRONIQUE : MICROELECTRONIQUE, OPTRONIQUE (LP LIMOGES)", label: "METIERS DE L'ELECTRONIQUE : MICROELECTRONIQUE, OPTRONIQUE (LP LIMOGES)" },
  { value: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP PARIS 12)", label: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP PARIS 12)" },
  { value: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP CORSE)", label: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP CORSE)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LPMONTPELLIER)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LPMONTPELLIER)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP POITIERS)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP POITIERS)" },
  { value: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP LORRAINE)", label: "SYSTEMES AUTOMATISES, RESEAUX ET INFORMATIQUE INDUSTRIELLE (LP LORRAINE)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP PARIS 12)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP PARIS 12)" },
  { value: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP VERSAILLES)", label: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP VERSAILLES)" },
  { value: "ACOUSTIQUE ET VIBRATIONS (LP POITIERS)", label: "ACOUSTIQUE ET VIBRATIONS (LP POITIERS)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : ELECTRONIQUE, INSTRUMENTATION (LP PARIS 11)", label: "MAINTENANCE ET TECHNOLOGIE : ELECTRONIQUE, INSTRUMENTATION (LP PARIS 11)" },
  { value: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP REIMS)", label: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP REIMS)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP NANTES)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP NANTES)" },
  { value: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP PARIS 11)", label: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP PARIS 11)" },
  { value: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP LORRAINE)", label: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP LORRAINE)" },
  { value: "DOMOTIQUE (LP PARIS12)", label: "DOMOTIQUE (LP PARIS12)" },
  { value: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP U DU LITTOTAL)", label: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP U DU LITTOTAL)" },
  { value: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP LIMOGES)", label: "MAITRISE DE L'ENERGIE, ELECTRICITE, DEVELOPPEMENT DURABLE (LP LIMOGES)" },
  { value: "DOMOTIQUE (LP VALENCIENNES)", label: "DOMOTIQUE (LP VALENCIENNES)" },
  { value: "DOMOTIQUE (LP CNAM)", label: "DOMOTIQUE (LP CNAM)" },
  { value: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP AIX MARSEILLE)", label: "METIERS DE L'ELECTRONIQUE : COMMUNICATION, SYSTEMES EMBARQUES (LP AIX MARSEILLE)" },
  { value: "METIERS DE L'ELECTRONIQUE : FABRICATION DE CARTES ET SOUS-ENSEMBLES ELECTRONIQUES (LP AIX MARSEILLE)", label: "METIERS DE L'ELECTRONIQUE : FABRICATION DE CARTES ET SOUS-ENSEMBLES ELECTRONIQUES (LP AIX MARSEILLE)" },
  { value: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP STRASBOURG)", label: "METIERS DE L'ELECTRICITE ET DE L'ENERGIE (LP STRASBOURG)" },
  { value: "METIERS DE L'IMMOBILIER : GESTION ET DEVELOPPEMENT DE PATRIMOINE IMMOBILIER (LP EVRY)", label: "METIERS DE L'IMMOBILIER : GESTION ET DEVELOPPEMENT DE PATRIMOINE IMMOBILIER (LP EVRY)" },
  { value: "LOGISTIQUE ET PILOTAGE DES FLUX (LP LYON 2)", label: "LOGISTIQUE ET PILOTAGE DES FLUX (LP LYON 2)" },
  { value: "ACTIVITES JURIDIQUES : METIERS DU DROIT DE L' IMMOBILIER (LP MARNE LA VALLEE)", label: "ACTIVITES JURIDIQUES : METIERS DU DROIT DE L' IMMOBILIER (LP MARNE LA VALLEE)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP EVRY)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP EVRY)" },
  { value: "E-COMMERCE ET MARKETING NUMERIQUE (LP LYON 1)", label: "E-COMMERCE ET MARKETING NUMERIQUE (LP LYON 1)" },
  { value: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP EVRY)", label: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP EVRY)" },
  { value: "METIERS DE L'IMMOBILIER : GESTION ET ADMINISTRATION DE BIENS (LP LYON 3)", label: "METIERS DE L'IMMOBILIER : GESTION ET ADMINISTRATION DE BIENS (LP LYON 3)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP PAU)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP PAU)" },
  { value: "METIERS DE LA QUALITE (LP TOULON)", label: "METIERS DE LA QUALITE (LP TOULON)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : CONTRÔLE DE GESTION (LP SAINT ETIENNE)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : CONTRÔLE DE GESTION (LP SAINT ETIENNE)" },
  { value: "METIERS DE L'ENTREPRENEURIAT (LP CORSE)", label: "METIERS DE L'ENTREPRENEURIAT (LP CORSE)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP MARNE LA VALLEE)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP MARNE LA VALLEE)" },
  { value: "COMMERCE ET DISTRIBUTION (LP MARNE LA VALLEE)", label: "COMMERCE ET DISTRIBUTION (LP MARNE LA VALLEE)" },
  { value: "GESTION DES ORGANISATIONS DE L'ECONOMIE SOCIALE ET SOLIDAIRE (LP MARNE LA VALLEE)", label: "GESTION DES ORGANISATIONS DE L'ECONOMIE SOCIALE ET SOLIDAIRE (LP MARNE LA VALLEE)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : FISCALITE (LP ORLEANS)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : FISCALITE (LP ORLEANS)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP LYON 1)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP LYON 1)" },
  { value: "LOGISTIQUE ET PILOTAGE DES FLUX (LP GRENOBLE ALPES)", label: "LOGISTIQUE ET PILOTAGE DES FLUX (LP GRENOBLE ALPES)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP CAEN)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP CAEN)" },
  { value: "MANAGEMENT DES ORGANISATIONS SPE AUDIT ET CONTROLE DE GESTION DES PETITES ET MOYENNES ORGANISATIONS (LP PARIS 13)", label: "MANAGEMENT DES ORGANISATIONS SPE AUDIT ET CONTROLE DE GESTION DES PETITES ET MOYENNES ORGANISATIONS (LP PARIS 13)" },
  { value: "MANAGEMENT DES ORGANISATIONS SPE MANAGEMENT DES ENTREPRISES DE DISTRIBUTION EN RESEAU (LP PARIS 2)", label: "MANAGEMENT DES ORGANISATIONS SPE MANAGEMENT DES ENTREPRISES DE DISTRIBUTION EN RESEAU (LP PARIS 2)" },
  { value: "METIERS DE L'ENTREPRENEURIAT (LP POITIERS)", label: "METIERS DE L'ENTREPRENEURIAT (LP POITIERS)" },
  { value: "METIERS DES ADMINISTRATIONS ET COLLECTIVITES TERRITORIALES (LP STRASBOURG)", label: "METIERS DES ADMINISTRATIONS ET COLLECTIVITES TERRITORIALES (LP STRASBOURG)" },
  { value: "LOGISTIQUE ET SYSTEMES D'INFORMATION (LP BORDEAUX)", label: "LOGISTIQUE ET SYSTEMES D'INFORMATION (LP BORDEAUX)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP LILLE 1)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP LILLE 1)" },
  { value: "METIERS DES ADMINISTRATIONS ET COLLECTIVITES TERRITORIALES (LP CHAMBERY)", label: "METIERS DES ADMINISTRATIONS ET COLLECTIVITES TERRITORIALES (LP CHAMBERY)" },
  { value: "METIERS DE LA QUALITE (LP STRASBOURG)", label: "METIERS DE LA QUALITE (LP STRASBOURG)" },
  { value: "MANAGEMENT DES ORGANISATIONS SPE MANAGER DE PETITES ET MOYENNES ORGANISATIONS:ORGANISATION ET ENTREPRENARIAT (LP PARIS 13)", label: "MANAGEMENT DES ORGANISATIONS SPE MANAGER DE PETITES ET MOYENNES ORGANISATIONS:ORGANISATION ET ENTREPRENARIAT (LP PARIS 13)" },
  { value: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP CERGY)", label: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP CERGY)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP PERPIGNAN)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP PERPIGNAN)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP EVRY)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP EVRY)" },
  { value: "METIERS DE L'ENTREPRENEURIAT (LP CNAM)", label: "METIERS DE L'ENTREPRENEURIAT (LP CNAM)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP STRASBOURG)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP STRASBOURG)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP LORRAINE)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP LORRAINE)" },
  { value: "METIERS DE L'ENTREPRENEURIAT (PARIS 12)", label: "METIERS DE L'ENTREPRENEURIAT (PARIS 12)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP PARIS 10)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP PARIS 10)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : GESTION COMPTABLE ET FINANCIERE (LP AIX MARSEILLE)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : GESTION COMPTABLE ET FINANCIERE (LP AIX MARSEILLE)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : CONTROLE DE GESTION (LP AIX MARSEILLE)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : CONTROLE DE GESTION (LP AIX MARSEILLE)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP BORDEAUX)", label: "METIERS DU COMMERCE INTERNATIONAL (LP BORDEAUX)" },
  { value: "LOGISTIQUE ET SYSTEMES D'INFORMATION (LP LIMOGES)", label: "LOGISTIQUE ET SYSTEMES D'INFORMATION (LP LIMOGES)" },
  { value: "METIERS DE L'ENTREPRENEURIAT (LP LORRAINE)", label: "METIERS DE L'ENTREPRENEURIAT (LP LORRAINE)" },
  { value: "GESTION DES ORGANISATIONS DE L'ECONOMIE SOCIALE ET SOLIDAIRE (LP ORLEANS)", label: "GESTION DES ORGANISATIONS DE L'ECONOMIE SOCIALE ET SOLIDAIRE (LP ORLEANS)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP LILLE 1)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP LILLE 1)" },
  { value: "METIERS DE L'IMMOBILIER : GESTION ET ADMINISTRATION DE BIENS (LP DIJON)", label: "METIERS DE L'IMMOBILIER : GESTION ET ADMINISTRATION DE BIENS (LP DIJON)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP PARIS 5)", label: "METIERS DU COMMERCE INTERNATIONAL (LP PARIS 5)" },
  { value: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP EVRY)", label: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP EVRY)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP TOULOUSE 3)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP TOULOUSE 3)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP NIMES)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP NIMES)" },
  { value: "COMMERCE SPE DEVELOPPEMENT INTERNATIONAL DE L'ENTREPRISE VERS LES MARCHES EMERGENTS (LP INALCO)", label: "COMMERCE SPE DEVELOPPEMENT INTERNATIONAL DE L'ENTREPRISE VERS LES MARCHES EMERGENTS (LP INALCO)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP CERGY)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP CERGY)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP MONTPELLIER)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP MONTPELLIER)" },
  { value: "METIERS DE L'ENTREPRENEURIAT (LP MONTPELLIER)", label: "METIERS DE L'ENTREPRENEURIAT (LP MONTPELLIER)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP LA REUNION)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP LA REUNION)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP VERSAILLES)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP VERSAILLES)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP TOULOUSE 1)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP TOULOUSE 1)" },
  { value: "METIERS DE L'IMMOBILIER : GESTION ET DEVELOPPEMENT DE PATRIMOINE IMMOBILIER (LP LYON 3)", label: "METIERS DE L'IMMOBILIER : GESTION ET DEVELOPPEMENT DE PATRIMOINE IMMOBILIER (LP LYON 3)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP LYON 3)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP LYON 3)" },
  { value: "LOGISTIQUE ET TRANSPORTS INTERNATIONAUX (LP AIX MARSEILLE)", label: "LOGISTIQUE ET TRANSPORTS INTERNATIONAUX (LP AIX MARSEILLE)" },
  { value: "METIERS DES ADMINISTRATIONS ET COLLECTIVITES TERRITORIALES (LP PARIS 11)", label: "METIERS DES ADMINISTRATIONS ET COLLECTIVITES TERRITORIALES (LP PARIS 11)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP BORDEAUX)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP BORDEAUX)" },
  { value: "METIERS DE L'ENTREPRENEURIAT (LP CLERMONT AUVERGNE)", label: "METIERS DE L'ENTREPRENEURIAT (LP CLERMONT AUVERGNE)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP CLERMONT AUVERGNE)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP CLERMONT AUVERGNE)" },
  { value: "METIERS DES ADMINISTRATIONS ET COLLECTIVITES TERRITORIALES (LP GRENOBLE ALPES)", label: "METIERS DES ADMINISTRATIONS ET COLLECTIVITES TERRITORIALES (LP GRENOBLE ALPES)" },
  { value: "METIERS DE LA QUALITE (LP TOULOUSE 3)", label: "METIERS DE LA QUALITE (LP TOULOUSE 3)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP DIJON)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP DIJON)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP PARIS EST CRETEIL)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP PARIS EST CRETEIL)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP GUYANE)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP GUYANE)" },
  { value: "METIERS DE LA QUALITE (LP LIMOGES)", label: "METIERS DE LA QUALITE (LP LIMOGES)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP ORLEANS)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP ORLEANS)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP LIMOGES)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP LIMOGES)" },
  { value: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP MULHOUSE)", label: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP MULHOUSE)" },
  { value: "LOGISTIQUE ET TRANSPORTS INTERNATIONAUX (LP BESANCON)", label: "LOGISTIQUE ET TRANSPORTS INTERNATIONAUX (LP BESANCON)" },
  { value: "LOGISTIQUE ET PILOTAGE DES FLUX (LP RENNES 1)", label: "LOGISTIQUE ET PILOTAGE DES FLUX (LP RENNES 1)" },
  { value: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP MARNE LA VALLEE)", label: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP MARNE LA VALLEE)" },
  { value: "LOGISTIQUE ET PILOTAGE DES FLUX (LP EVRY)", label: "LOGISTIQUE ET PILOTAGE DES FLUX (LP EVRY)" },
  { value: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP CAEN)", label: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP CAEN)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP CERGY)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP CERGY)" },
  { value: "LOGISTIQUE ET PILOTAGE DES FLUX (LP CNAM)", label: "LOGISTIQUE ET PILOTAGE DES FLUX (LP CNAM)" },
  { value: "LOGISTIQUE ET TRANSPORTS INTERNATIONAUX (LP NANTES)", label: "LOGISTIQUE ET TRANSPORTS INTERNATIONAUX (LP NANTES)" },
  { value: "LOGISTIQUE ET TRANSPORTS INTERNATIONAUX (LP PERPIGNAN)", label: "LOGISTIQUE ET TRANSPORTS INTERNATIONAUX (LP PERPIGNAN)" },
  { value: "LOGISTIQUE ET PILOTAGE DES FLUX (LP TOULOUSE 3)", label: "LOGISTIQUE ET PILOTAGE DES FLUX (LP TOULOUSE 3)" },
  { value: "LOGISTIQUE ET TRANSPORTS INTERNATIONAUX (LP LORRAINE)", label: "LOGISTIQUE ET TRANSPORTS INTERNATIONAUX (LP LORRAINE)" },
  { value: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP MONTPELLIER)", label: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP MONTPELLIER)" },
  { value: "MANAGEMENT DES TRANSPORTS ET DE LA DISTRIBUTION (LP CERGY)", label: "MANAGEMENT DES TRANSPORTS ET DE LA DISTRIBUTION (LP CERGY)" },
  { value: "LOGISTIQUE ET TRANSPORTS INTERNATIONAUX (LP EVRY)", label: "LOGISTIQUE ET TRANSPORTS INTERNATIONAUX (LP EVRY)" },
  { value: "LOGISTIQUE ET SYSTEMES D'INFORMATION (LP DIJON)", label: "LOGISTIQUE ET SYSTEMES D'INFORMATION (LP DIJON)" },
  { value: "MANAGEMENT DES TRANSPORTS ET DE LA DISTRIBUTION (LP BORDEAUX)", label: "MANAGEMENT DES TRANSPORTS ET DE LA DISTRIBUTION (LP BORDEAUX)" },
  { value: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP BORDEAUX)", label: "MANAGEMENT DES PROCESSUS LOGISTIQUES (LP BORDEAUX)" },
  { value: "LOGISTIQUE ET SYSTEMES D'INFORMATION (LP CLERMONT AUVERGNE)", label: "LOGISTIQUE ET SYSTEMES D'INFORMATION (LP CLERMONT AUVERGNE)" },
  { value: "LOGISTIQUE ET PILOTAGE DES FLUX (PARIS 8)", label: "LOGISTIQUE ET PILOTAGE DES FLUX (PARIS 8)" },
  { value: "LOGISTIQUE ET PILOTAGE DES FLUX (LP CLERMONT AUVERGNE)", label: "LOGISTIQUE ET PILOTAGE DES FLUX (LP CLERMONT AUVERGNE)" },
  { value: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP ANGERS)", label: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP ANGERS)" },
  { value: "LOGISTIQUE ET PILOTAGE DES FLUX (LP U ARTOIS)", label: "LOGISTIQUE ET PILOTAGE DES FLUX (LP U ARTOIS)" },
  { value: "LOGISTIQUE ET TRANSPORTS INTERNATIONAUX (LP LE HAVRE)", label: "LOGISTIQUE ET TRANSPORTS INTERNATIONAUX (LP LE HAVRE)" },
  { value: "COMMERCE ET DISTRIBUTION (LP TOULOUSE 3)", label: "COMMERCE ET DISTRIBUTION (LP TOULOUSE 3)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP SAINT ETIENNE)", label: "METIERS DU COMMERCE INTERNATIONAL (LP SAINT ETIENNE)" },
  { value: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP SAINT ETIENNE)", label: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP SAINT ETIENNE)" },
  { value: "E-COMMERCE ET MARKETING NUMERIQUE (LP BORDEAUX)", label: "E-COMMERCE ET MARKETING NUMERIQUE (LP BORDEAUX)" },
  { value: "E-COMMERCE ET MARKETING NUMERIQUE (LP EVRY)", label: "E-COMMERCE ET MARKETING NUMERIQUE (LP EVRY)" },
  { value: "TECHNICO-COMMERCIAL (LP EVRY)", label: "TECHNICO-COMMERCIAL (LP EVRY)" },
  { value: "COMMERCE ET DISTRIBUTION (LP LIMOGES)", label: "COMMERCE ET DISTRIBUTION (LP LIMOGES)" },
  { value: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP TOURS)", label: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP TOURS)" },
  { value: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP LORRAINE)", label: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP LORRAINE)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP AMIENS)", label: "METIERS DU COMMERCE INTERNATIONAL (LP AMIENS)" },
  { value: "TECHNICO-COMMERCIAL (LP ROUEN)", label: "TECHNICO-COMMERCIAL (LP ROUEN)" },
  { value: "TECHNICO-COMMERCIAL (LP CERGY)", label: "TECHNICO-COMMERCIAL (LP CERGY)" },
  { value: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP AIX MARSEILLE)", label: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP AIX MARSEILLE)" },
  { value: "COMMERCIALISATION DES PRODUITS ET SERVICES (LP LYON 3)", label: "COMMERCIALISATION DES PRODUITS ET SERVICES (LP LYON 3)" },
  { value: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP LYON 1)", label: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP LYON 1)" },
  { value: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP AIX MARSEILLE)", label: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP AIX MARSEILLE)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP DIJON)", label: "METIERS DU COMMERCE INTERNATIONAL (LP DIJON)" },
  { value: "E-COMMERCE ET MARKETING NUMERIQUE (LP LA ROCHELLE)", label: "E-COMMERCE ET MARKETING NUMERIQUE (LP LA ROCHELLE)" },
  { value: "COMMERCE ET DISTRIBUTION (LP GRENOBLE ALPES)", label: "COMMERCE ET DISTRIBUTION (LP GRENOBLE ALPES)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP PARIS 12)", label: "METIERS DU COMMERCE INTERNATIONAL (LP PARIS 12)" },
  { value: "COMMERCE ET DISTRIBUTION (LP AIX MARSEILLE)", label: "COMMERCE ET DISTRIBUTION (LP AIX MARSEILLE)" },
  { value: "COMMERCE ET DISTRIBUTION (LP BORDEAUX)", label: "COMMERCE ET DISTRIBUTION (LP BORDEAUX)" },
  { value: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP CERGY)", label: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP CERGY)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP STARSBOURG)", label: "METIERS DU COMMERCE INTERNATIONAL (LP STARSBOURG)" },
  { value: "COMMERCE ET DISTRIBUTION (LP PARIS 11)", label: "COMMERCE ET DISTRIBUTION (LP PARIS 11)" },
  { value: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP LORRAINE)", label: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP LORRAINE)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP ROUEN)", label: "METIERS DU COMMERCE INTERNATIONAL (LP ROUEN)" },
  { value: "TECHNICO-COMMERCIAL (LP POITIERS)", label: "TECHNICO-COMMERCIAL (LP POITIERS)" },
  { value: "COMMERCE SPE COLLABORATEUR DES ACTIVITES INTERNATIONALES (LP PARIS 4)", label: "COMMERCE SPE COLLABORATEUR DES ACTIVITES INTERNATIONALES (LP PARIS 4)" },
  { value: "TECHNICO-COMMERCIAL (LP VERSAILLES)", label: "TECHNICO-COMMERCIAL (LP VERSAILLES)" },
  { value: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP MULHOUSE)", label: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP MULHOUSE)" },
  { value: "COMMERCIALISATION DES PRODUITS ET SERVICES (LP ARTOIS)", label: "COMMERCIALISATION DES PRODUITS ET SERVICES (LP ARTOIS)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP CERGY)", label: "METIERS DU COMMERCE INTERNATIONAL (LP CERGY)" },
  { value: "COMMERCE ET DISTRIBUTION (LP NICE)", label: "COMMERCE ET DISTRIBUTION (LP NICE)" },
  { value: "COMMERCE ET DISTRIBUTION (LP STRASBOURG)", label: "COMMERCE ET DISTRIBUTION (LP STRASBOURG)" },
  { value: "COMMERCE ET DISTRIBUTION (LP MONTPELLIER)", label: "COMMERCE ET DISTRIBUTION (LP MONTPELLIER)" },
  { value: "COMMERCE SPE MANAGEMENT ET GESTION DE RAYON (LP PARIS 1)", label: "COMMERCE SPE MANAGEMENT ET GESTION DE RAYON (LP PARIS 1)" },
  { value: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP LIMOGES)", label: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP LIMOGES)" },
  { value: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP MARNE LA VALLEE)", label: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP MARNE LA VALLEE)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP MARNE LA VALLEE)", label: "METIERS DU COMMERCE INTERNATIONAL (LP MARNE LA VALLEE)" },
  { value: "TECHNICO-COMMERCIAL (LP TOURS)", label: "TECHNICO-COMMERCIAL (LP TOURS)" },
  { value: "TECHNICO-COMMERCIAL (LP SAINT ETIENNE)", label: "TECHNICO-COMMERCIAL (LP SAINT ETIENNE)" },
  { value: "E-COMMERCE ET MARKETING NUMERIQUE (LP BESANCON)", label: "E-COMMERCE ET MARKETING NUMERIQUE (LP BESANCON)" },
  { value: "COMMERCE ET DISTRIBUTION (LP LILLE 1)", label: "COMMERCE ET DISTRIBUTION (LP LILLE 1)" },
  { value: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP CNAM)", label: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP CNAM)" },
  { value: "COMMERCE ET DISTRIBUTION (LP AMIENS)", label: "COMMERCE ET DISTRIBUTION (LP AMIENS)" },
  { value: "TECHNICO-COMMERCIAL (LP MULHOUSE)", label: "TECHNICO-COMMERCIAL (LP MULHOUSE)" },
  { value: "METIERS DU MARKETING OPERATIONNEL (LP LORRAINE)", label: "METIERS DU MARKETING OPERATIONNEL (LP LORRAINE)" },
  { value: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP BRETAGNE SUD)", label: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP BRETAGNE SUD)" },
  { value: "COMMERCE ET DISTRIBUTION (LP LORRAINE)", label: "COMMERCE ET DISTRIBUTION (LP LORRAINE)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP REIMS)", label: "METIERS DU COMMERCE INTERNATIONAL (LP REIMS)" },
  { value: "E-COMMERCE ET MARKETING NUMERIQUE (LP TOURS)", label: "E-COMMERCE ET MARKETING NUMERIQUE (LP TOURS)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP CHAMBERY)", label: "METIERS DU COMMERCE INTERNATIONAL (LP CHAMBERY)" },
  { value: "COMMERCE ET DISTRIBUTION (LP DIJON)", label: "COMMERCE ET DISTRIBUTION (LP DIJON)" },
  { value: "COMMERCE SPE COMMERCE ELECTRONIQUE (LP PARIS 8)", label: "COMMERCE SPE COMMERCE ELECTRONIQUE (LP PARIS 8)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP BESANCON)", label: "METIERS DU COMMERCE INTERNATIONAL (LP BESANCON)" },
  { value: "E-COMMERCE ET MARKETING NUMERIQUE (LP CAEN)", label: "E-COMMERCE ET MARKETING NUMERIQUE (LP CAEN)" },
  { value: "COMMERCE ET DISTRIBUTION (LP ARTOIS)", label: "COMMERCE ET DISTRIBUTION (LP ARTOIS)" },
  { value: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP POITIERS)", label: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP POITIERS)" },
  { value: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP MONTPELLIER)", label: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP MONTPELLIER)" },
  { value: "COMMERCE ET DISTRIBUTION (LP ROUEN)", label: "COMMERCE ET DISTRIBUTION (LP ROUEN)" },
  { value: "METIERS DU MARKETING OPERATIONNEL (LP VERSAILLES)", label: "METIERS DU MARKETING OPERATIONNEL (LP VERSAILLES)" },
  { value: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP EVRY)", label: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP EVRY)" },
  { value: "ASSURANCE, BANQUE, FINANCE : SUPPORTS OPERATIONNELS (LP CNAM)", label: "ASSURANCE, BANQUE, FINANCE : SUPPORTS OPERATIONNELS (LP CNAM)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP POITIERS)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP POITIERS)" },
  { value: "METIERS DE L'IMMOBILIER : TRANSACTION ET COMMERCIALISATION DE BIENS IMMOBILIERS (LP EVRY)", label: "METIERS DE L'IMMOBILIER : TRANSACTION ET COMMERCIALISATION DE BIENS IMMOBILIERS (LP EVRY)" },
  { value: "ASSURANCE, BANQUE, FINANCE : SUPPORTS OPERATIONNELS (LP PARIS 5)", label: "ASSURANCE, BANQUE, FINANCE : SUPPORTS OPERATIONNELS (LP PARIS 5)" },
  { value: "ASSURANCE, BANQUE, FINANCE : SUPPORTS OPERATIONNELS (LP LYON 2)", label: "ASSURANCE, BANQUE, FINANCE : SUPPORTS OPERATIONNELS (LP LYON 2)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP GRENOBLE ALPES)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP GRENOBLE ALPES)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP MARNE LA VALLEE)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP MARNE LA VALLEE)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP PAU)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP PAU)" },
  { value: "ASSURANCE, BANQUE, FINANCE : SUPPORTS OPERATIONNELS (LP GRENOBLE ALPES)", label: "ASSURANCE, BANQUE, FINANCE : SUPPORTS OPERATIONNELS (LP GRENOBLE ALPES)" },
  { value: "ASSURANCE, BANQUE, FINANCE SPE GESTION JURIDIQUE DES CONTRATS D'ASSURANCE (LP PARIS 2)", label: "ASSURANCE, BANQUE, FINANCE SPE GESTION JURIDIQUE DES CONTRATS D'ASSURANCE (LP PARIS 2)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP PARIS 12)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP PARIS 12)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : GESTION COMPTABLE ET FINANCIERE (LP LORRAINE)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : GESTION COMPTABLE ET FINANCIERE (LP LORRAINE)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP TOULOUSE 1)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP TOULOUSE 1)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP MULHOUSE)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP MULHOUSE)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP CLERMONT)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP CLERMONT)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP AMIENS)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP AMIENS)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP LORRAINE)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP LORRAINE)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP LYON 3)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP LYON 3)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP VERSAILLES)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP VERSAILLES)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP RENNES 1)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP RENNES 1)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP PARIS 10)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP PARIS 10)" },
  { value: "ASSURANCE, BANQUE, FINANCE SPE CHARGE DE CLIENTELE PARTICULIERS (LP PARIS 13)", label: "ASSURANCE, BANQUE, FINANCE SPE CHARGE DE CLIENTELE PARTICULIERS (LP PARIS 13)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP LILLE 2)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP LILLE 2)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP STRASBOURG)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP STRASBOURG)" },
  { value: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP REIMS)", label: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP REIMS)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP BREST)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP BREST)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP LYON 2)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP LYON 2)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP ORLEANS)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP ORLEANS)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP TOURS)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP TOURS)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP BORDEAUX)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP BORDEAUX)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP DIJON)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP DIJON)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP TOULOUSE 3)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP TOULOUSE 3)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP CAEN)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP CAEN)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP PARIS 5)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP PARIS 5)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP NICE)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP NICE)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP TOULON)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP TOULON)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : RESPONSABLE DE PORTEFEUILLE CLIENTS EN CABINET D'EXPERTISE (LP CNAM)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : RESPONSABLE DE PORTEFEUILLE CLIENTS EN CABINET D'EXPERTISE (LP CNAM)" },
  { value: "ASSURANCE, BANQUE, FINANCE : SUPPORTS OPERATIONNELS (PARIS 13)", label: "ASSURANCE, BANQUE, FINANCE : SUPPORTS OPERATIONNELS (PARIS 13)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP CORSE)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP CORSE)" },
  { value: "ACTIVITES JURIDIQUES : METIERS DU DROIT DE L'IMMOBILIER (LP LIMOGES)", label: "ACTIVITES JURIDIQUES : METIERS DU DROIT DE L'IMMOBILIER (LP LIMOGES)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP MONTPELLIER)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP MONTPELLIER)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP EVRY)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP EVRY)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : CONTROLE DE GESTION (LP PARIS 12)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : CONTROLE DE GESTION (LP PARIS 12)" },
  { value: "ASSURANCE, BANQUE, FINANCE SPE ACTIVITES IMMOBILIERES (LP PARIS 12)", label: "ASSURANCE, BANQUE, FINANCE SPE ACTIVITES IMMOBILIERES (LP PARIS 12)" },
  { value: "METIERS DE L'IMMOBILIER : GESTION ET DEVELOPPEMENT DE PATRIMOINE IMMOBILIER (LP MULHOUSE)", label: "METIERS DE L'IMMOBILIER : GESTION ET DEVELOPPEMENT DE PATRIMOINE IMMOBILIER (LP MULHOUSE)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP CERGY)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP CERGY)" },
  { value: "METIERS DE L'IMMOBILIER : GESTION ET ADMINISTRATION DE BIENS (LP LA ROCHELLE)", label: "METIERS DE L'IMMOBILIER : GESTION ET ADMINISTRATION DE BIENS (LP LA ROCHELLE)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP PARIS 11)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP PARIS 11)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (EVRY)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (EVRY)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP ROUEN)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP ROUEN)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP ST ETIENNE)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP ST ETIENNE)" },
  { value: "METIERS DE L'IMMOBILIER : GESTION ET DEVELOPPEMENT DE PATRIMOINE IMMOBILIER (LP BORDEAUX)", label: "METIERS DE L'IMMOBILIER : GESTION ET DEVELOPPEMENT DE PATRIMOINE IMMOBILIER (LP BORDEAUX)" },
  { value: "METIERS DE L'IMMOBILIER : TRANSACTION ET COMMERCIALISATION DE BIENS IMMOBILIERS (LP BORDEAUX)", label: "METIERS DE L'IMMOBILIER : TRANSACTION ET COMMERCIALISATION DE BIENS IMMOBILIERS (LP BORDEAUX)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP CHAMBERY)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP CHAMBERY)" },
  { value: "ASSURANCE, BANQUE, FINANCE : SUPPORTS OPERATIONNELS (LP PARIS 10)", label: "ASSURANCE, BANQUE, FINANCE : SUPPORTS OPERATIONNELS (LP PARIS 10)" },
  { value: "METIERS DE L'IMMOBILIER : GESTION ET ADMINISTRATION DE BIENS (LP TOULOUSE 3)", label: "METIERS DE L'IMMOBILIER : GESTION ET ADMINISTRATION DE BIENS (LP TOULOUSE 3)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP NANTES)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP NANTES)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP LE MANS)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP LE MANS)" },
  { value: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP ANGERS)", label: "ASSURANCE, BANQUE, FINANCE : CHARGE DE CLIENTELE (LP ANGERS)" },
  { value: "METIERS DE L'IMMOBILIER : GESTION ET DEVELOPPEMENT DE PATRIMOINE IMMOBILIER (LP LORRAINE)", label: "METIERS DE L'IMMOBILIER : GESTION ET DEVELOPPEMENT DE PATRIMOINE IMMOBILIER (LP LORRAINE)" },
  { value: "METIERS DE L'IMMOBILIER : GESTION ET ADMINISTRATION DE BIENS (LP CORSE)", label: "METIERS DE L'IMMOBILIER : GESTION ET ADMINISTRATION DE BIENS (LP CORSE)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : CONTROLE DE GESTION (LP PARIS 11)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : CONTROLE DE GESTION (LP PARIS 11)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : RESPONSABLE DE PORTEFEUILLE CLIENTS EN CABINET D'EXPERTISE (LP MONTPELLIER)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : RESPONSABLE DE PORTEFEUILLE CLIENTS EN CABINET D'EXPERTISE (LP MONTPELLIER)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : CONTROLE DE GESTION (LP MULHOUSE)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : CONTROLE DE GESTION (LP MULHOUSE)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : RESPONSABLE DE PORTEFEUILLE CLIENTS EN CABINET D'EXPERTISE (LP EVRY)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : RESPONSABLE DE PORTEFEUILLE CLIENTS EN CABINET D'EXPERTISE (LP EVRY)" },
  { value: "MANAGEMENT DES ORGANISATIONS SPE METIERS DE LA COMPTABILITE : COMPTABILITE ET GESTION DES ASSOCIATIONS (LP CNAM)", label: "MANAGEMENT DES ORGANISATIONS SPE METIERS DE LA COMPTABILITE : COMPTABILITE ET GESTION DES ASSOCIATIONS (LP CNAM)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP CNAM)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP CNAM)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP BORDEAUX)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP BORDEAUX)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : REVISION COMPTABLE (LP PARIS 10)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : REVISION COMPTABLE (LP PARIS 10)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : REVISION COMPTABLE (LP CERGY)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : REVISION COMPTABLE (LP CERGY)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : GESTION COMPTABLE ET FINANCIERE (LP PERPIGNAN)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : GESTION COMPTABLE ET FINANCIERE (LP PERPIGNAN)" },
  { value: "ASSURANCE, BANQUE, FINANCE SPE METIERS DE LA COMPTABILITE:FISCALITE (LP PARIS 13)", label: "ASSURANCE, BANQUE, FINANCE SPE METIERS DE LA COMPTABILITE:FISCALITE (LP PARIS 13)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : REVISION COMPTABLE (LP PARIS 5)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : REVISION COMPTABLE (LP PARIS 5)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : GESTION COMPTABLE ET FINANCIERE (LP AMIENS)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : GESTION COMPTABLE ET FINANCIERE (LP AMIENS)" },
  { value: "ASSURANCE, BANQUE, FINANCE SPE METIERS DE LA COMPTABILITE:COMPTABILITE ET PAIE (LP PARIS 13)", label: "ASSURANCE, BANQUE, FINANCE SPE METIERS DE LA COMPTABILITE:COMPTABILITE ET PAIE (LP PARIS 13)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : FISCALITE (LP ANGERS)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : FISCALITE (LP ANGERS)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : GESTION COMPTABLE ET FINANCIERE (LP ST ETIENNE)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : GESTION COMPTABLE ET FINANCIERE (LP ST ETIENNE)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP ORLEANS)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP ORLEANS)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : GESTION COMPTABLE ET FINANCIERE (LP CNAM)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : GESTION COMPTABLE ET FINANCIERE (LP CNAM)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP TOULOUSE 1)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP TOULOUSE 1)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : GESTION COMPTABLE ET FINANCIERE (LP CLERMONT AUVERGNE)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : GESTION COMPTABLE ET FINANCIERE (LP CLERMONT AUVERGNE)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : RESPONSABLE DE PORTEFEUILLE CLIENTS EN CABINET D'EXPERTISE (LP NICE)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : RESPONSABLE DE PORTEFEUILLE CLIENTS EN CABINET D'EXPERTISE (LP NICE)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : RESPONSABLE DE PORTEFEUILLE CLIENTS EN CABINET D'EXPERTISE (LP CORSE)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : RESPONSABLE DE PORTEFEUILLE CLIENTS EN CABINET D'EXPERTISE (LP CORSE)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : CONTROLE DE GESTION (LP PARIS 13)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : CONTROLE DE GESTION (LP PARIS 13)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : REVISION COMPTABLE (LP CLERMONT AUVERGNE)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : REVISION COMPTABLE (LP CLERMONT AUVERGNE)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : FISCALITE (LP CHAMBERY)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : FISCALITE (LP CHAMBERY)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : FISCALITE (LP GRENOBLE ALPES)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : FISCALITE (LP GRENOBLE ALPES)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP AIX MARSEILLE)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP AIX MARSEILLE)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : RESPONSABLE DE PORTEFEUILLE CLIENTS EN CABINET D'EXPERTISE (LP AIX MARSEILLE)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : RESPONSABLE DE PORTEFEUILLE CLIENTS EN CABINET D'EXPERTISE (LP AIX MARSEILLE)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : CONTROLE DE GESTION (LP CNAM)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : CONTROLE DE GESTION (LP CNAM)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP LYON 3)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP LYON 3)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP SAINT ETIENNE)", label: "METIERS DE LA GRH : ASSISTANT (LP SAINT ETIENNE)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP LYON 2)", label: "METIERS DE LA GRH : ASSISTANT (LP LYON 2)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP LORRAINE)", label: "METIERS DE LA GRH : ASSISTANT (LP LORRAINE)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP MULHOUSE)", label: "METIERS DE LA GRH : ASSISTANT (LP MULHOUSE)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP LYON 1)", label: "METIERS DE LA GRH : ASSISTANT (LP LYON 1)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP VERSAILLES)", label: "METIERS DE LA GRH : ASSISTANT (LP VERSAILLES)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP PARIS 12)", label: "METIERS DE LA GRH : ASSISTANT (LP PARIS 12)" },
  { value: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP MARNE LA VALLEE)", label: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP MARNE LA VALLEE)" },
  { value: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP ORLEANS)", label: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP ORLEANS)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP EVRY)", label: "METIERS DE LA GRH : ASSISTANT (LP EVRY)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP POITIERS)", label: "METIERS DE LA GRH : ASSISTANT (LP POITIERS)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP PARIS 5)", label: "METIERS DE LA GRH : ASSISTANT (LP PARIS 5)" },
  { value: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP TOURS)", label: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP TOURS)" },
  { value: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP BORDEAUX)", label: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP BORDEAUX)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP MONTPELLIER)", label: "METIERS DE LA GRH : ASSISTANT (LP MONTPELLIER)" },
  { value: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP REIMS)", label: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP REIMS)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP BESANCON)", label: "METIERS DE LA GRH : ASSISTANT (LP BESANCON)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP BRETAGNE SUD)", label: "METIERS DE LA GRH : ASSISTANT (LP BRETAGNE SUD)" },
  { value: "METIERS DE LA GESTION ET D ELA COMPTABILITE : COMPTABILITE ET PAIE (LP MONTPELLIER)", label: "METIERS DE LA GESTION ET D ELA COMPTABILITE : COMPTABILITE ET PAIE (LP MONTPELLIER)" },
  { value: "GESTION DES RESSOURCES HUMAINES SPE GESTION DE LA PAIE ET DU SOCIAL (LP VERSAILLES)", label: "GESTION DES RESSOURCES HUMAINES SPE GESTION DE LA PAIE ET DU SOCIAL (LP VERSAILLES)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP CERGY)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP CERGY)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP LIMOGES)", label: "METIERS DE LA GRH : ASSISTANT (LP LIMOGES)" },
  { value: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP STRASBOURG)", label: "METIERS DE LA GESTION ET DE LA COMPTABILITE : COMPTABILITE ET PAIE (LP STRASBOURG)" },
  { value: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP DIJON)", label: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP DIJON)" },
  { value: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP AVIGNON)", label: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP AVIGNON)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP CLERMONT AUVERGNE)", label: "METIERS DE LA GRH : ASSISTANT (LP CLERMONT AUVERGNE)" },
  { value: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP CLERMONT AUVERGNE)", label: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP CLERMONT AUVERGNE)" },
  { value: "GESTION DES RESSOURCES HUMAINES SPE PETITES ET MOYENNES ORGANISATIONS (LP ANTILLES)", label: "GESTION DES RESSOURCES HUMAINES SPE PETITES ET MOYENNES ORGANISATIONS (LP ANTILLES)" },
  { value: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP AIX MARSEILLE)", label: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP AIX MARSEILLE)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP CAEN)", label: "METIERS DE LA GRH : ASSISTANT (LP CAEN)" },
  { value: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP PARIS 1)", label: "METIERS DE LA GRH : FORMATION, COMPETENCES ET EMPLOI (LP PARIS 1)" },
  { value: "METIERS DE LA GRH : ASSISTANT (LP AMIENS)", label: "METIERS DE LA GRH : ASSISTANT (LP AMIENS)" },
  { value: "TECHNIQUES ET ACTIVITES DE L'IMAGE ET DU SON SPE CREATION ET DEVELOPPEMENT NUMERIQUES EN LIGNE (LP PARIS 8)", label: "TECHNIQUES ET ACTIVITES DE L'IMAGE ET DU SON SPE CREATION ET DEVELOPPEMENT NUMERIQUES EN LIGNE (LP PARIS 8)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP MARNE LA VALLEE)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP MARNE LA VALLEE)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP TOURS)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP TOURS)" },
  { value: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP EVRY)", label: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP EVRY)" },
  { value: "METIERS DE LA COMMUNICATION : EVENEMENTIEL (LP POITIERS)", label: "METIERS DE LA COMMUNICATION : EVENEMENTIEL (LP POITIERS)" },
  { value: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP MULHOUSE)", label: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP MULHOUSE)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP MULHOUSE)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP MULHOUSE)" },
  { value: "ACTIVITES ET TECHNIQUES DE COMMUNICATION SPE REDACTEUR TECHNIQUE (LP PARIS 7)", label: "ACTIVITES ET TECHNIQUES DE COMMUNICATION SPE REDACTEUR TECHNIQUE (LP PARIS 7)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP TOULOUSE 3)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP TOULOUSE 3)" },
  { value: "ACTIVITES ET TECHNIQUES DE COMMUNICATION SPE SCENARISATION MULTIMEDIA DE CONTENUS DE FORMATION EN LIGNE (LP PARIS 13)", label: "ACTIVITES ET TECHNIQUES DE COMMUNICATION SPE SCENARISATION MULTIMEDIA DE CONTENUS DE FORMATION EN LIGNE (LP PARIS 13)" },
  { value: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP MARNE LA VALLEE)", label: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP MARNE LA VALLEE)" },
  { value: "METIERS DE LA COMMUNICATION : CHEF DE PROJET COMMUNICATION (LP PARIS 5)", label: "METIERS DE LA COMMUNICATION : CHEF DE PROJET COMMUNICATION (LP PARIS 5)" },
  { value: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP PERPIGNAN)", label: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP PERPIGNAN)" },
  { value: "E-COMMERCE ET MARKETING NUMERIQUE (LP MULHOUSE)", label: "E-COMMERCE ET MARKETING NUMERIQUE (LP MULHOUSE)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP STRASBOURG)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP STRASBOURG)" },
  { value: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP CERGY)", label: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP CERGY)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP VERSAILLES)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP VERSAILLES)" },
  { value: "TECHNIQUES ET ACTIVITES DE L'IMAGE ET DU SON SPE INFOGRAPHISTE WEB DESIGNER (LP PARIS 13)", label: "TECHNIQUES ET ACTIVITES DE L'IMAGE ET DU SON SPE INFOGRAPHISTE WEB DESIGNER (LP PARIS 13)" },
  { value: "ACTIVITES ET TECHNIQUES DE COMMUNICATION SPE CONCEPTEUR INTEGRATEUR WEB ET MULTI MEDIA (LP PARIS 13)", label: "ACTIVITES ET TECHNIQUES DE COMMUNICATION SPE CONCEPTEUR INTEGRATEUR WEB ET MULTI MEDIA (LP PARIS 13)" },
  { value: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP LA REUNION)", label: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP LA REUNION)" },
  { value: "METIERS DU DESIGN (LP CORSE)", label: "METIERS DU DESIGN (LP CORSE)" },
  { value: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP PARIS 12)", label: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP PARIS 12)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP BESANCON)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP BESANCON)" },
  { value: "METIERS DE L'INFORMATIQUE : CONDUITE DE PROJET (LP LIMOGES)", label: "METIERS DE L'INFORMATIQUE : CONDUITE DE PROJET (LP LIMOGES)" },
  { value: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP ROUEN)", label: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP ROUEN)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP GRENOBLE ALPES)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP GRENOBLE ALPES)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP CERGY)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP CERGY)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP PERPIGNAN)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP PERPIGNAN)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP EVRY)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP EVRY)" },
  { value: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP TOURS)", label: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP TOURS)" },
  { value: "TECHNIQUES DU SON ET DE L'IMAGE (LP LYON 1)", label: "TECHNIQUES DU SON ET DE L'IMAGE (LP LYON 1)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP CLERMONT AUVERGNE)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP CLERMONT AUVERGNE)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP LILLE 3)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP LILLE 3)" },
  { value: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP AMIENS)", label: "METIERS DE LA COMMUNICATION : CHARGE DE COMMUNICATION (LP AMIENS)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP PARIS 13)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP PARIS 13)" },
  { value: "METIERS DE L'INFORMATION : METIERS DU JOURNALISME ET DE LA PRESSE (LP TOURS)", label: "METIERS DE L'INFORMATION : METIERS DU JOURNALISME ET DE LA PRESSE (LP TOURS)" },
  { value: "METIERS DE LA COMMUNICATION : PUBLICITE (LP STRASBOURG)", label: "METIERS DE LA COMMUNICATION : PUBLICITE (LP STRASBOURG)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP IP GRENOBLE)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP IP GRENOBLE)" },
  { value: "METIERS DU LIVRE : EDITION ET COMMERCE DU LIVRE (LP PARIS 10)", label: "METIERS DU LIVRE : EDITION ET COMMERCE DU LIVRE (LP PARIS 10)" },
  { value: "METIERS DU LIVRE : EDITION ET COMMERCE DU LIVRE (LP MARNE LA VALLEE)", label: "METIERS DU LIVRE : EDITION ET COMMERCE DU LIVRE (LP MARNE LA VALLEE)" },
  { value: "METIERS DU LIVRE : EDITION ET COMMERCE DU LIVRE (LP AIX MARSEILLE)", label: "METIERS DU LIVRE : EDITION ET COMMERCE DU LIVRE (LP AIX MARSEILLE)" },
  { value: "METIERS DU LIVRE : EDITION ET COMMERCE DU LIVRE (LP BORDEAUX 3)", label: "METIERS DU LIVRE : EDITION ET COMMERCE DU LIVRE (LP BORDEAUX 3)" },
  { value: "METIERS DU LIVRE : EDITION ET COMMERCE DU LIVRE (LP MULHOUSE)", label: "METIERS DU LIVRE : EDITION ET COMMERCE DU LIVRE (LP MULHOUSE)" },
  { value: "TECHNIQUES DU SON ET DE L'IMAGE (LP MARNE LA VALLEE)", label: "TECHNIQUES DU SON ET DE L'IMAGE (LP MARNE LA VALLEE)" },
  { value: "TECHNIQUES DU SON ET DE L'IMAGE (LP POITIERS)", label: "TECHNIQUES DU SON ET DE L'IMAGE (LP POITIERS)" },
  { value: "COMMUNICATION ET VALORISATION DE LA CREATION ARTISTIQUE (LP LORRAINE)", label: "COMMUNICATION ET VALORISATION DE LA CREATION ARTISTIQUE (LP LORRAINE)" },
  { value: "METIERS DU LIVRE : EDITION ET COMMERCE DU LIVRE (LP PARIS 6)", label: "METIERS DU LIVRE : EDITION ET COMMERCE DU LIVRE (LP PARIS 6)" },
  { value: "TECHNIQUES DU SON ET DE L'IMAGE (LP CHAMBERY)", label: "TECHNIQUES DU SON ET DE L'IMAGE (LP CHAMBERY)" },
  { value: "TECHNIQUES DU SON ET DE L'IMAGE (LP CERGY)", label: "TECHNIQUES DU SON ET DE L'IMAGE (LP CERGY)" },
  { value: "METIERS DE L'INFORMATION : ARCHIVES, MEDIATION ET PATRIMOINE (LP CNAM)", label: "METIERS DE L'INFORMATION : ARCHIVES, MEDIATION ET PATRIMOINE (LP CNAM)" },
  { value: "METIERS DE L'INFORMATION : VEILLE ET GESTION DES RESSOURCES DOCUMENTAIRES (LP MONTPELLIER 3)", label: "METIERS DE L'INFORMATION : VEILLE ET GESTION DES RESSOURCES DOCUMENTAIRES (LP MONTPELLIER 3)" },
  { value: "ACTIVITES ET TECHNIQUES DE COMMUNICATION SPE METIERS DU NUMERIQUE:CONCEPTION, REDACTION ET REALISATION WEB (LP PARIS 13)", label: "ACTIVITES ET TECHNIQUES DE COMMUNICATION SPE METIERS DU NUMERIQUE:CONCEPTION, REDACTION ET REALISATION WEB (LP PARIS 13)" },
  { value: "METIERS DU LIVRE : EDITION ET COMMERCE DU LIVRE (LP PARIS 5)", label: "METIERS DU LIVRE : EDITION ET COMMERCE DU LIVRE (LP PARIS 5)" },
  { value: "METIERS DU LIVRE : DOCUMENTATION ET BIBLIOTHEQUES (LP BORDEAUX 3)", label: "METIERS DU LIVRE : DOCUMENTATION ET BIBLIOTHEQUES (LP BORDEAUX 3)" },
  { value: "METIERS DE L'INFORMATION : ARCHIVES, MEDIATION ET PATRIMOINE (LP LYON 1)", label: "METIERS DE L'INFORMATION : ARCHIVES, MEDIATION ET PATRIMOINE (LP LYON 1)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP TOULOUSE 2/3)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP TOULOUSE 2/3)" },
  { value: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP EVRY)", label: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP EVRY)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP VERSAILLES)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP VERSAILLES)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP AMIENS)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP AMIENS)" },
  { value: "CARTOGRAPHIE, TOPOGRAPHIE ET SYSTEMES D'INFORMATION GEOGRAPHIQUE (LP PERPIGNAN)", label: "CARTOGRAPHIE, TOPOGRAPHIE ET SYSTEMES D'INFORMATION GEOGRAPHIQUE (LP PERPIGNAN)" },
  { value: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP CNAM)", label: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP CNAM)" },
  { value: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP LYON 1)", label: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP LYON 1)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP LYON 1)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP LYON 1)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP TOULOUSE 2)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP TOULOUSE 2)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP SAINT ETIENNE)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP SAINT ETIENNE)" },
  { value: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP GRENOBLE ALPES)", label: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP GRENOBLE ALPES)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP PARIS 13)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP PARIS 13)" },
  { value: "MANAGEMENT DES ORGANISATIONS SPE MANAGEMENT DES RESEAUX ET SYSTEMES D'INFORMATION (LP PARIS 2)", label: "MANAGEMENT DES ORGANISATIONS SPE MANAGEMENT DES RESEAUX ET SYSTEMES D'INFORMATION (LP PARIS 2)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP PARIS 11)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP PARIS 11)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP PARIS 11)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP PARIS 11)" },
  { value: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP LE MANS)", label: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP LE MANS)" },
  { value: "SYTEMES INFORMATIQUES ET LOGICIELS SPE CONCEPTION DES SYSTEMES D'INFORMATION, INTEGRATION ET DECISION (LP PARIS 8)", label: "SYTEMES INFORMATIQUES ET LOGICIELS SPE CONCEPTION DES SYSTEMES D'INFORMATION, INTEGRATION ET DECISION (LP PARIS 8)" },
  { value: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP EVRY)", label: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP EVRY)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP LORRAINE)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP LORRAINE)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP CHAMBERY)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP CHAMBERY)" },
  { value: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP PARIS 5)", label: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP PARIS 5)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP CERGY)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP CERGY)" },
  { value: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP LILLE 2)", label: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP LILLE 2)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP STRASBOURG)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP STRASBOURG)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP AIX MARSEILLE)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP AIX MARSEILLE)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP LORRAINE)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP LORRAINE)" },
  { value: "SYSTEMES INFORMATIQUES ET LOGICIELS SPE BASES DE DONNEES, INTERNET ET SECURITE (LP BDISE PARIS 12)", label: "SYSTEMES INFORMATIQUES ET LOGICIELS SPE BASES DE DONNEES, INTERNET ET SECURITE (LP BDISE PARIS 12)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP ORLEANS)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP ORLEANS)" },
  { value: "SYSTEMES INFORMATIQUES ET LOGICIELS SPE GENIE LOGICIEL, SYSTEME D'INFORMATION (LP PARIS 13)", label: "SYSTEMES INFORMATIQUES ET LOGICIELS SPE GENIE LOGICIEL, SYSTEME D'INFORMATION (LP PARIS 13)" },
  { value: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP CERGY)", label: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP CERGY)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP POITIERS)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP POITIERS)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP BESANCON)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP BESANCON)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP AMIENS)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP AMIENS)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP EVRY)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP EVRY)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP RENNES 1)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP RENNES 1)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP BRETAGNE SUD)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP BRETAGNE SUD)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP NANTES)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP NANTES)" },
  { value: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP LA ROCHELLE)", label: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP LA ROCHELLE)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP CAEN)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP CAEN)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP LIMOGES)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP LIMOGES)" },
  { value: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP BORDEAUX)", label: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP BORDEAUX)" },
  { value: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP VERSAILLES)", label: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP VERSAILLES)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP LA REUNION)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP LA REUNION)" },
  { value: "SYSTEMES INFORMATIQUES ET LOGICIELS SPE COMMUNICATION INFORMATIQUE ET MULTIMEDIA (LP PARIS 8)", label: "SYSTEMES INFORMATIQUES ET LOGICIELS SPE COMMUNICATION INFORMATIQUE ET MULTIMEDIA (LP PARIS 8)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP SAINT ETIENNE)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP SAINT ETIENNE)" },
  { value: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP MARNE LA VALLEE)", label: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP MARNE LA VALLEE)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP BORDEAUX)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP BORDEAUX)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP TOURS)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP TOURS)" },
  { value: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DES DONNEES (LP GRENOBLE ALPES)", label: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DES DONNEES (LP GRENOBLE ALPES)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP LE MANS)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP LE MANS)" },
  { value: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP CNAM)", label: "MANAGEMENT ET GESTION DES ORGANISATIONS (LP CNAM)" },
  { value: "GESTION DES STRUCTURES SANITAIRES ET SOCIALES (LP MARNE LA VALLEE)", label: "GESTION DES STRUCTURES SANITAIRES ET SOCIALES (LP MARNE LA VALLEE)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP PARIS 11)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP PARIS 11)" },
  { value: "METIERS DE LA PROMOTION DES PRODUITS DE SANTE (LP TOULOUSE 3)", label: "METIERS DE LA PROMOTION DES PRODUITS DE SANTE (LP TOULOUSE 3)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : TECHNOLOGIE MEDICALE ET BIOMEDICALE (LP CHAMBERY)", label: "MAINTENANCE ET TECHNOLOGIE : TECHNOLOGIE MEDICALE ET BIOMEDICALE (LP CHAMBERY)" },
  { value: "GESTION DES STRUCTURES SANITAIRES ET SOCIALES (LP PARIS 5)", label: "GESTION DES STRUCTURES SANITAIRES ET SOCIALES (LP PARIS 5)" },
  { value: "METIERS DE LA SANTE : TECHNOLOGIES (LP PARIS 6)", label: "METIERS DE LA SANTE : TECHNOLOGIES (LP PARIS 6)" },
  { value: "OPTIQUE PROFESSIONNELLE (LP TOURS)", label: "OPTIQUE PROFESSIONNELLE (LP TOURS)" },
  { value: "OPTIQUE PROFESSIONNELLE (LP LA ROCHELLE)", label: "OPTIQUE PROFESSIONNELLE (LP LA ROCHELLE)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP CNAM)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP CNAM)" },
  { value: "METIERS DE LA SANTE : TECHNOLOGIES (LP AIX MARSEILLE)", label: "METIERS DE LA SANTE : TECHNOLOGIES (LP AIX MARSEILLE)" },
  { value: "OPTIQUE PROFESSIONNELLE (LP PARIS 11)", label: "OPTIQUE PROFESSIONNELLE (LP PARIS 11)" },
  { value: "OPTIQUE PROFESSIONNELLE (LP STRASBOURG)", label: "OPTIQUE PROFESSIONNELLE (LP STRASBOURG)" },
  { value: "METIERS DE LA PROMOTION DES PRODUITS DE SANTE (LP MONTPELLIER)", label: "METIERS DE LA PROMOTION DES PRODUITS DE SANTE (LP MONTPELLIER)" },
  { value: "METIERS DE LA SANTE : TECHNOLOGIES (LP DIJON)", label: "METIERS DE LA SANTE : TECHNOLOGIES (LP DIJON)" },
  { value: "OPTIQUE PROFESSIONNELLE (LP LORRAINE)", label: "OPTIQUE PROFESSIONNELLE (LP LORRAINE)" },
  { value: "SERVICES A LA PERSONNE (LP EVRY)", label: "SERVICES A LA PERSONNE (LP EVRY)" },
  { value: "INTERVENTION SOCIALE : ACCOMPAGNEMENT DE PUBLICS SPECIFIQUES (LP PARIS 12)", label: "INTERVENTION SOCIALE : ACCOMPAGNEMENT DE PUBLICS SPECIFIQUES (LP PARIS 12)" },
  { value: "GESTION ET ACCOMPAGNEMENT DE PROJETS PEDAGOGIQUES (SUP AGRO MONTPELLIER)", label: "GESTION ET ACCOMPAGNEMENT DE PROJETS PEDAGOGIQUES (SUP AGRO MONTPELLIER)" },
  { value: "HOTELLERIE ET TOURISME SPE HOTELLERIE INTERNATIONALE ET HEBERGEMENTS TOURISTIQUES (LP PARIS 1)", label: "HOTELLERIE ET TOURISME SPE HOTELLERIE INTERNATIONALE ET HEBERGEMENTS TOURISTIQUES (LP PARIS 1)" },
  { value: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP VERSAILLES)", label: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP VERSAILLES)" },
  { value: "METIERS DU TOURISME ET DES LOISIRS (LP MARNE LA VALLEE)", label: "METIERS DU TOURISME ET DES LOISIRS (LP MARNE LA VALLEE)" },
  { value: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP MARNE LA VALLEE)", label: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP MARNE LA VALLEE)" },
  { value: "METIERS DU TOURISME : COMMERCIALISATION DES PRODUITS TOURISTIQUES (LP MARNE LA VALLEE)", label: "METIERS DU TOURISME : COMMERCIALISATION DES PRODUITS TOURISTIQUES (LP MARNE LA VALLEE)" },
  { value: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP AVIGNON)", label: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP AVIGNON)" },
  { value: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP TOURS)", label: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP TOURS)" },
  { value: "METIERS DU TOURISME : COMMERCIALISATION DES PRODUITS TOURISTIQUES (LP PARIS 5)", label: "METIERS DU TOURISME : COMMERCIALISATION DES PRODUITS TOURISTIQUES (LP PARIS 5)" },
  { value: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP GRENOBLE ALPES)", label: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP GRENOBLE ALPES)" },
  { value: "METIERS DU TOURISME : COMMUNICATION ET VALORISATION DES TERRITOIRES (LP MULHOUSE)", label: "METIERS DU TOURISME : COMMUNICATION ET VALORISATION DES TERRITOIRES (LP MULHOUSE)" },
  { value: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP EVRY)", label: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP EVRY)" },
  { value: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP MONTPELLIER)", label: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP MONTPELLIER)" },
  { value: "METIERS DES ARTS CULINAIRES ET DES ARTS DE LA TABLE (LP CERGY)", label: "METIERS DES ARTS CULINAIRES ET DES ARTS DE LA TABLE (LP CERGY)" },
  { value: "METIERS DU TOURISME ET DES LOISIRS (LP CERGY)", label: "METIERS DU TOURISME ET DES LOISIRS (LP CERGY)" },
  { value: "HOTELLERIE ET TOURISME SPE MANAGEMENT DE PRODUITS DE RESTAURATION (LP PARIS 13)", label: "HOTELLERIE ET TOURISME SPE MANAGEMENT DE PRODUITS DE RESTAURATION (LP PARIS 13)" },
  { value: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP CERGY)", label: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP CERGY)" },
  { value: "METIERS DU TOURISME : COMMERCIALISATION DES PRODUITS TOURISTIQUES (LP CORSE)", label: "METIERS DU TOURISME : COMMERCIALISATION DES PRODUITS TOURISTIQUES (LP CORSE)" },
  { value: "HOTELLERIE ET TOURISME SPE DISTRIBUTION TOURISTIQUE (LP PARIS 1)", label: "HOTELLERIE ET TOURISME SPE DISTRIBUTION TOURISTIQUE (LP PARIS 1)" },
  { value: "METIERS DE LA FORME (LP BORDEAUX)", label: "METIERS DE LA FORME (LP BORDEAUX)" },
  { value: "METIERS DU TOURISME ET DES LOISIRS (LP LA REUNION)", label: "METIERS DU TOURISME ET DES LOISIRS (LP LA REUNION)" },
  { value: "METIERS DU TOURISME : COMMERCIALISATION DES PRODUITS TOURISTIQUES (LP LA ROCHELLE)", label: "METIERS DU TOURISME : COMMERCIALISATION DES PRODUITS TOURISTIQUES (LP LA ROCHELLE)" },
  { value: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP LE MANS)", label: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP LE MANS)" },
  { value: "METIERS DU TOURISME ET DES LOISIRS (LP CNAM)", label: "METIERS DU TOURISME ET DES LOISIRS (LP CNAM)" },
  { value: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP CHAMBERY)", label: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP CHAMBERY)" },
  { value: "METIERS DU TOURISME ET DES LOISIRS (LP LORRAINE)", label: "METIERS DU TOURISME ET DES LOISIRS (LP LORRAINE)" },
  { value: "METIERS DU TOURISME : COMMERCIALISATION DES PRODUITS TOURISTIQUES (LP EVRY)", label: "METIERS DU TOURISME : COMMERCIALISATION DES PRODUITS TOURISTIQUES (LP EVRY)" },
  { value: "TOURISME ET LOISIRS SPORTIFS (LP MARNE LA VALLEE)", label: "TOURISME ET LOISIRS SPORTIFS (LP MARNE LA VALLEE)" },
  { value: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP PARIS 10)", label: "ORGANISATION ET GESTION DES ETABLISSEMENTS HOTELIERS ET DE RESTAURATION (LP PARIS 10)" },
  { value: "METIERS DES ARTS CULINAIRES ET DES ARTS DE LA TABLE (LP CNAM)", label: "METIERS DES ARTS CULINAIRES ET DES ARTS DE LA TABLE (LP CNAM)" },
  { value: "METIERS DU TOURISME : COMMUNICATION ET VALORISATION DES TERRITOIRES (LP DIJON)", label: "METIERS DU TOURISME : COMMUNICATION ET VALORISATION DES TERRITOIRES (LP DIJON)" },
  { value: "METIERS DU TOURISME ET DES LOISIRS (LP AIX MARSEILLE)", label: "METIERS DU TOURISME ET DES LOISIRS (LP AIX MARSEILLE)" },
  { value: "METIERS DU TOURISME ET DES LOISIRS (LP GUYANE)", label: "METIERS DU TOURISME ET DES LOISIRS (LP GUYANE)" },
  { value: "METIERS DU TOURISME : COMMERCIALISATION DES PRODUITS TOURISTIQUES (LP LYON 2)", label: "METIERS DU TOURISME : COMMERCIALISATION DES PRODUITS TOURISTIQUES (LP LYON 2)" },
  { value: "INTERVENTION SOCIALE SPE ANIMATION PROFESSIONNELLE, COORDINATION ET DEV DE PROJETS POUR L'ACTION SOCIALE, CULTUR ET SOCIOCULTURELLE (PARIS 13)", label: "INTERVENTION SOCIALE SPE ANIMATION PROFESSIONNELLE, COORDINATION ET DEV DE PROJETS POUR L'ACTION SOCIALE, CULTUR ET SOCIOCULTURELLE (PARIS 13)" },
  { value: "METIERS DE LA MEDIATION PAR DES APPROCHES ARTISTIQUES ET CULTURELLES (LP LA REUNION)", label: "METIERS DE LA MEDIATION PAR DES APPROCHES ARTISTIQUES ET CULTURELLES (LP LA REUNION)" },
  { value: "ANIMATION, GESTION ET ORGANISATION DES ACTIVITES PHYSIQUES ET SPORTIVES (LP POITIERS)", label: "ANIMATION, GESTION ET ORGANISATION DES ACTIVITES PHYSIQUES ET SPORTIVES (LP POITIERS)" },
  { value: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP STRASBOURG)", label: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP STRASBOURG)" },
  { value: "METIERS DE LA FORME (LP PARIS 11)", label: "METIERS DE LA FORME (LP PARIS 11)" },
  { value: "METIERS DE L'ANIMATION SOCIALE, SOCIO-EDUCATIVE ET SOCIOCULTURELLE (LP PARIS 5)", label: "METIERS DE L'ANIMATION SOCIALE, SOCIO-EDUCATIVE ET SOCIOCULTURELLE (LP PARIS 5)" },
  { value: "GESTION DE PROJETS ET STRUCTURES ARTISTIQUES ET CULTURELS (LP DIJON)", label: "GESTION DE PROJETS ET STRUCTURES ARTISTIQUES ET CULTURELS (LP DIJON)" },
  { value: "GESTION DE PROJETS ET STRUCTURES ARTISTIQUES ET CULTURELS (LP MULHOUSE)", label: "GESTION DE PROJETS ET STRUCTURES ARTISTIQUES ET CULTURELS (LP MULHOUSE)" },
  { value: "METIERS DE L'AMENAGEMENT DU TERRITOIRE ET DE L'URBANISME (LP PERPIGAN)", label: "METIERS DE L'AMENAGEMENT DU TERRITOIRE ET DE L'URBANISME (LP PERPIGAN)" },
  { value: "SERVICES A LA PERSONNE (LP VERSAILLES)", label: "SERVICES A LA PERSONNE (LP VERSAILLES)" },
  { value: "GESTION DE PROJETS ET STRUCTURES ARTISTIQUES ET CULTURELS (LP CHAMBERY)", label: "GESTION DE PROJETS ET STRUCTURES ARTISTIQUES ET CULTURELS (LP CHAMBERY)" },
  { value: "METIERS DE L'AMENAGEMENT DU TERRITOIRE ET DE L'URBANISME (LP PARIS 11)", label: "METIERS DE L'AMENAGEMENT DU TERRITOIRE ET DE L'URBANISME (LP PARIS 11)" },
  { value: "AMENAGEMENT DU TERRITOIRE ET URBANISME SPE URBANISME, ENVIRONNEMENT ET GEOMATIQUE (LP PARIS 4)", label: "AMENAGEMENT DU TERRITOIRE ET URBANISME SPE URBANISME, ENVIRONNEMENT ET GEOMATIQUE (LP PARIS 4)" },
  { value: "DEVELOPPEMENT DE PROJETS DE TERRITOIRES (LP CNAM)", label: "DEVELOPPEMENT DE PROJETS DE TERRITOIRES (LP CNAM)" },
  { value: "AMENAGEMENT DU TERRITOIRE ET URBANISME SPE RESPONSABLE DE COLLECTIVITES LOCALES, DE PROJETS EUROPEENS, DE PROJETS URBAINS ET D'ENVIRONNEMENT (LP REIMS)", label: "AMENAGEMENT DU TERRITOIRE ET URBANISME SPE RESPONSABLE DE COLLECTIVITES LOCALES, DE PROJETS EUROPEENS, DE PROJETS URBAINS ET D'ENVIRONNEMENT (LP REIMS)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP LYON 2)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP LYON 2)" },
  { value: "METIERS DE L'AMENAGEMENT DU TERRITOIRE ET DE L'URBANISME (LP MARNE LA VALLEE)", label: "METIERS DE L'AMENAGEMENT DU TERRITOIRE ET DE L'URBANISME (LP MARNE LA VALLEE)" },
  { value: "METIERS DE L'AMENAGEMENT DU TERRITOIRE ET DE L'URBANISME (LP LORRAINE)", label: "METIERS DE L'AMENAGEMENT DU TERRITOIRE ET DE L'URBANISME (LP LORRAINE)" },
  { value: "DEVELOPPEMENT DE PROJETS DE TERRITOIRES (LP BORDEAUX 3)", label: "DEVELOPPEMENT DE PROJETS DE TERRITOIRES (LP BORDEAUX 3)" },
  { value: "METIERS DE L'AMENAGEMENT DU TERRITOIRE ET DE L'URBANISME (LP EVRY)", label: "METIERS DE L'AMENAGEMENT DU TERRITOIRE ET DE L'URBANISME (LP EVRY)" },
  { value: "METIERS DE L'AMENAGEMENT DU TERRITOIRE ET DE L'URBANISME (LP LILLE 1)", label: "METIERS DE L'AMENAGEMENT DU TERRITOIRE ET DE L'URBANISME (LP LILLE 1)" },
  { value: "PROTECTION ET VALORISATION DU PATRIMOINE HISTORIQUE ET CULTUREL (LP CERGY)", label: "PROTECTION ET VALORISATION DU PATRIMOINE HISTORIQUE ET CULTUREL (LP CERGY)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP PARIS 11)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP PARIS 11)" },
  { value: "METIERS DE LA SANTE : TECHNOLOGIES (LP BORDEAUX)", label: "METIERS DE LA SANTE : TECHNOLOGIES (LP BORDEAUX)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP VERSAILLES)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP VERSAILLES)" },
  { value: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP BRETAGNE SUD)", label: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP BRETAGNE SUD)" },
  { value: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP AMIENS)", label: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP AMIENS)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP PAU)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP PAU)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP PARIS 6)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP PARIS 6)" },
  { value: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP VERSAILLES)", label: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP VERSAILLES)" },
  { value: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP TOURS)", label: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP TOURS)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP CORSE)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP CORSE)" },
  { value: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP PERPIGNAN)", label: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP PERPIGNAN)" },
  { value: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP LORRAINE)", label: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP LORRAINE)" },
  { value: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP LORRAINE)", label: "METIERS DU BTP : GENIE CIVIL ET CONSTRUCTION (LP LORRAINE)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP NANTES)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP NANTES)" },
  { value: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP POITIERS)", label: "CHIMIE ANALYTIQUE, CONTROLE, QUALITE, ENVIRONNEMENT (LP POITIERS)" },
  { value: "PROTECTION DE L'ENVIRONNEMENT SPE GESTION ET TRAITEMENT DES DECHETS (LP PARIS 7)", label: "PROTECTION DE L'ENVIRONNEMENT SPE GESTION ET TRAITEMENT DES DECHETS (LP PARIS 7)" },
  { value: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP CNAM)", label: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP CNAM)" },
  { value: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP CHAMBERY)", label: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP CHAMBERY)" },
  { value: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP POITIERS)", label: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP POITIERS)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP MARNE LA VALLEE)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP MARNE LA VALLEE)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP NIMES)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP NIMES)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP MONTPELLIER)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP MONTPELLIER)" },
  { value: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP MONTPELLIER)", label: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP MONTPELLIER)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP TOULOUSE 3)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP TOULOUSE 3)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP LYON 1)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP LYON 1)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP MONTPELLIER 3)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP MONTPELLIER 3)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP GRENOBLE ALPES)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP GRENOBLE ALPES)" },
  { value: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP SAINT ETIENNE)", label: "GENIE DES PROCEDES POUR L'ENVIRONNEMENT (LP SAINT ETIENNE)" },
  { value: "METIERS DE LA RADIOPROTECTION ET DE LA SECURITE NUCLEAIRE (LP AIX MARSEILLE)", label: "METIERS DE LA RADIOPROTECTION ET DE LA SECURITE NUCLEAIRE (LP AIX MARSEILLE)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP PARIS 7)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP PARIS 7)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP LIMOGES)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP LIMOGES)" },
  { value: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP LA ROCHELLE)", label: "METIERS DE LA PROTECTION ET DE LA GESTION DE L'ENVIRONNEMENT (LP LA ROCHELLE)" },
  { value: "SECURITE DES BIENS ET DES PERSONNES (PARIS 5)", label: "SECURITE DES BIENS ET DES PERSONNES (PARIS 5)" },
  { value: "SECURITE DES BIENS ET DES PERSONNES (LP MULHOUSE)", label: "SECURITE DES BIENS ET DES PERSONNES (LP MULHOUSE)" },
  { value: "SECURITE DES BIENS ET DES PERSONNES (LP PARIS 13)", label: "SECURITE DES BIENS ET DES PERSONNES (LP PARIS 13)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP MARNE LA VALLEE)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP MARNE LA VALLEE)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP REIMS)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP REIMS)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP TOULOUSE 1)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP TOULOUSE 1)" },
  { value: "SECURITE DES BIENS ET DES PERSONNES (LP BESANCON)", label: "SECURITE DES BIENS ET DES PERSONNES (LP BESANCON)" },
  { value: "SECURITE DES BIENS ET DES PERSONNES (LP LIMOGES)", label: "SECURITE DES BIENS ET DES PERSONNES (LP LIMOGES)" },
  { value: "METIERS DU NOTARIAT (LP CHAMBERY)", label: "METIERS DU NOTARIAT (LP CHAMBERY)" },
  { value: "DIPLOME DE PREMIER CYCLE DE L'UNIVERSITE PARIS DAUPHINE, MENTION GESTION", label: "DIPLOME DE PREMIER CYCLE DE L'UNIVERSITE PARIS DAUPHINE, MENTION GESTION" },
  { value: "DIPLOME NATIONAL SUPERIEUR PROFESSIONNEL D'ARTISTE DE CIRQUE", label: "DIPLOME NATIONAL SUPERIEUR PROFESSIONNEL D'ARTISTE DE CIRQUE" },
  { value: "DIPLOME NATIONAL SUPERIEUR PROFESSIONNEL DE COMEDIEN", label: "DIPLOME NATIONAL SUPERIEUR PROFESSIONNEL DE COMEDIEN" },
  { value: "DIPLOME NATIONAL SUPERIEUR PROFESSIONNEL DE DANSEUR", label: "DIPLOME NATIONAL SUPERIEUR PROFESSIONNEL DE DANSEUR" },
  { value: "DIPLOME D'ETUDES EN ARCHITECTURE (DEEA)", label: "DIPLOME D'ETUDES EN ARCHITECTURE (DEEA)" },
  { value: "DIPLOME DE L'ECOLE SUPERIEURE DE COMMERCE D'AMIENS", label: "DIPLOME DE L'ECOLE SUPERIEURE DE COMMERCE D'AMIENS" },
  { value: "DIPLOME DE FORMATION EN MANAGEMENT GENERAL DE NEOMA", label: "DIPLOME DE FORMATION EN MANAGEMENT GENERAL DE NEOMA" },
  { value: "DIPLOME EN MANAGEMENT INTERNATIONAL DE BREST BUSINESS SCHOOL", label: "DIPLOME EN MANAGEMENT INTERNATIONAL DE BREST BUSINESS SCHOOL" },
  { value: "DIPLOME SUPERIEUR DE MANAGEMENT INTERNATIONAL DE L'ENTREPRISE DE L'EDHEC", label: "DIPLOME SUPERIEUR DE MANAGEMENT INTERNATIONAL DE L'ENTREPRISE DE L'EDHEC" },
  { value: "DIPLOME D'ETUDES SUPERIEURES DE GESTION ET COMMERCE INTERNATIONAL (ESC DIJON)", label: "DIPLOME D'ETUDES SUPERIEURES DE GESTION ET COMMERCE INTERNATIONAL (ESC DIJON)" },
  { value: "DIPLOME SUPERIEUR DE GESTION ET DE COMMERCE - PROG BACHELOR KEDGE BUSSINESS SCHOOL", label: "DIPLOME SUPERIEUR DE GESTION ET DE COMMERCE - PROG BACHELOR KEDGE BUSSINESS SCHOOL" },
  { value: "DIPLOME D'ETUDES SUPERIEURES EN COMMERCE ET MANAGEMENT OPERATIONNEL DE LA ROCHELLE", label: "DIPLOME D'ETUDES SUPERIEURES EN COMMERCE ET MANAGEMENT OPERATIONNEL DE LA ROCHELLE" },
  { value: "DIPLOME DU CENTRE DE MANAGEMENT COMMERCIAL ET INTERNATIONAL (CMCI)", label: "DIPLOME DU CENTRE DE MANAGEMENT COMMERCIAL ET INTERNATIONAL (CMCI)" },
  { value: "DIPLOME DE L'ECOLE INTERNATIONALE DE MANAGEMENT DE L'ESC TROYES", label: "DIPLOME DE L'ECOLE INTERNATIONALE DE MANAGEMENT DE L'ESC TROYES" },
  { value: "DIPLOME EN MANAGEMENT INTERNATIONAL (ESC CLERMONT)", label: "DIPLOME EN MANAGEMENT INTERNATIONAL (ESC CLERMONT)" },
  { value: "DIPLOME DE L'ECOLE ATLANTIQUE DE COMMERCE DE NANTES", label: "DIPLOME DE L'ECOLE ATLANTIQUE DE COMMERCE DE NANTES" },
  { value: "DIPLOME DE FORMATION INTERNATIONALE EN MANAGEMENT", label: "DIPLOME DE FORMATION INTERNATIONALE EN MANAGEMENT" },
  { value: "DIPLOME D'ENSEIGNEMENT SUPERIEUR EN MANAGEMENT INTERNATIONAL (EM NORMANDIE)", label: "DIPLOME D'ENSEIGNEMENT SUPERIEUR EN MANAGEMENT INTERNATIONAL (EM NORMANDIE)" },
  { value: "DIPLOME DE L'ECOLE DES PRATICIENS DU COMMERCE INTERNATIONAL DE CERGY", label: "DIPLOME DE L'ECOLE DES PRATICIENS DU COMMERCE INTERNATIONAL DE CERGY" },
  { value: "DIPLOME MANAGEMENT RELATION CLIENTS (DIPLOME GROUPE ESC PAU)", label: "DIPLOME MANAGEMENT RELATION CLIENTS (DIPLOME GROUPE ESC PAU)" },
  { value: "COMPTABILITE ET GESTION (DIPLÔME DE) (DCG)", label: "COMPTABILITE ET GESTION (DIPLÔME DE) (DCG)" },
  { value: "INFIRMIER OU INFIRMIERE (DIPLOME D'ETAT)", label: "INFIRMIER OU INFIRMIERE (DIPLOME D'ETAT)" },
  { value: "ERGOTHERAPEUTE (DIPLOME D'ETAT)", label: "ERGOTHERAPEUTE (DIPLOME D'ETAT)" },
  { value: "EDUCATEUR SPECIALISE (DIPLOME D'ETAT)", label: "EDUCATEUR SPECIALISE (DIPLOME D'ETAT)" },
  { value: "EDUCATEUR DE JEUNES ENFANTS (DIPLOME D'ETAT)", label: "EDUCATEUR DE JEUNES ENFANTS (DIPLOME D'ETAT)" },
  { value: "EDUCATEUR TECHNIQUE SPECIALISE (DIPLOME D'ETAT)", label: "EDUCATEUR TECHNIQUE SPECIALISE (DIPLOME D'ETAT)" },
  { value: "ASSISTANT DE SERVICE SOCIAL (DIPLOME D'ETAT)", label: "ASSISTANT DE SERVICE SOCIAL (DIPLOME D'ETAT)" },
  { value: "RESPONSABLE COMMERCIAL(E) EN AGROBUSINESS (CCI IDF - TECOMAH)", label: "RESPONSABLE COMMERCIAL(E) EN AGROBUSINESS (CCI IDF - TECOMAH)" },
  { value: "RESPONSABLE COMMERCIAL POUR L'AGRO-ALIMENTAIRE (RC2A)", label: "RESPONSABLE COMMERCIAL POUR L'AGRO-ALIMENTAIRE (RC2A)" },
  { value: "RESPONSABLE DE CENTRE DE PROFIT EN DISTRIBUTION (CNEAP)", label: "RESPONSABLE DE CENTRE DE PROFIT EN DISTRIBUTION (CNEAP)" },
  { value: "DESIGN DE PRODUITS (BTS)", label: "DESIGN DE PRODUITS (BTS)" },
  { value: "TECHNICO-COMMERCIAL (BTS)", label: "TECHNICO-COMMERCIAL (BTS)" },
  { value: "CONCEPTION DES PRODUITS INDUSTRIELS (BTS)", label: "CONCEPTION DES PRODUITS INDUSTRIELS (BTS)" },
  { value: "CONCEPTION ET REALISATION DES SYSTEMES AUTOMATIQUES (BTS)", label: "CONCEPTION ET REALISATION DES SYSTEMES AUTOMATIQUES (BTS)" },
  { value: "SYSTEMES NUMERIQUES OPTION A : INFORMATIQUE ET RESEAUX (BTS)", label: "SYSTEMES NUMERIQUES OPTION A : INFORMATIQUE ET RESEAUX (BTS)" },
  { value: "CONTROLE INDUSTRIEL ET REGULATION AUTOMATIQUE (BTS)", label: "CONTROLE INDUSTRIEL ET REGULATION AUTOMATIQUE (BTS)" },
  { value: "METIERS DE L'EAU (BTS)", label: "METIERS DE L'EAU (BTS)" },
  { value: "TECHNIQUES PHYSIQUES POUR L'INDUSTRIE ET LE LABORATOIRE (BTS)", label: "TECHNIQUES PHYSIQUES POUR L'INDUSTRIE ET LE LABORATOIRE (BTS)" },
  { value: "PILOTAGES DE PROCEDES (BTS)", label: "PILOTAGES DE PROCEDES (BTS)" },
  { value: "QUALITE DANS LES INDUSTRIES ALIMENTAIRES ET LES BIO INDUSTRIES (BTS)", label: "QUALITE DANS LES INDUSTRIES ALIMENTAIRES ET LES BIO INDUSTRIES (BTS)" },
  { value: "BIOTECHNOLOGIES (BTS)", label: "BIOTECHNOLOGIES (BTS)" },
  { value: "BIOANALYSES ET CONTROLE (BTS)", label: "BIOANALYSES ET CONTROLE (BTS)" },
  { value: "METIERS DE LA CHIMIE (BTS)", label: "METIERS DE LA CHIMIE (BTS)" },
  { value: "TRAITEMENT DES MATERIAUX BTS 1ERE ANNEE COMMUNE", label: "TRAITEMENT DES MATERIAUX BTS 1ERE ANNEE COMMUNE" },
  { value: "TRAITEMENTS DES MATERIAUX OPTION A : TRAITEMENTS THERMIQUES (BTS)", label: "TRAITEMENTS DES MATERIAUX OPTION A : TRAITEMENTS THERMIQUES (BTS)" },
  { value: "TRAITEMENTS DES MATERIAUX OPTION B : TRAITEMENTS DE SURFACES (BTS)", label: "TRAITEMENTS DES MATERIAUX OPTION B : TRAITEMENTS DE SURFACES (BTS)" },
  { value: "FONDERIE (BTS)", label: "FONDERIE (BTS)" },
  { value: "CONCEPTION DES PROCESSUS DE REALISATION DE PRODUITS BTS 1ERE ANNEE COMMUNE", label: "CONCEPTION DES PROCESSUS DE REALISATION DE PRODUITS BTS 1ERE ANNEE COMMUNE" },
  { value: "CONCEPTION DES PROCESSUS DE REALISATION DE PRODUITS OPTION B PRODUCTION SERIELLE (BTS)", label: "CONCEPTION DES PROCESSUS DE REALISATION DE PRODUITS OPTION B PRODUCTION SERIELLE (BTS)" },
  { value: "CONCEPTION DES PROCESSUS DE REALISATION DE PRODUITS OPTION A PRODUCTION UNITAIRE (BTS)", label: "CONCEPTION DES PROCESSUS DE REALISATION DE PRODUITS OPTION A PRODUCTION UNITAIRE (BTS)" },
  { value: "INDUSTRIES CERAMIQUES (BTS)", label: "INDUSTRIES CERAMIQUES (BTS)" },
  { value: "CONCEPTEUR EN ART & INDUSTRIE CERAMIQUE (BTS)", label: "CONCEPTEUR EN ART & INDUSTRIE CERAMIQUE (BTS)" },
  { value: "EUROPLASTICS ET COMPOSITES, OPTION CO : CONCEPTION OUTILLAGE (BTS)", label: "EUROPLASTICS ET COMPOSITES, OPTION CO : CONCEPTION OUTILLAGE (BTS)" },
  { value: "EUROPLASTICS ET COMPOSITES, OPTION POP : PILOTAGE ET OPTIMISATION DE LA PRODUCTION (BTS)", label: "EUROPLASTICS ET COMPOSITES, OPTION POP : PILOTAGE ET OPTIMISATION DE LA PRODUCTION (BTS)" },
  { value: "CONTROLE, RAYONNEMENT IONISANTS, APPLICATION TECHNIQUE DE PROTECTION (BTS)", label: "CONTROLE, RAYONNEMENT IONISANTS, APPLICATION TECHNIQUE DE PROTECTION (BTS)" },
  { value: "FLUIDES-ENERGIES-DOMOTIQUE OPTION A GENIE CLIMATIQUE ET FLUIDIQUE (BTS)", label: "FLUIDES-ENERGIES-DOMOTIQUE OPTION A GENIE CLIMATIQUE ET FLUIDIQUE (BTS)" },
  { value: "FLUIDES-ENERGIES-DOMOTIQUE OPTION B FROID ET CONDITIONNEMENT D'AIR (BTS)", label: "FLUIDES-ENERGIES-DOMOTIQUE OPTION B FROID ET CONDITIONNEMENT D'AIR (BTS)" },
  { value: "FLUIDES-ENERGIES-DOMOTIQUE OPTION C DOMOTIQUE ET BATIMENTS COMMUNICANTS (BTS)", label: "FLUIDES-ENERGIES-DOMOTIQUE OPTION C DOMOTIQUE ET BATIMENTS COMMUNICANTS (BTS)" },
  { value: "ETUDES ET ECONOMIE DE LA CONSTRUCTION (BTS)", label: "ETUDES ET ECONOMIE DE LA CONSTRUCTION (BTS)" },
  { value: "DESIGN D'ESPACE (BTS)", label: "DESIGN D'ESPACE (BTS)" },
  { value: "BATIMENT (BTS)", label: "BATIMENT (BTS)" },
  { value: "TRAVAUX PUBLICS (BTS)", label: "TRAVAUX PUBLICS (BTS)" },
  { value: "METIERS DU GEOMETRE-TOPOGRAPHE ET DE LA MODELISATION NUMERIQUE (BTS)", label: "METIERS DU GEOMETRE-TOPOGRAPHE ET DE LA MODELISATION NUMERIQUE (BTS)" },
  { value: "ENVELOPPE DES BATIMENTS : CONCEPTION ET REALISATION (BTS)", label: "ENVELOPPE DES BATIMENTS : CONCEPTION ET REALISATION (BTS)" },
  { value: "AMENAGEMENT FINITION (BTS)", label: "AMENAGEMENT FINITION (BTS)" },
  { value: "ETUDE ET REALISATION D'AGENCEMENT (BTS)", label: "ETUDE ET REALISATION D'AGENCEMENT (BTS)" },
  { value: "DEVELOPPEMENT ET REALISATION BOIS (BTS)", label: "DEVELOPPEMENT ET REALISATION BOIS (BTS)" },
  { value: "SYSTEMES CONSTRUCTIFS BOIS ET HABITAT (BTS)", label: "SYSTEMES CONSTRUCTIFS BOIS ET HABITAT (BTS)" },
  { value: "INNOVATION TEXTILE OPTION A STRUCTURES (BTS)", label: "INNOVATION TEXTILE OPTION A STRUCTURES (BTS)" },
  { value: "INNOVATION TEXTILE OPTION B TRAITEMENTS (BTS)", label: "INNOVATION TEXTILE OPTION B TRAITEMENTS (BTS)" },
  { value: "METIERS DE LA MODE-VETEMENTS (BTS)", label: "METIERS DE LA MODE-VETEMENTS (BTS)" },
  { value: "METIERS DE LA MODE-CHAUSSURE ET MAROQUINERIE (BTS)", label: "METIERS DE LA MODE-CHAUSSURE ET MAROQUINERIE (BTS)" },
  { value: "ASSISTANCE TECHNIQUE D'INGENIEUR (BTS)", label: "ASSISTANCE TECHNIQUE D'INGENIEUR (BTS)" },
  { value: "CONCEPTION ET INDUSTRIALISATION EN MICROTECHNIQUES (BTS)", label: "CONCEPTION ET INDUSTRIALISATION EN MICROTECHNIQUES (BTS)" },
  { value: "MAINTENANCE DES SYSTEMES OPTION A SYSTEMES DE PRODUCTION (BTS)", label: "MAINTENANCE DES SYSTEMES OPTION A SYSTEMES DE PRODUCTION (BTS)" },
  { value: "MAINTENANCE DES SYSTEMES OPTION B SYSTEMES ENERGETIQUES ET FLUIDIQUES (BTS)", label: "MAINTENANCE DES SYSTEMES OPTION B SYSTEMES ENERGETIQUES ET FLUIDIQUES (BTS)" },
  { value: "MAINTENANCE DES SYSTEMES OPTION C SYSTEMES EOLIENS (BTS)", label: "MAINTENANCE DES SYSTEMES OPTION C SYSTEMES EOLIENS (BTS)" },
  { value: "TECHNIQUES ET SERVICES EN MATERIELS AGRICOLES (BTS)", label: "TECHNIQUES ET SERVICES EN MATERIELS AGRICOLES (BTS)" },
  { value: "MOTEURS A COMBUSTION INTERNE (BTS)", label: "MOTEURS A COMBUSTION INTERNE (BTS)" },
  { value: "MAINTENANCE DES VEHICULES OPTION A VOITURES PARTICULIERES (BTS)", label: "MAINTENANCE DES VEHICULES OPTION A VOITURES PARTICULIERES (BTS)" },
  { value: "MAINTENANCE DES VEHICULES OPTION B VEHICULES DE TRANSPORT ROUTIER (BTS)", label: "MAINTENANCE DES VEHICULES OPTION B VEHICULES DE TRANSPORT ROUTIER (BTS)" },
  { value: "MAINTENANCE DES VEHICULES OPTION C MOTOCYCLES (BTS)", label: "MAINTENANCE DES VEHICULES OPTION C MOTOCYCLES (BTS)" },
  { value: "MAINTENANCE DES MATERIELS DE CONSTRUCTION ET DE MANUTENTION (BTS)", label: "MAINTENANCE DES MATERIELS DE CONSTRUCTION ET DE MANUTENTION (BTS)" },
  { value: "AERONAUTIQUE (BTS)", label: "AERONAUTIQUE (BTS)" },
  { value: "CONSTRUCTIONS METALLIQUES (BTS)/ARCHITECTURES EN METAL : CONCEPTION ET REALISATION (BTS)", label: "CONSTRUCTIONS METALLIQUES (BTS)/ARCHITECTURES EN METAL : CONCEPTION ET REALISATION (BTS)" },
  { value: "CONCEPTION ET REALISATION EN CHAUDRONNERIE INDUSTRIELLE (BTS)", label: "CONCEPTION ET REALISATION EN CHAUDRONNERIE INDUSTRIELLE (BTS)" },
  { value: "CONCEPTION ET REALISATION DE CARROSSERIE (BTS)", label: "CONCEPTION ET REALISATION DE CARROSSERIE (BTS)" },
  { value: "FORGE (BTS)", label: "FORGE (BTS)" },
  { value: "CONCEPTION ET INDUSTRIALISATION EN CONSTRUCTION NAVALE (BTS)", label: "CONCEPTION ET INDUSTRIALISATION EN CONSTRUCTION NAVALE (BTS)" },
  { value: "CONCEPTION DES PROCESSUS DE DECOUPE ET D'EMBOUTISSAGE (BTS)", label: "CONCEPTION DES PROCESSUS DE DECOUPE ET D'EMBOUTISSAGE (BTS)" },
  { value: "ELECTROTECHNIQUE (BTS)", label: "ELECTROTECHNIQUE (BTS)" },
  { value: "SYSTEMES NUMERIQUES OPTION B : ELECTRONIQUE ET COMMUNICATIONS (BTS)", label: "SYSTEMES NUMERIQUES OPTION B : ELECTRONIQUE ET COMMUNICATIONS (BTS)" },
  { value: "SYSTEMES PHOTONIQUES (BTS)", label: "SYSTEMES PHOTONIQUES (BTS)" },
  { value: "NEGOCIATION ET RELATION CLIENT (BTS)", label: "NEGOCIATION ET RELATION CLIENT (BTS)" },
  { value: "COMMERCE INTERNATIONAL A REFERENTIEL COMMUN EUROPEEN (BTS)", label: "COMMERCE INTERNATIONAL A REFERENTIEL COMMUN EUROPEEN (BTS)" },
  { value: "NEGOCIATION ET DIGITALISATION DE LA RELATION CLIENT (BTS)", label: "NEGOCIATION ET DIGITALISATION DE LA RELATION CLIENT (BTS)" },
  { value: "PROFESSIONS IMMOBILIERES (BTS)", label: "PROFESSIONS IMMOBILIERES (BTS)" },
  { value: "BANQUE, CONSEILLER DE CLIENTELE (PARTICULIERS) (BTS)", label: "BANQUE, CONSEILLER DE CLIENTELE (PARTICULIERS) (BTS)" },
  { value: "ASSURANCE (BTS)", label: "ASSURANCE (BTS)" },
  { value: "ASSISTANT DE GESTION DE PME PMI A REFERENTIEL COMMUN EUROPEEN (BTS)", label: "ASSISTANT DE GESTION DE PME PMI A REFERENTIEL COMMUN EUROPEEN (BTS)" },
  { value: "COMPTABILITE ET GESTION (BTS)", label: "COMPTABILITE ET GESTION (BTS)" },
  { value: "GESTION DE LA PME (BTS)", label: "GESTION DE LA PME (BTS)" },
  { value: "COMMUNICATION (BTS)", label: "COMMUNICATION (BTS)" },
  { value: "DESIGN DE COMMUNICATION : ESPACE ET VOLUME (BTS)", label: "DESIGN DE COMMUNICATION : ESPACE ET VOLUME (BTS)" },
  { value: "EDITION (BTS)", label: "EDITION (BTS)" },
  { value: "ETUDES DE REALISATION D'UN PROJET DE COMMUNICATION BTS 1ERE ANNEE COMMUNE", label: "ETUDES DE REALISATION D'UN PROJET DE COMMUNICATION BTS 1ERE ANNEE COMMUNE" },
  { value: "ETUDES DE REALISATION D'UN PROJET DE COMMUNICATION, OPTION A : ETUDES DE REALISATION DE PRODUITS PLURIMEDIA (BTS)", label: "ETUDES DE REALISATION D'UN PROJET DE COMMUNICATION, OPTION A : ETUDES DE REALISATION DE PRODUITS PLURIMEDIA (BTS)" },
  { value: "ETUDES DE REALISATION D'UN PROJET DE COMMUNICATION, OPTION B : ETUDES DE REALISATION DE PRODUITS IMPRIMES (BTS)", label: "ETUDES DE REALISATION D'UN PROJET DE COMMUNICATION, OPTION B : ETUDES DE REALISATION DE PRODUITS IMPRIMES (BTS)" },
  { value: "DESIGN GRAPHIQUE OPTION A COMMUNICATION ET MEDIAS IMPRIMES (BTS)", label: "DESIGN GRAPHIQUE OPTION A COMMUNICATION ET MEDIAS IMPRIMES (BTS)" },
  { value: "DESIGN GRAPHIQUE OPTION B COMMUNICATION ET MEDIAS NUMERIQUES (BTS)", label: "DESIGN GRAPHIQUE OPTION B COMMUNICATION ET MEDIAS NUMERIQUES (BTS)" },
  { value: "METIERS DE L'AUDIOVISUEL OPTION GESTION DE LA PRODUCTION (BTS)", label: "METIERS DE L'AUDIOVISUEL OPTION GESTION DE LA PRODUCTION (BTS)" },
  { value: "METIERS DE L'AUDIOVISUEL OPTION METIERS DE L'IMAGE (BTS)", label: "METIERS DE L'AUDIOVISUEL OPTION METIERS DE L'IMAGE (BTS)" },
  { value: "METIERS DE L'AUDIOVISUEL OPTION METIERS DU SON (BTS)", label: "METIERS DE L'AUDIOVISUEL OPTION METIERS DU SON (BTS)" },
  { value: "METIERS DE L'AUDIOVISUEL OPTION TECHNIQUES D'INGENIERIE ET EXPLOITATION DES EQUIPEMENTS (BTS)", label: "METIERS DE L'AUDIOVISUEL OPTION TECHNIQUES D'INGENIERIE ET EXPLOITATION DES EQUIPEMENTS (BTS)" },
  { value: "METIERS DE L'AUDIOVISUEL OPTION METIERS DU MONTAGE ET DE LA POSTPRODUCTION (BTS)", label: "METIERS DE L'AUDIOVISUEL OPTION METIERS DU MONTAGE ET DE LA POSTPRODUCTION (BTS)" },
  { value: "ASSISTANT DE MANAGER (BTS)", label: "ASSISTANT DE MANAGER (BTS)" },
  { value: "SUPPORT A L'ACTION MANAGERIALE (BTS)", label: "SUPPORT A L'ACTION MANAGERIALE (BTS)" },
  { value: "SERVICES INFORMATIQUES AUX ORGANISATIONS BTS 1ERE ANNEE", label: "SERVICES INFORMATIQUES AUX ORGANISATIONS BTS 1ERE ANNEE" },
  { value: "SERVICES INFORMATIQUES AUX ORGANISATIONS OPTION A SOLUTIONS D'INFRASTRUCTURE, SYSTEMES ET RESEAUX (BTS)", label: "SERVICES INFORMATIQUES AUX ORGANISATIONS OPTION A SOLUTIONS D'INFRASTRUCTURE, SYSTEMES ET RESEAUX (BTS)" },
  { value: "SERVICES INFORMATIQUES AUX ORGANISATIONS OPTION B SOLUTIONS LOGICIELLES ET APPLICATIONS METIERS (BTS)", label: "SERVICES INFORMATIQUES AUX ORGANISATIONS OPTION B SOLUTIONS LOGICIELLES ET APPLICATIONS METIERS (BTS)" },
  { value: "SERVICES ET PRESTATIONS DES SECTEURS SANITAIRE ET SOCIAL (BTS)", label: "SERVICES ET PRESTATIONS DES SECTEURS SANITAIRE ET SOCIAL (BTS)" },
  { value: "DIETETIQUE (BTS)", label: "DIETETIQUE (BTS)" },
  { value: "PROTHESISTE ORTHESISTE (BTS)", label: "PROTHESISTE ORTHESISTE (BTS)" },
  { value: "OPTICIEN LUNETIER (BTS)", label: "OPTICIEN LUNETIER (BTS)" },
  { value: "ANALYSES DE BIOLOGIE MEDICALE (BTS)", label: "ANALYSES DE BIOLOGIE MEDICALE (BTS)" },
  { value: "PROTHESISTE DENTAIRE (BTS)", label: "PROTHESISTE DENTAIRE (BTS)" },
  { value: "ECONOMIE SOCIALE ET FAMILIALE (BTS)", label: "ECONOMIE SOCIALE ET FAMILIALE (BTS)" },
  { value: "CONSEILLER EN ECONOMIE SOCIALE ET FAMILIALE (DIPLÔME D'ETAT)", label: "CONSEILLER EN ECONOMIE SOCIALE ET FAMILIALE (DIPLÔME D'ETAT)" },
  { value: "HOTELLERIE, RESTAURATION OPTION A : MERCATIQUE ET GESTION HOTELIERE (BTS)", label: "HOTELLERIE, RESTAURATION OPTION A : MERCATIQUE ET GESTION HOTELIERE (BTS)" },
  { value: "HOTELLERIE, RESTAURATION OPTION B : ART CULINAIRE, ART DE LA TABLE ET DU SERVICE (BTS)", label: "HOTELLERIE, RESTAURATION OPTION B : ART CULINAIRE, ART DE LA TABLE ET DU SERVICE (BTS)" },
  { value: "RESPONSABLE DE L'HEBERGEMENT A REFERENTIEL COMMUN EUROPEEN (BTS)", label: "RESPONSABLE DE L'HEBERGEMENT A REFERENTIEL COMMUN EUROPEEN (BTS)" },
  { value: "MANAGEMENT EN HOTELLERIE-RESTAURATION BTS 1ERE ANNEE COMMUNE", label: "MANAGEMENT EN HOTELLERIE-RESTAURATION BTS 1ERE ANNEE COMMUNE" },
  { value: "MANAGEMENT EN HOTELLERIE-RESTAURATION OPTION A MANAGEMENT D'UNITE DE RESTAURATION (BTS)", label: "MANAGEMENT EN HOTELLERIE-RESTAURATION OPTION A MANAGEMENT D'UNITE DE RESTAURATION (BTS)" },
  { value: "MANAGEMENT EN HOTELLERIE-RESTAURATION OPTION B MANAGEMENT D'UNITE DE PRODUCTION CULINAIRE (BTS)", label: "MANAGEMENT EN HOTELLERIE-RESTAURATION OPTION B MANAGEMENT D'UNITE DE PRODUCTION CULINAIRE (BTS)" },
  { value: "MANAGEMENT EN HOTELLERIE-RESTAURATION OPTION C MANAGEMENT D'UNITE D'HEBERGEMENT (BTS)", label: "MANAGEMENT EN HOTELLERIE-RESTAURATION OPTION C MANAGEMENT D'UNITE D'HEBERGEMENT (BTS)" },
  { value: "METIERS DE L'ESTHETIQUE-COSMETIQUE-PARFUMERIE, OPTION A : MANAGEMENT (BTS)", label: "METIERS DE L'ESTHETIQUE-COSMETIQUE-PARFUMERIE, OPTION A : MANAGEMENT (BTS)" },
  { value: "METIERS DE L'ESTHETIQUE-COSMETIQUE-PARFUMERIE, OPTION B : FORMATION-MARQUES (BTS)", label: "METIERS DE L'ESTHETIQUE-COSMETIQUE-PARFUMERIE, OPTION B : FORMATION-MARQUES (BTS)" },
  { value: "METIERS DE L'ESTHETIQUE-COSMETIQUE-PARFUMERIE, OPTION C : COSMETOLOGIE (BTS)", label: "METIERS DE L'ESTHETIQUE-COSMETIQUE-PARFUMERIE, OPTION C : COSMETOLOGIE (BTS)" },
  { value: "METIERS DE L'ESTHETIQUE-COSMETIQUE-PARFUMERIE BTS 1ERE ANNEE COMMUNE", label: "METIERS DE L'ESTHETIQUE-COSMETIQUE-PARFUMERIE BTS 1ERE ANNEE COMMUNE" },
  { value: "METIERS DE LA COIFFURE (BTS)", label: "METIERS DE LA COIFFURE (BTS)" },
  { value: "ENVIRONNEMENT NUCLEAIRE (BTS)", label: "ENVIRONNEMENT NUCLEAIRE (BTS)" },
  { value: "METIERS DES SERVICES A L'ENVIRONNEMENT (BTS)", label: "METIERS DES SERVICES A L'ENVIRONNEMENT (BTS)" },
  { value: "NOTARIAT (BTS)", label: "NOTARIAT (BTS)" },
  { value: "ART DU BIJOU ET DU JOYAU (DMA)", label: "ART DU BIJOU ET DU JOYAU (DMA)" },
  { value: "HABITAT OPTION : DECORS ET MOBILIERS (DMA)", label: "HABITAT OPTION : DECORS ET MOBILIERS (DMA)" },
  { value: "ARTS GRAPHIQUES OPTION : GRAVURE (DMA)", label: "ARTS GRAPHIQUES OPTION : GRAVURE (DMA)" },
  { value: "ARTS GRAPHIQUES OPTION : RELIURE DORURE (DMA)", label: "ARTS GRAPHIQUES OPTION : RELIURE DORURE (DMA)" },
  { value: "ARTS GRAPHIQUES OPTION : ILLUSTRATION (DMA)", label: "ARTS GRAPHIQUES OPTION : ILLUSTRATION (DMA)" },
  { value: "ARTS GRAPHIQUES OPTION : TYPOGRAPHIE (DMA)", label: "ARTS GRAPHIQUES OPTION : TYPOGRAPHIE (DMA)" },
  { value: "REGIE DU SPECTACLE OPTION LUMIERE (DMA)", label: "REGIE DU SPECTACLE OPTION LUMIERE (DMA)" },
  { value: "IMAGERIE MEDICALE ET RADIOLOGIE THERAPEUTIQUE (DIPLOME DE TECHNICIEN SUPERIEUR EN) - (DTS)", label: "IMAGERIE MEDICALE ET RADIOLOGIE THERAPEUTIQUE (DIPLOME DE TECHNICIEN SUPERIEUR EN) - (DTS)" },
  { value: "GENIE DES EQUIPEMENTS AGRICOLES (BTSA)", label: "GENIE DES EQUIPEMENTS AGRICOLES (BTSA)" },
  { value: "DEVELOPPEMENT DE L'AGRICULTURE DES REGIONS CHAUDES (BTSA)", label: "DEVELOPPEMENT DE L'AGRICULTURE DES REGIONS CHAUDES (BTSA)" },
  { value: "SCIENCES ET TECHNOLOGIES DES ALIMENTS SPE PRODUITS CEREALIERS (BTSA)", label: "SCIENCES ET TECHNOLOGIES DES ALIMENTS SPE PRODUITS CEREALIERS (BTSA)" },
  { value: "TECHNICO-COMMERCIAL (BTSA)", label: "TECHNICO-COMMERCIAL (BTSA)" },
  { value: "ANALYSE, CONDUITE ET STRATEGIE DE L'ENTREPRISE AGRICOLE (ACSE) (BTSA)", label: "ANALYSE, CONDUITE ET STRATEGIE DE L'ENTREPRISE AGRICOLE (ACSE) (BTSA)" },
  { value: "PRODUCTION HORTICOLE (BTSA)", label: "PRODUCTION HORTICOLE (BTSA)" },
  { value: "VITICULTURE OENOLOGIE (BTSA)", label: "VITICULTURE OENOLOGIE (BTSA)" },
  { value: "AGRONOMIE- PRODUCTIONS VEGETALES (BTSA)", label: "AGRONOMIE- PRODUCTIONS VEGETALES (BTSA)" },
  { value: "PRODUCTIONS ANIMALES (BTSA)", label: "PRODUCTIONS ANIMALES (BTSA)" },
  { value: "AQUACULTURE (BTSA)", label: "AQUACULTURE (BTSA)" },
  { value: "GESTION FORESTIERE (BTSA)", label: "GESTION FORESTIERE (BTSA)" },
  { value: "GESTION ET PROTECTION DE LA NATURE (BTSA)", label: "GESTION ET PROTECTION DE LA NATURE (BTSA)" },
  { value: "AMENAGEMENTS PAYSAGERS (BTSA)", label: "AMENAGEMENTS PAYSAGERS (BTSA)" },
  { value: "ANALYSES AGRICOLES BIOLOGIQUES ET BIOTECHNOLOGIQUES (BTSA)", label: "ANALYSES AGRICOLES BIOLOGIQUES ET BIOTECHNOLOGIQUES (BTSA)" },
  { value: "SCIENCES ET TECHNOLOGIES DES ALIMENTS SPE PRODUITS LAITIERS (BTSA)", label: "SCIENCES ET TECHNOLOGIES DES ALIMENTS SPE PRODUITS LAITIERS (BTSA)" },
  { value: "SCIENCES ET TECHNOLOGIES DES ALIMENTS SPE ALIMENTS ET PROCESSUS TECHNOLOGIQUES (BTSA)", label: "SCIENCES ET TECHNOLOGIES DES ALIMENTS SPE ALIMENTS ET PROCESSUS TECHNOLOGIQUES (BTSA)" },
  { value: "SCIENCES ET TECHNOLOGIES DES ALIMENTS SPE VIANDES ET PRODUITS DE LA PECHE (BTSA)", label: "SCIENCES ET TECHNOLOGIES DES ALIMENTS SPE VIANDES ET PRODUITS DE LA PECHE (BTSA)" },
  { value: "DEVELOPPEMENT, ANIMATION DES TERRITOIRES RURAUX (BTSA)", label: "DEVELOPPEMENT, ANIMATION DES TERRITOIRES RURAUX (BTSA)" },
  { value: "GESTION ET MAITRISE DE L'EAU (BTSA)", label: "GESTION ET MAITRISE DE L'EAU (BTSA)" },
  { value: "DIPLOME D'ETAT DE LA JEUNESSE, DE L'EDUCATION POPULAIRE ET DU SPORT SPE ANIMATION SOCIO-EDUCATIVE OU CULTURELLE ( DE JEPS)", label: "DIPLOME D'ETAT DE LA JEUNESSE, DE L'EDUCATION POPULAIRE ET DU SPORT SPE ANIMATION SOCIO-EDUCATIVE OU CULTURELLE ( DE JEPS)" },
  { value: "DIPLOME D'ETAT DE LA JEUNESSE, DE L'EDUCATION POPULAIRE ET DU SPORT SPE PERFECTIONNEMENT SPORTIF ( DE JEPS)", label: "DIPLOME D'ETAT DE LA JEUNESSE, DE L'EDUCATION POPULAIRE ET DU SPORT SPE PERFECTIONNEMENT SPORTIF ( DE JEPS)" },
  { value: "ENTREPRENEUR DE PETITE ENTREPRISE (CNAM)", label: "ENTREPRENEUR DE PETITE ENTREPRISE (CNAM)" },
  { value: "TECHNICIEN(ENE) DEVELOPPEUR(EUSE) (CNAM)", label: "TECHNICIEN(ENE) DEVELOPPEUR(EUSE) (CNAM)" },
  { value: "SCIENCES ET GENIE DES MATERIAUX (DUT)", label: "SCIENCES ET GENIE DES MATERIAUX (DUT)" },
  { value: "MESURES PHYSIQUES (DUT)", label: "MESURES PHYSIQUES (DUT)" },
  { value: "STATISTIQUE ET INFORMATIQUE DECISIONNELLE (DUT)", label: "STATISTIQUE ET INFORMATIQUE DECISIONNELLE (DUT)" },
  { value: "CHIMIE OPTION CHIMIE ANALYTIQUE ET DE SYNTHESE (DUT)", label: "CHIMIE OPTION CHIMIE ANALYTIQUE ET DE SYNTHESE (DUT)" },
  { value: "CHIMIE OPTION CHIMIE DES MATERIAUX (DUT)", label: "CHIMIE OPTION CHIMIE DES MATERIAUX (DUT)" },
  { value: "CHIMIE (DUT ANNEE COMMUNE)", label: "CHIMIE (DUT ANNEE COMMUNE)" },
  { value: "GENIE BIOLOGIQUE (DUT ANNEE COMMUNE)", label: "GENIE BIOLOGIQUE (DUT ANNEE COMMUNE)" },
  { value: "GENIE BIOLOGIQUE OPTION ANALYSES BIOLOGIQUES ET BIOCHIMIQUES (DUT)", label: "GENIE BIOLOGIQUE OPTION ANALYSES BIOLOGIQUES ET BIOCHIMIQUES (DUT)" },
  { value: "GENIE BIOLOGIQUE OPTION INDUSTRIES AGROALIMENTAIRES ET BIOLOGIQUES (DUT)", label: "GENIE BIOLOGIQUE OPTION INDUSTRIES AGROALIMENTAIRES ET BIOLOGIQUES (DUT)" },
  { value: "GENIE BIOLOGIQUE OPTION AGRONOMIE (DUT)", label: "GENIE BIOLOGIQUE OPTION AGRONOMIE (DUT)" },
  { value: "GENIE BIOLOGIQUE OPTION GENIE DE L ENVIRONNEMENT (DUT)", label: "GENIE BIOLOGIQUE OPTION GENIE DE L ENVIRONNEMENT (DUT)" },
  { value: "GENIE INDUSTRIEL ET MAINTENANCE GIM (DUT)", label: "GENIE INDUSTRIEL ET MAINTENANCE GIM (DUT)" },
  { value: "QUALITE, LOGISTIQUE INDUSTRIELLE ET ORGANISATION (DUT)", label: "QUALITE, LOGISTIQUE INDUSTRIELLE ET ORGANISATION (DUT)" },
  { value: "CHIMIE OPTION CHIMIE INDUSTRIELLE (DUT)", label: "CHIMIE OPTION CHIMIE INDUSTRIELLE (DUT)" },
  { value: "GENIE CHIMIQUE, GENIE DES PROCEDES (DUT ANNEE COMMUNE)", label: "GENIE CHIMIQUE, GENIE DES PROCEDES (DUT ANNEE COMMUNE)" },
  { value: "GENIE CHIMIQUE, GENIE DES PROCEDES OPTION PROCEDES (DUT)", label: "GENIE CHIMIQUE, GENIE DES PROCEDES OPTION PROCEDES (DUT)" },
  { value: "GENIE CHIMIQUE, GENIE DES PROCEDES OPTION BIO-PROCEDES (DUT)", label: "GENIE CHIMIQUE, GENIE DES PROCEDES OPTION BIO-PROCEDES (DUT)" },
  { value: "GENIE THERMIQUE ET ENERGIE (DUT)", label: "GENIE THERMIQUE ET ENERGIE (DUT)" },
  { value: "GENIE CIVIL - CONSTRUCTION DURABLE (DUT)", label: "GENIE CIVIL - CONSTRUCTION DURABLE (DUT)" },
  { value: "GENIE MECANIQUE ET PRODUCTIQUE GMP (DUT)", label: "GENIE MECANIQUE ET PRODUCTIQUE GMP (DUT)" },
  { value: "GENIE ELECTRIQUE ET INFORMATIQUE INDUSTRIELLE GEII (DUT)", label: "GENIE ELECTRIQUE ET INFORMATIQUE INDUSTRIELLE GEII (DUT)" },
  { value: "GESTION DES ENTREPRISES ET DES ADMINISTRATIONS GEA OPTION GESTION ET MANAGEMENT DES ORGANISATIONS (DUT)", label: "GESTION DES ENTREPRISES ET DES ADMINISTRATIONS GEA OPTION GESTION ET MANAGEMENT DES ORGANISATIONS (DUT)" },
  { value: "GESTION DES ENTREPRISES ET DES ADMINISTRATIONS GEA OPTION GESTION COMPTABLE ET FINANCIERE (DUT)", label: "GESTION DES ENTREPRISES ET DES ADMINISTRATIONS GEA OPTION GESTION COMPTABLE ET FINANCIERE (DUT)" },
  { value: "GESTION ADMINISTRATIVE ET COMMERCIALE DES ORGANISATIONS (DUT)", label: "GESTION ADMINISTRATIVE ET COMMERCIALE DES ORGANISATIONS (DUT)" },
  { value: "GESTION DES ENTREPRISES ET DES ADMINISTRATIONS GEA (DUT ANNEE COMMUNE)", label: "GESTION DES ENTREPRISES ET DES ADMINISTRATIONS GEA (DUT ANNEE COMMUNE)" },
  { value: "GESTION LOGISTIQUE ET TRANSPORT (DUT)", label: "GESTION LOGISTIQUE ET TRANSPORT (DUT)" },
  { value: "PACKAGING EMBALLAGE ET CONDITIONNEMENT (DUT)", label: "PACKAGING EMBALLAGE ET CONDITIONNEMENT (DUT)" },
  { value: "TECHNIQUES DE COMMERCIALISATION (DUT)", label: "TECHNIQUES DE COMMERCIALISATION (DUT)" },
  { value: "TECHNIQUES DE COMMERCIALISATION ORIENTATION COMMERCIALISATION DES SYSTEMES, SOLUTIONS ET SERVICES INDUSTRIELS (DUT)", label: "TECHNIQUES DE COMMERCIALISATION ORIENTATION COMMERCIALISATION DES SYSTEMES, SOLUTIONS ET SERVICES INDUSTRIELS (DUT)" },
  { value: "GESTION DES ENTREPRISES ET DES ADMINISTRATIONS GEA OPTION GESTION DES RESSOURCES HUMAINES (DUT)", label: "GESTION DES ENTREPRISES ET DES ADMINISTRATIONS GEA OPTION GESTION DES RESSOURCES HUMAINES (DUT)" },
  { value: "METIERS DU MULTIMEDIA ET DE L'INTERNET (DUT)", label: "METIERS DU MULTIMEDIA ET DE L'INTERNET (DUT)" },
  { value: "INFORMATION COMMUNICATION OPTION COMMUNICATION DES ORGANISATIONS (DUT)", label: "INFORMATION COMMUNICATION OPTION COMMUNICATION DES ORGANISATIONS (DUT)" },
  { value: "INFORMATION COMMUNICATION OPTION INFORMATION NUMERIQUE DANS LES ORGANISATIONS (DUT)", label: "INFORMATION COMMUNICATION OPTION INFORMATION NUMERIQUE DANS LES ORGANISATIONS (DUT)" },
  { value: "INFORMATIQUE (DUT)", label: "INFORMATIQUE (DUT)" },
  { value: "RESEAUX ET TELECOMMUNICATIONS (DUT)", label: "RESEAUX ET TELECOMMUNICATIONS (DUT)" },
  { value: "CARRIERES SOCIALES OPTION SERVICES A LA PERSONNE (DUT)", label: "CARRIERES SOCIALES OPTION SERVICES A LA PERSONNE (DUT)" },
  { value: "CARRIERES SOCIALES OPTION EDUCATION SPECIALISEE (DUT)", label: "CARRIERES SOCIALES OPTION EDUCATION SPECIALISEE (DUT)" },
  { value: "CARRIERES SOCIALES OPTION ASSISTANCE SOCIALE (DUT)", label: "CARRIERES SOCIALES OPTION ASSISTANCE SOCIALE (DUT)" },
  { value: "CARRIERES SOCIALES OPTION ANIMATION SOCIALE ET SOCIO-CULTURELLE (DUT)", label: "CARRIERES SOCIALES OPTION ANIMATION SOCIALE ET SOCIO-CULTURELLE (DUT)" },
  { value: "HYGIENE SECURITE ENVIRONNEMENT (DUT)", label: "HYGIENE SECURITE ENVIRONNEMENT (DUT)" },
  { value: "CARRIERES JURIDIQUES (DUT)", label: "CARRIERES JURIDIQUES (DUT)" },
  { value: "PRODUCTION ET TRANSFORMATION SPECIALITE ARTS ET METIERS DE BOUCHE (DEUST CNAM)", label: "PRODUCTION ET TRANSFORMATION SPECIALITE ARTS ET METIERS DE BOUCHE (DEUST CNAM)" },
  { value: "MAINTENANCE HOTELIERE HOSPITALIERE ET IMMOBILIERE (DEUST ANGERS)", label: "MAINTENANCE HOTELIERE HOSPITALIERE ET IMMOBILIERE (DEUST ANGERS)" },
  { value: "BATIMENT ET CONSTRUCTION SPE CONDUITE DE TRAVAUX EN ECO-CONSTRUCTION (DEUST CNAM)", label: "BATIMENT ET CONSTRUCTION SPE CONDUITE DE TRAVAUX EN ECO-CONSTRUCTION (DEUST CNAM)" },
  { value: "BUREAUTIQUE ET COMMUNICATION MULTIMEDIA (DEUST UNIV DU LITTORAL)", label: "BUREAUTIQUE ET COMMUNICATION MULTIMEDIA (DEUST UNIV DU LITTORAL)" },
  { value: "ACCEUIL D'EXCELLENCE EN TOURISME (DEUST ANGERS)", label: "ACCEUIL D'EXCELLENCE EN TOURISME (DEUST ANGERS)" },
  { value: "METIERS DE LA FORME (DEUST LYON 1)", label: "METIERS DE LA FORME (DEUST LYON 1)" },
  { value: "ANIMATION ET GESTION DES ACTIVITES PHYSIQUES ET SPORTIVES OU CULTURELLES (DEUST DIJON)", label: "ANIMATION ET GESTION DES ACTIVITES PHYSIQUES ET SPORTIVES OU CULTURELLES (DEUST DIJON)" },
  { value: "ANIMATION ET GESTION DES ACTIVITES PHYSIQUES ET SPORTIVES OU CULTURELLES (DEUST LYON 1)", label: "ANIMATION ET GESTION DES ACTIVITES PHYSIQUES ET SPORTIVES OU CULTURELLES (DEUST LYON 1)" },
  { value: "METIERS DE LA FORME (DEUST STRASBOURG)", label: "METIERS DE LA FORME (DEUST STRASBOURG)" },
  { value: "METIERS DE LA FORME (DEUST TOULOUSE 3)", label: "METIERS DE LA FORME (DEUST TOULOUSE 3)" },
  { value: "STAPS:ANIMATION ET GESTION DES ACTIVITES PHYSIQUES, SPORTIVES OU CULTURELLES (DEUST CLERMONT AUVERGNE)", label: "STAPS:ANIMATION ET GESTION DES ACTIVITES PHYSIQUES, SPORTIVES OU CULTURELLES (DEUST CLERMONT AUVERGNE)" },
  { value: "PROFESSEUR DE DANSE (DIPLOME D'ETAT)", label: "PROFESSEUR DE DANSE (DIPLOME D'ETAT)" },
  { value: "CONDUCTEUR DES TRAVAUX PUBLICS ET TECHNICIEN DE BUREAU D'ETUDES (DIPLOME DE L'ECOLE SPECIALE DES TRAVAUX PUBLIC ESTP)", label: "CONDUCTEUR DES TRAVAUX PUBLICS ET TECHNICIEN DE BUREAU D'ETUDES (DIPLOME DE L'ECOLE SPECIALE DES TRAVAUX PUBLIC ESTP)" },
  { value: "CONDUCTEUR TECHNICIEN DES TRAVAUX DU BATIMENT (DIPLOME DE L'ECOLE SPECIALE DES TRAVAUX PUBLIC ESTP)", label: "CONDUCTEUR TECHNICIEN DES TRAVAUX DU BATIMENT (DIPLOME DE L'ECOLE SPECIALE DES TRAVAUX PUBLIC ESTP)" },
  { value: "PREPARATEUR EN PHARMACIE HOSPITALIERE (DIPLOME D'ETAT)", label: "PREPARATEUR EN PHARMACIE HOSPITALIERE (DIPLOME D'ETAT)" },
  { value: "PSYCHOMOTRICIEN (DIPLOME D'ETAT)", label: "PSYCHOMOTRICIEN (DIPLOME D'ETAT)" },
  { value: "AUDIOPROTHESISTE (DIPLOME D'ETAT)", label: "AUDIOPROTHESISTE (DIPLOME D'ETAT)" },
  { value: "RESPONSABLE TECHNICO COMMERCIAL EN AGROEQUIPEMENT", label: "RESPONSABLE TECHNICO COMMERCIAL EN AGROEQUIPEMENT" },
  { value: "RESPONSABLE TECHNICO COMMERCIAL EN AGROFOURNITURES (CSA)", label: "RESPONSABLE TECHNICO COMMERCIAL EN AGROFOURNITURES (CSA)" },
  { value: "TECHNIQUES D'AGRICULTURE BIOLOGIQUE (CSA)", label: "TECHNIQUES D'AGRICULTURE BIOLOGIQUE (CSA)" },
  { value: "TECHNICIEN CONSEIL EN AGRICULTURE BIOLOGIQUE (CSA)", label: "TECHNICIEN CONSEIL EN AGRICULTURE BIOLOGIQUE (CSA)" },
  { value: "RESPONSABLE TECHNICO COMMERCIAL : FRUITS ET LEGUMES (CSA)", label: "RESPONSABLE TECHNICO COMMERCIAL : FRUITS ET LEGUMES (CSA)" },
  { value: "GESTION DES ARBRES D'ORNEMENT (CSA)", label: "GESTION DES ARBRES D'ORNEMENT (CSA)" },
  { value: "AUTOMATISME ET GESTION TECHNICO-ECONOMIQUE INFORMATISEE EN ELEVAGE LAITIER (CSA)", label: "AUTOMATISME ET GESTION TECHNICO-ECONOMIQUE INFORMATISEE EN ELEVAGE LAITIER (CSA)" },
  { value: "TECHNICIEN CONSEIL EN PRODUCTION LAITIERE (CSA)", label: "TECHNICIEN CONSEIL EN PRODUCTION LAITIERE (CSA)" },
  { value: "TECHNICIEN CONSEIL EN PRODUCTION LAITIERE OVINE (CSA)", label: "TECHNICIEN CONSEIL EN PRODUCTION LAITIERE OVINE (CSA)" },
  { value: "TECHNICIEN CONSEIL EN PRODUCTION CAPRINE (CSA)", label: "TECHNICIEN CONSEIL EN PRODUCTION CAPRINE (CSA)" },
  { value: "TECHNICIEN CONSEIL EN BATIMENTS D'ELEVAGE ET AGRICOLES (CSA)", label: "TECHNICIEN CONSEIL EN BATIMENTS D'ELEVAGE ET AGRICOLES (CSA)" },
  { value: "COLLABORATEUR DU CONCEPTEUR PAYSAGISTE (CSA)", label: "COLLABORATEUR DU CONCEPTEUR PAYSAGISTE (CSA)" },
  { value: "TECHNICIEN CONSEIL EN COMPTABILITE ET GESTION AGRICOLES (CSA)", label: "TECHNICIEN CONSEIL EN COMPTABILITE ET GESTION AGRICOLES (CSA)" },
  { value: "CONSEILLER D'ELEVAGE AVICOLE (AVIPOLE FORMATION)", label: "CONSEILLER D'ELEVAGE AVICOLE (AVIPOLE FORMATION)" },
  { value: "CHEF DE PRODUITS FORET BOIS (CFPPA DE CROGNY)", label: "CHEF DE PRODUITS FORET BOIS (CFPPA DE CROGNY)" },
  { value: "ETUDE ET DEFINITION DE PRODUITS INDUSTRIELS (BAC PRO)", label: "ETUDE ET DEFINITION DE PRODUITS INDUSTRIELS (BAC PRO)" },
  { value: "2NDE PROFESSIONNELLE PRODUCTION (BAC PRO)", label: "2NDE PROFESSIONNELLE PRODUCTION (BAC PRO)" },
  { value: "PILOTE DE LIGNE DE PRODUCTION (BAC PRO)", label: "PILOTE DE LIGNE DE PRODUCTION (BAC PRO)" },
  { value: "CULTURES MARINES (BAC PRO)", label: "CULTURES MARINES (BAC PRO)" },
  { value: "CONDUITE ET GESTION DES ENTREPRISES MARITIMES OPTION PECHE (BAC PRO)", label: "CONDUITE ET GESTION DES ENTREPRISES MARITIMES OPTION PECHE (BAC PRO)" },
  { value: "BIO-INDUSTRIES DE TRANSFORMATION (BAC PRO)", label: "BIO-INDUSTRIES DE TRANSFORMATION (BAC PRO)" },
  { value: "PROCEDES DE LA CHIMIE, DE L'EAU ET DES PAPIERS-CARTONS (BAC PRO)", label: "PROCEDES DE LA CHIMIE, DE L'EAU ET DES PAPIERS-CARTONS (BAC PRO)" },
  { value: "BOUCHER-CHARCUTIER-TRAITEUR (BAC PRO)", label: "BOUCHER-CHARCUTIER-TRAITEUR (BAC PRO)" },
  { value: "BOULANGER-PÂTISSIER (BAC PRO)", label: "BOULANGER-PÂTISSIER (BAC PRO)" },
  { value: "CUISINE (BAC PRO)", label: "CUISINE (BAC PRO)" },
  { value: "TRAITEMENTS DES MATERIAUX (BAC PRO)", label: "TRAITEMENTS DES MATERIAUX (BAC PRO)" },
  { value: "FONDERIE (BAC PRO)", label: "FONDERIE (BAC PRO)" },
  { value: "ARTISANAT ET METIERS D'ART - FACTEUR D'ORGUES OPTION : ORGANIER (BAC PRO)", label: "ARTISANAT ET METIERS D'ART - FACTEUR D'ORGUES OPTION : ORGANIER (BAC PRO)" },
  { value: "ARTISANAT ET METIERS D'ART - FACTEUR D'ORGUES OPTION : TUYAUTIER (BAC PRO)", label: "ARTISANAT ET METIERS D'ART - FACTEUR D'ORGUES OPTION : TUYAUTIER (BAC PRO)" },
  { value: "ARTISANAT ET METIERS D'ART - FACTEUR D'ORGUES 2NDE COMMUNE (BAC PRO)", label: "ARTISANAT ET METIERS D'ART - FACTEUR D'ORGUES 2NDE COMMUNE (BAC PRO)" },
  { value: "ARTISANAT ET METIERS D'ART OPTION : METIERS DE L'ENSEIGNE ET DE LA SIGNALETIQUE (BAC PRO)", label: "ARTISANAT ET METIERS D'ART OPTION : METIERS DE L'ENSEIGNE ET DE LA SIGNALETIQUE (BAC PRO)" },
  { value: "ARTISANAT ET METIERS D'ART 2NDE COMMUNE (BAC PRO)", label: "ARTISANAT ET METIERS D'ART 2NDE COMMUNE (BAC PRO)" },
  { value: "PLASTIQUES ET COMPOSITES (BAC PRO)", label: "PLASTIQUES ET COMPOSITES (BAC PRO)" },
  { value: "TECHNICIEN EN INSTALLATION DES SYSTEMES ENERGETIQUES ET CLIMATIQUES (BAC PRO)", label: "TECHNICIEN EN INSTALLATION DES SYSTEMES ENERGETIQUES ET CLIMATIQUES (BAC PRO)" },
  { value: "TECHNICIEN DE MAINTENANCE DES SYSTEMES ENERGETIQUES ET CLIMATIQUES (BAC PRO)", label: "TECHNICIEN DE MAINTENANCE DES SYSTEMES ENERGETIQUES ET CLIMATIQUES (BAC PRO)" },
  { value: "TECHNICIEN GAZ (BAC PRO)", label: "TECHNICIEN GAZ (BAC PRO)" },
  { value: "TECHNICIEN D'ETUDES DU BATIMENT OPTION A : ETUDES ET ECONOMIE (BAC PRO)", label: "TECHNICIEN D'ETUDES DU BATIMENT OPTION A : ETUDES ET ECONOMIE (BAC PRO)" },
  { value: "TECHNICIEN D'ETUDES DU BATIMENT OPTION B : ASSISTANT EN ARCHITECTURE (BAC PRO)", label: "TECHNICIEN D'ETUDES DU BATIMENT OPTION B : ASSISTANT EN ARCHITECTURE (BAC PRO)" },
  { value: "TECHNICIEN D'ETUDES DU BATIMENT 2NDE COMMUNE (BAC PRO)", label: "TECHNICIEN D'ETUDES DU BATIMENT 2NDE COMMUNE (BAC PRO)" },
  { value: "TRAVAUX PUBLICS (BAC PRO)", label: "TRAVAUX PUBLICS (BAC PRO)" },
  { value: "TECHNICIEN GEOMETRE-TOPOGRAPHE (BAC PRO)", label: "TECHNICIEN GEOMETRE-TOPOGRAPHE (BAC PRO)" },
  { value: "TECHNICIEN DU BATIMENT : ORGANISATION ET REALISATION DU GROS OEUVRE (BAC PRO)", label: "TECHNICIEN DU BATIMENT : ORGANISATION ET REALISATION DU GROS OEUVRE (BAC PRO)" },
  { value: "INTERVENTIONS SUR LE PATRIMOINE BATI, OPTION A MACONNERIE (BAC PRO)", label: "INTERVENTIONS SUR LE PATRIMOINE BATI, OPTION A MACONNERIE (BAC PRO)" },
  { value: "INTERVENTIONS SUR LE PATRIMOINE BATI, OPTION B CHARPENTE (BAC PRO)", label: "INTERVENTIONS SUR LE PATRIMOINE BATI, OPTION B CHARPENTE (BAC PRO)" },
  { value: "INTERVENTIONS SUR LE PATRIMOINE BATI, OPTION C COUVERTURE (BAC PRO)", label: "INTERVENTIONS SUR LE PATRIMOINE BATI, OPTION C COUVERTURE (BAC PRO)" },
  { value: "METIERS ET ARTS DE LA PIERRE (BAC PRO)", label: "METIERS ET ARTS DE LA PIERRE (BAC PRO)" },
  { value: "INTERVENTIONS SUR LE PATRIMOINE BATI 2NDE COMMUNE (BAC PRO)", label: "INTERVENTIONS SUR LE PATRIMOINE BATI 2NDE COMMUNE (BAC PRO)" },
  { value: "MENUISERIE ALUMINIUM-VERRE (BAC PRO)", label: "MENUISERIE ALUMINIUM-VERRE (BAC PRO)" },
  { value: "AMENAGEMENT ET FINITIONS DU BATIMENT (BAC PRO)", label: "AMENAGEMENT ET FINITIONS DU BATIMENT (BAC PRO)" },
  { value: "TECHNICIEN CONSTRUCTEUR BOIS (BAC PRO)", label: "TECHNICIEN CONSTRUCTEUR BOIS (BAC PRO)" },
  { value: "TECHNICIEN MENUISIER AGENCEUR (BAC PRO)", label: "TECHNICIEN MENUISIER AGENCEUR (BAC PRO)" },
  { value: "TECHNICIEN DE SCIERIE (BAC PRO)", label: "TECHNICIEN DE SCIERIE (BAC PRO)" },
  { value: "TECHNICIEN DE FABRICATION BOIS ET MATERIAUX ASSOCIES (BAC PRO)", label: "TECHNICIEN DE FABRICATION BOIS ET MATERIAUX ASSOCIES (BAC PRO)" },
  { value: "ETUDE ET REALISATION D'AGENCEMENT (BAC PRO)", label: "ETUDE ET REALISATION D'AGENCEMENT (BAC PRO)" },
  { value: "METIERS DU PRESSING ET DE LA BLANCHISSERIE (BAC PRO)", label: "METIERS DU PRESSING ET DE LA BLANCHISSERIE (BAC PRO)" },
  { value: "ARTISANAT ET METIERS D'ART OPTION : TAPISSIER D'AMEUBLEMENT (BAC PRO)", label: "ARTISANAT ET METIERS D'ART OPTION : TAPISSIER D'AMEUBLEMENT (BAC PRO)" },
  { value: "METIERS DE LA MODE - VÊTEMENT (BAC PRO)", label: "METIERS DE LA MODE - VÊTEMENT (BAC PRO)" },
  { value: "METIERS DU CUIR OPTION MAROQUINERIE (BAC PRO)", label: "METIERS DU CUIR OPTION MAROQUINERIE (BAC PRO)" },
  { value: "PRODUCTIQUE MECANIQUE OPTION DECOLLETAGE (BAC PRO)", label: "PRODUCTIQUE MECANIQUE OPTION DECOLLETAGE (BAC PRO)" },
  { value: "MICROTECHNIQUES (BAC PRO)", label: "MICROTECHNIQUES (BAC PRO)" },
  { value: "MAINTENANCE DES EQUIPEMENTS INDUSTRIELS (BAC PRO)", label: "MAINTENANCE DES EQUIPEMENTS INDUSTRIELS (BAC PRO)" },
  { value: "ELECTROMECANICIEN MARINE (BAC PRO)", label: "ELECTROMECANICIEN MARINE (BAC PRO)" },
  { value: "MAINTENANCE NAUTIQUE (BAC PRO)", label: "MAINTENANCE NAUTIQUE (BAC PRO)" },
  { value: "TECHNICIEN D'USINAGE (BAC PRO)", label: "TECHNICIEN D'USINAGE (BAC PRO)" },
  { value: "TECHNICIEN OUTILLEUR (BAC PRO)", label: "TECHNICIEN OUTILLEUR (BAC PRO)" },
  { value: "TECHNICIEN MODELEUR (BAC PRO)", label: "TECHNICIEN MODELEUR (BAC PRO)" },
  { value: "MAINTENANCE DES VEHICULES OPTION A VOITURES PARTICULIERES (BAC PRO)", label: "MAINTENANCE DES VEHICULES OPTION A VOITURES PARTICULIERES (BAC PRO)" },
  { value: "MAINTENANCE DES VEHICULES OPTION B VEHICULES DE TRANSPORT ROUTIER (BAC PRO)", label: "MAINTENANCE DES VEHICULES OPTION B VEHICULES DE TRANSPORT ROUTIER (BAC PRO)" },
  { value: "MAINTENANCE DES VEHICULES OPTION C MOTOCYCLES (BAC PRO)", label: "MAINTENANCE DES VEHICULES OPTION C MOTOCYCLES (BAC PRO)" },
  { value: "MAINTENANCE DES MATERIELS 2NDE COMMUNE (BAC PRO)", label: "MAINTENANCE DES MATERIELS 2NDE COMMUNE (BAC PRO)" },
  { value: "MAINTENANCE DES VEHICULES 2NDE COMMUNE (BAC PRO)", label: "MAINTENANCE DES VEHICULES 2NDE COMMUNE (BAC PRO)" },
  { value: "MAINTENANCE DES MATERIELS OPTION A MATERIELS AGRICOLES (BAC PRO)", label: "MAINTENANCE DES MATERIELS OPTION A MATERIELS AGRICOLES (BAC PRO)" },
  { value: "MAINTENANCE DES MATERIELS OPTION B MATERIELS DE CONSTRUCTION ET DE MANUTENTION (BAC PRO)", label: "MAINTENANCE DES MATERIELS OPTION B MATERIELS DE CONSTRUCTION ET DE MANUTENTION (BAC PRO)" },
  { value: "MAINTENANCE DES MATERIELS OPTION C MATERIELS D'ESPACES VERTS (BAC PRO)", label: "MAINTENANCE DES MATERIELS OPTION C MATERIELS D'ESPACES VERTS (BAC PRO)" },
  { value: "AERONAUTIQUE OPTION AVIONIQUE (BAC PRO)", label: "AERONAUTIQUE OPTION AVIONIQUE (BAC PRO)" },
  { value: "AERONAUTIQUE OPTION SYSTEMES (BAC PRO)", label: "AERONAUTIQUE OPTION SYSTEMES (BAC PRO)" },
  { value: "AERONAUTIQUE OPTION STRUCTURE (BAC PRO)", label: "AERONAUTIQUE OPTION STRUCTURE (BAC PRO)" },
  { value: "AVIATION GENERALE (BAC PRO)", label: "AVIATION GENERALE (BAC PRO)" },
  { value: "OUVRAGES DU BATIMENT : METALLERIE (BAC PRO)", label: "OUVRAGES DU BATIMENT : METALLERIE (BAC PRO)" },
  { value: "REPARATION DES CARROSSERIES (BAC PRO)", label: "REPARATION DES CARROSSERIES (BAC PRO)" },
  { value: "TECHNICIEN EN CHAUDRONNERIE INDUSTRIELLE (BAC PRO)", label: "TECHNICIEN EN CHAUDRONNERIE INDUSTRIELLE (BAC PRO)" },
  { value: "CONSTRUCTION DES CARROSSERIES (BAC PRO)", label: "CONSTRUCTION DES CARROSSERIES (BAC PRO)" },
  { value: "TECHNICIEN DU FROID ET DU CONDITIONNEMENT D'AIR (BAC PRO)", label: "TECHNICIEN DU FROID ET DU CONDITIONNEMENT D'AIR (BAC PRO)" },
  { value: "METIERS DE L'ELECTRICITE ET DE SES ENVIRONNEMENTS CONNECTES (BAC PRO)", label: "METIERS DE L'ELECTRICITE ET DE SES ENVIRONNEMENTS CONNECTES (BAC PRO)" },
  { value: "SYSTEMES NUMERIQUES OPTION A SURETE ET SECURITE DES INFRASTRUCTURES, DE L'HABITAT ET DU TERTIAIRE (BAC PRO)", label: "SYSTEMES NUMERIQUES OPTION A SURETE ET SECURITE DES INFRASTRUCTURES, DE L'HABITAT ET DU TERTIAIRE (BAC PRO)" },
  { value: "SYSTEMES NUMERIQUES OPTION B AUDIOVISUELS, RESEAU ET EQUIPEMENT DOMESTIQUES (BAC PRO)", label: "SYSTEMES NUMERIQUES OPTION B AUDIOVISUELS, RESEAU ET EQUIPEMENT DOMESTIQUES (BAC PRO)" },
  { value: "SYSTEMES NUMERIQUES OPTION C RESEAUX INFORMATIQUES ET SYSTEMES COMMUNICANTS (BAC PRO)", label: "SYSTEMES NUMERIQUES OPTION C RESEAUX INFORMATIQUES ET SYSTEMES COMMUNICANTS (BAC PRO)" },
  { value: "SYSTEMES NUMERIQUES 2NDE COMMUNE (BAC PRO)", label: "SYSTEMES NUMERIQUES 2NDE COMMUNE (BAC PRO)" },
  { value: "GESTION-ADMINISTRATION (BAC PRO)", label: "GESTION-ADMINISTRATION (BAC PRO)" },
  { value: "2NDE PROFESSIONNELLE SERVICES (BAC PRO)", label: "2NDE PROFESSIONNELLE SERVICES (BAC PRO)" },
  { value: "LOGISTIQUE (BAC PRO)", label: "LOGISTIQUE (BAC PRO)" },
  { value: "CONDUCTEUR TRANSPORT ROUTIER MARCHANDISES (BAC PRO)", label: "CONDUCTEUR TRANSPORT ROUTIER MARCHANDISES (BAC PRO)" },
  { value: "TRANSPORT (BAC PRO)", label: "TRANSPORT (BAC PRO)" },
  { value: "TRANSPORT FLUVIAL (BAC PRO)", label: "TRANSPORT FLUVIAL (BAC PRO)" },
  { value: "POISSONNIER-ECAILLER-TRAITEUR (BAC PRO)", label: "POISSONNIER-ECAILLER-TRAITEUR (BAC PRO)" },
  { value: "FACONNAGE DE PRODUITS IMPRIMES, ROUTAGE (BAC PRO)", label: "FACONNAGE DE PRODUITS IMPRIMES, ROUTAGE (BAC PRO)" },
  { value: "REALISATION DE PRODUITS IMPRIMES ET PLURIMEDIA OPTION A PRODUCTIONS GRAPHIQUES (BAC PRO)", label: "REALISATION DE PRODUITS IMPRIMES ET PLURIMEDIA OPTION A PRODUCTIONS GRAPHIQUES (BAC PRO)" },
  { value: "REALISATION DE PRODUITS IMPRIMES ET PLURIMEDIA OPTION B PRODUCTIONS IMPRIMEES (BAC PRO)", label: "REALISATION DE PRODUITS IMPRIMES ET PLURIMEDIA OPTION B PRODUCTIONS IMPRIMEES (BAC PRO)" },
  { value: "REALISATION DE PRODUITS IMPRIMES ET PLURIMEDIA 2NDE COMMUNE (BAC PRO)", label: "REALISATION DE PRODUITS IMPRIMES ET PLURIMEDIA 2NDE COMMUNE (BAC PRO)" },
  { value: "ARTISANAT ET METIERS D'ART OPTION : COMMUNICATION VISUELLE PLURI-MEDIA (BAC PRO)", label: "ARTISANAT ET METIERS D'ART OPTION : COMMUNICATION VISUELLE PLURI-MEDIA (BAC PRO)" },
  { value: "PHOTOGRAPHIE (BAC PRO)", label: "PHOTOGRAPHIE (BAC PRO)" },
  { value: "SERVICES DE PROXIMITE ET VIE LOCALE (BAC PRO)", label: "SERVICES DE PROXIMITE ET VIE LOCALE (BAC PRO)" },
  { value: "ACCOMPAGNEMENT SOINS ET SERVICES A LA PERSONNE OPTION A - A DOMICILE (BAC PRO)", label: "ACCOMPAGNEMENT SOINS ET SERVICES A LA PERSONNE OPTION A - A DOMICILE (BAC PRO)" },
  { value: "ACCOMPAGNEMENT SOINS ET SERVICES A LA PERSONNE OPTION B - EN STRUCTURE (BAC PRO)", label: "ACCOMPAGNEMENT SOINS ET SERVICES A LA PERSONNE OPTION B - EN STRUCTURE (BAC PRO)" },
  { value: "OPTIQUE LUNETTERIE (BAC PRO)", label: "OPTIQUE LUNETTERIE (BAC PRO)" },
  { value: "PROTHESE DENTAIRE (BAC PRO)", label: "PROTHESE DENTAIRE (BAC PRO)" },
  { value: "TECHNICIEN EN APPAREILLAGE ORTHOPEDIQUE (BAC PRO)", label: "TECHNICIEN EN APPAREILLAGE ORTHOPEDIQUE (BAC PRO)" },
  { value: "COMMERCIALISATION ET SERVICES EN RESTAURATION (BAC PRO)", label: "COMMERCIALISATION ET SERVICES EN RESTAURATION (BAC PRO)" },
  { value: "ESTHETIQUE COSMETIQUE PARFUMERIE (BAC PRO)", label: "ESTHETIQUE COSMETIQUE PARFUMERIE (BAC PRO)" },
  { value: "GESTION DES POLLUTIONS ET PROTECTION DE L'ENVIRONNEMENT (BAC PRO)", label: "GESTION DES POLLUTIONS ET PROTECTION DE L'ENVIRONNEMENT (BAC PRO)" },
  { value: "HYGIENE, PROPRETE, STERILISATION (BAC PRO)", label: "HYGIENE, PROPRETE, STERILISATION (BAC PRO)" },
  { value: "TECHNIQUES D'INTERVENTIONS SUR INSTALLATIONS NUCLEAIRES (BAC PRO)", label: "TECHNIQUES D'INTERVENTIONS SUR INSTALLATIONS NUCLEAIRES (BAC PRO)" },
  { value: "METIERS DE LA SECURITE (BAC PRO)", label: "METIERS DE LA SECURITE (BAC PRO)" },
  { value: "ART DU BIJOU OPTION BIJOUTERIE JOAILLERIE (BMA)", label: "ART DU BIJOU OPTION BIJOUTERIE JOAILLERIE (BMA)" },
  { value: "ART DU BIJOU OPTION BIJOUTERIE SERTISSAGE (BMA)", label: "ART DU BIJOU OPTION BIJOUTERIE SERTISSAGE (BMA)" },
  { value: "ART DU BIJOU OPTION BIJOUTERIE POLISSAGE FINITION (BMA)", label: "ART DU BIJOU OPTION BIJOUTERIE POLISSAGE FINITION (BMA)" },
  { value: "ORFEVRERIE OPTION MONTURE-TOURNURE (BMA)", label: "ORFEVRERIE OPTION MONTURE-TOURNURE (BMA)" },
  { value: "VERRIER DECORATEUR (BMA)", label: "VERRIER DECORATEUR (BMA)" },
  { value: "GRAVURE SUR PIERRE (BMA)", label: "GRAVURE SUR PIERRE (BMA)" },
  { value: "VOLUMES : STAFF ET MATERIAUX ASSOCIES (BMA)", label: "VOLUMES : STAFF ET MATERIAUX ASSOCIES (BMA)" },
  { value: "GRAPHISME ET DECOR OPTION A : GRAPHISTE EN LETTRES ET DECOR (BMA)", label: "GRAPHISME ET DECOR OPTION A : GRAPHISTE EN LETTRES ET DECOR (BMA)" },
  { value: "GRAPHISME ET DECOR OPTION B : DECORATEUR DE SURFACES ET VOLUMES (BMA)", label: "GRAPHISME ET DECOR OPTION B : DECORATEUR DE SURFACES ET VOLUMES (BMA)" },
  { value: "TECHNICIEN EN FACTURE INSTRUMENTALE OPTION GUITARE (BMA)", label: "TECHNICIEN EN FACTURE INSTRUMENTALE OPTION GUITARE (BMA)" },
  { value: "TECHNICIEN EN FACTURE INSTRUMENTALE OPTION PIANO (BMA)", label: "TECHNICIEN EN FACTURE INSTRUMENTALE OPTION PIANO (BMA)" },
  { value: "EBENISTE (BMA)", label: "EBENISTE (BMA)" },
  { value: "HORLOGERIE (BMA)", label: "HORLOGERIE (BMA)" },
  { value: "TECHNICIEN EN FACTURE INSTRUMENTALE OPTION INSTRUMENTS A VENTS (BMA)", label: "TECHNICIEN EN FACTURE INSTRUMENTALE OPTION INSTRUMENTS A VENTS (BMA)" },
  { value: "FERRONNIER D'ART (BMA)", label: "FERRONNIER D'ART (BMA)" },
  { value: "ORFEVRERIE OPTION GRAVURES-CISELURE (BMA)", label: "ORFEVRERIE OPTION GRAVURES-CISELURE (BMA)" },
  { value: "AGRO-EQUIPEMENT (BAC PRO AG)", label: "AGRO-EQUIPEMENT (BAC PRO AG)" },
  { value: "CONDUITE ET GESTION DE L'ENTREPRISE AGRICOLE (BAC PRO AG)", label: "CONDUITE ET GESTION DE L'ENTREPRISE AGRICOLE (BAC PRO AG)" },
  { value: "PRODUCTIONS (SEC PRO AG)", label: "PRODUCTIONS (SEC PRO AG)" },
  { value: "CONDUITE ET GESTION DE L'ENTREPRISE VITI VINICOLE (BAC PRO AG)", label: "CONDUITE ET GESTION DE L'ENTREPRISE VITI VINICOLE (BAC PRO AG)" },
  { value: "TECHNICIEN CONSEIL VENTE EN ANIMALERIE (BAC PRO AG)", label: "TECHNICIEN CONSEIL VENTE EN ANIMALERIE (BAC PRO AG)" },
  { value: "PRODUCTIONS AQUACOLES (BAC PRO AG)", label: "PRODUCTIONS AQUACOLES (BAC PRO AG)" },
  { value: "CONDUITE ET GESTION D'UNE ENTREPRISE DU SECTEUR CANIN ET FELIN (BAC PRO AG)", label: "CONDUITE ET GESTION D'UNE ENTREPRISE DU SECTEUR CANIN ET FELIN (BAC PRO AG)" },
  { value: "CONDUITE ET GESTION DE L'ENTREPRISE HIPPIQUE (BAC PRO AG)", label: "CONDUITE ET GESTION DE L'ENTREPRISE HIPPIQUE (BAC PRO AG)" },
  { value: "TECHNICIEN EN EXPERIMENTATION ANIMALE (BAC PRO AG)", label: "TECHNICIEN EN EXPERIMENTATION ANIMALE (BAC PRO AG)" },
  { value: "GESTION DES MILIEUX NATURELS ET DE LA FAUNE (BAC PRO AG)", label: "GESTION DES MILIEUX NATURELS ET DE LA FAUNE (BAC PRO AG)" },
  { value: "FORET (BAC PRO AG)", label: "FORET (BAC PRO AG)" },
  { value: "NATURE JARDIN PAYSAGE FORET (SEC PRO AG)", label: "NATURE JARDIN PAYSAGE FORET (SEC PRO AG)" },
  { value: "AMENAGEMENTS PAYSAGERS (BAC PRO AG)", label: "AMENAGEMENTS PAYSAGERS (BAC PRO AG)" },
  { value: "TECHNICIEN CONSEIL VENTE EN PRODUITS DE JARDIN (BAC PRO AG)", label: "TECHNICIEN CONSEIL VENTE EN PRODUITS DE JARDIN (BAC PRO AG)" },
  { value: "CONSEIL VENTE (SEC PRO AG)", label: "CONSEIL VENTE (SEC PRO AG)" },
  { value: "LABORATOIRE CONTROLE QUALITE (BAC PRO AG)", label: "LABORATOIRE CONTROLE QUALITE (BAC PRO AG)" },
  { value: "TECHNICIEN CONSEIL VENTE EN ALIMENTATION SPE VINS ET SPIRITUEUX (BAC PRO AG)", label: "TECHNICIEN CONSEIL VENTE EN ALIMENTATION SPE VINS ET SPIRITUEUX (BAC PRO AG)" },
  { value: "TECHNICIEN CONSEIL VENTE EN ALIMENTATION SPE PRODUITS ALIMENTAIRES (BAC PRO AG)", label: "TECHNICIEN CONSEIL VENTE EN ALIMENTATION SPE PRODUITS ALIMENTAIRES (BAC PRO AG)" },
  { value: "SERVICE AUX PERSONNES ET AUX TERRITOIRES (SEC PRO AG)", label: "SERVICE AUX PERSONNES ET AUX TERRITOIRES (SEC PRO AG)" },
  { value: "SERVICES AUX PERSONNES ET AUX TERRITOIRES (BAC PRO AG)", label: "SERVICES AUX PERSONNES ET AUX TERRITOIRES (BAC PRO AG)" },
  { value: "DESSINATEUR EN ARTS APPLIQUES SPECIALITE : CERAMIQUE (BT)", label: "DESSINATEUR EN ARTS APPLIQUES SPECIALITE : CERAMIQUE (BT)" },
  { value: "TECHNICIEN DES METIERS DU SPECTACLE OPTION MACHINISTE CONSTRUCTEUR (DIPLOME)", label: "TECHNICIEN DES METIERS DU SPECTACLE OPTION MACHINISTE CONSTRUCTEUR (DIPLOME)" },
  { value: "STI2D INNOVATION TECHNOLOGIQUE ET ECO CONCEPTION (BAC TECHNO)", label: "STI2D INNOVATION TECHNOLOGIQUE ET ECO CONCEPTION (BAC TECHNO)" },
  { value: "STI2D SYSTÈME D'INFORMATION ET NUMERIQUE (BAC TECHNO)", label: "STI2D SYSTÈME D'INFORMATION ET NUMERIQUE (BAC TECHNO)" },
  { value: "STL BIOTECHNOLOGIES (BAC TECHNO)", label: "STL BIOTECHNOLOGIES (BAC TECHNO)" },
  { value: "STL SCIENCES PHYSIQUES ET CHIMIQUES EN LABORATOIRE (BAC TECHNO)", label: "STL SCIENCES PHYSIQUES ET CHIMIQUES EN LABORATOIRE (BAC TECHNO)" },
  { value: "STL BIOCHIMIE-BIOLOGIE-BIOTECHNOLOGIE (BAC TECHNO)", label: "STL BIOCHIMIE-BIOLOGIE-BIOTECHNOLOGIE (BAC TECHNO)" },
  { value: "SCIENCES ET TECHNOLOGIES DE L'INGENIERIE, DE L'INNOVATION ET DU DEVELOPPEMENT DURABLE (1ERE STI2D)", label: "SCIENCES ET TECHNOLOGIES DE L'INGENIERIE, DE L'INNOVATION ET DU DEVELOPPEMENT DURABLE (1ERE STI2D)" },
  { value: "SCIENCES ET TECHNOLOGIES DU MANAGEMENT ET DE LA GESTION (1ERE STMG)", label: "SCIENCES ET TECHNOLOGIES DU MANAGEMENT ET DE LA GESTION (1ERE STMG)" },
  { value: "STMG GESTION ET FINANCE (BAC TECHNO)", label: "STMG GESTION ET FINANCE (BAC TECHNO)" },
  { value: "STMG MERCATIQUE (MARKETING) (BAC TECHNO)", label: "STMG MERCATIQUE (MARKETING) (BAC TECHNO)" },
  { value: "STMG RESSOURCES HUMAINES ET COMMUNICATION (BAC TECHNO)", label: "STMG RESSOURCES HUMAINES ET COMMUNICATION (BAC TECHNO)" },
  { value: "STMG SYSTEMES D'INFORMATION DE GESTION (BAC TECHNO)", label: "STMG SYSTEMES D'INFORMATION DE GESTION (BAC TECHNO)" },
  { value: "ST2S SCIENCES ET TECHNOLOGIES DE LA SANTE ET DU SOCIAL (BAC TECHNO)", label: "ST2S SCIENCES ET TECHNOLOGIES DE LA SANTE ET DU SOCIAL (BAC TECHNO)" },
  { value: "STHR SCIENCES ET TECHNOLOGIES DE L'HOTELLERIE ET DE LA RESTAURATION (BAC TECHNO)", label: "STHR SCIENCES ET TECHNOLOGIES DE L'HOTELLERIE ET DE LA RESTAURATION (BAC TECHNO)" },
  { value: "SPECIALITE ACTIVITES NAUTIQUES (BP JEPS)", label: "SPECIALITE ACTIVITES NAUTIQUES (BP JEPS)" },
  { value: "SPECIALITE ACTIVITES PUGILISTIQUES (BP JEPS)", label: "SPECIALITE ACTIVITES PUGILISTIQUES (BP JEPS)" },
  { value: "SPECIALITE GOLF (BP JEPS)", label: "SPECIALITE GOLF (BP JEPS)" },
  { value: "SPECIALITE TECHNIQUES DE L'INFORMATION ET DE LA COMMUNICATION (BP JEPS)", label: "SPECIALITE TECHNIQUES DE L'INFORMATION ET DE LA COMMUNICATION (BP JEPS)" },
  { value: "SPECIALITE LOISIRS TOUS PUBLICS (BP JEPS)", label: "SPECIALITE LOISIRS TOUS PUBLICS (BP JEPS)" },
  { value: "SPECIALITE ACTIVITES PHYSIQUES POUR TOUS (BP JEPS)", label: "SPECIALITE ACTIVITES PHYSIQUES POUR TOUS (BP JEPS)" },
  { value: "SPECIALITE PECHE DE LOISIRS (BP JEPS)", label: "SPECIALITE PECHE DE LOISIRS (BP JEPS)" },
  { value: "SPECIALITE ACTIVITES EQUESTRES (BP JEPS)", label: "SPECIALITE ACTIVITES EQUESTRES (BP JEPS)" },
  { value: "SPECIALITE SPORT AUTOMOBILE (BP JEPS)", label: "SPECIALITE SPORT AUTOMOBILE (BP JEPS)" },
  { value: "SPECIALITE ANIMATION CULTURELLE (BP JEPS)", label: "SPECIALITE ANIMATION CULTURELLE (BP JEPS)" },
  { value: "SPECIALITE ACTIVITES GYMNIQUES DE LA FORME ET DE LA FORCE (BP JEPS)", label: "SPECIALITE ACTIVITES GYMNIQUES DE LA FORME ET DE LA FORCE (BP JEPS)" },
  { value: "SPECIALITE ANIMATION SOCIALE (BP JEPS)", label: "SPECIALITE ANIMATION SOCIALE (BP JEPS)" },
  { value: "SPECIALITE ACTIVITES SPORTS COLLECTIFS (BP JEPS)", label: "SPECIALITE ACTIVITES SPORTS COLLECTIFS (BP JEPS)" },
  { value: "SPECIALITE ACTIVITES DE RANDONNEES ( BP JEPS)", label: "SPECIALITE ACTIVITES DE RANDONNEES ( BP JEPS)" },
  { value: "SPECIALITE ACTIVITES AQUATIQUES (BP JEPS)", label: "SPECIALITE ACTIVITES AQUATIQUES (BP JEPS)" },
  { value: "SPECIALITE VOL LIBRE (BP JEPS)", label: "SPECIALITE VOL LIBRE (BP JEPS)" },
  { value: "SPECIALITE ACTIVITES DU CIRQUE (BP JEPS)", label: "SPECIALITE ACTIVITES DU CIRQUE (BP JEPS)" },
  { value: "SPECIALITE ACTIVITES AQUATIQUES ET DE LA NATATION (BP JEPS)", label: "SPECIALITE ACTIVITES AQUATIQUES ET DE LA NATATION (BP JEPS)" },
  { value: "SPECIALITE EDUCATION A L'ENVIRONNEMENT VERS UN DEVELOPPEMENT DURABLE (BPJEPS)", label: "SPECIALITE EDUCATION A L'ENVIRONNEMENT VERS UN DEVELOPPEMENT DURABLE (BPJEPS)" },
  { value: "SPECIALITE JUDO-JIUJITSU (BP JEPS)", label: "SPECIALITE JUDO-JIUJITSU (BP JEPS)" },
  { value: "SPECIALITE RUGBY A XV (BPJEPS)", label: "SPECIALITE RUGBY A XV (BPJEPS)" },
  { value: "SPECIALITE ANIMATEUR (BP JEPS)", label: "SPECIALITE ANIMATEUR (BP JEPS)" },
  { value: "SPECIALITE EDUCATEUR SPORTIF (BP JEPS)", label: "SPECIALITE EDUCATEUR SPORTIF (BP JEPS)" },
  { value: "SPECIALITE ACTIVITES DU CYCLISME (BP JEPS)", label: "SPECIALITE ACTIVITES DU CYCLISME (BP JEPS)" },
  { value: "SPECIALITE-BASKET BALL (BP JEPS)", label: "SPECIALITE-BASKET BALL (BP JEPS)" },
  { value: "TECHNIQUES DE LABORATOIRE DE RECHERCHE OPTION A BIOLOGIE (BP)", label: "TECHNIQUES DE LABORATOIRE DE RECHERCHE OPTION A BIOLOGIE (BP)" },
  { value: "TECHNIQUES DE LABORATOIRE DE RECHERCHE OPTION B PHYSICOCHIMIE (BP)", label: "TECHNIQUES DE LABORATOIRE DE RECHERCHE OPTION B PHYSICOCHIMIE (BP)" },
  { value: "CHARCUTIER-TRAITEUR (BP)", label: "CHARCUTIER-TRAITEUR (BP)" },
  { value: "BOULANGER (BP)", label: "BOULANGER (BP)" },
  { value: "ARTS DE LA CUISINE (BP)", label: "ARTS DE LA CUISINE (BP)" },
  { value: "BOUCHER (BP)", label: "BOUCHER (BP)" },
  { value: "CONDUCTEUR D'APPAREILS DES INDUSTRIES CHIMIQUES (BP)", label: "CONDUCTEUR D'APPAREILS DES INDUSTRIES CHIMIQUES (BP)" },
  { value: "GEMMOLOGUE (BP)", label: "GEMMOLOGUE (BP)" },
  { value: "MISE EN OEUVRE DES CAOUTCHOUCS ET DES ELASTOMERES THERMOPLASTIQUES (BP)", label: "MISE EN OEUVRE DES CAOUTCHOUCS ET DES ELASTOMERES THERMOPLASTIQUES (BP)" },
  { value: "PLASTIQUES ET COMPOSITES (BP)", label: "PLASTIQUES ET COMPOSITES (BP)" },
  { value: "GAZ OPTION B : DISTRIBUTION DU GAZ (BP)", label: "GAZ OPTION B : DISTRIBUTION DU GAZ (BP)" },
  { value: "MONTEUR EN INSTALLATIONS DU GENIE CLIMATIQUE ET SANITAIRE (BP)", label: "MONTEUR EN INSTALLATIONS DU GENIE CLIMATIQUE ET SANITAIRE (BP)" },
  { value: "INSTALLATEUR, DEPANNEUR EN FROID ET CONDITIONNEMENT D'AIR (BP)", label: "INSTALLATEUR, DEPANNEUR EN FROID ET CONDITIONNEMENT D'AIR (BP)" },
  { value: "CONDUCTEUR D'ENGINS : TRAVAUX PUBLICS ET CARRIERES (BP)", label: "CONDUCTEUR D'ENGINS : TRAVAUX PUBLICS ET CARRIERES (BP)" },
  { value: "TAILLEUR DE PIERRES 'MONUMENTS HISTORIQUES' (BP DEPARTEMENTAL)", label: "TAILLEUR DE PIERRES 'MONUMENTS HISTORIQUES' (BP DEPARTEMENTAL)" },
  { value: "MACON (BP)", label: "MACON (BP)" },
  { value: "COUVREUR (BP)", label: "COUVREUR (BP)" },
  { value: "METIERS DE LA PIERRE (BP)", label: "METIERS DE LA PIERRE (BP)" },
  { value: "METIERS DE LA PISCINE (BP)", label: "METIERS DE LA PISCINE (BP)" },
  { value: "ETANCHEITE DU BATIMENT ET DES TRAVAUX PUBLICS (BP)", label: "ETANCHEITE DU BATIMENT ET DES TRAVAUX PUBLICS (BP)" },
  { value: "CARRELAGE MOSAIQUE (BP)", label: "CARRELAGE MOSAIQUE (BP)" },
  { value: "MENUISIER ALUMINIUM-VERRE (BP)", label: "MENUISIER ALUMINIUM-VERRE (BP)" },
  { value: "METIERS DU PLATRE ET DE L'ISOLATION (BP)", label: "METIERS DU PLATRE ET DE L'ISOLATION (BP)" },
  { value: "PEINTRE APPLICATEUR DE REVETEMENTS (BP)", label: "PEINTRE APPLICATEUR DE REVETEMENTS (BP)" },
  { value: "CARRELEUR MOSAISTE (BP)", label: "CARRELEUR MOSAISTE (BP)" },
  { value: "CHARPENTIER DE MARINE (BP)", label: "CHARPENTIER DE MARINE (BP)" },
  { value: "MENUISIER (BP)", label: "MENUISIER (BP)" },
  { value: "CHARPENTIER BOIS (BP)", label: "CHARPENTIER BOIS (BP)" },
  { value: "MAINTENANCE DES ARTICLES TEXTILES OPTION PRESSING (BP)", label: "MAINTENANCE DES ARTICLES TEXTILES OPTION PRESSING (BP)" },
  { value: "AMEUBLEMENT OPTION TAPISSERIE DECORATION (BP)", label: "AMEUBLEMENT OPTION TAPISSERIE DECORATION (BP)" },
  { value: "VETEMENT SUR MESURE OPTION COUTURE FLOU (BP)", label: "VETEMENT SUR MESURE OPTION COUTURE FLOU (BP)" },
  { value: "VETEMENT SUR MESURE OPTION TAILLEUR DAME (BP)", label: "VETEMENT SUR MESURE OPTION TAILLEUR DAME (BP)" },
  { value: "VETEMENT SUR MESURE OPTION TAILLEUR HOMME (BP)", label: "VETEMENT SUR MESURE OPTION TAILLEUR HOMME (BP)" },
  { value: "METALLIER (BP)", label: "METALLIER (BP)" },
  { value: "ELECTRICIEN(NE) (BP)", label: "ELECTRICIEN(NE) (BP)" },
  { value: "FLEURISTE (BP)", label: "FLEURISTE (BP)" },
  { value: "LIBRAIRE (BP)", label: "LIBRAIRE (BP)" },
  { value: "BUREAUTIQUE (BP)", label: "BUREAUTIQUE (BP)" },
  { value: "PREPARATEUR EN PHARMACIE (BP)", label: "PREPARATEUR EN PHARMACIE (BP)" },
  { value: "BARMAN (BP)", label: "BARMAN (BP)" },
  { value: "GOUVERNANTE (BP)", label: "GOUVERNANTE (BP)" },
  { value: "SOMMELIER (BP)", label: "SOMMELIER (BP)" },
  { value: "ARTS DU SERVICE ET COMMERCIALISATION EN RESTAURATION (BP)", label: "ARTS DU SERVICE ET COMMERCIALISATION EN RESTAURATION (BP)" },
  { value: "COIFFURE (BP)", label: "COIFFURE (BP)" },
  { value: "AGENT TECHNIQUE DE PREVENTION ET DE SECURITE (BP)", label: "AGENT TECHNIQUE DE PREVENTION ET DE SECURITE (BP)" },
  { value: "AGENT TECHNIQUE DE SECURITE DANS LES TRANSPORTS (BP)", label: "AGENT TECHNIQUE DE SECURITE DANS LES TRANSPORTS (BP)" },
  { value: "TECHNICIEN DE RECHERCHE DEVELOPPEMENT (BP)", label: "TECHNICIEN DE RECHERCHE DEVELOPPEMENT (BP)" },
  { value: "AGROEQUIPEMENT CONDUITE ET MAINTENANCE DES MATERIELS (BP)", label: "AGROEQUIPEMENT CONDUITE ET MAINTENANCE DES MATERIELS (BP)" },
  { value: "RESPONSABLE D'ENTREPRISE AGRICOLE (BP)", label: "RESPONSABLE D'ENTREPRISE AGRICOLE (BP)" },
  { value: "RESPONSABLE D'ATELIER DE PRODUCTIONS HORTICOLES (BP)", label: "RESPONSABLE D'ATELIER DE PRODUCTIONS HORTICOLES (BP)" },
  { value: "RESPONSABLE D'EXPLOITATION AQUACOLE MARITIME CONTINENTALE (BP)", label: "RESPONSABLE D'EXPLOITATION AQUACOLE MARITIME CONTINENTALE (BP)" },
  { value: "EDUCATEUR CANIN (BP)", label: "EDUCATEUR CANIN (BP)" },
  { value: "RESPONSABLE D'ENTREPRISE HIPPIQUE (BP)", label: "RESPONSABLE D'ENTREPRISE HIPPIQUE (BP)" },
  { value: "RESPONSABLE DES CHANTIERS FORESTIERS (BP)", label: "RESPONSABLE DES CHANTIERS FORESTIERS (BP)" },
  { value: "AMENAGEMENTS PAYSAGERS (BP)", label: "AMENAGEMENTS PAYSAGERS (BP)" },
  { value: "INDUSTRIES ALIMENTAIRES (BP)", label: "INDUSTRIES ALIMENTAIRES (BP)" },
  { value: "MONITEUR-EDUCATEUR (DIPLOME D'ETAT ex CAFME)", label: "MONITEUR-EDUCATEUR (DIPLOME D'ETAT ex CAFME)" },
  { value: "TECHNICIEN DE L'INTERVENTION SOCIALE ET FAMILIALE (DIPLOME)", label: "TECHNICIEN DE L'INTERVENTION SOCIALE ET FAMILIALE (DIPLOME)" },
  { value: "AGRICULTEUR ANIMATEUR DE PROJET COLLECTIF DE DEVELOPPEMENT TERRITORIAL (EXPERIMENTAL) (CSA)", label: "AGRICULTEUR ANIMATEUR DE PROJET COLLECTIF DE DEVELOPPEMENT TERRITORIAL (EXPERIMENTAL) (CSA)" },
  { value: "ARROSAGE INTEGRE (CSA)", label: "ARROSAGE INTEGRE (CSA)" },
  { value: "CONDUITE DE PRODUCTION EN AGRICULTURE BIOLOGIQUE ET COMMERCIALISATION (CSA)", label: "CONDUITE DE PRODUCTION EN AGRICULTURE BIOLOGIQUE ET COMMERCIALISATION (CSA)" },
  { value: "TECHNICIEN DE CAVE (CSA)", label: "TECHNICIEN DE CAVE (CSA)" },
  { value: "PLANTES A PARFUM ,AROMATIQUES ET MEDICINALES A USAGE ARTISANAL OU INDUSTRIEL (CSA)", label: "PLANTES A PARFUM ,AROMATIQUES ET MEDICINALES A USAGE ARTISANAL OU INDUSTRIEL (CSA)" },
  { value: "ELEVAGE DES RUMINANTS - PRODUCTION ET COMMERCIALISATION DES PRODUITS (CSA)", label: "ELEVAGE DES RUMINANTS - PRODUCTION ET COMMERCIALISATION DES PRODUITS (CSA)" },
  { value: "CONDUITE DE L'ELEVAGE LAITIER (CSA)", label: "CONDUITE DE L'ELEVAGE LAITIER (CSA)" },
  { value: "CONDUITE DE L'ELEVAGE PORCIN (CSA)", label: "CONDUITE DE L'ELEVAGE PORCIN (CSA)" },
  { value: "CONDUITE D’UN ELEVAGE CAPRIN ET COMMERCIALISATION DES PRODUITS (CSA)", label: "CONDUITE D’UN ELEVAGE CAPRIN ET COMMERCIALISATION DES PRODUITS (CSA)" },
  { value: "PRODUCTION,TRANSFORMATION ET COMMERCIALISATION DES PRODUITS FERMIERS (CSA)", label: "PRODUCTION,TRANSFORMATION ET COMMERCIALISATION DES PRODUITS FERMIERS (CSA)" },
  { value: "CONDUITE D’UN ELEVAGE AVICOLE ET COMMERCIALISATION DES PRODUITS (CSA)", label: "CONDUITE D’UN ELEVAGE AVICOLE ET COMMERCIALISATION DES PRODUITS (CSA)" },
  { value: "EDUCATION ET TRAVAIL DES JEUNES EQUIDES (CSA)", label: "EDUCATION ET TRAVAIL DES JEUNES EQUIDES (CSA)" },
  { value: "CONDUITE D'UN ELEVAGE OVIN VIANDE (CSA)", label: "CONDUITE D'UN ELEVAGE OVIN VIANDE (CSA)" },
  { value: "CONSTRUCTIONS PAYSAGERES (CSA)", label: "CONSTRUCTIONS PAYSAGERES (CSA)" },
  { value: "MAINTENANCE DES TERRAINS DE SPORTS ET DE LOISIRS (CSA)", label: "MAINTENANCE DES TERRAINS DE SPORTS ET DE LOISIRS (CSA)" },
  { value: "DIAGNOSTIC ET TAILLE DES ARBRES (CSA)", label: "DIAGNOSTIC ET TAILLE DES ARBRES (CSA)" },
  { value: "TRANSFORMATION DES PRODUITS CARNES (CSA)", label: "TRANSFORMATION DES PRODUITS CARNES (CSA)" },
  { value: "TECHNICIEN SPECIALISE EN TRANSFORMATION LAITIERE (CSA)", label: "TECHNICIEN SPECIALISE EN TRANSFORMATION LAITIERE (CSA)" },
  { value: "TOURISME VERT ACCUEIL ET ANIMATION EN MILIEU RURAL (CSA)", label: "TOURISME VERT ACCUEIL ET ANIMATION EN MILIEU RURAL (CSA)" },
  { value: "TECHNICIEN AGRICOLE (UNMFREO)", label: "TECHNICIEN AGRICOLE (UNMFREO)" },
  { value: "TECHNICIEN JARDINS ESPACES VERTS (CCTAR UNMFREO)", label: "TECHNICIEN JARDINS ESPACES VERTS (CCTAR UNMFREO)" },
  { value: "JARDINIER-BOTANISTE (CFAA - CFPPA DE CHATEAUFARINE)", label: "JARDINIER-BOTANISTE (CFAA - CFPPA DE CHATEAUFARINE)" },
  { value: "ELEVEUR (UNREP)", label: "ELEVEUR (UNREP)" },
  { value: "AUXILIAIRE SPECIALISE VETERINAIRE (GISPA SNVEL)", label: "AUXILIAIRE SPECIALISE VETERINAIRE (GISPA SNVEL)" },
  { value: "SOMMELIER-CONSEIL CAVISTE (UNIVERSITE DU VIN)", label: "SOMMELIER-CONSEIL CAVISTE (UNIVERSITE DU VIN)" },
  { value: "CUISINIER GESTIONNAIRE DE RESTAURATION COLLECTIVE (UNMFREO)", label: "CUISINIER GESTIONNAIRE DE RESTAURATION COLLECTIVE (UNMFREO)" },
  { value: "1ERE ANNEE DE CAP PRODUCTION (CAP)", label: "1ERE ANNEE DE CAP PRODUCTION (CAP)" },
  { value: "CONDUCTEUR D'INSTALLATIONS DE PRODUCTION (CAP)", label: "CONDUCTEUR D'INSTALLATIONS DE PRODUCTION (CAP)" },
  { value: "MAREYAGE (CAP)", label: "MAREYAGE (CAP)" },
  { value: "MATELOT (CAPM)", label: "MATELOT (CAPM)" },
  { value: "PROPRETE DE L'ENVIRONNEMENT URBAIN - COLLECTE ET RECYCLAGE (CAP)", label: "PROPRETE DE L'ENVIRONNEMENT URBAIN - COLLECTE ET RECYCLAGE (CAP)" },
  { value: "AGENT POLYVALENT DE RESTAURATION (CAP)", label: "AGENT POLYVALENT DE RESTAURATION (CAP)" },
  { value: "CHARCUTIER-TRAITEUR (CAP)", label: "CHARCUTIER-TRAITEUR (CAP)" },
  { value: "CHOCOLATIER CONFISEUR (CAP)", label: "CHOCOLATIER CONFISEUR (CAP)" },
  { value: "BOUCHER (CAP)", label: "BOUCHER (CAP)" },
  { value: "BOULANGER (CAP)", label: "BOULANGER (CAP)" },
  { value: "GLACIER-FABRICANT (CAP)", label: "GLACIER-FABRICANT (CAP)" },
  { value: "CUISINE (CAP)", label: "CUISINE (CAP)" },
  { value: "CREMIER-FROMAGER (CAP)", label: "CREMIER-FROMAGER (CAP)" },
  { value: "INDUSTRIES CHIMIQUES (CAP)", label: "INDUSTRIES CHIMIQUES (CAP)" },
  { value: "MOULEUR NOYAUTEUR : CUIVRE ET BRONZE (CAP)", label: "MOULEUR NOYAUTEUR : CUIVRE ET BRONZE (CAP)" },
  { value: "DOREUR A LA FEUILLE ORNEMANISTE (CAP)", label: "DOREUR A LA FEUILLE ORNEMANISTE (CAP)" },
  { value: "LAPIDAIRE OPTION A : DIAMANT (CAP)", label: "LAPIDAIRE OPTION A : DIAMANT (CAP)" },
  { value: "ORFEVRE OPTION A : MONTEUR EN ORFEVRERIE (CAP)", label: "ORFEVRE OPTION A : MONTEUR EN ORFEVRERIE (CAP)" },
  { value: "ORFEVRE OPTION B : TOURNEUR REPOUSSEUR EN ORFEVRERIE (CAP)", label: "ORFEVRE OPTION B : TOURNEUR REPOUSSEUR EN ORFEVRERIE (CAP)" },
  { value: "ORFEVRE OPTION C : POLISSEUR AVIVEUR EN ORFEVRERIE (CAP)", label: "ORFEVRE OPTION C : POLISSEUR AVIVEUR EN ORFEVRERIE (CAP)" },
  { value: "LAPIDAIRE OPTION B : PIERRES DE COULEUR (CAP)", label: "LAPIDAIRE OPTION B : PIERRES DE COULEUR (CAP)" },
  { value: "BRONZIER OPTION A : MONTEUR EN BRONZE (CAP)", label: "BRONZIER OPTION A : MONTEUR EN BRONZE (CAP)" },
  { value: "BRONZIER OPTION B : CISELEUR SUR BRONZE (CAP)", label: "BRONZIER OPTION B : CISELEUR SUR BRONZE (CAP)" },
  { value: "BRONZIER OPTION C : TOURNEUR SUR BRONZE (CAP)", label: "BRONZIER OPTION C : TOURNEUR SUR BRONZE (CAP)" },
  { value: "ORFEVRE OPTION D : PLANEUR EN ORFEVRERIE (CAP)", label: "ORFEVRE OPTION D : PLANEUR EN ORFEVRERIE (CAP)" },
  { value: "ART ET TECHNIQUES DE LA BIJOUTERIE-JOAILLERIE OPTION BIJOUTERIE-JOAILLERIE (CAP)", label: "ART ET TECHNIQUES DE LA BIJOUTERIE-JOAILLERIE OPTION BIJOUTERIE-JOAILLERIE (CAP)" },
  { value: "ART ET TECHNIQUES DE LA BIJOUTERIE-JOAILLERIE OPTION BIJOUTERIE-SERTISSAGE (CAP)", label: "ART ET TECHNIQUES DE LA BIJOUTERIE-JOAILLERIE OPTION BIJOUTERIE-SERTISSAGE (CAP)" },
  { value: "ART ET TECHNIQUES DE LA BIJOUTERIE-JOAILLERIE OPTION POLISSAGE-FINITION (CAP)", label: "ART ET TECHNIQUES DE LA BIJOUTERIE-JOAILLERIE OPTION POLISSAGE-FINITION (CAP)" },
  { value: "AGENT DE MAINTENANCE DES INDUSTRIES DE MATERIAUX DE CONSTRUCTION ET CONNEXES (CAP)", label: "AGENT DE MAINTENANCE DES INDUSTRIES DE MATERIAUX DE CONSTRUCTION ET CONNEXES (CAP)" },
  { value: "FABRICATION INDUSTRIELLE DES CERAMIQUES (CAP)", label: "FABRICATION INDUSTRIELLE DES CERAMIQUES (CAP)" },
  { value: "MODELES ET MOULES CERAMIQUES (CAP)", label: "MODELES ET MOULES CERAMIQUES (CAP)" },
  { value: "TOURNAGE EN CERAMIQUE (CAP)", label: "TOURNAGE EN CERAMIQUE (CAP)" },
  { value: "DECORATION EN CERAMIQUE (CAP)", label: "DECORATION EN CERAMIQUE (CAP)" },
  { value: "ARTS ET TECHNIQUES DU VERRE OPTION VITRAILLISTE (CAP)", label: "ARTS ET TECHNIQUES DU VERRE OPTION VITRAILLISTE (CAP)" },
  { value: "ARTS ET TECHNIQUES DU VERRE OPTION DECORATEUR (CAP)", label: "ARTS ET TECHNIQUES DU VERRE OPTION DECORATEUR (CAP)" },
  { value: "ARTS DU VERRE ET DU CRISTAL (CAP)", label: "ARTS DU VERRE ET DU CRISTAL (CAP)" },
  { value: "SOUFFLEUR DE VERRE OPTION ENSEIGNE LUMINEUSE (CAP)", label: "SOUFFLEUR DE VERRE OPTION ENSEIGNE LUMINEUSE (CAP)" },
  { value: "SOUFFLEUR DE VERRE OPTION VERRERIE SCIENTIFIQUE (CAP)", label: "SOUFFLEUR DE VERRE OPTION VERRERIE SCIENTIFIQUE (CAP)" },
  { value: "MISE EN OEUVRE DES CAOUTCHOUCS ET DES ELASTOMERES THERMOPLASTIQUES (CAP)", label: "MISE EN OEUVRE DES CAOUTCHOUCS ET DES ELASTOMERES THERMOPLASTIQUES (CAP)" },
  { value: "PLASTURGIE (CAP)", label: "PLASTURGIE (CAP)" },
  { value: "COMPOSITES, PLASTIQUES CHAUDRONNES (CAP)", label: "COMPOSITES, PLASTIQUES CHAUDRONNES (CAP)" },
  { value: "MECANICIEN CONDUCTEUR DES SCIERIES ET DES INDUSTRIES MECANIQUES DU BOIS OP. B : MECANICIEN AFFUTEUR DE SCIAGE, TRANCHAGE, DEROULAGE (CAP)", label: "MECANICIEN CONDUCTEUR DES SCIERIES ET DES INDUSTRIES MECANIQUES DU BOIS OP. B : MECANICIEN AFFUTEUR DE SCIAGE, TRANCHAGE, DEROULAGE (CAP)" },
  { value: "INSTALLATEUR THERMIQUE (CAP)", label: "INSTALLATEUR THERMIQUE (CAP)" },
  { value: "INSTALLATEUR EN FROID ET CONDITIONNEMENT D'AIR (CAP)", label: "INSTALLATEUR EN FROID ET CONDITIONNEMENT D'AIR (CAP)" },
  { value: "MONTEUR EN INSTALLATIONS THERMIQUES (CAP)", label: "MONTEUR EN INSTALLATIONS THERMIQUES (CAP)" },
  { value: "MAINTENANCE DE BATIMENTS DE COLLECTIVITES (CAP)", label: "MAINTENANCE DE BATIMENTS DE COLLECTIVITES (CAP)" },
  { value: "DESSINATEUR INDUSTRIEL D'AMEUBLEMENT (CAP DEPARTEMENTAL)", label: "DESSINATEUR INDUSTRIEL D'AMEUBLEMENT (CAP DEPARTEMENTAL)" },
  { value: "CONSTRUCTEUR DE ROUTES (CAP)", label: "CONSTRUCTEUR DE ROUTES (CAP)" },
  { value: "CONSTRUCTEUR EN OUVRAGES D'ART (CAP)", label: "CONSTRUCTEUR EN OUVRAGES D'ART (CAP)" },
  { value: "CONDUCTEUR D'ENGINS : TRAVAUX PUBLICS ET CARRIERES (CAP)", label: "CONDUCTEUR D'ENGINS : TRAVAUX PUBLICS ET CARRIERES (CAP)" },
  { value: "MACON (CAP)", label: "MACON (CAP)" },
  { value: "COUVREUR (CAP)", label: "COUVREUR (CAP)" },
  { value: "CONSTRUCTEUR EN BETON ARME DU BATIMENT (CAP)", label: "CONSTRUCTEUR EN BETON ARME DU BATIMENT (CAP)" },
  { value: "TAILLEUR DE PIERRE (CAP)", label: "TAILLEUR DE PIERRE (CAP)" },
  { value: "MARBRIER DU BATIMENT ET DE LA DECORATION (CAP)", label: "MARBRIER DU BATIMENT ET DE LA DECORATION (CAP)" },
  { value: "MONTEUR EN ISOLATION THERMIQUE ET ACOUSTIQUE (CAP)", label: "MONTEUR EN ISOLATION THERMIQUE ET ACOUSTIQUE (CAP)" },
  { value: "STAFFEUR ORNEMANISTE (CAP)", label: "STAFFEUR ORNEMANISTE (CAP)" },
  { value: "INSTALLATEUR SANITAIRE (CAP)", label: "INSTALLATEUR SANITAIRE (CAP)" },
  { value: "PEINTRE-APPLICATEUR DE REVETEMENT (CAP)", label: "PEINTRE-APPLICATEUR DE REVETEMENT (CAP)" },
  { value: "SOLIER-MOQUETTISTE (CAP)", label: "SOLIER-MOQUETTISTE (CAP)" },
  { value: "ETANCHEUR DU BATIMENT ET DES TRAVAUX PUBLICS (CAP)", label: "ETANCHEUR DU BATIMENT ET DES TRAVAUX PUBLICS (CAP)" },
  { value: "MENUISIER ALUMINIUM-VERRE (CAP)", label: "MENUISIER ALUMINIUM-VERRE (CAP)" },
  { value: "PLATRIER-PLAQUISTE (CAP)/METIERS DU PLATRE ET DE L'ISOLATION (CAP)", label: "PLATRIER-PLAQUISTE (CAP)/METIERS DU PLATRE ET DE L'ISOLATION (CAP)" },
  { value: "MONTEUR EN INSTALLATIONS SANITAIRES (CAP)", label: "MONTEUR EN INSTALLATIONS SANITAIRES (CAP)" },
  { value: "LUTHERIE (CAP)", label: "LUTHERIE (CAP)" },
  { value: "ARTS DU BOIS OPTION A : SCULPTEUR ORNEMANISTE (CAP)", label: "ARTS DU BOIS OPTION A : SCULPTEUR ORNEMANISTE (CAP)" },
  { value: "ARTS DU BOIS OPTION B : TOURNEUR (CAP)", label: "ARTS DU BOIS OPTION B : TOURNEUR (CAP)" },
  { value: "ARTS DU BOIS OPTION C : MARQUETEUR (CAP)", label: "ARTS DU BOIS OPTION C : MARQUETEUR (CAP)" },
  { value: "ENCADREUR (CAP)", label: "ENCADREUR (CAP)" },
  { value: "TONNELLERIE (CAP)", label: "TONNELLERIE (CAP)" },
  { value: "CHARPENTIER BOIS (CAP)", label: "CHARPENTIER BOIS (CAP)" },
  { value: "CONSTRUCTEUR BOIS (CAP)", label: "CONSTRUCTEUR BOIS (CAP)" },
  { value: "MENUISIER FABRICANT DE MENUISERIE, MOBILIER ET AGENCEMENT (CAP)", label: "MENUISIER FABRICANT DE MENUISERIE, MOBILIER ET AGENCEMENT (CAP)" },
  { value: "MENUISIER INSTALLATEUR (CAP)", label: "MENUISIER INSTALLATEUR (CAP)" },
  { value: "CONDUCTEUR-OPERATEUR DE SCIERIE (CAP)", label: "CONDUCTEUR-OPERATEUR DE SCIERIE (CAP)" },
  { value: "EBENISTE (CAP)", label: "EBENISTE (CAP)" },
  { value: "METIER DU PRESSING (CAP)", label: "METIER DU PRESSING (CAP)" },
  { value: "METIERS DE LA BLANCHISSERIE (CAP)", label: "METIERS DE LA BLANCHISSERIE (CAP)" },
  { value: "PLUMASSIERE (CAP DEPARTEMENTAL)", label: "PLUMASSIERE (CAP DEPARTEMENTAL)" },
  { value: "TAPISSIER-TAPISSIERE D'AMEUBLEMENT EN SIEGE (CAP)", label: "TAPISSIER-TAPISSIERE D'AMEUBLEMENT EN SIEGE (CAP)" },
  { value: "TAPISSIER-TAPISSIERE D'AMEUBLEMENT EN DECOR (CAP)", label: "TAPISSIER-TAPISSIERE D'AMEUBLEMENT EN DECOR (CAP)" },
  { value: "METIERS DE LA MODE-VÊTEMENT FLOU (CAP)", label: "METIERS DE LA MODE-VÊTEMENT FLOU (CAP)" },
  { value: "METIERS DE LA MODE-VÊTEMENT TAILLEUR (CAP)", label: "METIERS DE LA MODE-VÊTEMENT TAILLEUR (CAP)" },
  { value: "METIERS DE LA MODE : CHAPELIER-MODISTE (CAP)", label: "METIERS DE LA MODE : CHAPELIER-MODISTE (CAP)" },
  { value: "CORDONNIER BOTTIER (CAP)", label: "CORDONNIER BOTTIER (CAP)" },
  { value: "CHAUSSURE (CAP)", label: "CHAUSSURE (CAP)" },
  { value: "SELLERIE GENERALE (CAP)", label: "SELLERIE GENERALE (CAP)" },
  { value: "CORDONNERIE MULTISERVICE (CAP)", label: "CORDONNERIE MULTISERVICE (CAP)" },
  { value: "SELLIER HARNACHEUR (CAP)", label: "SELLIER HARNACHEUR (CAP)" },
  { value: "MAROQUINERIE (CAP)", label: "MAROQUINERIE (CAP)" },
  { value: "INSTRUMENTS COUPANTS ET DE CHIRURGIE (CAP)", label: "INSTRUMENTS COUPANTS ET DE CHIRURGIE (CAP)" },
  { value: "DECOLLETAGE : OPERATEUR REGLEUR EN DECOLLETAGE (CAP)", label: "DECOLLETAGE : OPERATEUR REGLEUR EN DECOLLETAGE (CAP)" },
  { value: "AGENT VERIFICATEUR D'APPAREILS EXTINCTEURS (CAP)", label: "AGENT VERIFICATEUR D'APPAREILS EXTINCTEURS (CAP)" },
  { value: "TRANSPORT PAR CABLES ET REMONTEES MECANIQUES (CAP)", label: "TRANSPORT PAR CABLES ET REMONTEES MECANIQUES (CAP)" },
  { value: "ARMURERIE (FABRICATION ET REPARATION) (CAP)", label: "ARMURERIE (FABRICATION ET REPARATION) (CAP)" },
  { value: "HORLOGERIE (CAP)", label: "HORLOGERIE (CAP)" },
  { value: "REPARATION ENTRETIEN DES EMBARCATIONS DE PLAISANCE (CAP)", label: "REPARATION ENTRETIEN DES EMBARCATIONS DE PLAISANCE (CAP)" },
  { value: "MAINTENANCE DES VEHICULES OPTION A VOITURES PARTICULIERES (CAP)", label: "MAINTENANCE DES VEHICULES OPTION A VOITURES PARTICULIERES (CAP)" },
  { value: "MAINTENANCE DES VEHICULES OPTION B VEHICULES DE TRANSPORT ROUTIER (CAP)", label: "MAINTENANCE DES VEHICULES OPTION B VEHICULES DE TRANSPORT ROUTIER (CAP)" },
  { value: "MAINTENANCE DES VEHICULES OPTIONS C MOTOCYCLES (CAP)", label: "MAINTENANCE DES VEHICULES OPTIONS C MOTOCYCLES (CAP)" },
  { value: "MAINTENANCE DES MATERIELS OPTION A MATERIELS AGRICOLES (CAP)", label: "MAINTENANCE DES MATERIELS OPTION A MATERIELS AGRICOLES (CAP)" },
  { value: "MAINTENANCE DES MATERIELS OPTION B MATERIELS DE CONSTRUCTION ET DE MANUTENTION (CAP)", label: "MAINTENANCE DES MATERIELS OPTION B MATERIELS DE CONSTRUCTION ET DE MANUTENTION (CAP)" },
  { value: "MAINTENANCE DES MATERIELS OPTION C MATERIELS D'ESPACES VERTS (CAP)", label: "MAINTENANCE DES MATERIELS OPTION C MATERIELS D'ESPACES VERTS (CAP)" },
  { value: "AERONAUTIQUE OPTION AVIONIQUE (CAP)", label: "AERONAUTIQUE OPTION AVIONIQUE (CAP)" },
  { value: "AERONAUTIQUE OPTION SYSTEMES (CAP)", label: "AERONAUTIQUE OPTION SYSTEMES (CAP)" },
  { value: "AERONAUTIQUE OPTION STRUCTURES (CAP)", label: "AERONAUTIQUE OPTION STRUCTURES (CAP)" },
  { value: "SERRURIER METALLIER (CAP)", label: "SERRURIER METALLIER (CAP)" },
  { value: "CONSTRUCTION DES CARROSSERIES (CAP)", label: "CONSTRUCTION DES CARROSSERIES (CAP)" },
  { value: "PEINTURE EN CARROSSERIE (CAP)", label: "PEINTURE EN CARROSSERIE (CAP)" },
  { value: "REPARATION DES CARROSSERIES (CAP)", label: "REPARATION DES CARROSSERIES (CAP)" },
  { value: "FERRONNIER D'ART (CAP)", label: "FERRONNIER D'ART (CAP)" },
  { value: "REALISATIONS INDUSTRIELLES EN CHAUDRONNERIE OU SOUDAGE OPTION A CHAUDRONNERIE (CAP)", label: "REALISATIONS INDUSTRIELLES EN CHAUDRONNERIE OU SOUDAGE OPTION A CHAUDRONNERIE (CAP)" },
  { value: "REALISATIONS INDUSTRIELLES EN CHAUDRONNERIE OU SOUDAGE OPTION B SOUDAGE (CAP)", label: "REALISATIONS INDUSTRIELLES EN CHAUDRONNERIE OU SOUDAGE OPTION B SOUDAGE (CAP)" },
  { value: "METIERS DE L'ENSEIGNE ET DE LA SIGNALETIQUE (CAP)", label: "METIERS DE L'ENSEIGNE ET DE LA SIGNALETIQUE (CAP)" },
  { value: "PREPARATION ET REALISATION D'OUVRAGES ELECTRIQUES (CAP)", label: "PREPARATION ET REALISATION D'OUVRAGES ELECTRIQUES (CAP)" },
  { value: "ELECTRICIEN (CAP)", label: "ELECTRICIEN (CAP)" },
  { value: "1ERE ANNEE DE CAP SERVICES (CAP)", label: "1ERE ANNEE DE CAP SERVICES (CAP)" },
  { value: "EMBALLEUR PROFESSIONNEL (CAP)", label: "EMBALLEUR PROFESSIONNEL (CAP)" },
  { value: "AGENT D'ACCUEIL ET DE CONDUITE ROUTIERE - TRANSPORT DE VOYAGEURS (CAP)", label: "AGENT D'ACCUEIL ET DE CONDUITE ROUTIERE - TRANSPORT DE VOYAGEURS (CAP)" },
  { value: "CONDUCTEUR ROUTIER 'MARCHANDISES' (CAP)", label: "CONDUCTEUR ROUTIER 'MARCHANDISES' (CAP)" },
  { value: "CONDUCTEUR LIVREUR DE MARCHANDISES (CAP)", label: "CONDUCTEUR LIVREUR DE MARCHANDISES (CAP)" },
  { value: "DEMENAGEUR SUR VEHICULE UTILITAIRE LEGER (CAP)", label: "DEMENAGEUR SUR VEHICULE UTILITAIRE LEGER (CAP)" },
  { value: "DISTRIBUTION D'OBJETS ET SERVICES A LA CLIENTELE (CAP)", label: "DISTRIBUTION D'OBJETS ET SERVICES A LA CLIENTELE (CAP)" },
  { value: "TRANSPORT FLUVIAL (CAP)", label: "TRANSPORT FLUVIAL (CAP)" },
  { value: "OPERATEUR/OPERATRICE LOGISTIQUE (CAP)", label: "OPERATEUR/OPERATRICE LOGISTIQUE (CAP)" },
  { value: "OPERATEUR/OPERATRICE DE SERVICE-RELATION CLIENT ET LIVRAISON (CAP)", label: "OPERATEUR/OPERATRICE DE SERVICE-RELATION CLIENT ET LIVRAISON (CAP)" },
  { value: "FLEURISTE EN FLEURS ARTIFICIELLES (CAP DEPARTEMENTAL)", label: "FLEURISTE EN FLEURS ARTIFICIELLES (CAP DEPARTEMENTAL)" },
  { value: "EMPLOYE DE COMMERCE MULTI-SPECIALITES (CAP)", label: "EMPLOYE DE COMMERCE MULTI-SPECIALITES (CAP)" },
  { value: "EMPLOYE DE VENTE SPECIALISE OPTION A : PRODUITS ALIMENTAIRES (CAP)", label: "EMPLOYE DE VENTE SPECIALISE OPTION A : PRODUITS ALIMENTAIRES (CAP)" },
  { value: "EMPLOYE DE VENTE SPECIALISE OPTION B : PRODUITS D'EQUIPEMENT COURANT (CAP)", label: "EMPLOYE DE VENTE SPECIALISE OPTION B : PRODUITS D'EQUIPEMENT COURANT (CAP)" },
  { value: "VENDEUR-MAGASINIER EN PIECES DE RECHANGE ET EQUIPEMENTS AUTOMOBILES (CAP)", label: "VENDEUR-MAGASINIER EN PIECES DE RECHANGE ET EQUIPEMENTS AUTOMOBILES (CAP)" },
  { value: "FLEURISTE (CAP)", label: "FLEURISTE (CAP)" },
  { value: "EMPLOYE DE VENTE SPECIALISE OPTION C : SERVICE A LA CLIENTELE (CAP)", label: "EMPLOYE DE VENTE SPECIALISE OPTION C : SERVICE A LA CLIENTELE (CAP)" },
  { value: "EMPLOYE DE VENTE SPECIALISE OPTION D : LIBRAIRIE-PAPETERIE-PRESSE (CAP)", label: "EMPLOYE DE VENTE SPECIALISE OPTION D : LIBRAIRIE-PAPETERIE-PRESSE (CAP)" },
  { value: "POISSONNIER ECAILLER (CAP)", label: "POISSONNIER ECAILLER (CAP)" },
  { value: "PRIMEUR (CAP)", label: "PRIMEUR (CAP)" },
  { value: "ARTS DE LA RELIURE (CAP)", label: "ARTS DE LA RELIURE (CAP)" },
  { value: "METIERS DE LA GRAVURE OPTION A : GRAVURE D'ORNEMENTATION (CAP)", label: "METIERS DE LA GRAVURE OPTION A : GRAVURE D'ORNEMENTATION (CAP)" },
  { value: "METIERS DE LA GRAVURE OPTION B : GRAVURE D'IMPRESSION (CAP)", label: "METIERS DE LA GRAVURE OPTION B : GRAVURE D'IMPRESSION (CAP)" },
  { value: "METIERS DE LA GRAVURE OPTION C : GRAVURE EN MODELE (CAP)", label: "METIERS DE LA GRAVURE OPTION C : GRAVURE EN MODELE (CAP)" },
  { value: "METIERS DE LA GRAVURE OPTION D : MARQUAGE POINCONNAGE (CAP)", label: "METIERS DE LA GRAVURE OPTION D : MARQUAGE POINCONNAGE (CAP)" },
  { value: "SERIGRAPHIE INDUSTRIELLE(CAP)", label: "SERIGRAPHIE INDUSTRIELLE(CAP)" },
  { value: "SIGNALETIQUE ET DECORS GRAPHIQUES (CAP)", label: "SIGNALETIQUE ET DECORS GRAPHIQUES (CAP)" },
  { value: "ACCESSOIRISTE REALISATEUR (CAP)", label: "ACCESSOIRISTE REALISATEUR (CAP)" },
  { value: "ACCORDEUR DE PIANO (CAP)", label: "ACCORDEUR DE PIANO (CAP)" },
  { value: "ASSISTANT TECHNIQUE EN INSTRUMENTS DE MUSIQUE OPTION INSTRUMENTS A VENTS (CAP)", label: "ASSISTANT TECHNIQUE EN INSTRUMENTS DE MUSIQUE OPTION INSTRUMENTS A VENTS (CAP)" },
  { value: "ASSISTANT TECHNIQUE EN INSTRUMENTS DE MUSIQUE OPTION PIANO (CAP)", label: "ASSISTANT TECHNIQUE EN INSTRUMENTS DE MUSIQUE OPTION PIANO (CAP)" },
  { value: "ASSISTANT TECHNIQUE EN INSTRUMENTS DE MUSIQUE OPTION GUITARE (CAP)", label: "ASSISTANT TECHNIQUE EN INSTRUMENTS DE MUSIQUE OPTION GUITARE (CAP)" },
  { value: "OPERATEUR PROJECTIONNISTE DE CINEMA (CAP)", label: "OPERATEUR PROJECTIONNISTE DE CINEMA (CAP)" },
  { value: "ORTHO-PROTHESISTE (CAP)", label: "ORTHO-PROTHESISTE (CAP)" },
  { value: "PODO-ORTHESISTE (CAP)", label: "PODO-ORTHESISTE (CAP)" },
  { value: "PETITE ENFANCE (CAP)", label: "PETITE ENFANCE (CAP)" },
  { value: "ACCOMPAGNANT EDUCATIF PETITE ENFANCE (CAP)", label: "ACCOMPAGNANT EDUCATIF PETITE ENFANCE (CAP)" },
  { value: "ASSISTANT(E) TECHNIQUE EN MILIEUX FAMILIAL ET COLLECTIF (CAP)", label: "ASSISTANT(E) TECHNIQUE EN MILIEUX FAMILIAL ET COLLECTIF (CAP)" },
  { value: "COMMERCIALISATION ET SERVICES EN HOTEL-CAFE-RESTAURANT (CAP)", label: "COMMERCIALISATION ET SERVICES EN HOTEL-CAFE-RESTAURANT (CAP)" },
  { value: "METIERS DU FOOTBALL (CAP)", label: "METIERS DU FOOTBALL (CAP)" },
  { value: "ESTHETIQUE COSMETIQUE PARFUMERIE (CAP)", label: "ESTHETIQUE COSMETIQUE PARFUMERIE (CAP)" },
  { value: "AGENT DE PREVENTION ET DE MEDIATION (CAP)", label: "AGENT DE PREVENTION ET DE MEDIATION (CAP)" },
  { value: "AGENT D'ASSAINISSEMENT ET DE COLLECTE DES DECHETS LIQUIDES SPECIAUX (CAP)", label: "AGENT D'ASSAINISSEMENT ET DE COLLECTE DES DECHETS LIQUIDES SPECIAUX (CAP)" },
  { value: "AGENT DE PROPRETE ET D'HYGIENE (CAP)", label: "AGENT DE PROPRETE ET D'HYGIENE (CAP)" },
  { value: "GARDIEN D'IMMEUBLE (CAP)", label: "GARDIEN D'IMMEUBLE (CAP)" },
  { value: "AGENT DE SECURITE (CAP)", label: "AGENT DE SECURITE (CAP)" },
  { value: "AGRICULTURES DES REGIONS CHAUDES (CAPA)", label: "AGRICULTURES DES REGIONS CHAUDES (CAPA)" },
  { value: "METIERS DE L'AGRICULTURE (CAPA)", label: "METIERS DE L'AGRICULTURE (CAPA)" },
  { value: "LAD CAVALIER D'ENTRAINEMENT (CAPA)", label: "LAD CAVALIER D'ENTRAINEMENT (CAPA)" },
  { value: "MARECHAL FERRANT (CAPA)", label: "MARECHAL FERRANT (CAPA)" },
  { value: "PALEFRENIER SOIGNEUR (CAPA)", label: "PALEFRENIER SOIGNEUR (CAPA)" },
  { value: "TRAVAUX FORESTIERS (CAPA)", label: "TRAVAUX FORESTIERS (CAPA)" },
  { value: "JARDINIER PAYSAGISTE (CAPA)", label: "JARDINIER PAYSAGISTE (CAPA)" },
  { value: "OPERATEUR EN INDUSTRIES AGROALIMENTAIRES SPE CONDUITE DE MACHINES (CAPA)", label: "OPERATEUR EN INDUSTRIES AGROALIMENTAIRES SPE CONDUITE DE MACHINES (CAPA)" },
  { value: "OPERATEUR EN INDUSTRIES AGROALIMENTAIRES SPE TRANSFORMATION DE PRODUITS ALIMENTAIRES (CAPA)", label: "OPERATEUR EN INDUSTRIES AGROALIMENTAIRES SPE TRANSFORMATION DE PRODUITS ALIMENTAIRES (CAPA)" },
  { value: "SERVICES AUX PERSONNES ET VENTE EN ESPACE RURAL (CAPA)", label: "SERVICES AUX PERSONNES ET VENTE EN ESPACE RURAL (CAPA)" },
  { value: "TRAVAUX PAYSAGERS (BEPA)", label: "TRAVAUX PAYSAGERS (BEPA)" },
  { value: "TRAVAUX DE LA CONDUITE ET ENTRETIEN DES ENGINS AGRICOLES (BPA5)", label: "TRAVAUX DE LA CONDUITE ET ENTRETIEN DES ENGINS AGRICOLES (BPA5)" },
  { value: "TRAVAUX DE LA VIGNE ET DU VIN TRAVAUX DE LA CAVE (BPA5)", label: "TRAVAUX DE LA VIGNE ET DU VIN TRAVAUX DE LA CAVE (BPA5)" },
  { value: "TRAVAUX DE LA VIGNE ET DU VIN TRAVAUX DE LA VIGNE (BPA5)", label: "TRAVAUX DE LA VIGNE ET DU VIN TRAVAUX DE LA VIGNE (BPA5)" },
  { value: "TRAVAUX DE PRODUCTIONS HORTICOLES SPECIALITE ARBORICULTURE FRUITIERE (BPA5)", label: "TRAVAUX DE PRODUCTIONS HORTICOLES SPECIALITE ARBORICULTURE FRUITIERE (BPA5)" },
  { value: "TRAVAUX DE PRODUCTIONS HORTICOLES SPECIALITE HORTICULTURE ORNEMENTALE LEGUMIERE (BPA5)", label: "TRAVAUX DE PRODUCTIONS HORTICOLES SPECIALITE HORTICULTURE ORNEMENTALE LEGUMIERE (BPA5)" },
  { value: "TRAVAUX DE LA PRODUCTION ANIMALE / ELEVAGE DE PORC OU DE VOLAILLE (BPA5)", label: "TRAVAUX DE LA PRODUCTION ANIMALE / ELEVAGE DE PORC OU DE VOLAILLE (BPA5)" },
  { value: "TRAVAUX DE LA PRODUCTION ANIMALE / ELEVAGE DE RUMINANTS (BPA5)", label: "TRAVAUX DE LA PRODUCTION ANIMALE / ELEVAGE DE RUMINANTS (BPA5)" },
  { value: "TRAVAUX DE LA PRODUCTION ANIMALE / POLYCULTURE ELEVAGE (BPA5)", label: "TRAVAUX DE LA PRODUCTION ANIMALE / POLYCULTURE ELEVAGE (BPA5)" },
  { value: "TRAVAUX DE L'ELEVAGE CANIN ET FELIN (BPA5)", label: "TRAVAUX DE L'ELEVAGE CANIN ET FELIN (BPA5)" },
  { value: "TRAVAUX FORESTIERS CONDUITE DES MACHINES FORESTIERES (BPA5)", label: "TRAVAUX FORESTIERS CONDUITE DES MACHINES FORESTIERES (BPA5)" },
  { value: "TRAVAUX FORESTIERS TRAVAUX DE BUCHERONNAGE (BPA5)", label: "TRAVAUX FORESTIERS TRAVAUX DE BUCHERONNAGE (BPA5)" },
  { value: "TRAVAUX FORESTIERS TRAVAUX DE SYLVICULTURE (BPA5)", label: "TRAVAUX FORESTIERS TRAVAUX DE SYLVICULTURE (BPA5)" },
  { value: "TRAVAUX D'AMENAGEMENTS PAYSAGERS SPECIALITE TRAVAUX DE CREATION ET D'ENTRETIEN (BPA5)", label: "TRAVAUX D'AMENAGEMENTS PAYSAGERS SPECIALITE TRAVAUX DE CREATION ET D'ENTRETIEN (BPA5)" },
  { value: "TRANSFORMATION ALIMENTAIRES TRANSFORMATION DES VIANDES (BPA5)", label: "TRANSFORMATIONS ALIMENTAIRES TRANSFORMATION DES VIANDES (BPA5)" },
  { value: "TRANSFORMATIONS ALIMENTAIRES TRANSFORMATION DES PRODUITS ALIMENTAIRES (BPA5)", label: "TRANSFORMATIONS ALIMENTAIRES TRANSFORMATION DES PRODUITS ALIMENTAIRES (BPA5)" },
  { value: "AIDE-SOIGNANT (DIPLÔME D'ETAT)", label: "AIDE-SOIGNANT (DIPLÔME D'ETAT)" },
  { value: "AUXILIAIRE DE PUERICULTURE (DIPLOME D'ETAT)", label: "AUXILIAIRE DE PUERICULTURE (DIPLOME D'ETAT)" },
  { value: "AMBULANCIER (DIPLOME D'ETAT)", label: "AMBULANCIER (DIPLOME D'ETAT)" },
  { value: "ACCOMPAGNANT EDUCATIF ET SOCIAL SPECIALITE ACCOMPAGNEMENT DE LA VIE A DOMICILE (DIPLOME D'ETAT)", label: "ACCOMPAGNANT EDUCATIF ET SOCIAL SPECIALITE ACCOMPAGNEMENT DE LA VIE A DOMICILE (DIPLOME D'ETAT)" },
  { value: "ACCOMPAGNANT EDUCATIF ET SOCIAL SPECIALITE ACCOMPAGNEMENT DE LA VIE EN STRUCTURE COLLECTIVE (DIPLOME D'ETAT)", label: "ACCOMPAGNANT EDUCATIF ET SOCIAL SPECIALITE ACCOMPAGNEMENT DE LA VIE EN STRUCTURE COLLECTIVE (DIPLOME D'ETAT)" },
  { value: "ACCOMPAGNANT EDUCATIF ET SOCIAL SPECIALITE ACCOMPAGNEMENT A L'EDUCATION INCLUSIVE ET A LA VIE ORDINAIRE (DIPLOME D'ETAT)", label: "ACCOMPAGNANT EDUCATIF ET SOCIAL SPECIALITE ACCOMPAGNEMENT A L'EDUCATION INCLUSIVE ET A LA VIE ORDINAIRE (DIPLOME D'ETAT)" },
  { value: "TRACTEURS ET MACHINES AGRICOLES - UTILISATION ET MAINTENANCE (CSA)", label: "TRACTEURS ET MACHINES AGRICOLES - UTILISATION ET MAINTENANCE (CSA)" },
  { value: "TAILLE ET SOINS DES ARBRES (CSA)", label: "TAILLE ET SOINS DES ARBRES (CSA)" },
  { value: "JARDINIER DE GOLF ET ENTRETIEN DE SOLS SPORTIFS (CSA)", label: "JARDINIER DE GOLF ET ENTRETIEN DE SOLS SPORTIFS (CSA)" },
  { value: "RESTAURATION COLLECTIVE (CSA)", label: "RESTAURATION COLLECTIVE (CSA)" },
  { value: "CONDUCTEUR D'ENGINS DE TRAVAUX PUBLICS (CFPPA DE LA BAROTTE)", label: "CONDUCTEUR D'ENGINS DE TRAVAUX PUBLICS (CFPPA DE LA BAROTTE)" },
  { value: "PATISSERIE, GLACERIE, CHOCOLATERIE, CONFISERIE SPECIALISEES (MC NIVEAU V)", label: "PATISSERIE, GLACERIE, CHOCOLATERIE, CONFISERIE SPECIALISEES (MC NIVEAU V)" },
  { value: "DROIT DES AFFAIRES (MASTER BESANCON)", label: "DROIT DES AFFAIRES (MASTER BESANCON)" },
  { value: "DROIT DES COLLECTIVITES TERRITORIALES (MASTER CORSE)", label: "DROIT DES COLLECTIVITES TERRITORIALES (MASTER CORSE)" },
  { value: "DROIT DES AFFAIRES (MASTER TOULOUSE 1)", label: "DROIT DES AFFAIRES (MASTER TOULOUSE 1)" },
  { value: "DROIT DE L'ENTREPRISE (MASTER TOURS)", label: "DROIT DE L'ENTREPRISE (MASTER TOURS)" },
  { value: "DROIT DE L'ENVIRONNEMENT ET DE L'URBANISME (MASTER TOURS)", label: "DROIT DE L'ENVIRONNEMENT ET DE L'URBANISME (MASTER TOURS)" },
  { value: "DROIT PUBLIC (MASTER TOURS)", label: "DROIT PUBLIC (MASTER TOURS)" },
  { value: "DROIT PUBLIC (MASTER ORLEANS)", label: "DROIT PUBLIC (MASTER ORLEANS)" },
  { value: "JUSTICE, PROCES ET PROCEDURES (MASTER TOURS)", label: "JUSTICE, PROCES ET PROCEDURES (MASTER TOURS)" },
  { value: "DROIT (MASTER MULHOUSE)", label: "DROIT (MASTER MULHOUSE)" },
  { value: "DROIT DES AFFAIRES (MASTER STRASBOURG)", label: "DROIT DES AFFAIRES (MASTER STRASBOURG)" },
  { value: "DROIT DES AFFAIRES (MASTER AIX MARSEILLE)", label: "DROIT DES AFFAIRES (MASTER AIX MARSEILLE)" },
  { value: "DROIT PRIVE (MASTER COMUE COTE D'AZUR)", label: "DROIT PRIVE (MASTER COMUE COTE D'AZUR)" },
  { value: "DROIT DU NUMERIQUE (MASTER PARIS EST CRETEIL)", label: "DROIT DU NUMERIQUE (MASTER PARIS EST CRETEIL)" },
  { value: "DROIT PUBLIC (MASTER LA REUNION)", label: "DROIT PUBLIC (MASTER LA REUNION)" },
  { value: "DROIT DES AFFAIRES (MASTER LORRAINE)", label: "DROIT DES AFFAIRES (MASTER LORRAINE)" },
  { value: "DROIT PUBLIC (MASTER BORDEAUX)", label: "DROIT PUBLIC (MASTER BORDEAUX)" },
  { value: "DROIT PUBLIC (MASTER PAU)", label: "DROIT PUBLIC (MASTER PAU)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT STRATEGIQUE (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT STRATEGIQUE (MASTER MONTPELLIER)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT DE L'INNOVATION (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT DE L'INNOVATION (MASTER MONTPELLIER)" },
  { value: "MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER NANTES)", label: "MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER NANTES)" },
  { value: "MANAGEMENT DE L'INNOVATION (MASTER NANTES)", label: "MANAGEMENT DE L'INNOVATION (MASTER NANTES)" },
  { value: "DROIT, ECONOMIE, GESTION : COMPTABILITE - CONTROLE - AUDIT (MASTER VALENCIENNES)", label: "DROIT, ECONOMIE, GESTION : COMPTABILITE - CONTROLE - AUDIT (MASTER VALENCIENNES)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT ET COMMERCE INTERNATIONAL (MASTER PAU)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT ET COMMERCE INTERNATIONAL (MASTER PAU)" },
  { value: "ADMINISTRATION PUBLIQUE (MASTER POITIERS)", label: "ADMINISTRATION PUBLIQUE (MASTER POITIERS)" },
  { value: "SCIENCES DES ORGANISATIONS (DROIT, ECONOMIE, GESTION, SCIENCES SOCIALES) : INNOVATION, RESEAUX ET NUMERIQUE (MASTER IRN DAUPHINE)", label: "SCIENCES DES ORGANISATIONS (DROIT, ECONOMIE, GESTION, SCIENCES SOCIALES) : INNOVATION, RESEAUX ET NUMERIQUE (MASTER IRN DAUPHINE)" },
  { value: "QUALITE, HYGIENE, SECURITE (MASTER STRASBOURG)", label: "QUALITE, HYGIENE, SECURITE (MASTER STRASBOURG)" },
  { value: "DROIT, ECONOMIE, GESTION : DROIT, ECONOMIE, GESTION : CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER EVRY)", label: "DROIT, ECONOMIE, GESTION : DROIT, ECONOMIE, GESTION : CONTROLE DE GESTION ET AUDIT ORGANISATIONNEL (MASTER EVRY)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT STRATEGIQUE (MASTER LILLE 1)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT STRATEGIQUE (MASTER LILLE 1)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER MONTPELLIER)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT PUBLIC (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT PUBLIC (MASTER MONTPELLIER)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER CERGY)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER CERGY)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER PARIS SACLAY)", label: "DROIT, ECONOMIE, GESTION : GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER PARIS SACLAY)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER PARIS 12)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER PARIS 12)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER VERSAILLES)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER VERSAILLES)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER VERSAILLES)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER VERSAILLES)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT SECTORIEL (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT SECTORIEL (MASTER MONTPELLIER)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT SECTORIEL (MASTER LILLE 2)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT SECTORIEL (MASTER LILLE 2)" },
  { value: "DROIT, ECONOMIE, GESTION : ENTREPRENEURIAT ET MANAGEMENT DE PROJET (CNAM)", label: "DROIT, ECONOMIE, GESTION : ENTREPRENEURIAT ET MANAGEMENT DE PROJET (CNAM)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER BORDEAUX)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER BORDEAUX)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER MARNE LA VALLEE)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER MARNE LA VALLEE)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT SECTORIEL (MASTER PAU)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT SECTORIEL (MASTER PAU)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER TOULOUSE 1)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER TOULOUSE 1)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT STRATEGIQUE (MASTER CLERMONT AUVERGNE)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT STRATEGIQUE (MASTER CLERMONT AUVERGNE)" },
  { value: "COMMUNICATION DES ORGANISATIONS (MASTER POITIERS)", label: "COMMUNICATION DES ORGANISATIONS (MASTER POITIERS)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT STRATEGIQUE (MASTER PARIS 1)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT STRATEGIQUE (MASTER PARIS 1)" },
  { value: "DROIT ECONOMIE GESTION : MANAGEMENT (MASTER SAINT ETIENNE)", label: "DROIT ECONOMIE GESTION : MANAGEMENT (MASTER SAINT ETIENNE)" },
  { value: "DIRECTION DE PROJETS OU ETABLISSEMENTS CULTURELS (MASTER AIX MARSEILLE)", label: "DIRECTION DE PROJETS OU ETABLISSEMENTS CULTURELS (MASTER AIX MARSEILLE)" },
  { value: "MANAGEMENT ET COMMERCE INTERNATIONAL (MASTER LYON 3)", label: "MANAGEMENT ET COMMERCE INTERNATIONAL (MASTER LYON 3)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER CHAMBERY)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT (MASTER CHAMBERY)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER GRENOBLE ALPES)", label: "DROIT, ECONOMIE, GESTION : GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER GRENOBLE ALPES)" },
  { value: "DROIT, ECONOMIE, GESTION : ENTREPRENEURIAT ET MANAGEMENT DE PROJETS (MASTER MONTPELLIER)", label: "DROIT, ECONOMIE, GESTION : ENTREPRENEURIAT ET MANAGEMENT DE PROJETS (MASTER MONTPELLIER)" },
  { value: "DROIT, ECONOMIE, GESTION : GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER CLERMONT AUVERGNE)", label: "DROIT, ECONOMIE, GESTION : GESTION DE PRODUCTION, LOGISTIQUE, ACHATS (MASTER CLERMONT AUVERGNE)" },
  { value: "DROIT, ECONOMIE, GESTION : ENTREPRENEURIAT ET MANAGEMENT DE PROJETS (MASTER PARIS EST CRETEIL)", label: "DROIT, ECONOMIE, GESTION : ENTREPRENEURIAT ET MANAGEMENT DE PROJETS (MASTER PARIS EST CRETEIL)" },
  { value: "MANAGEMENT DE L'INNOVATION (MASTER LILLE 1)", label: "MANAGEMENT DE L'INNOVATION (MASTER LILLE 1)" },
  { value: "DROIT, ECONOMIE, GESTION : MANAGEMENT SPE ENTREPRENEURIAT (MASTER PARIS 5)", label: "DROIT, ECONOMIE, GESTION : MANAGEMENT SPE ENTREPRENEURIAT (MASTER PARIS 5)" },
  { value: "MANAGEMENT (MASTER CORSE)", label: "MANAGEMENT (MASTER CORSE)" },
  { value: "MANAGEMENT ET COMMERCE INTERNATIONAL (MASTER COMUE COTE D'AZUR)", label: "MANAGEMENT ET COMMERCE INTERNATIONAL (MASTER COMUE COTE D'AZUR)" },
  { value: "ADMINISTRATION ET LIQUIDATION D'ENTREPRISES EN DIFFICULTE (MASTER COMUE COTE D'AZUR)", label: "ADMINISTRATION ET LIQUIDATION D'ENTREPRISES EN DIFFICULTE (MASTER COMUE COTE D'AZUR)" },
  { value: "INNOVATION, ENTREPRISE ET SOCIETE (MASTER COMUE COTE D'AZUR)", label: "INNOVATION, ENTREPRISE ET SOCIETE (MASTER COMUE COTE D'AZUR)" },
  { value: "MANAGEMENT (MASTER COMUE COTE D'AZUR)", label: "MANAGEMENT (MASTER COMUE COTE D'AZUR)" },
  { value: "ENTREPRENEURIAT ET MANAGEMENT DE PROJETS (MASTER AMIENS)", label: "ENTREPRENEURIAT ET MANAGEMENT DE PROJETS (MASTER AMIENS)" },
  { value: "MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER TOURS)", label: "MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER TOURS)" },
  { value: "DROIT, ECONOMIE, GESTION : INNOVATION, ENTREPRISE ET SOCIETE (MASTER CNAM)", label: "DROIT, ECONOMIE, GESTION : INNOVATION, ENTREPRISE ET SOCIETE (MASTER CNAM)" },
  { value: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES SPE MANAGEMENT DE PROJETS INTERNATIONAUX EN MILIEU ANGLOPHONE (MASTER PARIS 3)", label: "ARTS, LETTRES, LANGUES : LANGUES, LITTERATURES ET CIVILISATIONS ETRANGERES ET REGIONALES SPE MANAGEMENT DE PROJETS INTERNATIONAUX EN MILIEU ANGLOPHONE (MASTER PARIS 3)" },
  { value: "MANAGEMENT DE L'INNOVATION (PARIS 1)", label: "MANAGEMENT DE L'INNOVATION (PARIS 1)" },
  { value: "MANAGEMENT (MASTER STRASBOURG)", label: "MANAGEMENT (MASTER STRASBOURG)" },
  { value: "ADMINISTRATION ECONOMIQUE ET SOCIALE (MASTER STRASBOURG)", label: "ADMINISTRATION ECONOMIQUE ET SOCIALE (MASTER STRASBOURG)" },
  { value: "DROIT, ECONOMIE, GESTION : ENTREPRENEURIAT ET MANAGEMENT DE PROJETS (MASTER MULHOUSE)", label: "DROIT, ECONOMIE, GESTION : ENTREPRENEURIAT ET MANAGEMENT DE PROJETS (MASTER MULHOUSE)" },
  { value: "MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER STRASBOURG)", label: "MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER STRASBOURG)" },
  { value: "MANAGEMENT SECTORIEL (MASTER LIMOGES)", label: "MANAGEMENT SECTORIEL (MASTER LIMOGES)" },
  { value: "MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER PAU)", label: "MANAGEMENT ET ADMINISTRATION DES ENTREPRISES (MASTER PAU)" },
  { value: "INFORMATIQUE (MASTER PARIS 8)", label: "INFORMATIQUE (MASTER PARIS 8)" },
  { value: "INFORMATIQUE (GRENOBLE IP)", label: "INFORMATIQUE (GRENOBLE IP)" },
  { value: "MANAGEMENT DES SYSTEMES D'INFORMATION (MASTER LA ROCHELLE)", label: "MANAGEMENT DES SYSTEMES D'INFORMATION (MASTER LA ROCHELLE)" },
  { value: "HYGIENISTE DU TRAVAIL ET DE L'ENVIRONNEMENT (CNAM)", label: "HYGIENISTE DU TRAVAIL ET DE L'ENVIRONNEMENT (CNAM)" },
  { value: "EXPERT EN CREATION ET INGENIERIE DE PRODUITS ALIMENTAIRES (MS AGRO PARIS TECH, CCIP ILE DE FRANCE, ECOLE GREGOIRE-FERRANDI)", label: "EXPERT EN CREATION ET INGENIERIE DE PRODUITS ALIMENTAIRES (MS AGRO PARIS TECH, CCIP ILE DE FRANCE, ECOLE GREGOIRE-FERRANDI)" },
  { value: "MANAGER EN INGENIERIE DE LA QUALITE ET DU DEVELOPPEMENT DURABLE (ECOLE HUBERT CURIEN)", label: "MANAGER EN INGENIERIE DE LA QUALITE ET DU DEVELOPPEMENT DURABLE (ECOLE HUBERT CURIEN)" },
  { value: "MANAGER DU DEVELOPPEMENT D'AFFAIRES EN AGROBUSINESS (TECOMAH CCI PARIS IDF)", label: "MANAGER DU DEVELOPPEMENT D'AFFAIRES EN AGROBUSINESS (TECOMAH CCI PARIS IDF)" },
  { value: "RESPONSABLE D'AFFAIRES AGROALIMENTAIRES (ISEMA)", label: "RESPONSABLE D'AFFAIRES AGROALIMENTAIRES (ISEMA)" },
  { value: "MANAGER - DEVELOPPEUR(EUSE) PRODUIT DE LA MODE ET DE L'HABILLEMENT (CCIP ESIV)", label: "MANAGER - DEVELOPPEUR(EUSE) PRODUIT DE LA MODE ET DE L'HABILLEMENT (CCIP ESIV)" },
  { value: "MANAGER DE SYSTEME QUALITE SECURITE ENVIRONNEMENT QSE (KEDGE BUSINESS SCHOOL)", label: "MANAGER DE SYSTEME QUALITE SECURITE ENVIRONNEMENT QSE (KEDGE BUSINESS SCHOOL)" },
  { value: "MANAGER DES ACHATS INTERNATIONAUX (MS ASSOC GROUPE ESSEC)", label: "MANAGER DES ACHATS INTERNATIONAUX (MS ASSOC GROUPE ESSEC)" },
  { value: "MANAGER DES AFFAIRES JURIDIQUES ET FINANCIERES INTERNATIONALES (MS ASSOC GROUPE ESSEC)", label: "MANAGER DES AFFAIRES JURIDIQUES ET FINANCIERES INTERNATIONALES (MS ASSOC GROUPE ESSEC)" },
  { value: "MANAGER TRANSPORT, LOGISTIQUE ET COMMERCE INTERNATIONAL (KEDGE BUSINESS SCHOOL, AFTRAL)", label: "MANAGER TRANSPORT, LOGISTIQUE ET COMMERCE INTERNATIONAL (KEDGE BUSINESS SCHOOL, AFTRAL)" },
  { value: "MANAGER ACHATS ET SUPPLY CHAIN (GROUPE SUP DE CO LA ROCHELLE, CCI PORTES DE NORMANDIE)", label: "MANAGER ACHATS ET SUPPLY CHAIN (GROUPE SUP DE CO LA ROCHELLE, CCI PORTES DE NORMANDIE)" },
  { value: "MANAGER DU DEVELOPPEMENT D'AFFAIRES A L'INTERNATIONAL (CCI FRANCE RESEAU NEGOVENTIS)", label: "MANAGER DU DEVELOPPEMENT D'AFFAIRES A L'INTERNATIONAL (CCI FRANCE RESEAU NEGOVENTIS)" },
  { value: "MANAGER EN INGENIERIE D'AFFAIRES (SUP DE V CCIV)", label: "MANAGER EN INGENIERIE D'AFFAIRES (SUP DE V CCIV)" },
  { value: "MANAGER MARKETING ET COMMERCIAL INTERNATIONAL (MS) (GROUPE ESSEC)", label: "MANAGER MARKETING ET COMMERCIAL INTERNATIONAL (MS) (GROUPE ESSEC)" },
  { value: "RESPONSABLE EN GESTION DES RESSOURCES HUMAINES (ISFOGEP LIMOGES)", label: "RESPONSABLE EN GESTION DES RESSOURCES HUMAINES (ISFOGEP LIMOGES)" },
  { value: "MANAGER EN INGENIERIE DE LA COMMUNICATION NUMERIQUE INTERACTIVE (CCIP ECOLE DE L'IMAGE GOBELINS)", label: "MANAGER EN INGENIERIE DE LA COMMUNICATION NUMERIQUE INTERACTIVE (CCIP ECOLE DE L'IMAGE GOBELINS)" },
  { value: "MANAGER MARKETING DIGITAL ET EVENEMENTIEL (CCIP IDF - NOVENCIA)", label: "MANAGER MARKETING DIGITAL ET EVENEMENTIEL (CCIP IDF - NOVENCIA)" },
  { value: "MANAGER EN INGENIERIE INFORMATIQUE (CCI PARIS ILE DE FRANCE - ITESCIA)", label: "MANAGER EN INGENIERIE INFORMATIQUE (CCI PARIS ILE DE FRANCE - ITESCIA)" },
  { value: "INGENIEUR D'AFFAIRES EN HAUTES TECHNOLOGIES (EURIDIS MANAGEMENT)", label: "INGENIEUR D'AFFAIRES EN HAUTES TECHNOLOGIES (EURIDIS MANAGEMENT)" },
  { value: "MANAGER DE SYSTEMES D'INFORMATION ET D'INFRASTRUCTURE (CCI 3IL)", label: "MANAGER DE SYSTEMES D'INFORMATION ET D'INFRASTRUCTURE (CCI 3IL)" },
  { value: "MANAGER EN HOTELERIE INTERNATIONALE (GROUPE ESSEC)", label: "MANAGER EN HOTELERIE INTERNATIONALE (GROUPE ESSEC)" },
  { value: "MANAGER DES RISQUES (CCI DE L'ALLIER - IEQT)", label: "MANAGER DES RISQUES (CCI DE L'ALLIER - IEQT)" },
  { value: "MANAGER EN INGENIERIE ET GOUVERNANCE DES RISQUES (ECOLE HUBERT CURIEN)", label: "MANAGER EN INGENIERIE ET GOUVERNANCE DES RISQUES (ECOLE HUBERT CURIEN)" },
  { value: "DIPLOME DE PSYCHOLOGUE DE L'ECOLE DES PSYCHOLOGUES PRATICIENS (EPP)", label: "DIPLOME DE PSYCHOLOGUE DE L'ECOLE DES PSYCHOLOGUES PRATICIENS (EPP)" },
  { value: "MANAGER DU DEVELOPPEMENT DES RESSOURCES HUMAINES (SUP DES RH)", label: "MANAGER DU DEVELOPPEMENT DES RESSOURCES HUMAINES (SUP DES RH)" },
  { value: "RESPONSABLE EN MANAGEMENT ET DIRECTION DES RESSOURCES HUMAINES (IGS)", label: "RESPONSABLE EN MANAGEMENT ET DIRECTION DES RESSOURCES HUMAINES (IGS)" },
  { value: "CONSULTANT EN INTELLIGENCE ECONOMIQUE (EEIE)", label: "CONSULTANT EN INTELLIGENCE ECONOMIQUE (EEIE)" },
  { value: "EXPERT EN INGENIERIE INFORMATIQUE (EPITA)", label: "EXPERT EN INGENIERIE INFORMATIQUE (EPITA)" },
  { value: "EXPERT EN INGENIERIE INFORMATIQUE ET DES SYSTEMES D'INFORMATION (ANAPIJ)", label: "EXPERT EN INGENIERIE INFORMATIQUE ET DES SYSTEMES D'INFORMATION (ANAPIJ)" },
  { value: "EXPERT(E) EN INGENIERIE INFORMATIQUE ET DES SYSTEMES D'INFORMATION (GROUPE ESIA-INTECH INFO)", label: "EXPERT(E) EN INGENIERIE INFORMATIQUE ET DES SYSTEMES D'INFORMATION (GROUPE ESIA-INTECH INFO)" },
  { value: "EXPERT EN INGENIERIE DU LOGICIEL (GROUPE ESIEA INTECH INFO)", label: "EXPERT EN INGENIERIE DU LOGICIEL (GROUPE ESIEA INTECH INFO)" },
  { value: "ARCHITECTE TECHNIQUE EN INFORMATIQUE ET RESEAUX (ENSA-2I-INSTA)", label: "ARCHITECTE TECHNIQUE EN INFORMATIQUE ET RESEAUX (ENSA-2I-INSTA)" },
  { value: "MANAGER EN SYSTEMES D'INFORMATION (CESI)", label: "MANAGER EN SYSTEMES D'INFORMATION (CESI)" },
  { value: "MANAGER EN SYSTEMES D'INFORMATION (ADIP IPI)", label: "MANAGER EN SYSTEMES D'INFORMATION (ADIP IPI)" },
  { value: "MANAGER DES RISQUES (RISK MANAGER EISTI)", label: "MANAGER DES RISQUES (RISK MANAGER EISTI)" },
  { value: "MANAGER DES OPERATIONS LOGISTIQUES INTERNATIONALES (PROMOTRANS)", label: "MANAGER DES OPERATIONS LOGISTIQUES INTERNATIONALES (PROMOTRANS)" },
  { value: "MANAGER DE LA STRATEGIE COMMERCIALE ET MARKETING (ALPF-IDRAC)", label: "MANAGER DE LA STRATEGIE COMMERCIALE ET MARKETING (ALPF-IDRAC)" },
  { value: "MANAGER DE LA COMMUNICATION NUMERIQUE (ASSOC L. DE VINCI IIM)", label: "MANAGER DE LA COMMUNICATION NUMERIQUE (ASSOC L. DE VINCI IIM)" },
  { value: "MANAGER DE SYSTEMES INFORMATIQUE ET ROBOTIQUE (IMERIR)", label: "MANAGER DE SYSTEMES INFORMATIQUE ET ROBOTIQUE (IMERIR)" },
  { value: "MANAGER DES PROCESS DE CREATION ET DE DEVELOPPEMENT DES PRODUITS PARFUM, COSMETIQUE ET AROMES (CCIP, ISIPCA)", label: "MANAGER DES PROCESS DE CREATION ET DE DEVELOPPEMENT DES PRODUITS PARFUM, COSMETIQUE ET AROMES (CCIP, ISIPCA)" },
  { value: "CHEF DE PROJET SYSTEMES EMBARQUES (ESTEI)", label: "CHEF DE PROJET SYSTEMES EMBARQUES (ESTEI)" },
  { value: "MANAGER D'ENTREPRISE OU DE CENTRE DE PROFIT (IFAG)", label: "MANAGER D'ENTREPRISE OU DE CENTRE DE PROFIT (IFAG)" },
  { value: "MANAGER DE PROJETS INTERNATIONAUX (APTIM)", label: "MANAGER DE PROJETS INTERNATIONAUX (APTIM)" },
  { value: "MANAGER D'AFFAIRES (LPO GUSTAVE FLAUBERT - CFA ISD FLAUBERT)", label: "MANAGER D'AFFAIRES (LPO GUSTAVE FLAUBERT - CFA ISD FLAUBERT)" },
  { value: "MANAGER D'UNITE OPERATIONNELLE (ASSOC POUR LE COLLEGE DE PARIS - ISE)", label: "MANAGER D'UNITE OPERATIONNELLE (ASSOC POUR LE COLLEGE DE PARIS - ISE)" },
  { value: "MANAGER CULTUREL (EAC)", label: "MANAGER CULTUREL (EAC)" },
  { value: "ENTREPRENEUR-DIRIGEANT (IGS - ESAM)", label: "ENTREPRENEUR-DIRIGEANT (IGS - ESAM)" },
  { value: "DIRIGEANT(E) MANAGER OPERATIONNEL D'ENTREPRISE (C3 INSTITUTE)", label: "DIRIGEANT(E) MANAGER OPERATIONNEL D'ENTREPRISE (C3 INSTITUTE)" },
  { value: "MANAGER D'ENTREPRISE (EMD)", label: "MANAGER D'ENTREPRISE (EMD)" },
  { value: "MANAGER EN STRATEGIE ET DEVELOPPEMENT (ISEE-Y-NOV)", label: "MANAGER EN STRATEGIE ET DEVELOPPEMENT (ISEE-Y-NOV)" },
  { value: "MANAGER DE PROGET (ECEMA)", label: "MANAGER DE PROGET (ECEMA)" },
  { value: "MANAGER DES ORGANISATIONS (ECEMA LYON)", label: "MANAGER DES ORGANISATIONS (ECEMA LYON)" },
  { value: "MANAGER EN INGENIERIE DE LA QUALITE ET DE LA PERFORMANCE DES ENTREPRISES (CCI PARIS ILE DE FRANCE, ITESCIA)", label: "MANAGER EN INGENIERIE DE LA QUALITE ET DE LA PERFORMANCE DES ENTREPRISES (CCI PARIS ILE DE FRANCE, ITESCIA)" },
  { value: "MANAGER LOGISTIQUE ACHATS INDUSTRIELS (GIP ESPRIT)", label: "MANAGER LOGISTIQUE ACHATS INDUSTRIELS (GIP ESPRIT)" },
  { value: "DIRECTEUR DE PROJET EN INGENIERIE, LOGISTIQUE ET INNOVATION ORGANISATIONNELLE (MS CCI BAYONNE PAYS BASQUES - ESTIA) )", label: "DIRECTEUR DE PROJET EN INGENIERIE, LOGISTIQUE ET INNOVATION ORGANISATIONNELLE (MS CCI BAYONNE PAYS BASQUES - ESTIA) )" },
  { value: "NEGOCIATEUR D'AFFAIRES INTERNATIONALES (GESCEP ESCI)", label: "NEGOCIATEUR D'AFFAIRES INTERNATIONALES (GESCEP ESCI)" },
  { value: "MANAGER DES ACHATS (INSTITUT LEONARD DE VINCI)", label: "MANAGER DES ACHATS (INSTITUT LEONARD DE VINCI)" },
  { value: "MANAGER DU MARKETING DIGITAL (INSTITUT LEONARD DE VINCI)", label: "MANAGER DU MARKETING DIGITAL (INSTITUT LEONARD DE VINCI)" },
  { value: "MANAGER MARKETING ET COMMERCIAL (INSEEC BORDEAUX)", label: "MANAGER MARKETING ET COMMERCIAL (INSEEC BORDEAUX)" },
  { value: "MANAGER DES ACHATS INTERNATIONAUX (CESCI)", label: "MANAGER DES ACHATS INTERNATIONAUX (CESCI)" },
  { value: "MANAGER DE LA STRATEGIE ET DE LA PERFORMANCE COMMERCIALE (ESA3)", label: "MANAGER DE LA STRATEGIE ET DE LA PERFORMANCE COMMERCIALE (ESA3)" },
  { value: "MANAGER DE L'ASSURANCE (ESA)", label: "MANAGER DE L'ASSURANCE (ESA)" },
  { value: "EXPERT FINANCIER (IGS - ESAM)", label: "EXPERT FINANCIER (IGS - ESAM)" },
  { value: "AUDITEUR(TRICE) EN CONSEIL IMMOBILIER (ESPI)", label: "AUDITEUR(TRICE) EN CONSEIL IMMOBILIER (ESPI)" },
  { value: "PROPERTY MANAGER (MANAGER DES ACTIONS IMMOBILIERES GROUPE ESPI)", label: "PROPERTY MANAGER (MANAGER DES ACTIONS IMMOBILIERES GROUPE ESPI)" },
  { value: "MANAGER EN IMMOBILIER RESIDENTIEL ET TERTIAIRE (ASSOC INSTITUT DE GESTION SOCIALE - ESAM)", label: "MANAGER EN IMMOBILIER RESIDENTIEL ET TERTIAIRE (ASSOC INSTITUT DE GESTION SOCIALE - ESAM)" },
  { value: "MANAGER DE L'ORGANISATION DES RESSOURCES HUMAINES ET DES RELATIONS SOCIALES (ISGP-FACLIP)", label: "MANAGER DE L'ORGANISATION DES RESSOURCES HUMAINES ET DES RELATIONS SOCIALES (ISGP-FACLIP)" },
  { value: "MANAGER DU DEVELOPPEMENT DES RESSOURCES HUMAINES (CESI)", label: "MANAGER DU DEVELOPPEMENT DES RESSOURCES HUMAINES (CESI)" },
  { value: "MANAGER DE POLITIQUES ET STRATEGIES DE RESSOURCES HUMAINES (ICP)", label: "MANAGER DE POLITIQUES ET STRATEGIES DE RESSOURCES HUMAINES (ICP)" },
  { value: "MANAGER DES RESSOURCES HUMAINES (CRESPA-IPAC)", label: "MANAGER DES RESSOURCES HUMAINES (CRESPA-IPAC)" },
  { value: "RESPONSABLE DES RESSOURCES HUMAINES (ARTEMYS - IMC)", label: "RESPONSABLE DES RESSOURCES HUMAINES (ARTEMYS - IMC)" },
  { value: "MANAGER EN RESSOURCES HUMAINES (ASCENCIA BUSINESS SCHOOL - ASSOCIATION POUR LE COLLEGE DE PARIS)", label: "MANAGER EN RESSOURCES HUMAINES (ASCENCIA BUSINESS SCHOOL - ASSOCIATION POUR LE COLLEGE DE PARIS)" },
  { value: "MANAGER DE PROJET DE COMMUNICATION (ASSOC ST ANNE - IRCOM)", label: "MANAGER DE PROJET DE COMMUNICATION (ASSOC ST ANNE - IRCOM)" },
  { value: "ANALYSTE EN INTELLIGENCE STRATEGIQUE ET ECONOMIQUE (EISTI)", label: "ANALYSTE EN INTELLIGENCE STRATEGIQUE ET ECONOMIQUE (EISTI)" },
  { value: "MANAGER DE L'INFORMATION NUMERIQUE (EBD)", label: "MANAGER DE L'INFORMATION NUMERIQUE (EBD)" },
  { value: "EXPERT EN INFORMATIQUE ET SYSTEMES D'INFORMATION (EPSI)", label: "EXPERT EN INFORMATIQUE ET SYSTEMES D'INFORMATION (EPSI)" },
  { value: "EXPERT(E) INFORMATIQUE ET SYSTEMES D'INFORMATION (INGESUP)", label: "EXPERT(E) INFORMATIQUE ET SYSTEMES D'INFORMATION (INGESUP)" },
  { value: "MANAGER ET ENTREPRENEURIAT DE PROJETS NUMERIQUES (ASSOC CAMPUS FONDERIE DE L'IMAGE)", label: "MANAGER ET ENTREPRENEURIAT DE PROJETS NUMERIQUES (ASSOC CAMPUS FONDERIE DE L'IMAGE)" },
  { value: "EXPERT(E) EN ETUDES ET DEVELOPPEMENT DU SYSTEME D'INFORMATION (HITEMA)", label: "EXPERT(E) EN ETUDES ET DEVELOPPEMENT DU SYSTEME D'INFORMATION (HITEMA)" },
  { value: "CHEF DE PROJET MULTIMEDIA (ESTEI)", label: "CHEF DE PROJET MULTIMEDIA (ESTEI)" },
  { value: "EXPERT EN SYSTEMES D'INFORMATION (PARTNER FORMATION - ISITECH)", label: "EXPERT EN SYSTEMES D'INFORMATION (PARTNER FORMATION - ISITECH)" },
  { value: "CHEF DE PROJET SYSTEME D'INFORMATION (G4H INSTITUT G4)", label: "CHEF DE PROJET SYSTEME D'INFORMATION (G4H INSTITUT G4)" },
  { value: "EXPERT(E) EN INGENIERIE ET DEVELOPPEMENT DE LOGICIELS (AFTI)", label: "EXPERT(E) EN INGENIERIE ET DEVELOPPEMENT DE LOGICIELS (AFTI)" },
  { value: "CHEF(FE) DE PROJETS INFORMATIQUES (ADIP - IPI)", label: "CHEF(FE) DE PROJETS INFORMATIQUES (ADIP - IPI)" },
  { value: "EXPERT EN SYSTEME INFORMATIQUE (INGETIS)", label: "EXPERT EN SYSTEME INFORMATIQUE (INGETIS)" },
  { value: "EXPERT EN INGENIERIE INFORMATIQUE (SCIENCES-U LILLE - EFFICOM - SCIENCES-U LYON - CRESPA)", label: "EXPERT EN INGENIERIE INFORMATIQUE (SCIENCES-U LILLE - EFFICOM - SCIENCES-U LYON - CRESPA)" },
  { value: "EXPERT EN PROJET DIGITAL (GLOBAL KNOWLEDGE NETWORK FRANCE)", label: "EXPERT EN PROJET DIGITAL (GLOBAL KNOWLEDGE NETWORK FRANCE)" },
  { value: "EXPERT(E) INFORMATIQUE ET SYSTEMES D'INFORMATION (YNOV - INGESUP)", label: "EXPERT(E) INFORMATIQUE ET SYSTEMES D'INFORMATION (YNOV - INGESUP)" },
  { value: "EXPERT EN INFORMATIQUE ET SYSTEMES D'INFORMATION (IPSSI)", label: "EXPERT EN INFORMATIQUE ET SYSTEMES D'INFORMATION (IPSSI)" },
  { value: "EXPERT EN PSYCHOMOTRICITE A REFERENTIEL EUROPEEN (ISRP)", label: "EXPERT EN PSYCHOMOTRICITE A REFERENTIEL EUROPEEN (ISRP)" },
  { value: "MANAGER DES HEBERGEMENTS TOURISTIQUES (ESGV MBA ESG)", label: "MANAGER DES HEBERGEMENTS TOURISTIQUES (ESGV MBA ESG)" },
  { value: "EXPERT(E) EN INGENIERIE ET DROIT DE L'ENVIRONNEMENT (ISE)", label: "EXPERT(E) EN INGENIERIE ET DROIT DE L'ENVIRONNEMENT (ISE)" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT D'OPTIQUE THEORIQUE ET APPLIQUEE", label: "INGENIEUR DIPLOME DE L'INSTITUT D'OPTIQUE THEORIQUE ET APPLIQUEE" },
  { value: "INGENIEUR DIPLOME DE TELECOM SAINT-ETIENNE DE L'UNIVERSITE DE SAINT-ETIENNE SPECIALITE OPTIQUE", label: "INGENIEUR DIPLOME DE TELECOM SAINT-ETIENNE DE L'UNIVERSITE DE SAINT-ETIENNE SPECIALITE OPTIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ENS D'ELECTROTECHNIQUE, D'ELECTRONIQUE, D'INFORMATIQUE, D'HYDRAULIQUE ET DES TELECOMMUNICATIONS DE L'INP DE TOULOUSE, SPECIALITE MECANIQUE ET GENIE HYDRAULIQUE", label: "INGENIEUR DIPLOME DE L'ENS D'ELECTROTECHNIQUE, D'ELECTRONIQUE, D'INFORMATIQUE, D'HYDRAULIQUE ET DES TELECOMMUNICATIONS DE L'INP DE TOULOUSE, SPECIALITE MECANIQUE ET GENIE HYDRAULIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE DE CHIMIE ORGANIQUE ET MINERALE", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE DE CHIMIE ORGANIQUE ET MINERALE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE CHIMIE DE MONTPELLIER", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE CHIMIE DE MONTPELLIER" },
  { value: "INGENIEUR SPECIALISE EN GEOSCIENCES PETROLIERES, DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DU PETROLE ET DES MOTEURS", label: "INGENIEUR SPECIALISE EN GEOSCIENCES PETROLIERES, DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DU PETROLE ET DES MOTEURS" },
  { value: "INGENIEUR DIPLOME DU CENTRE D'ETUDES SUPERIEURES INDUSTRIELLES", label: "INGENIEUR DIPLOME DU CENTRE D'ETUDES SUPERIEURES INDUSTRIELLES" },
  { value: "INGENIEUR DIPLOME DU CENTRE D'ETUDES SUPERIEURES INDUSTRIELLES, SPECIALITE GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DU CENTRE D'ETUDES SUPERIEURES INDUSTRIELLES, SPECIALITE GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURES D'INGENIEURS DE PARIS-EST DE L'UNIVERSITE DE MARNE LA VALLEE SPECIALITE MAINTENANCE ET FIABILITE DES PROCESSUS INDUSTRIELS", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURES D'INGENIEURS DE PARIS-EST DE L'UNIVERSITE DE MARNE LA VALLEE SPECIALITE MAINTENANCE ET FIABILITE DES PROCESSUS INDUSTRIELS" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE D'ORLEANS, SPECIALITE GENIE MECANIQUE ET PRODUCTION", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE D'ORLEANS, SPECIALITE GENIE MECANIQUE ET PRODUCTION" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE DES TECHNOLOGIES INDUSTRIELLES AVANCEES (ESTIA)", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE DES TECHNOLOGIES INDUSTRIELLES AVANCEES (ESTIA)" },
  { value: "INGENIEUR DIPLOME DE TELECOM PHYSIQUE STRASBOURG, DE L'UNIVERSITE STRASBOURG, SPECIALITE ELECTRONIQUE ET INFORMATIQUE INDUSTRIELLE", label: "INGENIEUR DIPLOME DE TELECOM PHYSIQUE STRASBOURG, DE L'UNIVERSITE STRASBOURG, SPECIALITE ELECTRONIQUE ET INFORMATIQUE INDUSTRIELLE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'ELECTRONIQUE DE L'OUEST, SPECIALITE INFORMATIQUE INDUSTRIELLE", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'ELECTRONIQUE DE L'OUEST, SPECIALITE INFORMATIQUE INDUSTRIELLE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE DOUAI, SPECIALITE GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE DOUAI, SPECIALITE GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE EN SYSTEMES AVANCES ET RESEAUX DE L'INSTITUT POLYTECHNIQUE DE GRENOBLE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE EN SYSTEMES AVANCES ET RESEAUX DE L'INSTITUT POLYTECHNIQUE DE GRENOBLE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE DE TOURS SPE INFORMATIQUE INDUSTRIELLE (POLYTECHNIQUE TOURS)", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE DE TOURS SPE INFORMATIQUE INDUSTRIELLE (POLYTECHNIQUE TOURS)" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT POLYTECHNIQUE DE BORDEAUX-ENS D'ELECTRONIQUE, INFORMATIQUE, TELECOMMUNICATIONS, MATHEMATIQUES ET MECANIQUE DE BORDEAUX, SPECIALITE SYSTEMES ELECTRONIQUES EMBARQUES", label: "INGENIEUR DIPLOME DE L'INSTITUT POLYTECHNIQUE DE BORDEAUX-ENS D'ELECTRONIQUE, INFORMATIQUE, TELECOMMUNICATIONS, MATHEMATIQUES ET MECANIQUE DE BORDEAUX, SPECIALITE SYSTEMES ELECTRONIQUES EMBARQUES" },
  { value: "INGENIEUR DIPLOME DE L'EPF ECOLE POLYTECHNIQUE FEMININE, SPECIALITE SYSTEMES INFORMATIQUES ET INDUSTRIELS", label: "INGENIEUR DIPLOME DE L'EPF ECOLE POLYTECHNIQUE FEMININE, SPECIALITE SYSTEMES INFORMATIQUES ET INDUSTRIELS" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INFORMATIQUE, ELECTRONIQUE, AUTOMATIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INFORMATIQUE, ELECTRONIQUE, AUTOMATIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE NANTES SPE INGENIERIE LOGICIELLE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE NANTES SPE INGENIERIE LOGICIELLE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE MONTPELLIER DE L'UNIVERSITE DE MONTPELLIER, SPECIALITE ELECTRONIQUE ET INFORMATIQUE INDUSTRIELLE", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE MONTPELLIER DE L'UNIVERSITE DE MONTPELLIER, SPECIALITE ELECTRONIQUE ET INFORMATIQUE INDUSTRIELLE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT DES SCIENCES ET TECHNIQUES DES YVELINES DE L'UNIVERSITE DE VERSAILLES-SAINT QUENTIN EN YVELINES, SPECIALITE SYSTEMES ELECTRONIQUES", label: "INGENIEUR DIPLOME DE L'INSTITUT DES SCIENCES ET TECHNIQUES DES YVELINES DE L'UNIVERSITE DE VERSAILLES-SAINT QUENTIN EN YVELINES, SPECIALITE SYSTEMES ELECTRONIQUES" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE PHYSIQUE, ELECTRONIQUE, MATERIAUX DE L'IP DE GRENOBLE SPE MICROELECTRONIQUE ET TELECOMMUNICATIONS", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE PHYSIQUE, ELECTRONIQUE, MATERIAUX DE L'IP DE GRENOBLE SPE MICROELECTRONIQUE ET TELECOMMUNICATIONS" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE LILLE DE L'UNIVERSITE LILLE 1, SPECIALITE GENIE ELECTRIQUE ET INFORMATIQUE INDUSTRIELLE", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE LILLE DE L'UNIVERSITE LILLE 1, SPECIALITE GENIE ELECTRIQUE ET INFORMATIQUE INDUSTRIELLE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE DE LILLE, SPECIALITE GENIE INFORMATIQUE ET INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE DE LILLE, SPECIALITE GENIE INFORMATIQUE ET INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE DES SCIENCES ET TECHNOLOGIES DE L'INGENIEUR DE NANCY DE L'UNIVERSITE DE LORRAINE, SPECIALITE INFORMATIQUE INDUSTRIELLE", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE DES SCIENCES ET TECHNOLOGIES DE L'INGENIEUR DE NANCY DE L'UNIVERSITE DE LORRAINE, SPECIALITE INFORMATIQUE INDUSTRIELLE" },
  { value: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS, SPECIALITE AUTOMATIQUE ET ROBOTIQUE", label: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS, SPECIALITE AUTOMATIQUE ET ROBOTIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'AGRICULTURE D'ANGERS (ESAA)", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'AGRICULTURE D'ANGERS (ESAA)" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT POLYTECHNIQUE LASALLE BEAUVAIS, SPECIALITE AGRONOMIE ET AGRO-INDUSTRIES", label: "INGENIEUR DIPLOME DE L'INSTITUT POLYTECHNIQUE LASALLE BEAUVAIS, SPECIALITE AGRONOMIE ET AGRO-INDUSTRIES" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR D'AGRICULTURE DE LILLE DU GROUPE HEI-ISA-ISEN SPECIALITE PAYSAGE", label: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR D'AGRICULTURE DE LILLE DU GROUPE HEI-ISA-ISEN SPECIALITE PAYSAGE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR DES SCIENCES AGRONOMIQUES, AGROALIMENTAIRES, HORTICOLES ET DU PAYSAGE (AGRO CAMPUS OUEST), SPECIALITE PAYSAGE", label: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR DES SCIENCES AGRONOMIQUES, AGROALIMENTAIRES, HORTICOLES ET DU PAYSAGE (AGRO CAMPUS OUEST), SPECIALITE PAYSAGE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT POLYTECHNIQUE DE BORDEAUX - ECOLE NATIONALE SUPERIEURE DE CHIMIE DE BIOLOGIE ET DE PHYSIQUE SPE MATERIAUX", label: "INGENIEUR DIPLOME DE L'INSTITUT POLYTECHNIQUE DE BORDEAUX - ECOLE NATIONALE SUPERIEURE DE CHIMIE DE BIOLOGIE ET DE PHYSIQUE SPE MATERIAUX" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE MECANIQUE ET DES MICROTECHNIQUES, SPECIALITE MICROTECHNIQUES ET DESIGN", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE MECANIQUE ET DES MICROTECHNIQUES, SPECIALITE MICROTECHNIQUES ET DESIGN" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DU PETROLE ET DES MOTEURS, SPECIALITE DEVELOPPEMENT ET EXPLOITATION DES GISEMENTS", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DU PETROLE ET DES MOTEURS, SPECIALITE DEVELOPPEMENT ET EXPLOITATION DES GISEMENTS" },
  { value: "INGENIEUR SPECIALISE EN ENERGIE ET PROCEDES, DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DU PETROLE ET DES MOTEURS", label: "INGENIEUR SPECIALISE EN ENERGIE ET PROCEDES, DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DU PETROLE ET DES MOTEURS" },
  { value: "INGENIEUR SPECIALISE EN ENERGIE ET MARCHES, DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DU PETROLE ET DES MOTEURS", label: "INGENIEUR SPECIALISE EN ENERGIE ET MARCHES, DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DU PETROLE ET DES MOTEURS" },
  { value: "INGENIEUR SPECIALISE EN PROCEDES POLYMERES, DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DU PETROLE ET DES MOTEURS", label: "INGENIEUR SPECIALISE EN PROCEDES POLYMERES, DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DU PETROLE ET DES MOTEURS" },
  { value: "INGENIEUR SPECIALISE EN ENERGIE ET PRODUIT, DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DU PETROLE ET DES MOTEURS", label: "INGENIEUR SPECIALISE EN ENERGIE ET PRODUIT, DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DU PETROLE ET DES MOTEURS" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES INGENIEURS EN ARTS CHIMIQUES ET TECHNOLOGIQUES, DE L'INSTITUT NATIONAL POLYTECHNIQUE DE TOULOUSE SPECIALITE GENIE CHIMIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES INGENIEURS EN ARTS CHIMIQUES ET TECHNOLOGIQUES, DE L'INSTITUT NATIONAL POLYTECHNIQUE DE TOULOUSE SPECIALITE GENIE CHIMIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES INDUSTRIES CHIMIQUES DE L'UNIVERSITE DE LORRAINE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES INDUSTRIES CHIMIQUES DE L'UNIVERSITE DE LORRAINE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE DE FONDERIE ET DE FORGE", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE DE FONDERIE ET DE FORGE" },
  { value: "INGENIEUR DIPLOME L'ECOLE INTERNATIONALE DU PAPIER, DE LA COMMUNICATION IMPRIMEE ET DES BIOMATERIAUX DE L'INP DE GRENOBLE", label: "INGENIEUR DIPLOME L'ECOLE INTERNATIONALE DU PAPIER, DE LA COMMUNICATION IMPRIMEE ET DES BIOMATERIAUX DE L'INP DE GRENOBLE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE STRASBOURG, SPECIALITE GENIE ENERGETIQUE", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE STRASBOURG, SPECIALITE GENIE ENERGETIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE DES MINES DE SAINT-ETIENNE, SPECIALITE GENIE DES INSTALLATIONS NUCLEAIRES", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE DES MINES DE SAINT-ETIENNE, SPECIALITE GENIE DES INSTALLATIONS NUCLEAIRES" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE D'INGENIEURS DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS, SPECIALITE ENERGETIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE D'INGENIEURS DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS, SPECIALITE ENERGETIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS, SPECIALITE GENIE ENERGETIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS, SPECIALITE GENIE ENERGETIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE D'ELECTRICITE, DE PRODUCTION ET DES METHODES INDUSTRIELLES, SPECIALITE GENIE ENERGETIQUE ET CLIMATIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE D'ELECTRICITE, DE PRODUCTION ET DES METHODES INDUSTRIELLES, SPECIALITE GENIE ENERGETIQUE ET CLIMATIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS EN ELECTRONIQUE ET ELECTROTECHNIQUE D'AMIENS", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS EN ELECTRONIQUE ET ELECTROTECHNIQUE D'AMIENS" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS DES TRAVAUX DE LA CONSTRUCTION DE CAEN (ESITC)", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS DES TRAVAUX DE LA CONSTRUCTION DE CAEN (ESITC)" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE DE NANTES, SPECIALITE BATIMENT ET TRAVAUX PUBLICS", label: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE DE NANTES, SPECIALITE BATIMENT ET TRAVAUX PUBLICS" },
  { value: "INGENIEUR DIPLOME DU CENTRE D'ETUDES SUPERIEURES INDUSTRIELLES, SPECIALITE BATIMENT ET TRAVAUX PUBLICS", label: "INGENIEUR DIPLOME DU CENTRE D'ETUDES SUPERIEURES INDUSTRIELLES, SPECIALITE BATIMENT ET TRAVAUX PUBLICS" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS , SPECIALITE TRAVAUX PUBLICS (ENSAM)", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS , SPECIALITE TRAVAUX PUBLICS (ENSAM)" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE TOULOUSE SPE GENIE CIVIL", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE TOULOUSE SPE GENIE CIVIL" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES TECHNIQUES INDUSTRIELLES ET DES MINES D'ALES SPE CONCEPTION ET MANAGEMENT DE LA CONSTRUCTION", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES TECHNIQUES INDUSTRIELLES ET DES MINES D'ALES SPE CONCEPTION ET MANAGEMENT DE LA CONSTRUCTION" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE D'ORLEANS, SPECIALITE SYSTEME D'INFORMATION POUR LE BATIMENT", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE D'ORLEANS, SPECIALITE SYSTEME D'INFORMATION POUR LE BATIMENT" },
  { value: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS, SPECIALITE BATIMENT ET TRAVAUX PUBLICS", label: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS, SPECIALITE BATIMENT ET TRAVAUX PUBLICS" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS DES TRAVAUX DE LA CONSTRUCTION DE CACHAN", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS DES TRAVAUX DE LA CONSTRUCTION DE CACHAN" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE CATHOLIQUE D'ARTS ET METIERS DE LYON, SPECIALITE BOIS", label: "INGENIEUR DIPLOME DE L'ECOLE CATHOLIQUE D'ARTS ET METIERS DE LYON, SPECIALITE BOIS" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE DOUAI, SPECIALITE GENIE CIVL ET SYSTEMES FERROVIAIRES, EN CONVENTION AVEC L'IPHC", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE DOUAI, SPECIALITE GENIE CIVL ET SYSTEMES FERROVIAIRES, EN CONVENTION AVEC L'IPHC" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT TEXTILE ET CHIMIQUE DE LYON", label: "INGENIEUR DIPLOME DE L'INSTITUT TEXTILE ET CHIMIQUE DE LYON" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE DES ARTS ET INDUSTRIES TEXTILES", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE DES ARTS ET INDUSTRIES TEXTILES" },
  { value: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE MECANIQUE", label: "INGENIEUR DIPLOME DU CONSERVATOIRE NATIONAL DES ARTS ET METIERS SPECIALITE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS, SPECIALITE MECANIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS, SPECIALITE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE DE NANTES, SPECIALITE MECANIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE CENTRALE DE NANTES, SPECIALITE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURES D'INGENIEURS DE PARIS-EST DE L'UNIVERSITE DE MARNE LA VALLEE, SPECIALITE MECANIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURES D'INGENIEURS DE PARIS-EST DE L'UNIVERSITE DE MARNE LA VALLEE, SPECIALITE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE CATHOLIQUE D'ARTS ET METIERS DE LYON, SPECIALITE MECANIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE CATHOLIQUE D'ARTS ET METIERS DE LYON, SPECIALITE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS EN CONVENTION AVEC L'UNIVERSITE DE REIMS, SPECIALITE MECANIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ARTS ET METIERS EN CONVENTION AVEC L'UNIVERSITE DE REIMS, SPECIALITE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR D'ETUDES LOGISTIQUES DE L'UNIVERSITE DU HAVRE SPECIALITE MECANIQUE ET PRODUCTION", label: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR D'ETUDES LOGISTIQUES DE L'UNIVERSITE DU HAVRE SPECIALITE MECANIQUE ET PRODUCTION" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE STRASBOURG, SPECIALITE MECANIQUE", label: "INGENIEUR DIPLOME DE L'INSTITUT NATIONAL DES SCIENCES APPLIQUEES DE STRASBOURG, SPECIALITE MECANIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE SAVOIE DE L'UNIVERSITE DE CHAMBERY , SPECIALITE MECANIQUE-PRODUCTIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE UNIVERSITAIRE DE SAVOIE DE L'UNIVERSITE DE CHAMBERY , SPECIALITE MECANIQUE-PRODUCTIQUE" },
  { value: "INGENIEUR DIPLOME DE L'EPU PIERRE ET MARIE CURIE DE L'UNIVERSITE PARIS 6, SPECIALITE ELECTRONIQUE ET INFORMATIQUE", label: "INGENIEUR DIPLOME DE L'EPU PIERRE ET MARIE CURIE DE L'UNIVERSITE PARIS 6, SPECIALITE ELECTRONIQUE ET INFORMATIQUE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR D'ELECTRONIQUE DE PARIS", label: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR D'ELECTRONIQUE DE PARIS" },
  { value: "INGENIEUR DIPLOME DU CENTRE D'ETUDES SUPERIEURES INDUSTRIELLES SPECIALITE SYSTEMES ELECTRIQUES ET ELECTRONIQUES EMBARQUES", label: "INGENIEUR DIPLOME DU CENTRE D'ETUDES SUPERIEURES INDUSTRIELLES SPECIALITE SYSTEMES ELECTRIQUES ET ELECTRONIQUES EMBARQUES" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS EN GENIE ELECTRIQUE (ESIGELEC)", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'INGENIEURS EN GENIE ELECTRIQUE (ESIGELEC)" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE L'ELECTRONIQUE ET DE SES APPLICATIONS, SPECIALITE ELECTRONIQUE ET INFORMATIQUE INDUSTRIELLE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE L'ELECTRONIQUE ET DE SES APPLICATIONS, SPECIALITE ELECTRONIQUE ET INFORMATIQUE INDUSTRIELLE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE GRENOBLE-ALPES, SPECIALITE ELECTRONIQUE ET INFORMATIQUE INDUSTRIELLE", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE GRENOBLE-ALPES, SPECIALITE ELECTRONIQUE ET INFORMATIQUE INDUSTRIELLE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE DE NICE, SPECIALITE ELECTRONIQUE ET INFORMATIQUE INDUSTRIELLE", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE DE NICE, SPECIALITE ELECTRONIQUE ET INFORMATIQUE INDUSTRIELLE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE SAINT-ETIENNE, SPECIALITE GENIE INDUSTRIEL", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES MINES DE SAINT-ETIENNE, SPECIALITE GENIE INDUSTRIEL" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR D'ELECTRONIQUE ET DU NUMERIQUE DE TOULON, SPECIALITE ELECTRONIQUE ET INFORMATIQUE INDUSTRIELLE", label: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR D'ELECTRONIQUE ET DU NUMERIQUE DE TOULON, SPECIALITE ELECTRONIQUE ET INFORMATIQUE INDUSTRIELLE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE PARIS 11, SPECIALITE ELECTRONIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE PARIS 11, SPECIALITE ELECTRONIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE PARIS 11, SPECIALITE PHOTONIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE PARIS 11, SPECIALITE PHOTONIQUE" },
  { value: "INGENIEUR DIPLOME DE L'UNIVERSITE DE TECHNOLOGIE DE BELFORT MONTBELIARD SPECIALITE GENIE ELECTRIQUE (UTBM)", label: "INGENIEUR DIPLOME DE L'UNIVERSITE DE TECHNOLOGIE DE BELFORT MONTBELIARD SPECIALITE GENIE ELECTRIQUE (UTBM)" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS EN INFORMATIQUE AUTOMATIQUE MECANIQUE ENERGETIQUE ET ELECTRONIQUE DE VALENCIENNES SPE GENIE ELECTRIQUE ET INFORMATIQUE INDUSTRIELLE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS EN INFORMATIQUE AUTOMATIQUE MECANIQUE ENERGETIQUE ET ELECTRONIQUE DE VALENCIENNES SPE GENIE ELECTRIQUE ET INFORMATIQUE INDUSTRIELLE" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR D'ELECTRONIQUE ET DU NUMERIQUE DE BREST", label: "INGENIEUR DIPLOME DE L'INSTITUT SUPERIEUR D'ELECTRONIQUE ET DU NUMERIQUE DE BREST" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'ELECTRICITE (SUPELEC)", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE D'ELECTRICITE (SUPELEC)" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE PARIS 11, SPECIALITE INFORMATIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE POLYTECHNIQUE DE L'UNIVERSITE PARIS 11, SPECIALITE INFORMATIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURES D'INGENIEURS DE PARIS-EST DE L'UNIVERSITE DE MARNE LA VALLEE, SPECIALITE INFORMATIQUE ET RESEAUX", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURES D'INGENIEURS DE PARIS-EST DE L'UNIVERSITE DE MARNE LA VALLEE, SPECIALITE INFORMATIQUE ET RESEAUX" },
  { value: "INGENIEUR DIPLOME DE L'INSTITUT D'INGENIERIE INFORMATIQUE DE LIMOGES", label: "INGENIEUR DIPLOME DE L'INSTITUT D'INGENIERIE INFORMATIQUE DE LIMOGES" },
  { value: "INGENIEUR DIPLOME TELECOM LILLE 1", label: "INGENIEUR DIPLOME TELECOM LILLE 1" },
  { value: "INGENIEUR DIPLOME DE TELECOM BRETAGNE DE L'INSTITUT MINES-TELECOM, SPECIALITE RESEAUX ET TELECOMMUNICATIONS", label: "INGENIEUR DIPLOME DE TELECOM BRETAGNE DE L'INSTITUT MINES-TELECOM, SPECIALITE RESEAUX ET TELECOMMUNICATIONS" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE DE CHIMIE, PHYSIQUE, ELECTRONIQUE DE LYON, SPECIALITE INFORMATIQUE ET RESEAUX DE COMMUNICATION", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURE DE CHIMIE, PHYSIQUE, ELECTRONIQUE DE LYON, SPECIALITE INFORMATIQUE ET RESEAUX DE COMMUNICATION" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ELECTRONIQUE, INFORMATIQUE ET RADIOCOMMUNICATIONS DE BORDEAUX, SPECIALITE RESEAUX ET SYSTEMES D'INFORMATION", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'ELECTRONIQUE, INFORMATIQUE ET RADIOCOMMUNICATIONS DE BORDEAUX, SPECIALITE RESEAUX ET SYSTEMES D'INFORMATION" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS DE CAEN, SPECIALITE INFORMATIQUE (ING)", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INGENIEURS DE CAEN, SPECIALITE INFORMATIQUE (ING)" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE POUR L'INFORMATIQUE ET LES TECHNIQUES AVANCEES (EPITA)", label: "INGENIEUR DIPLOME DE L'ECOLE POUR L'INFORMATIQUE ET LES TECHNIQUES AVANCEES (EPITA)" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES TECHNIQUES INDUSTRIELLES ET DES MINES D'ALES SPE INFORMATIQUE ET RESEAUX", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DES TECHNIQUES INDUSTRIELLES ET DES MINES D'ALES SPE INFORMATIQUE ET RESEAUX" },
  { value: "INGENIEUR DIPLOME DE L'IP DE GRENOBLE-EC NAT SUP D'INFORMATIQUE ET DE MATHEMATIQUES APPLIQUEES DE GRENOBLE, SPECIALITE INFORMATIQUE ET SYSTEMES D'INFORMATION", label: "INGENIEUR DIPLOME DE L'IP DE GRENOBLE-EC NAT SUP D'INFORMATIQUE ET DE MATHEMATIQUES APPLIQUEES DE GRENOBLE, SPECIALITE INFORMATIQUE ET SYSTEMES D'INFORMATION" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INFORMATIQUE POUR L'INDUSTRIE ET L'ENTREPRISE, SPECIALITE INFORMATIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE D'INFORMATIQUE POUR L'INDUSTRIE ET L'ENTREPRISE, SPECIALITE INFORMATIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ENS D'ELECTROTECHNIQUE, D'ELECTRONIQUE, D'INFORMATIQUE, D'HYDRAULIQUE ET DES TELECOMMUNICATIONS DE L'INP DE TOULOUSE SPECIALITE INFORMATIQUE ET TELECOMMUNICATIONS", label: "INGENIEUR DIPLOME DE L'ENS D'ELECTROTECHNIQUE, D'ELECTRONIQUE, D'INFORMATIQUE, D'HYDRAULIQUE ET DES TELECOMMUNICATIONS DE L'INP DE TOULOUSE SPECIALITE INFORMATIQUE ET TELECOMMUNICATIONS" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE SCIENCES APPLIQUEES ET DE TECHNOLOGIE DE LANNION DE L'UNIVERSITE DE RENNES-I, SPECIALITE INFORMATIQUE MULTIMEDIA ET RESEAUX", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE SUPERIEURE DE SCIENCES APPLIQUEES ET DE TECHNOLOGIE DE LANNION DE L'UNIVERSITE DE RENNES-I, SPECIALITE INFORMATIQUE MULTIMEDIA ET RESEAUX" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURES D'INGENIEURS DE PARIS-EST DE L'UNIVERSITE DE MARNE LA VALLEE, SPECIALITE ELECTRONIQUE ET INFORMATIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURES D'INGENIEURS DE PARIS-EST DE L'UNIVERSITE DE MARNE LA VALLEE, SPECIALITE ELECTRONIQUE ET INFORMATIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURES D'INGENIEURS DE PARIS-EST DE L'UNIVERSITE DE MARNE LA VALLEE, SPECIALITE INFORMATIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE SUPERIEURES D'INGENIEURS DE PARIS-EST DE L'UNIVERSITE DE MARNE LA VALLEE, SPECIALITE INFORMATIQUE" },
  { value: "INGENIEUR DIPLOME DE TELECOM PARIS TECH DE L'INSTITUT MINES-TELECOM", label: "INGENIEUR DIPLOME DE TELECOM PARIS TECH DE L'INSTITUT MINES-TELECOM" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE FRANCAISE D'ELECTRONIQUE ET D'INFORMATIQUE", label: "INGENIEUR DIPLOME DE L'ECOLE FRANCAISE D'ELECTRONIQUE ET D'INFORMATIQUE" },
  { value: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE DU GENIE DE L'EAU ET DE L'ENVIRONNEMENT DE STRASBOURG", label: "INGENIEUR DIPLOME DE L'ECOLE NATIONALE DU GENIE DE L'EAU ET DE L'ENVIRONNEMENT DE STRASBOURG" },
  { value: "RESPONSABLE COMMERCIAL ET MARKETING (CNAM)", label: "RESPONSABLE COMMERCIAL ET MARKETING (CNAM)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : CONTROLE INDUSTRIEL (LP LORRAINE)", label: "MAINTENANCE ET TECHNOLOGIE : CONTROLE INDUSTRIEL (LP LORRAINE)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP MARNE LA VALLEE)", label: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP MARNE LA VALLEE)" },
  { value: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP PARIS 11)", label: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP PARIS 11)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP LYON 1)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP LYON 1)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP BORDEAUX)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP BORDEAUX)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP GRENOBLE ALPES)", label: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP GRENOBLE ALPES)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP PARIS 6)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP PARIS 6)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP DIJON)", label: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP DIJON)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP PERPIGNAN)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP PERPIGNAN)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP CLERMONT AUVERGNE))", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP CLERMONT AUVERGNE))" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP CLERMONT AUVERGNE)", label: "METIERS DE L'INDUSTRIE : CONCEPTION DE PRODUITS INDUSTRIELS (LP CLERMONT AUVERGNE)" },
  { value: "METIERS DE LA QUALITE (LP CLERMONT AUVERGNE)", label: "METIERS DE LA QUALITE (LP CLERMONT AUVERGNE)" },
  { value: "MAINTENANCE DES SYSTEMES INDUSTRIELS, DE PRODUCTION ET D'ENERGIE (LP LYON 1)", label: "MAINTENANCE DES SYSTEMES INDUSTRIELS, DE PRODUCTION ET D'ENERGIE (LP LYON 1)" },
  { value: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP BESANCON)", label: "MAINTENANCE ET TECHNOLOGIE : SYSTEMES PLURITECHNIQUES (LP BESANCON)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP DIJON)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET PROCESSUS DE MISE EN FORME DES MATERIAUX (LP DIJON)" },
  { value: "METIERS DE L'INDUSTRIE : INDUSTRIE NAVALE ET MARITIME (LP BREST)", label: "METIERS DE L'INDUSTRIE : INDUSTRIE NAVALE ET MARITIME (LP BREST)" },
  { value: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP TOULOUSE 3)", label: "METIERS DE L'INSTRUMENTATION, DE LA MESURE ET DU CONTROLE QUALITE (LP TOULOUSE 3)" },
  { value: "METIERS DE L'INDUSTRIE : INDUSTRIE NAVALE ET MARITIME (LP BRETAGNE SUD)", label: "METIERS DE L'INDUSTRIE : INDUSTRIE NAVALE ET MARITIME (LP BRETAGNE SUD)" },
  { value: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP SAINT ETIENNE)", label: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP SAINT ETIENNE)" },
  { value: "GESTION DES RISQUES INDUSTRIELS ET TECHNOLOGIQUES (LP LITTORAL)", label: "GESTION DES RISQUES INDUSTRIELS ET TECHNOLOGIQUES (LP LITTORAL)" },
  { value: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP BRETAGNE SUD)", label: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP BRETAGNE SUD)" },
  { value: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP BESANCON)", label: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP BESANCON)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP LORRAINE)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP LORRAINE)" },
  { value: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP BREST)", label: "METIERS DE L'INDUSTRIE : CONCEPTION ET AMELIORATION DE PROCESSUS ET PROCEDES INDUSTRIELS (LP BREST)" },
  { value: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP SAINT ETIENNE)", label: "QUALITE, HYGIENE, SECURITE, SANTE, ENVIRONNEMENT (LP SAINT ETIENNE)" },
  { value: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP PAU)", label: "METIERS DE L'INDUSTRIE : GESTION DE LA PRODUCTION INDUSTRIELLE (LP PAU)" },
  { value: "TECHNICO-COMMERCIAL (LP PARIS 12)", label: "TECHNICO-COMMERCIAL (LP PARIS 12)" },
  { value: "COMMERCE ET DISTRIBUTION (LP SAINT ETIENNE)", label: "COMMERCE ET DISTRIBUTION (LP SAINT ETIENNE)" },
  { value: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP CORSE)", label: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP CORSE)" },
  { value: "COMMERCE SPE MARKETING DIGITAL (LP MADIG PARIS 5)", label: "COMMERCE SPE MARKETING DIGITAL (LP MADIG PARIS 5)" },
  { value: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP RENNES 1)", label: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP RENNES 1)" },
  { value: "MANAGEMENT DES ACTIVITES COMMERCIALES", label: "MANAGEMENT DES ACTIVITES COMMERCIALES" },
  { value: "COMMERCE SPE MANAGEMENT COMMERCIAL DE L'EVENEMENTIEL (LP PARIS 13)", label: "COMMERCE SPE MANAGEMENT COMMERCIAL DE L'EVENEMENTIEL (LP PARIS 13)" },
  { value: "COMMERCE SPE COMMERCIALISATION DES PRODUITS DE LUXE (LP PARIS 13)", label: "COMMERCE SPE COMMERCIALISATION DES PRODUITS DE LUXE (LP PARIS 13)" },
  { value: "COMMERCE SPE MARKETING TERRITORIAL (LP PARIS 13)", label: "COMMERCE SPE MARKETING TERRITORIAL (LP PARIS 13)" },
  { value: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP MULHOUSE)", label: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP MULHOUSE)" },
  { value: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP BORDEAUX)", label: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP BORDEAUX)" },
  { value: "COMMERCE ET DISTRIBUTION (LP CERGY)", label: "COMMERCE ET DISTRIBUTION (LP CERGY)" },
  { value: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP MARNE LA VALLEE)", label: "COMMERCIALISATION DES PRODUITS ALIMENTAIRES (LP MARNE LA VALLEE)" },
  { value: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP MARNE LA VALLEE)", label: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP MARNE LA VALLEE)" },
  { value: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP CERGY)", label: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP CERGY)" },
  { value: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP VERSAILLES)", label: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP VERSAILLES)" },
  { value: "COMMERCE ET DISTRIBUTION (LP LA REUNION)", label: "COMMERCE ET DISTRIBUTION (LP LA REUNION)" },
  { value: "COMMERCE ET DISTRIBUTION (LP PARIS 12)", label: "COMMERCE ET DISTRIBUTION (LP PARIS 12)" },
  { value: "E-COMMERCE ET MARKETING NUMERIQUE (LP NANTES)", label: "E-COMMERCE ET MARKETING NUMERIQUE (LP NANTES)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (PARIS 4)", label: "METIERS DU COMMERCE INTERNATIONAL (PARIS 4)" },
  { value: "E-COMMERCE ET MARKETING NUMERIQUE (PARIS 12)", label: "E-COMMERCE ET MARKETING NUMERIQUE (PARIS 12)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP PARIS 10)", label: "METIERS DU COMMERCE INTERNATIONAL (LP PARIS 10)" },
  { value: "COMMERCE ET DISTRIBUTION (LP LYON 3)", label: "COMMERCE ET DISTRIBUTION (LP LYON 3)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP GRENOBLE ALPES)", label: "METIERS DU COMMERCE INTERNATIONAL (LP GRENOBLE ALPES)" },
  { value: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP ROUEN)", label: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP ROUEN)" },
  { value: "TECHNICO-COMMERCIAL (LP GRENOBLE ALPES)", label: "TECHNICO-COMMERCIAL (LP GRENOBLE ALPES)" },
  { value: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP LILLE 2)", label: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP LILLE 2)" },
  { value: "E-COMMERCE ET MARKETING NUMERIQUE (LP LILLE 2)", label: "E-COMMERCE ET MARKETING NUMERIQUE (LP LILLE 2)" },
  { value: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP PARIS 13)", label: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP PARIS 13)" },
  { value: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP PAU)", label: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP PAU)" },
  { value: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP ORLEANS)", label: "GESTION DES ACHATS ET DES APPROVISIONNEMENTS (LP ORLEANS)" },
  { value: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP CLERMONT AUVERGNE)", label: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP CLERMONT AUVERGNE)" },
  { value: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP DIJON)", label: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP DIJON)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP CORSE)", label: "METIERS DU COMMERCE INTERNATIONAL (LP CORSE)" },
  { value: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP GRENOBLE ALPES)", label: "MANAGEMENT DES ACTIVITES COMMERCIALES (LP GRENOBLE ALPES)" },
  { value: "COMMERCE ET DISTRIBUTION (LP ANGERS)", label: "COMMERCE ET DISTRIBUTION (LP ANGERS)" },
  { value: "COMMERCE ET DISTRIBUTION (LP BREST)", label: "COMMERCE ET DISTRIBUTION (LP BREST)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP LILLE 1)", label: "METIERS DU COMMERCE INTERNATIONAL (LP LILLE 1)" },
  { value: "COMMERCE ET DISTRIBUTION (LP VALENCIENNES)", label: "COMMERCE ET DISTRIBUTION (LP VALENCIENNES)" },
  { value: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP CLERMONT AUVERGNE)", label: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP CLERMONT AUVERGNE)" },
  { value: "METIERS DU MARKETING OPERATIONNEL (LP PARIS 1)", label: "METIERS DU MARKETING OPERATIONNEL (LP PARIS 1)" },
  { value: "METIERS DU MARKETING OPERATIONNEL (LP GUYANE)", label: "METIERS DU MARKETING OPERATIONNEL (LP GUYANE)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP ANGERS)", label: "METIERS DU COMMERCE INTERNATIONAL (LP ANGERS)" },
  { value: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP GRENOBLE ALPES)", label: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP GRENOBLE ALPES)" },
  { value: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP LORRAINE)", label: "COMMERCIALISATION DE PRODUITS ET SERVICES (LP LORRAINE)" },
  { value: "METIERS DU COMMERCE INTERNATIONAL (LP LORRAINE)", label: "METIERS DU COMMERCE INTERNATIONAL (LP LORRAINE)" },
  { value: "COMMERCE ET DISTRIBUTION (LP VERSAILLES SAINT QUENTIN)", label: "COMMERCE ET DISTRIBUTION (LP VERSAILLES SAINT QUENTIN)" },
  { value: "COMMERCE ET DISTRIBUTION (LP GUYANE)", label: "COMMERCE ET DISTRIBUTION (LP GUYANE)" },
  { value: "COMMERCE ET DISTRIBUTION (LP POITIERS)", label: "COMMERCE ET DISTRIBUTION (LP POITIERS)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP NANTES)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP NANTES)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP LA ROCHELLE)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP LA ROCHELLE)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP LA ROCHELLE)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP LA ROCHELLE)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP RENNES 1)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP RENNES 1)" },
  { value: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP MONTPELLIER)", label: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP MONTPELLIER)" },
  { value: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP GRENOBLE ALPES)", label: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP GRENOBLE ALPES)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP DIJON)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP DIJON)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP LORRAINE)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP LORRAINE)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP ANGERS)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP ANGERS)" },
  { value: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP BRETAGNE SUD)", label: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP BRETAGNE SUD)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP POITIERS)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP POITIERS)" },
  { value: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP PARIS 6)", label: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP PARIS 6)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP GRENOBLE ALPES)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP GRENOBLE ALPES)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP PARIS 11)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP PARIS 11)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP MARNE LA VALLEE)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP MARNE LA VALLEE)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP PARIS 12)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP PARIS 12)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP VALENCIENNES)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP VALENCIENNES)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP MONTPELLIER)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP MONTPELLIER)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP PARIS 12)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP PARIS 12)" },
  { value: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP ANGERS)", label: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP ANGERS)" },
  { value: "CARTOGRAPHIE, TOPOGRAPHIE ET SYSTEMES D'INFORMATION GEOGRAPHIQUE (LP PARIS 12)", label: "CARTOGRAPHIE, TOPOGRAPHIE ET SYSTEMES D'INFORMATION GEOGRAPHIQUE (LP PARIS 12)" },
  { value: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP LIMOGES)", label: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP LIMOGES)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP BORDEAUX)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP BORDEAUX)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP LYON 1)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP LYON 1)" },
  { value: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP LYON 1)", label: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP LYON 1)" },
  { value: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP TOULOUSE 3)", label: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP TOULOUSE 3)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP GRENOBLE ALPES)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP GRENOBLE ALPES)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP GRENOBLE ALPES)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP GRENOBLE ALPES)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP PAU)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP PAU)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP LYON 1)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP LYON 1)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP PERPIGNAN)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP PERPIGNAN)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP PARIS 5)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP PARIS 5)" },
  { value: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (CNAM)", label: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (CNAM)" },
  { value: "METIERS DE L'INFORMATIQUE : CONDUITE DE PROJET (LP CLERMONT AUVERGNE)", label: "METIERS DE L'INFORMATIQUE : CONDUITE DE PROJET (LP CLERMONT AUVERGNE)" },
  { value: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP AIX MARSEILLE)", label: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP AIX MARSEILLE)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP CERGY)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP CERGY)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP DIJON)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP DIJON)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP GUYANE)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP GUYANE)" },
  { value: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP BORDEAUX)", label: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP BORDEAUX)" },
  { value: "E-COMMERCE ET MARKETING NUMERIQUE (LP LORRAINE)", label: "E-COMMERCE ET MARKETING NUMERIQUE (LP LORRAINE)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP U LITTORAL)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP U LITTORAL)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP REIMS)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP REIMS)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP NICE)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP NICE)" },
  { value: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP NICE)", label: "METIERS DE L'INFORMATIQUE : CONCEPTION, DEVELOPPEMENT ET TEST DE LOGICIELS (LP NICE)" },
  { value: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP NICE)", label: "METIERS DE L'INFORMATIQUE : SYSTEMES D'INFORMATION ET GESTION DE DONNEES (LP NICE)" },
  { value: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP AIX MARSEILLE)", label: "METIERS DE L'INFORMATIQUE : ADMINISTRATION ET SECURITE DES SYSTEMES ET DES RESEAUX (LP AIX MARSEILLE)" },
  { value: "E-COMMERCE ET MARKETING NUMERIQUE (UNIV VERSAILLES SAINT QUENTIN)", label: "E-COMMERCE ET MARKETING NUMERIQUE (UNIV VERSAILLES SAINT QUENTIN)" },
  { value: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP STRASBOURG)", label: "METIERS DU NUMERIQUE : CONCEPTION, REDACTION ET REALISATION WEB (LP STRASBOURG)" },
  { value: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP STRASBOURG)", label: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP STRASBOURG)" },
  { value: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP TOULOUSE 3)", label: "METIERS DE L'INFORMATIQUE : APPLICATIONS WEB (LP TOULOUSE 3)" },
  { value: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP MULHOUSE)", label: "METIERS DES RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS (LP MULHOUSE)" },
  { value: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP PAU)", label: "METIERS DU DECISIONNEL ET DE LA STATISTIQUE (LP PAU)" },
  { value: "DIPLOME D'ETUDES SUPERIEURES EN COMMERCE ET ENTREPRENEURIAT (ESCP EUROPE)", label: "DIPLOME D'ETUDES SUPERIEURES EN COMMERCE ET ENTREPRENEURIAT (ESCP EUROPE)" },
  { value: "CONSEILLER EN DROIT RURAL ET ECONOMIE AGRICOLE (IHDREA)", label: "CONSEILLER EN DROIT RURAL ET ECONOMIE AGRICOLE (IHDREA)" },
  { value: "RESPONSABLE MANAGEMENT DURABLE QUALITE SECURITE ENVIRONNEMENT (CCI DE L'ALLIER - IEQT)", label: "RESPONSABLE MANAGEMENT DURABLE QUALITE SECURITE ENVIRONNEMENT (CCI DE L'ALLIER - IEQT)" },
  { value: "RESPONSABLE DE SYSTEMES DE MANAGEMENT QUALITE-SECURITE-ENVIRONNEMENT (CCIP IDF ITESCIA)", label: "RESPONSABLE DE SYSTEMES DE MANAGEMENT QUALITE-SECURITE-ENVIRONNEMENT (CCIP IDF ITESCIA)" },
  { value: "RESPONSABLE QUALITE SECURITE SURETE ENVIRONNEMENT (CCI EURE ROCHEFORT SAINTONGE)", label: "RESPONSABLE QUALITE SECURITE SURETE ENVIRONNEMENT (CCI EURE ROCHEFORT SAINTONGE)" },
  { value: "DESIGNER (EUROMED MANAGEMENT EID))", label: "DESIGNER (EUROMED MANAGEMENT EID))" },
  { value: "RESPONSABLE TECHNIQUE EN AMENAGEMENT D'ESPACES SPORTIFS (CCI PARIS)", label: "RESPONSABLE TECHNIQUE EN AMENAGEMENT D'ESPACES SPORTIFS (CCI PARIS)" },
  { value: "RESPONSABLE EN UNITE DE PRODUCTION ET PROJETS INDUSTRIELS (CCI TERRITORIALE DE LA MARNE)", label: "RESPONSABLE EN UNITE DE PRODUCTION ET PROJETS INDUSTRIELS (CCI TERRITORIALE DE LA MARNE)" },
  { value: "CHEF DE PROJET COMMERCIAL POUR L'AGROALIMENTAIRE (ISEMA )", label: "CHEF DE PROJET COMMERCIAL POUR L'AGROALIMENTAIRE (ISEMA )" },
  { value: "RESPONSABLE DE TRAVAUX RESEAUX TELECOMS TRES HAUT DEBIT (CCI FRANCE RSX FORMATIONS TRES HT DEBIT)", label: "RESPONSABLE DE TRAVAUX RESEAUX TELECOMS TRES HAUT DEBIT (CCI FRANCE RSX FORMATIONS TRES HT DEBIT)" },
  { value: "RESPONSABLE EN MARKETING, COMMERCIALISATION ET GESTION (CCI FRANCE - RESEAU EGC)", label: "RESPONSABLE EN MARKETING, COMMERCIALISATION ET GESTION (CCI FRANCE - RESEAU EGC)" },
  { value: "RESPONSABLE LOGISTIQUE (CCI FRANCE RESEAU RLOG)", label: "RESPONSABLE LOGISTIQUE (CCI FRANCE RESEAU RLOG)" },
  { value: "RESPONSABLE EN LOGISTIQUE (AFTRAL)", label: "RESPONSABLE EN LOGISTIQUE (AFTRAL)" },
  { value: "RESPONSABLE ACHATS ET APPROVISIONNEMENTS (CCI DORDOGNE, ROCHEFORT STAINTONGE, PORTES DE NORMANDIE - ECOLE DE SAVIGNAC -ISAAP)", label: "RESPONSABLE ACHATS ET APPROVISIONNEMENTS (CCI DORDOGNE, ROCHEFORT STAINTONGE, PORTES DE NORMANDIE - ECOLE DE SAVIGNAC -ISAAP)" },
  { value: "CHEF DE PROJET EN MARKETING INTERNET ET CONCEPTION DE SITE (CCI TERRITORIALE OUEST NORMANDIE)", label: "CHEF DE PROJET EN MARKETING INTERNET ET CONCEPTION DE SITE (CCI TERRITORIALE OUEST NORMANDIE)" },
  { value: "RESPONSABLE EN COMMERCE INTERNATIONAL (CCI FRANCE RESEAU NEGOVENTIS)", label: "RESPONSABLE EN COMMERCE INTERNATIONAL (CCI FRANCE RESEAU NEGOVENTIS)" },
  { value: "RESPONSABLE DE LA DISTRIBUTION (CCI FRANCE - RESEAU NEGOVENTIS)", label: "RESPONSABLE DE LA DISTRIBUTION (CCI FRANCE - RESEAU NEGOVENTIS)" },
  { value: "RESPONSABLE DE DEVELOPPEMENT COMMERCIAL (OPTION BANQUE ASSURANCE) (CCI FRANCE RESEAU NEGOVENTIS)", label: "RESPONSABLE DE DEVELOPPEMENT COMMERCIAL (OPTION BANQUE ASSURANCE) (CCI FRANCE RESEAU NEGOVENTIS)" },
  { value: "RESPONSABLE VISUEL MERCHANDISER (CCI FRANCE - RESEAU ESDC)", label: "RESPONSABLE VISUEL MERCHANDISER (CCI FRANCE - RESEAU ESDC)" },
  { value: "CONTROLEUR DE GESTION (ITESCIA)", label: "CONTROLEUR DE GESTION (ITESCIA)" },
  { value: "RESPONSABLE RESSOURCES HUMAINES (CCI GRAND HAINAUT CCIP IDF ESSYM)", label: "RESPONSABLE RESSOURCES HUMAINES (CCI GRAND HAINAUT CCIP IDF ESSYM)" },
  { value: "CONCEPTEUR REALISATEUR MULTIMEDIA (LES GOBELINS ECOLE DE L'IMAGE)", label: "CONCEPTEUR REALISATEUR MULTIMEDIA (LES GOBELINS ECOLE DE L'IMAGE)" },
  { value: "RESPONSABLE EN INGENIERIE DES LOGICIELS (CESI)", label: "RESPONSABLE EN INGENIERIE DES LOGICIELS (CESI)" },
  { value: "CONCEPTEUR ET DEVELOPPEUR DE SOLUTIONS MOBILES (CCI FRANCE RESEAU ESI)", label: "CONCEPTEUR ET DEVELOPPEUR DE SOLUTIONS MOBILES (CCI FRANCE RESEAU ESI)" },
  { value: "CONCEPTEUR DE SYSTEMES D'INFORMATION (3IL)", label: "CONCEPTEUR DE SYSTEMES D'INFORMATION (3IL)" },
  { value: "CHARGE D'AFFAIRES EN HAUTES TECHNOLOGIES (EURIDIS MANAGEMENT)", label: "CHARGE D'AFFAIRES EN HAUTES TECHNOLOGIES (EURIDIS MANAGEMENT)" },
  { value: "MANAGER DE RESTAURANT (CCIP ECOLE GREGOIRE FERRANDI)", label: "MANAGER DE RESTAURANT (CCIP ECOLE GREGOIRE FERRANDI)" },
  { value: "RESPONSABLE D'UN CENTRE DE PROFIT TOURISME HOTELLERIE RESTAURATION (CCI FRANCE RESEAU NEGOVENTIS)", label: "RESPONSABLE D'UN CENTRE DE PROFIT TOURISME HOTELLERIE RESTAURATION (CCI FRANCE RESEAU NEGOVENTIS)" },
  { value: "CHARGE(E) D'OPTIMISATION COMMERCIALE DES MARQUES ET PRODUITS PARFUMS/COSMETIQUES (CCI ILE DE FRANCE ISIPCA)", label: "CHARGE(E) D'OPTIMISATION COMMERCIALE DES MARQUES ET PRODUITS PARFUMS/COSMETIQUES (CCI ILE DE FRANCE ISIPCA)" },
  { value: "MANAGER TRANSPORTS ET LOGISTIQUE (ENOES EST)", label: "MANAGER TRANSPORTS ET LOGISTIQUE (ENOES EST)" },
  { value: "ADMINISTRATEUR DE SYSTEMES D'INFORMATION (CFA ASPECT UFA LYCEE PASTEUR MT ROLAND)", label: "ADMINISTRATEUR DE SYSTEMES D'INFORMATION (CFA ASPECT UFA LYCEE PASTEUR MT ROLAND)" },
  { value: "DOCUMENTALISTE MULTIMEDIAS (INA)", label: "DOCUMENTALISTE MULTIMEDIAS (INA)" },
  { value: "CHARGE DE PROJET ENERGIE ET BATIMENT DURABLE (ASDER)", label: "CHARGE DE PROJET ENERGIE ET BATIMENT DURABLE (ASDER)" },
  { value: "CHEF DE PRODUITS TEXTILES (ISTA)", label: "CHEF DE PRODUITS TEXTILES (ISTA)" },
  { value: "RESPONSABLE MARKETING ET COMMUNICATION INTERACTIVE (ISIMI - POLE PARIS ALTERNANCE)", label: "RESPONSABLE MARKETING ET COMMUNICATION INTERACTIVE (ISIMI - POLE PARIS ALTERNANCE)" },
  { value: "CHARGE DE PROJETS COMMERCIAUX ET MARKETING A L'INTERNATIONAL (GROUPE SUP DE CO MONTPELLIER BUSINESS SCHOOL)", label: "CHARGE DE PROJETS COMMERCIAUX ET MARKETING A L'INTERNATIONAL (GROUPE SUP DE CO MONTPELLIER BUSINESS SCHOOL)" },
  { value: "RESPONSABLE DE LA GESTION DES RESSOURCES HUMAINES (IGS)", label: "RESPONSABLE DE LA GESTION DES RESSOURCES HUMAINES (IGS)" },
  { value: "ADMINISTRATEUR(TRICE) SYSTEMES RESEAUX ET BASES DE DONNEES (ADIP - IPI)", label: "ADMINISTRATEUR(TRICE) SYSTEMES RESEAUX ET BASES DE DONNEES (ADIP - IPI)" },
  { value: "RESPONSABLE DU TRANSPORT MULTIMODAL (PROMOTRANS)", label: "RESPONSABLE DU TRANSPORT MULTIMODAL (PROMOTRANS)" },
  { value: "RESPONSABLE QUALITE, ENVIRONNEMENT, SECURITE (INTERFOR SIA)", label: "RESPONSABLE QUALITE, ENVIRONNEMENT, SECURITE (INTERFOR SIA)" },
  { value: "CONCEPTEUR(TRICE) EN SYSTEMES MECANIQUES (ASSOC CFA LEONARD DE VINCI)", label: "CONCEPTEUR(TRICE) EN SYSTEMES MECANIQUES (ASSOC CFA LEONARD DE VINCI)" },
  { value: "RESPONSABLE DU DEVELOPPEMENT EN AGRO-INDUSTRIE (AIRFIC)", label: "RESPONSABLE DU DEVELOPPEMENT EN AGRO-INDUSTRIE (AIRFIC)" },
  { value: "CHEF DE PROJET EN MATERIAUX COMPOSITES (CTIPC - IPC COMPOSITEC)", label: "CHEF DE PROJET EN MATERIAUX COMPOSITES (CTIPC - IPC COMPOSITEC)" },
  { value: "NEGOCIATEUR D'AFFAIRES (ASSOC CFA LEONARD DE VINCI)", label: "NEGOCIATEUR D'AFFAIRES (ASSOC CFA LEONARD DE VINCI)" },
  { value: "RESPONSABLE MANAGEMENT OPERATIONNEL COMMERCIAL ET MARKETING (AIFP IDRAC)", label: "RESPONSABLE MANAGEMENT OPERATIONNEL COMMERCIAL ET MARKETING (AIFP IDRAC)" },
  { value: "RESPONSABLE TECHNICO-COMMERCIAL (AIPF)", label: "RESPONSABLE TECHNICO-COMMERCIAL (AIPF)" },
  { value: "ADMINISTRATEUR DE SYSTEMES ET RESEAUX (ADALES CFA LEONARD DE VINCI)", label: "ADMINISTRATEUR DE SYSTEMES ET RESEAUX (ADALES CFA LEONARD DE VINCI)" },
  { value: "MANAGER DE PROXIMITE DANS L'ECONOMIE SOCIALE ET SOLIDAIRE (IRUP)", label: "MANAGER DE PROXIMITE DANS L'ECONOMIE SOCIALE ET SOLIDAIRE (IRUP)" },
  { value: "RESPONSABLE DEVELOPPEMENT HYGIENE PROPRETE ET SERVICES (CPNEFP DE LA PROPRETE - OC PROPRETE)", label: "RESPONSABLE DEVELOPPEMENT HYGIENE PROPRETE ET SERVICES (CPNEFP DE LA PROPRETE - OC PROPRETE)" },
  { value: "RESPONSABLE D'ENTREPRISE D'ECONOMIE SOCIALE ET SOLIDAIRE (AROBASE)", label: "RESPONSABLE D'ENTREPRISE D'ECONOMIE SOCIALE ET SOLIDAIRE (AROBASE)" },
  { value: "CONCEPTEUR DEVELOPPEUR INFORMATIQUE (TP)", label: "CONCEPTEUR DEVELOPPEUR INFORMATIQUE (TP)" },
  { value: "MUSICIEN INTERVENANT (UNIVERSITES AIX-MARSEILLE, LILLE3, LYON 2, PARIS 11, POITIERS, RENNES 2, STRASBOURG, TOULOUSE 2, TOURS)", label: "MUSICIEN INTERVENANT (UNIVERSITES AIX-MARSEILLE, LILLE3, LYON 2, PARIS 11, POITIERS, RENNES 2, STRASBOURG, TOULOUSE 2, TOURS)" },
  { value: "RESPONSABLE D'ACTIVITES EN ENVIRONNEMENT NUCLEAIRE (IRUP)", label: "RESPONSABLE D'ACTIVITES EN ENVIRONNEMENT NUCLEAIRE (IRUP)" },
  { value: "RESPONSABLE DES OPERATIONS LOGISTIQUES (IUT AIX MARSEILLE)", label: "RESPONSABLE DES OPERATIONS LOGISTIQUES (IUT AIX MARSEILLE)" },
  { value: "RESPONSABLE EN QUALITE SECURITE ENVIRONNEMENT (KLM-ISEAM)", label: "RESPONSABLE EN QUALITE SECURITE ENVIRONNEMENT (KLM-ISEAM)" },
  { value: "RESPONSABLE PERFORMANCE INDUSTRIELLE (CESI)", label: "RESPONSABLE PERFORMANCE INDUSTRIELLE (CESI)" },
  { value: "RESPONSABLE QUALITE DES ENTREPRISES VITI-VINICOLES (MAISON RURALE DE VAYRES)", label: "RESPONSABLE QUALITE DES ENTREPRISES VITI-VINICOLES (MAISON RURALE DE VAYRES)" },
  { value: "ASSISTANT INGENIEUR EN BIOLOGIE - BIOCHIMIE - BIOTECHNOLOGIES (AFPICL, ESTBB)", label: "ASSISTANT INGENIEUR EN BIOLOGIE - BIOCHIMIE - BIOTECHNOLOGIES (AFPICL, ESTBB)" },
  { value: "TECHNICIEN SPECIALISE EN BIOPRODUCTION INDUSTRIELLE (GROUPE IMT)", label: "TECHNICIEN SPECIALISE EN BIOPRODUCTION INDUSTRIELLE (GROUPE IMT)" },
  { value: "RESPONSABLE EN GESTION ET DEVELOPPEMENT D'ENTREPRISE (IGS ESAM)", label: "RESPONSABLE EN GESTION ET DEVELOPPEMENT D'ENTREPRISE (IGS ESAM)" },
  { value: "CHEF DE PROJET MARKETING ET COMMERCIAL (ISEE)", label: "CHEF DE PROJET MARKETING ET COMMERCIAL (ISEE)" },
  { value: "MANAGER DE DE BUSINESS UNIT (ESA3)", label: "MANAGER DE DE BUSINESS UNIT (ESA3)" },
  { value: "RESPONSABLE EN MANAGEMENT D'UNITE ET DE PROJET (CESI)", label: "RESPONSABLE EN MANAGEMENT D'UNITE ET DE PROJET (CESI)" },
  { value: "RESPONSABLE OPERATIONNEL D'UNITE (GRENOBLE EM)", label: "RESPONSABLE OPERATIONNEL D'UNITE (GRENOBLE EM)" },
  { value: "RESPONSABLE DU DEVELOPPEMENT DE L'UNITE COMMERCIALE (IPAC)", label: "RESPONSABLE DU DEVELOPPEMENT DE L'UNITE COMMERCIALE (IPAC)" },
  { value: "RESPONSABLE OPERATIONNEL D'ACTVITE (IFAG)", label: "RESPONSABLE OPERATIONNEL D'ACTVITE (IFAG)" },
  { value: "RESPONSABLE DE PRODUCTION TRANSPORT LOGISTIQUE (AFT-IFTIM ISTELI)", label: "RESPONSABLE DE PRODUCTION TRANSPORT LOGISTIQUE (AFT-IFTIM ISTELI)" },
  { value: "CHARGE DES SERVICES GENERAUX ET LOGISTIQUE HUMANITAIRE (BIOFORCE DEVELOPPEMENT)", label: "CHARGE DES SERVICES GENERAUX ET LOGISTIQUE HUMANITAIRE (BIOFORCE DEVELOPPEMENT)" },
  { value: "RESPONSABLE DE PRODUCTION TRANSPORT DE PERSONNE (AFTRAL)", label: "RESPONSABLE DE PRODUCTION TRANSPORT DE PERSONNE (AFTRAL)" },
  { value: "DECLARANT(E) EN DOUANE ET CONSEIL (AFTRAL)", label: "DECLARANT(E) EN DOUANE ET CONSEIL (AFTRAL)" },
  { value: "RESPONSABLE DU DEVELOPPEMENT COMMERCIAL (ICD)", label: "RESPONSABLE DU DEVELOPPEMENT COMMERCIAL (ICD)" },
  { value: "RESPONSABLE DES ACHATS (CDAF FORMATION)", label: "RESPONSABLE DES ACHATS (CDAF FORMATION)" },
  { value: "RESPONSABLE TECHNICO COMMERCIAL FRANCE ET INTERNATIONAL (CTI)", label: "RESPONSABLE TECHNICO COMMERCIAL FRANCE ET INTERNATIONAL (CTI)" },
  { value: "CHARGE(E) DE LA DISTRIBUTION, DU MARKETING ET DE LA NEGOCIATION (CFQ - ISIFA)", label: "CHARGE(E) DE LA DISTRIBUTION, DU MARKETING ET DE LA NEGOCIATION (CFQ - ISIFA)" },
  { value: "RESPONSABLE MARKETING OPERATIONNEL (C3 INSTITUTE)", label: "RESPONSABLE MARKETING OPERATIONNEL (C3 INSTITUTE)" },
  { value: "GEMMOLOGUE EXPERT (EAC)", label: "GEMMOLOGUE EXPERT (EAC)" },
  { value: "RESPONSABLE D'AFFAIRES COMMERCIALES ET DU DEVELOPPEMENT A L'INTERNATIONAL (ISIMI - POLE PARIS ALTERNANCE)", label: "RESPONSABLE D'AFFAIRES COMMERCIALES ET DU DEVELOPPEMENT A L'INTERNATIONAL (ISIMI - POLE PARIS ALTERNANCE)" },
  { value: "RESPONSABLE OPERATIONNEL EN GRANDE DISTRIBUTION (CARREL FORMATION CONTINUE)", label: "RESPONSABLE OPERATIONNEL EN GRANDE DISTRIBUTION (CARREL FORMATION CONTINUE)" },
  { value: "RESPONSABLE DE PROJETS EN MARKETING (CCI DU CHER - ESTACOM)", label: "RESPONSABLE DE PROJETS EN MARKETING (CCI DU CHER - ESTACOM)" },
  { value: "ANIMATEUR E-COMMERCE (FORMAOUEST)", label: "ANIMATEUR E-COMMERCE (FORMAOUEST)" },
  { value: "RESPONSABLE DEVELOPPEMENT COMMERCIAL ET MARKETING EN ENTREORISES DU SPORT (CCI PAU BEARN - CNPC SPORTS LOISIRS)", label: "RESPONSABLE DEVELOPPEMENT COMMERCIAL ET MARKETING EN ENTREORISES DU SPORT (CCI PAU BEARN - CNPC SPORTS LOISIRS)" },
  { value: "RESPONSABLE DE CLIENTELE BANQUE, FINANCE, ASSURANCE (ECORIS, ICADEMIS EDITIONS, IFC)", label: "RESPONSABLE DE CLIENTELE BANQUE, FINANCE, ASSURANCE (ECORIS, ICADEMIS EDITIONS, IFC)" },
  { value: "RESPONSABLE D'AFFAIRES EN IMMOBILIER (ECORIS, ICADEMIE EDIITIONS, IFC)", label: "RESPONSABLE D'AFFAIRES EN IMMOBILIER (ECORIS, ICADEMIE EDIITIONS, IFC)" },
  { value: "CONSEILLER FINANCIER (SOFTEC)", label: "CONSEILLER FINANCIER (SOFTEC)" },
  { value: "GESTIONNAIRE D'AFFAIRES IMMOBILIERES (ESPI - ENTREPRISES)", label: "GESTIONNAIRE D'AFFAIRES IMMOBILIERES (ESPI - ENTREPRISES)" },
  { value: "CHARGE(E) DE CLIENTELE - ASSURANCE ET BANQUE (ESA)", label: "CHARGE(E) DE CLIENTELE - ASSURANCE ET BANQUE (ESA)" },
  { value: "CONSEILLER EN GESTION DE PATRIMOINE ET ASSURANCES (IFPASS)", label: "CONSEILLER EN GESTION DE PATRIMOINE ET ASSURANCES (IFPASS)" },
  { value: "NEGOCIATEUR-CONSEIL EN PATRIMOINE IMMOBILIER ET FINANCIER (SCIENCES-U LYON)", label: "NEGOCIATEUR-CONSEIL EN PATRIMOINE IMMOBILIER ET FINANCIER (SCIENCES-U LYON)" },
  { value: "GESTIONNAIRE D'ACTIFS ET DE PATRIMOINES IMMOBILIERS (SCIENCES-U LILLE LYON ET PARIS)", label: "GESTIONNAIRE D'ACTIFS ET DE PATRIMOINES IMMOBILIERS (SCIENCES-U LILLE LYON ET PARIS)" },
  { value: "CHARGE DE CLIENTELE EN ASSURANCES DE PERSONNES ET PRODUITS FINANCIERS (SOGESTE GROUPE ESCCOT)", label: "CHARGE DE CLIENTELE EN ASSURANCES DE PERSONNES ET PRODUITS FINANCIERS (SOGESTE GROUPE ESCCOT)" },
  { value: "CHARGE(E) DES RESSOURCES HUMAINES (SUP DES RH)", label: "CHARGE(E) DES RESSOURCES HUMAINES (SUP DES RH)" },
  { value: "CONSULTANT RECRUTEMENT (CCI PARIS IDF, SUP DE VENTE, CCI LYON METROPOLE ST ETIENNE ROANNE, ESMAE)", label: "CONSULTANT RECRUTEMENT (CCI PARIS IDF, SUP DE VENTE, CCI LYON METROPOLE ST ETIENNE ROANNE, ESMAE)" },
  { value: "RESPONSABLE RESSOURCES HUMAINES (CESI)", label: "RESPONSABLE RESSOURCES HUMAINES (CESI)" },
  { value: "RESPONSABLE DE GESTION DES RESSOURCES HUMAINES (IPAC)", label: "RESPONSABLE DE GESTION DES RESSOURCES HUMAINES (IPAC)" },
  { value: "CHARGE(E) DE LA GESTION DES RESSOURCES HUMAINES (ISGP-FACLIP)", label: "CHARGE(E) DE LA GESTION DES RESSOURCES HUMAINES (ISGP-FACLIP)" },
  { value: "CHARGE DES RESSOURCES HUMAINES (ISIMI - POLE PARIS ALTERNANCE)", label: "CHARGE DES RESSOURCES HUMAINES (ISIMI - POLE PARIS ALTERNANCE)" },
  { value: "CHARGE DES RESSOURCES HUMAINES (ISIFA - CFQ)", label: "CHARGE DES RESSOURCES HUMAINES (ISIFA - CFQ)" },
  { value: "RESPONSABLE EN RESSOURCES HUMAINES (GESCEP, ESM-A)", label: "RESPONSABLE EN RESSOURCES HUMAINES (GESCEP, ESM-A)" },
  { value: "RESPONSABLE DU POLE PAIE (EUROFORMATION - BC DEVELOPPEMENT)", label: "RESPONSABLE DU POLE PAIE (EUROFORMATION - BC DEVELOPPEMENT)" },
  { value: "RESPONSABLE DE LA GESTION DE LA PAIE ET DU SOCIAL (IPAC)", label: "RESPONSABLE DE LA GESTION DE LA PAIE ET DU SOCIAL (IPAC)" },
  { value: "RESPONSABLE DE LA GESTION DES RESSOURCES HUMAINES (ORT LYON)", label: "RESPONSABLE DE LA GESTION DES RESSOURCES HUMAINES (ORT LYON)" },
  { value: "RESPONSABLE EN RESSOURCES HUMAINES (ASSOC ST YVES UCO)", label: "RESPONSABLE EN RESSOURCES HUMAINES (ASSOC ST YVES UCO)" },
  { value: "CHARGE(E) D'ADMINISTRATION DES RESSOURCES HUMAINES (CIEFA PARIS)", label: "CHARGE(E) D'ADMINISTRATION DES RESSOURCES HUMAINES (CIEFA PARIS)" },
  { value: "RESPONSABLE EN GESTION DES RELATIONS SOCIALES (SOFTEC)", label: "RESPONSABLE EN GESTION DES RELATIONS SOCIALES (SOFTEC)" },
  { value: "RESPONSABLE PROJET COMMUNICATION INTERNE/EXTERNE (ISCPA)", label: "RESPONSABLE PROJET COMMUNICATION INTERNE/EXTERNE (ISCPA)" },
  { value: "CHEF DE PROJET MULTIMEDIA (ECAD CONSULTANTS IESA)", label: "CHEF DE PROJET MULTIMEDIA (ECAD CONSULTANTS IESA)" },
  { value: "CHEF(FE) DE PROJET E.BUSINESS (EIMP)", label: "CHEF(FE) DE PROJET E.BUSINESS (EIMP)" },
  { value: "CONCEPTEUR EN COMMUNICATION VISUELLE (IDEM)", label: "CONCEPTEUR EN COMMUNICATION VISUELLE (IDEM)" },
  { value: "CONCEPTEUR ET REALISATEUR NUMERIQUE D'ANIMATION 2D/3D (IDEM)", label: "CONCEPTEUR ET REALISATEUR NUMERIQUE D'ANIMATION 2D/3D (IDEM)" },
  { value: "CHEF DE PROJETS EVENEMENTIELS (SCIENCES-U PARIS - ESUPCOM)", label: "CHEF DE PROJETS EVENEMENTIELS (SCIENCES-U PARIS - ESUPCOM)" },
  { value: "CHARGE DE LA COMMUNICATION (CCI DU CHER - ESTACOM)", label: "CHARGE DE LA COMMUNICATION (CCI DU CHER - ESTACOM)" },
  { value: "COMMUNITY MANAGER (IFOCOP)", label: "COMMUNITY MANAGER (IFOCOP)" },
  { value: "RESPONSABLE PROJET MARKETING COMMUNICATION (SCIENCES-U LILLE EFFICOM)", label: "RESPONSABLE PROJET MARKETING COMMUNICATION (SCIENCES-U LILLE EFFICOM)" },
  { value: "CHEF(FE) DE PROJETS MARKETING ET COMMUNICATION (ESA3)", label: "CHEF(FE) DE PROJETS MARKETING ET COMMUNICATION (ESA3)" },
  { value: "JOURNALISTE PLURIMEDIA (EJT)", label: "JOURNALISTE PLURIMEDIA (EJT)" },
  { value: "CONCEPTEUR(TRICE) DESIGNER EN COMMUNICATION GRAPHIQUE ECORESPONSABLE (ASSOC CAMPUS FONDERIE DE L'IMAGE)", label: "CONCEPTEUR(TRICE) DESIGNER EN COMMUNICATION GRAPHIQUE ECORESPONSABLE (ASSOC CAMPUS FONDERIE DE L'IMAGE)" },
  { value: "GRAPHISTE MOTION DESIGNER (CCIP GOBELINS ECOLE DE L'IMAGE)", label: "GRAPHISTE MOTION DESIGNER (CCIP GOBELINS ECOLE DE L'IMAGE)" },
  { value: "DESIGNER NUMERIQUE (ICAN)", label: "DESIGNER NUMERIQUE (ICAN)" },
  { value: "GESTIONNAIRE DE L'INFORMATION (EBD)", label: "GESTIONNAIRE DE L'INFORMATION (EBD)" },
  { value: "ADMINISTRATEUR(TRICE) SYSTEMES, RESEAUX ET SECURITE (AFTI)", label: "ADMINISTRATEUR(TRICE) SYSTEMES, RESEAUX ET SECURITE (AFTI)" },
  { value: "ANALYSTE INFORMATICIEN (AFCEPF)", label: "ANALYSTE INFORMATICIEN (AFCEPF)" },
  { value: "RESPONSABLE EN INGENIERIE RESEAUX (CESI)", label: "RESPONSABLE EN INGENIERIE RESEAUX (CESI)" },
  { value: "CHEF DE PROJET EN INFORMATIQUE (CESI)", label: "CHEF DE PROJET EN INFORMATIQUE (CESI)" },
  { value: "CHEF/FE DE PROJET LOGICIEL ET RESEAUX (SAS ANAPIJ -ESGI)", label: "CHEF/FE DE PROJET LOGICIEL ET RESEAUX (SAS ANAPIJ -ESGI)" },
  { value: "RESPONSABLE DE PROJETS INFORMATIQUES (FONDATION LA MACHE PARTNER FORMATION)", label: "RESPONSABLE DE PROJETS INFORMATIQUES (FONDATION LA MACHE PARTNER FORMATION)" },
  { value: "ADMINISTRATEUR (TRICE) DU SYSTEME D'INFORMATION (IP-FORMATION IPSSI)", label: "ADMINISTRATEUR (TRICE) DU SYSTEME D'INFORMATION (IP-FORMATION IPSSI)" },
  { value: "CHEF(FE) DE PROJET DIGITAL (IEF21)", label: "CHEF(FE) DE PROJET DIGITAL (IEF21)" },
  { value: "CHARGE(E) DE PROJETS EN SYSTEMES INFORMATIQUES APPLIQUES (CRESPA - GROUPE SCIENCES U)", label: "CHARGE(E) DE PROJETS EN SYSTEMES INFORMATIQUES APPLIQUES (CRESPA - GROUPE SCIENCES U)" },
  { value: "RESPONSABLE EN STRATEGIE DIGITALE ET GESTION DE PROJET (EFREI PARIS)", label: "RESPONSABLE EN STRATEGIE DIGITALE ET GESTION DE PROJET (EFREI PARIS)" },
  { value: "ADMINISTRATEUR(TRICE) SYSTEMES ET RESEAUX (DORANCO)", label: "ADMINISTRATEUR(TRICE) SYSTEMES ET RESEAUX (DORANCO)" },
  { value: "DEVELOPPEUR(EUSE) D'APPLICATION (OPENCLASSROOMS)", label: "DEVELOPPEUR(EUSE) D'APPLICATION (OPENCLASSROOMS)" },
  { value: "CHEF DE PROJETS DIGITAUX (EEMI)", label: "CHEF DE PROJETS DIGITAUX (EEMI)" },
  { value: "CHEF DE PROJETS DIGITAL (OPEN CLASSROOMS)", label: "CHEF DE PROJETS DIGITAL (OPEN CLASSROOMS)" },
  { value: "RESPONSABLE EN REFRACTION ET EQUIPEMENT OPTIQUE (ISV)", label: "RESPONSABLE EN REFRACTION ET EQUIPEMENT OPTIQUE (ISV)" },
  { value: "RESPONSABLE EN CUISINE ET RESTAURATION (INSTITUT PAUL BOCUSE)", label: "RESPONSABLE EN CUISINE ET RESTAURATION (INSTITUT PAUL BOCUSE)" },
  { value: "RESPONSABLE INTERNATIONAL DE L'HOTELLERIE ET DE LA RESTAURATION (INSTITUT PAUL BOCUSE)", label: "RESPONSABLE INTERNATIONAL DE L'HOTELLERIE ET DE LA RESTAURATION (INSTITUT PAUL BOCUSE)" },
  { value: "RESPONSABLE DE LA COMMUNICATION DANS L'HOTELLERIE ET LE TOURISME (IETH -CMH)", label: "RESPONSABLE DE LA COMMUNICATION DANS L'HOTELLERIE ET LE TOURISME (IETH -CMH)" },
  { value: "RESPONSABLE D'ACTIVITE OU D'ENTREPRISES TOURISTIQUES (IPAC)", label: "RESPONSABLE D'ACTIVITE OU D'ENTREPRISES TOURISTIQUES (IPAC)" },
  { value: "GEOLOGIE APPLIQUEE (BTS)", label: "GEOLOGIE APPLIQUEE (BTS)" },
  { value: "TRANSPORT ET PRESTATION LOGISTIQUES (BTS)/GESTION DES TRANSPORTS ET LOGISTIQUE ASSOCIEE (BTS)", label: "TRANSPORT ET PRESTATION LOGISTIQUES (BTS)/GESTION DES TRANSPORTS ET LOGISTIQUE ASSOCIEE (BTS)" },
  { value: "MANAGEMENT DES UNITES COMMERCIALES - MUC (BTS)/ MANAGEMENT COMMERCIAL OPERATIONNEL - MCO (BTS)", label: "MANAGEMENT DES UNITES COMMERCIALES - MUC (BTS)/ MANAGEMENT COMMERCIAL OPERATIONNEL - MCO (BTS)" },
  { value: "TOURISME (BTS)", label: "TOURISME (BTS)" },
  { value: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (DEUST PARIS 11)", label: "BIO-INDUSTRIES ET BIOTECHNOLOGIES (DEUST PARIS 11)" },
  { value: "SYSTEMES D'INFORMATION, NUMERIQUE ET ELECTRONIQUE (DEUST PARIS 6)", label: "SYSTEMES D'INFORMATION, NUMERIQUE ET ELECTRONIQUE (DEUST PARIS 6)" },
  { value: "PRODUCTION, CONTROLE ET QUALITE DES PRODUITS DE SANTE (DEUST BORDEAUX)", label: "PRODUCTION, CONTROLE ET QUALITE DES PRODUITS DE SANTE (DEUST BORDEAUX)" },
  { value: "SYSTEMES D'INFORMATION ET RESEAUX, GESTION ET DEVELOPPEMENT (DEUST PARIS 2)", label: "SYSTEMES D'INFORMATION ET RESEAUX, GESTION ET DEVELOPPEMENT (DEUST PARIS 2)" },
  { value: "TRAVAIL SOCIAL, METIERS DE L'AMENAGEMENT ET DES ACTIVITES SOCIALES (MAAS) ECONOMIE SOCIALE ET SOLIDAIRE (ESS) (DEUST EVRY)", label: "TRAVAIL SOCIAL, METIERS DE L'AMENAGEMENT ET DES ACTIVITES SOCIALES (MAAS) ECONOMIE SOCIALE ET SOLIDAIRE (ESS) (DEUST EVRY)" },
  { value: "CHARGE DE PROJET CAO ET PLM (CCI COLMAR CENTRE ALSACE)", label: "CHARGE DE PROJET CAO ET PLM (CCI COLMAR CENTRE ALSACE)" },
  { value: "ASSISTANT(E) TECHNIQUE DES LABORATOIRES PARFUM COSMETIQUE ET AROMES (CCIP IDF ISIPCA)", label: "ASSISTANT(E) TECHNIQUE DES LABORATOIRES PARFUM COSMETIQUE ET AROMES (CCIP IDF ISIPCA)" },
  { value: "TECHNICIEN DE BUREAU D'ETUDES RESEAUX NUMERIQUES (CCIT MANCHE FIM NOVEA)", label: "TECHNICIEN DE BUREAU D'ETUDES RESEAUX NUMERIQUES (CCIT MANCHE FIM NOVEA)" },
  { value: "CHARGE DE LA GESTION ET DE L'ACTIVITE COMMERCIALE DE L'ENTREPRISE (CCI SEINE ET MARNE)", label: "CHARGE DE LA GESTION ET DE L'ACTIVITE COMMERCIALE DE L'ENTREPRISE (CCI SEINE ET MARNE)" },
  { value: "ATTACHE(E) COMMERCIAL(E) (CCI FRANCE - RESEAU NEGOVENTIS)", label: "ATTACHE(E) COMMERCIAL(E) (CCI FRANCE - RESEAU NEGOVENTIS)" },
  { value: "GESTIONNAIRE COMMERCIAL, SPECIALISE SPORT (CNPC SPORT LOISIRS)", label: "GESTIONNAIRE COMMERCIAL, SPECIALISE SPORT (CNPC SPORT LOISIRS)" },
  { value: "GESTIONNAIRE D'UNITE COMMERCIALE, OPTION GENERALISTE - OPTION SPECIALISEE (CCI FRANCE - RESEAU NEGOVENTIS)", label: "GESTIONNAIRE D'UNITE COMMERCIALE, OPTION GENERALISTE - OPTION SPECIALISEE (CCI FRANCE - RESEAU NEGOVENTIS)" },
  { value: "DECORATEUR MERCHANDISEUR (CCI FRANCE - RESEAU ESDC)", label: "DECORATEUR MERCHANDISEUR (CCI FRANCE - RESEAU ESDC)" },
  { value: "NEGOCIATEUR GESTIONNAIRE IMMOBILIER (CCI LIMOGES)", label: "NEGOCIATEUR GESTIONNAIRE IMMOBILIER (CCI LIMOGES)" },
  { value: "ASSISTANT DE GESTION ET RECRUTEMENT (CCI PARIS IDF, SUP DE VENTE,CCI LYON METROPOLE ST ETIENNE ROANNE, ESMAE)", label: "ASSISTANT DE GESTION ET RECRUTEMENT (CCI PARIS IDF, SUP DE VENTE,CCI LYON METROPOLE ST ETIENNE ROANNE, ESMAE)" },
  { value: "ASSISTANT(E) EN RESSOURCES HUMAINES (CCI FRANCE - RESEAU ESMASS)", label: "ASSISTANT(E) EN RESSOURCES HUMAINES (CCI FRANCE - RESEAU ESMASS)" },
  { value: "GRAPHISTE EN COMMUNICATION MULTICANAL (CCI HAUTS DE FRANCE)", label: "GRAPHISTE EN COMMUNICATION MULTICANAL (CCI HAUTS DE FRANCE)" },
  { value: "ASSISTANT(E) DE DIRECTION (CCI FRANCE - RESEAU ESMASS)", label: "ASSISTANT(E) DE DIRECTION (CCI FRANCE - RESEAU ESMASS)" },
  { value: "CHARGE D'EXPLOITATION EN RESEAUX ET TELECOMS (CCI ET CAUX)", label: "CHARGE D'EXPLOITATION EN RESEAUX ET TELECOMS (CCI ET CAUX)" },
  { value: "DEVELOPPEUR INTEGRATEUR DE SOLUTIONS INTRANET EXTRANET (CCI COLMAR)", label: "DEVELOPPEUR INTEGRATEUR DE SOLUTIONS INTRANET EXTRANET (CCI COLMAR)" },
  { value: "ANALYSTE DEVELOPPEUR D'APPLICATIONS INFORMATIQUES (CCI FRANCE - RESEAU ESI)", label: "ANALYSTE DEVELOPPEUR D'APPLICATIONS INFORMATIQUES (CCI FRANCE - RESEAU ESI)" },
  { value: "ASSISTANT MANAGER TOURISME HOTELLERIE RESTAURATION (CCI FRANCE - RESEAU NEGOVENTIS)", label: "ASSISTANT MANAGER TOURISME HOTELLERIE RESTAURATION (CCI FRANCE - RESEAU NEGOVENTIS)" },
  { value: "TECHNICIEN DE MAINTENANCE DU PARC EOLIEN ON SHORE (LYCEE DHUODAT GRETA NIME)", label: "TECHNICIEN DE MAINTENANCE DU PARC EOLIEN ON SHORE (LYCEE DHUODAT GRETA NIME)" },
  { value: "REGISSEUR (SSEUSE) LUMIERE (CFPTS)", label: "REGISSEUR (SSEUSE) LUMIERE (CFPTS)" },
  { value: "FLEURISTE (BM APCMA)", label: "FLEURISTE (BM APCMA)" },
  { value: "PATISSIER CONFISEUR GLACIER TRAITEUR (BM APCMA)", label: "PATISSIER CONFISEUR GLACIER TRAITEUR (BM APCMA)" },
  { value: "BOULANGER (BM APCMA)", label: "BOULANGER (BM APCMA)" },
  { value: "BOUCHER-CHARCUTIER-TRAITEUR (BM APCMA)", label: "BOUCHER-CHARCUTIER-TRAITEUR (BM APCMA)" },
  { value: "INSTALLATEUR DE SYSTEMES DE GENIE CLIMATIQUE (BM APCMA)", label: "INSTALLATEUR DE SYSTEMES DE GENIE CLIMATIQUE (BM APCMA)" },
  { value: "EBENISTE (BTMS APCMA)", label: "EBENISTE (BTMS APCMA)" },
  { value: "MENUISIER DE BATIMENT ET D'AGENCEMENT (BM APCMA)", label: "MENUISIER DE BATIMENT ET D'AGENCEMENT (BM APCMA)" },
  { value: "INSTALLATEUR EN EQUIPEMENTS ELECTRIQUES (BM APCMA)", label: "INSTALLATEUR EN EQUIPEMENTS ELECTRIQUES (BM APCMA)" },
  { value: "PROTHESISTE DENTAIRE (BTMS APCMA)", label: "PROTHESISTE DENTAIRE (BTMS APCMA)" },
  { value: "TRAITEUR-ORGANISATEUR DE RECEPTION (BM APCMA)", label: "TRAITEUR-ORGANISATEUR DE RECEPTION (BM APCMA)" },
  { value: "COIFFEUR (BM APCMA)", label: "COIFFEUR (BM APCMA)" },
  { value: "ESTHETICIEN(NE) COSMETICIEN(NE) (BM APCMA)", label: "ESTHETICIEN(NE) COSMETICIEN(NE) (BM APCMA)" },
  { value: "CHARGE DE GESTION TECHNIQUE DES BATIMENTS TERTIAIRES ET INDUSTRIELS (IRUP)", label: "CHARGE DE GESTION TECHNIQUE DES BATIMENTS TERTIAIRES ET INDUSTRIELS (IRUP)" },
  { value: "DEVELOPPEUR(EUSE) INTEGRATEUR(TRICE) DE MEDIAS INTERACTIFS (ASSOC CAMPUS FONDERIE DE L'IMAGE)", label: "DEVELOPPEUR(EUSE) INTEGRATEUR(TRICE) DE MEDIAS INTERACTIFS (ASSOC CAMPUS FONDERIE DE L'IMAGE)" },
  { value: "RESPONSABLE TECHNIQUE EN BATIMENT ET DES TRAVAUX PUBLICS (CCI GRENOBLE-ISCO)", label: "RESPONSABLE TECHNIQUE EN BATIMENT ET DES TRAVAUX PUBLICS (CCI GRENOBLE-ISCO)" },
  { value: "VENDEUR(SE) CONSEIL EN VOYAGE D'AFFAIRES ET DE TOURISME (AFT-IFTIM)", label: "VENDEUR(SE) CONSEIL EN VOYAGE D'AFFAIRES ET DE TOURISME (AFT-IFTIM)" },
  { value: "CHARGE DES SYSTEMES NUMERIQUES INDUSTRIELS (IRUP)", label: "CHARGE DES SYSTEMES NUMERIQUES INDUSTRIELS (IRUP)" },
  { value: "TECHNICIEN SUPERIEUR EN PHARMACIE ET COSMETOLOGIE INDUSTRIELLES (GROUPE IMT)", label: "TECHNICIEN SUPERIEUR EN PHARMACIE ET COSMETOLOGIE INDUSTRIELLES (GROUPE IMT)" },
  { value: "TECHNICIEN(NE) SUPERIEUR(E) DE MAINTENANCE INDUSTRIELLE (TP)", label: "TECHNICIEN(NE) SUPERIEUR(E) DE MAINTENANCE INDUSTRIELLE (TP)" },
  { value: "TECHNICIEN(NE) SUPERIEUR(E) EN AUTOMATIQUE ET INFORMATIQUE INDUSTRIELLE (TP)", label: "TECHNICIEN(NE) SUPERIEUR(E) EN AUTOMATIQUE ET INFORMATIQUE INDUSTRIELLE (TP)" },
  { value: "TECHNICIEN SUPERIEUR PHYSICIEN CHIMISTE (TP)", label: "TECHNICIEN SUPERIEUR PHYSICIEN CHIMISTE (TP)" },
  { value: "TECHNICIEN SUPERIEUR DE MAINTENANCE ET D'EXPLOITATION EN CLIMATIQUE (TP)", label: "TECHNICIEN SUPERIEUR DE MAINTENANCE ET D'EXPLOITATION EN CLIMATIQUE (TP)" },
  { value: "TECHNICIEN SUPERIEUR DU BATIMENT EN ECONOMIE DE LA CONSTRUCTION (TP)", label: "TECHNICIEN SUPERIEUR DU BATIMENT EN ECONOMIE DE LA CONSTRUCTION (TP)" },
  { value: "CONDUCTEUR DE TRAVAUX DU BATIMENT ET DU GENIE CIVIL (TP)", label: "CONDUCTEUR DE TRAVAUX DU BATIMENT ET DU GENIE CIVIL (TP)" },
  { value: "CONDUCTEUR DE TRAVAUX PUBLICS ROUTE, CANALISATION, TERRASSEMENT (TP)", label: "CONDUCTEUR DE TRAVAUX PUBLICS ROUTE, CANALISATION, TERRASSEMENT (TP)" },
  { value: "TECHNICIEN SUPERIEUR GEOMETRE TOPOGRAPHE OPTION GEOMETRE ET TRAVAUX PUBLICS (TP)", label: "TECHNICIEN SUPERIEUR GEOMETRE TOPOGRAPHE OPTION GEOMETRE ET TRAVAUX PUBLICS (TP)" },
  { value: "CHEF DE CHANTIER GROS OEUVRE (TP)", label: "CHEF DE CHANTIER GROS OEUVRE (TP)" },
  { value: "TECHNICIEN(NE) SUPERIEUR(E) METHODES PRODUIT PROCESS (TP)", label: "TECHNICIEN(NE) SUPERIEUR(E) METHODES PRODUIT PROCESS (TP)" },
  { value: "GESTIONNAIRE DE PETITE OU MOYENNE STRUCTURE (TP)/RESPONSABLE DE PETITE ET MOYENNE STRUCTURE (TP)", label: "GESTIONNAIRE DE PETITE OU MOYENNE STRUCTURE (TP)/RESPONSABLE DE PETITE ET MOYENNE STRUCTURE (TP)" },
  { value: "TECHNICIEN SUPERIEUR EN METHODES ET EXPLOITATION LOGISTIQUE (TP)", label: "TECHNICIEN SUPERIEUR EN METHODES ET EXPLOITATION LOGISTIQUE (TP)" },
  { value: "TECHNICIEN SUPERIEUR EN TRANSPORTS LOGISTIQUES (TP 2 OPTIONS)", label: "TECHNICIEN SUPERIEUR EN TRANSPORTS LOGISTIQUES (TP 2 OPTIONS)" },
  { value: "TECHNICIEN(NE) SUPERIEUR(E) DES TRANSPORTS DE PERSONNES (TP)", label: "TECHNICIEN(NE) SUPERIEUR(E) DES TRANSPORTS DE PERSONNES (TP)" },
  { value: "TECHNICIEN(NE) SUPERIEUR(E) DU TRANSPORT TERRESTRE DE MARCHANDISES (TP)", label: "TECHNICIEN(NE) SUPERIEUR(E) DU TRANSPORT TERRESTRE DE MARCHANDISES (TP)" },
  { value: "TECHNICIEN(NE) SUPERIEUR(E) DU TRANSPORT AERIEN ET MARITIME DE MARCHANDISES (TP)", label: "TECHNICIEN(NE) SUPERIEUR(E) DU TRANSPORT AERIEN ET MARITIME DE MARCHANDISES (TP)" },
  { value: "MANAGER D'UNIVERS MARCHAND (TP)", label: "MANAGER D'UNIVERS MARCHAND (TP)" },
  { value: "ASSISTANT(E) IMPORT-EXPORT BILINGUE ANGLAIS (TP)", label: "ASSISTANT(E) IMPORT-EXPORT BILINGUE ANGLAIS (TP)" },
  { value: "NEGOCIATEUR (TRICE) TECHNICO-COMMERCIAL (TP)", label: "NEGOCIATEUR (TRICE) TECHNICO-COMMERCIAL (TP)" },
  { value: "ETALAGISTE DECORATEUR MERCHANDISEUR (CNPH)", label: "ETALAGISTE DECORATEUR MERCHANDISEUR (CNPH)" },
  { value: "COMPTABLE GESTIONNAIRE (TP)", label: "COMPTABLE GESTIONNAIRE (TP)" },
  { value: "GESTIONNAIRE DE PAIE (TP)", label: "GESTIONNAIRE DE PAIE (TP)" },
  { value: "ASSISTANT(E) RESSOURCES HUMAINES (TP)", label: "ASSISTANT(E) RESSOURCES HUMAINES (TP)" },
  { value: "DESIGNER WEB (TP)", label: "DESIGNER WEB (TP)" },
  { value: "ASSISTANTE DE DIRECTION (TP)", label: "ASSISTANTE DE DIRECTION (TP)" },
  { value: "ASSISTANTE(T) COMMERCIAL(E) (TP)", label: "ASSISTANTE(T) COMMERCIAL(E) (TP)" },
  { value: "TECHNICIEN(NE) SUPERIEUR(E) GESTIONNAIRE EXPLOITANT(E) DE RESSOURCES INFORMATIQUES (TP)", label: "TECHNICIEN(NE) SUPERIEUR(E) GESTIONNAIRE EXPLOITANT(E) DE RESSOURCES INFORMATIQUES (TP)" },
  { value: "TECHNICIEN (NE) SUPERIEUR(E) EN RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS D'ENTREPRISE (TP)", label: "TECHNICIEN (NE) SUPERIEUR(E) EN RESEAUX INFORMATIQUES ET TELECOMMUNICATIONS D'ENTREPRISE (TP)" },
  { value: "TECHNICIEN SUPERIEUR SYSTEMES ET RESEAUX (TP)", label: "TECHNICIEN SUPERIEUR SYSTEMES ET RESEAUX (TP)" },
  { value: "DEVELOPPEUR WEB ET WEB MOBILE (TP)", label: "DEVELOPPEUR WEB ET WEB MOBILE (TP)" },
  { value: "CONSEILLER EN INTERTION PROFESSIONNELLE (TP)", label: "CONSEILLER EN INTERTION PROFESSIONNELLE (TP)" },
  { value: "FORMATEUR(TRICE) PROFESSIONNEL(E) D'ADULTES (TP)", label: "FORMATEUR(TRICE) PROFESSIONNEL(E) D'ADULTES (TP)" },
  { value: "RESPONSABLE D'ETABLISSEMENT TOURISTIQUE (TP)", label: "RESPONSABLE D'ETABLISSEMENT TOURISTIQUE (TP)" },
  { value: "ANIMATEUR QUALITE SECURITE ENVIRONNEMENT ( CESI)", label: "ANIMATEUR QUALITE SECURITE ENVIRONNEMENT ( CESI)" },
  { value: "GESTIONNAIRE EN ORGANISATION ET PERFORMANCE INDUSTRIELLE (CESI)", label: "GESTIONNAIRE EN ORGANISATION ET PERFORMANCE INDUSTRIELLE (CESI)" },
  { value: "CONDUCTEUR DE TRAVAUX EN ENTREPRISES DE TRAVAUX AGRICOLES (FEDERATION NATIONALE DES ENTREPRENEURS DES TERRITOIRES)", label: "CONDUCTEUR DE TRAVAUX EN ENTREPRISES DE TRAVAUX AGRICOLES (FEDERATION NATIONALE DES ENTREPRENEURS DES TERRITOIRES)" },
  { value: "RESPONSABLE DE CHANTIER BATIMENT ET TRAVAUX PUBLICS (CESI)", label: "RESPONSABLE DE CHANTIER BATIMENT ET TRAVAUX PUBLICS (CESI)" },
  { value: "CHARGE DE MAINTENANCE EN ENVIRONNEMENT NUCLEAIRE (IRUP)", label: "CHARGE DE MAINTENANCE EN ENVIRONNEMENT NUCLEAIRE (IRUP)" },
  { value: "ASSISTANT(E) WEBMARKETING (SCIENCES-U LILLE - EFFICOM)", label: "ASSISTANT(E) WEBMARKETING (SCIENCES-U LILLE - EFFICOM)" },
  { value: "CHARGE(E) DE CLIENTELE (CIEFA RHONE ALPES)", label: "CHARGE(E) DE CLIENTELE (CIEFA RHONE ALPES)" },
  { value: "COLLABORATEUR COMPTABLE (SOFTEC)", label: "COLLABORATEUR COMPTABLE (SOFTEC)" },
  { value: "ASSISTANT(E) RESSOURCES HUMAINES (CESI)", label: "ASSISTANT(E) RESSOURCES HUMAINES (CESI)" },
  { value: "COLLABORATEUR(TRICE) SOCIAL ET PAIE (SOFTEC AVENIR FORMATION)", label: "COLLABORATEUR(TRICE) SOCIAL ET PAIE (SOFTEC AVENIR FORMATION)" },
  { value: "GESTIONNAIRE DE PAIE (CIEFA RHONE ALPES)", label: "GESTIONNAIRE DE PAIE (CIEFA RHONE ALPES)" },
  { value: "DEVELOPPEUR WEB (CESI)", label: "DEVELOPPEUR WEB (CESI)" },
  { value: "REGISSEUR DU SPECTACLE (ISTS)", label: "REGISSEUR DU SPECTACLE (ISTS)" },
  { value: "ANIMATEUR(TRICE) MUSICAL ET SCENIQUE (UCPA)", label: "ANIMATEUR(TRICE) MUSICAL ET SCENIQUE (UCPA)" },
  { value: "BIBLIOTHECAIRE DOCUMENTALISTE (EBD)", label: "BIBLIOTHECAIRE DOCUMENTALISTE (EBD)" },
  { value: "GESTIONNAIRE EN MAINTENANCE ET SUPPORT INFORMATIQUE (CESI)", label: "GESTIONNAIRE EN MAINTENANCE ET SUPPORT INFORMATIQUE (CESI)" },
  { value: "TECHNICIEN(E) SYSTEMES ET RESEAUX (CESI)", label: "TECHNICIEN(E) SYSTEMES ET RESEAUX (CESI)" },
  { value: "TECHNICIEN(NE) SYSTEMES, RESEAUX ET SECURITE (ADIP - IPI)", label: "TECHNICIEN(NE) SYSTEMES, RESEAUX ET SECURITE (ADIP - IPI)" },
  { value: "DEVELOPPEUR INTEGRATEUR EN REALISATION D'APPLICATIONS WEB (3W ACADEMY)", label: "DEVELOPPEUR INTEGRATEUR EN REALISATION D'APPLICATIONS WEB (3W ACADEMY)" },
  { value: "CHARGE(E) DE DEVELEPPEMENT EN OENOTOURISME (INSTITUT RURAL DE VAYRES)", label: "CHARGE(E) DE DEVELEPPEMENT EN OENOTOURISME (INSTITUT RURAL DE VAYRES)" },
  { value: "ENTRAINEUR DE FOOTBALL (FFF)", label: "ENTRAINEUR DE FOOTBALL (FFF)" },
  { value: "CHEF D'EQUIPE EN SECURITE PRIVEE (FORMAPLUS 3B)", label: "CHEF D'EQUIPE EN SECURITE PRIVEE (FORMAPLUS 3B)" },
  { value: "COMMERCE (BAC PRO)/METIERS DU COMMERCE ET DE LA VENTE OPTION A ANIMATION ET GESTION DE L'ESPACE COMMERCIAL (BAC PRO)", label: "COMMERCE (BAC PRO)/METIERS DU COMMERCE ET DE LA VENTE OPTION A ANIMATION ET GESTION DE L'ESPACE COMMERCIAL (BAC PRO)" },
  { value: "VENTE (PROSPECTION - NEGOCIATION - SUIVI DE CLIENTELE) (BAC PRO)/METIERS DU COMMERCE ET DE LA VENTE OPTION B PROSPECTION CLIENTELE ET VALORISATION DE L'OFFRE COMMERCIALE (BAC PRO)", label: "VENTE (PROSPECTION - NEGOCIATION - SUIVI DE CLIENTELE) (BAC PRO)/METIERS DU COMMERCE ET DE LA VENTE OPTION B PROSPECTION CLIENTELE ET VALORISATION DE L'OFFRE COMMERCIALE (BAC PRO)" },
  { value: "ACCUEIL - RELATION CLIENTS ET USAGERS (BAC PRO)/METIERS DE L'ACCUEIL (BAC PRO)", label: "ACCUEIL - RELATION CLIENTS ET USAGERS (BAC PRO)/METIERS DE L'ACCUEIL (BAC PRO)" },
  { value: "ARTS DE LA RELIURE ET DE LA DORURE (BMA)", label: "ARTS DE LA RELIURE ET DE LA DORURE (BMA)" },
  { value: "PRODUCTIONS HORTICOLES (BAC PRO AG)/CONDUITE DE PRODUCTIONS HORTICOLES (BAC PRO AG)", label: "PRODUCTIONS HORTICOLES (BAC PRO AG)/CONDUITE DE PRODUCTIONS HORTICOLES (BAC PRO AG)" },
  { value: "ESTHETIQUE COSMETIQUE PARFUMERIE (BP)", label: "ESTHETIQUE COSMETIQUE PARFUMERIE (BP)" },
  { value: "ANIMATEUR EN GERONTOLOGIE (UNMFREO)", label: "ANIMATEUR EN GERONTOLOGIE (UNMFREO)" },
  { value: "PREPARATEUR(TRICE) EN PARFUM, COSMETIQUE ET AROMATIQUE (ISIPCA)", label: "PREPARATEUR(TRICE) EN PARFUM, COSMETIQUE ET AROMATIQUE (ISIPCA)" },
  { value: "CHEF D'EQUIPE CONSTRUCTION DURABLE (CCIT DE LA DROME)", label: "CHEF D'EQUIPE CONSTRUCTION DURABLE (CCIT DE LA DROME)" },
  { value: "TECHNICIEN(NE) DE MAINTENANCE DES ASCENSEURS (CCIP IDF CFI)", label: "TECHNICIEN(NE) DE MAINTENANCE DES ASCENSEURS (CCIP IDF CFI)" },
  { value: "TECHNICIEN RESEAUX ET SERVICE TRES HAUT DEBIT (INNOVANCE)", label: "TECHNICIEN RESEAUX ET SERVICE TRES HAUT DEBIT (INNOVANCE)" },
  { value: "VENDEUR CONSEILLER SPORT (CCI PAU BEARN CNPC SPORTS LOISIRS)", label: "VENDEUR CONSEILLER SPORT (CCI PAU BEARN CNPC SPORTS LOISIRS)" },
  { value: "VENDEUR CONSEILLER COMMERCIAL (ACFCI RESEAU NEGOVENTIS)", label: "VENDEUR CONSEILLER COMMERCIAL (ACFCI RESEAU NEGOVENTIS)" },
  { value: "CREATEUR D'AMBIANCES LUDIQUES ARTISTIQUES SPORTIVES (CCI MILLAU)", label: "CREATEUR D'AMBIANCES LUDIQUES ARTISTIQUES SPORTIVES (CCI MILLAU)" },
  { value: "CONSEILLER DE VENTE EN PARFUMERIE ET COSMETIQUE (SIADEP, CCI LYON METROPOLE ST ETIENNE ROANNE)", label: "CONSEILLER DE VENTE EN PARFUMERIE ET COSMETIQUE (SIADEP, CCI LYON METROPOLE ST ETIENNE ROANNE)" },
  { value: "TECHNICIEN RESEAU GAZ (GIP FTLV NANCY)", label: "TECHNICIEN RESEAU GAZ (GIP FTLV NANCY)" },
  { value: "MONTEUR TECHNICIEN EN RESEAUX ELECTRIQUES (LP CLEMENT ADER)", label: "MONTEUR TECHNICIEN EN RESEAUX ELECTRIQUES (LP CLEMENT ADER)" },
  { value: "FLEURISTE (BTM APCMA)", label: "FLEURISTE (BTM APCMA)" },
  { value: "GLACIER FABRICANT (BTM APCMA)", label: "GLACIER FABRICANT (BTM APCMA)" },
  { value: "CHOCOLATIER CONFISEUR (BTM APCMA)", label: "CHOCOLATIER CONFISEUR (BTM APCMA)" },
  { value: "PATISSIER CONFISEUR GLACIER TRAITEUR (BTM APCMA)", label: "PATISSIER CONFISEUR GLACIER TRAITEUR (BTM APCMA)" },
  { value: "TECHNICIEN EN SYSTEMES DE GENIE CLIMATIQUE OPTION INSTALLATEUR DE SYSTEMES DE GENIE CLIMATIQUE (BTM APCMA)", label: "TECHNICIEN EN SYSTEMES DE GENIE CLIMATIQUE OPTION INSTALLATEUR DE SYSTEMES DE GENIE CLIMATIQUE (BTM APCMA)" },
  { value: "PEINTRE EN BATIMENT (BTM APCMA)", label: "PEINTRE EN BATIMENT (BTM APCMA)" },
  { value: "EBENISTE (BTM APCMA)", label: "EBENISTE (BTM APCMA)" },
  { value: "TAPISSIER DECORATEUR OPTION COUTURE OPTIONS COUTURE ET GARNITURE (BTM APCMA)", label: "TAPISSIER DECORATEUR OPTION COUTURE OPTIONS COUTURE ET GARNITURE (BTM APCMA)" },
  { value: "CORDONNIER (BTM APCMA)", label: "CORDONNIER (BTM APCMA)" },
  { value: "MECANICIEN DE MATERIELS AGRICOLES (BTM APCMA)", label: "MECANICIEN DE MATERIELS AGRICOLES (BTM APCMA)" },
  { value: "INSTALLATEUR EN EQUIPEMENTS ELECTRIQUES (BTM APCMA)", label: "INSTALLATEUR EN EQUIPEMENTS ELECTRIQUES (BTM APCMA)" },
  { value: "ASSISTANT DE DIRIGEANT D'ENTREPRISE ARTISANALE (BCCEA APCMA)", label: "ASSISTANT DE DIRIGEANT D'ENTREPRISE ARTISANALE (BCCEA APCMA)" },
  { value: "ENCADRANT D'ENTREPRISE ARTISANALE (APCMA)", label: "ENCADRANT D'ENTREPRISE ARTISANALE (APCMA)" },
  { value: "PHOTOGRAPHE (BTM APCMA)", label: "PHOTOGRAPHE (BTM APCMA)" },
  { value: "PROTHESISTE DENTAIRE (BTM APCMA)", label: "PROTHESISTE DENTAIRE (BTM APCMA)" },
  { value: "MAINTENICIEN EN MICRO INFORMATIQUE ET RESEAUX (IGS IPI)", label: "MAINTENICIEN EN MICRO INFORMATIQUE ET RESEAUX (IGS IPI)" },
  { value: "TECHNICIEN(NE) DE MAINTENANCE EN MICRO INFORMATIQUE (ACTIF CNT)", label: "TECHNICIEN(NE) DE MAINTENANCE EN MICRO INFORMATIQUE (ACTIF CNT)" },
  { value: "MAQUETTISTE INFOGRAPHISTE MULTIMEDIA (ASSOC CAMPUS FONDERIE DE L'IMAGE)", label: "MAQUETTISTE INFOGRAPHISTE MULTIMEDIA (ASSOC CAMPUS FONDERIE DE L'IMAGE)" },
  { value: "TECHNICIEN EN RADIOPROTECTION (INSTN CEA)", label: "TECHNICIEN EN RADIOPROTECTION (INSTN CEA)" },
  { value: "TECHNICIEN (NE) DE REPARATION DES ASCENCEURS (AFP)", label: "TECHNICIEN (NE) DE REPARATION DES ASCENCEURS (AFP)" },
  { value: "OPERATEUR DE CIRCULATION FERROVIAIRE (SNCF)", label: "OPERATEUR DE CIRCULATION FERROVIAIRE (SNCF)" },
  { value: "AGENT D'ESCALE FERROVIAIRE (SNCF MOBILITES)", label: "AGENT D'ESCALE FERROVIAIRE (SNCF MOBILITES)" },
  { value: "CAVISTE CONSEILLER FRANCE COMMERCIAL (CCI MORBIHAN)", label: "CAVISTE CONSEILLER FRANCE COMMERCIAL (CCI MORBIHAN)" },
  { value: "VENDEUR-AGENCEUR DE CUISINES ET DE SALLE DE BAINS (AFPIA)", label: "VENDEUR-AGENCEUR DE CUISINES ET DE SALLE DE BAINS (AFPIA)" },
  { value: "TECHNICIEN EN PHARMACIE ET COSMETIQUE INDUSTRIELLE (GROUPE IMT)", label: "TECHNICIEN EN PHARMACIE ET COSMETIQUE INDUSTRIELLE (GROUPE IMT)" },
  { value: "TECHNICIEN DE MAINTENANCE INDUSTRIELLE (TP)", label: "TECHNICIEN DE MAINTENANCE INDUSTRIELLE (TP)" },
  { value: "TECHNICIEN(NE) DE PRODUCTION EN PLASTURGIE (TP)", label: "TECHNICIEN(NE) DE PRODUCTION EN PLASTURGIE (TP)" },
  { value: "TECHNICIEN(NE) D'INTERVENTION EN FROID COMMERCIAL ET CLIMATISATION (TP)", label: "TECHNICIEN(NE) D'INTERVENTION EN FROID COMMERCIAL ET CLIMATISATION (TP)" },
  { value: "TECHNICIEN(NE) D'INTERVENTION EN FROID ET EQUIPEMENTS DE CUINES PROFESIONNELLES (TP)", label: "TECHNICIEN(NE) D'INTERVENTION EN FROID ET EQUIPEMENTS DE CUINES PROFESIONNELLES (TP)" },
  { value: "TECHNICIEN(NE) D'INTERVENTION EN FROID INDUSTRIEL (TP)", label: "TECHNICIEN(NE) D'INTERVENTION EN FROID INDUSTRIEL (TP)" },
  { value: "TECHNICIEN(NE) DE MAINTENANCE D'EQUIPEMENTS DE CONFORT CLIMATIQUE (TP)", label: "TECHNICIEN(NE) DE MAINTENANCE D'EQUIPEMENTS DE CONFORT CLIMATIQUE (TP)" },
  { value: "TECHNICIEN DE MAINTENANCE DES EQUIPEMENTS THERMIQUES (TP)", label: "TECHNICIEN DE MAINTENANCE DES EQUIPEMENTS THERMIQUES (TP)" },
  { value: "TECHNICIEN EN ECONOMIE DE LA CONSTRUCTION ET ETUDE DE PRIX (TP)", label: "TECHNICIEN EN ECONOMIE DE LA CONSTRUCTION ET ETUDE DE PRIX (TP)" },
  { value: "TECHNICIEN D'ETUDES DU BATIMENT EN DESSIN DE PROJET (TP)", label: "TECHNICIEN D'ETUDES DU BATIMENT EN DESSIN DE PROJET (TP)" },
  { value: "CHEF D'EQUIPE GROS OEUVRE (TP)", label: "CHEF D'EQUIPE GROS OEUVRE (TP)" },
  { value: "CHEF D'EQUIPE AMENAGEMENT FINITIONS (TP)", label: "CHEF D'EQUIPE AMENAGEMENT FINITIONS (TP)" },
  { value: "TECHNICIEN ETUDES EN CONSTRUCTION BOIS (TP)", label: "TECHNICIEN ETUDES EN CONSTRUCTION BOIS (TP)" },
  { value: "TECHNICIEN(NE) DE MAINTENANCE EN MULTIMEDIA ET ELECTRODOMESTIQUE (TP)", label: "TECHNICIEN(NE) DE MAINTENANCE EN MULTIMEDIA ET ELECTRODOMESTIQUE (TP)" },
  { value: "TECHNICIEN(NE) D'USINAGE EN COMMANDE NUMERIQUE (TP)", label: "TECHNICIEN(NE) D'USINAGE EN COMMANDE NUMERIQUE (TP)" },
  { value: "TECHNICIEN(NE) DE MAINTENANCE D'ENGINS ET DE MATERIELS - MACHINISME AGRICOLE (TP)", label: "TECHNICIEN(NE) DE MAINTENANCE D'ENGINS ET DE MATERIELS - MACHINISME AGRICOLE (TP)" },
  { value: "TECHNICIEN(NE) DE MAINTENANCE D'ENGINS ET DE MATERIELS DE CHANTIER ET DE MANUTENTION (TP)", label: "TECHNICIEN(NE) DE MAINTENANCE D'ENGINS ET DE MATERIELS DE CHANTIER ET DE MANUTENTION (TP)" },
  { value: "TECHNICIEN(NE) DE CONTROLE NON DESTRUCTIF (TP)", label: "TECHNICIEN(NE) DE CONTROLE NON DESTRUCTIF (TP)" },
  { value: "CHAUDRONNIER (ERE)", label: "CHAUDRONNIER (ERE)" },
  { value: "TECHNICIEN DES RESEAUX CABLES DE COMMUNICATIONS (TP)", label: "TECHNICIEN DES RESEAUX CABLES DE COMMUNICATIONS (TP)" },
  { value: "TECHNICIEN DE MAINTENANCE ET DE TRAVAUX EN SYSTEMES DE SECURITE INCENDIE (TP)", label: "TECHNICIEN DE MAINTENANCE ET DE TRAVAUX EN SYSTEMES DE SECURITE INCENDIE (TP)" },
  { value: "TECHNICIEN D'EQUIPEMENT EN ELECTRICITE (TP)", label: "TECHNICIEN D'EQUIPEMENT EN ELECTRICITE (TP)" },
  { value: "TECHNICIEN EN ELECTRICITE ET AUTOMATISME DU BATIMENT (TP)", label: "TECHNICIEN EN ELECTRICITE ET AUTOMATISME DU BATIMENT (TP)" },
  { value: "TECHNICIEN(NE) EN LOGISTIQUE D'ENTREPOSAGE (TP)", label: "TECHNICIEN(NE) EN LOGISTIQUE D'ENTREPOSAGE (TP)" },
  { value: "TECHNICIEN(NE) D'EXPLOITATION EN TRANSPORTS TERRESTRES DE MARCHANDISES (TP)", label: "TECHNICIEN(NE) D'EXPLOITATION EN TRANSPORTS TERRESTRES DE MARCHANDISES (TP)" },
  { value: "CONSEILLER RELATION CLIENT A DISTANCE (TP)", label: "CONSEILLER RELATION CLIENT A DISTANCE (TP)" },
  { value: "COMMERCIAL(E) (TP)", label: "COMMERCIAL(E) (TP)" },
  { value: "VENDEUR CONSEIL EN MAGASIN (TP)", label: "VENDEUR CONSEIL EN MAGASIN (TP)" },
  { value: "RESPONSABLE DE RAYON (TP)", label: "RESPONSABLE DE RAYON (TP)" },
  { value: "COMPTABLE ASSISTANT(E) (TP)", label: "COMPTABLE ASSISTANT(E) (TP)" },
  { value: "INFOGRAPHISTE METTEUR EN PAGE (TP)", label: "INFOGRAPHISTE METTEUR EN PAGE (TP)" },
  { value: "SECRETAIRE ASSISTANTE MEDICO-SOCIALE (TP)", label: "SECRETAIRE ASSISTANTE MEDICO-SOCIALE (TP)" },
  { value: "SECRETAIRE ASSISTANT(E) (TP)", label: "SECRETAIRE ASSISTANT(E) (TP)" },
  { value: "SECRETAIRE COMPTABLE (TP)", label: "SECRETAIRE COMPTABLE (TP)" },
  { value: "SECRETAIRE ASSISTANT(E) IMMOBILIER (TP)", label: "SECRETAIRE ASSISTANT(E) IMMOBILIER (TP)" },
  { value: "TECHNICIEN(NE) D'ASSISTANCE EN INFORMATIQUE (TP)", label: "TECHNICIEN(NE) D'ASSISTANCE EN INFORMATIQUE (TP)" },
  { value: "CONSEILLER(ERE) EN SEJOURS ET VOYAGES (TP)", label: "CONSEILLER(ERE) EN SEJOURS ET VOYAGES (TP)" },
  { value: "TECHNICIEN D'ACCUEIL TOURISTIQUE 2 OPTIONs : ACCOMPAGNEMENT, ANIMATION (TP)", label: "TECHNICIEN D'ACCUEIL TOURISTIQUE 2 OPTIONs : ACCOMPAGNEMENT, ANIMATION (TP)" },
  { value: "ACCOMPAGNATEUR (TRICE) DE TOURISME (TP)", label: "ACCOMPAGNATEUR (TRICE) DE TOURISME (TP)" },
  { value: "TECHNICIEN(NE) DE TRAITEMENT DES EAUX (TP)", label: "TECHNICIEN(NE) DE TRAITEMENT DES EAUX (TP)" },
  { value: "ARTISTE DANSEUR (AFMDCC)", label: "ARTISTE DANSEUR (AFMDCC)" },
  { value: "ARTISTE INTERPRETE DE MUSIC-ALL SPECIALITE DANSEUR(EUSE) OU SPECIALITE FANTAISIE (INST NATIONAL DES ARTS DU MUSIC-ALL)", label: "ARTISTE INTERPRETE DE MUSIC-ALL SPECIALITE DANSEUR(EUSE) OU SPECIALITE FANTAISIE (INST NATIONAL DES ARTS DU MUSIC-ALL)" },
  { value: "OPERATEUR(TRICE) EXTERIEUR(E) DES INDUSTRIES PETROLIERES ET PETROCHIMIQUES (BREVET D'OPERATEUR(TRICE) IFP TRAINING)", label: "OPERATEUR(TRICE) EXTERIEUR(E) DES INDUSTRIES PETROLIERES ET PETROCHIMIQUES (BREVET D'OPERATEUR(TRICE) IFP TRAINING)" },
  { value: "TECHNICIEN DE PRODUCTION DES MATERIAUX POUR LA CONSTRUCTION ET L'INDUSTRIE (UNICEM)", label: "TECHNICIEN DE PRODUCTION DES MATERIAUX POUR LA CONSTRUCTION ET L'INDUSTRIE (UNICEM)" },
  { value: "INTERVENANT TECHNIQUE EN ENVIRONNEMENT NUCLEAIRE (IRUP)", label: "INTERVENANT TECHNIQUE EN ENVIRONNEMENT NUCLEAIRE (IRUP)" },
  { value: "TECHNICIEN(NE) DE MAINTENANCE EN GENIE CLIMATIQUE (LYCEE MAXIMILEN PERRET - GRETA MTI)", label: "TECHNICIEN(NE) DE MAINTENANCE EN GENIE CLIMATIQUE (LYCEE MAXIMILEN PERRET - GRETA MTI)" },
  { value: "METREUR (EUSE) PEINTURE FINITION (GPPF FORMATION)", label: "METREUR (EUSE) PEINTURE FINITION (GPPF FORMATION)" },
  { value: "CONSEILLER TECHNIQUE CYCLES (ANFA)", label: "CONSEILLER TECHNIQUE CYCLES (ANFA)" },
  { value: "VENDEUR DE L'ELECTRODOMESTIQUE ET DU MULTIMEDIA (RESEAU DUCRETE)", label: "VENDEUR DE L'ELECTRODOMESTIQUE ET DU MULTIMEDIA (RESEAU DUCRETE)" },
  { value: "CONSEILLER(E) SERVICES EN ELECTRODOMESTIQUE ET MULTIMEDIA (RESEAU DECRETE ACTIF CNT)", label: "CONSEILLER(E) SERVICES EN ELECTRODOMESTIQUE ET MULTIMEDIA (RESEAU DECRETE ACTIF CNT)" },
  { value: "TECHNICIEN(NE) SERVICES DE L'ELECTRODOMESTIQUE CONNECTE (RESEAU DUCRETET)", label: "TECHNICIEN(NE) SERVICES DE L'ELECTRODOMESTIQUE CONNECTE (RESEAU DUCRETET)" },
  { value: "TECHNICIEN(NE) SERVICES DE LA MAISON CONNECTEE (RESEAU DUCRETET)", label: "TECHNICIEN(NE) SERVICES DE LA MAISON CONNECTEE (RESEAU DUCRETET)" },
  { value: "CONDUCTEUR DE TRAIN (SNCF MOBILITES)", label: "CONDUCTEUR DE TRAIN (SNCF MOBILITES)" },
  { value: "ASSISTANT SON EN POSTPRODUCTION ET STUDIO D'ENREGISTREMENT (INFA)", label: "ASSISTANT SON EN POSTPRODUCTION ET STUDIO D'ENREGISTREMENT (INFA)" },
  { value: "TECHNICIEN DU SPECTACLE VIVANT OPTIONS LUMIERES SON OU MACHINERIE (STAFF)", label: "TECHNICIEN DU SPECTACLE VIVANT OPTIONS LUMIERES SON OU MACHINERIE (STAFF)" },
  { value: "TECHNICIEN D'EXPLOITATION D'EQUIPEMENTS CULTURELS (3IS SUP)", label: "TECHNICIEN D'EXPLOITATION D'EQUIPEMENTS CULTURELS (3IS SUP)" },
  { value: "TECHNICIEN D'EXPLOITATION CINEMATOGRAPHIQUE (AFOMAV)", label: "TECHNICIEN D'EXPLOITATION CINEMATOGRAPHIQUE (AFOMAV)" },
  { value: "SECRETAIRE MEDICAL(E) (SOSIM)", label: "SECRETAIRE MEDICAL(E) (SOSIM)" },
  { value: "MONITEUR DE FOOTBALL (FFF)", label: "MONITEUR DE FOOTBALL (FFF)" },
  { value: "MONITEUR DE NATATION (FFN)", label: "MONITEUR DE NATATION (FFN)" },
  { value: "PATISSIER (CAP)", label: "PATISSIER (CAP)" },
  { value: "CONSTRUCTEUR EN CANALISATIONS DES TRAVAUX PUBLICS (CAP)/CONSTRUCTEUR DE RESEAUX DE CANALISATIONS DE TRAVAUX PUBLICS (CAP)", label: "CONSTRUCTEUR EN CANALISATIONS DES TRAVAUX PUBLICS (CAP)/CONSTRUCTEUR DE RESEAUX DE CANALISATIONS DE TRAVAUX PUBLICS (CAP)" },
  { value: "CARRELEUR MOSAISTE (CAP)", label: "CARRELEUR MOSAISTE (CAP)" },
  { value: "COIFFURE (CAP)/METIERS DE LA COIFFURE (CAP)", label: "COIFFURE (CAP)/METIERS DE LA COIFFURE (CAP)" },
  { value: "OPERATEUR DE TRANSFORMATION INDUSTRIELLE DES VIANDES (IFRIA BRETAGNE)", label: "OPERATEUR DE TRANSFORMATION INDUSTRIELLE DES VIANDES (IFRIA BRETAGNE)" },
  { value: "CONDUCTEUR DE MATERIEL DE COLLECTE OU DE NETTOIEMMENT OU D'ASSAINISSEMNT (GIP FCIP CAFOC TOULOUSE)", label: "CONDUCTEUR DE MATERIEL DE COLLECTE OU DE NETTOIEMMENT OU D'ASSAINISSEMNT (GIP FCIP CAFOC TOULOUSE)" },
  { value: "AGENT DE NETTOIEMENT DANS L'ESPACE PUBLIC URBAIN (GIP FCIP DE TOULOUSE)", label: "AGENT DE NETTOIEMENT DANS L'ESPACE PUBLIC URBAIN (GIP FCIP DE TOULOUSE)" },
  { value: "AGENT DES SERVICES DE GESTION ET DE VALORISATION DES DECHETS (GIP FORMAVIE)", label: "AGENT DES SERVICES DE GESTION ET DE VALORISATION DES DECHETS (GIP FORMAVIE)" },
  { value: "BREVET D'APTITUDE PROFESSIONNELLE D'ASSISTANT ANIMATEUR TECHNICIEN (BAPAAT)", label: "BREVET D'APTITUDE PROFESSIONNELLE D'ASSISTANT ANIMATEUR TECHNICIEN (BAPAAT)" },
  { value: "TOILETTEUR(EUSE) CANIN (FFATA)", label: "TOILETTEUR(EUSE) CANIN (FFATA)" },
  { value: "TOILETTEUR CANIN ET FELIN (CTM APCMA)", label: "TOILETTEUR CANIN ET FELIN (CTM APCMA)" },
  { value: "PREPARATEUR(TRICE) VENDEUR(SE) OPTION BOUCHERIE (CTM APCMA)", label: "PREPARATEUR(TRICE) VENDEUR(SE) OPTION BOUCHERIE (CTM APCMA)" },
  { value: "PREPARATEUR(TRICE) VENDEUR(SE) OPTION CHARCUTERIE-TRAITEUR (CTM APCMA)", label: "PREPARATEUR(TRICE) VENDEUR(SE) OPTION CHARCUTERIE-TRAITEUR (CTM APCMA)" },
  { value: "VENDEUR EN BOULANGERIE-PATISSERIE (CTM APCMA)", label: "VENDEUR EN BOULANGERIE-PATISSERIE (CTM APCMA)" },
  { value: "INSTALLATEUR SANITAIRE ET THERMIQUE (BCP CHAMBRE DES METIERS D'ALSACE)", label: "INSTALLATEUR SANITAIRE ET THERMIQUE (BCP CHAMBRE DES METIERS D'ALSACE)" },
  { value: "MONTEUR EN CHEMINEE, EN POELE, FUMISTE (APCMA)", label: "MONTEUR EN CHEMINEE, EN POELE, FUMISTE (APCMA)" },
  { value: "RAMONEUR (CTM APCMA)", label: "RAMONEUR (CTM APCMA)" },
  { value: "PEINTRE APPLICATEUR DE REVETEMENTS (BCP CHAMBRE DES METIERS D'ALSACE)", label: "PEINTRE APPLICATEUR DE REVETEMENTS (BCP CHAMBRE DES METIERS D'ALSACE)" },
  { value: "ASSISTANT PHOTOGRAPHE (CTM)", label: "ASSISTANT PHOTOGRAPHE (CTM)" },
  { value: "AUXILIAIRE EN PROTHESE DENTAIRE (CTM APCMA)", label: "AUXILIAIRE EN PROTHESE DENTAIRE (CTM APCMA)" },
  { value: "CREPIER (CTM APCMA)", label: "CREPIER (CTM APCMA)" },
  { value: "POSEUR-AGENCEUR DE CUISINES ET SALLES DE BAINS (AFPIA OUEST)", label: "POSEUR-AGENCEUR DE CUISINES ET SALLES DE BAINS (AFPIA OUEST)" },
  { value: "OUVRIER DE PRODUCTION HORTICOLE ORNEMENTALE (TP)", label: "OUVRIER DE PRODUCTION HORTICOLE ORNEMENTALE (TP)" },
  { value: "CUISINIER (ERE) (TP)", label: "CUISINIER (ERE) (TP)" },
  { value: "CONDUCTEUR D'APPAREILS DE L'INDUSTRIE CHIMIQUE (TP)", label: "CONDUCTEUR D'APPAREILS DE L'INDUSTRIE CHIMIQUE (TP)" },
  { value: "PLASTURGISTE DES SEMI-OUVRES (TP)", label: "PLASTURGISTE DES SEMI-OUVRES (TP)" },
  { value: "STRATIFIEUR MULTIPROCEDES EN MATERIAUX COMPOSITES (TP)", label: "STRATIFIEUR MULTIPROCEDES EN MATERIAUX COMPOSITES (TP)" },
  { value: "OPERATEUR COMPOSITE HAUTES PERFORMANCES (TP)", label: "OPERATEUR COMPOSITE HAUTES PERFORMANCES (TP)" },
  { value: "AGENT DE MAINTENANCE EN CHAUFFAGE (TP)", label: "AGENT DE MAINTENANCE EN CHAUFFAGE (TP)" },
  { value: "METREUR (TP)", label: "METREUR (TP)" },
  { value: "CONDUCTEUR DE PELLE HYDRAULIQUE ET DE CHARGEUSE PELLETEUSE (TP)", label: "CONDUCTEUR DE PELLE HYDRAULIQUE ET DE CHARGEUSE PELLETEUSE (TP)" },
  { value: "MACON EN VOIRIE ET RESEAUX DIVERS (TP)", label: "MACON EN VOIRIE ET RESEAUX DIVERS (TP)" },
  { value: "CANALISATEUR (TP)", label: "CANALISATEUR (TP)" },
  { value: "MACON (TP)", label: "MACON (TP)" },
  { value: "MACON DU BATI ANCIEN (TP)", label: "MACON DU BATI ANCIEN (TP)" },
  { value: "COFFREUR BANCHEUR (TP)", label: "COFFREUR BANCHEUR (TP)" },
  { value: "COUVREUR-ZINGUEUR (TP)", label: "COUVREUR-ZINGUEUR (TP)" },
  { value: "PEINTRE EN DECOR (TP)", label: "PEINTRE EN DECOR (TP)" },
  { value: "SOLIER MOQUETTISTE (TP)", label: "SOLIER MOQUETTISTE (TP)" },
  { value: "PEINTRE EN BATIMENT (TP)", label: "PEINTRE EN BATIMENT (TP)" },
  { value: "PLAQUISTE PLATRIER (TP)", label: "PLAQUISTE PLATRIER (TP)" },
  { value: "CARRELEUR (TP)", label: "CARRELEUR (TP)" },
  { value: "FACADIER-PEINTRE (TP)", label: "FACADIER-PEINTRE (TP)" },
  { value: "MENUISIER DE FABRICATION BATIMENT AMEUBLEMENT (TP)", label: "MENUISIER DE FABRICATION BATIMENT AMEUBLEMENT (TP)" },
  { value: "CHARPENTIER BOIS (TP 3 OPTIONS)", label: "CHARPENTIER BOIS (TP 3 OPTIONS)" },
  { value: "MONTEUR EN CONSTRUCTION BOIS (TP)", label: "MONTEUR EN CONSTRUCTION BOIS (TP)" },
  { value: "POSEUR INSTALLATEUR DE MENUISERIES, FERMETURES ET EQUIPEMENTS (TP)", label: "POSEUR INSTALLATEUR DE MENUISERIES, FERMETURES ET EQUIPEMENTS (TP)" },
  { value: "PIQUEUR EN MAROQUINERIE (TP)", label: "PIQUEUR EN MAROQUINERIE (TP)" },
  { value: "ELECTRICIEN DE MAINTENANCE DES SYSTEMES AUTOMATISES (TP)", label: "ELECTRICIEN DE MAINTENANCE DES SYSTEMES AUTOMATISES (TP)" },
  { value: "FRAISEUR(EUSE) SUR MACHINE CONVENTIONNELLES ET A COMMANDE NUMERIQUES (TP)", label: "FRAISEUR(EUSE) SUR MACHINE CONVENTIONNELLES ET A COMMANDE NUMERIQUES (TP)" },
  { value: "TOURNEUR(EUSE) SUR MACHINES CONVENTIONNELLES ET A COMMANDES NUMERIQUES (TP)", label: "TOURNEUR(EUSE) SUR MACHINES CONVENTIONNELLES ET A COMMANDES NUMERIQUES (TP)" },
  { value: "AGENT(E) DE FABRICATION INDUSTRIELLE (TP)", label: "AGENT(E) DE FABRICATION INDUSTRIELLE (TP)" },
  { value: "OPERATEUR(TRICE) REGLEUR(EUSE) D'USINAGE EN COMMANDE NUMERIQUE (TP)", label: "OPERATEUR(TRICE) REGLEUR(EUSE) D'USINAGE EN COMMANDE NUMERIQUE (TP)" },
  { value: "CONDUCTEUR(TRICE) D'INSTALLATIONS ET DE MACHINES AUTOMATISEES (TP)", label: "CONDUCTEUR(TRICE) D'INSTALLATIONS ET DE MACHINES AUTOMATISEES (TP)" },
  { value: "MONTEUR(EUSE) DE STRUCTURES AERONAUTIQUES METALLIQUES ET COMPOSITES (TP)", label: "MONTEUR(EUSE) DE STRUCTURES AERONAUTIQUES METALLIQUES ET COMPOSITES (TP)" },
  { value: "SOUDEUR (SE) (TP)", label: "SOUDEUR (SE) (TP)" },
  { value: "TUYAUTEUR(SE) INDUSTRIEL(E) (TP)", label: "TUYAUTEUR(SE) INDUSTRIEL(E) (TP)" },
  { value: "INSTALLATEUR DE RESEAUX CABLES DE COMMUNICATION (TP)", label: "INSTALLATEUR DE RESEAUX CABLES DE COMMUNICATION (TP)" },
  { value: "MONTEUR DE RESEAUX ELECTRIQUES AERO-SOUTERRAINS (TP)", label: "MONTEUR DE RESEAUX ELECTRIQUES AERO-SOUTERRAINS (TP)" },
  { value: "CONDUCTEUR DU TRANSPORT ROUTIER MARCHANDISES SUR TOUS VEHICULES (TP)", label: "CONDUCTEUR DU TRANSPORT ROUTIER MARCHANDISES SUR TOUS VEHICULES (TP)" },
  { value: "CONDUCTEUR DU TRANSPORT ROUTIER MARCHANDISES SUR PORTEUR (TP)", label: "CONDUCTEUR DU TRANSPORT ROUTIER MARCHANDISES SUR PORTEUR (TP)" },
  { value: "PREPARATEUR(TRICE) DE COMMANDES EN ENTREPOT (TP)", label: "PREPARATEUR(TRICE) DE COMMANDES EN ENTREPOT (TP)" },
  { value: "AGENT(E) MAGASINIER (ERE) (TP)", label: "AGENT(E) MAGASINIER (ERE) (TP)" },
  { value: "CARISTE D'ENTREPOT (TP)", label: "CARISTE D'ENTREPOT (TP)" },
  { value: "CONDUCTEUR(TRICE) LIVREUR(SE) SUR VEHICULE UTILITAIRE LEGER (TP)", label: "CONDUCTEUR(TRICE) LIVREUR(SE) SUR VEHICULE UTILITAIRE LEGER (TP)" },
  { value: "CONDUCTEUR DE TRANSPORT EN COMMUN SUR ROUTE (TP)", label: "CONDUCTEUR DE TRANSPORT EN COMMUN SUR ROUTE (TP)" },
  { value: "CONDUCTEUR(TRICE) DU TRANSPORT ROUTIER INTERURBAIN DE VOYAGEURS (TP)", label: "CONDUCTEUR(TRICE) DU TRANSPORT ROUTIER INTERURBAIN DE VOYAGEURS (TP)" },
  { value: "CONDUCTEUR LIVREUR SUR VEHICULE UTILITAIRE LEGER (TP)", label: "CONDUCTEUR LIVREUR SUR VEHICULE UTILITAIRE LEGER (TP)" },
  { value: "EMPLOYE(E) COMMERCIAL(E) EN MAGASIN (TP)", label: "EMPLOYE(E) COMMERCIAL(E) EN MAGASIN (TP)" },
  { value: "AGENT ADMINISTRATIF (TP)", label: "AGENT ADMINISTRATIF (TP)" },
  { value: "EMPLOYE(E) ADMINISTRATIF ET D'ACCUEIL (TP)", label: "EMPLOYE(E) ADMINISTRATIF ET D'ACCUEIL (TP)" },
  { value: "ASSISTANT(E) DE VIE AUX FAMILLES (TP)", label: "ASSISTANT(E) DE VIE AUX FAMILLES (TP)" },
  { value: "AUXILIAIRE EN PROTHESE DENTAIRE (TP)", label: "AUXILIAIRE EN PROTHESE DENTAIRE (TP)" },
  { value: "AGENT DE MEDIATION EN INFORMATION ET SERVICES (TP)", label: "AGENT DE MEDIATION EN INFORMATION ET SERVICES (TP)" },
  { value: "SERVEUR (SE) EN RESTAURATION (TP)", label: "SERVEUR (SE) EN RESTAURATION (TP)" },
  { value: "AGENT(E) DE RESTAURATION (TP)", label: "AGENT(E) DE RESTAURATION (TP)" },
  { value: "AGENT(E) D'HOTELLERIE (TP)", label: "AGENT(E) D'HOTELLERIE (TP)" },
  { value: "AGENT DE PROPRETE ET D'HYGIENE (TP)", label: "AGENT DE PROPRETE ET D'HYGIENE (TP)" },
  { value: "PIZZAIOLO/PIZZAIOLA (INSTITUT DE LA CUISINE ITALIENNE - ECOLE FRANCAISE DE PIZZAIOLO)", label: "PIZZAIOLO/PIZZAIOLA (INSTITUT DE LA CUISINE ITALIENNE - ECOLE FRANCAISE DE PIZZAIOLO)" },
  { value: "OPERATEUR DE TRANSFORMATION EN INDUSTRIE ALIMENTAIRE (IFRIA BRETAGNE)", label: "OPERATEUR DE TRANSFORMATION EN INDUSTRIE ALIMENTAIRE (IFRIA BRETAGNE)" },
  { value: "MONTEUR(EUSE) INSTALLATEUR(TRICE) DE RESEAUX TRES HAUT DEBIT (CCI FRANCE - RESEAUX TRES HAUT DEBIT)", label: "MONTEUR(EUSE) INSTALLATEUR(TRICE) DE RESEAUX TRES HAUT DEBIT (CCI FRANCE - RESEAUX TRES HAUT DEBIT)" },
  { value: "ANIMATEUR ASSISTANT D'EQUITATION (FFE)", label: "ANIMATEUR ASSISTANT D'EQUITATION (FFE)" },
  { value: "AGENT(E) DE PREVENTION DE SURETE ET DE SECURITE (EEIE-EESP)", label: "AGENT(E) DE PREVENTION DE SURETE ET DE SECURITE (EEIE-EESP)" }
]
