import { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import {passwordRecovery, requestPassword} from '../../../core/_requests';
import {useAuth} from "../core/Auth";
import {AuthLayout} from "../AuthPage";

const initialValues = {
  email: '',
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
      .email('Format d\'email incorrect')
      .min(3, 'Minimum 3 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Email requis'),
});

export function ForgotPassword() {
  const {logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      const response = await passwordRecovery(values.email,logout)
      if(response.message == 'success'){
        setHasErrors(false);
        setLoading(false);
        formik.resetForm({
          values: formik.initialValues,
        });
      }
      if(response.message == 'User not found'){
        setHasErrors(true);
        setLoading(false);
        setSubmitting(false);
        setStatus('Les informations de connexion sont incorrectes');
      }

    },
  });

  return (<AuthLayout>
      <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='login_password_reset_form'
          onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Mot de passe oublié ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            Entrez votre adresse e-mail pour réinitialiser votre mot de passe.
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Désolé, cette adresse email est introuvable.
              </div>
            </div>
        )}

        {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>Demande de réinitialisation de mot de passe envoyée. Veuillez vérifier votre messagerie pour récupérer votre mot de passe transitoire et vous connectez à votre espace. Changez votre mot de passe dès que possible.</div>
            </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
              type='email'
              placeholder='Entrer votre email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': formik.touched.email && formik.errors.email },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
              )}
          />
          {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='submit' id='password_reset_submit' className=' main-btn-small me-4'>
            <span className='indicator-label'>Réinitialiser</span>
            {loading && (
                <span className='indicator-progress'>
              Veuillez patienter...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
            )}
          </button>
          <Link to='/connexion'>
            <button
                type='button'
                id='login_password_reset_form_cancel_button'
                className=' main-btn-3 me-3 main-btn-small'
                disabled={formik.isSubmitting || !formik.isValid}
            >
              Annuler
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
      </AuthLayout>
  );
}
