import {UserListViewProvider, useUserListView} from './core/ListViewProvider'
import { UserQueryRequestProvider} from './core/QueryRequestProvider'
import {UserQueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {UsersTable} from './table/UsersTable'
import {UserEditModal} from './user-edit-modal/UserEditModal'
import {KTCard} from '../../../../_rebusiness/helpers'

const UsersList = () => {
  const {itemIdForUpdate} = useUserListView()
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <UsersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <UserEditModal />}
    </>
  )
}

const UsersListWrapper = ({ type }: { type: string }) => (
  <UserQueryRequestProvider>
    <UserQueryResponseProvider type={type}>
      <UserListViewProvider>
        <UsersList />
      </UserListViewProvider>
    </UserQueryResponseProvider>
  </UserQueryRequestProvider>
)

export {UsersListWrapper}
