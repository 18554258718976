/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {OptionType, useAuth} from "../../auth";
import Select from 'react-select';
import {cfaDiploma} from "../../auth";
export type StepProps = {
  formik:any
}


const Step2 = ({formik}: StepProps) => {
  const [selectedOption, setSelectedOption] = useState<OptionType[]>([]);



  const onChange = (selectedOptions:any) => {
    if (selectedOptions) {
      // Map over the selectedOptions to extract the value and join them into a string
      const valueString = selectedOptions.map((option:any) => option.value).join(', ');
      console.log(valueString);  // Optional: Log the string to see the output
      formik.setFieldValue('projectDiploma', valueString);
    } else {
      // Handle the case where no options are selected
      formik.setFieldValue('projectDiploma', '');
    }
    setSelectedOption(selectedOptions);
  };
  return (
    <div  data-stepper-element='content'>
      <div className='w-100' style={{minHeight:'400px'}}>

        <div className='fv-row mb-10'>
          <label className='d-flex justify-content-between fs-5 fw-semibold mb-2'>
            <span className='required'>Liste des diplômes</span>
            <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Formations associées (le matériel et équipement présente un intérêt pour une ou plusieurs formations, merci de les lister : CAP Cuisine, BAC Pro ELEEC, BTS Conception et réalisation de systèmes automatiques…)'
            ></i>

          </label>
          <Select
              isMulti
              name="form-field-name"
              maxMenuHeight={100}
              placeholder={'Sélectionnez un diplôme'}
              value={selectedOption}
              onChange={onChange}
              options={cfaDiploma}
              className="basic-multi-select"
              classNamePrefix="basic-multi-select"
          />

          { formik.errors.projectDiploma && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.projectDiploma}</div>
              </div>
          )}
        </div>


      </div>
    </div>
  )
}
export {Step2}
