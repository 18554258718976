import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {KTIcon, toAbsoluteUrl} from "../../../_rebusiness/helpers";
import {Dropdown1} from "../../../_rebusiness/partials";
import {useAuth} from "../../pages/auth";
import {Simulate} from "react-dom/test-utils";
import progress = Simulate.progress;
import {getProjectsByUserId} from "../../core/_requests";

const ProfileHeader: React.FC = () => {
    const location = useLocation()
    const {currentUser, logout} = useAuth()

return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='me-7 mb-4'>
                        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                           {currentUser?.avatar ? <img src={toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL+'/'+currentUser?.avatar)} alt='avatar' />:<div className="symbol symbol-100px">
                               <div className="symbol-label fs-1 fw-bold bg-info text-inverse-info">{currentUser?.contactLastName.slice(0,1)}.{currentUser?.contactFirstName.slice(0,1)}</div>
                           </div>}
                            <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                        </div>

                    </div>

                    <div className='flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-2'>
                                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                                        {currentUser?.companyName} | {currentUser?.contactLastName.slice(0,1)}. {currentUser?.contactFirstName}
                                    </a>
                                    <div className='ms-2'>
                                        <a
                                            href='#'
                                            className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                                        >
                                            {currentUser?.role}
                                        </a>
                                    </div>
                                    <a href='#'>
                                        <KTIcon iconName='verify' className='fs-1 text-primary' />
                                    </a>
                                </div>
                                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                    >
                                        <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                                        {currentUser?.contactPosition}
                                    </a>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                    >
                                        <KTIcon iconName='geolocation' className='fs-4 me-1' />
                                  {currentUser?.companyCity}
                                    </a>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                                    >
                                        <KTIcon iconName='sms' className='fs-4 me-1' />
                                        {currentUser?.email}
                                    </a>
                                </div>
                            </div>
                            {currentUser?.role=='cfa'&& <div className='d-flex my-4'>
                                <a href='#' className='main-btn-3 main-btn-small  me-2'
                                   data-bs-toggle='modal'
                                   data-bs-target='#modal_invite_cfa'>
                                    <span className='indicator-label'>Inviter </span>
                                </a>
                            </div>}

                        </div>
                        <div className='d-flex flex-wrap flex-stack'>
                            <div className='d-flex flex-column flex-grow-1 pe-8'>
                                {currentUser?.role=='cfa'&& <div className='d-flex flex-wrap'>
                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                                            <div className='fs-2 fw-bolder'>{currentUser?.stats?.totalMoneyReceived?.toLocaleString('fr-FR')} €</div>
                                        </div>
                                        <div className='fw-bold fs-6 text-gray-400'>Récoltés</div>
                                    </div>
                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                           {/* <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' />*/}
                                            <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                                            <div className='fs-2 fw-bolder'>{currentUser?.stats?.totalProjects}</div>
                                        </div>
                                        <div className='fw-bold fs-6 text-gray-400'>Projets</div>
                                    </div>
                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                                            <div className='fs-2 fw-bolder'>{currentUser?.stats?.progressPercentage}</div>
                                        </div>
                                        <div className='fw-bold fs-6 text-gray-400'>Projets financés</div>
                                    </div>
                                </div>}
                                {currentUser?.role=='entreprise'&& <div className='d-flex flex-wrap'>
                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                                            <div className='fs-2 fw-bolder'>  {currentUser?.companyTax ? parseFloat(currentUser?.companyTax).toLocaleString('fr-FR') + ' €' : 'en attente'} </div>
                                        </div>
                                        <div className='fw-bold fs-6 text-gray-400'>Taxe d'apprentissage</div>
                                    </div>
                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            {/* <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' />*/}
                                            <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                                            <div className='fs-2 fw-bolder'>{currentUser?.stats?.totalAmountSpent} €</div>
                                        </div>
                                        <div className='fw-bold fs-6 text-gray-400'>Subventions</div>
                                    </div>
                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                                            <div className='fs-2 fw-bolder'>{currentUser?.stats?.totalProjectsHelped}</div>
                                        </div>
                                        <div className='fw-bold fs-6 text-gray-400'>Projets financés</div>
                                    </div>
                                </div>}
                                {currentUser?.role=='fournisseur'&& <div className='d-flex flex-wrap'>
                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                                            <div className='fs-2 fw-bolder'>{currentUser?.stats?.totalProducts?.toLocaleString('fr-FR')} </div>
                                        </div>
                                        <div className='fw-bold fs-6 text-gray-400'>Produits</div>
                                    </div>
                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            {/* <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' />*/}
                                            <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                                            <div className='fs-2 fw-bolder'>{currentUser?.stats?.totalMoneyReceived} €</div>
                                        </div>
                                        <div className='fw-bold fs-6 text-gray-400'>Chiffre d'affaire</div>
                                    </div>

                                </div>}
                            </div>
                            {currentUser?.stats?.profileProgress != 100 || currentUser?.role=='fournisseur'? <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                                    <span className='fw-bold fs-6 text-gray-400'>Profil complété à </span>
                                    <span className='fw-bolder fs-6'>{currentUser?.stats?.profileProgress}%</span>
                                </div>
                                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                                    <div className='bg-success rounded h-5px' role='progressbar' aria-valuemin={0} aria-valuemax={100} style={{ width: `${Math.min(currentUser?.stats?.profileProgress||0,100)}%` }}></div>
                                </div>
                            </div>:null}
                            {currentUser?.role=='entreprise' || currentUser?.role=='cfa'?currentUser?.stats?.profileProgress == 100 &&<div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                            <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                                <span className='fw-bold fs-6 text-gray-400'>{currentUser?.role=='cfa'?'Projets financés':"Montant de la taxe dépensé"} </span>
                                <span className='fw-bolder fs-6'>{currentUser?.stats?.progressPercentage}%</span>
                            </div>
                            <div className='h-5px mx-3 w-100 bg-light mb-3'>
                                <div className='bg-success rounded h-5px' role='progressbar' aria-valuemin={0} aria-valuemax={100} style={{ width: `${Math.min(currentUser?.stats?.progressPercentage,100)}%` }}></div>
                            </div>
                        </div>:null}
                        </div>
                    </div>
                </div>

                <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/${currentUser?.role}/overview` && 'active')
                                }
                                to={`/${currentUser?.role}/overview`}
                            >
                                Aperçu
                            </Link>
                        </li>
                        {currentUser?.role=='entreprise'?<li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/${currentUser?.role}/mycontributions` && 'active')
                                }
                                to={`/${currentUser?.role}/mycontributions`}
                            >
                                Mes subventions
                            </Link>
                        </li>:currentUser?.role=='cfa'?<li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/${currentUser?.role}/myprojects` && 'active')
                                }
                                to={`/${currentUser?.role}/myprojects`}
                            >
                                Mes Projets
                            </Link>
                        </li>:<li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/${currentUser?.role}/myproducts` && 'active')
                                }
                                to={`/${currentUser?.role}/myproducts`}
                            >
                                Mes Produits
                            </Link>
                        </li>}
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/${currentUser?.role}/settings` && 'active')
                                }
                                to={`/${currentUser?.role}/settings`}
                            >
                                Réglages
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export {ProfileHeader}