import { projectDetailsArea } from "../../../data/projectsArea";
import React, {useEffect} from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

const {

  socials,
} = projectDetailsArea;

const ProjectDetailsArea = ({project,fetchData}) => {
  const handleModalClose = () => {
    fetchData() // Fetch data again when the modal is closed
  };
  useEffect(() => {
    const modalElement = document.getElementById('modal_funding_project');
    modalElement.addEventListener('hidden.bs.modal', handleModalClose);
    // Cleanup event listener on component unmount
    return () => {
      modalElement.removeEventListener('hidden.bs.modal', handleModalClose);
    };
  }, []);

  // Convert the target date to a JavaScript Date object
  const targetDate = new Date(project.deadlineDate);

// Get the current date
  const currentDate = new Date();

// Calculate the difference in milliseconds between the target date and the current date
  const timeDifference = targetDate.getTime() - currentDate.getTime();

// Convert the time difference to days
  const daysLeft = Math.max(Math.ceil(timeDifference / (1000 * 3600 * 24)),0);


  return (
    <section style={{marginTop:'50px'}} className="project-details-area pt-120 pb-190">
      <Container>
        <Row>
          <Col lg={7}>
            <div className="project-details-thumb" style={{ backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}/${project.projectImages})` }}>
             {/* <div className="icon">
                <i className="fa fa-heart"></i>
              </div>*/}
            </div>
          </Col>
          <Col lg={5}>
            <div className="project-details-content">
              <div className="details-btn">

                <div className={'flag'}>
                  <Image style={{height:'50px',borderRadius:'50px '}}  src={process.env.REACT_APP_PUBLIC_URL+'/'+project.User?.avatar} alt="logo cfa" />
                  <p>{project.User?.companyName}|{project.User?.companyCity}<br/>{project.User?.companyWebsite&&<a target="_blank" rel="noopener noreferrer" href={'https://'+project.User?.companyWebsite}>{project.User?.companyWebsite}</a>}</p>
                </div>
              </div>

              <h3 className="title">{project.projectName}</h3>
              <div className="project-details-item">
                <div className="item text-center">
                  <h5 className="title text-dark">{project.totalContributions?.toLocaleString('fr-FR')?? '0'} €</h5>
                  <span>Collectés</span>
                </div>
                <div className="item text-center">
                  <h5 className="title text-dark">{project.contributionCount}</h5>
                  <span>Contributeurs</span>
                </div>
                <div className="item text-center">
                  <h5 className="title text-dark">{daysLeft}</h5>
                  <span>Jours Restants</span>
                </div>
              </div>
              <div className="projects-range">
                <div className="projects-range-content">
                  <ul>
                    <li>Collecté:</li>
                    <li>{Math.floor((project.totalContributions / project.projectCost) * 100)}%</li>
                  </ul>
                  <div className='h-10px  w-100  mb-3' style={{backgroundColor:'#e8e8e8'}}>
                    <div className='square h-10px' role='progressbar'  aria-valuemin={0} aria-valuemax={100} style={{ width: `${Math.min(Math.floor((project.totalContributions / project.projectCost)*100),100)||0}%`,backgroundColor:'#28f0b4' }}></div>
                  </div>
                </div>
              </div>
              <div className="projects-goal">
                <span>
                  Objectif: <span className={'text-dark'}>{project.projectCost?.toLocaleString('fr-FR')} €</span>
                </span>
              </div>
              <div className="project-btn mt-25" >
                <a className="main-btn" data-bs-toggle='modal'
                   data-bs-target={'#modal_funding_project'}
                   disabled={daysLeft === 0}
                   style={{width:'100%'}}
                   href="#">
                  Soutenir ce projet
                </a>
              </div>
              <div className="project-btn mt-25" >
                <a className={'main-btn-small main-btn-3'}  style={{width:'100%'}} href={`mailto:${project.User?.email}`} rel="noopener noreferrer">
                  Envoyez un message
                </a>
              </div>
             {/* <div className="project-share d-flex align-items-center">
                <span>Partager ce projet</span>
                <ul>
                  {socials.map(({ id, icon, href }) => (
                    <li key={id}>
                      <a href={href}>
                        <i className={icon}></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>*/}
            </div>
          </Col>
        </Row>
      </Container>
    </section>

  );
};

export default ProjectDetailsArea;
