import {FC} from 'react'

type Props = {
  status?: string
}

const ProjectStatus: FC<Props> = ({status}) => (
  <>{status ==='pending' && <div className='badge badge-light-warning fw-bolder'>En attente de l'admin</div>}
    {status ==='rejected' && <div className='badge badge-light-danger fw-bolder'>Rejeté</div>}
    {status ==='online' && <div className='badge badge-light-success fw-bolder'>En ligne</div>}</>
)

export {ProjectStatus}
