import {FC} from 'react'

type Props = {
    line1?: string
    line2?: string
}

const UserTwoLines: FC<Props> = ({line1,line2}) => (
    <div className='d-flex align-items-center'><div className='d-flex flex-column'>
    <span>{line1}</span>
    <span>{line2} </span>
    </div></div>
)

export {UserTwoLines}
