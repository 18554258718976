import React, { useState } from 'react';
import { toAbsoluteUrl } from '../../../_rebusiness/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {AuthInit, getAuth, useAuth} from '../../pages/auth';
import {editProfile, getUserByToken} from "../../core/_requests";
import { getBase64 } from '../../core/getBase64';

const profileDetailsSchema = Yup.object().shape({
    contactFirstName: Yup.string().required('Le prénom est requis'),
    contactLastName: Yup.string().required('Le nom de famille est requis'),
    companyName: Yup.string().required('Le nom de l\'entreprise est requis'),
    contactPhoneNumber: Yup.string().required('Le numéro de téléphone de contact est requis'),
    companyAddress: Yup.string().required('L\'adresse de l\'entreprise est requise'),
    contactPosition: Yup.string().required('La fonction est requise'),
});


const ProfileDetails: React.FC = () => {
    const { currentUser,logout,setCurrentUser } = useAuth();
    const [selectedAvatar, setSelectedAvatar] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            contactFirstName: currentUser?.contactFirstName || '',
            contactLastName: currentUser?.contactLastName || '',
            companyName: currentUser?.companyName || '',
            contactPhoneNumber: currentUser?.contactPhoneNumber || '',
            companyAddress: currentUser?.companyAddress || '',
            companyZipCode: currentUser?.companyZipCode || '',
            companyWebsite: currentUser?.companyWebsite || '',
            companyCity: currentUser?.companyCity || '',
            uai: currentUser?.uai || '',
            siren: currentUser?.siren || '',
            siret: currentUser?.siret || '',
            contactPosition: currentUser?.contactPosition || '',
        },
        validationSchema: profileDetailsSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                // Prepare the data
                const data = {
                    contactFirstName: values.contactFirstName,
                    contactLastName: values.contactLastName,
                    companyName: values.companyName,
                    companyWebsite: values.companyWebsite,
                    contactPhoneNumber: values.contactPhoneNumber,
                    companyAddress: values.companyAddress,
                    companyZipCode: values.companyZipCode,
                    companyCity: values.companyCity,
                    uai: values.uai,
                    siren: values.siren,
                    siret: values.siret,
                    contactPosition: values.contactPosition,
                    avatar: null as string | null, // Specify the type as string | null and initialize with null
                };

                if (selectedAvatar) {
                    const base64Image = await getBase64(selectedAvatar);
                    data.avatar = base64Image;
                }

                const response = await editProfile(data, logout);

                setCurrentUser(response.user);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        },
    });

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#account_profile_details'
                aria-expanded='true'
                aria-controls='account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Détails du profil</h3>
                </div>
            </div>

            <div id='account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
                            <div className='col-lg-8'>
                                <div className='image-input image-input-outline' data-image-input='true' >
                                    <div
                                        className='image-input-wrapper w-125px h-125px'
                                        style={{
                                            backgroundImage: `url(${
                                                selectedAvatar
                                                    ? URL.createObjectURL(selectedAvatar)
                                                    : currentUser?.avatar
                                                        ? toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL + '/' + currentUser?.avatar)
                                                        : toAbsoluteUrl('/media/avatars/blank.png')
                                            })`,
                                            cursor: 'pointer', // Make the image appear clickable with a pointer cursor
                                        }}
                                        onClick={() => {
                                            const avatarInput = document.getElementById('avatar');
                                            if (avatarInput) {
                                                avatarInput.click();
                                            }
                                        }} // Trigger file input click when the image is clicked
                                    ></div>
                                    <label htmlFor='avatar' className='visually-hidden'>
                                        Modifier l'avatar
                                        <input
                                            id='avatar'
                                            type='file'
                                            name='avatar'
                                            accept='.png, .jpg, .jpeg'
                                            style={{ display: 'none' }}
                                            onChange={(event) => {
                                                const file = event.target.files?.[0] || null;
                                                setSelectedAvatar(file);
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>

                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nom complet</label>

                            <div className='col-lg-8'>
                                <div className='row'>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='First name'
                                            {...formik.getFieldProps('contactFirstName')}
                                        />
                                        {formik.touched.contactFirstName && formik.errors.contactFirstName && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.contactFirstName}</div>
                                            </div>
                                        )}
                                    </div>

                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Last name'
                                            {...formik.getFieldProps('contactLastName')}
                                        />
                                        {formik.touched.contactLastName && formik.errors.contactLastName && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.contactLastName}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nom {currentUser?.role ==='cfa'? 'du CFA':"de l'entreprise"}</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Company name'
                                    {...formik.getFieldProps('companyName')}
                                />
                                {formik.touched.companyName && formik.errors.companyName && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.companyName}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Numéro de téléphone</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Phone number'
                                    {...formik.getFieldProps('contactPhoneNumber')}
                                />
                                {formik.touched.contactPhoneNumber && formik.errors.contactPhoneNumber && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.contactPhoneNumber}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Addresse {currentUser?.role ==='cfa'? 'du CFA':"de l'entreprise"}</span>
                            </label>

                            <div className='col-lg-8 '>
                                <div className='row'>
                                    <div className='col-lg-4 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Company address'
                                    {...formik.getFieldProps('companyAddress')}
                                />
                                {formik.touched.companyAddress && formik.errors.companyAddress && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.companyAddress}</div>
                                    </div>
                                )}
                                    </div>
                                    <div className='col-lg-4 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Code postal'
                                            {...formik.getFieldProps('companyZipCode')}
                                        />
                                        {formik.touched.companyZipCode && formik.errors.companyZipCode && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.companyZipCode}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-lg-4 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Ville'
                                            {...formik.getFieldProps('companyCity')}
                                        />
                                        {formik.touched.companyCity && formik.errors.companyCity && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.companyCity}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {currentUser?.role === 'cfa' && (
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>UAI</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='UAI'
                                        {...formik.getFieldProps('uai')}
                                        readOnly // Add readOnly attribute to make it non-editable
                                    />
                                    {formik.touched.uai && formik.errors.uai && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.uai}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        )}
                        {currentUser?.role === 'cfa' && (
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Site internet</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Site internet'
                                        {...formik.getFieldProps('companyWebsite')}

                                    />
                                    {formik.touched.companyWebsite && formik.errors.companyWebsite && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.companyWebsite}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        )}

                        {(currentUser?.role === 'entreprise' ) && (
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>SIREN</label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='SIREN'
                                        {...formik.getFieldProps('siren')}
                                        readOnly // Add readOnly attribute to make it non-editable
                                    />
                                    {formik.touched.siren && formik.errors.siren && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.siren}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {(currentUser?.role === 'entreprise' ) && (
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>SIRET</label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='SIRET'
                                        {...formik.getFieldProps('siret')}
                                        readOnly // Add readOnly attribute to make it non-editable
                                    />
                                    {formik.touched.siret && formik.errors.siret && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.siret}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Poste occupé</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Position'
                                    {...formik.getFieldProps('contactPosition')}
                                />
                                {formik.touched.contactPosition && formik.errors.contactPosition && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.contactPosition}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='main-btn-small' disabled={loading}>
                            {!loading && 'Enregistrer'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                  Patientez...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export { ProfileDetails };
