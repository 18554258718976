/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_rebusiness/helpers'
import { useAuth} from "../../pages/auth";
import {getContributionsByUserId, getProjectsByUserId} from "../../core/_requests";
import {Link} from "react-router-dom";
import {Contribution} from './../../core/_models'
type Props = {
    className: string
}

const ContributionsList: React.FC<Props> = ({className}) => {
    const { currentUser,logout,setCurrentUser } = useAuth();
    const [contributions, setContributions] = useState<Contribution[]>();
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const contributions = await getContributionsByUserId(logout);
                setContributions(contributions);
            } catch (error) {
                console.error(error);
                // Handle error if necessary
            }
        };
        fetchProjects();
    }, [logout]);
    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            {contributions && ( <><div className='card-header border-0'>
                <h3 className='card-title fw-bold text-dark'>Mes subventions</h3>
                <div className='card-toolbar'>
                    <Link to='/projets' className='main-btn-3 main-btn-small  align-self-center'>
                        Soutenir un projet
                    </Link>
                </div>
            </div>
            <div className='card-body pt-2'>

            {contributions?.map((contribution) => (
                        <div key={contribution.id} className='d-flex align-items-center mb-7'>
                            <div className='flex-grow-1'>
                                <a href={'#' + contribution.id} className='text-dark fw-bold text-hover-primary fs-6'>
                                    {/* Accessing contribution properties */}
                                    Ref : {contribution.reference}
                                </a>
                                <span className='text-muted d-block fw-semibold'>
                                    {contribution.amount} € {contribution.status ==='pending' && <div className='badge badge-light-warning fw-bolder'>En attente </div>}
                                    {contribution.status  ==='rejected' && <div className='badge badge-light-danger fw-bolder'>Rejeté</div>}
                                    {contribution.status  ==='refunded' && <div className='badge badge-light-danger fw-bolder'>Remboursé</div>}
                                    {contribution.status  ==='success' && <div className='badge badge-light-success fw-bolder'>Confirmé</div>}
                </span>
                            </div>

                                {contribution?.quote && <a href={`${process.env.REACT_APP_PUBLIC_URL}/${contribution?.quote}`}>Facture</a>}

                        </div>
                    ))}
            </div></>)}
            {/* end::Body */}
        </div>
    )
}

export {ContributionsList}
