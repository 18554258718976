import React from "react";
import { Image } from "react-bootstrap";
import Link from "../Reuseable/Link";
import {toAbsoluteUrl} from "../../../_rebusiness/helpers";

const ProjectDetail = ({ project = {} }) => {
  const { projectImages, projectDescription, projectCost, projectName, deadlineDate, uniqueID,totalContributions,User} = project;
  const dateObject = new Date(deadlineDate);
  const day = dateObject.getUTCDate();
  const month = dateObject.getUTCMonth() + 1; // Months are zero-based, so we add 1
  const year = dateObject.getUTCFullYear();
  const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  return (
    <div className="explore-projects-item mt-30">
      <div className="explore-projects-thumb">
        <img src={toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL+'/'+`${projectImages}`)} alt="explore-projects" />
        <a href="#">
          <i className="fa fa-heart"></i>
        </a>
      </div>
      <div className="explore-projects-content">
        <div className="item d-flex align-items-center">
          <div className='symbol symbol-circle symbol-40px'>
            {project["User.avatar"]?<img src={toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL+'/'+project["User.avatar"])} alt='avatar' />:null}
          </div>
          <p className="ml-20">
            <b>{project["User.companyName"]}</b> <br/>
            Date limite : {formattedDate}
          </p>
        </div>

        <Link to={`/project/${project.uniqueID}`}>
          <h3 className="title">{projectName}</h3>
        </Link>
        <div className="projects-range">
          <div className="projects-range-content">
            <ul>
              <li>Collecté:</li>
              <li>{Math.floor((totalContributions/projectCost)*100)+' %'||0}</li>
            </ul>
            <div className='h-10px  w-100  mb-3' style={{backgroundColor:'#e8e8e8'}}>
              <div className='square h-10px' role='progressbar'  aria-valuemin={0} aria-valuemax={100} style={{ width: `${Math.min(Math.floor((totalContributions/projectCost)*100),100)||0}%`,backgroundColor:'#28f0b4' }}></div>
            </div>
          </div>
        </div>
        <div className="projects-goal">
          <span>
            Objectif :  <span> {projectCost.toLocaleString('fr-FR')} €</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;
