import {FC, useMemo} from 'react'
import {ID} from '../../../../../../_rebusiness/helpers'
import {useProjectListView} from '../../core/ListViewProvider'

type Props = {
  id: ID
}

const ProjectSelectionCell: FC<Props> = ({id}) => {
  const {selected, onSelect} = useProjectListView()
  const isSelected = useMemo(() => selected.includes(id), [id, selected])
  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        data-check={isSelected}
        data-check-target='#table_projects .form-check-input'
        checked={isSelected}
        onChange={() => onSelect(id)}
      />
    </div>
  )
}

export {ProjectSelectionCell}
