import React from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../_rebusiness/helpers";
import HeaderInfo from "./HeaderInfo";
import headerData from "../../data/headerData";
import useScroll from "../../hooks/useScroll";
import {useLocation} from "react-router-dom";

const { logo2, navItems, phone, icon, socials } = headerData;

const HeaderFront = () => {
    const { scrollTop } = useScroll(160);
    const location = useLocation();
    return (

        <header className="header-area header-2-area">
            <div className={`main-header${scrollTop ? " sticky" : ""}`}>
                <Container fluid >
                    <Row>
                        <Col lg={12}>
                            <div className="main-header-item">
                                <div className="main-header-menus d-flex justify-content-between align-items-center">
                                    <div className="header-logo">
                                        <a href="/">
                                            <Image src={toAbsoluteUrl(
                                                '/media/assets/images/logo-dark.svg'
                                            )} alt="logo" style={{height:'23px'}} />
                                        </a>
                                    </div>
                                    <div className="header-menu d-none d-lg-block">
                                        <ul>
                                            {navItems.map((navItem) => (
                                                <li>
                                                    <a
                                                        className={location.pathname == navItem.href ? "active" : ""}
                                                        href={navItem.href}
                                                    >
                                                        {navItem.name}{" "}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <HeaderInfo
                                        icon={icon}
                                        socials={socials}
                                        searchColor={'white'}
                                    />

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </header>
    );
};

export default HeaderFront;
