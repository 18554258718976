import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { EmailVerification } from '../../../core/_requests';
import { useAuth } from '../core/Auth';
import {AuthLayout} from "../AuthPage";

export function MailVerification() {
    const location = useLocation();
    const { logout, setCurrentUser } = useAuth();
    const [token, setToken] = useState('');
    const [status, setStatus] = useState('failed');

    useEffect(() => {
        // Extract the token from the URL path
        const pathTokens = location.pathname.split('/');
        const tokenFromPath = pathTokens[pathTokens.length - 1];
        setToken(tokenFromPath);
    }, [location]);
    console.log(token)
    const verifyEmail = async () => {
        if (token) {
            try {
                const response = await EmailVerification(token, logout);
                console.log(response);
                setStatus(response.message)
                setTimeout(() => {
                    window.location.href = '/'; // Redirect using window.location
                }, 5000);

                // Display success message
            } catch (error:any) {
                setStatus(error.message)
                console.error('Email verification failed:', error);
                setTimeout(() => {
                    window.location.href = '/'; // Redirect using window.location
                }, 5000);
            }
        }
    };

    useEffect(() => {
        // Call the verifyEmail function when the component mounts
        verifyEmail();
    }, [token]);

    return ( <AuthLayout>
            {status == 'success' && <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Votre email a été vérifié avec succès </h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-500 fw-semibold fs-6'>
                    Merci votre email a été activé avec succès.
                </div>
                {/* end::Link */}
            </div>}
            {status == 'failed' && <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>La vérification de votre email a échoué </h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-500 fw-semibold fs-6'>
                    Aucune vérification en cours n'a été trouvé.
                </div>
                {/* end::Link */}
            </div>}
        </AuthLayout>

    );
}
