import bg from "../assets/images/footer-bg.jpg";
import shape from "../assets/images/footer-shape.png";
import logo from "../assets/images/logo-2.png";

export const socials2 = [
  {
    id: 1,
    icon: "fa fa-brands fa-twitter",
    href: "#",
  },
  {
    id: 2,
    icon: "fa fa-brands fa-facebook",
    href: "#",
  },
  {
    id: 3,
    icon: "fa fa-brands fa-pinterest",
    href: "#",
  },
  {
    id: 4,
    icon: "fa fa-brands fa-instagram",
    href: "#",
  },
];

const footerData = {
  logo,
  bg,
  socials: socials2,
  text: "Des projets ambitieux,\n Des investissements solidaires,\n Pour soutenir l'apprentissage",
  text2:
    "Inscrivez vous a l",
  shape,
  links: [
    {
      id: 1,
      text: "About us",
      href: "/about",
    },
    {
      id: 2,
      text: "How It Works",
      href: "/team",
    },
    {
      id: 3,
      text: "Knowledge hub",
      href: "/case",
    },
    {
      id: 4,
      text: "Success Stories",
      href: "/blog",
    },
    {
      id: 5,
      text: "Contact",
      href: "/contact",
    },
    {
      id: 6,
      text: "Education",
      href: "/about",
    },
    {
      id: 7,
      text: "Design",
      href: "/about",
    },
    {
      id: 8,
      text: "Film & Video",
      href: "/about",
    },
    {
      id: 9,
      text: "Technology",
      href: "/about",
    },
    {
      id: 10,
      text: "Games",
      href: "/about",
    },
  ],
  author: "Rebusiness",
  year: new Date().getFullYear(),
};

export default footerData;
