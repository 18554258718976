import { useRootContext } from "../../context/context";
import { useLocation } from "react-router-dom";
import React from "react";
import { Link } from "react-router-dom";

const NavItem = ({ navItem = {}, mobile = false, activeId, handleActive }) => {
  const location = useLocation();
  const { toggleMenu } = useRootContext();

  const { name, href, subNavItems = [], id } = navItem;

  const handleExpand = () => {
    if (mobile && href) {
      toggleMenu(false);
    } else if (mobile) {
      handleActive?.(id);
    }
  };

  let current = location.pathname === href;
  if (!current) {
    current = subNavItems.find((item) => item.href === location.pathname);
  }
  const active = id === activeId;

  return (
      <li>
        <Link
            className={current ? "active" : ""}
            onClick={handleExpand}
            to={href}
        >
          {name}{" "}
          {mobile && subNavItems.length > 0 && (
              <span className="menu-expand">
            <i className="fa fa-angle-down"></i>
          </span>
          )}
        </Link>
        {subNavItems.length > 0 && (
            <ul className={`sub-menu ${mobile && !active ? "d-none" : "d-block"}`}>
              {subNavItems.map(({ href, name, id }) => (
                  <li key={id}>
                    <Link onClick={() => mobile && toggleMenu(false)} to={href}>
                      {name}
                    </Link>
                  </li>
              ))}
            </ul>
        )}
      </li>
  );
};

export default NavItem;
