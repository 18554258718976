import { contactFormArea } from "../../data/contactArea";
import handleSubmit from "../../utils/handleSubmit";
import React,{useState} from "react";
import { Col, Container, Row } from "react-bootstrap";
import Title from "../Reuseable/Title";
import {contactMail} from "../../../app/core/_requests";
import {useAuth} from "../../../app/pages/auth";
const { tagline, title, inputs } = contactFormArea;

const ContactFormArea = () => {
  const [sent, setSent] = useState(false);
  const {logout} = useAuth()
  const onSubmit = async (data) => {
    setSent(true)
    await contactMail(data,logout)
  };

  return (
      <section className="contact-form-area">
        {sent?<Container>
          <Title title={"Merci !"} className="text-center"/>
          <p className="text-center">Votre message a bien été envoyé. Nous ne manquerons pas de vous répondre. A bientôt sur CrowdFundCFA !</p>
          <div className="input-box mt-20 text-center">
            <button className="main-btn" onClick={()=>{setSent(false)}}>
              Renvoyer un message
            </button>
          </div>
        </Container>:
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <Title title={title} tagline={tagline} className="text-center" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8}>
              <form onSubmit={handleSubmit(onSubmit)} id="contact-form">
                <div className="conact-form-item">
                  <Row>
                    {inputs.map(({ id, type, name, placeholder, cols, rows }) => (
                        <Col key={id} lg={type ? 6 : 12} md={type ? 6 : 12}>
                          <div className="input-box mt-20">
                            {type ? (
                                <input
                                    type={type}
                                    placeholder={placeholder}
                                    name={name}
                                />
                            ) : (
                                <textarea
                                    id="#"
                                    cols={cols}
                                    rows={rows}
                                    placeholder={placeholder}
                                    name={name}
                                ></textarea>
                            )}
                          </div>
                        </Col>
                    ))}
                    <Col lg={12}>
                      <div className="input-box mt-20 text-center">
                        <button className="main-btn" type="submit">
                          Envoyer
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </form>
              <p className="form-message"></p>
            </Col>
          </Row>
        </Container>}
      </section>
  );
};

export default ContactFormArea;
