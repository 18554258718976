import React, {useEffect, useState} from "react";
import Header from "../components/Header/Header";
import Layout from "../components/Layout/Layout";
import ProjectDetailsArea from "../components/ProjectsArea/ProjectDetails/ProjectDetailsArea";
import ProjectDetailsContent from "../components/ProjectsArea/ProjectDetails/ProjectDetailsContent";
import SimilarProjects from "../components/ProjectsArea/SimilarProjects";
import PageTitle from "../components/Reuseable/PageTitle";
import HeaderFront2 from "../components/Header/HeaderFront2";
import {useLocation} from "react-router-dom";
import {getProject} from "../../app/core/_requests";
import {useAuth} from "../../app/pages/auth";
import {Col, Container, Row} from "react-bootstrap";
import ProjectDetailsStory from "../components/ProjectsArea/ProjectDetails/ProjectDetailsStory";
import { projectDetailsTabBtns } from "../data/projectsArea";
import ProjectDetailsSidebar from "../components/ProjectsArea/ProjectDetails/ProjectDetailsSidebar";



const ProjectDetails : React.FC =  () => {
    const location=useLocation();
    const {currentUser, logout} = useAuth();
    const [project, setProject] = useState([]);

    const fetchData = async () => {
        try {
            const data = await getProject(location.pathname.split('/')[2], logout)
            setProject(data.project)
        } catch (error) {
            console.error('Error fetching project data:', error);
        }
    };

        useEffect(() => {
            // Perform any asynchronous operations here
            fetchData();
            window.scrollTo(0, 0);
        }, [location.pathname]);


    const [current, setCurrent] = useState("pills-home");

    const getClassName = (id = "") => {
        const active = current === id;
        return `tab-pane animated${active ? " fadeIn show active" : ""}`;
    };

    return (
    <Layout>
        <HeaderFront2/>
      {/*<PageTitle title="Single Project" page="Project" />*/}
      <ProjectDetailsArea project={project} fetchData={fetchData}/>
        <section className="project-details-content-area pb-110">
            <Container>
                <Row >
                    <Col lg={8}>
                        <div className="tab-btns">
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                {projectDetailsTabBtns.map(({ id, name }) => (
                                    <li key={id} className="nav-item" role="presentation">
                                        <a
                                            onClick={() => setCurrent(id)}
                                            className={`nav-link cursor-pointer${
                                                id === current ? " active" : ""
                                            }`}
                                            role="tab"
                                        >
                                            {name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                            <ProjectDetailsStory project={project} getClassName={getClassName} />
                           {/* <ProjectDetailsUpdates project={project} getClassName={getClassName} />*/}
                        </div>
                    </Col>
                    <Col lg={4} md={7} sm={9}>
                        <ProjectDetailsSidebar project={project} fetchData={fetchData}/>
                    </Col>
                </Row>
            </Container>
        </section>

      {/*<Header />
      <ProjectDetailsContent />
      <SimilarProjects />*/}
    </Layout>
  );
};

export default ProjectDetails;
