import {ProductListViewProvider, useProjectListView} from './core/ListViewProvider'
import {ProductQueryRequestProvider} from './core/QueryRequestProvider'
import {ProductQueryResponseProvider} from './core/QueryResponseProvider'
import {ProductsListHeader} from './components/header/ProductsListHeader'
import {ProductsTable} from './table/ProductsTable'
import {ProductViewModal} from './user-edit-modal/ProductViewModal'
import {KTCard} from '../../../../_rebusiness/helpers'

const ProductsList = () => {
  const {itemIdForUpdate,setItemIdForUpdate} = useProjectListView()
  return (
    <>
      <KTCard>
        <ProductsListHeader />
          <ProductsTable/>
      </KTCard>
      {itemIdForUpdate !== undefined && <ProductViewModal editProduct={itemIdForUpdate} onModalClose={()=>setItemIdForUpdate(undefined)}/>}
    </>
  )
}

const ProductsListWrapper = ({ type }: { type: string }) => (
  <ProductQueryRequestProvider>
    <ProductQueryResponseProvider type={type}>
      <ProductListViewProvider>
        <ProductsList />
      </ProductListViewProvider>
    </ProductQueryResponseProvider>
  </ProductQueryRequestProvider>
)

export {ProductsListWrapper}
