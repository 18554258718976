/* eslint-disable react-hooks/exhaustive-deps */
import React,{FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../_rebusiness/helpers'
import {getUsers,getProjects,getProducts,getContributions} from './_requests'
import {User,Project,Product,Contribution} from './_models'
import {useUserQueryRequest,useProjectQueryRequest,useProductQueryRequest,useContributionQueryRequest} from './QueryRequestProvider'

const UserQueryResponseContext = createResponseContext<User>(initialQueryResponse)
interface UserQueryResponseProviderProps {
  type: string;
  children: React.ReactNode;
}

const UserQueryResponseProvider: FC<UserQueryResponseProviderProps> = ({ children, type }) => {
  const {state} = useUserQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
      `${QUERIES.USERS_LIST}-${query}`,
      () => { return getUsers(query,type)},
      {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  return (<UserQueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
        {children}
      </UserQueryResponseContext.Provider>
  )
}
const useUserQueryResponse = () => useContext(UserQueryResponseContext)

const useUserQueryResponseData = () => {
  const {response} = useUserQueryResponse()
  if (!response) {
    return []
  }
  return response?.data || []
}

const useUserQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }
  const {response} = useUserQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }
  return response.payload.pagination
}

const useUserQueryResponseLoading = (): boolean => {
  const {isLoading} = useUserQueryResponse()
  return isLoading
}







const ProjectQueryResponseContext = createResponseContext<Project>(initialQueryResponse)
interface ProjectQueryResponseProviderProps {
  type: string;
  children: React.ReactNode;
}
const ProjectQueryResponseProvider: FC<ProjectQueryResponseProviderProps> = ({ children, type }) => {
  const {state} = useProjectQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
      `${QUERIES.PROJECTS_LIST}-${query}`,
      () => { return getProjects(query)},
      {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  return (
      <ProjectQueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
          {children}
        </ProjectQueryResponseContext.Provider>
  )
}
const useProjectQueryResponse = () => useContext(ProjectQueryResponseContext)

const useProjectQueryResponseData = () => {
  const {response} = useProjectQueryResponse()
  if (!response) {
    return []
  }
  return response?.data || []
}

const useProjectQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useProjectQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useProjectQueryResponseLoading = (): boolean => {
  const {isLoading} = useProjectQueryResponse()
  return isLoading
}







const ContributionQueryResponseContext = createResponseContext<Contribution>(initialQueryResponse)
interface ContributionQueryResponseProviderProps {
  type: string;
  children: React.ReactNode;
}
const ContributionQueryResponseProvider: FC<ContributionQueryResponseProviderProps> = ({ children, type }) => {
  const {state} = useContributionQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
      `${QUERIES.CONTRIBUTIONS_LIST}-${query}`,
      () => { return getContributions(query)},
      {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
      <ContributionQueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
        {children}
      </ContributionQueryResponseContext.Provider>
  )
}
const useContributionQueryResponse = () => useContext(ContributionQueryResponseContext)

const useContributionQueryResponseData = () => {
  const {response} = useContributionQueryResponse()
  if (!response) {
    return []
  }
  return response?.data || []
}

const useContributionQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useContributionQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useContributionQueryResponseLoading = (): boolean => {
  const {isLoading} = useContributionQueryResponse()
  return isLoading
}



const ProductQueryResponseContext = createResponseContext<Product>(initialQueryResponse)
interface ProductQueryResponseProviderProps {
  type: string;
  children: React.ReactNode;
}
const ProductQueryResponseProvider: FC<ProductQueryResponseProviderProps> = ({ children, type }) => {
  const {state} = useProductQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
      `${QUERIES.PRODUCTS_LIST}-${query}`,
      () => { return getProducts(query)},
      {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  console.log(query)
  console.log(response)
  return (
      <ProductQueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
        {children}
      </ProductQueryResponseContext.Provider>
  )
}
const useProductQueryResponse = () => useContext(ProductQueryResponseContext)

const useProductQueryResponseData = () => {
  const {response} = useProductQueryResponse()
  if (!response) {
    return []
  }
  return response?.data || []
}

const useProductQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useProductQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useProductQueryResponseLoading = (): boolean => {
  const {isLoading} = useProductQueryResponse()
  return isLoading
}


export {
  ProductQueryResponseProvider,
  useProductQueryResponseLoading,
  useProductQueryResponsePagination,
  useProductQueryResponseData,
  useProductQueryResponse,
  ProjectQueryResponseProvider,
  useProjectQueryResponseLoading,
  useProjectQueryResponsePagination,
  useProjectQueryResponseData,
  useProjectQueryResponse,
  UserQueryResponseProvider,
  useUserQueryResponse,
  useUserQueryResponseData,
  useUserQueryResponsePagination,
  useUserQueryResponseLoading,
  ContributionQueryResponseProvider,
  useContributionQueryResponseLoading,
  useContributionQueryResponsePagination,
  useContributionQueryResponseData,
  useContributionQueryResponse,
}
