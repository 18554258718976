import {FC, Suspense} from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, useAuth } from '../pages/auth';
import { App } from '../App';
import Home from '../../front/pages/home';
import Projects from '../../front/pages/projects';
import Faq from '../../front/pages/faq';
import Contact from '../../front/pages/contact';
import { Login } from '../pages/auth/components/Login';
import { Registration } from '../pages/auth/components/Registration';
import { ForgotPassword } from '../pages/auth/components/ForgotPassword';
import { MailVerification } from '../pages/auth/components/MailVerification';
import {CfaSpace} from "../pages/cfa/Cfa";
import {EnterpriseSpace} from "../pages/enterprise/enterprise";
import {AdminSpace} from "../pages/admin/AdminDashboard";
import {MasterLayout} from "../core/MasterLayout";
import {WithChildren} from "../../_rebusiness/helpers";
import {getCSSVariableValue} from "../../_rebusiness/assets/ts/_utils";
import TopBarProgress from "react-topbar-progress-indicator";
import {DisableSidebar} from "../../_rebusiness/layout/core";
import ProjectDetails from "../../front/pages/projectDetails";
import ProductDetails from "../../front/pages/productDetails";
import {CfaManager} from "../pages/admin/CfaManager";
import {ProjectsManager} from "../pages/admin/ProjectsManager";
import {ProductsManager} from "../pages/admin/ProductsManager";
import {EnterpriseManager} from "../pages/admin/EnterpriseManager";
import {SuplierManager} from "../pages/admin/SuplierManager";
import {ContributionsManager} from "../pages/admin/ContributionsManager";
import {SupplierSpace} from "../pages/supplier/supplier";
import Suppliers from "../../front/pages/suppliers";
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  const getRoleSpecificRoute = () => {
    if (currentUser?.role === 'admin') {
      return <Navigate to='/admin' />;
    } else if (currentUser?.role === 'entreprise') {
      return <Navigate to='/entreprise' />;
    } else if (currentUser?.role === 'cfa') {
      return <Navigate to='/cfa' />;
    } else if (currentUser?.role === 'fournisseur') {
      return <Navigate to='/fournisseur' />;
    } else {
      return <Navigate to='/connexion' />;
    }
  };
  return (
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            <Route path='/' element={<Home />} />
            <Route path='connexion' element={<Login />} />
            <Route path='faq' element={<Faq />} />
            <Route path='contact' element={<Contact />} />
            <Route path='projets' element={<Projects />} />
            <Route path='equipements/*' element={<Suppliers />} />
            <Route path='project/*' element={<ProjectDetails/>} />
            <Route path='product/*' element={<ProductDetails/>} />
            <Route path='inscription' element={<Registration />} />
            <Route path='mot-de-passe-oublie' element={<ForgotPassword />} />
            <Route path='verify-email/*' element={<MailVerification />} />

            {currentUser && currentUser.role === 'admin' && (
                <>
                  <Route element={<MasterLayout />}>
                    <Route path='admin/*' element={<SuspensedView>
                      <AdminSpace />
                    </SuspensedView>} />
                    <Route path='admin/gestion/cfa' element={<SuspensedView>
                      <CfaManager />
                    </SuspensedView>} />
                    <Route path='admin/gestion/projets' element={<SuspensedView>
                      <ProjectsManager />
                    </SuspensedView>} />
                    <Route path='admin/gestion/entreprises' element={<SuspensedView>
                      <EnterpriseManager />
                    </SuspensedView>} />
                    <Route path='admin/gestion/paiements' element={<SuspensedView>
                      <ContributionsManager />
                    </SuspensedView>} />
                    <Route path='admin/gestion/fournisseurs' element={<SuspensedView>
                      <SuplierManager />
                    </SuspensedView>} />
                  <Route path='admin/gestion/produits' element={<SuspensedView>
                      <ProductsManager />
                    </SuspensedView>} />
                  </Route>
                </>
            )}
            {currentUser && currentUser.role === 'entreprise' && (
                <>
                  <Route element={<MasterLayout />}>
                    <Route path='entreprise/*' element={<SuspensedView>
                      <EnterpriseSpace />
                    </SuspensedView>} />
                  </Route>
                </>
            )}
            {currentUser && currentUser.role === 'cfa' && (
                <>
                <Route element={<MasterLayout />}>
                  <Route path='cfa/*' element={<SuspensedView>
                    <CfaSpace />
                  </SuspensedView>} />
                </Route>
                </>
            )}
            {currentUser && currentUser.role === 'fournisseur' && (
                <>
                <Route element={<MasterLayout />}>
                  <Route path='fournisseur/*' element={<SuspensedView>
                    <SupplierSpace />
                  </SuspensedView>} />
                </Route>
                </>
            )}
            {!currentUser && <Route path='*' element={<Navigate to='/' />} />}
          </Route>
        </Routes>
      </BrowserRouter>
  );
};
const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
      <Suspense fallback={<TopBarProgress />}>
        <DisableSidebar>{children}</DisableSidebar>
      </Suspense>
  )
}

export { AppRoutes };
