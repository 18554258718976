import {ProjectListViewProvider, useProjectListView} from './core/ListViewProvider'
import {ProjectQueryRequestProvider} from './core/QueryRequestProvider'
import {ProjectQueryResponseProvider} from './core/QueryResponseProvider'
import {ProjectsListHeader} from './components/header/ProjectsListHeader'
import {ProjectsTable} from './table/ProjectsTable'
import {ProjectViewModal} from './user-edit-modal/ProjectViewModal'
import {KTCard} from '../../../../_rebusiness/helpers'

const ProjectsList = () => {
  const {itemIdForUpdate,setItemIdForUpdate} = useProjectListView()
  return (
    <>
      <KTCard>
        <ProjectsListHeader />
          <ProjectsTable/>
      </KTCard>
      {itemIdForUpdate !== undefined && <ProjectViewModal editProject={itemIdForUpdate} onModalClose={()=>setItemIdForUpdate(undefined)}/>}
    </>
  )
}

const ProjectsListWrapper = ({ type }: { type: string }) => (
  <ProjectQueryRequestProvider>
    <ProjectQueryResponseProvider type={type}>
      <ProjectListViewProvider>
        <ProjectsList />
      </ProjectListViewProvider>
    </ProjectQueryResponseProvider>
  </ProjectQueryRequestProvider>
)

export {ProjectsListWrapper}
