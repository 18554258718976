/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, QUERIES, toAbsoluteUrl} from '../../../../_rebusiness/helpers'
import {useAuth} from "../../auth";
import {getAdminStatistics, getAllUsersPending, updateUserStatus} from "../../../core/_requests";
import {User,Project} from '../management-list/core/_models'

type Props = {
  className: string
}

const ValidationWidget: React.FC<Props> = ({className}) => {
  const {currentUser,logout}=useAuth()
  const [Users, setUsers] = useState<User[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const UsersData = await getAllUsersPending("filter_status=admin verification pending",logout);
        setUsers(UsersData.data.data)
        // Do something with the stats, such as storing them in state or displaying them in the component
      } catch (error) {
        // Handle errors if any
        console.error(error);
      }
    };

    fetchData();
  }, [setUsers,logout]);
    async function validateUser(id: any, index: any) {
        try {
            await updateUserStatus(id, 'activated');
            // Update the status in the local state
            const updatedUsers = [...Users];
            updatedUsers[index].status = 'activated';
            setUsers(updatedUsers);
        } catch (error) {
            console.error(error);
        }
    }

    async function banUser(id: any, index: any) {
        try {
            await updateUserStatus(id, 'banned');
            // Update the status in the local state
            const updatedUsers = [...Users];
            updatedUsers[index].status = 'banned';
            setUsers(updatedUsers);
        } catch (error) {
            console.error(error);
        }
    }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Utilisateurs en attente </span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Validez vos nouveaux utilisateurs en un click</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Nom</th>
                <th className='min-w-140px'>Adresse</th>
                <th className='min-w-120px'>Contact</th>
                <th className='min-w-120px'>Téléphone</th>
                <th className='min-w-120px'>Status</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {Users?.map((user:User,index) => (
              <tr key={user?.id}>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      {user?.avatar&& <img src={toAbsoluteUrl(`${process.env.REACT_APP_PUBLIC_URL}/${user?.avatar}`)} alt='' />}
                      {!user?.avatar&& <div className="symbol-label fs-2 fw-bold text-success">
                        {user.contactFirstName?.slice(0,1)}.{user.contactLastName?.slice(0,1)}
                      </div>}
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        {user?.companyName}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {user?.email}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    {user?.companyAddress}
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                   {user?.companyZipCode}{user?.companyCity}
                  </span>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    {user?.contactFirstName} {user?.contactLastName}
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    {user?.contactPosition}
                  </span>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    {user?.companyPhoneNumber}
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    {user?.contactPhoneNumber}

                  </span>
                </td>
                <td className='text-end'>
                  <div className='d-flex   me-2'>
                    {user?.status ==='email verification pending' && <div className='badge badge-light-danger fw-bolder'>Email Non vérifié</div>}
                    {user?.status ==='admin verification pending' && <div className='badge badge-light-warning fw-bolder'>En attente de l'admin</div>}
                    {user?.status ==='banned' && <div className='badge badge-light-danger fw-bolder'>Banni</div>}
                    {user?.status ==='activated' && <div className='badge badge-light-success fw-bolder'>Validé</div>}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>

                        <a
                            href="#"
                            onClick={() => validateUser(user?.id,index)}
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                        >
                          <KTIcon iconName="check" className="fs-3" />
                        </a>
                        <a
                            href="#"
                            onClick={() => banUser(user?.id,index)}
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                        >
                          <KTIcon iconName="cross" className="fs-3" />
                        </a>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {ValidationWidget}
