import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Search, ThemeModeSwitcher, UserMenu} from '../../../partials'
import {useAuth} from "../../../../app/pages/auth";
import React from "react";
import {useThemeMode} from "../../../../app/core/theme-mode/ThemeModeProvider";

const Topbar = () => {
    const {currentUser} = useAuth()
    const {mode} = useThemeMode()
  return (
    <div className='d-flex align-items-center flex-shrink-0'>
      {/* Search */}
     {/* <Search
        className='w-lg-250px'
        mobileToggleBtnClass='btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px'
      />*/}

      {/* begin::Activities */}
      {/*<div className='d-flex align-items-center ms-3 ms-lg-4'>
        <div
          className='btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px'
          id='activities_toggle'
        >
          <KTIcon iconName='notification-bing' className='fs-1' />
        </div>
      </div>*/}
      {/* end::Activities */}


      {/* CHAT */}
      {/*<div className='d-flex align-items-center ms-3 ms-lg-4'>
        <div
          className='btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px position-relative'
          id='drawer_chat_toggle'
        >
          <KTIcon iconName='message-text-2' className='fs-1' />

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div>
      </div>*/}

      {/* begin::Sidebar Toggler */}
      {/*<button
        className='btn btn-icon btn-active-icon-primary w-40px h-40px d-xxl-none ms-2 me-n2 d-none'
        id='sidebar_toggler'
      >
        <KTIcon iconName='setting-2' className='fs-2x' />
      </button>*/}
      {/* end::Sidebar Toggler */}

        {currentUser?.role!='admin'? <div className=' ms-1'>

            <div
                className='  btn btn-sm btn-icon btn-active-color-primary position-relative me-n2'
                data-menu-trigger='click'
                data-menu-overflow='false'
                data-menu-placement='bottom-end'
            >

                <div className='symbol symbol-circle symbol-40px '>
                    {currentUser?.avatar ? <img src={toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL+'/'+currentUser?.avatar)} alt='avatar' />:<div className="symbol symbol-40px">
                        <div className="symbol-label fs-6 fw-bold bg-info text-inverse-info">{currentUser?.contactFirstName.slice(0,1)}</div></div>}
                </div>
            </div>
            <UserMenu />
        </div>:null}

      {/* begin::Theme mode */}
      <div className={'d-flex align-items-center ms-3 ms-lg-4'}>
        <ThemeModeSwitcher toggleBtnClass='btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px' />
      </div>
      {/* end::Theme mode */}

    </div>
  )
}

export {Topbar}
