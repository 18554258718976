import React, { FC, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { MenuComponent } from '../../../../../../_rebusiness/assets/ts/components';
import { ID, KTIcon, QUERIES } from '../../../../../../_rebusiness/helpers';
import { useContributionListView } from '../../core/ListViewProvider';
import { useContributionQueryResponse } from '../../core/QueryResponseProvider';
import {statusContributions} from '../../core/_requests';

type Props = {
    id: ID;
    project: any;
};

const ContributionActionsCell: FC<Props> = ({ id, project }) => {
    const { setItemIdForUpdate } = useContributionListView();
    const { query } = useContributionQueryResponse();
    const queryClient = useQueryClient();

    useEffect(() => {
        MenuComponent.reinitialization();
    }, []);


    const validateContribution = useMutation(() => statusContributions(id, 'succeeded'), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
            // ✅ update detail view directly
            queryClient.invalidateQueries([`${QUERIES.CONTRIBUTIONS_LIST}-${query}`]);
        },
    });
    const refundContribution = useMutation(() => statusContributions(id, 'refunded'), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
            // ✅ update detail view directly
            queryClient.invalidateQueries([`${QUERIES.CONTRIBUTIONS_LIST}-${query}`]);
        },
    });

    const rejectContribution = useMutation(() => statusContributions(id, 'rejected'), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
            // ✅ update detail view directly
            queryClient.invalidateQueries([`${QUERIES.CONTRIBUTIONS_LIST}-${query}`]);
        },
    });

    return (
        <>
            <a
                href='#'
                className='btn btn-light btn-active-light-primary btn-sm'
                data-menu-trigger='click'
                data-menu-placement='bottom-end'
            >
                Status
                <KTIcon iconName='down' className='fs-5 m-0' />
            </a>
            {/* begin::Menu */}
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                data-menu='true'
            >

                <div className='menu-item px-3'>
                    <a className='menu-link px-3' onClick={async () => await validateContribution.mutateAsync()}>
                        Confirmé
                    </a>
                </div>
                <div className='menu-item px-3'>
                    <a className='menu-link px-3' onClick={async () => await refundContribution.mutateAsync()}>
                        Remboursé
                    </a>
                </div>
                <div className='menu-item px-3'>
                    <a className='menu-link px-3' onClick={async () => await rejectContribution.mutateAsync()}>
                        Rejeté
                    </a>
                </div>

            </div>
            {/* end::Menu */}
        </>

    );
};

export { ContributionActionsCell };
