/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {IconUserModel} from '../profile/ProfileModels'
import {UsersList} from '../profile/components/UsersList'
import {toAbsoluteUrl} from '../../../_rebusiness/helpers'

type Props = {
    id: number
    icon: string
    badgeColor: string
    status: string
    statusColor: string
    title: string
    description: string
    date: string
    budget: string
    link: string
    progress: number
    users?: Array<IconUserModel>
    onClick: () => void
}

const ProjectCard: FC<Props> = ({
                              id,
                              icon,
                              badgeColor,
                              status,
                              statusColor,
                              title,
                              description,
                              date,
                              budget,
                              progress,
                              users = undefined,
                                    link,
                                    onClick,
                          }) => {
    const [isCopied, setIsCopied] = useState(false);
    const handleCopyClick = () => {
        // Create a hidden input element
        const inputElement = document.createElement('input');
        inputElement.style.position = 'fixed';
        inputElement.style.opacity = '0';
        inputElement.value = "https//crowdfundcfa.fr/"+link;

        document.body.appendChild(inputElement);
        inputElement.select();
        document.execCommand('copy');
        document.body.removeChild(inputElement);

        setIsCopied(true);
    };
    const dateObject = new Date(date);
    const day = dateObject.getUTCDate();
    const month = dateObject.getUTCMonth() + 1; // Months are zero-based, so we add 1
    const year = dateObject.getUTCFullYear();
    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
    return (
        <Link
            to={'#'}

            className='card border border-2 border-gray-300 border-hover'
        >
            <div className='card-header ribbon ribbon-top border-0 pt-9'>
                <div className='card-title m-0'>
                <Link to={link} style={{minHeight:'52px'}} className='fs-3 fw-bolder text-dark'>{title}</Link>
                </div>
                    <div className={`ribbon-label bg-${badgeColor}`}>{status}</div>
            </div>
            <div className='card-body p-9'>
                <div className=' me-10 mb-10 bg-light' style={{
                    width: '100%',
                    height: '200px',
                    overflow:'hidden',
                    objectFit:'cover',
                }}>
                    <img style={{
                        width: '100%',
                        height: '200px',
                        overflow:'hidden',
                        objectFit:'cover',
                    }} src={toAbsoluteUrl(icon)} alt='card2' className='p-3' />
                </div>
                <p style={{minHeight:'125px'}} className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{description}</p>

                <div className='d-flex flex-wrap mb-5'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                        <div className='fs-6 text-gray-800 fw-bolder'>{formattedDate}</div>
                        <div className='fw-bold text-gray-400'>Date limite</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                        <div className='fs-6 text-gray-800 fw-bolder'>{budget}</div>
                        <div className='fw-bold text-gray-400'>Objectif</div>
                    </div>
                </div>
                <div >

                    {status=="en ligne" && <button  style={{width:'100%',}} className="main-btn-small  mb-5" onClick={handleCopyClick} disabled={isCopied}>
                            {isCopied ? 'Copié!' : 'Copier le lien '}
                        </button>}
                    {status !=='refusé' && <button  style={{width:'100%'}} className="main-btn-small " onClick={onClick} >
                           Modifier
                        </button>}
                </div>

               {/* <div
                    className='h-4px w-100 bg-light mb-5'
                    data-bs-toggle='tooltip'
                    title='This project completed'
                >
                    <div
                        className={`bg-${statusColor} rounded h-4px`}
                        role='progressbar'
                        style={{width: `${progress}%`}}
                    ></div>
                </div>*/}

               {/* <UsersList users={users} />*/}
            </div>
        </Link>
    )
}

export {ProjectCard}
