import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_rebusiness/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_rebusiness/layout/core'
import {MasterInit} from './core/MasterInit'
import {AuthInit} from './pages/auth'
import Home from '../front/pages/home'
import ContextProvider from "../front/context/ContextProvider";

const App = () => {
  return (

  <Suspense fallback={<LayoutSplashScreen />}>
    <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <ContextProvider>
            <Outlet />
            <MasterInit />
            </ContextProvider>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
