/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useState,useEffect} from "react";
import {Button} from 'react-bootstrap'
import {Product, ProductLight} from "../../../core/_models";
import {getProductByUniqueId} from "../../../core/_requests";
import {toAbsoluteUrl} from "../../../core/AssetHelpers";
import {util} from "prismjs";
import type = util.type;
import {useAuth} from "../../auth";
import {KTIcon} from "../../../../_rebusiness/helpers";
export type StepProps = {
  formik:any
  setButton: (fieldsToUpdate: any) => void
}
const Step1 = ({formik , setButton}: StepProps) => {
    const { currentUser,logout,setCurrentUser } = useAuth();
    const [ref, setRef] = useState('');

    const [supplierSiret, setSupplierSiret] = useState('');
    const [supplierAddress, setSupplierAddress] = useState('');
    const [designation, setDesignation] = useState('');
    const [qtt, setQtt] = useState(0);
    const [price, setPrice] = useState(0);
    const [costTotal, setCostTotal] = useState(0);
    const [prodComment, setProdComment] = useState('');
    const [productList, setProductList] = useState<ProductLight[]>([]);
    const [status, setStatus] = useState('question');
    const [question, setQuestion] = useState('Avez vous identifié le prix de vos besoins en équipement? ');
    const [questionStep, setQuestionStep] = useState(0);

  const updateProjectCost =  () => {
    setCostTotal(0);
    let totalCost = 0;
    // Calculate the total cost of all products in the productList
    productList.forEach(product => {
      const productCost = Number(product.productPrice) * Number(product.quantity);
      totalCost += productCost;
    });
    /*totalCost = parseFloat((totalCost * 1.15).toFixed(2))*/
    setCostTotal(totalCost);
    // Update the projectCost field in Formik with the total cost
    formik.setFieldValue('projectCost', totalCost);
  };
  useEffect(() => {
    updateProjectCost();
  }, [productList]);

  const answer=(ans:string)=>{
    if (questionStep == 0){
      if(ans == 'yes'){
        setQuestion('Votre fournisseur est il inscrit sur notre plateforme ?')
        setQuestionStep(1)
      }else{
        setQuestionStep(11)
        setQuestion("Merci de bien vouloir chiffrer vos besoins en équipement. Réinititialiez cette étape une fois que ce sera fait.")
        setButton(true);
      }
    }
    if (questionStep == 1){
      if(ans == 'yes'){
        setStatus('internalSupplier')
        setButton(true);
      }else{
        setQuestion('Avez vous réalisé un devis ou connaissez vous la reférence et le prix de chaque équipement ?')
        setQuestionStep(2)
      }
    }
    if (questionStep == 2){
      if(ans == 'yes'){
        setStatus('externalSupplier')
        setButton(true);
      }else{
        setQuestion('Contacter votre fournisseur pour avoir les informations et reinitialiser cette étape pour remplir la liste de materiel. ')
      }
      setQuestionStep(11)
    }
  }
  const searchProduct = async (ref: any) => {
    setProdComment('');
    if (qtt === 0) {
      setProdComment('La quantité est obligatoire');
    } else if (ref === '') {
      setProdComment('Une référence produit est obligatoire');
    } else if (ref !== '' && qtt > 0) {
      try {
        const product = await getProductByUniqueId(ref.split(' ').join(''),logout);
        if (product && product.product) {
          const existingProduct = productList.find(p => p.uniqueID === product.product.uniqueID);
          if (existingProduct) {
            existingProduct.quantity += qtt;
            setProdComment('Produit déjà présent dans la liste. Quantité mise à jour.');
          } else {
            const updatedProduct = { ...product.product, quantity: qtt };
            const updatedProductList = [...productList, updatedProduct];
            setProductList(updatedProductList);
            formik.setFieldValue('projectProducts', JSON.stringify(updatedProductList));
            setProdComment('Produit ajouté avec succès.');
          }
          setRef('');
          setQtt(0);
        } else {
          setProdComment('Produit non trouvé');
        }
      } catch (error) {
        console.error(error);
      }
    }
    updateProjectCost();
  };
  const addProduct = async (ref: string, designation: string, qtt: number, price: number) => {
    setProdComment('');
    if (qtt === 0) {
      setProdComment('La quantité est obligatoire');
      return;
    }else if (price === 0) {
      setProdComment('Le prix du produit est obligatoire');
      return;
    } else if (designation === '') {
      setProdComment('La designation du produit est obligatoire');
      return;
    }else if (supplierAddress === '') {
      setProdComment("L'addresse du fournisseur est obligatoire");
      return;
    }else if (ref === '') {
      setProdComment('Le nom du fournisseur est obligatoire');
      return;
    }else if (supplierSiret === '') {
      setProdComment('Le siret du fournisseur est obligatoire');
      return;
    }

    if (price > 0 && designation !== '' && qtt > 0) {
      try {
        const newProduct: Product = {
          uniqueID: ref.split(' ').join(''),
          id: 0,
          productName: designation,
          productDescription: '',
          productCategory: 'General',
          productPrice: price,
          productStock: 0,
          productImages: '',
          UserId: 0,
          userId: 0,
          status: 'new',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          visits: 0,
          quantity:qtt,
        };

        const existingProduct = productList.find(p => p.uniqueID === newProduct.uniqueID && p.productName == newProduct.productName && p.productPrice == newProduct.productPrice);
        if (existingProduct) {
          existingProduct.quantity += qtt;
          setProdComment('Produit déjà présent dans la liste. Quantité mise à jour.');
        } else {
          const updatedProductList = [...productList, newProduct];
          setProductList(updatedProductList);
          formik.setFieldValue('projectProducts', JSON.stringify(updatedProductList));
          setProdComment('Produit ajouté avec succès.');
        }
        setRef('');
        setSupplierAddress('');
        setSupplierSiret('');
        setQtt(0);
        setDesignation('');
        setPrice(0);
      } catch (error) {
        console.error(error);
        setProdComment('Erreur lors de l\'ajout du produit');
      }
    }
    updateProjectCost();
  };



  const deleteProduct = async (ref: any,price:number,quantity:number) => {
    setProdComment('');
      try {
        setProductList(productList => productList.filter(product => product.uniqueID !== ref));
        formik.setFieldValue('projectProducts', JSON.stringify(productList.filter(product => product.uniqueID !== ref)));
    } catch (error) {
      console.error(error);
    }
    await updateProjectCost();
  }

  return (
    <>
      {/*begin::Step 3 */}
      <div className='pb-5 current' data-stepper-element='content'>
        <div className='w-100' style={{minHeight:'400px'}}>
          {status =="question"  && <div>
            <label className='required fs-5 fw-semibold mb-2'> {question}</label>
            {questionStep !== 11 && <div className='d-flex flex-stack pt-10'>
                <button
                    type="button"
                    className='main-btn-small'
                    onClick={()=>answer('no')}
                >
                  NON
                </button>

            <button
                type="button"
                className='main-btn-small'
                onClick={()=>answer('yes')}
                style={{display: 'flex', alignItems: 'center'}}
            >
              OUI
            </button>
          </div>}
          </div>}
          {status == 'internalSupplier' && <><div className='fv-row mb-10'>
            <label className='required fs-5 fw-semibold mb-2'>Coût du projet (mis a jour automatiquement avec la liste des produits)</label>
            <input
                readOnly={true}
                type='text'
                style={{backgroundColor: '#FFFFFF',
                  borderColor: '#FFFFFF',
                  textAlign: 'center',
                  fontSize: 'xxx-large'}}
                className='form-control form-control-lg form-control-solid'
                placeholder='Coût du projet'
                value={formik.values.projectCost +' €'}
            />
            {formik.touched.projectCost && formik.errors.projectCost && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.projectCost}</div>
                </div>
            )}
          </div>
            <div className='fv-row mb-10'>
              <label className='required fs-5 fw-semibold mb-2'>Liste des produits</label>
              <span><a  href='/equipements' target={'_blank'}>Rechercher vos produits sur notre site</a>, copier la référence produit et coller la juste en dessous avec la quantité.</span>


              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input style={{width:'45%',marginRight:'5px'}} type='text'  value={ref}  className='form-control form-control-md form-control-solic' placeholder='référence du produit'
                       onChange={e => setRef(e.target.value)}
                />
                <input style={{width:'45%',marginRight:'5px'}} type='number
              ' className='form-control form-control-md form-control-solic'  value={qtt}  placeholder='quantité'
                       onChange={e => setQtt(Number(e.target.value))}
                />
                <button  type={"button"} className='main-btn-small-iadd main-btn-3' onClick={() => searchProduct(ref)}>
                  <i className="fas fa-plus"></i>
                </button>
              </div>
              {prodComment&&<div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{prodComment}</div>
              </div>}

              {productList.length>0? productList.map((product:ProductLight) => (
                  <div style={{margin:'10px'}} key={product?.uniqueID} className='d-flex align-items-center mb-7'>
                    {/* begin::Avatar */}
                    <div className='symbol symbol-2by3 symbol-50px me-5'>
                      <img src={toAbsoluteUrl(process.env.REACT_APP_PUBLIC_URL+'/'+product?.productImages)} className='' alt='' />
                    </div>
                    {/* end::Avatar */}
                    {/* begin::Text */}
                    <div className='flex-grow-1'>
                      <a href={'/product/'+product?.uniqueID} className='text-dark fw-bold text-hover-primary fs-6'>
                        {product?.productName.slice(0,100) }
                      </a>
                      <span className='text-dark fw-bold  d-block fw-semibold'>Quantité: {product.quantity} | Prix total: {Number(product.quantity * product.productPrice)+' €'}</span>
                      <span className='text-muted d-block fw-semibold'>{product.productDescription?.slice(0,100)+'[...]'}</span>
                    </div>
                    <div className='end-0 pl-10 '>
                      <button type={"button"} className='main-btn-small-idel main-btn-3' onClick={() => deleteProduct(product?.uniqueID,product?.productPrice,product?.quantity)}>
                        <i className="fas fa-trash-can"></i>
                      </button>
                    </div>
                    {/* end::Text */}
                  </div>
              )):null}
              {formik.errors.projectProducts && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.projectProducts}</div>
                  </div>
              )}
            </div></>}
          {status === 'externalSupplier' && (<>
            <div className='fv-row mb-10'>
              <label className='required fs-5 fw-semibold mb-2'>Coût du projet (mis a jour automatiquement avec la liste des produits)</label>
              <input
                  readOnly={true}
                  type='text'
                  style={{backgroundColor: '#FFFFFF',
                    borderColor: '#FFFFFF',
                    textAlign: 'center',
                    fontSize: 'xxx-large'}}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Coût du projet'
                  value={formik.values.projectCost +' €'}
              />
              {formik.touched.projectCost && formik.errors.projectCost && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.projectCost}</div>
                  </div>
              )}
            </div>
              <div style={{padding: '20px',
                backgroundColor: '#2af0b460',
                borderColor: '#2af0b460',
                borderWidth: '2px',
                borderStyle: 'solid',}}>
                <label className='fs-5 fw-semibold mb-2'>Ajouter un équipement</label>
                <div style={{ alignItems: 'center', marginBottom: '10px' }}>
                  <div className='fv-row mb-10' style={{display: 'flex',}}>

                    <div style={{width: '50%', marginRight: '3%'}}>
                      <label className='required fs-5 fw-semibold mb-2'>Designation</label>
                      <input
                          style={{width: '100%', marginRight: '3%'}}
                          type='text'
                          className='form-control form-control-md form-control-solid'
                          placeholder='Désignation'
                          value={designation}
                          onChange={e => setDesignation(e.target.value)}
                      />
                    </div>
                    <div style={{width: '50%', marginRight: '3%'}}>
                      <label className='required fs-5 fw-semibold mb-2'>Fournisseur</label>
                      <input
                          style={{width: '100%', marginRight: '3%'}}
                          type='text'
                          className='form-control form-control-md form-control-solid'
                          placeholder='Fournisseur'
                          value={ref}
                          onChange={e => setRef(e.target.value)}
                      />
                    </div>

                  </div>
                  <div className='fv-row mb-10' style={{display: 'flex',}}>
                    <div style={{width: '50%', marginRight: '3%'}}>
                      <label className='required fs-5 fw-semibold mb-2'>Siret Fournisseur</label>
                      <input
                          style={{width: '100%', marginRight: '3%'}}
                          type='text'
                          className='form-control form-control-md form-control-solid'
                          placeholder='Siret'
                          value={supplierSiret}
                          onChange={e => setSupplierSiret(e.target.value)}
                      />
                    </div>
                    <div style={{width: '50%', marginRight: '3%'}}>
                      <label className=' required fs-5 fw-semibold mb-2'>Adresse Fournisseur</label>
                      <input
                          style={{width: '100%', marginRight: '3%'}}
                          type='text'
                          className='form-control form-control-md form-control-solid'
                          placeholder='Addresse du Fournisseur'
                          value={supplierAddress}
                          onChange={e => setSupplierAddress(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='fv-row mb-10' style={{display: 'flex',}}>
                    <div style={{width: '50%', marginRight: '3%'}}>
                      <label className='required fs-5 fw-semibold mb-2'>Quantité</label>
                      <input
                          style={{width: '100%', marginRight: '3%'}}
                          type='number'
                          className='form-control form-control-md form-control-solid'
                          placeholder='Quantité'
                          value={qtt}
                          onChange={e => setQtt(Number(e.target.value))}
                      />
                    </div>
                    <div style={{width: '50%', marginRight: '3%'}}>
                      <label className='required fs-5 fw-semibold mb-2'>Prix unitaire TTC</label>
                      <input
                          style={{width: '100%', marginRight: '3%'}}
                          type='number'
                          className='form-control form-control-md form-control-solid'
                          placeholder='Prix'
                          value={price}
                          onChange={e => setPrice(parseFloat(e.target.value))}
                      />
                    </div>
                  </div>
                </div>
                  <button  type={"button"} className='main-btn-small w-100' onClick={() => addProduct(ref, designation, qtt, price)}>
                    Ajouter <i className="fas fa-plus"></i>
                  </button>
                {prodComment&&<div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{prodComment}</div>
                </div>}

                {productList.length>0? productList.map((product:ProductLight) => (
                    <div style={{margin:'10px'}} key={product?.uniqueID} className='d-flex align-items-center mb-7'>

                      <div className='flex-grow-1'>
                        <a href={'/product/'+product?.uniqueID} className='text-dark fw-bold text-hover-primary fs-6'>
                          {product?.productName.slice(0,100) }
                        </a>
                        <span className='text-dark fw-bold  d-block fw-semibold'>Quantité: {product.quantity} | Prix total: {Number(product.quantity * product.productPrice)+' €'}</span>
                      </div>
                      <div className='end-0 pl-10 '>
                        <button type={"button"} className='main-btn-small-idel main-btn-3' onClick={() => deleteProduct(product?.uniqueID,product?.productPrice,product?.quantity)}>
                          <i className="fas fa-trash-can"></i>
                        </button>
                      </div>
                      {/* end::Text */}
                    </div>
                )):null}
              </div>
                {formik.errors.projectProducts && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.projectProducts}</div>
                    </div>
                )}
              </>
          )}

          { questionStep !== 0 && status!== 'externalSupplier' && status !== 'internalSupplier' && <button
              type='button'
              className='main-btn-2 me-3 main-btn-small w-100 mt-10'

              onClick={()=>{
                setQuestion('Avez vous identifié un fournisseur pour le materiel?')
                setStatus('question')
                setQuestionStep(0)
                setButton(false)
              }}
          >
            Réinitialiser
          </button>}
        </div>
      </div>
      {/*end::Step 3 */}
    </>
  )
}

export {Step1}
