import React, {FC,useEffect} from 'react'
import {KTIcon} from '../../../../../_rebusiness/helpers'
import {Link} from 'react-router-dom'

export type StepProps = {
  formik:any
}

const RegistrationStep4 = ({formik}: StepProps) => {
  return (
      <div className='w-100'>
        <div className='pb-8 pb-lg-10'>
          <h2 className='fw-bolder text-dark'>La sécurité de votre compte est notre priorité .</h2>

          <div className='text-gray-400 fw-bold fs-6'>
           Une dernière petite étape
          </div>
        </div>

        <div className='mb-0'>
          <div className='fs-6 text-gray-600 mb-5'>
              Un e-mail vous a été envoyé pour valider votre adresse et activer votre compte
          </div>

          <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
            <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>Nous avons besoin que vous vérifiez votre adresse email</h4>
                <div className='fs-6 text-gray-600'>
                    Pour entrer dans l’aventure du crowdfunding, rendez vous sur votre boite mail et cliquez sur le lien.

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export {RegistrationStep4}
