import { projectDetailsStory } from "../../../data/projectsArea";
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import {toAbsoluteUrl} from "../../../../app/core/AssetHelpers";

const { id, text, lists, text2, items, text3, image, images } =
  projectDetailsStory;

const ProjectDetailsStory = ({ project,getClassName }) => {
  return (
    <div className={getClassName?.(id)} id={id} role="tabpanel">
      <div className="project-details-content-top mt-20">
          <pre style={{fontFamily: 'inherit',
              whiteSpace: 'pre-wrap',
              margin: 0,
              padding: 0,
              fontSize: '1.5em',color: 'dimgrey'}}><b>{project.projectName} </b></pre>
        <pre style={{fontFamily: 'inherit',
            whiteSpace: 'pre-wrap',
            margin: 0,
            padding: 0,
            fontSize: '14px',color: 'dimgrey'}}>{project.projectDescription}</pre><br/>

          {project.projectDiploma && <div><pre style={{fontFamily: 'inherit',
              whiteSpace: 'pre-wrap',
              margin: 0,
              padding: 0,
              fontSize: '1.5em',color: 'dimgrey'}}><b>Diplôme(s) associé(s): </b></pre>
              <pre style={{fontFamily: 'inherit',
              whiteSpace: 'pre-wrap',
              margin: 0,
              padding: 0,
              fontSize: '14px',color: 'dimgrey'}}>{project.projectDiploma}</pre></div>}<br/>


        {/*<ul>
          {lists.map((list, i) => (
            <li key={i}>
              <i className="flaticon-check"></i> {list}
            </li>
          ))}
        </ul>
        <div className="project-details-thumb">
          <Image src={image.src} alt="" />
        </div>
      </div>
      <div className="project-details-item">
        <p>{text2}</p>
        {items.map(({ id, title, text, className = "" }) => (
          <div className={`item ${className}`} key={id}>
            <i className="flaticon-checkmark"></i>
            <h5 className="title">{title}</h5>
            <p>{text}</p>
          </div>
        ))}
        <Row>
          {images.map((image, i) => (
            <Col lg={6} md={6} sm={6} key={i}>
              <div className="project-details-thumb">
                <Image src={image.src} alt="" />
              </div>
            </Col>
          ))}
        </Row>
        <p className="text">{text3}</p>*/}
      </div>
    </div>
  );
};

export default ProjectDetailsStory;
