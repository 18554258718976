import {FC} from 'react'

type Props = {
  status?: string
}

const ContributionStatus: FC<Props> = ({status}) => (
  <>{status ==='pending' && <div className='badge badge-light-warning fw-bolder'>En attente </div>}
    {status ==='rejected' && <div className='badge badge-light-danger fw-bolder'>Rejeté</div>}
    {status ==='refunded' && <div className='badge badge-light-danger fw-bolder'>Remboursé</div>}
    {status ==='success' && <div className='badge badge-light-success fw-bolder'>Confirmé</div>}</>
)

export {ContributionStatus}
