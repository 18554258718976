import React from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../_rebusiness/helpers";
import HeaderInfo from "./HeaderInfo";
import headerData from "../../data/headerData";
import useScroll from "../../hooks/useScroll";
import {useLocation} from "react-router-dom";
import HeaderMenu from "../../components/Header/HeaderMenu";
import { useRootContext } from "../../context/context";

const { logo2, navItems, phone, icon, socials } = headerData;

const HeaderFront = () => {
    const { scrollTop } = useScroll(160);
     const { toggleMenu, toggleSearch } = useRootContext();
    const location = useLocation();
    return (

        <header className={`header-area `}>
            <div className={`main-header${scrollTop ? " sticky" : ""}`}>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="main-header-item">
                                <div className="main-header-menus d-flex justify-content-between align-items-center">
                                    <div className="header-logo">
                                        <a href="/">
                                            <Image src={toAbsoluteUrl(
                                                '/media/assets/images/logo.svg'
                                            )} style={{height:'25px'}} alt="logo" />
                                        </a>
                                    </div>
                                    <div className="header-menu d-none d-lg-block">
                                        <ul>
                                            {navItems.map((navItem,index) => (
                                                <li key={index}>
                                                    <a
                                                        className={location.pathname == navItem.href ? "active" : ""}
                                                        href={navItem.href}
                                                    >
                                                        {navItem.name}{" "}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="header-info d-flex align-items-center"></div>
                                    <div
                                    onClick={toggleMenu}
                                    className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                >
                                    <i className="fa fa-bars"></i>
                                </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </header>
    );
};

export default HeaderFront;
