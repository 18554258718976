import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import clsx from "clsx";

export type StepProps = {
  formik:any
}


const RegistrationStep3 = ({formik}: StepProps) => {
  return (
      <div className='w-100'>
        {/*<div className='pb-10 pb-lg-12'>
          <h2 className='fw-bolder text-dark'>Business Details</h2>

          <div className='text-gray-400 fw-bold fs-6'>
            If you need more info, please check out
            <a href='/dashboard' className='link-primary fw-bolder'>
              {' '}
              Help Page
            </a>
            .
          </div>
        </div>*/}



        {/* begin::Form group Firstname */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>{`Prénom du contact`}</label>
          <input
              placeholder={`Entrer votre prénom`}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('contactFirstName')}
              className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.contactFirstName && formik.errors.contactFirstName,
                  },
                  {
                    'is-valid': formik.touched.contactFirstName && !formik.errors.contactFirstName,
                  }
              )}
          />
          {formik.touched.contactFirstName && formik.errors.contactFirstName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.contactFirstName}</span>
                </div>
              </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>{`Nom du contact`}</label>
          <input
              placeholder={`Entrer votre nom`}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('contactLastName')}
              className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.contactLastName && formik.errors.contactLastName,
                  },
                  {
                    'is-valid': formik.touched.contactLastName && !formik.errors.contactLastName,
                  }
              )}
          />
          {formik.touched.contactLastName && formik.errors.contactLastName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.contactLastName}</span>
                </div>
              </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>{`Téléphone du contact`}</label>
          <input
              placeholder={`Entrer votre numéro de telephone`}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('contactPhoneNumber')}
              className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.contactPhoneNumber && formik.errors.contactPhoneNumber,
                  },
                  {
                    'is-valid': formik.touched.contactPhoneNumber && !formik.errors.contactPhoneNumber,
                  }
              )}
          />
          {formik.touched.contactPhoneNumber && formik.errors.contactPhoneNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.contactPhoneNumber}</span>
                </div>
              </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>{`Poste occupé`}</label>
          <input
              placeholder={`Entrer votre poste`}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('contactPosition')}
              className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.contactPosition && formik.errors.contactPosition,
                  },
                  {
                    'is-valid': formik.touched.contactPosition && !formik.errors.contactPosition,
                  }
              )}
          />
          {formik.touched.contactPosition && formik.errors.contactPosition && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.contactPosition}</span>
                </div>
              </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-check form-check-inline' htmlFor='login_toc_agree'>
            <input
                className='form-check-input'
                type='checkbox'
                id='login_toc_agree'
                {...formik.getFieldProps('acceptTerms')}
            />
              <span>
            J'accepte les {' '}
                  <a
                      href='/mentions_legales.pdf'
                      target='_blank'
                      className='ms-1 link-primary'
                  >
              règles générales
            </a> et le <a
                  href='/mandat.pdf'
                  target='_blank'
                  className='ms-1 link-primary'
              >
             mandat
            </a>
            .
          </span>
          </label>
            {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.acceptTerms}</span>
                </div>
              </div>
          )}
        </div>
      </div>
   /* <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Business Details</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Business Name</label>

        <Field name='businessName' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessName' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Shortened Descriptor</span>
        </label>

        <Field
          name='businessDescriptor'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessDescriptor' />
        </div>

        <div className='form-text'>
          Customers will see this shortened version of your statement descriptor
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Corporation Type</label>

        <Field
          as='select'
          name='businessType'
          className='form-select form-select-lg form-select-solid'
        >
          <option></option>
          <option value='1'>S Corporation</option>
          <option value='1'>C Corporation</option>
          <option value='2'>Sole Proprietorship</option>
          <option value='3'>Non-profit</option>
          <option value='4'>Limited Liability</option>
          <option value='5'>General Partnership</option>
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessType' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label'>Business Description</label>

        <Field
          as='textarea'
          name='businessDescription'
          className='form-control form-control-lg form-control-solid'
          rows={3}
        ></Field>
      </div>

      <div className='fv-row mb-0'>
        <label className='fs-6 fw-bold form-label required'>Contact Email</label>

        <Field name='businessEmail' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessEmail' />
        </div>
      </div>
    </div>*/
  )
}

export {RegistrationStep3}
