/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_rebusiness/helpers'
import {getAdminActivities, getAdminStatistics} from "../../../core/_requests";
import {useAuth} from "../../auth";

type Props = {
  className: string
}
type Activity = {
  id: number;
  userId: number;
  activityAction: string;
  activityData: any;
  activityDescription: string;
  createdAt: string;
  updatedAt: string;
};
const ActivitiesWidget: React.FC<Props> = ({className}) => {
  const {currentUser,logout}=useAuth()
  const [activities, setActivities] = useState<Activity[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const activitiesData= await getAdminActivities(logout)
        setActivities(activitiesData)
        // Do something with the stats, such as storing them in state or displaying them in the component
      } catch (error) {
        // Handle errors if any
        console.error(error);
      }
    };

    fetchData();
  }, [logout]);

  const getBadgeColor = (activityAction: string) => {
    // Customize the colors based on the activityAction categories
    switch (activityAction) {
      case 'User Login':
        return 'text-warning'; // Yellow color for User Login
      case 'User Registration':
        return 'text-primary'; // Blue color for User Registration
      case 'Project Created':
        return 'text-success'; // Green color for Project Created
        // Add more cases for other activityAction categories if needed
      default:
        return 'text-info'; // Default color for other categories
    }
  };
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Activités</span>
          <span className='text-muted fw-semibold fs-7'>Les dernières activités</span>
        </h3>
        <div className='card-toolbar'>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {activities.map((activity) => (
              <div className='timeline-item' key={activity.id}>
                {/* begin::Label */}
                <div className='timeline-label fw-bold text-gray-800 fs-6'>
                  {new Date(activity?.createdAt).toLocaleDateString('fr-FR', {
                    day: 'numeric',
                    month: 'long',
                  })}<br/>
                  {new Date(activity?.createdAt).toLocaleTimeString('fr-FR', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </div>
                {/* end::Label */}
                {/* begin::Badge */}
                <div className='timeline-badge'>
                  <i className={`fa fa-genderless ${getBadgeColor(activity?.activityAction)} fs-1`}></i>
                </div>
                {/* end::Badge */}
                {/* begin::Text */}
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  {activity?.activityDescription} {/* Display activity description */}
                </div>
                {/* end::Text */}
              </div>
          ))}
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export {ActivitiesWidget}
