/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useProductQueryResponseLoading, useProductQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useProductQueryRequest} from '../../core/QueryRequestProvider'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const ProductsListPagination = () => {
  const pagination = useProductQueryResponsePagination()
  const isLoading = useProductQueryResponseLoading()
  const {updateState} = useProductQueryRequest()
  console.log(pagination)
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }
    updateState({page, items_per_page: pagination.items_per_page || 10})
  }

  return (
    <div className='row'>
      {/*<div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>*/}
      <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='table_projects_paginate'>
          <ul className='pagination'>
            {pagination.links
              ?.map((link) => {
                return {...link, label: mappedLabel(link.label)}
              })
              .map((link) => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: pagination.page === link.page,
                    disabled: isLoading,
                    previous: link.label === 'Précédent',
                    next: link.label === 'Suivant',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Précédent' || link.label === 'Suivant',
                      'me-5': link.label === 'Précédent',
                    })}
                    onClick={() => updatePage(link.page)}
                    style={{cursor: 'pointer'}}
                  >
                    {mappedLabel(link.label)}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ProductsListPagination}
