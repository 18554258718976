import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { MetronicI18nProvider } from './_rebusiness/i18n/Metronici18n';
import { AppRoutes } from './app/routing/AppRoutes';
import { AuthProvider, setupAxios } from './app/pages/auth';
import'./front/styles/style.css';
import'./front/styles/default.css';
import'./front/styles/swiper-bundle.min.css';
import"./front/assets/vendors/animate.min.css";
import"./front/assets/vendors/flaticon.css";
import'./_rebusiness/assets/fonticon/fonticon.css';
import'./_rebusiness/assets/keenicons/duotone/style.css';
import'./_rebusiness/assets/keenicons/outline/style.css';
import'./_rebusiness/assets/keenicons/solid/style.css';
import'./_rebusiness/assets/sass/style.scss';
import'./_rebusiness/assets/sass/plugins.scss';
import'./_rebusiness/assets/sass/style.react.scss';
const googleFontsLink = document.createElement('link');
googleFontsLink.href = 'https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Roboto:wght@300;400;500;700;900&display=swap';
googleFontsLink.rel = 'stylesheet';
document.head.appendChild(googleFontsLink);

Chart.register(...registerables);
setupAxios(axios);

const queryClient = new QueryClient();
const container = document.getElementById('root');
const location = window.location.pathname;
/*if (container) {
    switch (location) {
        case '/':
        case '/faq':
        case '/contact':
        case '/explorer':
            require('./front/styles/style.css');
            require('./front/styles/default.css');
            require('./front/styles/swiper-bundle.min.css');
            require("./front/styles/bootstrap.min.css");
            require("./front/assets/vendors/animate.min.css");
            require("./front/assets/vendors/font-awesome.min.css");
            require("./front/assets/vendors/flaticon.css");
            /!*require("node_modules/swiper/swiper-bundle.min.css");
            require("react-modal-video/css/modal-video.css");*!/
            // Dynamically add the Google Fonts URL to the head
            const googleFontsLink = document.createElement('link');
            googleFontsLink.href = 'https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Roboto:wght@300;400;500;700;900&display=swap';
            googleFontsLink.rel = 'stylesheet';
            document.head.appendChild(googleFontsLink);
            break;
        // Add more cases for other locations and their corresponding CSS files
        default:
            // Use a default CSS file if no matching location found
            require('./_rebusiness/assets/fonticon/fonticon.css');
            require('./_rebusiness/assets/keenicons/duotone/style.css');
            require('./_rebusiness/assets/keenicons/outline/style.css');
            require('./_rebusiness/assets/keenicons/solid/style.css');
            require('./_rebusiness/assets/sass/style.scss');
            require('./_rebusiness/assets/sass/plugins.scss');
            require('./_rebusiness/assets/sass/style.react.scss');
            break;
    }

    createRoot(container).render(
        <QueryClientProvider client={queryClient}>
            <MetronicI18nProvider>
                <AuthProvider>
                    <AppRoutes />
                </AuthProvider>
            </MetronicI18nProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}*/
if (container) {
    createRoot(container).render(
        <QueryClientProvider client={queryClient}>
            <MetronicI18nProvider>
                <AuthProvider>
                    <AppRoutes />
                </AuthProvider>
            </MetronicI18nProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}
