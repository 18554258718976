import React, {FC, useEffect,useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../../_rebusiness/helpers'
import {ErrorMessage, Field} from 'formik'
import clsx from "clsx";
import {Link} from "react-router-dom";
import {PasswordMeterComponent} from '../../../../../_rebusiness/assets/ts/components'

export type StepProps = {
  formik:any
}


const RegistrationStep2 = ({formik}: StepProps) => {
    const [showPassword, setShowPassword] = useState(false);
    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])
  return (
      <div>
          {/* begin::Form group Firstname */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>{`Nom ${formik.values.role == 'cfa' ? "du CFA" : "de l'entreprise"}`}</label>
          <input
              placeholder={`Entrer le Nom ${formik.values.role =='cfa' ? "du CFA" : "de l'entreprise"}`}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('companyName')}
              className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.companyName && formik.errors.companyName,
                  },
                  {
                    'is-valid': formik.touched.companyName && !formik.errors.companyName,
                  }
              )}
          />
          {formik.touched.companyName && formik.errors.companyName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.companyName}</span>
                </div>
              </div>
          )}
        </div>
          {formik.values.role=='cfa'?<div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>{`UAI`}</label>
              <input
                  placeholder={`Entrer votre numéro UAI`}
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('uai')}
                  className={clsx(
                      'form-control bg-transparent',
                      {
                          'is-invalid': formik.touched.uai && formik.errors.uai,
                      },
                      {
                          'is-valid': formik.touched.uai && !formik.errors.uai,
                      }
                  )}
              />
              {formik.touched.uai && formik.errors.uai && (
                  <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.uai}</span>
                      </div>
                  </div>
              )}
          </div>:<><div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>{`SIRET`}</label>
              <input
                  placeholder={`Entrer votre SIRET `}
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('siret')}
                  className={clsx(
                      'form-control bg-transparent',
                      {
                          'is-invalid': formik.touched.siret && formik.errors.siret,
                      },
                      {
                          'is-valid': formik.touched.siret && !formik.errors.siret,
                      }
                  )}
              />
              {formik.touched.siret && formik.errors.siret && (
                  <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.siret}</span>
                      </div>
                  </div>
              )}
          </div><div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>{`SIREN`}</label>
              <input
                  placeholder={`Entrer votre SIREN`}
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('siren')}
                  className={clsx(
                      'form-control bg-transparent',
                      {
                          'is-invalid': formik.touched.siren && formik.errors.siren,
                      },
                      {
                          'is-valid': formik.touched.siren && !formik.errors.siren,
                      }
                  )}
              />
              {formik.touched.siren && formik.errors.siren && (
                  <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.siren}</span>
                      </div>
                  </div>
              )}
          </div></>}
          <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>{`Adresse`}</label>
              <input
                  placeholder={`Entrer votre adresse`}
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('companyAddress')}
                  className={clsx(
                      'form-control bg-transparent mb-8',
                      {
                          'is-invalid': formik.touched.companyAddress && formik.errors.companyAddress,
                      },
                      {
                          'is-valid': formik.touched.companyAddress && !formik.errors.companyAddress,
                      }
                  )}
              />
              <div className='d-flex'>
                  <input
                      placeholder={`Entrer votre code postal`}
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('companyZipCode')}
                      className={clsx(
                          'form-control bg-transparent mb-8',
                          {
                              'is-invalid': formik.touched.companyZipCode && formik.errors.companyZipCode,
                          },
                          {
                              'is-valid': formik.touched.companyZipCode && !formik.errors.companyZipCode,
                          }
                      )}
                  />
                  <input
                      placeholder={`Entrer votre ville`}
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('companyCity')}
                      className={clsx(
                          'form-control bg-transparent mb-8',
                          {
                              'is-invalid': formik.touched.companyCity && formik.errors.companyCity,
                          },
                          {
                              'is-valid': formik.touched.companyCity && !formik.errors.companyCity,
                          }
                      )}
                  />
              </div>

              {formik.touched.companyAddress && formik.errors.companyAddress && (
                  <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.companyAddress}</span>
                      </div>
                  </div>
              )}
              {formik.touched.companyZipCode && formik.errors.companyZipCode && (
                  <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.companyZipCode}</span>
                      </div>
                  </div>
              )}
              {formik.touched.companyCity && formik.errors.companyCity && (
                  <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.companyCity}</span>
                      </div>
                  </div>
              )}
          </div>
          {formik.values.role=='cfa'&&<div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>{`Site Internet`}</label>
              <input
                  placeholder={`Entrer votre site internet`}
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('companyWebsite')}
                  className={clsx(
                      'form-control bg-transparent',
                      {
                          'is-invalid': formik.touched.companyWebsite && formik.errors.companyWebsite,
                      },
                      {
                          'is-valid': formik.touched.companyWebsite && !formik.errors.companyWebsite,
                      }
                  )}
              />
              {formik.touched.companyWebsite && formik.errors.companyWebsite && (
                  <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.companyWebsite}</span>
                      </div>
                  </div>
              )}
          </div>}
          <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>{`Teléphone`}</label>
              <input
                  placeholder={`Entrer votre numéro de téléphone`}
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('companyPhoneNumber')}
                  className={clsx(
                      'form-control bg-transparent',
                      {
                          'is-invalid': formik.touched.companyPhoneNumber && formik.errors.companyPhoneNumber,
                      },
                      {
                          'is-valid': formik.touched.companyPhoneNumber && !formik.errors.companyPhoneNumber,
                      }
                  )}
              />
              {formik.touched.companyPhoneNumber && formik.errors.companyPhoneNumber && (
                  <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.companyPhoneNumber}</span>
                      </div>
                  </div>
              )}
          </div>
          <div className='separator separator-content my-14'>
              <span className='w-125px text-gray-500 fw-semibold fs-7'>Information de connexion</span>
          </div>












          {/* end::Form group */}

        {/* begin::Form group Email */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Email</label>
          <input
              placeholder='Email'
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
              )}
          />
          {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
          <div className='fv-row mb-8' data-password-meter='true'>
              <div className='mb-1'>
                  <label className='form-label fw-bolder text-dark fs-6'>Mot de passe</label>
                  <div className='flex mb-3'>
                      <div className='input-group'><input
                          type={showPassword ? 'text' : 'password'}
                          placeholder='Mot de passe'
                          autoComplete='off'
                          {...formik.getFieldProps('password')}
                          className={clsx(
                              'form-control bg-transparent',
                              {
                                  'is-invalid': formik.touched.password && formik.errors.password,
                              },
                              {
                                  'is-valid': formik.touched.password && !formik.errors.password,
                              }
                          )}
                      />
            <i  onClick={() => setShowPassword(prevShow => !prevShow)} style={{ cursor: 'pointer' }} className={`input-group-text bi bi-eye${showPassword ? '-slash' : ''}`} />
                        </div>

                      {formik.touched.password && formik.errors.password && (
                          <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                  <span role='alert'>{formik.errors.password}</span>
                              </div>
                          </div>
                      )}
                  </div>

                  {/* begin::Meter */}
                  <div className='d-flex align-items-center mb-3' data-password-meter-control='highlight'>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                  </div>
                  {/* end::Meter */}
              </div>
              <div className='text-muted'>
                  Le mot de passe doit contenir au moins 8 caractères, dont au moins 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial
              </div>
          </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>Confirmer le mot de passe</label>
          <input
              type='password'
              placeholder='Confirmer le mot de passe'
              autoComplete='off'
              {...formik.getFieldProps('passwordConfirmation')}
              className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.passwordConfirmation && formik.errors.passwordConfirmation,
                  },
                  {
                    'is-valid': formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
                  }
              )}
          />
          {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.passwordConfirmation}</span>
                </div>
              </div>
          )}
        </div>
        </div>
  )
}

export {RegistrationStep2}
