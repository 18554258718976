// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {ProjectInfoCell} from './ProjectInfoCell'
import {ProductInfoCell} from './ProductInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserStatus} from './UserStatus'
import {ProjectStatus} from './ProjectStatus'
import {ContributionStatus} from './ContributionStatus'
import {UserActionsCell} from './UserActionsCell'
import {ProjectActionsCell} from './ProjectActionsCell'
import {ProductActionsCell} from './ProductActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {ProjectSelectionCell} from './ProjectSelectionCell'
import {ProductSelectionCell} from './ProductSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {ProjectCustomHeader} from './ProjectCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {ProjectSelectionHeader} from './ProjectSelectionHeader'
import {ProductSelectionHeader} from './ProductSelectionHeader'
import {ProjectIsPrivateCell} from './ProjectIsPrivateCell'
import {Contribution, User} from '../../core/_models'
import {UserAddress} from "./UserAddress";
import {UserTwoLines} from "./UserTwoLines";
import {ProjectInfoCell2} from "./ProjectInfoCell2";
import {PriceTag} from "./PriceTag";
import {ContributionActionsCell} from "./ContributionActionsCell";
import {ContributionSelectionHeader} from "./ContributionSelectionHeader";
import {ContributionSelectionCell} from "./ContributionSelectionCell";
import {ContributionCustomHeader} from "./ContributionCustomHeader";

const usersColumns: ReadonlyArray<Column<User>> = [

  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Nom' className='min-w-125px' />,
    id: 'companyName',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Adresse' className='min-w-125px' />
    ),
    id: 'companyAddress',
    Cell: ({...props}) => <UserAddress address={props.data[props.row.index].companyAddress} city={props.data[props.row.index].companyCity} zipcode={props.data[props.row.index].companyZipCode} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Contact' className='min-w-125px' />
    ),
    id: 'contactFirstName',
    Cell: ({...props}) => <UserTwoLines line1={(props.data[props.row.index].contactFirstName + props.data[props.row.index].contactLastName)} line2={(props.data[props.row.index].contactPosition)}  />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Téléphone' className='min-w-125px' />
    ),
    id: 'companyPhoneNumber',
    Cell: ({...props}) => <UserTwoLines line1={(props.data[props.row.index].companyPhoneNumber).replace(/\D/g, '').replace(/\D/g, '')} line2={(props.data[props.row.index].contactPhoneNumber).replace(/\D/g, '').replace(/\D/g, '')}  />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='statut' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <UserStatus status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} user={props.data[props.row.index]} />,
  },
]
const ProjectsColumns: ReadonlyArray<Column<Project>> = [

  {
    Header: (props) => <ProjectSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ProjectSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Nom' className='min-w-125px' />,
    id: 'companyName',
    Cell: ({...props}) => <ProjectInfoCell user={props.data[props.row.index].User} project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Objectif' className='min-w-125px' />,
    accessor: 'projectCost',
    Cell: ({...props}) => <PriceTag price={props.data[props.row.index].projectCost} />,
  },
  {
    Header: (props) => (
        <ProjectCustomHeader tableProps={props} title='statut' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <ProjectStatus status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
        <ProjectCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ProjectActionsCell id={props.data[props.row.index].id} project={props.data[props.row.index]} />,
  },
]
const ProductsColumns: ReadonlyArray<Column<Product>> = [

  {
    Header: (props) => <ProductSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ProductSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Nom' className='min-w-125px' />,
    id: 'companyName',
    Cell: ({...props}) => <ProductInfoCell user={props.data[props.row.index].User} product={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Prix TTC' className='min-w-125px' />,
    accessor: 'productPrice',
    Cell: ({...props}) => <PriceTag price={props.data[props.row.index].productPrice} />,
  },
  {
    Header: (props) => (
        <ProjectCustomHeader tableProps={props} title='statut' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <ProjectStatus status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
        <ProjectCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ProductActionsCell id={props.data[props.row.index].id} product={props.data[props.row.index]} />,
  },
]
const ContributionsColumns: ReadonlyArray<Column<Contribution>> = [

  {
    Header: (props) => <ContributionSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ContributionSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ContributionCustomHeader tableProps={props} title='Nom' className='min-w-125px' />,
    accessor: 'reference',
  },
  {
    Header: (props) => <ContributionCustomHeader tableProps={props} title='Projet' className='min-w-125px' />,
    accessor: 'Project',
    Cell: ({...props}) => <ProjectInfoCell2 user={props.data[props.row.index].Project} project={props.data[props.row.index].Project} />,
  },
  {
    Header: (props) => <ContributionCustomHeader tableProps={props} title='Montant' className='min-w-125px' />,
    accessor: 'amount',
    Cell: ({...props}) => <PriceTag price={props.data[props.row.index].amount} />,
  },
  {
    Header: (props) => <ContributionCustomHeader tableProps={props} title='Methode' className='min-w-125px' />,
    accessor: 'method',
  },
  {
    Header: (props) => <ContributionCustomHeader tableProps={props} title='Statut' className='min-w-125px' />,
    accessor: 'status',
    Cell: ({...props}) => <ContributionStatus status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
        <ContributionCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ContributionActionsCell id={props.data[props.row.index].id} project={props.data[props.row.index]} />,
  },
]
export {usersColumns,ProjectsColumns,ContributionsColumns,ProductsColumns}
