/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_rebusiness/helpers'
import {User} from '../../core/_models'

type Props = {
  user: User
}

const UserInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {user.avatar ? (
          <div className='symbol-label'>
            <img src={toAbsoluteUrl(`${process.env.REACT_APP_PUBLIC_URL}/${user.avatar}`)} alt={user.companyName} className='w-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-${user.contactFirstName?.slice(0,1)}`,
              `text-${user.contactLastName?.slice(0,1)}`
            )}
          >
            {user.contactFirstName?.slice(0,1)}
          </div>
        )}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {user.companyName} <div className='badge badge-light fw-bolder'> {user.role}</div>
      </a>
      <span>{user.email}</span>
    </div>
  </div>
)

export {UserInfoCell}
