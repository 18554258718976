import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_rebusiness/helpers'
import {UsersQueryResponse,ProjectsQueryResponse,ProductsQueryResponse,ContributionsQueryResponse} from './_models'
import {
  deleteUserById,
  getAllUsers,
  getAllProjects,
  updateUserStatus,
  updateProjectStatus,
  updateContributionStatus,
  getAllContributions,
  getAllProducts,
} from "../../../../core/_requests";

const getUsers = (query: string,type:string): Promise<UsersQueryResponse> => {
  return getAllUsers(query,type).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const getProjects = (query: string): Promise<ProjectsQueryResponse> => {
  return getAllProjects(query).then((d: AxiosResponse<ProjectsQueryResponse>) => d.data)
}
const getProducts = (query: string): Promise<ProductsQueryResponse> => {

  return getAllProducts(query).then((d: AxiosResponse<ProductsQueryResponse>) => d.data)
}
const getContributions = (query: string): Promise<ContributionsQueryResponse> => {
  return getAllContributions(query).then((d: AxiosResponse<ContributionsQueryResponse>) => d.data)
}
const statusUsers = (id:ID,status:string): Promise<void> => {
  return updateUserStatus(id, status)
}
const statusProjects = (id:ID,status:string): Promise<void> => {
  return updateProjectStatus(id, status)
}
const statusContributions = (id:ID,status:string): Promise<void> => {
  return updateContributionStatus(id, status)
}

const deleteUser = (userId: ID): Promise<void> => {
  return deleteUserById(userId)
}

const activateSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  console.log('activated selected user')
  const requests = userIds.map((id) => statusUsers(id,'activated'))
  return axios.all(requests).then(() => {})
}
const activateSelectedProjects = (projectIds: Array<ID>): Promise<void> => {
  console.log('activated selected projects')
  const requests = projectIds.map((id) => statusProjects(id,'online'))
  return axios.all(requests).then(() => {})
}
const activateSelectedContributions = (contributionIds: Array<ID>): Promise<void> => {
  console.log('activated selected contributions')
  const requests = contributionIds.map((id) => statusContributions(id,'succeeded'))
  return axios.all(requests).then(() => {})
}

export {getUsers,getProducts,getProjects,getContributions,statusProjects,statusContributions, statusUsers, deleteUser, activateSelectedUsers,activateSelectedProjects,activateSelectedContributions}
