import { createContext, useContext } from "react";

const context = createContext({
  toggleMenu: () => {},
  toggleSearch: () => {},
});

export const useRootContext = () => {
  return useContext(context);
};

export default context;
